import { CommonModule } from '@angular/common';
import { Component, ComponentRef, EventEmitter, Injector, Input, OnDestroy, Output, ViewContainerRef, inject } from '@angular/core';
import { Subject, defer, from, map, startWith, takeUntil } from 'rxjs';
import { JarvisModalComponent, JarvisUiModalWrapperModule } from '@jarvis/ui';
import { ChatRoom } from '../../../types/messaging.types';
import { ComponentPortal, PortalModule } from '@angular/cdk/portal';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'chat-autoreply',
  templateUrl: 'chat-autoreply.component.html',
  standalone: true,
  imports: [
    CommonModule,
    JarvisModalComponent,
    JarvisUiModalWrapperModule,
    PortalModule
  ]
})
export class ChatAutoreplyComponent implements OnDestroy {
  @Input() autoReplies: ChatRoom['autoReply'];
  @Input() chatroomId: string;
  @Output() closed = new EventEmitter<void>();

  configComponentPortal$ = this.createAutoReplyComponentObservable();

  private vcr = inject(ViewContainerRef);
  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeHandler(): void {
    this.closed.emit();
  }
  
  createAutoReplyComponentObservable() {
    return defer(
      () => from(import('@jarvis/auto-reply'))
    ).pipe(
      map((importResult) => {
        const component = importResult.AutoReplyConfigComponent;
        const dataToken = importResult.AUTO_REPLY_DATA;

        const injector = Injector.create([
          {
            provide: dataToken,
            useValue: {
              autoReplies: this.autoReplies,
              chatroomId: this.chatroomId
            }
          }
        ], this.vcr.injector);
        
        const componentPortal = new ComponentPortal(component, null, injector);

        const componentAttachCb = (ref: ComponentRef<InstanceType<typeof component>>) => {
          const { instance } = ref;
          // Set Outputs
          instance.closed.pipe(
            takeUntil(this.destroy$)
          ).subscribe(() => {
            this.closeHandler();
          });
        };

        return {
          portal: componentPortal,
          attachCb: componentAttachCb
        };
      }),
      startWith(null)
    );
  }

}