import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MarketplaceCardAnimations } from '../../marketplace-card.animations';

@Component({
  selector: 'jarvis-ui-marketplace-card-tag',
  templateUrl: 'marketplace-card-tag.component.html',
  standalone: true,
  imports: [
    CommonModule
  ],
  animations: [...MarketplaceCardAnimations],
  styleUrls: ['./marketplace-card-tag.component.scss']
})

export class MarketplaceCardTagComponent implements OnInit {
  @Input() textColor: 'orange' | 'gray' = 'gray';

  /* @HostBinding('@fadeInOut') get fadeInOut() {
    return true;
  } */

  /* @HostBinding('class') get class() {
    return 'bztt-pointer-events-none bztt-min-w-[155px] md:bztt-min-w-0 bztt-rounded-md bztt-flex bztt-px-1.5 bztt-items-center bztt-bg-white bztt-h-6 bztt-font-montserrat bztt-text-xss bztt-text-[#EF5518] bztt-cursor-pointer';
  } */

  /* @HostBinding('class.bztt-text-[#717171]') get orangeTextColor() {
    return this.textColor === 'orange';
  }

  @HostBinding('class.bztt-text-[#717171]') get defaultTextColor() {
    return this.textColor !== 'orange';
  } */


  ngOnInit() { }
}