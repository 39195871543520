
import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  inject,
} from '@angular/core';
import { JarvisTrackingService } from '@jarvis/services';
import { Subject, takeUntil } from 'rxjs';

import SwiperCore, { Pagination, Navigation, Scrollbar } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Pagination, Navigation, Scrollbar]);

@Component({
  selector: 'jarvis-homepage-reviews',
  templateUrl: './homepage-reviews.component.html',
  styleUrls: ['./homepage-reviews.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomepageReviewsComponent implements AfterViewInit {
  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  trackService = inject(JarvisTrackingService);

  window = window;

  reviewsConfig = {
    slidesPerView: 1,
    grabCursor: true,
    scrollbar: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  };

  reviews = [
    {
      image: 'slide-1.webp',
      name: 'landing.homepageUplift.reviews.review-1.name',
      description: 'landing.homepageUplift.reviews.review-1.description',
    },
    {
      image: 'slide-5.webp',
      name: 'landing.homepageUplift.reviews.review-5.name',
      description: 'landing.homepageUplift.reviews.review-5.description',
    },
    {
      image: 'slide-2.webp',
      name: 'landing.homepageUplift.reviews.review-2.name',
      description: 'landing.homepageUplift.reviews.review-2.description',
    },
    {
      image: 'slide-3.webp',
      name: 'landing.homepageUplift.reviews.review-3.name',
      description: 'landing.homepageUplift.reviews.review-3.description',
    },
    {
      image: 'slide-4.webp',
      name: 'landing.homepageUplift.reviews.review-4.name',
      description: 'landing.homepageUplift.reviews.review-4.description',
    },
    {
      image: 'slide-6.webp',
      name: 'landing.homepageUplift.reviews.review-6.name',
      description: 'landing.homepageUplift.reviews.review-6.description',
    },
  ];

  private destroy$ = new Subject<void>();

  constructor(public cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cd.detectChanges();

    this.swiper.indexChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  swipePrev() {
    this.swiper.swiperRef.slidePrev();
  }
  swipeNext() {
    this.swiper.swiperRef.slideNext();
  }
}
