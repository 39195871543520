import { SEOStructuredDataStrategy } from '@jarvis/seo';
import { Organization } from 'schema-dts';

const SCHEMA_US: Organization = {
  "@type": "Organization",
  "url": "https://breezit.com",
  "description": "Wedding venues marketplace in California. Detailed listings, prices, offers & vendor calendar availability.",
  "name": "Breezit",
  "sameAs": [
    "https://www.facebook.com/JustBreezit",
    "https://www.instagram.com/justbreezit/",
    "https://www.tiktok.com/@justbreezit",
    "https://www.pinterest.com/justbreezit/"
  ],
  "image": "https://breezit.com/assets/images/landing/homepage/homepage-top-alvaro.jpg",
  "logo": "https://breezit.com/assets/images/bzt-logo.svg",
  "email": "support@breezit.com",
  "telephone": "+1 (213) 723-2796",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "2055 Limestone Rd. STE 200-C",
    "addressLocality": "Wilmington",
    "addressCountry": "US",
    "addressRegion": "Delaware",
    "postalCode": "19808"
  }
};

const SCHEMA_LT: Organization = {
  "@type": "Organization",
  "url": "https://breezit.lt",
  "description": "Didžiausia vestuvių ir kitų renginių platforma Lietuvoje. Vestuvių ir kitų renginių planavimas vienoje modernioje platformoje. Šventės vietos, fotografai, atlikėjai, vedėjai ir šimtai kitų paslaugų teikėjų.",
  "name": "Breezit",
  "alternateName": "Foxy Wedding",
  "sameAs": [
    "https://www.facebook.com/breezitLT/",
    "https://www.instagram.com/breezit_lt/"
  ],
  "image": "https://breezit.lt/assets/images/seo/default-meta-lt.jpg",
  "logo": "https://breezit.lt/assets/images/bzt-logo.svg",
  "email": "support@breezit.com",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Goštauto g. 8",
    "addressLocality": "Vilnius",
    "addressCountry": "LT",
    "addressRegion": "Vilniaus apskritis",
    "postalCode": "01108"
  },
  "vatID": "LT100012545818"
};


export class HomepageStructuredDataStrategy implements SEOStructuredDataStrategy {
  constructor(
    private _document: Document,
    private domainCountry: string
  ) { }

  execute(): void {
    const organizationSchema = this.generateOrganizationSchema();
    
    const result = [
      organizationSchema
    ];

    this.insertSchema(result);
  }

  // TODO: Proper multi-store solution
  private generateOrganizationSchema() {
    if (this.domainCountry === 'lt') {
      return SCHEMA_LT;
    }

    return SCHEMA_US;
  }

  // TODO: Repeats. Lib function?
  private insertSchema(schema: Record<string, any>, className = 'structured-data-website'): void {
    let script;
    let shouldAppend = false;

    if (this._document.head.getElementsByClassName(className).length) {
      script = this._document.head.getElementsByClassName(className)[0];
    } else {
      script = this._document.createElement('script');
      shouldAppend = true;
    }

    script.setAttribute('class', className);
    script.type = 'application/ld+json';
    script.text = JSON.stringify(schema);

    if (shouldAppend) {
      this._document.head.appendChild(script);
    }
  }
}
