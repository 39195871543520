import { Component, Inject } from '@angular/core';
import { DOMAIN_COUNTRY, JarvisLanguageService } from '@jarvis/services';
import { switchMap, tap } from 'rxjs/operators';
import { JarvisUiRulesPoliciesService, RulesPoliciesTypes } from '../services/rules-policies.service';

@Component({
  selector: 'jarvis-ui-rules-policies-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class JarvisUiCookiesComponent {

  loading = false;

  cookiesContent$ = this.languageService.currentLanguage$.pipe(
    // tap((v) => console.log(v)),
    tap(() => this.loading = true),
    switchMap((language) => {
      return this.rulesPoliciesService.getPolicy(RulesPoliciesTypes.cookies, language, this.domainCountry.toUpperCase()).pipe(
        tap(() => this.loading = false)
      );
    })
  );

  constructor(
    private rulesPoliciesService: JarvisUiRulesPoliciesService,
    private languageService: JarvisLanguageService,
    @Inject(DOMAIN_COUNTRY) private domainCountry: 'us' | 'lt'
  ) { }

}
