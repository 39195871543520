<div class="service-container mb-3 p-3 p-lg-4 d-flex bztt-justify-center">
  <div class="service-info">
    <!-- <span class='service-name mb-4'>{{serviceInfo.name | translate}}</span> -->

    <div class="service-price">
      <div
        class="col pb-3 bztt-box-content bztt-min-h-[48px] text-start d-flex align-items-center gap-3"
      >
        <span class="service-name">{{ serviceInfo.name | translate }}</span>

        <ng-container [ngSwitch]="quantityFieldDisabled">
          <div *ngSwitchCase="true" class="price-new-input"></div>
          <div class="bztt-flex bztt-gap-2 md:bztt-ml-auto" *ngSwitchDefault>
            <mat-form-field class="price-new-input">
              <mat-label>{{
                'unitsShort.' +
                  (serviceInfo.priceUnit === 'fixed' &&
                  !serviceInfo.quantityDisabled
                    ? 'fixed_venues'
                    : serviceInfo.priceUnit)
                  | translate
                  | titlecase
              }}</mat-label>
              <div class="input-wrapper">
                <mat-icon
                  [class.disabled]="quantityDisabled"
                  (click)="decreaseQty()"
                  class="cursor-pointer"
                  >remove</mat-icon
                >
                <input
                  matInput
                  (change)="quantityUpdate($event)"
                  [value]="serviceInfo.quantity"
                  [disabled]="quantityDisabled"
                  min="0"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                />
                <mat-icon
                  [class.disabled]="quantityDisabled"
                  (click)="increaseQty()"
                  class="cursor-pointer"
                  >add</mat-icon
                >
              </div>
            </mat-form-field>

            <mat-form-field
              *ngIf="serviceInfo.additionalQuantityEnabled"
              class="price-new-input"
            >
              <mat-label>{{
                'unitsShort.hour' | translate | titlecase
              }}</mat-label>
              <div class="input-wrapper">
                <mat-icon
                  (click)="decreaseAdditionalQuantity()"
                  class="cursor-pointer"
                  >remove</mat-icon
                >
                <input
                  matInput
                  (change)="additionalQuantityUpdate($event)"
                  [value]="serviceInfo.additionalQuantity"
                  min="0"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                />
                <mat-icon
                  (click)="increaseAdditionalQuantity()"
                  class="cursor-pointer"
                  >add</mat-icon
                >
              </div>
            </mat-form-field>
          </div>
        </ng-container>

        <!-- <div [class.disabled]="quantityDisabled" class="p-2 rounded-6 border d-inline-flex price-input">
                    <mat-icon (click)="decreaseQty()" class="cursor-pointer">remove</mat-icon>
                    <mat-icon (click)="increaseQty()" class="cursor-pointer">add</mat-icon>
                </div>

                <div *ngIf="!quantityDisabled" class="p-2 rounded-6 border d-inline-flex price-input">
                    <input [disabled]="quantityDisabled" [value]='serviceInfo.quantity' (change)="quantityUpdate($event)" class="fw-bolder price-font quantity" min="0" type="number" />
                </div>

                <span>{{'unitsShort.' + serviceInfo.priceUnit | translate | titlecase }}</span> -->

        <ng-container [ngSwitch]="serviceInfo.priceUnit === 'fixed_percentage'">
          <div *ngSwitchCase="true" class="price-font">
            {{ serviceInfo.defaultPrice }} %
          </div>

          <ng-container
            *ngSwitchDefault
            [ngSwitch]="(finalPrice || finalUnitPrice) < 10"
          >
            <div *ngSwitchCase="true" class="price-font">
              {{
                finalPrice || finalUnitPrice
                  | currency
                    : (activeCurrencyCode$ | async)
                    : 'symbol'
                    : '1.2-2'
                    : (activeLocale$ | async)
              }}
            </div>

            <div *ngSwitchDefault class="price-font">
              {{
                finalPrice || finalUnitPrice
                  | currency
                    : (activeCurrencyCode$ | async)
                    : 'symbol'
                    : '1.0-0'
                    : (activeLocale$ | async)
              }}
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <span
      [class.show-full-description]="readMore"
      class="mb-3 service-description"
    >
      <jarvis-ui-package-description
        [serviceVariant]="serviceInfo"
        [locale]="activeLocale$ | async"
        [currencyCode]="activeCurrencyCode$ | async"
        [date]="dateControl.value"
        [recalculate]="recalculatedPrice$"
        [showPricePerUnit]="showPricePerUnit"
      ></jarvis-ui-package-description>
      
    </span>

    <a (click)="readMore = !readMore; trackClick()">{{
      'listing.selectService.' + (readMore ? 'close' : 'readMore') | translate
    }}</a>
  </div>
  <div class="service-buttons">
    <ng-container [ngSwitch]="serviceInfo.included">
      <button
        *ngSwitchCase="true"
        (click)="removeService()"
        app-button
        [color]="buttonColor"
      >
        <mat-icon prefix>done</mat-icon>
        <span class="text-center">{{
          (quantityFieldDisabled
            ? 'listing.selectService.included'
            : 'listing.selectService.interested'
          ) | translate
        }}</span>
      </button>

      <button
        *ngSwitchDefault
        (click)="addService()"
        app-button
        class="outline-button"
        [class.accent]="buttonColor === 'accent'"
      >
        <span class="text-center">{{
          'listing.selectService.add' | translate
        }}</span>
      </button>
    </ng-container>
  </div>
</div>
