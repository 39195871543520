import { Component, Input, OnInit } from '@angular/core';
import { BreezitReviewData } from '../reviews.types';

@Component({
  selector: 'jarvis-reviews-review-summary',
  templateUrl: './review-summary.component.html',
  styleUrls: ['./review-summary.component.scss']
})
export class ReviewSummaryComponent implements OnInit {

  @Input() interactive = true;
  @Input() data: BreezitReviewData;

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }

}
