import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { HomepageReviewsComponent } from './components/homepage-reviews/homepage-reviews.component';
import { SwiperModule } from 'swiper/angular';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TranslateModule } from '@ngx-translate/core';
import { JarvisUiMarketplaceCardModule } from './../../../../ui/marketplace-card/marketplace-card.module';
import { ReviewStarsModule } from '@jarvis/reviews';
import { LandingsModule } from '../landings.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions,
  MatLegacyFormFieldModule as MatFormFieldModule,
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/legacy-form-field';
import { LANDING_TYPE } from '../services';
import { RouterModule, Routes } from '@angular/router';
import {
  ButtonModule,
  JarvisCommonDialogModule,
  JarvisHeaderModule,
  SmallCalendarModule,
} from '@jarvis/ui';
import { ServiceTypeSelectorModule } from '@jarvis/ui/service-type-selector';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HomepageUpliftedComponent } from './homepage-uplifted.component';
import { JarvisUiInputFormatsModule } from '@jarvis/ui/src/lib/directives/input-formats/input-formats.module';
import { MatDividerModule } from '@angular/material/divider';
import { UpliftedHomepageCalendarComponent } from './components/homepage-calendar/homepage-calendar.component';
import { ServicePipesModule } from '@jarvis/services';
import { MatRippleModule } from '@angular/material/core';
import { EventDateSelectorComponent } from './components/event-date-selector/event-date-selector.component';
import { HomepageHeroSectionComponent } from './components/homepage-hero-section/homepage-hero-section.component';
import { HomepagePackageSectionComponent } from './components/homepage-package-section/homepage-package-section.component';
import { HomepageMenuServiceListComponent } from './components/service-list/service-list.component';
import { HomepageCategoriesSectionComponent } from './components/categories-section/categories-section.component';
import { HomepageCuratedListsComponent } from './components/curated-lists/curated-lists.component';
import { HomepagePlannerSectionComponent } from './components/planner-section/planner-section.component';
import { HomepageListingListComponent } from './components/listing-list/listing-list.component';
import { UiGoogleLocationDropdownComponent } from '@jarvis/ui/google-location-dropdown';
import { HomepageSeoResolver } from './seo/seo.resolver';

const FORM_FIELD_DEFAULTS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

const ROUTES: Routes = [
  {
    path: '',
    component: HomepageUpliftedComponent,
    resolve: {
      seo: HomepageSeoResolver
    }
  },
];

@NgModule({
  declarations: [
    HomepageUpliftedComponent,
    UpliftedHomepageCalendarComponent,
    EventDateSelectorComponent,
    HomepageHeroSectionComponent,
    HomepagePackageSectionComponent,
    HomepageReviewsComponent,
    HomepageMenuServiceListComponent,
    HomepageCategoriesSectionComponent,
    HomepageCuratedListsComponent,
    HomepagePlannerSectionComponent,
    HomepageListingListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    LayoutModule,
    ButtonModule,
    HttpClientModule,
    MatSidenavModule,
    LandingsModule,
    SmallCalendarModule,
    ReviewStarsModule,
    JarvisUiInputFormatsModule,
    MatDividerModule,
    JarvisUiMarketplaceCardModule,
    TranslateModule,
    JarvisCommonDialogModule,
    MatMenuModule,
    ServicePipesModule,
    MatRippleModule,
    SwiperModule,
    MatSnackBarModule,
    ServiceTypeSelectorModule,
    JarvisHeaderModule,
    UiGoogleLocationDropdownComponent
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: FORM_FIELD_DEFAULTS,
    },
    {
      provide: LANDING_TYPE,
      useValue: 'user',
    },
    HomepageSeoResolver
  ],
  exports: [HomepageUpliftedComponent, HomepageMenuServiceListComponent],
})
export class HomepageUpliftedModule {}
