<div
  class="d-flex flex-column w-100 h-100 justify-content-start overflow-hidden bztt-relative"
>
  <div class="bztt-pb-5 bztt-font-semibold bztt-text-lg bztt-text-start">
    {{ 'bookings.detail.selectedServices' | translate }}
  </div>
  <ng-container>
    <!-- <ng-template [ngTemplateOutlet]="charges"></ng-template> -->
    <ng-template [ngTemplateOutlet]="groupedListings"></ng-template>
    <div
      class="bztt-flex bztt-justify-start bztt-items-center bztt-gap-2 bztt-font-semibold bztt-text-base bztt-py-7 bztt-text-[#EF5518] bztt-cursor-pointer"
      (click)="setGroupEditKey('customService')"
    >
      <mat-icon>add</mat-icon>
      <span class="bztt-text-[#EF5518]">Add a custom service</span>
    </div>
  </ng-container>
</div>

<jarvis-modal
  maxHeight="90vh"
  [modalClass]="['mat-dialog-no-padding', 'booking-edit-dialog']"
  *ngIf="groupEditKey"
  (modalClose)="clearGroupEditKey()"
>
  <jarvis-ui-modal-wrapper
    class="modal-wrapper bztt-flex"
    (closed)="clearGroupEditKey()"
  >
    <div [ngSwitch]="groupEditKey">
      <div *ngSwitchCase="'customService'">
        <ng-template [ngTemplateOutlet]="customServiceAdd"></ng-template>
      </div>

      <div *ngSwitchDefault>
        <ng-template [ngTemplateOutlet]="groupEdit"></ng-template>
      </div>
    </div>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>

<ng-template #groupEdit>
  <div
    class="bztt-flex bztt-flex-col bztt-justify-between bztt-p-6 bztt-h-full bztt-pb-28"
  >
    <div
      class="bztt-flex bztt-flex-col"
      *ngIf="
        bookingDetailEdit
          | groupByKey : 'key'
          | arrayContainsValues : [{ key: groupEditKey }] as groups
      "
    >
      <div
        class="bztt-text-2xl bztt-font-semibold bztt-text-start bztt-pb-6"
        *ngIf="groups[0]?.services.length !== 1"
      >
        {{ groupEditKey | translate }}
      </div>

      <div *ngFor="let group of groups">
        <div class="bztt-flex bztt-flex-col bztt-gap-4">
          <ng-container *ngFor="let service of group.services">
            <ng-template
              [ngTemplateOutlet]="serviceItem"
              [ngTemplateOutletContext]="{ item: service }"
            ></ng-template>
          </ng-container>
        </div>
      </div>
    </div>

    <div
      class="bztt-flex bztt-flex-col bztt-absolute bztt-bottom-0 bztt-bg-white"
      style="width: calc(100% - 47px)"
    >
      <div class="bztt-py-4 bztt-bottom-0">
        <div class="bztt-flex bztt-items-center bztt-justify-center bztt-gap-4">
          <button
            app-button
            color="accent"
            (click)="saveEditDialog()"
            class="bztt-w-full bztt-max-w-[200px]"
          >
            {{ 'bookings.detail.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #serviceItem let-item="item">
  <div
    class="bztt-flex bztt-rounded-lg bztt-bg-[#F9F9F9] bztt-p-3 bztt-flex-col"
  >
    <div
      class="included-switch bztt-flex bztt-justify-end bztt-items-center bztt-gap-2"
    >
      <span class="bztt-font-semibold">{{
        'bookings.servicesModal.included' | translate
      }}</span>
      <mat-slide-toggle
        [checked]="item.isIncluded"
        (change)="$event.checked ? addService(item) : removeService(item)"
        color="accent"
      ></mat-slide-toggle>
    </div>

    <div class="bztt-text-lg bztt-text-start bztt-font-semibold bztt-pb-5">
      {{
        (item.serviceVariant?.type === ServiceTypes.globalMinimalBudget
          ? 'booking.defaultDescriptions.globalMinimalBudget.altName'
          : item.name
        ) | translate
      }}
    </div>

    <div
      class="bztt-flex bztt-w-full bztt-items-center bztt-relative bztt-gap-3 bztt-mb-4"
    >
      <div
        *ngIf="
          item.serviceVariant?.type !== 'serviceCharge' &&
          item.serviceVariant?.type !== 'globalMinimalBudget'
        "
        class="bztt-border bztt-rounded-lg bztt-p-3 bztt-flex bztt-items-center bztt-w-full"
      >
        <mat-icon class="bztt-cursor-pointer" (click)="qntChange(item, -1)"
          >remove</mat-icon
        >
        <input
          [(ngModel)]="item.qnt"
          (change)="qntChange(item, 0)"
          class="bztt-bg-[#F9F9F9] fw-bold quantity"
          type="number"
          inputmode="numeric"
          pattern="[0-9]*"
        />
        <mat-icon class="bztt-cursor-pointer" (click)="qntChange(item, 1)"
          >add</mat-icon
        >
        <span *ngIf="item.unit" class="unit-title">{{
          'unitsShort.' + item.unit | translate | titlecase
        }}</span>
      </div>
      <div
        *ngIf="item.serviceVariant?.additionalQuantityEnabled"
        class="bztt-border bztt-rounded-lg bztt-p-3 bztt-flex bztt-items-center bztt-w-full"
      >
        <mat-icon
          class="bztt-cursor-pointer"
          (click)="additionalQntChange(item, -1)"
          >remove</mat-icon
        >
        <input
          [(ngModel)]="item.additionalQnt"
          (change)="additionalQntChange(item, 0)"
          class="bztt-bg-[#F9F9F9] fw-bold quantity"
          type="number"
          inputmode="numeric"
          pattern="[0-9]*"
        />
        <mat-icon
          class="bztt-cursor-pointer"
          (click)="additionalQntChange(item, 1)"
          >add</mat-icon
        >
        <span *ngIf="item.unit" class="unit-title">{{
          'unitsShort.hour' | translate | titlecase
        }}</span>
      </div>
      <div
        class="bztt-border bztt-rounded-lg bztt-p-3 bztt-flex bztt-items-center bztt-w-1/2"
      >
        <span *ngIf="getCurrencySymbol(item.serviceVariant) == '$'" matPrefix>{{
          getCurrencySymbol(item.serviceVariant)
        }}</span>
        <ng-container
          *ngIf="item.serviceVariant?.type === ServiceTypes.globalMinimalBudget"
        >
          <input
            [(ngModel)]="item.savedMinimalBudget"
            (change)="setCustomPrice(item)"
            [placeholder]="item.price"
            class="bztt-bg-[#F9F9F9] fw-bolder price"
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
          />
        </ng-container>
        <ng-container
          *ngIf="item.serviceVariant?.type !== ServiceTypes.globalMinimalBudget"
        >
          <input
            [(ngModel)]="item.amount"
            (change)="setCustomPrice(item)"
            [placeholder]="item.price"
            class="bztt-bg-[#F9F9F9] fw-bolder price"
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
          />
        </ng-container>
        <span
          *ngIf="getCurrencySymbol(item.serviceVariant) != '$'"
          class="ps-1"
          matSuffix
          >{{ getCurrencySymbol(item.serviceVariant) }}</span
        >
      </div>
    </div>

    <div>
      <div
        class="bztt-flex bztt-flex-col description-container bztt-pb-1 bztt-text-start"
        [class.bztt-hidden]="item.edit"
        [class.expanded]="item.readLess"
      >
        <div #content>
          <div
            *ngIf="!item.serviceVariant"
            class="text-content bztt-whitespace-pre-line"
          >
            {{ item.description }}
          </div>
          <jarvis-ui-package-description
            class="bztt-flex bztt-flex-col bztt-whitespace-pre-line"
            *ngIf="item.serviceVariant"
            [serviceVariant]="item.serviceVariant"
            [customDescription]="item.description"
            [locale]="locale"
            [currencyCode]="currencyCode"
            [date]="booking?.eventDate"
            [showPricePerUnit]="true"
            [overideMinimalBudget]="item.savedMinimalBudget"
          ></jarvis-ui-package-description>
        </div>
      </div>
      <div
        *ngIf="!item.edit && content.scrollHeight > 75"
        class="bztt-w-fit bztt-flex bztt-justify-start bztt-items-center bztt-cursor-pointer bztt-text-sm bztt-pt-[10px] bztt-font-semibold bztt-underline"
        (click)="item.readLess = !item.readLess"
      >
        <span>
          {{
            'bookings.servicesModal.' +
              (item.readLess ? 'readLess' : 'readMore') | translate
          }}
        </span>
        <mat-icon>{{
          item.readLess ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
        }}</mat-icon>
      </div>
    </div>

    <div *ngIf="item.edit" class="bztt-mb-3">
      <mat-form-field
        appearance="outline"
        floatLabel="never"
        class="wo-border w-100"
      >
        <textarea
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="15"
          type="text"
          [(ngModel)]="item.description"
          matInput
        >
        </textarea>
      </mat-form-field>
    </div>

    <div
      class="bztt-flex bztt-justify-center bztt-items-center bztt-gap-2 bztt-rounded-full bztt-text-[#6B6060] bztt-border bztt-border-[#6B6060] bztt-px-3 bztt-py-1 bztt-cursor-pointer bztt-text-sm bztt-w-fit bztt-self-end"
      (click)="handleItemEdit(item)"
    >
      <mat-icon class="material-icons-outlined">edit</mat-icon>
      <span class="bztt-leading-none">
        {{
          'bookings.servicesModal.' +
            (item.edit ? 'saveDescription' : 'editDescription') | translate
        }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #customServiceAdd>
  <div
    class="bztt-flex bztt-flex-col bztt-justify-between bztt-p-6 bztt-h-full bztt-pb-28"
  >
    <div class="bztt-flex bztt-flex-col bztt-gap-5">
      <div class="bztt-text-2xl bztt-font-semibold bztt-text-start bztt-pb-6">
        {{ 'bookings.servicesModal.customService.title' | translate }}
      </div>

      <mat-form-field appearance="outline" floatLabel="never">
        <input
          [(ngModel)]="customService.name"
          placeholder="{{ 'bookings.servicesModal.serviceTitle' | translate }}"
          type="text"
          min="0"
          matInput
        />
      </mat-form-field>

      <div
        class="bztt-flex bztt-justify-center bztt-w-full bztt-items-center bztt-gap-4"
      >
        <div
          class="bztt-border bztt-rounded-lg bztt-p-3 bztt-flex bztt-items-center bztt-w-full bztt-relative"
        >
          <mat-icon
            class="bztt-cursor-pointer"
            (click)="
              customService.qnt =
                customService.qnt - (customService.qnt > 1 ? 1 : 0)
            "
            >remove</mat-icon
          >
          <input
            class="fw-bold quantity"
            [(ngModel)]="customService.qnt"
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
          />
          <mat-icon
            class="bztt-cursor-pointer"
            (click)="customService.qnt = customService.qnt + 1"
            >add</mat-icon
          >
          <span class="unit-title bztt-bg-white">{{
            'bookings.servicesModal.customService.qnt' | translate
          }}</span>
        </div>
        <div
          class="bztt-border bztt-rounded-lg bztt-p-3 bztt-flex bztt-text-[#1F1F1F] bztt-items-center bztt-w-full"
        >
          <span *ngIf="getCurrencySymbol() == '$'" matPrefix>{{
            getCurrencySymbol()
          }}</span>
          <input
            [(ngModel)]="customService.amount"
            class="fw-bolder price"
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
          />
          <span *ngIf="getCurrencySymbol() != '$'" class="ps-1" matSuffix>{{
            getCurrencySymbol()
          }}</span>
        </div>
      </div>
      <mat-form-field appearance="outline" floatLabel="never">
        <textarea
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="15"
          [(ngModel)]="customService.description"
          placeholder="{{ 'bookings.servicesModal.description' | translate }}"
          type="text"
          matInput
        >
        </textarea>
      </mat-form-field>
    </div>
    <div
      class="bztt-flex bztt-flex-col bztt-absolute bztt-bottom-0 bztt-bg-white"
      style="width: calc(100% - 47px)"
    >
      <div class="bztt-py-4 bztt-bottom-0">
        <div class="bztt-flex bztt-items-center bztt-justify-center bztt-gap-4">
          <button
            app-button
            color="accent"
            [disabled]="!canAddCustomService()"
            (click)="addCustomService()"
            app-button
            class="bztt-w-full"
          >
            {{ 'bookings.detail.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #listingGroupItem let-item="data" let-total="total">
  <div
    class="listingGroupItem bztt-flex bztt-py-2 bztt-flex-col bztt-gap-1 bztt-text-[#6B6060]"
  >
    <div class="bztt-text-start bztt-text-base bztt-text-[#6B6060]">
      {{ item.name | translate }}
    </div>
    <div class="bztt-flex bztt-justify-between bztt-items-center bztt-gap-5">
      <span
        class="bztt-text-sm bztt-text-[#6B6060] bztt-font-normal bztt-text-start"
      >
        {{ item.description }}
      </span>

      <div *ngIf="total > 1">
        <span>{{
          item.amount | currency : currencyCode : 'symbol' : null : locale
        }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #groupedListings>
  <div class="bztt-flex bztt-flex-col bztt-gap-4">
    <div
      *ngFor="
        let group of bookingDetailEdit
          | groupByKey : 'key'
          | sortToEnd : 'key' : 'serviceCharge'
      "
      class="bztt-flex bztt-flex-col bztt-bg-[#F9F9F9] bztt-rounded-lg bztt-p-4"
    >
      <div class="bztt-flex bztt-justify-between bztt-items-center bztt-pb-4">
        <div class="bztt-text-lg bztt-font-semibold bztt-text-start">
          {{
            (group.type === 'otherFees' ? group.name : group.key) | translate
          }}
        </div>
        <div
          class="bztt-flex bztt-justify-center bztt-items-center bztt-gap-1 bztt-rounded-full bztt-cursor-pointer bztt-text-xs"
          (click)="setGroupEditKey(group.key)"
        >
          <mat-icon class="material-icons-outlined">edit</mat-icon>
          <span class="bztt-leading-none">
            {{ 'bookings.detail.edit' | translate }}
          </span>
        </div>
      </div>

      <div
        class="package-group"
        *ngIf="
          group.type !== 'otherFees' && group.services
            | arrayContainsValues : [{ isIncluded: true }] as includedServices
        "
      >
        <ng-container *ngFor="let groupService of includedServices">
          <ng-template
            [ngTemplateOutlet]="listingGroupItem"
            [ngTemplateOutletContext]="{
              data: groupService,
              total: includedServices.length
            }"
          ></ng-template>
        </ng-container>
      </div>

      <div
        *ngIf="group.services.length > 0"
        class="bztt-flex bztt-justify-between bztt-items-center bztt-text-base bztt-pt-3"
      >
        <ng-container
          *ngIf="
            group.services.length === 1 &&
              (group.services[0]?.unit === 'fixed_percentage' ||
                group.services[0]?.serviceVariant?.type ===
                  ServiceTypes.globalMinimalBudget);
            else totalGroupValue
          "
        >
          <ng-container *ngIf="group.services[0]?.unit === 'fixed_percentage'">
            <span>Amount</span>

            <div
              class="bztt-flex bztt-justify-end bztt-items-center bztt-text-[#1F1F1F]"
            >
              <span>{{ group.services[0]?.amount || 0 }}%</span>
            </div>
          </ng-container>

          <ng-container
            *ngIf="
              group.services[0]?.serviceVariant?.type ===
              ServiceTypes.globalMinimalBudget
            "
          >
            <span class="bztt-text-[#6B6060]">Price</span>

            <div
              class="bztt-flex bztt-justify-end bztt-items-center bztt-text-[#1F1F1F]"
            >
              <span>
                {{
                  (group.services
                    | arrayContainsValues : [{ isIncluded: true }])[0]?.price ||
                    0 | currency : currencyCode : 'symbol' : null : locale
                }}</span
              >
            </div>
          </ng-container>
        </ng-container>

        <ng-template #totalGroupValue>
          <span class="bztt-text-[#6B6060]">Price</span>

          <div
            class="bztt-flex bztt-justify-end bztt-items-center bztt-text-[#1F1F1F]"
          >
            <span>{{
              group.services
                | arrayContainsValues : [{ isIncluded: true }]
                | selectedServicesTotalPrice
                | currency : currencyCode : 'symbol' : null : locale
            }}</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

