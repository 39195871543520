import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { JarvisMeasurementUnitModule } from '@jarvis/services';
import {
  JarvisOverlayComponent,
  JarvisReadMoreLinkComponent,
} from '@jarvis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CardListingTypePipe } from '../../pipes/card-listing-type.pipe';
import { PriceUnitLabelPipe } from '../../pipes/price-unit.pipe';
import { MarketplaceVenuesBlogInfoTagsComponent } from './venues-blog-info-tags/venues-blog-info-tags.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';

@Component({
  selector: 'jarvis-ui-marketplace-card-venues-blog-info',
  templateUrl: 'venues-blog-info.component.html',
  styleUrls: ['venues-blog-info.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    CardListingTypePipe,
    PriceUnitLabelPipe,
    JarvisOverlayComponent,
    JarvisReadMoreLinkComponent,
    MarketplaceVenuesBlogInfoTagsComponent,
    JarvisMeasurementUnitModule,
    CdkOverlayOrigin,
  ],
})
export class JarvisUiMarketplaceCardVenuesBlogInfoComponent implements OnInit {
  @Input() isMobile = false;
  @Input() data;

  hasOverlayInformation = false;
  infoOverlayShown = false;

  ngOnInit(): void {
    this.hasOverlayInformation = this.data.venueInfo?.seatsOverCapacity;
  }

  handleInfoOverlayOpen(): void {
    this.infoOverlayShown = true;
  }

  handleInfoOverlayClose(): void {
    this.infoOverlayShown = false;
  }
}
