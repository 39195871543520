import { UntypedFormControl } from '@angular/forms';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASE_URL, JarvisSearchParamStoreService } from '@jarvis/services';
import { take } from 'rxjs/operators';
import { JarvisLocationService } from '@jarvis/ui/google-location-dropdown';

@Injectable({
  providedIn: 'root',
})
export class HomepageUpliftedService {
  eventDateControl = new UntypedFormControl();
  eventLocationControl = new UntypedFormControl();

  private get homepageEndpoint(): string {
    if (this.baseUrl) {
      return `${this.baseUrl}/marketplace/search/home`;
    }

    return `/marketplace/search/home`;
  }

  constructor(
    private httpService: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
    private searchParamService: JarvisSearchParamStoreService,
    private locationService: JarvisLocationService
  ) {
    this.searchParamService.searchParamStore$
      .pipe(take(1))
      .subscribe((params) => {
        if (params.date) {
          const date = new Date(params.date);
          const today = new Date();
          if (+date > +today) {
            this.eventDateControl.setValue(date);
          }
        }
      });

    this.eventDateControl.valueChanges.subscribe((date) => {
      if (date) {
        this.searchParamService.setParam({
          date,
        });
      }
    });
  }

  getHomepageServices(
    eventType: string,
    country: string,
    category,
    location?
  ): Observable<any> {
    const fromObject = {};

    fromObject['serviceType'] = category;
    fromObject['venueType'] = eventType;

    if (location && location.region) {
      fromObject['region'] = location.region;
    }

    if (location && location.gmapData?.data?.area1) {
      fromObject['area1'] = location.gmapData?.data.area1;
    }

    if (location && location.gmapData?.data?.area2) {
      fromObject['area2'] = location.gmapData?.data.area2;
    }

    if (country) {
      fromObject['country'] = country.toUpperCase();
    }

    let params = new HttpParams({ fromObject });

    return this.httpService.get(this.homepageEndpoint, { params });
  }
}
