<!-- <div class="no-messages-container">
    <div>
        <h1>You don't have any messages yet</h1>
        <span>Want to know how messages works?</span>
    </div>
    <div class="button-container">
        <button app-button color="accent">Video tutorial</button>
    </div>
</div> -->

<!-- <jarvis-ui-actions-card [i18nKey]="'messages.noMessages'" [showSubtext]="false" [showButton]="false"></jarvis-ui-actions-card> -->

<jarvis-ui-card-wrapper class="bookings-wrapper bztt-p-1 bztt-w-full bztt-justify-center bztt-items-center bztt-flex">
    <div class="bztt-flex bztt-flex-col bztt-items-center">
       <mat-icon class="bztt-text-[60px] bztt-color-[#6b6060] bztt-opacity-70 bztt-h-[60px] bztt-w-[60px] material-icons-outlined">
          chat
       </mat-icon>
       <div class="bztt-pb-8 bztt-pt-6">{{ "messages.noMessages.title" | translate }}</div>                         
    </div>
  </jarvis-ui-card-wrapper>
