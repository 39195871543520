<app-modal-wrapper class="modal-wrapper success" (closed)="close()">
<div class="h-100 w-100 d-flex flex-column align-items-center justify-content-center md:bztt-max-w-3xl md:bztt-p-10 bztt-p-5">
        <span><mat-icon class="icon" [ngClass]="data.icon">{{data.icon?data.icon:'verified'}}</mat-icon></span>
        <span *ngIf="data.title" class="mt-3 fs-3 bolder bztt-leading-9">{{ data.title }}</span>
        <span *ngIf="!data.title" class="mt-3 fs-3 bolder bztt-leading-9">{{ i18nKey + ".title" | translate }}</span>

        <span *ngIf="data.message" class="mt-3 message">{{ data.message }}</span>
        <span *ngIf="!data.message" class="mt-3 message">{{ i18nKey + ".message" | translate }}</span>
</div>
</app-modal-wrapper>
