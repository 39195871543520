import { SwiperComponent } from 'swiper/angular';
import { AfterViewInit, Component, Input, ViewChild, ViewEncapsulation, ChangeDetectorRef, inject } from '@angular/core';
import { LayoutService } from '@jarvis/ui';
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";
import { DOMAIN_COUNTRY } from '@jarvis/services';

SwiperCore.use([EffectCoverflow, Autoplay]);

const EVENT_TYPES_LT = [
  {
    type: 'type-1',
    image: 'hero-image-private-lt.webp',
    translation: 'landing.homepageUplift.hero.celebration'
  },
  {
    type: 'type-2',
    image: 'hero-image-wedding-lt.webp',
    translation: 'landing.homepageUplift.hero.wedding'
  },
  {
    type: 'type-3',
    image: 'hero-image-business-lt.webp',
    translation: 'landing.homepageUplift.hero.businessEvent'
  }
];

const EVENT_TYPES_US = [
  {
    type: 'type-1',
    image: 'hero/us/1.png',
    translation: 'landing.homepageUplift.hero.celebration'
  },
  {
    type: 'type-2',
    image: 'hero/us/2.png',
    translation: 'landing.homepageUplift.hero.wedding'
  },
  {
    type: 'type-3',
    image: 'hero/us/3.png',
    translation: 'landing.homepageUplift.hero.businessEvent'
  }
];

@Component({
  selector: 'jarvis-homepage-hero-section',
  templateUrl: './homepage-hero-section.component.html',
  styleUrls: ['./homepage-hero-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomepageHeroSectionComponent implements AfterViewInit {
  @Input() marketplaceLink;

  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  activeEventType = {
    type: 'type-1',
    image: 'hero-image-1.webp'
  };

  heroConfig = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    autoplay: {
      delay: 2000,
    },
    coverflowEffect: {
      rotate: 0,
      stretch: '85%',
      depth: 20,
      modifier: 1,
      slideShadows: false
    }
  };

  country = inject(DOMAIN_COUNTRY);

  eventTypes = this.country === 'lt' ? EVENT_TYPES_LT : EVENT_TYPES_US;

  constructor(
    public layoutService: LayoutService,
    private cd: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.cd.detectChanges();

    this.swiper.indexChange.subscribe(() => {
      this.cd.detectChanges();
    });
  }

}
