import { SmallCalendarModule } from '@jarvis/ui/small-calendar';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleMapsAPIWrapper } from '@jarvis/agm-map';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { AppButtonModule } from './shared/components/buttons/button.module';
import { CredentialsInterceptor } from './shared/interceptors/credentials.interceptor';
import { MatIconModule } from '@angular/material/icon';
import { HeaderModule } from './core/components/header/header.module';
import { MESSAGES_TYPE } from '@jarvis/messaging';

import { registerLocaleData } from '@angular/common';
import localeLt from '@angular/common/locales/lt';
import localeUs from '@angular/common/locales/en';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { JarvisAuthService } from '@jarvis/auth';
import {
  BASE_URL,
  LINK_URLS,
  DOMAIN_COUNTRY,
  ExamineCountry,
  EnvironmentByHostName,
  AmplitudeFactory,
  JarvisLanguageService,
  BASE_SOCKET_URL,
  LANGUAGE_TOKEN,
  ServicesService,
  MixpanelFactory,
} from '@jarvis/services';
import {
  environment,
  environmentByHostName,
} from '../environments/environment';
import { MessagingService } from '@jarvis/messaging';
import {
  MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions,
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/legacy-form-field';
import { JarvisNotificationService } from '@jarvis/notifications';
import { LandingsModule } from '@jarvis/landings';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { JarvisHeaderModule } from '@jarvis/ui';
import { MainComponent } from './main.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { PixelModule } from 'ngx-pixel';
import { MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/legacy-autocomplete';
import { LandingFooterComponent } from '@jarvis/landings/landing-footer';

import defaultLanguage from 'libs/ui/src/assets/i18n/app/en.json';
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { BookingsDataService } from '@jarvis/saved-bookings';
import { NGX_HOTJAR_SETTINGS_TOKEN, NgxHotjarModule } from 'ngx-hotjar';

const FORM_FIELD_DEFAULTS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

registerLocaleData(localeLt);
registerLocaleData(localeUs);

moment.updateLocale('en', {
  week: {
    dow: 1,
    doy: 0,
  },
});

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/app/',
    '.json?cb=' + environment.hash
  );
}

function translationInitFactory(
  translate: TranslateService
): () => Promise<any> {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

@NgModule({
  declarations: [AppComponent, MainComponent],
  imports: [
    BrowserModule,
    HeaderModule,
    JarvisHeaderModule,
    HammerModule,
    MatDialogModule,
    MatIconModule,
    LandingsModule,
    AppButtonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LandingFooterComponent,
    HttpClientModule,
    SmallCalendarModule,
    StoreModule.forRoot({}),
    NgxHotjarModule.forRoot(''),
    EffectsModule.forRoot([]),
    PixelModule.forRoot({ enabled: false, pixelId: '348134357452498' }),
    MatProgressBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      //defaultLanguage: 'en',
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    }),
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManagerId,
    }),
    SocialLoginModule,
  ],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    {
      provide: BASE_URL,
      useValue:
        EnvironmentByHostName(environment, environmentByHostName).baseUrl +
        'api',
    },
    {
      provide: BASE_SOCKET_URL,
      useValue: EnvironmentByHostName(environment, environmentByHostName)
        .baseSocketUrl,
    },
    {
      provide: DOMAIN_COUNTRY,
      useFactory: ExamineCountry(),
    },

    BookingsDataService,
    {
      provide: MESSAGES_TYPE,
      useValue: 'vendor',
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: FORM_FIELD_DEFAULTS,
    },
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    GoogleMapsAPIWrapper,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: JarvisAuthService.checkLoginFactory,
      deps: [JarvisAuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AmplitudeFactory(environment.amplitudeKey),
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: MixpanelFactory(environment.mixpanelKey),
      multi: true,
    },
    /* {
      provide: BASE_URL,
      useValue: environment.baseUrl + 'api'
    }, */
    {
      provide: LINK_URLS,
      useValue: {
        marketplace: environment.marketplaceUrl,
        saas: environment.saasUrl,
      },
    },
    {
      provide: LANGUAGE_TOKEN,
      useValue: defaultLanguage,
    },
    {
      provide: NGX_HOTJAR_SETTINGS_TOKEN,
      useFactory: (country: string) => {
        return {
          trackingCode:
            country === 'lt' ? environment.hotjarIdLT : environment.hotjarId,
          version: 6,
        };
      },
      deps: [DOMAIN_COUNTRY],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: JarvisLanguageService) => () =>
        ds.translateInitializerFactory(),
      deps: [JarvisLanguageService, LANGUAGE_TOKEN],
      multi: true,
    },
    MessagingService,
    JarvisNotificationService,
    ServicesService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              // '379422156132-iakjv96r5jgeckunnkfri4mu8v2udhi7.apps.googleusercontent.com',
              '611147134097-5vtptf6k2mnlmjo4ebrr4pl6t1b7dm6g.apps.googleusercontent.com',

              {
                scope: 'email',
                plugin_name: 'breezit',
              }
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('559046098959289'),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [MainComponent],
})
export class AppModule {
  constructor() {
    // iconRegistry.setDefaultFontSetClass('material-icons-outlined');
  }
}
