<div class='close-container'>
    <button class='close-button' (click)="close()">
        <a>Close</a>
        <mat-icon>clear</mat-icon>
    </button>
</div>

<div class='default-modal-wrapper' *ngIf="outlet$ | async as outlet">
    <ng-template [cdkPortalOutlet]="outlet"></ng-template>
</div>

<div class='actions-container' *ngIf="actions && actions.length > 0">
    <ng-container *ngFor="let action of actions">
        <button (click)="action.callback()" app-button [color]="action.color || 'accent'">
            {{action.displayValue | translate}}
        </button>
    </ng-container>
</div>