<div 
  class="bztt-pointer-events-none bztt-min-w-[127px] bztt-rounded-md bztt-flex bztt-px-1.5 bztt-items-center bztt-bg-white bztt-h-6 bztt-font-montserrat bztt-text-[10px] bztt-cursor-pointer"
  [ngClass]="{'bztt-text-[#717171]': textColor !== 'orange', 'bztt-text-[#ef5518]': textColor === 'orange'}"
>
  <div
    class="bztt-w-[20px] bztt-h-[20px] bztt-flex bztt-items-center bztt-justify-center bztt-mr-1"
  >
    <ng-content select="mat-icon"></ng-content>
  </div>
  <span class="bztt-font-bold">
    <ng-content select="span"></ng-content>
  </span>
</div>