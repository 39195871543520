import { Component, inject, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'jarvis-form-errors',
  template: `
    <mat-error>{{ 'errors.fieldRequired' | translate }}</mat-error>
  `,
  styles: [
    `
      :host {
        text-align: left;
      }

      mat-error {
        color: #EF5518;
        font-weight: 400;
        font-size: 12px;
        border-bottom-color: #EF5518;
      }
    `
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    TranslateModule
  ]
})
export class JarvisFormErrorsComponent  {

  @Input() control: string;

  private controlContainer = inject(ControlContainer);

  get form(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get formControl(): AbstractControl {
    return this.form.get(this.control) as AbstractControl;
  }

  get isNotValid() {
    return this.formControl.invalid && (this.formControl.touched || this.formControl.dirty)
  }
}