import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { JarvisSeoService } from '@jarvis/seo';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { AIPlannerLandingStructuredDataStrategy } from './ai-planner-landing-structured-data.strategy';
import { AIPlannerLandingMetaTagStrategy } from './ai-planner-landing-metatag.strategy';
import { Meta, Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AIPlannerLandingSeoResolver implements Resolve<any> {
  private domainCountry = inject(DOMAIN_COUNTRY);
  private document = inject(DOCUMENT);
  private seoService = inject(JarvisSeoService);
  private metaService = inject(Meta);
  private titleService = inject(Title);

  resolve() {
    const metaTagData$ = new Subject<any>();

    // const aiPlannerLandingStructuredDataStrategy =
    //   new AIPlannerLandingStructuredDataStrategy(
    //     this.document,
    //     this.domainCountry,
    //     metaTagData$
    //   );

    const aiPlannerLandingMetaTagStrategy = new AIPlannerLandingMetaTagStrategy(
      this.metaService,
      this.titleService,
      this.domainCountry,
      this.document,
      metaTagData$
    );

    // this.seoService.structuredDataStrategy =
    //   aiPlannerLandingStructuredDataStrategy;

    this.seoService.metaTagStrategy = aiPlannerLandingMetaTagStrategy;

    return { metaTagData$ };
  }
}
