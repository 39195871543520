import { Component, Inject, Input, OnDestroy, Optional } from '@angular/core';
import {UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { LayoutService } from '@jarvis/ui/layout';

@Component({
  selector: 'menu-service-list',
  templateUrl: './service-list.component.html',
  styles: [`
        .orange-transform:hover {
            filter: invert(37%) sepia(100%) saturate(2720%) hue-rotate(356deg) brightness(100%) contrast(88%) !important;
        }

        .orange-transformed {
            filter: invert(37%) sepia(100%) saturate(2720%) hue-rotate(356deg) brightness(100%) contrast(88%) !important;
        }
    `]
})

export class MenuServiceListComponent implements OnDestroy {
  @Input() disableFeatured = false;
  @Input() rearrangeItems = false;
  @Input() disableFooter = false;

  searchFieldControl = new UntypedFormControl();

  private searchFieldControlValue$ = this.searchFieldControl.valueChanges.pipe(
    startWith(null)
  );

  category$;

  isMobile$ = this.layoutService.isMobileObserver;

  close$ = new Subject<void>();

  constructor(
    @Inject(DOMAIN_COUNTRY) public domainCountry: string,
    @Optional() private dialogRef: MatDialogRef<MenuServiceListComponent>,
    private layoutService: LayoutService
  ) {
  }

  ngOnDestroy(): void {
    this.close$.complete();
  }

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      return;
    }
    this.close$.next();
  }
}

