import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  SearchParamsStore,
  SearchParamsStoreByCategory,
} from '../../types/search-param-store.types';

const SEARCH_PARAM_STORAGE_KEY = 'marketplace_search_params_v2';

@Injectable({ providedIn: 'root' })
export class JarvisSearchParamStoreService {
  private _searchParamStore$ = new BehaviorSubject<SearchParamsStore>({});
  searchParamStore$ = this._searchParamStore$.asObservable();

  private _filterChange$ = new Subject<any>();
  filterChange$ = this._filterChange$.asObservable();

  constructor() {
    const currentData = localStorage.getItem(SEARCH_PARAM_STORAGE_KEY);
    if (currentData) {
      const parsed = JSON.parse(currentData);
      this._searchParamStore$.next(parsed);
    }
  }

  getSearchParamStore() {
    return this._searchParamStore$;
  }

  setParamByCategory(
    category: string,
    paramObj: Partial<SearchParamsStoreByCategory>
  ): void {
    const filterKey = Object.keys(paramObj)[0];
    const oldfilterValue = this._searchParamStore$.value?.categories?.[category]?.[filterKey];
    if (
      JSON.stringify(paramObj[filterKey]) !== JSON.stringify(oldfilterValue) &&
      paramObj[filterKey]
    ) {
      this._filterChange$.next({
        category,
        filter_name: filterKey,
        value: paramObj[filterKey],
      });
    }

    const categories = this._searchParamStore$.value?.categories || {};
    categories[category] = { ...categories[category], ...paramObj };
    this.setParam({ categories });
  }

  clearParamByCategory(category: string): any {
    const categories = this._searchParamStore$.value?.categories || {};
    categories[category] = {};
    this.setParam({ categories });
  }

  getParamByCategory(
    category: string
  ): Observable<SearchParamsStoreByCategory> {
    return this.searchParamStore$.pipe(
      take(1),
      map((prop) =>
        prop && prop.categories ? prop?.categories[category] : null
      )
    );
  }

  getParam(): SearchParamsStore {
    return this._searchParamStore$.getValue();
  }

  setParam(paramObj: Partial<SearchParamsStore>): void {
    const existingData = this._searchParamStore$.value;
    const modified = {
      ...existingData,
      ...paramObj,
    };
    this._searchParamStore$.next(modified);
    this.saveLocalStorageParams(modified);
  }

  private saveLocalStorageParams(data: SearchParamsStore) {
    const stringified = JSON.stringify(data);
    localStorage.setItem(SEARCH_PARAM_STORAGE_KEY, stringified);
  }
}
