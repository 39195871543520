import { Pipe, PipeTransform } from '@angular/core';
import { ServiceTypes } from '@jarvis/services';

@Pipe({
  name: 'sumOfKeyValues',
  standalone: true,
})
export class SumOfKeyValuesPipe implements PipeTransform {
  transform(value: any, containValues: string): any {
    if (!value) return null;
    if (!containValues) return value;

    return value.reduce((acc: number, curr: any) => {
      return acc + curr[containValues];
    }, 0);
  }
}

@Pipe({
  name: 'groupByKey',
  standalone: true,
})
export class GroupByKeyPipe implements PipeTransform {
  transform(value: any, key: string): any {
    if (!value) return null;
    if (!key) return value;

    const grouped = value.reduce((acc: any, curr: any) => {
      const index = acc.findIndex((item: any) => item.key === curr[key]);

      if (index === -1) {
        acc.push({
          key: curr[key],
          name: curr.name,
          type: curr.type,
          services: [curr],
        });
        return acc;
      }
      acc[index].services.push(curr);

      return acc;
    }, []);

    return grouped;
  }
}

@Pipe({
  name: 'arrayFilter',
  standalone: true,
})
export class ArrayFilterPipe implements PipeTransform {
  transform(value: any, func: any): any {
    if (!value) return null;
    if (!func) return value;

    return value.filter(func);
  }
}

@Pipe({
  name: 'sortToEnd',
  standalone: true,
})
export class SortToEndPipe implements PipeTransform {
  transform(value: any, key: any, val: string): any {
    if (!value) return null;
    if (!key) return value;

    return value.sort((a: any, b: any) => {
      if (a[key] === val) return 1;
      if (b[key] === val) return -1;
      return 0;
    });
  }
}

@Pipe({
  name: 'selectedServicesTotalPrice',
  standalone: true,
  pure: false,
})
export class SelectedServicesTotalPricePipe implements PipeTransform {
  transform(value: any[], date?: string): any {
    if (!value) return 0;

    return value
      .filter(
        (detail) => detail.serviceVariant?.type !== ServiceTypes.serviceCharge
      )
      .reduce((partial_sum, a) => {
        if (!a.isIncluded) return partial_sum;
        if (
          a.serviceVariant &&
          a.serviceVariant.type === ServiceTypes.globalMinimalBudget
        ) {
          a.serviceVariant.customPrice = a.price;
          return partial_sum + (a.serviceVariant.customPrice || a.amount);
        }
        if (a.serviceVariant && !a.isCustomPrice) {
          a.serviceVariant.quantity = a.qnt;
          if (a.serviceVariant.additionalQuantityEnabled) {
            a.serviceVariant.additionalQuantity = a.additionalQnt;
          }

          return partial_sum + a.serviceVariant.getFinalPrice(date || null);
        }
        return partial_sum + a.qnt * a.price;
      }, 0);
  }
}

// @Pipe({
//   name: 'cateringServicesTotalPrice',
//   standalone: true,
// })
// export class CateringServicesTotalPricePipe implements PipeTransform {
//   transform(value: any[], date?: string): any {
//     if (!value) return 0;

//     const catering

//     return value
//       .filter(
//         (detail) => detail.serviceVariant?.type !== ServiceTypes.serviceCharge
//       )
//       .reduce((partial_sum, a) => {
//         if (!a.isIncluded) return partial_sum;
//         if (a.serviceVariant && !a.isCustomPrice) {
//           a.serviceVariant.quantity = a.qnt;

//           if (a.serviceVariant.type === ServiceTypes.globalMinimalBudget) {
//             a.serviceVariant.customPrice = a.price;
//             return (
//               partial_sum + a.serviceVariant.getFinalUnitPrice(date || null)
//             );
//           }

//           return partial_sum + a.serviceVariant.getFinalPrice(date || null);
//         }
//         return partial_sum + a.qnt * a.price;
//       }, 0);
//   }
// }
