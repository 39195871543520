import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { JarvisUiTimePickerContentComponent } from './components/picker-content/time-picker-content.component';
import { JarvisUiTimePickerComponent } from './components/picker/time-picker.component';
import { JarvisUiTimepickerToggleComponent } from './components/toggle/time-picker-toggle.component';
import { TimePickerInputDirective } from './directives/time-picker-input.directive';
import { MinuteTimeValuePipe } from './pipes/minute-time-value.pipe';
import { TimeValueDisabledPipe } from './pipes/time-value-disabled.pipe';

const COMPONENTS_DIRECTIVES = [
    JarvisUiTimePickerComponent,
    TimePickerInputDirective,
    JarvisUiTimepickerToggleComponent,
    JarvisUiTimePickerContentComponent
];

@NgModule({
    imports: [
        MatIconModule,
        CommonModule
    ],
    exports: [
        ...COMPONENTS_DIRECTIVES
    ],
    declarations: [
        ...COMPONENTS_DIRECTIVES,
        MinuteTimeValuePipe,
        TimeValueDisabledPipe
    ],
    providers: [
        MinuteTimeValuePipe,
    ],
})
export class JarvisUiTimePickerModule { }
