import { Pipe, PipeTransform } from '@angular/core';
import { setHours, setMinutes } from 'date-fns';
import { JarvisLanguageService } from '@jarvis/services';

@Pipe({
  name: 'OverTimeDisplay',
  pure: true,
  standalone: true
})

export class OverTimeDisplayPipe implements PipeTransform {
  transform(value: number, locale: string): any {
    // const locale = language === 'lt' ? 'lt-LT' : 'en-US';
    return convertMinutesToTime(value, locale);
  }
}

function convertMinutesToTime(
  minutes: number | string,
  locale: string
): string {
  if (typeof minutes === 'string') {
    return '';
  }

  const leftMinutes = minutes % 60;
  const hours = (minutes - leftMinutes) / 60;
  return JarvisLanguageService.formatShortTime(
    setHours(setMinutes(new Date(), leftMinutes), hours),
    locale
  );
}