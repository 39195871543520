import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import SwiperCore, { Navigation } from 'swiper';

SwiperCore.use([Navigation]);

@Component({
  selector: 'landings-functions-section',
  templateUrl: './functions-section.component.html',
  styleUrls: ['./functions-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FunctionsSectionComponent implements OnInit {
  @Input() config: {
    icon: string,
    color: string,
    label: string,
  }[]

  configuration = [
    //vendor
    {
      icon: 'ic_create_account.svg',
      color: '#e5e7f8',
      label: 'Create an account'
    },
    {
      icon: 'ic_set_up_your_listing.svg',
      color: '#fdeae3',
      label: 'Set up your listing'
    },
    {
      icon: 'ic_update_your_calendar.svg',
      color: '#fff3e0',
      label: 'Update your calendar'
    },
    {
      icon: 'ic_start_taking_bookings.svg',
      color: '#ebebeb',
      label: 'Start taking bookings'
    },
    //user
    {
      icon: 'ic_create_account.svg',
      color: '#e5e7f8',
      label: 'Create an account'
    },
    {
      icon: 'ic_message_service_providers.svg',
      color: '#fdeae3',
      label: 'Message service providers'
    },
    {
      icon: 'ic_update_your_calendar.svg',
      color: '#fff3e0',
      label: 'Book services'
    },
  ];

  colCount: number;

  constructor() { }

  ngOnInit(): void {
    if (this.config) this.configuration = this.config;
    this.colCount = this.config.length;
  }

}
