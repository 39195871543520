<div [@slideContent]="animationState">
    <app-dropdown-wrapper>
        <a>First item</a>
        <a>Second item</a>
        <mat-divider></mat-divider>
        <a>Third item</a>
        <a>Fourth item</a>
        <mat-divider></mat-divider>
        <a>Fifth item</a>
        <a>Sixth item</a>
        <a>Seventh item</a>
    </app-dropdown-wrapper>
</div>
