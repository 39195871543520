<a class="menu-item-wrapper bztt-p-0 bztt-relative" [ngClass]="{
    'bztt-pointer-events-none': disableLink,
    'route-active': activeOn && activeOn.includes(currentUrl$ | async)
  }" [class.only-icon]="onlyIcon" [routerLink]="link ? link : null"
  [routerLinkActive]="link && !activeOn ? 'route-active' : ''" [routerLinkActiveOptions]="{ exact: true }"
  [state]="state" (click)="openDropdown($event)">
  <div class="icon" *ngIf="iconName">
    <div class="notif-indicator" *ngIf="notifications | async as notifCount">
      {{ notifCount }}
    </div>

    <ng-container *ngIf="imageLink != null; else iconBlock">
      <img slot="icon" [src]="imageLink" alt="Profile picture" class="profile-image" />
    </ng-container>

    <ng-template #iconBlock>
      <mat-icon>
        {{ iconName }}
      </mat-icon>
    </ng-template>
  </div>
  <!-- <div class="text" *ngIf="!onlyIcon">
    <span>
      <ng-content></ng-content>
    </span>
    <mat-icon *ngIf="dropdown"> arrow_drop_down </mat-icon>
  </div> -->

  <div class="text">
    <span class="bztt-whitespace-nowrap">
      <ng-content></ng-content>
    </span>
    <mat-icon *ngIf="showArrow"> arrow_drop_down </mat-icon>
  </div>
  <div class="bztt-pointer-events-none bztt-absolute -bztt-bottom-5" [matMenuTriggerFor]="lastLikedItem"
    #lastLikeDropdownTrigger="matMenuTrigger"></div>
</a>

<mat-menu xPosition="before" #lastLikedItem="matMenu" class="last-like-item-dropdown bztt-p-0" [hasBackdrop]="false">
  <ng-container *ngIf="lastLike$ | async as lastLike">
    <jarvis-last-like-dropdown (jarvis-last-like-dropdown)="closeLikeDropdown()"
      [lastLike]="lastLike"></jarvis-last-like-dropdown>
  </ng-container>
</mat-menu>