import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { mapTo, retry, switchMap } from 'rxjs/operators';
import { UserProfilingData } from '../types/user-profiling.types';
import { JarvisAuthService } from '@jarvis/auth/services';
import { BASE_URL } from '@jarvis/services/tokens';
import { httpRequestState } from '@jarvis/utils';

@Injectable({ providedIn: 'root' })
export class JarvisUserProfilingService {
  constructor(
      private httpService: HttpClient,
      private authService: JarvisAuthService,
      @Inject(BASE_URL) private baseUrl: string
  ) { }

  createProfilingInfo(data: UserProfilingData) {
    return this.httpService.post<UserProfilingData>(`${this.baseUrl}/common/editProfiling`, {
      data
    }).pipe(
      httpRequestState(),
      switchMap(data => {
        if (data.value) {
          return this.authService.getCurrentUser().pipe(
            mapTo(data)
          );
        }

        return of(data);
      })
    );
  }

  modifyIncompleteProfilingInfo(data: UserProfilingData): void {
    const incompleteData = {
      ...data,
      completed: false
    };

    this.httpService.post(`${this.baseUrl}/common/editProfiling`, { data: incompleteData }).pipe(
      retry(3)
    ).subscribe();
  }
    
}