<div class="bztt-text-xl bztt-font-semibold bztt-mb-4 bztt-text-start">
    {{ 'bookings.agreement.sign.title' | translate }}
</div>
<div class="bztt-flex bztt-flex-col">
<div class="bztt-px-0 md:bztt-px-[15%] bztt-py-10">
<div class="bztt-z-10 bztt-absolute bztt-top-0 bztt-left-0 bztt-items-center bztt-bg-[#ffffff8f] bztt-w-full bztt-h-full bztt-flex bztt-flex-col bztt-justify-center" *ngIf="processed | async">
    <mat-progress-spinner  mode="indeterminate" strokeWidth="2"></mat-progress-spinner>    
</div>    
<input
placeholder="{{ 'bookings.agreement.sign.placeholder' | translate }}"
type="text"
[formControl]="signature"
min="0"
matInput
class="bztt-border bztt-p-10 md:bztt-p-14 bztt-w-full bztt-text-center"
/>
</div>

<div class="bztt-flex bztt-flex-col md:bztt-flex-row bztt-justify-center bztt-gap-3 bztt-items-center">
    <div class="bztt-text-xs md:bztt-text-base bztt-bg-[#ebebeb] bztt-p-3 bztt-rounded-lg">{{ 'bookings.agreement.sign.description' | translate }}</div>
    <button app-button color="accent" [disabled]="!signature.value || (processed | async)" class="md:bztt-max-w-[100px]" (click)="sign()">
        <span>{{ 'bookings.agreement.sign.sign' | translate }}</span>
    </button>     
</div>
</div>