<div
  #infoContainer
  [ngClass]="{ 'bztt-pb-4': isMobile }"
  class="venues-blog-info bztt-font-sora bztt-font-normal bztt-flex bztt-flex-col bztt-h-full"
>
  <div class="bztt-flex bztt-justify-between bztt-mb-2">
    <p
      class="bztt-m-0 bztt-text-sm bztt-text-[var(--text-default)] bztt-whitespace-nowrap bztt-overflow-ellipsis bztt-overflow-hidden"
    >
      {{ data | CardListingType }}
    </p>

    <div class="bztt-min-w-fit" *ngIf="data.ratingCount != '-'">
      <div class="bztt-flex bztt-items-center">
        <span class="bztt-text-[#ef5518] bztt-mr-2 bztt-text-xl bztt-leading-4"
          >★</span
        >
        <span class="bztt-text-sm bztt-text-[var(--text-default)]">
          {{ data.rating != '-' ? data.rating : '' }} ({{ data.ratingCount }})
        </span>
      </div>
    </div>
  </div>

  <div class="bztt-min-h-4 bztt-mb-2">
    <div
      *ngIf="data.address"
      class="bztt-flex bztt-gap-[2px] bztt-text-[var(--text-accent)]"
    >
      <mat-icon class="bztt-text-[14px] bztt-h-[14px] bztt-w-[14px]"
        >location_on</mat-icon
      >
      <span
        class="bztt-text-xs bztt-h-4 bztt-text-ellipsis bztt-w-64 bztt-overflow-hidden bztt-whitespace-nowrap"
      >
        {{ data.address }}
      </span>
    </div>
  </div>

  <div class="bztt-mb-2">
    <h2
      class="bztt-font-sora bztt-mb-0 bztt-text-lg bztt-font-bold bztt-text-[16px] card-title-overflow-elipses"
    >
      {{ data.title }}
    </h2>
  </div>

  <div *ngIf="isMobile" class="pb-3 pt-2">
    <hr />
    <div
      class="bztt-text-sm bztt-font-sora bztt-pb-1.5 bztt-pt-4 bztt-font-semibold"
    >
      {{ 'listing.about' | translate }}
    </div>
    <jarvis-read-more-link
      [text]="data.serviceBase.description.generalDescription"
      [maxHeight]="90"
      [fontSize]="'14px'"
      [lineHeight]="'130%'"
    >
    </jarvis-read-more-link>
  </div>
  <jarvis-marketplace-venues-blog-info-tags
    [listingData]="data"
  ></jarvis-marketplace-venues-blog-info-tags>
  <div
    class="bztt-flex bztt-flex-col bztt-gap-1 bztt-justify-between bztt-mt-auto"
  ></div>
  <div class="bztt-flex bztt-items-center bztt-justify-between bztt-min-h-8">
    <span
      class="bztt-text-xs bztt-text-[var(--text-accent)] bztt-font-semibold"
    >
      {{
        (data.priceTitle
          ? 'listing.cart.' + data.priceTitle
          : 'listing.cart.priceTitle'
        ) | translate
      }}
    </span>

    <div *ngIf="data.venueInfo" class="bztt-flex bztt-gap-1 bztt-pb-1">
      <div
        class="bztt-flex bztt-items-center bztt-h-[32px] bztt-px-2 bztt-border bztt-rounded-[20px] bztt-border-[var(--border-color)]"
      >
        <mat-icon
          [svgIcon]="
            data.venueInfo.hasCatering && data.venueInfo.cateringProvided
              ? 'catering'
              : 'no_catering'
          "
        ></mat-icon>
        <mat-icon
          [svgIcon]="data.venueInfo.hasBeverages ? 'beverages' : 'no_beverages'"
        ></mat-icon>
        <mat-icon
          [svgIcon]="
            data.venueInfo.enoughSeats || data.venueInfo.maxSeatCount !== 0
              ? 'accommodation'
              : 'no_accommodation'
          "
        ></mat-icon>
      </div>

      <ng-container>
        <div
          (click)="
            $event.stopPropagation();
            $event.preventDefault();
            handleInfoOverlayOpen()
          "
          #infoOverlayOrigin="cdkOverlayOrigin"
          cdkOverlayOrigin
          [ngClass]="{ 'overlay-active': infoOverlayShown }"
          class="bztt-flex bztt-relative bztt-items-center bztt-justify-center bztt-h-[32px] bztt-w-[32px] bztt-border bztt-rounded-[20px] bztt-border-[var(--border-color)]"
        >
          <mat-icon svgIcon="info"></mat-icon>
          <div
            *ngIf="hasOverlayInformation"
            class="bztt-absolute bztt-h-[8px] bztt-w-[8px] bztt-rounded-full bztt-bg-[#EF5518] bztt-top-0 bztt-right-0"
          ></div>
        </div>

        <jarvis-overlay
          *ngIf="infoOverlayShown"
          (closed)="handleInfoOverlayClose()"
          type="connected"
          [anchor]="infoOverlayOrigin"
          overlayX="end"
          overlayY="bottom"
          originX="end"
          originY="top"
          [viewportMargin]="0"
        >
          <ng-container *ngTemplateOutlet="infoOverlay"></ng-container>
        </jarvis-overlay>
      </ng-container>
    </div>
  </div>

  <div class="bztt-flex bztt-justify-between bztt-items-center bztt-min-h-8">
    <div class="bztt-flex bztt-gap-1 bztt-items-end">
      <span
        class="bztt-text-2xl bztt-leading-[1em] bztt-text-[16px] bztt-font-semibold"
      >
        {{ data?.indicativePrice }}
      </span>

      <span
        *ngIf="data.category !== 'cakes_desserts'"
        class="bztt-text-sm bztt-text-[#717171]"
      >
        {{ data.priceUnit | PriceUnitLabel | async }}
      </span>
    </div>

    <div
      *ngIf="data.venueInfo"
      class="bztt-flex bztt-gap-1 bztt-items-center bztt-h-[32px] bztt-px-2 bztt-border bztt-rounded-[20px] bztt-border-[var(--border-color)]"
    >
      <span
        [ngSwitch]="data.venueInfo.filteredGuestCount > 0"
        class="bztt-text-xs bztt-text-[var(--text-dark)]"
      >
        <ng-container *ngSwitchCase="true">{{
          data.venueInfo.filteredGuestCount
        }}</ng-container>
        <ng-container *ngSwitchDefault>{{
          'listing.cart.guestCountUpTo' | translate : data.venueInfo
        }}</ng-container>
      </span>
      <mat-icon svgIcon="user_circle"></mat-icon>
    </div>

    <div *ngIf="data?.priceAddition?.hoursIncluded">
      <span
        class="bztt-text-xl bztt-text-[var(--text-default)] bztt-font-semibold"
      >
        {{ 'listing.cart.hoursIncludedVal' | translate : data.priceAddition }}
      </span>
    </div>
  </div>
</div>
<ng-content> </ng-content>

<ng-template #infoOverlay>
  <div
    style="--overlay-width: {{ infoContainer.clientWidth }}px"
    class="overlay-container-bright bztt-mb-4 bztt-rounded-lg bztt-w-[var(--overlay-width)] bztt-max-w-md bztt-text-xs bztt-p-4 bztt-flex bztt-flex-col bztt-justify-center bztt-gap-2"
  >
    <div class="bztt-flex bztt-gap-2 bztt-items-center">
      <div class="bztt-relative">
        <mat-icon
          [svgIcon]="
            data.venueInfo.hasCatering && data.venueInfo.cateringProvided
              ? 'catering'
              : 'no_catering'
          "
        ></mat-icon>
      </div>
      <span *ngIf="!data.venueInfo.cateringProvided">
        {{ 'listing.cart.cateringNotProvided' | translate }}
      </span>
      <ng-container *ngIf="data.venueInfo.cateringProvided">
        <span *ngIf="!data.venueInfo.hasCatering">
          {{ 'listing.cart.cateringNotIncluded' | translate }}
        </span>
        <span *ngIf="data.venueInfo.hasCatering">
          {{ 'listing.cart.cateringIncluded' | translate }}
        </span>
      </ng-container>
    </div>
    <div class="bztt-flex bztt-gap-2 bztt-items-center">
      <div class="bztt-relative">
        <mat-icon
          [svgIcon]="data.venueInfo.hasBeverages ? 'beverages' : 'no_beverages'"
        ></mat-icon>
      </div>
      <span
        *ngIf="
          !data.venueInfo.beveragesProvided && !data.venueInfo.hasBeverages
        "
      >
        {{ 'listing.cart.beveragesNotProvided' | translate }}
      </span>
      <ng-container
        *ngIf="data.venueInfo.beveragesProvided || data.venueInfo.hasBeverages"
      >
        <span *ngIf="data.venueInfo.hasBeverages">
          {{ 'listing.cart.beveragesIncluded' | translate }}
        </span>
        <span *ngIf="!data.venueInfo.hasBeverages">
          {{ 'listing.cart.beveragesNotIncluded' | translate }}
        </span>
      </ng-container>
    </div>
    <div class="bztt-flex bztt-gap-2 bztt-items-center">
      <div class="bztt-relative">
        <mat-icon
          [svgIcon]="
            data.venueInfo.enoughSeats || data.venueInfo.maxSeatCount !== 0
              ? 'accommodation'
              : 'no_accommodation'
          "
        ></mat-icon>
        <div
          *ngIf="data.venueInfo.seatsOverCapacity"
          class="bztt-absolute bztt-h-[8px] bztt-w-[8px] bztt-rounded-full bztt-bg-[#EF5518] bztt-top-0 bztt-right-0"
        ></div>
      </div>
      <span *ngIf="data.venueInfo.enoughSeats">
        {{ 'listing.cart.seatsFullyProvided' | translate }}
      </span>
      <ng-container *ngIf="!data.venueInfo.enoughSeats">
        <span *ngIf="data.venueInfo.maxSeatCount === 0">
          {{ 'listing.cart.noSeats' | translate }}
        </span>
        <ng-container *ngIf="data.venueInfo.maxSeatCount > 0">
          <span *ngIf="!data.venueInfo.seatsOverCapacity">
            {{
              'listing.cart.seatsAvailable'
                | translate
                  : {
                      seatCount:
                        data.venueInfo.filteredGuestCount ||
                        data.venueInfo.maxSeatCount
                    }
            }}
          </span>
          <span *ngIf="data.venueInfo.seatsOverCapacity">
            {{
              'listing.cart.seatsAvailable'
                | translate : { seatCount: data.venueInfo.maxSeatCount }
            }}
          </span>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
