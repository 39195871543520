<jarvis-modal height="80vh" (modalClose)="closeHandler()">
  <jarvis-ui-modal-wrapper [fullHeight]="true" class="modal-wrapper medium-modal" (closed)="closeHandler()">
    <div class="bztt-flex bztt-flex-col bztt-items-center bztt-flex-grow bztt-p-2">
      <div class="bztt-flex bztt-items-center bztt-justify-center bztt-h-16">
        <span>Debug prompt</span>
      </div>
    
      <textarea
        class="bztt-w-full bztt-h-full bztt-resize-none bztt-jarvis-scroll bztt-bg-[#F1F1F1] bztt-font-montserrat-r bztt-rounded-lg bztt-p-3 bztt-text-sm"
        [formControl]="promptControl"
      ></textarea>
    
    </div>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>