<div class="bztt-w-full bztt-flex bztt-flex-col bztt-items-start">
  <div
    class="bztt-flex bztt-justify-between bztt-items-center bztt-w-full bztt-mb-4 md:bztt-mb-8 bztt-sticky bztt-top-0 bztt-z-10 bztt-py-3 bztt-bg-[#F9F9F9]"
  >
    <div
      class="bztt-text-lg md:bztt-text-2xl bztt-font-semibold bztt-text-[#1F1F1F]"
    >
      {{ ('bookings.detail.' + titleTranslation) | translate }}
    </div>

    <div
      class="bztt-flex bztt-justify-center bztt-items-center bztt-gap-2 bztt-rounded-full bztt-text-[#6B6060] bztt-border bztt-border-[#6B6060] bztt-px-3 bztt-py-1 bztt-cursor-pointer bztt-text-sm"
      (click)="openBookingEdit.emit()"
    >
      <mat-icon class="material-icons-outlined">edit</mat-icon>
      <span class="bztt-leading-none">
        {{ 'bookings.detail.edit' | translate }}
      </span>
    </div>
  </div>
  <div
    class="bztt-text-sm md:bztt-text-base bztt-font-semibold bztt-text-[#1F1F1F] bztt-mb-3"
  >
    {{ 'bookings.detail.eventDetails' | translate }}
  </div>
  <div
    class="bztt-w-full bztt-flex bztt-justify-between bztt-text-sm bztt-text-[#6B6060] bztt-font-medium bztt-mb-3"
  >
    <span>{{ 'bookings.detail.date' | translate }}</span>
    <div>
      <span [ngSwitch]="!detailData.eventDate">
        <ng-container *ngSwitchCase="true">{{
          flexible ? flexible : ('bookings.list.unknownEventDate' | translate)
        }}</ng-container>
        <ng-container *ngSwitchDefault
          >{{ detailData.eventDate | date : 'MMM d, y' : 'UTC' }}
          <ng-container *ngIf="flexible"
            >({{ flexible }})</ng-container
          ></ng-container
        >
        <!-- <ng-container *ngIf="!isSameDay() && isTimeScheduled()">
          {{ detailData.eventDate | date : 'shortTime' : 'UTC' : locale }}
          <ng-container *ngIf="flexible"
            >({{ flexible }})</ng-container
          ></ng-container
        > -->
      </span>
      <!-- <span>
        <ng-container *ngIf="isSameDay() && isTimeScheduled()">
          {{ detailData.eventDate | date : 'shortTime' : 'UTC' : locale }} -
          {{ detailData.eventTillDate | date : 'shortTime' : 'UTC' : locale }}
        </ng-container>
        <ng-container *ngIf="!isSameDay()">
          {{ detailData.eventTillDate | date : 'yyyy-MM-dd' : 'UTC' }}
          <ng-container *ngIf="isTimeScheduled()">
            {{ detailData.eventTillDate | date : 'shortTime' : 'UTC' : locale }}
          </ng-container>
        </ng-container>
      </span> -->
    </div>
  </div>
  <div
    *ngIf="
      !bookingsUtil.allDayEvent(detailData.eventDate, detailData.eventTillDate)
    "
    class="bztt-w-full bztt-flex bztt-justify-between bztt-text-sm bztt-text-[#6B6060] bztt-font-medium bztt-mb-3"
  >
    <span>{{ 'bookings.detail.time' | translate }}</span>
    <div>
      {{ detailData.eventDate | date : 'h:mm a' : 'UTC' : locale
      }}<!--: 'UTC' : locale-->
      -
      {{ detailData.eventTillDate | date : 'h:mm a' : 'UTC' : locale }}
    </div>
  </div>

  <div
    class="bztt-w-full bztt-flex bztt-justify-between bztt-text-sm bztt-text-[#6B6060] bztt-font-medium bztt-mb-3"
  >
    <span>{{ 'bookings.detail.guestCount' | translate }}</span>
    <div>{{ detailData.options.guestNo }}</div>
  </div>
  <div
    class="bztt-mt-4 md:bztt-mt-8 bztt-text-sm md:bztt-text-base bztt-font-semibold bztt-text-[#1F1F1F]"
  >
    {{ 'bookings.detail.selectedServices' | translate }}
  </div>

  <mat-accordion
    *ngFor="
      let item of bookingDetailsService.servicesEditData
        | arrayFilter : filterFunc
        | sortToEnd : 'key' : 'serviceCharge'
    "
    class="bztt-w-full"
    togglePosition="before"
    displayMode="flat"
  >
    <mat-expansion-panel
      class="mat-elevation-z0 bztt-bg-transparent bztt-text-sm bztt-border-b bztt-border-[#DCDCDC] bztt-text-[#6B6060]"
    >
      <mat-expansion-panel-header
        class="services-list bztt-h-12 bztt-p-1 hover:bztt-bg-transparent bztt-text-sm md:bztt-text-base"
      >
        <mat-panel-title class="bztt-mr-0">
          <div
            class="bztt-w-full bztt-flex bztt-justify-between bztt-text-sm md:bztt-text-base bztt-text-[#6B6060]"
          >
            <span class="bztt-text-sm md:bztt-text-base">{{
              item.name | translate
            }}</span>
            <span class="bztt-text-sm md:bztt-text-base">
              <div [ngSwitch]="item.serviceVariant?.type">
                <ng-container *ngSwitchCase="ServiceTypes.serviceCharge">
                  {{
                    detailData.serviceCharge ?? 0
                      | currency : currencyCode : 'symbol' : null : locale
                  }}
                </ng-container>
                <!-- <ng-container *ngSwitchCase="ServiceTypes.globalMinimalBudget">
                  {{
                    item.serviceVariant.amount
                      | currency : currencyCode : 'symbol' : null : locale
                  }}
                </ng-container> -->
                <ng-container *ngSwitchDefault>
                  {{
                    item.amount
                      | currency : currencyCode : 'symbol' : null : locale
                  }}
                </ng-container>
              </div>
            </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span class="bztt-text-sm md:bztt-text-base bztt-whitespace-pre-line">        
        <jarvis-ui-package-description
          *ngIf="item.serviceVariant"
          [serviceVariant]="item.serviceVariant"
          [customDescription]="item.description"
          [locale]="locale"
          [currencyCode]="currencyCode"
          [date]="detailData.eventDate"
          [showPricePerUnit]="true"
          [overideMinimalBudget]="item.savedMinimalBudget"
        ></jarvis-ui-package-description>
        <ng-container *ngIf="!item.serviceVariant">
          <p>{{ item.description | translate }}.</p>
          <p *ngIf="item.unit == 'currency_per_person'">
            {{ 'bookings.detail.guests' | translate }}: {{ item.qnt }}.
          </p>
        </ng-container>
      </span>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="bztt-flex bztt-flex-col bztt-gap-4 bztt-pt-4 bztt-w-full">
    <ng-container
      *ngTemplateOutlet="
        total;
        context: {
          title: 'price.price',
          value:
            (detailData.price ? detailData.price : 0) -
            (detailData.tax ? detailData.tax : 0)
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        total;
        context: {
          title: 'price.tax',
          value: detailData.tax ?? 0,
          hidden: !facts().hasTaxes
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        total;
        context: {
          title: 'price.discount',
          value: detailData.discount ?? 0,
          hidden: (detailData.discount ?? 0) == 0
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        total;
        context: { title: 'price.deposit', value: detailData.deposit ?? 0 }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        total;
        context: {
          title: 'price.paid',
          value: detailData.paid + (detailData.paidCash ?? 0)
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        total;
        context: {
          title: 'balance',
          value:
            (detailData.price ?? 0) -
            detailData.discount -
            detailData.paid -
            (detailData.paidCash ?? 0),
          class: 'bztt-mt-4 bztt-font-bold'
        }
      "
    ></ng-container>
  </div>
</div>

<ng-template
  #total
  let-title="title"
  let-value="value"
  let-hidden="hidden"
  let-class="class"
>
  <div
    *ngIf="!hidden"
    [ngClass]="class"
    class="bztt-w-full bztt-flex bztt-justify-between bztt-text-sm md:bztt-text-base bztt-text-[#6B6060]"
  >
    <span>{{ 'bookings.detail.' + title | translate }}</span>
    <span>{{
      value | currency : currencyCode : 'symbol' : null : locale
    }}</span>
  </div>
</ng-template>
