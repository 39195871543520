import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ReviewsInviteInfo, ReviewsService } from '../services/reviews.service';

@Injectable({ providedIn: 'root' })
export class GetBookingReviewsResolver implements Resolve<ReviewsInviteInfo> {
    constructor(
        private reviewsService: ReviewsService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const bookingId = route.params.id;
        if (!bookingId) {
            return of(null);
        }
        return this.reviewsService.getBooking(bookingId);
    }
}
