import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { JarvisFileUploaderComponent } from './file-uploader.component';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        TranslateModule
    ],
    exports: [
        JarvisFileUploaderComponent
    ],
    declarations: [
        JarvisFileUploaderComponent
    ],
    providers: [],
})
export class JarvisFileUploaderModule { }
