import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'jarvis-ui-default-modal',
  templateUrl: './default-modal.component.html',
  styleUrls: ['./default-modal.component.scss']
})
export class DefaultModalComponent implements OnInit {

  actions = [
    {
      displayValue: 'Save',
      color: 'accent',
      callback: () => {}
    },
    {
      displayValue: 'Cancel',
      color: '',
      callback: () => {}
    }
  ];

  outlet$: BehaviorSubject<ComponentPortal<unknown> | TemplatePortal>;

  constructor(
    private dialogRef: MatDialogRef<DefaultModalComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit(): void {
    this.outlet$ = this.dialogData.outlet$;
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }

}
