<mat-form-field appearance="outline" floatLabel="never" class="time-picker">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #timePicker
    [panelClass]="isMobile ? 'mobile-time-picker-panel' : 'time-picker-panel'"
    [(ngModel)]="value"
    (selectionChange)="doSelection($event)"
    (openedChange)="openedChange($event)"
  >
    <div
      class="d-md-none d-flex justify-content-end pe-2 close-wrapper"
      (click)="close()"
    >
      <span>{{ 'Close' }}</span>
      <mat-icon>close</mat-icon>
    </div>
    <mat-option [value]="timeunit.tm" *ngFor="let timeunit of time"
      >{{ timeunit.name }}
    </mat-option>
  </mat-select>
  <mat-icon matSuffix>query_builder</mat-icon>
</mat-form-field>
