import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASE_URL } from '@jarvis/services';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationDataService {

  constructor(
    private httpService: HttpClient,
    @Inject(BASE_URL) private baseUrl: string
  ) { }

  async getConfiguration(type) {
    return await this.httpService.get<any>(`${this.baseUrl}/marketplace/configuration?type=` + type).toPromise();
  }
}
