import { ElementRef, Injectable, Type } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { filter, map, take } from 'rxjs/operators';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { NavigationEnd, Router } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';

const MOBILE_WIDTH = 812;

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    isMobile: boolean;
    isMobileObserver: Observable<boolean>;
    windowResize = fromEvent(window, 'resize');

    private mobileBreakpointObserver = this.breakpointObserver.observe([`(max-width: ${MOBILE_WIDTH}px)`]);

    constructor(
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private overlay: Overlay
    ) {
        this.isMobileObserver = this.mobileBreakpointObserver.pipe(
            map(state => state.matches)
        );

        this.mobileBreakpointObserver.subscribe(state => {
            this.isMobile = state.matches ? true : false;
        });
    }

    createOverlay(component: Type<any>, connectedTo: ElementRef): void {
        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy: this.overlay.position()
                .flexibleConnectedTo(connectedTo)
                .withPositions([{
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                }])
                .withDefaultOffsetY(5)
        });

        const componentPortal = new ComponentPortal(component);

        merge(
            overlayRef.backdropClick(),
            overlayRef.detachments(),
            this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        ).pipe(take(1)).subscribe(() => {
            overlayRef.detach();
        });

        overlayRef.attach(componentPortal);
    }

    createMobileMenuOverlay(component: Type<any>): void {
        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy: this.overlay.position().global()
        });

        const componentPortal = new ComponentPortal(component);

        merge(
            overlayRef.backdropClick(),
            overlayRef.detachments(),
            this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        ).pipe(take(1)).subscribe(() => {
            overlayRef.detach();
        });
        const componentRef = overlayRef.attach(componentPortal);
        const closeEventSubject = componentRef.instance.close$;

        if (closeEventSubject && closeEventSubject instanceof Subject) {
            closeEventSubject.pipe(
                take(1)
            ).subscribe(() => {
                overlayRef.detach();
            });
        }
    }
}
