import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { IntroTutorialComponent } from '@jarvis/foxy-vendor/app/site/profile/intro-tutorial/intro-tutorial.component';
import { ModalService } from '../services/modal.service';

@Injectable({providedIn: 'root'})
export class VideoPresentationGuard implements CanActivate {
    constructor(private modalService: ModalService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.modalService.openDialog(IntroTutorialComponent);
        return false;
    }
}
