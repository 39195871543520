import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RulesPoliciesComponent } from '../rules-policies/rules-policies.component';

@Component({
  selector: 'app-terms-conditions-modal',
  templateUrl: './terms-conditions-modal.component.html',
  styleUrls: ['./terms-conditions-modal.component.scss']
})
export class TermsConditionsModalComponent implements OnInit {

  @ViewChild(RulesPoliciesComponent, { static: true }) rulesPoliciesCmp: RulesPoliciesComponent;

  constructor(
    private modalRef: MatDialogRef<TermsConditionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit(): void {
    const buttonToOpen = this.dialogData.activePage;
    // console.log(buttonToOpen);
    if (buttonToOpen) {
      const buttonIndex = this.rulesPoliciesCmp.buttons.findIndex(x => x.value === buttonToOpen);
      if (buttonIndex !== -1) {
        this.rulesPoliciesCmp.activeButton = this.rulesPoliciesCmp.buttons[buttonIndex];
      }
    }
  }

  onClose(): void {
    this.modalRef.close();
  }

}
