
import { Component, inject, Inject, OnDestroy, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LayoutService } from '@jarvis/ui';
import { Subject } from 'rxjs';
import { DOMAIN_COUNTRY, JarvisTrackingService } from '@jarvis/services';

@Component({
  selector: 'jarvis-homepage-menu-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss'],
})
export class HomepageMenuServiceListComponent implements OnDestroy {
  private trackService = inject(JarvisTrackingService);

  iconData = {
    new: '/assets/icons/icon-types/uplifted/ic_new.svg',
    venues: '/assets/icons/icon-types/uplifted/ic_venues.svg',
    ceremony_venue: '/assets/icons/icon-types/ic_ceremony.svg',
    catering: '/assets/icons/icon-types/ic_food.svg',
    ceremony_regale: '/assets/icons/icon-types/ic_regale.svg',
    bartenders: '/assets/icons/icon-types/ic_bartender.svg',
    foodtruck: '/assets/icons/icon-types/ic_food_cart.svg',
    videographer: '/assets/icons/icon-types/ic_video.svg',
    photographer: '/assets/icons/icon-types/ic_photo.svg',
    live_music: '/assets/icons/icon-types/ic_music.svg',
    planning: '/assets/icons/icon-types/ic_planning.svg',
    host: '/assets/icons/icon-types/ic_microphone.svg',
    cakes_desserts: '/assets/icons/icon-types/ic_cake.svg',
    makeup_hairdressing: '/assets/icons/icon-types/ic_makeup.svg',
    dj: '/assets/icons/icon-types/ic_dj.svg',
    bride_clothing: '/assets/icons/icon-types/ic_dress.svg',
    groom_clothing: '/assets/icons/icon-types/ic_groom.svg',
    secondary_dress: '/assets/icons/icon-types/ic_market.svg',
    transport: '/assets/icons/icon-types/ic_transport.svg',
    decor: '/assets/icons/icon-types/ic_decorations.svg',
    invitations_printing: '/assets/icons/icon-types/ic_invitation.svg',
    decor_floristics: '/assets/icons/icon-types/ic_flowers.svg',
    pavilion_furniture: '/assets/icons/icon-types/ic_pavilion.svg',
    sound_lights: '/assets/icons/icon-types/ic_effects.svg',
    screens_projectors: '/assets/icons/icon-types/ic_screens.svg',
    entertainment: '/assets/icons/icon-types/ic_party.svg',
    show: '/assets/icons/icon-types/ic_show.svg',
    training: '/assets/icons/icon-types/uplifted/ic_training.svg',
    jewelery: '/assets/icons/icon-types/ic_rings.svg',
    presents: '/assets/icons/icon-types/ic_present.svg',
    honeymoon: '/assets/icons/icon-types/ic_honeymoon.svg',
    photo_booth: '/assets/icons/icon-types/ic_photobooth.svg',
  };

  categories;
  allCategories = [
    {
      label: 'listing.selectCategory.findYourProvider',
      items: [
        { title: 'new', iconName: 'new' }, //temp_redirrect: 'photographer' },
        { title: 'venues', iconName: 'venues' }, //temp_redirrect: 'photographer' },
        { title: 'planning', iconName: 'planning' }, //temp_redirrect: 'photographer' },
        {
          title: 'ceremony_venue',
          iconName: 'ceremony_venue',
          hiddenForCountries: ['US'],
        },
        {
          title: 'ceremony_regale',
          iconName: 'ceremony_regale',
          hiddenForCountries: ['US'],
        },
        {
          title: 'foodtruck',
          iconName: 'foodtruck',
          hiddenForCountries: ['US'],
        },
        { title: 'catering', iconName: 'catering', hiddenForCountries: ['US'] }, //, temp_redirrect: 'photographer' },
      ],
    },
    {
      label: '',
      items: [
        { title: 'photographer', iconName: 'photographer' },
        { title: 'videographer', iconName: 'videographer' }, //temp_redirrect: 'photographer' },
        { title: 'host', iconName: 'host', hiddenForCountries: ['US'] }, //, temp_redirrect: 'photographer' },
        {
          title: 'makeup_hairdressing',
          iconName: 'makeup_hairdressing',
          hiddenForCountries: ['US'],
        },
        // { title: 'bride_clothing', iconName: 'bride_clothing' },
        // { title: 'groom_clothing', iconName: 'groom_clothing' },
        { title: 'live_music', iconName: 'live_music' }, //, temp_redirrect: 'photographer' },
        {
          title: 'sound_lights',
          iconName: 'sound_lights',
          hiddenForCountries: ['US'],
        }, //, temp_redirrect: 'photographer' },
        {
          title: 'screens_projectors',
          iconName: 'screens_projectors',
          hiddenForCountries: ['US'],
        },
      ],
    },
    {
      label: '',
      items: [
        {
          title: 'secondary_dress',
          iconName: 'secondary_dress',
          hiddenForCountries: ['US'],
        },
        {
          title: 'transport',
          iconName: 'transport',
          hiddenForCountries: ['US'],
        },
        { title: 'decor', iconName: 'decor', hiddenForCountries: ['US'] },
        { title: 'dj', iconName: 'dj' }, //temp_redirrect: 'photographer' },
        { title: 'decor_floristics', iconName: 'decor_floristics' }, //temp_redirrect: 'photographer' },
        {
          title: 'photo_booth',
          iconName: 'photo_booth',
          hiddenForCountries: ['US'],
        },
        // { title: 'invitations_printing', iconName: 'invitations_press' },
        {
          title: 'pavilion_furniture',
          iconName: 'pavilion_furniture',
          hiddenForCountries: ['US'],
        },
      ],
    },
    {
      label: '',
      items: [
        {
          title: 'bartenders',
          iconName: 'bartenders',
          hiddenForCountries: ['US'],
        }, //, temp_redirrect: 'photographer' },
        {
          title: 'cakes_desserts',
          iconName: 'cakes_desserts',
          hiddenForCountries: ['US'],
        }, //, temp_redirrect: 'photographer' },
        { title: 'show', iconName: 'show', hiddenForCountries: ['US'] },
        { title: 'training', iconName: 'training', hiddenForCountries: ['US'] },
        {
          title: 'entertainment',
          iconName: 'entertainment',
          hiddenForCountries: ['US'],
        },
        { title: 'jewelery', iconName: 'jewelery', hiddenForCountries: ['US'] },
        {
          title: 'presents',
          iconName: 'presents',
          hiddenForCountries: ['US', 'LT'],
        },
        {
          title: 'honeymoon',
          iconName: 'honeymoon',
          hiddenForCountries: ['US', 'LT'],
        },
      ],
    },
  ];

  isMobile$ = this.layoutService.isMobileObserver;

  close$ = new Subject<void>();

  constructor(
    private layoutService: LayoutService,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string,
    @Optional()
    private dialogRef: MatDialogRef<HomepageMenuServiceListComponent>
  ) {
    this.categories = this.allCategories.map((itm) => ({
      label: itm.label,
      items: itm.items.filter(
        (v) =>
          !v.hiddenForCountries ||
          !v.hiddenForCountries.includes(domainCountry.toUpperCase())
      ),
    }));
  }

  ngOnDestroy(): void {
    this.close$.complete();
  }

  trackCategoryClick(category) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'services_dropdown_click',
      data: { service_selected: category },
    });
  }

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      return;
    }
    this.close$.next();
  }
}
