import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { CommonModule, getCurrencySymbol } from '@angular/common';
import { AdvertsPriceUnits, ServiceTypes } from '@jarvis/services';
import { TranslateModule } from '@ngx-translate/core';
import { DFormActionService } from '@jarvis/ui/src/lib/components/d-form/d-form.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { ServiceDescriptionsPipe } from 'libs/saved-bookings/src/lib/pipes/service-descriptions.pipe';
import {
  ArrayContainsValuesPipe,
  ContainsValuesPipe,
  GroupValuesPipe,
} from '@jarvis/utils';
import { MatInputModule } from '@angular/material/input';
import {
  ButtonModule,
  JarvisModalComponent,
  JarvisUiModalWrapperModule,
  PackageDescriptionComponent,
} from '@jarvis/ui';
import { BookingDetailsEditType } from 'libs/saved-bookings/src/lib/bookings.types';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  GroupByKeyPipe,
  SelectedServicesTotalPricePipe,
  SortToEndPipe,
  SumOfKeyValuesPipe,
} from 'libs/saved-bookings/src/lib/pipes/shared-pipes.pipe';
import { BookingDetailsService } from 'libs/saved-bookings/src/lib/booking-details.service';
import { PredefinedTypePipe } from 'libs/saved-bookings/src/lib/pipes/predefined-types.pipe';

@Component({
  selector: 'jarvis-booking-edit-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    ServiceDescriptionsPipe,
    ContainsValuesPipe,
    ArrayContainsValuesPipe,
    GroupValuesPipe,
    FormsModule,
    TextFieldModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonModule,
    MatSlideToggleModule,
    SumOfKeyValuesPipe,
    GroupByKeyPipe,
    JarvisModalComponent,
    JarvisUiModalWrapperModule,
    PackageDescriptionComponent,
    SortToEndPipe,
    SelectedServicesTotalPricePipe,
    PredefinedTypePipe,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
  ],
})
export class BookingEditServicesListComponent implements OnInit {
  @Output() hideMainDialog = new EventEmitter<boolean>();
  bookingDetailsService = inject(BookingDetailsService);

  ServiceTypes = ServiceTypes;

  get booking() {
    return this.bookingDetailsService.detailData;
  }

  get locale() {
    return this.bookingDetailsService.locale;
  }

  get eventType() {
    return this.bookingDetailsService.getServiceType();
  }

  get currencyCode() {
    return this.bookingDetailsService.currencyCode;
  }

  get bookingDetailEdit() {
    return this.bookingDetailsService.servicesEditData;
  }

  set bookingDetailEdit(value) {
    this.bookingDetailsService.servicesEditData = value;
  }

  customService: BookingDetailsEditType = {
    name: null,
    qnt: 1,
    additionalQnt: null,
    price: 0,
    hash: 'custom',
    isMain: false,
    description: null,
    isCustomPrice: true,
    unit: AdvertsPriceUnits.fixedFee,
    maxQuantity: -1,
    minQuantity: -1,
    minimalBudget: 0,
    isIncluded: true,
    amount: 0,
    serviceCharge: 0,
    tax: 0,
  };

  actionService: DFormActionService = new DFormActionService();

  groupEditKey: any = null;

  rollBackData: any[] = [];

  constructor() {}

  ngOnInit(): void {}

  clearGroupEditKey() {
    return this.setGroupEditKey();
  }

  setGroupEditKey(key: any = null) {
    //    console.log(key);
    //   if (key === null) this.bookingDetailsService.saveServiceEditChanges();
    this.groupEditKey = key;

    if (!key) {
      //this.bookingDetailsService.detailData = {}; //{...this.rollBackData};
      if (this.rollBackData.length)
        this.bookingDetailsService.servicesEditData = this.rollBackData.map(
          (object) => ({ ...object })
        );
    } else {
      this.rollBackData = this.bookingDetailsService.servicesEditData.map(
        (object) => ({ ...object })
      );
    }

    this.hideMainDialog.emit(key ? true : false);
  }

  private getTextContentElement(id: string): HTMLElement {
    return document
      .getElementById('srv-' + id)
      .querySelector('.text-content') as HTMLElement;
  }

  toggleReadMore(id: string) {
    this.getTextContentElement(id).classList.toggle('line-clamp');
  }

  getCurrencySymbol(item?: any) {
    if (item && item?.priceUnit === AdvertsPriceUnits.fixedPercentage) {
      return '%';
    }
    return getCurrencySymbol(this.currencyCode, 'wide');
  }

  isMoreNeeded(id: string) {
    const element = this.getTextContentElement(id);

    return (
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth
    );
  }

  isLessNeeded(id: string) {
    return !this.getTextContentElement(id).classList.contains('line-clamp');
  }

  setCustomPrice(item: BookingDetailsEditType) {
    if (item.serviceVariant?.type === ServiceTypes.serviceCharge) {
      this.bookingDetailEdit.forEach((detail) => {
        if (detail.serviceVariant) {
          detail.serviceVariant.serviceCharge = item.amount;
        }
      });

      item.price = item.amount;
      this.bookingDetailsService.updateMinimumBudget();

      return;
    }

    // if (item.serviceVariant?.type === ServiceTypes.globalMinimalBudget) {
    //   item.savedMinimalBudget = item.amount;
    //   item.price = item.amount;

    //   return;
    // }

    item.price = item.amount / (item.qnt ? item.qnt : 1);
    item.price = Math.round(item.price * 100) / 100;
    item.isCustomPrice = true;
    if (!item.isIncluded) this.addService(item);
    this.bookingDetailsService.updateMinimumBudget();
  }

  removeService(item: BookingDetailsEditType) {
    /* if (item.serviceVariant?.type === ServiceTypes.serviceCharge) {
      return;
    } */
    item.qnt = 0;
    if (item.serviceVariant) {
      item.serviceVariant.quantity = 0;
    }
    item.amount = null; //item.price;
    item.isIncluded = false;
    // this.reloadData();
    this.bookingDetailsService.updateMinimumBudget();
  }

  private reloadData() {
    this.bookingDetailEdit = [...this.bookingDetailEdit];
    this.bookingDetailsService.updateMinimumBudget();
  }

  qntChange(item: BookingDetailsEditType, direction: number) {
    item.qnt = Number(item.qnt ?? 0) + Number(direction);
    if (item.qnt < 1) {
      item.serviceVariant.quantity = 0;
      item.qnt = 0;
      item.isIncluded = false;
      item.amount = item.price;
    } else {
      if (item?.serviceVariant?.quantityDisabled) item.qnt = 1;

      if (item.serviceVariant) item.serviceVariant['_quantity'] = item.qnt;
      item.serviceVariant.customPrice = null;
      item.amount =
        Math.round(
          item.serviceVariant.getFinalPrice(this.booking.eventDate) * 100
        ) * 0.01;
      item.price = item.amount / (item.qnt ? item.qnt : 1);
    }

    this.bookingDetailsService.updateMinimumBudget();
  }

  additionalQntChange(item: BookingDetailsEditType, direction: number) {
    item.additionalQnt = Number(item.additionalQnt ?? 0) + Number(direction);

    if (item.additionalQnt < item.serviceVariant.minAdditionalQuantity) {
      item.serviceVariant.additionalQuantity =
        item.serviceVariant.minAdditionalQuantity;
      item.additionalQnt = item.serviceVariant.minAdditionalQuantity;
    } else {
      if (item.serviceVariant)
        item.serviceVariant.additionalQuantity = item.additionalQnt;
    }

    this.qntChange(item, 0);
  }

  addCustomService() {
    this.bookingDetailEdit.push({
      ...this.customService,
      key: 'bookings.detail.customServices',
      price:
        Math.round((this.customService.amount / this.customService.qnt) * 100) /
        100,
    });

    this.customService = {
      name: null,
      qnt: 1,
      additionalQnt: null,
      price: 0,
      hash: 'custom',
      isMain: false,
      description: null,
      isCustomPrice: true,
      unit: AdvertsPriceUnits.fixedFee,
      maxQuantity: -1,
      minQuantity: -1,
      minimalBudget: 0,
      isIncluded: true,
      amount: 0,
      serviceCharge: 0,
      tax: 0,
    };

    this.rollBackData = [];
    this.reloadData();
    this.bookingDetailsService.saveServiceEditChanges();
    this.setGroupEditKey(null);
  }

  addService(item: BookingDetailsEditType) {
    if (item.qnt == 0) item.qnt = 1;
    item.isIncluded = true;
    item.amount = item.price * item.qnt;
    if (item.serviceVariant) item.serviceVariant.quantity = item.qnt;
    if (item.serviceVariant?.type === ServiceTypes.globalMinimalBudget) {
      item.savedMinimalBudget =
        item.savedMinimalBudget || item.serviceVariant.defaultPrice;
      this.reloadData();
    }
    this.bookingDetailsService.updateMinimumBudget();
  }

  canAddCustomService(): boolean {
    return (
      this.customService.name &&
      this.customService.amount > 0 &&
      this.customService.qnt > 0
    );
  }

  saveEditDialog() {
    this.rollBackData = [];
    this.setGroupEditKey(null);
    this.reloadData();
    this.bookingDetailsService.saveServiceEditChanges();
  }

  handleItemEdit(item: any) {
    const SERVICES_TO_EXPAND: string[] = [
      // ServiceTypes.venueStandalone,
      ServiceTypes.venueAccomodation,
      ServiceTypes.serviceCharge,
      ServiceTypes.globalMinimalBudget,
      ServiceTypes.venueOvertime,
    ];

    item.edit = !item.edit;
    item.edit && !item.description
      ? (item.description =
          (item.serviceVariant.description ||
            item.serviceVariant.originalService.additionalDescription) &&
          !item.serviceVariant.description.includes(
            'booking.defaultDescriptions'
          )
            ? item.serviceVariant.description ||
              item.serviceVariant.originalService.additionalDescription
            : '')
      : null;
  }
}

// interface BookingDetailsEditType extends BookingDetailsType {
//   amount: number;
//   isIncluded: boolean;
//   isMain: boolean;
//   tax?: number;
//   serviceCharge?: number;
// }
