import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'a',
  standalone: true,
})
export class ALinkNoreferrerDirective {
  constructor(public ref: ElementRef) {}

  ngAfterViewInit() {
    const link = this.ref.nativeElement;
    
    if (link.hostname === window.location.hostname) {
      return;
    }


    link.relList.add('noreferrer');
  }
}
