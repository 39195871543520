<div class="close-container">
  <button class="close-button" (click)="close()">
    <a>Close</a>
    <mat-icon>clear</mat-icon>
  </button>
</div>

<div class="selector-wrapper">
  <ng-container [ngTemplateOutlet]="datePicker"></ng-container>
</div>

<ng-template #datePicker>
  <div
    class="calendar-container mat-elevation-z4 p-2 pt-3 bztt-w-full bztt-h-full bztt-flex bztt-justify-center"
  >
    <jarvis-ui-small-calendar
      class="bztt-flex bztt-flex-col bztt-justify-center"
      [events]="[]"
      [date]="homepageService.eventDateControl.value"
      (daySelected)="handleWeddingDateSelect($event)"
    ></jarvis-ui-small-calendar>
  </div>
</ng-template>
