<div
  class="bztt-text-xl bztt-font-semibold bztt-mb-4 bztt-text-start bztt-absolute bztt-top-0 bztt-z-10"
>
  Send proposal
</div>
<div></div>
<div>
  <div class="bztt-font-semibold bztt-mb-4 bztt-text-start bztt-pt-4">
    To: &nbsp;&nbsp; {{ detailData?.customer?.name }}
    {{ detailData?.customer?.surname }}
  </div>

  <div class="bztt-relative">
    <div class="bztt-z-10 bztt-absolute bztt-top-0 bztt-left-0 bztt-items-center bztt-bg-[#ffffff8f] bztt-w-full bztt-h-full bztt-flex bztt-flex-col bztt-justify-center" *ngIf="processed | async">
        <mat-progress-spinner  mode="indeterminate" strokeWidth="2"></mat-progress-spinner>    
    </div>      
    <mat-form-field appearance="outline" floatLabel="never" class="note w-100">
      <textarea
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="10"
        cdkAutosizeMaxRows="15"
        placeholder="Message"
        [formControl]="proposalForm"
        type="text"
        matInput
      ></textarea>
    </mat-form-field>
    <div
      class="bztt-absolute bztt-bottom-8 bztt-flex bztt-justify-between bztt-items-start bztt-w-full bztt-left-0 bztt-px-4 bztt-text-sm md:bztt-text-base bztt-gap-3"
    >
      <span class="bztt-text-start">
        To view your proposal, please click here:
      </span>

      <a
        [href]="getProposalLink(detailData._id, '/preview')"
        target="_blank"
        class="bztt-flex bztt-justify-start bztt-items-center bztt-gap-2 bztt-border-0 bztt-p-0"
        ><span
          class="bztt-text-sm bztt-underline bztt-font-semibold bztt-underline-offset-4 bztt-text-nowrap"
          >View proposal</span
        ></a
      >
    </div>
  </div>

  <div class="bztt-flex bztt-justify-between bztt-items-start bztt-py-5">
    <div>
      <div
        *ngIf="files?.length"
        class="bztt-flex bztt-flex-col bztt-justify-start bztt-items-start bztt-pb-4"
      >
        <div class="bztt-text-sm bztt-font-semibold">Attachments:</div>
        <div
          *ngFor="let file of files; let i = index"
          class="bztt-flex bztt-justify-start bztt-items-center bztt-gap-2"
        >
          <span class="bztt-text-sm bztt-font-normal bztt-text-start">{{
            file.name
          }}</span>
          <mat-icon class="clear bztt-cursor-pointer" (click)="removeFile(i)"
            >clear</mat-icon
          >
        </div>
      </div>
    </div>

    <div
      class="bztt-flex bztt-justify-start bztt-items-center bztt-cursor-pointer bztt-gap-1 bztt-pr-4"
      (click)="fileInput.click()"
    >
    <!-- (click)="uploader.clickUpload()" -->
    
      <!-- <mat-icon *ngIf="!uploader.filesUploading">attach_file</mat-icon>
      <mat-spinner
        *ngIf="uploader.filesUploading"
        diameter="20"
        color="accent"
        class="bztt-mx-2"
      ></mat-spinner>-->
      <span 
        class="bztt-text-sm bztt-underline bztt-font-semibold bztt-underline-offset-4"
        >Attach</span
      >
    </div>
    <!-- <jarvis-ui-file-uploader
      #uploader
      [accept]="'.pdf'"
      (onAction)="fileUploaderAction($event)"
      class="d-none"
    ></jarvis-ui-file-uploader> -->

    <input
      #fileInput
      hidden
      multiple
      type="file"
      (change)="onFileChange($event)"
    />    
  </div>
</div>

<div
  class="bztt-flex bztt-flex-col md:bztt-flex-row bztt-justify-between bztt-items-center bztt-gap-5"
>
  <div class="bztt-text-sm bztt-font-semibold bztt-mb-4 bztt-text-start">
    The proposal will be automatically included in the message and sent to the customer's inbox.
  </div>
  <button
    mat-raised-button
    type="submit"
    app-button
    color="accent"
    class="bztt-w-full md:bztt-w-fit bztt-px-8"
    (click)="sendProposal()"
  >
    Send
  </button>
</div>
