
import { UpliftedHomepageCalendarComponent } from './../homepage-calendar/homepage-calendar.component';
import { HomepageUpliftedService } from './../../services/homepage-uplifted.service';
import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  inject,
  Injector,
  StaticProvider,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import { JarvisUiModalService, LayoutService } from '@jarvis/ui';
import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
} from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { HOMEPAGE_CATEGORIES } from '../../services/homepage-uplifted.constants';
import {
  GOOGLE_LOCATION_FILTER_FORM_CONTROL,
  GOOGLE_LOCATION_FILTER_OVERLAY_REF,
  LOCATION_FILTER_CONFIGURATION,
} from '@jarvis/ui/google-location-dropdown';
import { UiGoogleLocationDropdownComponent } from '@jarvis/ui/google-location-dropdown';
import { JarvisTrackingService } from '@jarvis/services';

const QUICK_LINKS_US = [
  {
    category: 'venues',
    iconName: 'location_on',
    link: 'venues',
  },
  {
    category: 'photographer',
    iconName: 'photo_camera',
    link: 'photographers',
  },
  {
    category: 'dj',
    iconName: 'headphones',
    link: 'djs',
  },
  {
    category: 'live_music',
    iconName: 'music_note',
    link: 'live-music',
  },
  {
    category: 'planning',
    iconName: 'checklist',
    link: 'planners',
  },
];

const QUICK_LINKS_LT = [
  {
    name: 'categoriesMarketplace.venues',
    icon: 'ic_venues.svg',
    link: '/sventes-vietos',
  },
  {
    name: 'categoriesMarketplace.photographer',
    icon: 'ic_photographers.svg',
    link: '/fotografai',
  },
  {
    name: 'categoriesMarketplace.videographer',
    icon: 'ic_video.svg',
    link: '/videografai',
  },
  {
    name: 'categoriesMarketplace.host',
    icon: 'ic_microphone.svg',
    link: '/vedejai',
  },
  {
    name: 'categoriesMarketplace.live_music',
    icon: 'ic_live_music.svg',
    link: '/gyva-muzika',
  },
];

@Component({
  selector: 'jarvis-event-date-selector',
  templateUrl: './event-date-selector.component.html',
  styleUrls: ['./event-date-selector.component.scss'],
})
export class EventDateSelectorComponent {
  @ViewChild('smallCalendarOverlay') smallCalendarOverlay: TemplateRef<any>;
  @ViewChild('dateAnchor', { read: ElementRef }) dateAnchor: ElementRef;
  @ViewChild('dateAnchor2', { read: ElementRef }) dateAnchor2: ElementRef;
  @ViewChild('overlayOrigin') overlayOrigin: ElementRef<HTMLDivElement>;

  @Input() marketplaceLink;
  @Input() position: 'location_hero' | 'location_bottom';
  private closeCalendar$ = new Subject<void>();

  QUICK_LINKS_US = QUICK_LINKS_US;
  window = window;

  today = new Date();

  domainCountry = inject(DOMAIN_COUNTRY);
  private trackService = inject(JarvisTrackingService);

  quickLinks: any[];

  constructor(
    public homepageService: HomepageUpliftedService,
    private vcr: ViewContainerRef,
    private modalService: JarvisUiModalService,
    private layoutService: LayoutService,
    private overlay: Overlay,
    private injector: Injector
  ) {
    this.quickLinks = HOMEPAGE_CATEGORIES[this.domainCountry];
  }

  handleTrackClick(event: string, data): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: event,
      data,
    });
  }

  handleWeddingDateSelect(date) {
    this.handleTrackClick('date_selected', { location: this.position, date });

    this.homepageService.eventDateControl.setValue(date);
    this.closeCalendar$.next();
  }

  resetForms() {
    this.homepageService.eventDateControl.reset();
    this.homepageService.eventLocationControl.reset();
  }

  openDatePicker(top = false): void {
    this.handleTrackClick('date_click', {
      location: this.position,
    });

    if (this.layoutService.isMobile) {
      this.modalService.openDialog(UpliftedHomepageCalendarComponent, {
        viewContainerRef: this.vcr,
      });
    } else {
      this.createOverlay(
        this.smallCalendarOverlay,
        top ? this.dateAnchor2 : this.dateAnchor
      );
    }
  }

  scrollTop() {}

  private createOverlay(
    template: TemplateRef<any>,
    connectedTo: ElementRef
  ): void {
    const position: any[] = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
    ];
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(connectedTo)
        .withPositions(position)
        .withDefaultOffsetY(5),
    });

    const componentPortal = new TemplatePortal(template, this.vcr);

    merge(
      overlayRef.backdropClick(),
      overlayRef.detachments(),
      this.closeCalendar$
    )
      .pipe(take(1))
      .subscribe(() => {
        overlayRef.detach();
      });

    overlayRef.attach(componentPortal);
  }

  handleLocationOverlay() {
    const configuration = { hideSearch: true };
    if (this.layoutService.isMobile) {
      const modalProviders: StaticProvider[] = [
        {
          provide: GOOGLE_LOCATION_FILTER_FORM_CONTROL,
          useValue: this.homepageService.eventLocationControl,
        },
        {
          provide: LOCATION_FILTER_CONFIGURATION,
          useValue: configuration,
        },
      ];

      const modalInjector = Injector.create({
        providers: modalProviders,
        parent: this.injector,
      });

      const ref = this.modalService.openDialog(
        UiGoogleLocationDropdownComponent,
        {
          viewContainerRef: this.vcr,
          injector: modalInjector,
        }
      );

      return;
    }

    const overlayPositions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
    ];

    const overlaySettings: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      minWidth: this.overlayOrigin.nativeElement.clientWidth,
      maxHeight: 400,
      minHeight: 200,
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.overlayOrigin)
        // .withPush(false)
        // .withGrowAfterOpen
        .withViewportMargin(20)
        .withPositions(overlayPositions)
        .withDefaultOffsetY(20),
    };

    const overlayRef = this.overlay.create(overlaySettings);

    const overlayProviders: StaticProvider[] = [
      {
        provide: GOOGLE_LOCATION_FILTER_OVERLAY_REF,
        useValue: overlayRef,
      },
      {
        provide: GOOGLE_LOCATION_FILTER_FORM_CONTROL,
        useValue: this.homepageService.eventLocationControl,
      },
      {
        provide: LOCATION_FILTER_CONFIGURATION,
        useValue: configuration,
      },
    ];

    const overlayInjector = Injector.create({
      providers: overlayProviders,
      parent: this.injector,
    });

    const componentPortal = new ComponentPortal(
      UiGoogleLocationDropdownComponent,
      this.vcr,
      overlayInjector
    );

    const ref = overlayRef.attach(componentPortal);

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.detach();
    });
  }
}
