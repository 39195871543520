
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Optional,
  inject,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  AdvertServiceVariant,
  AdvertsPriceUnits,
  JarvisMeasurementUnitService,
  JarvisTrackingService,
  ServicesService,
} from '@jarvis/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'jarvis-ui-service-list-item',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class JarvisUiServiceListItemComponent implements OnInit, OnDestroy {
  @Input() serviceInfo: AdvertServiceVariant;
  @Input() dateControl = new UntypedFormControl();
  @Input() buttonColor: 'primary' | 'accent' = 'primary';
  @Input() showPricePerUnit = false;
  @Input() quantityFieldDisabled = false;
  @Input() globalMinimalSpendIncluded = false;
  @Input()
  set priceChange(newValue) {
    if (this.serviceInfo) {
      this.serviceInfo.setPriceChange(newValue);
      this.recalculatePrice();
    }
  }
  private _priceChange: any;

  @Output() serviceAdded = new EventEmitter();
  @Output() serviceRemoved = new EventEmitter();
  @Output() quantityChanged = new EventEmitter();

  finalPrice = 0;
  readMore = false;
  quantityDisabled = false;

  quantityControl = new UntypedFormControl(null);

  activeCurrencyCode$ = this.measurementUnitService.activeCurrencyCode$;
  activeLocale$ = this.measurementUnitService.activeLocale$;

  recalculatePriceObs = new Subject<void>();
  recalculatedPrice$ = this.recalculatePriceObs.asObservable();

  private trackService = inject(JarvisTrackingService);

  private destroy$ = new Subject<void>();

  get finalUnitPrice(): number {
    return this.serviceInfo.getFinalUnitPrice(this.dateControl.value);
  }

  constructor(
    private servicesService: ServicesService,
    @Optional() private measurementUnitService: JarvisMeasurementUnitService
  ) {}

  ngOnInit(): void {
    this.dateControl.valueChanges.subscribe(() => {
      this.recalculatePrice();
    });

    this.quantityControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.quantityUpdate(newValue);
      });
  }

  ngOnDestroy(): void {
    this.quantityChanged.complete();
    this.serviceAdded.complete();
    this.serviceRemoved.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  quantityUpdate(event: any): void {
    const lastQuantity = this.serviceInfo.quantity;

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'service_input_changed',
    });

    const el = event.target;
    this.serviceInfo.quantity = +el.value;
    el.value = this.serviceInfo.quantity;
    this.recalculatePrice();
    if (lastQuantity < 1 && this.serviceInfo.quantity > 0) {
      this.serviceAdded.emit();
    }
    if (lastQuantity > 0 && this.serviceInfo.quantity < 1) {
      this.serviceRemoved.emit();
    }
    this.quantityChanged.emit();
  }

  increaseQty(): void {
    const lastQuantity = this.serviceInfo.quantity;

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'service_plus_click',
      data: {
        package_name: this.serviceInfo.name,
        package_pricing: this.finalPrice || this.finalUnitPrice,
      },
    });

    this.serviceInfo.quantity += 1;
    this.recalculatePrice();
    if (lastQuantity < 1 && this.serviceInfo.quantity > 0) {
      this.serviceAdded.emit();
    }
    this.quantityChanged.emit();
  }

  decreaseQty(): void {
    const lastQuantity = this.serviceInfo.quantity;

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'service_minus_click',
      data: {
        package_name: this.serviceInfo.name,
        package_pricing: this.finalPrice || this.finalUnitPrice,
      },
    });

    this.serviceInfo.quantity -= 1;
    this.recalculatePrice();
    if (lastQuantity > 0 && this.serviceInfo.quantity < 1) {
      this.serviceRemoved.emit();
    }
    this.quantityChanged.emit();
  }

  additionalQuantityUpdate(event: any): void {
    const el = event.target;
    this.serviceInfo.additionalQuantity = +el.value;
    el.value = this.serviceInfo.additionalQuantity;
    this.recalculatePrice();
    this.quantityChanged.emit();
  }

  increaseAdditionalQuantity(): void {
    this.serviceInfo.additionalQuantity += 1;
    this.recalculatePrice();
    this.quantityChanged.emit();
  }

  decreaseAdditionalQuantity(): void {
    this.serviceInfo.additionalQuantity -= 1;
    this.recalculatePrice();
    this.quantityChanged.emit();
  }

  addService(): void {
    if (this.quantityFieldDisabled) {
      return;
    }
    this.servicesService.serviceSelected$.next();

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'add_service_click',
      data: {
        package_name: this.serviceInfo.name,
        package_pricing: this.finalPrice || this.finalUnitPrice,
      },
    });

    this.serviceInfo.included = true;
    if (this.serviceInfo.quantity === 0) {
      this.serviceInfo.quantity = 1;
      this.recalculatePrice();
    }

    this.serviceAdded.emit();
    this.quantityChanged.emit();
  }

  removeService(): void {
    if (this.quantityFieldDisabled) {
      return;
    }

    this.serviceInfo.included = false;
    this.serviceInfo.quantity = 0;
    this.recalculatePrice();

    this.serviceRemoved.emit();
    this.quantityChanged.emit();
  }

  private recalculatePrice(): void {
    const price = this.serviceInfo.getFinalPrice(this.dateControl.value);
    const finalPriceAfterPriceChange = price;
    this.finalPrice = finalPriceAfterPriceChange;
    this.quantityControl.setValue(this.finalPrice, { emitEvent: false });
    this.recalculatePriceObs.next();
  }

  trackClick() {
    if (this.readMore)
      this.trackService.handleEvent({
        trackers: ['amplitude', 'mixpanel'],
        eventName: 'read_more_click',
      });
  }
}
