import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'personInitials',
})
export class PersonInitialsPipe implements PipeTransform {
  transform(fullName: string): any {
    return fullName && !fullName.startsWith('+')
      ? fullName
          .split(' ')
          .map((n) => n[0])
          .join('')
          .substring(0, 2)
      : '';
  }
}
