<ng-container *ngIf="showFooter$ | async">
  <ng-container *ngIf="footerContent$ | async as content">
    <div
      class="bztt-flex bztt-w-full bztt-h-full bztt-justify-center bztt-bg-white"
    >
      <div class="bztt-mx-8 footer-content-styling bztt-max-w-[1440px]">
        <div [innerHTML]="content" class="md:bztt-max-w-[75%]"></div>
      </div>
    </div>
  </ng-container>
</ng-container>
