import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { JarvisDatepickerRange } from './datepicker-range.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [MatDatepickerModule, MatInputModule, CommonModule, FormsModule],
  exports: [JarvisDatepickerRange],
  declarations: [JarvisDatepickerRange],
  providers: [],
})
export class JarvisDatepickerRangeModule {}
