import { delay, map, tap } from 'rxjs/operators';
import { MainHeaderMenuComponent } from './../menu/menu.component';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injector } from '@angular/core';
import { DOMAIN_COUNTRY } from '@jarvis/services';
import {
  HEADER_MENU_ITEMS_LT,
  HEADER_MENU_ITEMS_US,
} from '../header.constants';
import { OverlayRef } from '@angular/cdk/overlay';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  private links: any;
  private viewConstraints = new BehaviorSubject<ViewConstraints[]>([]);
  private headerPortalSource$ = new BehaviorSubject<any>(null);
  headerPortal$ = this.headerPortalSource$.asObservable().pipe(
    tap(header => console.log(header))
  );

  private headerFiltersComponent$ = new BehaviorSubject<any>(null);
  headerFilters$ = this.headerFiltersComponent$.asObservable();

  private saasHeaderActive$ = new BehaviorSubject<any>(false);
  saasHeader$ = this.saasHeaderActive$.asObservable().pipe(delay(10));

  showSecondaryHeader$ = new BehaviorSubject<boolean>(true);

  // currentUserMainProfile$ = null;

  headerWidth$: Observable<[number, number]> = of(null);

  overlays: OverlayRef[] = [];

  constructor(private injector: Injector) {
    this.setDefaultHeader();
  }

  closeAllOverlays(): void {
    this.overlays.forEach((overlay) => {
      overlay.dispose();
    });
  }

  setHeaderWidthStream(stream: Observable<[number, number]>): void {
    this.headerWidth$ = stream;
  }

  setHeaderTemplate(
    template: TemplateRef<any>,
    viewContainerRef: ViewContainerRef
  ): void {
    const templatePortal = new TemplatePortal(template, viewContainerRef);
    this.headerPortalSource$.next(templatePortal);
  }

  setHeaderComponent(component: Type<unknown>): void {
    const componentPortal = new ComponentPortal(component);

    this.headerPortalSource$.next(componentPortal);
  }

  setHeaderFilters(
    template?: TemplateRef<any>,
    viewContainerRef?: ViewContainerRef
  ): void {
    if (!template) return this.headerFiltersComponent$.next(null);
    
    const templatePortal = new TemplatePortal(template, viewContainerRef);
    this.headerFiltersComponent$.next(templatePortal);
  }

  setSaasHeader(active: boolean = true) {
    this.saasHeaderActive$.next(active);
  }

  hideHeader(): void {
    this.headerPortalSource$.next(null);
  }

  get menuItems() {
    return this.links;
  }
  set menuItems(items) {
    this.links = items;
  }

  setDefaultHeader(): void {
    const domainCountry = this.injector.get(DOMAIN_COUNTRY).toUpperCase();
    this.links =
      domainCountry === 'LT' ? HEADER_MENU_ITEMS_LT : HEADER_MENU_ITEMS_US;
    this.viewConstraints.next([
      ViewConstraints.Logo,
      ViewConstraints.TopSearch,
      ViewConstraints.Items,
      ViewConstraints.DropdownMenu,
      ViewConstraints.MobileMenu,
      ViewConstraints.ServiceProvider,
      ViewConstraints.AuthSelector,
      ViewConstraints.MobileDivider,
    ]);

    this.setHeaderComponent(MainHeaderMenuComponent);
  }

  setViewConstraints(constraints: ViewConstraints[]) {
    this.viewConstraints.next(constraints);
  }

  public isEnabled(constraint: ViewConstraints): Observable<boolean> {
    return this.viewConstraints.pipe(map((v) => v.includes(constraint)));
  }
}

export enum ViewConstraints {
  Logo,
  TopSearch,
  Items,
  DropdownMenu,
  ServiceProvider,
  AuthSelector,
  MobileMenu,
  MobileDivider,
}
