import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  Self,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import {
  MatLegacyFormField as MatFormField,
  MatLegacyFormFieldControl as MatFormFieldControl,
  MAT_LEGACY_FORM_FIELD as MAT_FORM_FIELD,
} from '@angular/material/legacy-form-field';
import { DOMAIN_COUNTRY } from '@jarvis/services';
import { Subject } from 'rxjs';
import { countryCodes } from './country-codes';

@Component({
  selector: 'jarvis-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: JarvisPhoneNumberComponent,
    },
  ],
})
export class JarvisPhoneNumberComponent
  implements
    OnInit,
    OnDestroy,
    MatFormFieldControl<string>,
    ControlValueAccessor,
    DoCheck
{
  @ViewChild('number') numberInput: ElementRef<HTMLInputElement>;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @ViewChildren(MatOption) options: QueryList<MatOption>;

  static nextId = 0;
  countryCodes = countryCodes;

  parts: UntypedFormGroup;

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  // tslint:disable-next-line: no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;
  stateChanges = new Subject<void>();

  touched = false;
  focused = false;
  id = `phone-no-input-${JarvisPhoneNumberComponent.nextId++}`;
  controlType = 'phone-no-input';
  onChange = (_: any) => {};
  onTouched = () => {};

  @Input()
  get value(): string | null {
    const { internationalCode, number } = this.parts.value;
    return `${internationalCode?.dialCode ?? ''} ${number}`;
  }
  set value(tel: string | null) {
    if (tel) {
      const codeObj = this.countryCodes.find((codeInfo) =>
        tel.includes(codeInfo.dialCode + ' ')
      );
      if (codeObj) {
        this.parts.get('internationalCode').setValue(codeObj);
        const leftPart = tel.replace(codeObj.dialCode + ' ', '');
        this.parts.get('number').setValue(leftPart);
        return;
      }

      this.parts.get('number').setValue(tel);
    }
  }

  get empty(): boolean {
    const {
      value: { internationalCode, number },
    } = this.parts;

    return !internationalCode && !number;
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;
  private destroy$ = new Subject<void>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
    private formBuilder: UntypedFormBuilder,
    private elementRef: ElementRef,
    private focusMonitor: FocusMonitor,
    private scrollDispatcher: ScrollDispatcher,
    private cdRef: ChangeDetectorRef,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string
  ) {
    this.parts = this.formBuilder.group({
      internationalCode: [
        countryCodes.find((info) => info.code === domainCountry.toUpperCase()),
        [Validators.required],
      ],
      number: [
        null,
        [
          Validators.required,
          ...(this.domainCountry === 'lt' ? [Validators.pattern(/^[0-9]{8}$|^[0-9]{3} [0-9]{5}$/)] : []),
        ],
      ],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    const validators = [this.validate.bind(this)];

    if (this.ngControl.control.validator) {
      validators.push(this.ngControl.control.validator);
    }

    this.ngControl.control.setValidators(validators);
    this.ngControl.control.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngDoCheck(): void {
    this.updateErrorState();
  }

  ngAfterViewInit(): void {
    /* this.scrollDispatcher
      .scrolled()
      .pipe(filter(scrollable => this.cdkVirtualScrollViewPort === scrollable))
      .subscribe(() => {
        let needUpdate = false;
 
        this.options.forEach(option => {
          const selected = this.parts.value.internationalCode?.includes(option.value);
 
          if (selected && !option.selected) {
            option.select();
            needUpdate = true;
          } else if (!selected && option.selected) {
            option.deselect();
            needUpdate = true;
          }
        });
 
        if (needUpdate) {
          this.cdRef.detectChanges();
        }
      }); */
  }

  validate({ value }) {
    const invalid = this.parts.invalid;

    const hasCountryCode = !this.parts.get('internationalCode').invalid;

    if (invalid && hasCountryCode) {
      return this.parts.get('number').errors;
    }

    return (
      invalid && {
        required: true,
      }
    );
  }

  updateErrorState(): void {
    const controlTouched = this.ngControl.control.touched;
    // this.ngControl.control.updateValueAndValidity();
    if (!this.touched && controlTouched) {
      this.touched = true;
      this.stateChanges.next();
    }
  }

  onFocusIn(event: FocusEvent): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (
      !this.elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this.focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this.focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement =
      this.elementRef.nativeElement.querySelector('.container')!;

    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  writeValue(tel: string | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleInput(): void {
    this.onChange(this.value);
  }

  handleSelectionChange(event: any): void {
    this.onChange(this.value);
    setTimeout(() => {
      this.focusMonitor.focusVia(this.numberInput, 'program');
    }, 0);
  }

  onContainerClick(event: MouseEvent): void {}
}
