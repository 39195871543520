<div class="lets-container full-width">
  <h2 class="title">
    {{ (!type ? texts.title : configuration[type].title) | translate }}
  </h2>
  <p class="description">
    {{
      (!type ? texts.description : configuration[type].description) | translate
    }}
  </p>
  <a rel="noreferrer" [routerLink]="routerUrl" [queryParams]="qparams" class="bztt-no-underline">
    <button
      (click)="handleButtonClick()"
      app-button
      color="accent"
      class="bztt-px-4"
      [class.vendor-header-get-started]="type === 'vendor'"
      [class.header-get-started]="type === 'user'"
    >
      {{ (buttonText ? buttonText : 'landing.saas.createPage') | translate }}
    </button>
  </a>

  <a
    [href]="
      contactUrl
        ? contactUrl
        : 'https://meetings-eu1.hubspot.com/arturas-asakavicius/intro-to-breezit'
    "
    target="_blank"
    style="font-size: 12px; color: grey"
  >
    {{ 'landing.saas.bookCall' | translate }}
  </a>
</div>
