import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DOMAIN_COUNTRY } from '@jarvis/services';

@Component({
  selector: 'jarvis-last-like-dropdown',
  templateUrl: './last-like-dropdown.component.html',
  styleUrls: ['./last-like-dropdown.component.scss']
})
export class LastLikeDropdownComponent {
  @Output() closeLastLike = new EventEmitter();
  @Input() lastLike;

  likeData = inject(MAT_BOTTOM_SHEET_DATA, { optional: true });
  bottomSheetRef = inject(MatBottomSheetRef<LastLikeDropdownComponent>, { optional: true });
  country = inject(DOMAIN_COUNTRY);

  constructor() {
    this.lastLike = this.likeData;
  }

  close() {
    if (this.bottomSheetRef) this.bottomSheetRef.dismiss();
    this.closeLastLike.emit(null);
  }
}
