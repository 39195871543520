import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASE_URL } from '@jarvis/services';
import { Observable } from 'rxjs';

export enum RulesPoliciesTypes {
    privacy = 'privacyPolicy',
    rules = 'rulesPolicy',
    cookies = 'cookiePolicy'
}

@Injectable({ providedIn: 'root' })
export class JarvisUiRulesPoliciesService {

    termsConditionsEndpoint: string;

    constructor(
        @Inject(BASE_URL) private baseUrl: string,
        private httpService: HttpClient
    ) {
        this.termsConditionsEndpoint = `${baseUrl}/termsConditions`;
    }

    getPolicy(policyType: RulesPoliciesTypes, language: string, domain: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                type: policyType,
                lang: language,
                country: domain
            }
        });

        return this.httpService.get(this.termsConditionsEndpoint, {
            params
        });
    }
}
