import { DOMAIN_COUNTRY, JarvisTrackingService } from '@jarvis/services';
/* eslint-disable max-len */
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth';
import { LayoutService } from '@jarvis/ui/layout';
import {
  JarvisCardComponent,
  JarvisCardSliderComponent,
} from '@jarvis/ui/card-slider';

import { Subject } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import {
  alternatives,
  comments,
  howItWorksItems,
  whatWillGetItems,
} from './ai-planner-landing.configurations';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule, JarvisHeaderService } from '@jarvis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'jarvis-ai-planner-landing',
  templateUrl: './ai-planner-landing.component.html',
  styleUrls: ['./ai-planner-landing.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    ButtonModule,
    JarvisCardSliderComponent,
    JarvisCardComponent,
    TranslateModule,
    MatMenuModule,
    RouterModule
  ],
  standalone: true,
})
export class AIPlannerLandingComponent implements OnInit, OnDestroy {
  @ViewChild('customHeader', { static: true })
  landingHeader: TemplateRef<HTMLDivElement>;

  private country = inject(DOMAIN_COUNTRY);
  private layoutService = inject(LayoutService);
  private router = inject(Router);
  private headerService = inject(JarvisHeaderService);
  private vcr = inject(ViewContainerRef);
  private trackService = inject(JarvisTrackingService);
  private route = inject(ActivatedRoute);

  loggedIn$ = this.authService.isLoggedIn$;
  private destroy$ = new Subject<void>();

  howItWorksItems = howItWorksItems;
  whatWillGetItems = whatWillGetItems;
  alternatives = alternatives;
  comments = comments;

  paid;

  constructor(private authService: JarvisAuthService) {}

  ngOnInit(): void {
    const { paid } = this.route.snapshot.data;
    this.paid = !!paid;
    this.headerService.setHeaderTemplate(this.landingHeader, this.vcr);

    this.layoutService.disableFooterMargin();
    // this.layoutService.hideNearMeSubject.next(true);
    // this.layoutService.disableFooter();

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'page_load',
      data: { page_type: 'ai_planner_landing' },
    });

    this.route.snapshot.data.seo.metaTagData$.next({
      title:
        'Wedding Venue Finder | How to Find a Dream Wedding Venue | Breezit',
      description:
        'Wondering why it is so hard to find your dream wedding venue? Start chat to receive immediate venue recommendations tailored to your style, budget, and location.',
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.layoutService.enableFooterMargin();
    // this.layoutService.hideNearMeSubject.next(false);
    this.headerService.setDefaultHeader();
  }

  scrollTo(section: string): void {
    const element = document.getElementById(section);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  handleCTOClick(position): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'ai_planner_landing_cta_click',
      data: {
        paid: !!this.paid,
        position,
        page_type: 'ai_planner_landing',
        action: 'cta_click',
      },
    });

    this.router.navigate(['./ai'], {
      relativeTo: this.route,
    });
  }

  get vendorSignupLink() {
    return this.authService.userData$.pipe(
      take(1),
      map(() => {
        const signUpLink = this.country === 'lt' ? '/registracija' : '/sign-up';

        return {
          link: signUpLink,
        };
      })
    );
  }
}
