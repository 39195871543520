import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'messaging-bookings-list-mobile',
  templateUrl: './bookings-list-mobile.component.html',
  styleUrls: ['./bookings-list-mobile.component.scss']
})
export class MessagingBookingsListMobileComponent {
  @Output()
  closed = new EventEmitter();
}
