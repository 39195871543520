<div
  class="bztt-pointer-events-none bztt-flex bztt-flex-col bztt-absolute bztt-left-4 bztt-top-4 bztt-gap-2"
  *ngIf="cardType !== 'loading'"
>
  <!-- <jarvis-ui-marketplace-card-tag
    *ngIf="data?.paid"
    textColor="orange"
  >
    <mat-icon>campaign</mat-icon>
    <span>
      {{ 'listing.cart.sponsored' | translate }}
    </span>
  </jarvis-ui-marketplace-card-tag> -->

  <jarvis-ui-marketplace-card-tag
    *ngIf="
      data?.priceAddition?.negotiablePricing &&
      (
        data?.priceAddition?.breezitExclusive === null ||
        data.priceAddition.breezitExclusive?.enabled === false
      )
    "
  >
    <mat-icon>local_offer</mat-icon>
    <span>{{ 'listing.cart.negotiablePricing' | translate }}</span>
  </jarvis-ui-marketplace-card-tag>

  <jarvis-ui-marketplace-card-tag
    *ngIf="
      data?.priceAddition?.breezitExclusive &&
      data.priceAddition.breezitExclusive.enabled === true
    "
  >
    <mat-icon class="bztt-h-4 bztt-w-4" svgIcon="breezit"></mat-icon>
    <span>
      {{ data.priceAddition.breezitExclusive.value | ServiceCurrency : data.serviceBase : '1.0-0' }}
      {{ 'listing.cashback' | translate }}
    </span>
  </jarvis-ui-marketplace-card-tag>
</div>