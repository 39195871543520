<div
  class="bztt-w-full bztt-grid bztt-grid-cols-1 bztt-gap-3 bztt-flex-col bztt-items-center"
>
  <ng-container
    *ngTemplateOutlet="
      contactItem;
      context: {
        title: 'selectService.requestDate',
        value: detailData?.createdAt | date : 'MMM d, y h:mm a' : tz : locale
      }
    "
  ></ng-container>
  <ng-container *ngIf="detailData.options?.adminData?.tourDate">
    <ng-container
      *ngTemplateOutlet="
        contactItem;
        context: {
          title: 'detail.siteTourDate',
          value:
            detailData.options?.adminData?.tourDate
            | date : 'MMM d, y h:mm a' : tz : locale
        }
      "
    ></ng-container>
  </ng-container>
  <ng-container
    *ngTemplateOutlet="
      contactItem;
      context: {
        title: 'detail.customer.person.email',
        value: detailData?.customer?.email
      }
    "
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="
      contactItem;
      context: {
        title: 'detail.customer.person.phoneNo',
        value: detailData?.customer?.phoneNo
      }
    "
  ></ng-container>
</div>

<ng-template
  #contactItem
  let-title="title"
  let-hidden="hidden"
  let-value="value"
>
  <div
    *ngIf="!hidden"
    class="bztt-flex bztt-w-full bztt-flex-row bztt-justify-between"
  >
    <div class="bztt-text-xs md:bztt-text-sm bztt-text-[#6B6060]">
      {{ 'bookings.' + title | translate }}
    </div>
    <div
      class="bztt-text-xs md:bztt-text-sm bztt-text-right bztt-font-semibold bztt-text-[#1F1F1F]"
    >
      {{ value | translate }}
    </div>
  </div>
</ng-template>

<ng-template #social let-type="type" let-value="value" let-metric="metric">
  <img
    *ngIf="value"
    (click)="openNewTab(value, metric)"
    class="bztt-ml-1.5 bztt-w-5 bztt-h-5 bztt-cursor-pointer"
    [src]="'assets/icons/ic_' + type + '.svg'"
  />
</ng-template>
