import { Component, OnInit, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CauseOfCancellation } from '@jarvis/types';
import { JarvisUiModalService } from '../modal/services/modal.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-cancel-of-cancellation',
  template: `
<jarvis-ui-modal-wrapper class="modal-wrapper medium-modal cancel-q" [closeButton]="false">
   <div class="h-100 w-100 d-flex justify-content-center">
      <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between">
         <div style="flex-grow: 1;" class="d-flex w-100 flex-column align-items-center pb-0">
            <div class="d-flex flex-column">
               <span><mat-icon class="icon material-icons-outlined">new_releases</mat-icon></span>
               <label class="fw-bolder">{{i18nKey+ '.question' | translate}}</label>
               <mat-radio-group [(ngModel)]="causeOfCancellation">
                  <ng-container *ngFor="let enum of CauseOfCancellationArray;">
                    <mat-radio-button *ngIf="isMessageSet('cause.' +  enum)" [value]="enum">
                      <span class="radio-text">{{(i18nKey+'.cause.' +  enum) | translate}}</span>
                    </mat-radio-button>
                  </ng-container>
               </mat-radio-group>
            <mat-form-field *ngIf="enumEquals(causeOfCancellation, CauseOfCancellation.other)"  appearance="outline" floatLabel="never" class="w-100 mb-0" >
                       <textarea 
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="2"
                          cdkAutosizeMaxRows="3"
                          placeholder="{{i18nKey + '.otherPlaceHolder' | translate}}" type="text" [(ngModel)]="otherCause" matInput>
                        </textarea>
            </mat-form-field>

            </div>
       </div>
        <div class="d-flex w-100 pb-3 justify-content-center">
                 <button class="me-1" app-button (click)="close()">{{i18nKey+ '.back' | translate}}</button>
                 <button class="ms-1" [disabled]="causeOfCancellation==null" app-button (click)="confirm()" color="accent">{{i18nKey+ '.cancelBtn' | translate}}</button> 
        </div>          
      </div>
   </div>
</jarvis-ui-modal-wrapper>
  `,
  styles: [` 
   .cause-cancel-list-panel .mat-dialog-container {overflow:hidden}
   .cancel-q .icon {color:#ef5518; width:60px; height:100%; font-size:60px }
   /* .cancel-q button {min-width:00px} */
   .cancel-q mat-radio-group {
      display: flex;
      flex-direction: column;
      margin: 10px 0px 20px 0px;
      align-items: flex-start;
    }
   .cancel-q .mat-button-disabled { opacity: 0.4; }
   .cancel-q button { max-width:200px; }
   .cancel-q .radio-text { white-space: normal; }
   .cancel-q .mat-radio-button { text-align:left; margin: 8px 0;  }
   .cancel-q .mat-radio-container { margin-right:10px; }
   `],
  encapsulation: ViewEncapsulation.None,
  providers: [ TranslatePipe ]
})

export class CauseOfCancellationComponent {
 public causeOfCancellation:CauseOfCancellation;
 public otherCause:string;
 public i18nKey:string;

 constructor(private dialogR: MatDialogRef<any>,
             private modalSrv: JarvisUiModalService,
             private translatePipe: TranslatePipe,
             @Inject(MAT_DIALOG_DATA) private dt: {i18nKey:string}) {
    this.i18nKey = dt.i18nKey;
 }
  
  
  public close(value?: any):void {this.dialogR.close(value)};   

  public confirm() {
     this.close({cause: this.causeOfCancellation, otherCause: this.otherCause });
  }

  ngOnInit(): void { }

  public get CauseOfCancellationArray(): CauseOfCancellation[] { 
     return Object.keys(CauseOfCancellation)
                 .filter(v => isNaN(Number(v)) === false)
                 .map(key => CauseOfCancellation[key]);
  } 

  public enumEquals(x1:CauseOfCancellation, x2:CauseOfCancellation):boolean {
     return CauseOfCancellation[String(x1)] === x2;
  } 

  public get CauseOfCancellation(): typeof CauseOfCancellation { return CauseOfCancellation; }  

  public isMessageSet(key: string): boolean { return this.translatePipe.transform(this.i18nKey + '.' + key) !== (this.i18nKey + '.' + key);}  

}  

