import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { JarvisLanguageService } from '@jarvis/services';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'jarvis-ui-actions-card',
  templateUrl: './actions-card.component.html',
  styleUrls: ['./actions-card.component.scss']
})
export class JarvisUiActionsCardComponent implements OnInit {
  @Input() i18nKey: string;

  @Input() showButton = true;
  @Input() showSubtext = true;
  @Input() showTitle = true;
  @Input() customButton = false;
  @Output() buttonClickHandle = new EventEmitter<any>();

  constructor(private router: Router,
    private languageService: JarvisLanguageService,
    private translationService: TranslateService  
   ) { }

  destroy$ = new Subject<void>();

  ngOnInit(): void {   
    this.languageService.currentLanguage$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((language) => this.translationService.use(language));      
  }

  openTutorialDialog(): void {
    this.router.navigate(['/video-presentation']);
  }

  openServices(): void {
    this.router.navigate(['/services']);
  }

  handleButtonClick() {
    this.buttonClickHandle.emit()
  }

}
