<ng-container>
  <div class="messages-container" [class.shrunk]="isExpanded">
    <div class="messages-scroll-container" #messageContainer>
      <chat-message
        *ngFor="let message of messages; let i = index; let lastMessage = last;"
        []
      ></chat-message>
    </div>
  </div>

  <div class="action-container-wrapper">
    <chat-messages-actions [isExpanded]="isExpanded"></chat-messages-actions>
  </div>

</ng-container>

<!--
<ng-container *ngIf="autoReplyShown$ | async">
  <chat-autoreply
    (closed)="closeAutoReply()"
    [autoReplies]="autoReply$ | async"
    [chatroomId]="currentChatroomId$ | async"
  ></chat-autoreply>
</ng-container> -->