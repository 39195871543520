import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ServiceBase } from '@jarvis/types';

@Pipe({
    name: 'ServiceCurrency',
    standalone: true
})
export class ServiceCurrencyPipe implements PipeTransform {

    constructor(
        private currencyPipe: CurrencyPipe
    ) {}

    transform(value: number | string, serviceBase?: ServiceBase, digitsInfo?: string, countryName?: string): string {
        const country = serviceBase?.address?.country || countryName || 'US';
        const currencyCode = country === 'US' ? 'USD' : 'EUR';
        const localeId = country === 'US' ? 'en-US' : 'lt-LT';
        return this.currencyPipe.transform(value, currencyCode, 'symbol', digitsInfo, localeId);
    }
}