import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { MessagesType, MESSAGES_TYPE } from '@jarvis/messaging/tokens';
import { BASE_URL } from '@jarvis/services/tokens';
import { SocketService } from '@jarvis/services/socket';
import {
  NavigationNotificationData,
  NavigationNotificationDataResponse,
  NavigationNotificationType,
  NavigationNotificationUpdate,
} from './notifications.types';
import { fromEvent, merge, Observable, of, Subject } from 'rxjs';
import {
  map,
  scan,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { JarvisAuthService } from '@jarvis/auth';

interface NotificationUpdateObject {
  message: string;
  data: any;
}

@Injectable({ providedIn: 'root' })
export class JarvisNotificationService {
  private notificationCache$: Observable<NavigationNotificationData>;
  newBookingCount$: Observable<number>;
  newMessagesCount$: Observable<number>;

  manualUpdate$ = new Subject<NotificationUpdateObject>();

  authService = inject(JarvisAuthService);

  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    // TODO - change to a more logical name all over project
    @Inject(MESSAGES_TYPE) private messagesType: MessagesType,
    private socketService: SocketService,
    private httpService: HttpClient
  ) {
    this.notificationCache$ = this.navigationNotifications$(this.messagesType);
    this.newBookingCount$ = this.notificationCache$.pipe(
      map((data) => data?.bookings ?? 0)
    );
    this.newMessagesCount$ = this.notificationCache$.pipe(
      map((data) => data?.messages ?? 0)
    );
  }

  navigationNotifications$(
    eventType: MessagesType
  ): Observable<NavigationNotificationData> {
    const initialData = this.authService.isLoggedIn$.pipe(
      switchMap((loggedIn) => {
        if (loggedIn) {
          return this.socketService.wrapInSocket(() =>
            this.getNavigationNotifications(eventType).pipe(
              map((notificationData) => {
                return {
                  ...notificationData,
                  messages: eventType === 'user' ? (notificationData.messages.customer[0]?.count || 0) : (notificationData.messages.vendor[0]?.count || 0)
                };
              })
            )
          );
        }

        return this.retrieveLocalUserNotifications();
      })
    );

    const updateStream = (
      initialData: NavigationNotificationData
    ): Observable<any> =>
      this.navigationNotificationsEvent$(eventType).pipe(
        scan((notificationData, updateEvent) => {
          if (!updateEvent) {
            return notificationData;
          }

          const mappedUpdateData = {
            ...updateEvent.data,
            messages: eventType === 'user' ? (updateEvent.data.messages?.customer[0]?.count || 0) : (updateEvent.data.messages?.vendor[0]?.count || 0)
          };

          if (updateEvent.message === 'update') {
            const currentDataCopy = {
              ...notificationData,
              ...mappedUpdateData,
            };

            return currentDataCopy;
          }

          if (updateEvent.message === 'add') {
            const currentDataCopy = {
              ...notificationData,
            };

            Object.keys(mappedUpdateData).forEach((key) => {
              currentDataCopy[key] += mappedUpdateData[key];
            });

            return currentDataCopy;
          }

          if (updateEvent.message === 'remove') {
            const currentDataCopy = {
              ...notificationData,
            };

            Object.keys(mappedUpdateData).forEach((key) => {
              currentDataCopy[key] -= mappedUpdateData[key];
            });

            return currentDataCopy;
          }

          return {
            ...notificationData,
            ...mappedUpdateData,
          };
        }, initialData),
        startWith(initialData)
      );

    return initialData.pipe(
      switchMap((originalData) => updateStream(originalData)),
      shareReplay(1)
    );
  }

  private navigationNotificationsEvent$(
    userType: MessagesType
  ): Observable<NavigationNotificationUpdate> {
    const socket$ = this.socketService.wrapInSocket((socket) =>
      fromEvent(
        socket,
        userType === 'user'
          ? NavigationNotificationType.user
          : NavigationNotificationType.vendor
      )
    );
    return merge(this.manualUpdate$, socket$);

    // ['eventName', { message: 'update', data: { bookings: x, messages: y } }]
  }

  retrieveLocalUserNotifications(): Observable<NavigationNotificationData> {
    const likes: number = (
      JSON.parse(localStorage.getItem('liked_services')) || []
    ).length;

    return of({
      bookings: 0,
      messages: 0,
      likes,
    });
  }

  private getNavigationNotifications(
    userType: MessagesType
  ): Observable<NavigationNotificationDataResponse> {
    const params = new HttpParams({
      fromObject: {
        userType,
      },
    });

    return this.httpService.get<NavigationNotificationDataResponse>(
      `${this.baseUrl}/common/getInitialNavigationInfo`,
      {
        params,
      }
    );
  }
}
