import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AdminMoveSessionGuard implements CanActivate {
    constructor(
        private authService: JarvisAuthService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const hash = route.queryParams.hash;
        if (!hash) {
            return of(false);
        }

        return this.authService.useMoveSession(hash).pipe(
            switchMap(() => this.authService.getCurrentUser()),
            switchMap(() => {
                return from(this.router.navigateByUrl('/'))
            })
        );
    }
}