import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ServiceMainPhoto',
  pure: true
})
export class ServiceMainPhotoPipe implements PipeTransform {
  transform(serviceEvent: any): any {
    const customPricing = serviceEvent?.customPricing;

    if (customPricing) {
      return (
        serviceEvent?.wedding?.photos?.main[0]?.croppedPhotoUrl ||
        serviceEvent?.wedding?.photos?.main[0]?.originalPhotoUrl ||
        serviceEvent?.privateEvent?.photos?.main[0]?.croppedPhotoUrl ||
        serviceEvent?.privateEvent?.photos?.main[0]?.originalPhotoUrl ||
        serviceEvent?.businessEvent?.photos?.main[0]?.croppedPhotoUrl ||
        serviceEvent?.businessEvent?.photos?.main[0]?.originalPhotoUrl
      );
    } else {
      return (
        serviceEvent?.general?.photos?.main[0]?.croppedPhotoUrl ||
        serviceEvent?.general?.photos?.main[0]?.originalPhotoUrl
      );
    }
  }
}
