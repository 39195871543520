<div class="bzt-landing-root">
  <mat-sidenav-container #sidenav>
    <mat-sidenav role="navigation" position="end" fixedInViewport>
      <nav class="bztt-flex bztt-flex-col">
        <div class="links bztt-flex bztt-flex-col bztt-gap-y-2">
          <span class="link-header bztt-pb-1">{{
            'landing.saas.header.solutions' | translate
          }}</span>
          <a
            class="link-subheader"
            *ngFor="let solutionItem of configuration?.sections"
            (click)="navigateToSection(solutionItem.id)"
            >{{ solutionItem.header | translate }}</a
          >

          <a>
            <a
              class="bztt-no-underline bztt-leading-none"
              href="https://meetings-eu1.hubspot.com/eva-from-breezit"
              target="_blank"
              >{{ 'landing.saas.header.bookCall' | translate }}</a
            >
          </a>
          <a *ngIf="domainCountry == 'us'" (click)="handlePricingClick()">{{
            'landing.saas.header.pricing' | translate
          }}</a>
          <a class="link vendor-header-login" (click)="openLoginDialog()">{{
            'landing.saas.header.login' | translate
          }}</a>
        </div>
        <a
          [routerLink]="(vendorSignupLink | async).link"
          [queryParams]="{
            source: isProfileLink ? 'profile-link-signup' : 'vendor-header'
          }"
          class="bztt-no-underline"
        >
          <button
            (click)="handleVendorSignup('vendor-header'); sidenav.close()"
            app-button
            color="accent"
            class="vendor-header-get-started"
          >
            {{ configuration?.createAccount | translate }}
          </button>
        </a>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content #content>
      <main>
        <section aria-describedby="introplan" class="intro">
          <h1
            #introplan
            class="bztt-max-w-none bztt-text-[28px] md:bztt-text-[55px]"
          >
            {{ configuration?.title | translate }}
          </h1>

          <p class="bztt-text-[17px] md:bztt-text-[21px] bztt-mt-7">
            {{ configuration?.description | translate }}
          </p>
          <a
            [routerLink]="(vendorSignupLink | async).link"
            [queryParams]="{
              source: isProfileLink ? 'profile-link-signup' : 'vendor-hero'
            }"
            class="bztt-no-underline"
          >
            <button
              app-button
              class="vendor-hero-getstarted bztt-px-4"
              color="accent"
              (click)="handleVendorSignup('vendor-hero')"
            >
              {{ configuration?.createAccount | translate }}
            </button>
          </a>

          <span
            class="bztt-font-montserrat-bold bztt-mt-4 bztt-text-xs bztt-text-[#717171]"
          >
            {{ configuration?.descriptionSmall | translate }} 🌟
          </span>

          <h2
            *ngIf="configuration?.additionalTitle"
            class="label bztt-justify-center bztt-whitespace-pre-wrap bztt-mt-20 md:bztt-mb-[-20px]"
          >
            {{ configuration?.additionalTitle }}
          </h2>

          <landings-browser-window
            [class.big]="!isMobile"
            class="main-screenshot"
          >
            <iframe
              *ngIf="configuration?.topImageVideo"
              width="2543"
              [height]="!isMobile ? 'auto' : '340'"
              [src]="
                sanitizer.bypassSecurityTrustResourceUrl(
                  configuration?.topImageVideo
                )
              "
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <img
              *ngIf="!configuration?.topImageVideo"
              [src]="'assets/images/' + configuration.topImage"
            />
          </landings-browser-window>
        </section>

        <section
          class="our-approach sections-container"
          *ngIf="configuration?.ourApproach"
        >
          <div class="filled"></div>
          <div class="border"></div>
          <div class="approach-container">
            <div
              class="approach-content bztt-flex bztt-flex-col bztt-gap-x-7 bztt-justify-center bztt-max-w-2xl bztt-text-center"
            >
              <h2
                class="label bztt-mb-5 bztt-justify-center bztt-whitespace-pre-wrap"
              >
                {{ configuration?.ourApproach.title | translate }}
              </h2>
              <p
                class="description bztt-text-center bztt-whitespace-pre-line"
                [innerHTML]="configuration?.ourApproach.description | translate"
              ></p>
            </div>
          </div>
        </section>

        <section
          *ngIf="configuration?.afterOurApproach"
          class="bztt-text-3xl bztt-leading-[45px] bztt-font-semibold md:bztt-mt-[-150px] bztt-font-maple-r"
        >
          {{ configuration?.afterOurApproach }}
        </section>

        <section *ngIf="configuration?.sections" class="sections-container">
          <ng-container
            *ngFor="let section of configuration?.sections; let i = index"
          >
            <div [id]="section.id">
              <div class="text-container">
                <h2 class="bztt-text-[28px]">
                  {{ section.header | translate }}
                </h2>
                <p>{{ section.text | translate }}</p>
                <div>
                  <div class="subtext" *ngFor="let subtext of section.subtexts">
                    <mat-icon>check_circle</mat-icon>{{ subtext | translate }}
                  </div>
                </div>
                <a
                  [routerLink]="(vendorSignupLink | async).link"
                  [queryParams]="{ source: 'learn-more' }"
                  class="bztt-no-underline"
                >
                  <div
                    [ngClass]="section.id + ' learn-more mt-4'"
                    (click)="handleVendorSignup('learn-more')"
                  >
                    {{ 'landing.saas.templates.tryYourself' | translate }}
                  </div>
                </a>
                <!-- <div class="link" (click)="handleVendorSignup()">
                  <a>Start now</a>
                  <mat-icon>arrow_forward</mat-icon>
                </div> -->
              </div>
              <div class="video-container">
                <landings-browser-window>
                  <img [src]="section.videoPoster" alt="section-image" />
                </landings-browser-window>
              </div>
            </div>
          </ng-container>
        </section>

        <section
          *ngIf="configuration?.compare"
          class="sections-container bztt-w-full"
        >
          <div class="compare-container bztt-gap-y-4">
            <div id="free_profile" class="with bztt-self-stretch">
              <div class="title-container">
                <h2>{{ configuration?.compare.package1.title | translate }}</h2>
              </div>
              <ul class="bztt-flex bztt-flex-col bztt-gap-y-3">
                <li *ngFor="let text of configuration?.compare.package1.items">
                  <mat-icon>check_circle</mat-icon>
                  <span>
                    {{ text | translate }}
                  </span>
                </li>
              </ul>
            </div>
            <div
              class="without bztt-self-stretch"
              [class.warning]="configuration?.compare.package2.hideItemIcon"
            >
              <div class="title-container">
                <h2>{{ configuration?.compare.package2.title | translate }}</h2>
              </div>
              <ul class="bztt-flex bztt-flex-col bztt-gap-y-3">
                <li *ngFor="let text of configuration?.compare.package2.items">
                  <mat-icon
                    *ngIf="!configuration?.compare.package2.hideItemIcon"
                    >check_circle</mat-icon
                  >
                  <span>
                    {{ text | translate }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section
          *ngIf="configuration?.endorsment"
          class="auders-photo sections-container bztt-flex bztt-flex-col bztt-justify-center bztt-items-center"
        >
          <h2
            class="label bztt-text-center bztt-px-5 bztt-justify-center bztt-max-w-[90%]"
          >
            {{ configuration?.endorsment.title | translate }}
          </h2>

          <img
            class="bztt-rounded-full"
            [src]="'assets/images/landing/' + configuration?.endorsment.image"
            alt="endorsment image"
          />

          <p
            class="subtitle bztt-justify-center bztt-text-center bztt-whitespace-pre-line"
          >
            {{ configuration?.endorsment.description | translate }}
          </p>

          <p class="description bztt-text-center bztt-px-5 bztt-justify-center">
            {{ configuration?.endorsment.label | translate }}
          </p>
        </section>

        <section>
          <h2
            class="label bztt-text-center bztt-px-5 bztt-justify-center bztt-mt-[-40px] md:bztt-mt-[-100px]"
          >
            Trusted by more than 2000 venue operators and event professionals
          </h2>
          <div
            class="bztt-flex bztt-flex-col md:bztt-flex-row md:bztt-justify-between bztt-items-center bztt-gap-6 bztt-mt-10 bztt-w-full"
          >
            <div class="bztt-flex bztt-flex-col bztt-gap-3 bztt-items-center">
              <img
                class="bztt-h-10"
                src="assets/images/landing/partners/ace-hotel.png"
                alt="ace-hotel"
              />
            </div>
            <div class="bztt-flex bztt-flex-col bztt-gap-3 bztt-items-center">
              <img
                class="bztt-h-10"
                src="assets/images/landing/partners/boka.png"
                alt="Boka Group"
              />
            </div>
            <div class="bztt-flex bztt-flex-col bztt-gap-3 bztt-items-center">
              <img
                class="bztt-h-10"
                src="assets/images/landing/partners/proper.png"
                alt="Proper Hotels"
              />
            </div>
            <div class="bztt-flex bztt-flex-col bztt-gap-3 bztt-items-center">
              <img
                class="bztt-h-10"
                src="assets/images/landing/partners/line.png"
                alt="The Line"
              />
            </div>
            <div class="bztt-flex bztt-flex-col bztt-gap-3 bztt-items-center">
              <img
                class="bztt-h-10"
                src="assets/images/landing/partners/casetta.svg"
                alt="Casseta Group"
              />
              <div class="bztt-text-lg bztt-font-semibold">Casseta Group</div>
            </div>
          </div>
        </section>

        <section
          id="profile-link-pricing"
          *ngIf="configuration?.bookConsultation"
          class="section-fullwidth"
        >
          <landings-lets-breezit-section
            [type]="null"
            [texts]="configuration?.bookConsultation"
            [buttonText]="configuration?.createAccount"
            (buttonClick)="handleVendorSignup('vendor-footer')"
            [routerUrl]="(vendorSignupLink | async).link"
            [qparams]="{
              source: isProfileLink ? 'profile-link-signup' : 'vendor-footer'
            }"
          ></landings-lets-breezit-section>
        </section>
      </main>

      <!-- <landings-landing-footer></landings-landing-footer> -->
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #landingHeader>
  <div class="landing-header">
    <div class="logo bztt-cursor-pointer" (click)="marketplaceRedirrect()">
      <img src="assets/images/bzt-logo.svg" />
    </div>
    <div
      (click)="sidenav.open()"
      class="mobile-button-container"
      *ngIf="isMobile"
    >
      <mat-icon>menu</mat-icon>
    </div>
    <div class="provider-button-container" *ngIf="!isMobile">
      <ul class="section-navigation-list">
        <li [matMenuTriggerFor]="solutionsMenu">
          {{ 'landing.saas.header.solutions' | translate }}
        </li>

        <li *ngIf="domainCountry == 'us'" (click)="handlePricingClick()">
          {{ 'landing.saas.header.pricing' | translate }}
        </li>

        <li class="vendor-header-login" (click)="openLoginDialog()">
          {{ 'landing.saas.header.login' | translate }}
        </li>
      </ul>

      <a
        [routerLink]="(vendorSignupLink | async).link"
        [queryParams]="{
          source: isProfileLink ? 'profile-link-signup' : 'vendor-header'
        }"
        class="bztt-no-underline"
      >
        <button
          (click)="handleVendorSignup('vendor-header')"
          app-button
          class="accent-outline vendor-bottom-getstarted bztt-w-44 bztt-px-4"
        >
          <span>{{ 'landing.saas.header.getStarted' | translate }}</span>
        </button>
      </a>
    </div>
  </div>
</ng-template>

<mat-menu #solutionsMenu="matMenu">
  <button
    *ngFor="let solutionItem of configuration?.sections"
    mat-menu-item
    (click)="navigateToSection(solutionItem.id)"
  >
    {{ solutionItem.header | translate }}
  </button>
</mat-menu>
