import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, MatIconModule],
  templateUrl: './ui-eu-support-description.component.html',
  styleUrls: ['./ui-eu-support-description.component.scss'],
})
export class UiEuSupportDescriptionComponent {}
