import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'GlobalMinimalBudget',
  pure: false
})
export class JarvisServicesGlobalMinimalBudgetPipe implements PipeTransform {
  transform(
    totalPrice: number,
    budgetInfo: { value: number, tax: number, serviceCharge: number},
    difference = false
  ): number {
    if (!totalPrice) {
      return 0;
    }
    const { value: globalMinimalBudget, tax: globalMinimalBudgetTax, serviceCharge: serviceChargePercent = 0 } = budgetInfo;
    const differencePrice = globalMinimalBudget - totalPrice;
    const serviceCharge = differencePrice * (serviceChargePercent / 100);
    const serviceChargeTax = serviceCharge * (globalMinimalBudgetTax / 100);
    const differenceTax =   differencePrice * (globalMinimalBudgetTax / 100);
    const taxedDifferencePrice = differencePrice + serviceCharge + serviceChargeTax + differenceTax; 


    if (differencePrice <= 0) {
      return difference ? 0 : totalPrice;
    }

    if (difference) {
      return taxedDifferencePrice;
    }

    return totalPrice + taxedDifferencePrice;
  }
}
