import { JarvisPartnersListHttpService } from './../../../../partners/src/partners-list/services/partners-list-http.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jarvis-ui-marketplace-card-partner-list-info',
  templateUrl: 'partner-list-info.component.html',
  styleUrls: [
    './partner-list-info.component.scss'
  ]
})

export class JarvisUiMarketplaceCardPartnerListInfoComponent implements OnInit {
  @Input() data;
  @Input() cardType; 

  stats = [{
    label: 'clicks',
    icon: 'pan_tool_alt',
    param: 'clicks'
  },
  {
    label: 'inquries',
    icon: 'live_help',
    param: 'inquiries'
  },
  {
    label: 'bookings',
    icon: 'event_available',
    param: 'reserved'
  }];

  constructor(private partnerListService: JarvisPartnersListHttpService) { }

  ngOnInit() { }

  handleVisibilityChange(e) {
    this.partnerListService.updatePartnerListItem(this.data.partnerData.partnerListItemId, this.data.partnerData.partnerList, { visible: e.value }).subscribe(() => this.data.partnerData.isVisible = e.value);
  }
}
