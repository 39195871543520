import { Pipe, PipeTransform } from '@angular/core';
import { serviceEventPictureSort } from '@jarvis/utils';

@Pipe({
  name: 'CardPictures',
  standalone: true
})

export class ServiceMarketplaceCardPicturesPipe implements PipeTransform {
  transform(value: any, eventType: string): any {
    const customPricing = value.customPricing === true;
    const eventTypeParsed = customPricing ? eventType : 'wedding';
    const photos = value.venue?.photos || value[eventTypeParsed].photos;

    if (!photos) {
      return [];
    }

    // return [
    //   {
    //     link: photos.main[0].croppedPhotoUrl,
    //     type: 'image'
    //   },
    //   {
    //     _id: value._id,
    //     slug: value.slug,
    //     type: 'debug'
    //   }
    // ];
    
    const sortedPhotos = serviceEventPictureSort(photos);
    return sortedPhotos;
  }
}