import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { JarvisHeaderService } from './header.service';
import { PortalModule } from '@angular/cdk/portal';



@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    PortalModule
  ],
  exports: [
    HeaderComponent
  ],
  providers: [
    JarvisHeaderService
  ]
})
export class JarvisHeaderModule { }
