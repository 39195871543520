import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessagingService } from '../../services/messaging.service';
import { IMessagesViewService, MESSAGES_VIEW_SERVICE } from '../services/messages-view.token';
import { MessagesType, MESSAGES_TYPE } from '../services/messages-type.token';

@Component({
  selector: 'messaging-chat-messages-mobile',
  templateUrl: './chat-messages-mobile.component.html',
  styleUrls: ['./chat-messages-mobile.component.scss']
})
export class MessagingChatMessagesMobileComponent implements OnInit, OnDestroy {

  @Output()
  openBookingInfo = new EventEmitter();

  phoneShown = false;
  currentChatroom$: Observable<any>;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MESSAGES_TYPE) public messagesType: MessagesType,
    @Inject(MESSAGES_VIEW_SERVICE) private messagesViewService: IMessagesViewService,
    private messagingService: MessagingService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private location: Location
  ) {
    this.currentChatroom$ = combineLatest([
      this.messagingService.allChatroomCache$,
      this.messagesViewService.currentChatroomId$
    ]).pipe(
      map(([chatrooms, currentId]) => {
        if (!currentId) {
          return null;
        }
        return chatrooms.find(chatroom => chatroom._id === currentId)
      })
    );
  }

  ngOnInit(): void {
    /* this.router.events.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.close();
    }); */
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.messagesViewService.currentChatroomId$.next(null);
  }

  close(): void {
    this.destroy$.next();
    this.messagesViewService.closeMobileViews();
    // this.router.navigate(['../'], { relativeTo: this.currentRoute});
    this.location.back();
  }

  callLink(phoneNo: string): void {
    window.location.href = `tel:${phoneNo}`;
  }

}
