import { Directive, ElementRef, NgZone, inject } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[mouseScrollable]' })
export class MouseScrollableDirective {
  
  private ngZone = inject(NgZone);
  private elementRef = inject(ElementRef);

  constructor() {
    const element = this.elementRef.nativeElement;

    this.ngZone.runOutsideAngular(() => {
      this.registerScrollableElement(element);
    });
  }

  private registerScrollableElement(element: Element) {
    let isDrag = false;
      
    const dragStart = () => isDrag = true;
    const dragEnd = () => isDrag = false;
    const drag = (ev) => isDrag && (element.scrollLeft -= ev.movementX);
    
    element.addEventListener("pointerdown", dragStart);
    addEventListener("pointerup", dragEnd);
    addEventListener("pointermove", drag);
  }

}