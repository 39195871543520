import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

export interface HttpRequestState<T> {
    isLoading: boolean;
    value?: T;
    error?: HttpErrorResponse | Error;
}

export const httpRequestState = () => {
    return <T>(observable: Observable<T>) => {
        return observable.pipe(
            map((value) => ({isLoading: false, value})),
            catchError(error => of({isLoading: false, error})),
            startWith({isLoading: true})
        ) as Observable<HttpRequestState<T>>;
    };
};
