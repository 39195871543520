export const insertSchema = (
  schema: Record<string, any>,
  className = 'structured-data-website',
  document: Document
) => {
  let script: any;
  let shouldAppend = false;

  if (document.head.getElementsByClassName(className).length) {
    script = document.head.getElementsByClassName(className)[0];
  } else {
    script = document.createElement('script');
    shouldAppend = true;
  }

  script.setAttribute('class', className);
  script.type = 'application/ld+json';
  script.text = JSON.stringify(schema);

  if (shouldAppend) {
    document.head.appendChild(script);
  }
};
