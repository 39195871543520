import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HeaderMenuComponent } from '../menu/menu.component';

@Injectable({providedIn: 'root'})
export class HeaderService {

    private headerPortalSource$ = new BehaviorSubject<any>(null);
    headerPortal$ = this.headerPortalSource$.asObservable();

    // currentUserMainProfile$ = null;

    headerWidth$: Observable<[number, number]> = of(null);

    constructor() {
        this.setDefaultHeader();
    }

    setHeaderWidthStream(stream: Observable<[number, number]>): void {
        this.headerWidth$ = stream;
    }

    setHeaderTemplate(template: TemplateRef<any>, viewContainerRef: ViewContainerRef): void {
        const templatePortal = new TemplatePortal(template, viewContainerRef);
        this.headerPortalSource$.next(templatePortal);
    }

    setHeaderComponent(component: Type<unknown>): void {
        const componentPortal = new ComponentPortal(component);
        this.headerPortalSource$.next(componentPortal);
    }

    setDefaultHeader(): void {
        this.setHeaderComponent(HeaderMenuComponent);
    }

}
