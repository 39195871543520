import { ChangeDetectorRef, Directive, OnInit } from '@angular/core';;
import { take, timer } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[priceUnitRefresh]',
  standalone: true
})
export class PriceUnitRefreshDirective implements OnInit {
  constructor(
    private cdref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    timer(200).pipe(
      take(1)
    ).subscribe(() => {
      this.cdref.detectChanges();
    });
  }
}