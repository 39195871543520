import { Injectable, inject } from '@angular/core';
import * as moment from 'moment';
import { BookingsDataService } from './bookings.service';
import {
  BookingDetailsEditType,
  BookingDetailsOptions,
} from './bookings.types';
import {
  BookingDetailsType,
  BookingFacts,
  BookingStateKeys,
  BookingsType,
  BookingsUtil,
  ContractStateType,
  ServiceBase,
  ServiceEvents,
  ServiceTypeKeys,
} from '@jarvis/types';
import {
  AdvertServiceVariant,
  AdvertsPriceUnits,
  ExtractedServices,
  MainParsedServicesType,
  ServiceTypes,
  extractServicesFromAdvert,
  parseMainServices,
} from '@jarvis/services';
import { TranslateService } from '@ngx-translate/core';
import { roundToTwoPrecision } from '@jarvis/utils';
import { LayoutService } from '@jarvis/ui';

@Injectable({
  providedIn: 'root',
})
export class BookingDetailsService {
  private bookingDataService = inject(BookingsDataService);
  private translationService = inject(TranslateService);
  private layoutService = inject(LayoutService);

  detailData: any;
  differ: any = {
    booking: null,
    options: null,
  };

  prevState: BookingStateKeys;

  locale;
  currencyCode;
  userId;

  servicesEditData: BookingDetailsEditType[] = [];
  extractedServices!: ExtractedServices;
  selectedServiceList!: BookingDetailsType[];
  additionalServices: any;

  options: BookingDetailsOptions = {
    contractState: ContractStateType.standard,
    listingContractState: ContractStateType.standard,
    deposit: { required: false, fullAdvance: false },
    stripeAccount: false,
    files: [],
    bookingCancel: [],
    penaltiesCancel: [],
    reschedule: [],
    timeScheduling: false,
    customDeposit: false,
  };

  get facts(): BookingFacts {
    const facts = BookingsUtil.getUIState(
      this.detailData as BookingsType,
      this.detailData.serviceBaseId as ServiceBase,
      'facts'
    );
    return facts;
  }

  constructor() {}

  updateIfChanged() {
    const diffBooking = this.differ.booking.diff(this.detailData);
    const diffOptions = this.differ.options.diff(this.detailData.options);

    if (diffBooking || diffOptions) {
      let dateChanged = false;
      if (diffBooking) {
        diffBooking.forEachItem((item) => {
          if (item.key === 'eventDate')
            if (
              (item.key === 'eventDate' || item.key === 'eventTillDate') &&
              !moment(item.currentValue).isSame(moment(item.previousValue))
            ) {
              dateChanged = true;
            }
        });
      }

      // TODO update with calendar for unregistered!!!!
      if (dateChanged && this.userId) this.updateWithCalendar();
      else this.update();
    }
  }

  updateWithCalendar() {
    if (this.userId)
      this.bookingDataService
        .updateBookingDates({
          id: this.detailData._id,
          eventDate: this.detailData.eventDate
            ? this.detailData.eventDate
            : new Date(-8640000000000000),
          eventTillDate: this.detailData.eventTillDate
            ? this.detailData.eventTillDate
            : new Date(-8640000000000000),
        })
        .subscribe();
    else
      this.bookingDataService
        .updateBookingDatesUnregistered({
          id: this.detailData._id,
          eventDate: this.detailData.eventDate
            ? this.detailData.eventDate
            : new Date(-8640000000000000),
          eventTillDate: this.detailData.eventTillDate
            ? this.detailData.eventTillDate
            : new Date(-8640000000000000),
        })
        .subscribe();

    this.update();
  }

  calculateDeposit(price: number): number {
    if (this.options.deposit.fullAdvance) return price;
    else if (this.options.deposit.required && this.options.deposit.value) {
      if (this.options.deposit.unit == 'fixed')
        return Math.min(this.options.deposit.value, price);
      else return Math.round(price * this.options.deposit.value) / 100;
    }
    return 0;
  }

  getServiceType(): string {
    return BookingsUtil.aliasServiceObjByTypeName(this.detailData.serviceType);
  }

  getTotalPaid(): number {
    return (
      Number(this.detailData.paidCash ?? 0) + Number(this.detailData.paid ?? 0)
    );
  }

  update() {
    if (this.userId)
      this.bookingDataService.changeState(this.detailData).subscribe();
    else
      this.bookingDataService
        .changeStateUnregistered(this.detailData)
        .subscribe();
    ``;
  }

  getSelectedTotal(): number {
    return this.servicesEditData
      .filter(
        (detail) => detail.serviceVariant?.type !== ServiceTypes.serviceCharge
      )
      .reduce((partial_sum, a) => {
        if (!a.isIncluded) return partial_sum;
        if (a.serviceVariant && !a.isCustomPrice) {
          a.serviceVariant.quantity = a.qnt;

          return (
            partial_sum +
            a.serviceVariant.getFinalPrice(this.detailData.eventDate)
          );
        }
        return partial_sum + a.qnt * a.price;
      }, 0);
  }

  calculateFullPrice(): number {
    return this.servicesEditData
      .filter(
        (detail) => detail.serviceVariant?.type !== ServiceTypes.serviceCharge
      )
      .reduce((partial_sum, a) => {
        if (!a.isIncluded) return partial_sum;
        if (a.serviceVariant) {
          a.serviceVariant.quantity = this.detailData.options.guestNo;
          return (
            partial_sum +
            a.serviceVariant.getFinalPrice(this.detailData.eventDate)
          );
        }
        return partial_sum + a.qnt * a.price;
      }, 0);
  }

  saveServiceEditChanges() {
    const serviceChargeActive =
      this.servicesEditData.find(
        (detail) => detail.serviceVariant?.type === ServiceTypes.serviceCharge
      )?.isIncluded || false;

    const result = this.servicesEditData
      .sort((a: any, b: any) => {
        if (a.serviceVariant?.type === ServiceTypes.serviceCharge) return 1;
        if (b.serviceVariant?.type === ServiceTypes.serviceCharge) return -1;
        return 0;
      })
      .filter((item) => item.isIncluded && item.price > 0)
      .map((item) => {
        const serviceCharge =
          item.price *
          (serviceChargeActive
            ? (item.serviceVariant?.serviceCharge || 0) / 100
            : 0);
        const tax =
          (item.price + serviceCharge) *
          ((item.serviceVariant?.tax || 0) / 100);

        const serviceChargeRounded =
          Math.round((serviceCharge + Number.EPSILON) * 100) / 100;
        const taxRounded = Math.round((tax + Number.EPSILON) * 100) / 100;

        const newData: any = {
          name: this.translationService.instant(item.name),
          description: item.description,
          price: item.price,
          unit: item.unit,
          qnt: item.qnt,
          isCustomPrice: item.isCustomPrice,
          hash: item.hash,
          ignorePrice: item.serviceVariant?.type === ServiceTypes.serviceCharge,
          tax: taxRounded,
          serviceCharge: serviceChargeRounded,
          taxPercent: (item.serviceVariant?.tax || 0) / 100,
          serviceChargePercent:
            serviceChargeActive &&
            (item.serviceVariant?.serviceCharge || 0) / 100,
          type: item.serviceVariant?.type,
          excludedFromGlobalMinBudget:
            item.serviceVariant?.excludedFromGlobalMinBudget || false,
          savedMinimalBudget: null,
          additionalQnt: item.additionalQnt,
        };

        if (
          item.serviceVariant?.type === ServiceTypes.venueSeparate &&
          item.isIncluded
        ) {
          newData.rentAdditionalPriceUnit = item.unit;

          if (
            item.unit === AdvertsPriceUnits.fixedFee ||
            item.unit === AdvertsPriceUnits.startingPrice
          ) {
            newData.rentAdditionalFixedCost = item.price;
          }

          if (item.unit === AdvertsPriceUnits.currencyPerPerson) {
            newData.rentAdditionalUnitCost = item.price;
          }
        }

        if (
          item.serviceVariant?.type === ServiceTypes.globalMinimalBudget &&
          item.isIncluded
        ) {
          newData.savedMinimalBudget = item.savedMinimalBudget;

          const customPrice = item.serviceVariant?.customPrice;
          newData.price = customPrice ? customPrice : item.price;
          newData.serviceCharge = 0;
          newData.tax = 0;
        }

        return newData;
      });

    if (result) {
      //   console.log(result);
      this.detailData.details = result;
      const price = result
        .filter((res) => !res.ignorePrice)
        .reduce(
          (partial_sum, a) =>
            partial_sum +
            a.qnt * a.price * (1 + a.serviceChargePercent) * (1 + a.taxPercent),
          0
        );
      const tax = result
        .filter((res) => !res.ignorePrice)
        .reduce(
          (partial_sum, a) =>
            partial_sum +
            a.qnt * a.price * (1 + a.serviceChargePercent) * a.taxPercent,
          0
        );
      const serviceCharge = result
        .filter((res) => !res.ignorePrice)
        .reduce(
          (partial_sum, a) =>
            partial_sum + a.qnt * a.price * a.serviceChargePercent,
          0
        );

      //   console.log(price, tax, serviceCharge);

      this.detailData.price = roundToTwoPrecision(price);
      this.detailData.tax = roundToTwoPrecision(tax);
      this.detailData.serviceCharge = roundToTwoPrecision(serviceCharge);

      this.detailData.deposit = this.calculateDeposit(this.detailData.price);
      this.update();
    }
  }

  hideFooter(hide = true) {
    hide
      ? this.layoutService.disableFooter()
      : this.layoutService.enableFooter();
  }

  getDeposit() {
    return this.options.customDeposit
      ? this.detailData.deposit
      : this.calculateDeposit(this.detailData.price);
  }

  updateMinimumBudget() {
    const globalMinimalBudgetVariant: any = this.servicesEditData
      .filter((item) => item.serviceVariant)
      .find((detail) => {
        return detail.serviceVariant?.type === ServiceTypes.globalMinimalBudget;
      });

    if (
      globalMinimalBudgetVariant &&
      globalMinimalBudgetVariant.savedMinimalBudget
    ) {
      const cateringServiceTypes = [
        ServiceTypes.venueCateringMenuMain,
        ServiceTypes.venueCateringMenuAdditional,
        ServiceTypes.venueSeparate,
        ServiceTypes.cateringDessert,
        ServiceTypes.cateringKidsMenu,
        ServiceTypes.cateringTasting,
        ServiceTypes.venueCateringMenuBeverages,
        ServiceTypes.venueCateringMenuChildren,
        ServiceTypes.venueCateringMenuBeveragesChildren,
      ];

      const type =
        globalMinimalBudgetVariant.serviceVariant?.globalMinimalBudgetType;

      const filteredServiceVariants = this.servicesEditData.filter(
        (detail: any) => {
          if (!detail.serviceVariant) return false;
          if (type === 'catering') {
            return cateringServiceTypes.includes(detail.serviceVariant.type);
          }

          return (
            detail.serviceVariant.type !== ServiceTypes.globalMinimalBudget &&
            detail.serviceVariant.type !== ServiceTypes.serviceCharge
          );
        }
      );

      const totalPrice = filteredServiceVariants.reduce(
        (total, item: any) => total + item.qnt * item.price,
        0
      );
      const globalMinimalBudget = globalMinimalBudgetVariant.savedMinimalBudget;

      const differencePrice: number = globalMinimalBudget - totalPrice;

      const newPrice: number =
        differencePrice <= 0 || totalPrice === 0 ? 0 : differencePrice;
      globalMinimalBudgetVariant.serviceVariant.customPrice = newPrice;
      globalMinimalBudgetVariant.amount = newPrice;
      globalMinimalBudgetVariant.price = newPrice;
    }
  }

  initDetailData() {
    this.servicesInit();

    this.servicesEditData = this.mapServicesForGrouping(
      parseMainServices(this.extractedServices, this.getServiceType())
    );

    this.setAdditionalData();

    // this.updateMinimumBudget();
  }

  servicesInit() {
    let type = this.getServiceType();
    const serviceEvent = this.detailData.serviceEventId as ServiceEvents;

    if (!serviceEvent?.enabledTypes[type] || !serviceEvent.customPricing) {
      if (
        !serviceEvent.customPricing ||
        !Object.entries(serviceEvent?.enabledTypes).find((k) => k[1])
      )
        type = 'wedding';
      else
        type = BookingsUtil.aliasServiceObjByTypeName(
          Object.entries(serviceEvent?.enabledTypes).find(
            (k) => k[1]
          )[0] as ServiceTypeKeys
        );
    }

    const serviceInfo = {
      base: this.detailData.serviceBaseId,
      event: serviceEvent[type],
    };

    const details = [];
    this.detailData.details.forEach((val) =>
      details.push(Object.assign({}, val))
    );

    this.extractedServices = extractServicesFromAdvert(serviceInfo);
    this.selectedServiceList = details;

    this.additionalServices = this.extractedServices.additional.filter(
      (service) =>
        service.type !== ServiceTypes.globalMinimalBudget &&
        service.type !== ServiceTypes.serviceCharge
    );
  }

  mapServicesForGrouping(servicesParsed: MainParsedServicesType) {
    const otherFees = this.getOtherFeesAndCharges();

    let result = [];

    if (this.detailData.serviceBaseId.type === 'venues') {
      if (servicesParsed.venueStandalone) {
        result.push({
          key: 'listing.services.venue',
          services: servicesParsed.venueStandalone,
        });
      }

      const globalMinimalBudget = otherFees[ServiceTypes.globalMinimalBudget];
      delete otherFees[ServiceTypes.globalMinimalBudget];
      if (globalMinimalBudget) {
        result.push({
          key: 'listing.services.globalMinimalBudget',
          services: [globalMinimalBudget],
        });
      }

      if (
        servicesParsed.venueRentAndCatering &&
        (servicesParsed.venueRentAndCatering.catering.banquet.length > 0 ||
          servicesParsed.venueRentAndCatering.catering.buffet.length > 0)
      ) {
        if (servicesParsed.venueRentAndCatering.catering.banquet.length > 0) {
          result.push({
            key: 'listing.services.banquetOptions',
            services: servicesParsed.venueRentAndCatering.catering.banquet,
          });
        }
        if (servicesParsed.venueRentAndCatering.catering.buffet.length > 0) {
          result.push({
            key: 'listing.services.buffetOptions',
            services: servicesParsed.venueRentAndCatering.catering.buffet,
          });
        }
        if (servicesParsed.venueRentAndCatering.catering.beverages.length > 0) {
          result.push({
            key: 'listing.services.beverageOptions',
            services: servicesParsed.venueRentAndCatering.catering.beverages,
          });
        }
        if (servicesParsed.venueCeremony) {
          result.push({
            key: 'listing.services.venueCeremony',
            services: servicesParsed.venueCeremony,
          });
        }
      }

      if (servicesParsed.catering.buffet.length) {
        result.push({
          key: 'listing.services.buffetServices',
          services: servicesParsed.catering.buffet,
        });
      }

      if (servicesParsed.catering.banquet.length) {
        result.push({
          key: 'listing.services.banquetServices',
          services: servicesParsed.catering.banquet,
        });
      }

      if (servicesParsed.catering.foodTruck.length) {
        result.push({
          key: 'listing.services.foodTruckServices',
          services: servicesParsed.catering.foodTruck,
        });
      }

      if (servicesParsed.catering.snacks.length) {
        result.push({
          key: 'listing.services.snackServices',
          services: servicesParsed.catering.snacks,
        });
      }

      if (servicesParsed.others.length) {
        result.push(
          servicesParsed.others.map((otherService) => {
            return {
              key:
                'subcategories.' +
                this.getServiceType() +
                '.' +
                otherService.name,
              services: otherService.services,
            };
          })
        );
      }
    }

    const venueSeparate = otherFees[ServiceTypes.venueSeparate];
    delete otherFees[ServiceTypes.venueSeparate];
    if (venueSeparate) {
      result.push({
        key: 'listing.services.venueSeparate',
        services: [venueSeparate],
      });
    }

    if (this.additionalServices?.length) {
      result.push({
        key: 'listing.services.selectAdditionalServices',
        services: this.additionalServices,
      });
    }

    result = result.reduce(
      (acc: any[], val: any) =>
        acc.concat(
          val.services.map((service) => {
            if (
              val.key === 'listing.services.venueSeparate' ||
              val.key === 'listing.services.globalMinimalBudget'
            )
              return service;
            return {
              serviceVariant: service,
              ...this.servicePreviewData(service),
              key: val.key,
            };
          })
        ),
      []
    );

    result = [
      ...Object.keys(otherFees)
        .filter((key) => key !== 'key' && key !== ServiceTypes.serviceCharge)
        .map((key) => otherFees[key]),
      ...result,
      ...(otherFees['serviceCharge'] ? [otherFees['serviceCharge']] : []),
    ];

    return result;
  }

  servicePreviewData(itm: AdvertServiceVariant) {
    return {
      name: this.translationService.instant(itm.name),
      unit: itm.priceUnit,
      qnt: itm?.minQuantity > -1 ? itm?.minQuantity : 0,
      additionalQnt: itm?.additionalQuantityEnabled
        ? itm?.additionalQuantity
        : null,
      isCustomPrice: false,
      isIncluded: false,
      price: Number(itm.getFinalUnitPrice(this.detailData.eventDate)),
      maxQuantity: itm.maxQuantity,
      minQuantity: itm.minQuantity,
      minimalBudget: itm.minimalBudget,
      hash: BookingsUtil.hashCode(
        itm.name,
        this.extractedServices.main.includes(itm)
      ),
      key: itm.key ? itm.key : itm.type,
    };
  }

  setAdditionalData() {
    this.selectedServiceList.forEach((item) => {
      if (item.hash === 'custom') {
        this.servicesEditData.push({
          ...item,
          amount: item.qnt * Number(item.price),
          description: item.description,
          isIncluded: true,
          isMain: false,
          key: 'bookings.detail.customServices',
        });
        return;
      }

      const srvc = this.servicesEditData.findIndex((checkitem) => {
        return checkitem.hash == item.hash || checkitem.name === item.name;
      });

      if (srvc >= 0)
        Object.assign(this.servicesEditData[srvc], {
          qnt: item.qnt,
          additionalQnt: item.additionalQnt,
          isCustomPrice: item.isCustomPrice,
          price: Number(item.price),
          amount: item.qnt * Number(item.price),
          description: item.description,
          isIncluded: true,
          savedMinimalBudget: item.savedMinimalBudget,
          ...(item.hash === 'custom' ? { key: 'listing.services.custom' } : {}),
        });
      else
        this.servicesEditData.push({
          ...item,
          amount: item.qnt * Number(item.price),
          description: item.description,
          isIncluded: true,
          isMain: false,
          ...(item.hash === 'custom' ? { key: 'listing.services.custom' } : {}),
        });
    });
  }

  getOtherFeesAndCharges() {
    const allExtracted = [
      ...this.extractedServices.main,
      ...this.extractedServices.additional,
    ].map((service) => {
      return {
        serviceVariant: service,
        ...this.servicePreviewData(service),
      };
    });

    let chargesAndFees = allExtracted.filter(
      (detail) => detail.serviceVariant?.type === ServiceTypes.venueSeparate
    );

    const globalMinimalBudgetVariant: any = allExtracted.find(
      (detail) =>
        detail.serviceVariant?.type === ServiceTypes.globalMinimalBudget
    );

    if (globalMinimalBudgetVariant) {
      const totalPriceWithoutCharges = allExtracted
        .filter(
          (detail) =>
            detail.isIncluded &&
            detail.serviceVariant?.type !== ServiceTypes.serviceCharge &&
            detail.serviceVariant?.type !== ServiceTypes.globalMinimalBudget
        )
        .reduce((total, item) => total + item.qnt * item.price, 0);

      const selectedIndex = this.selectedServiceList.findIndex(
        (item) =>
          item.hash === globalMinimalBudgetVariant.hash ||
          item.name === globalMinimalBudgetVariant.name
      );

      globalMinimalBudgetVariant.serviceVariant.setAlwaysIncluded(false);

      if (selectedIndex > -1) {
        const selectedService = this.selectedServiceList[selectedIndex];

        globalMinimalBudgetVariant.serviceVariant.quantity =
          selectedService.qnt;
        globalMinimalBudgetVariant.serviceVariant.setPriceChange(
          selectedService.price
        );
        globalMinimalBudgetVariant.isIncluded =
          selectedService.qnt > 0 ? true : false;
        globalMinimalBudgetVariant.qnt = selectedService.qnt;
        globalMinimalBudgetVariant.amount =
          selectedService.qnt * selectedService.price;
      } else {
        globalMinimalBudgetVariant.serviceVariant.quantity = 1;
        globalMinimalBudgetVariant.isIncluded = false;
        globalMinimalBudgetVariant.qnt = 0;
        const minCharge =
          globalMinimalBudgetVariant.serviceVariant.getFinalPrice(
            this.detailData.eventDate
          ) - totalPriceWithoutCharges;
        globalMinimalBudgetVariant.price = minCharge > 0 ? minCharge : 0;
      }

      chargesAndFees = [globalMinimalBudgetVariant, ...chargesAndFees];
    }

    const serviceChargeVariant: any = allExtracted.find(
      (detail) => detail.serviceVariant?.type === ServiceTypes.serviceCharge
    );

    if (serviceChargeVariant) {
      const selectedIndex = this.selectedServiceList.findIndex(
        (item) =>
          item.hash === serviceChargeVariant.hash ||
          item.name === serviceChargeVariant.name
      );

      serviceChargeVariant.serviceVariant.setAlwaysIncluded(false);

      if (selectedIndex > -1) {
        const selectedService = this.selectedServiceList[selectedIndex];

        serviceChargeVariant.serviceVariant.quantity = selectedService.qnt;
        serviceChargeVariant.isIncluded =
          selectedService.qnt > 0 ? true : false;
        serviceChargeVariant.qnt = selectedService.qnt;
        serviceChargeVariant.amount =
          selectedService.qnt * selectedService.price;
        serviceChargeVariant.serviceVariant.customPrice = selectedService.price;
      } else {
        serviceChargeVariant.serviceVariant.quantity = 1;
        serviceChargeVariant.isIncluded = false;
        serviceChargeVariant.qnt = 0;
        serviceChargeVariant.price =
          serviceChargeVariant.serviceVariant.getFinalPrice(
            this.detailData.eventDate
          );
      }
      chargesAndFees.push(serviceChargeVariant);
    }

    return chargesAndFees.reduce(
      (acc: any, val: any) => {
        val['type'] = 'otherFees';
        acc[val.serviceVariant.type] = val;
        return acc;
      },
      { key: 'otherFees' }
    );
  }
}
