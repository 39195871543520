import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ButtonModule } from '@jarvis/ui';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { MessageContent, MessagingService } from '@jarvis/messaging';
import { LinkUrls, LINK_URLS } from '@jarvis/services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'jarvis-contract-send-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ButtonModule,
    TextFieldModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,    
    MatIconModule,
  ],
  templateUrl: './contract-send-modal.component.html',
  styleUrls: ['./contract-send-modal.component.scss'],
})
export class CotnractSendModalComponent implements OnInit, OnDestroy {
  @Input() detailData: any;
  @Output() contractSent = new EventEmitter<string>();

  contractForm = new FormControl('');
  contractLink:string | undefined; 
  protected destroy$ = new Subject<void>();
  private sendContract$ = new Subject<void>();
  private messagingService: MessagingService = inject(MessagingService);
  private linkUrls: LinkUrls = inject(LINK_URLS);
  processed = new BehaviorSubject(false);
    
  constructor() {}


  ngOnInit(): void {
    this.contractLink = this.detailData.options.customAgreement;
    const mailLink = `${this.linkUrls.marketplace}/sign-contract/${this.detailData._id}`;
    
    this.sendContract$
      .pipe(
        takeUntil(this.destroy$),
        tap(()=>this.processed.next(true)),
        switchMap(()=>this.sendContractMessage(this.contractForm.value + `\nTo view your contract, please click here:\n${mailLink}`)),
        tap(()=>{ this.detailData.state = 'signing'; this.detailData.options.contractSent = true; } ),
        tap(()=>this.contractSent.emit())
     ).subscribe({
       next: v=>{this.processed.next(false)},
       error: (error) =>  { console.log(error); this.processed.next(false)},
       complete: ()=>{}
     }); 

    this.contractForm.setValue(
      `Hi ${
        this.detailData.customer?.name || ''
      },\n\nI'm sending you the contract to sign and pay the deposit. If you have any questions, just reply to this email.\n`
    );
    
  }

  sendcontract() {
    this.sendContract$.next();
  }

  previewContract() {
    this.contractSent.emit("preview");
  }  

  private sendContractMessage(message: string) {    
    const userId = this.detailData.customerId?._id;
    const serviceBaseId = this.detailData.serviceBaseId as string;
    const serviceEventId = this.detailData.serviceEventId as string;
    
    const chatRoom$ = userId
      ? this.messagingService.startChatroomWithUser(
          userId,
          serviceBaseId,
          serviceEventId,
          this.detailData._id
        )
      : this.messagingService.startChatroomWOUser(this.detailData._id);    
    
    return chatRoom$.pipe(
      switchMap((room: any, idx) => {
        return this.messagingService.sendMessage({
          chatRoomId: room[0]?._id,
          booking: this.detailData._id,
          message,
          type: 'contract',
        } as MessageContent);
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
