<div class="bztt-flex bztt-justify-between bztt-mb-2">
  <div class="bztt-m-0 bztt-bg-gray-300 bztt-h-5 bztt-w-[15%] bztt-animate-pulse"></div>
  <div class="bztt-min-w-fit">
    <div class="bztt-flex bztt-items-center">
      <span class="bztt-text-gray-300 bztt-mr-2 bztt-animate-pulse">★</span>
      <div class="bztt-bg-gray-300 bztt-h-4 bztt-w-8 bztt-animate-pulse"></div>
    </div>
  </div>
</div>

<div class="bztt-flex bztt-mb-1 bztt-bg-gray-300 bztt-animate-pulse bztt-h-4 bztt-w-[30%]">
</div>

<div class="bztt-min-h-[4rem]">
  <div class="bztt-h-6 bztt-bg-gray-300 bztt-animate-pulse"></div>
</div>

<div class="bztt-flex bztt-gap-1 bztt-items-center bztt-mt-auto">
  <div class="bztt-flex-col bztt-w-[25%]">
    <div class="bztt-bg-gray-300 bztt-animate-pulse bztt-mb-2 bztt-h-3 bztt-w-full"></div>
    <div class="bztt-h-5 bztt-bg-gray-300 bztt-animate-pulse bztt-w-[50%]"></div>
  </div>
</div>
