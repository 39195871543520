import { Meta, Title } from '@angular/platform-browser';
import { SEOMetaTagStrategy, DynamicSeoData } from '@jarvis/seo';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  filter,
  takeUntil,
  zipWith,
} from 'rxjs';

export class AIPlannerLandingMetaTagStrategy
  implements SEOMetaTagStrategy<DynamicSeoData>
{
  destroy$ = new Subject<void>();

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private domainCountry: string,
    private dom: Document,
    private metaTagData$: Observable<any>
  ) {
    this.metaTagData$
      .pipe(
        debounceTime(500),
        filter((data) => data !== null),
        takeUntil(this.destroy$)
      )
      .subscribe((seoData) => {
        this.executeStrategy(seoData);
      });
  }

  execute(seoData: DynamicSeoData) {
    // console.log('seoData', seoData);
  }

  private executeStrategy(seoData) {
    const { title, description } = seoData;

    this.titleService.setTitle(title);

    const descriptionShort = this.shortenDescription(description, true);

    this.metaService.updateTag({
      name: 'description',
      content: descriptionShort,
    });

    this.metaService.updateTag({
      property: 'og:description',
      content: descriptionShort,
    });

    this.metaService.updateTag({
      property: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      property: 'og:url',
      content: this.dom.location.href,
    });

    this.metaService.updateTag({
      property: 'og:type',
      content: 'product',
    });
  }

  private shortenDescription(description: string, byWord = false) {
    const CHAR_COUNT = 135;
    const learnMoreString =
      this.domainCountry === 'lt'
        ? '... | Daugiau >>>'
        : '... | Learn more >>>';

    if (description.length < CHAR_COUNT) return description + ' | Breezit';

    if (byWord) {
      const splited_spaces = description.split(' ');

      const reducer = (prev, curr) => {
        if ((prev + ' ' + curr).length <= CHAR_COUNT) {
          return (curr = prev + ' ' + curr);
        }
        return prev;
      };
      const res = splited_spaces.reduce(reducer, '');

      return res + learnMoreString;
    }

    return description.slice(0, CHAR_COUNT) + learnMoreString;
  }
}
