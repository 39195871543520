import { animate, state, style, transition, trigger } from '@angular/animations';

const FADE_IN_OUT_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

export const MarketplaceCardAnimations = [
  trigger('fadeInOut', [
    state(
      'shown',
      style({
        opacity: 1
      })
    ),
    state(
      'hidden',
      style({
        opacity: 0
      })
    ),
    transition('shown => hidden', [
      animate(FADE_IN_OUT_TIMINGS)
    ]),
    transition('hidden => shown', [
      animate(FADE_IN_OUT_TIMINGS)
    ]),
  ])
];