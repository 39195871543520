<div class="hero-container bztt-py-24 bztt-px-9">
  <div class="bztt-relative">
    <div
      class="wrap-container bztt-flex-wrap md:bztt-flex-nowrap bztt-inline-flex bztt-gap-7 bztt-flex-row bztt-relative bztt-max-w-[84rem] bztt-w-full"
    >
      <div class="main-title us-title" *ngIf="country === 'us'">
        {{ 'landing.homepageUplift.hero.title.1' | translate }}
        <span class="bztt-text-[#EF5518]">
          {{ 'landing.homepageUplift.hero.title.2' | translate }}
        </span> {{ 'landing.homepageUplift.hero.title.3' | translate }}
      </div>
      <div class="main-title" *ngIf="country === 'lt'">
        {{ 'landing.homepageUplift.hero.title' | translate }}
        <span>
          {{ eventTypes[swiper?.swiperRef.realIndex]?.translation | translate | lowercase }}
        </span>
      </div>
      <jarvis-event-date-selector
        [position]="'location_hero'"
        class="desktop bztt-flex md:bztt-absolute"
        [marketplaceLink]="marketplaceLink"
      ></jarvis-event-date-selector>
      <div>
        <swiper [config]="heroConfig" class="mySwiper">
          <ng-template
            swiperSlide
            *ngFor="let type of eventTypes; let i = index"
            ><img
              class="bztt-rounded-full bztt-shadow-lg"
              [src]="'assets/images/landing/homepage-uplift/' + type.image"
              [alt]="type.name"
          /></ng-template>
        </swiper>
      </div>
      <jarvis-event-date-selector
        class="mobile bztt-flex md:bztt-absolute"
        [marketplaceLink]="marketplaceLink"
        [position]="'location_hero'"
      ></jarvis-event-date-selector>
    </div>
  </div>
</div>
