import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';

import { JarvisUiActionsCardComponent } from './actions-card.component';

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        ButtonModule
    ],
    exports: [
        JarvisUiActionsCardComponent
    ],
    declarations: [
        JarvisUiActionsCardComponent
    ],
    providers: [],
})
export class JarvisUiActionsCardModule { }
