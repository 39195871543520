export abstract class URLUtils {

  private static MAP = "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";

  private static fromHexString(hexString):Uint8Array {
    return Uint8Array.from(hexString.match(/.{1,2}/g)
      .map((byte) => parseInt(byte, 16)));
  }

  private static tob58 = function(src:Uint8Array):string {
    const d=[];
    let s="",i,j,c,n;
    
    for(i in src){
      j=0,c=src[i];
      s+=c||s.length^i?"":1;
      while(j in d||c) {
        n=d[j];
        n=n?n*256+c:c;
        c=n/58|0;
        d[j]=n%58;
        j++;
      }
    }
    while(j--)
      s+=this.MAP[d[j]];
    return s;
  };

  private static fromb58(src:string):string {
    const d=[], b=[];
    let j,c,n;

    for(let i = 0; i < src.length; i++) {
      j=0,c=this.MAP.indexOf(src[i]);
      if(c<0) return undefined;
      c||b.length^i?i:b.push(0);
      while(j in d||c) {
        n=d[j];
        n=n?n*58+c:c;
        c=n>>8;
        d[j]=n%256;
        j++;
      }
    } 
    while(j--)
      b.push(d[j].toString(16).padStart(2, '0'));
 
    return b.join("");
  }
  
  public static encodeUUID(src:string):string {
    return this.tob58(this.fromHexString(src));
  }

  public static decodeUUID(src:string):string {
    if (src.match(/^[0-9a-f]{2,}$/)) return src;
    else return this.fromb58(src)??src;
  }
}    