import { MatDividerModule } from '@angular/material/divider';
import { JarvisUiMarketplaceCardPlannerInfoComponent } from './components/planner-info/planner-info.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { JarvisUiMarketplaceCardDefaultInfoComponent } from './components/default-info/default-info.component';
import { JarvisUiMarketplaceCardLoadingComponent } from './components/loading/loading.component';
import { JarvisUiMarketplaceCardReviewInfoComponent } from './components/review-info/review-info.component';

import { JarvisUiMarketplaceCardComponent } from './marketplace-card.component';
import { PriceUnitLabelPipe } from './pipes/price-unit.pipe';
import { JarvisUiMarketplaceCardPartnerListInfoComponent } from './components/partner-list-info/partner-list-info.component';
import { ServicePipesModule } from '@jarvis/services';
import { NgOptimizedImage } from '@angular/common';
import { JarvisUiMarketplacePhotoSwiperComponent } from './components/photo-swiper/photo-swiper.component';
import { SwiperModule } from 'swiper/angular';
// TODO: Find out why this breaks build
// import { PlannerModule } from '@jarvis/planner';
import { MarketplaceCardTagsContainerComponent } from './components/tags-container/marketplace-card-tags-container.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LinkPipe, LinkQueryParamsPipe } from './pipes/link.pipe';
import { CardListingTypePipe } from './pipes/card-listing-type.pipe';
import { JarvisUiMarketplaceCardDefaultInfoNewComponent } from './components/default-new/default-info-new.component';
import { JarvisUiMarketplaceCardVenuesBlogInfoComponent } from './components/venues-blog-info/venues-blog-info.component';
import { JarvisReadMoreLinkComponent } from '@jarvis/ui';
// import { LinkPipe } from './pipes/link.pipe';

const PUBLIC_COMPONENTS = [
  JarvisUiMarketplaceCardComponent,
  LinkPipe,
  LinkQueryParamsPipe,
  JarvisUiMarketplaceCardDefaultInfoComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    MatTooltipModule,
    MatIconModule,
    RouterModule,
    MatButtonToggleModule,
    ServicePipesModule,
    MatDividerModule,
    NgOptimizedImage,
    SwiperModule,
    MarketplaceCardTagsContainerComponent,
    MatSnackBarModule,
    CardListingTypePipe,
    PriceUnitLabelPipe,
    JarvisUiMarketplaceCardDefaultInfoNewComponent,
    JarvisUiMarketplaceCardVenuesBlogInfoComponent,
    JarvisReadMoreLinkComponent
    
  ],
  exports: [...PUBLIC_COMPONENTS, PriceUnitLabelPipe],
  declarations: [
    ...PUBLIC_COMPONENTS,
    JarvisUiMarketplaceCardDefaultInfoComponent,
    JarvisUiMarketplaceCardReviewInfoComponent,
    JarvisUiMarketplaceCardLoadingComponent,
    JarvisUiMarketplaceCardPartnerListInfoComponent,
    JarvisUiMarketplaceCardPlannerInfoComponent,
    JarvisUiMarketplacePhotoSwiperComponent,
    LinkPipe,
    LinkQueryParamsPipe,
  ],
  providers: [],
})
export class JarvisUiMarketplaceCardModule {}
