
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import {
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription, of } from 'rxjs';
import { JarvisAuthService } from '../../services/auth.service';
import { takeUntil, switchMap, filter } from 'rxjs/operators';
import { JarvisValidators, PasswordRepeatErrorMatcher } from '@jarvis/utils';
import {
  JarvisUiModalService,
  JarvisUiRulesPoliciesComponent,
} from '@jarvis/ui';
import { MessagesType, MESSAGES_TYPE } from '@jarvis/messaging';
import { SuccessDialogComponent } from '@jarvis/ui/src/lib/components/common-dialog/success-modal.component';
import { DOMAIN_COUNTRY, JarvisTrackingService } from '@jarvis/services';

interface RegisterOpenTermsModalAction {
  termsConditions: 'rules' | 'privacy';
}

type RegisterModalActions = RegisterOpenTermsModalAction;

@Component({
  selector: 'jarvis-auth-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class JarvisAuthRegisterComponent implements OnInit, OnDestroy {
  @Input() standalone = false;
  @Input() hideClose = false;
  @Input() hideTerms = false;
  @Input() hideTitle = false;
  @Input() disableLoginNavigation = false;
  @Input() showSuccessfulRegistrationPopup = false;
  @Input() predefinedUserType;
  @Input() emailPrefill;

  @Output() loginClicked = new EventEmitter<void>();
  @Output() registered = new EventEmitter<void>();

  closeButton = false;
  action$ = new Subject<RegisterModalActions>();

  @ViewChild('firstScreen', { static: true }) firstScreenTemplate: any;
  @ViewChild('emailScreen', { static: true }) emailScreenTemplate: any;

  passwordRepeatErrorMatcher = new PasswordRepeatErrorMatcher();
  domainCountry = inject(DOMAIN_COUNTRY);
  trackService = inject(JarvisTrackingService);

  registerFormGroup = new UntypedFormGroup({
    // name: newUntypedFormControl('', Validators.required),
    // surname: newUntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
    passwordGroup: new UntypedFormGroup(
      {
        password: new UntypedFormControl('', [
          Validators.required,
          JarvisValidators.passwordComplexityValidation,
        ]),
        passwordRepeat: new UntypedFormControl('', [
          Validators.required,
          JarvisValidators.passwordComplexityValidation,
        ]),
      },
      {
        validators: [JarvisValidators.repeatPasswordValidation],
      }
    ),
  });

  get passwordGroup(): UntypedFormGroup {
    return this.registerFormGroup.get('passwordGroup') as UntypedFormGroup;
  }

  passwordError$ = JarvisValidators.generateErrorStateMatcher(
    this.registerFormGroup,
    'passwordGroup.password'
  );
  passwordRepeatError$ = JarvisValidators.generateErrorStateMatcher(
    this.registerFormGroup,
    'passwordGroup.passwordRepeat'
  );
  passwordGroupError$ = JarvisValidators.generateErrorStateMatcher(
    this.registerFormGroup,
    'passwordGroup'
  );
  emailError$ = JarvisValidators.generateErrorStateMatcher(
    this.registerFormGroup,
    'email'
  );

  private emailErrorSource$ = new BehaviorSubject(false);
  emailErrorAfter$ = this.emailErrorSource$.asObservable();

  emailTemplate = null;

  private loadingSource$ = new BehaviorSubject(false);
  isLoading$ = this.loadingSource$.asObservable();

  userType: 'user' | 'vendor' = 'user';

  private destroy$ = new Subject<void>();
  navigationState;
  bookingId;
  socialAuthStatusSubscription: Subscription;

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private authService: JarvisAuthService,
    @Optional() public dialogRef: MatDialogRef<any>,
    private socialAuthService: SocialAuthService,
    private modalService: JarvisUiModalService,
    @Inject(MESSAGES_TYPE) private messageType: MessagesType,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const currentNavigation = this.router.getCurrentNavigation();
    this.navigationState = currentNavigation && currentNavigation.extras.state;

    if (this.navigationState && this.navigationState.userType) {
      this.userType = this.navigationState.userType;
    } else {
      this.userType = this.messageType;
    }

    this.bookingId = data?.bookingId;
  }

  ngOnInit(): void {
    if (this.emailPrefill) {
      this.registerFormGroup.get('email').setValue(this.emailPrefill);
    }

    if (this.predefinedUserType) {
      this.userType = this.predefinedUserType;
    }

    this.emailTemplate = this.firstScreenTemplate;

    if (this.dialogRef) {
      this.closeButton = true;
    }

    this.initSocialAuthStateChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.action$.complete();
  }

  showRegistrationSuccessDialog() {
    const dialogRef = this.modalService.openDialog(SuccessDialogComponent, {
      data: { i18nKey: 'register.registrationSuccessfuls' },
    });

    setTimeout(() => dialogRef.close(), 3000);
  }

  reopenLogin() {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName:
        this.predefinedUserType === 'vendor'
          ? 'signup_vendor_page_click'
          : 'signup_host_popup_click',
      data: { signup_type: 'login_register' },
    });

    if (this.disableLoginNavigation) {
      this.loginClicked.emit();
      return;
    }

    const currentQueryParams = this.currentRoute.snapshot.queryParams;
    if (this.standalone) {
      const url = [
        this.domainCountry.toUpperCase() == 'LT' ? '/prisijungti' : 'signin',
      ];
      if (this.currentRoute.snapshot.params?.hash)
        url.push(this.currentRoute.snapshot.params?.hash);
      return this.router.navigate(url, { queryParams: currentQueryParams });
    }

    this.router.navigate(['/auth/login'], { queryParams: currentQueryParams });
    return this.close(true);
  }

  openTermsAndConditionsModal(activePage: 'rules' | 'privacy' = 'rules'): void {
    this.modalService.openDialog(JarvisUiRulesPoliciesComponent, {
      data: {
        activePage: activePage,
      },
    });
  }

  registerWithEmail(): void {
    if (this.registerFormGroup.invalid) {
      this.registerFormGroup.markAllAsTouched();
      return;
    }

    this.loadingSource$.next(true);

    // const localContacts: any = JSON.parse(localStorage.getItem('QUEST_CONTACT_FORM'));
    // const localUserData = {};
    // if (localContacts) {
    //   if (localContacts.name) localUserData['name'] = localContacts.name;
    //   if (localContacts.surname) localUserData['surname'] = localContacts.surname;
    //   if (localContacts.phoneNo) localUserData['phoneNo'] = localContacts.phoneNo;
    // }

    const {
      email,
      passwordGroup: { password },
    } = this.registerFormGroup.getRawValue();

    this.authService
      .signUp({
        email,
        password,
        userType: this.userType,
        bookingId: this.bookingId,
      })
      .pipe(
        switchMap(() => this.authService.getCurrentUser(true)),
        filter((user) => Boolean(user))
      )
      .subscribe(
        () => {
          this.authService.registrationEvent$.next('success');
          this.loadingSource$.next(false);

          if (!this.disableLoginNavigation) {
            this.close(true);
          }
        },
        ({ error }) => {
          this.authService.registrationEvent$.next('fail');
          const errArray: { message: string }[] = error.errors;
          if (errArray) {
            errArray.forEach((errorObject) => {
              if (errorObject.message === 'Email exists') {
                this.registerFormGroup
                  .get('email')
                  .setErrors({ emailTaken: true });
              }
            });
          }

          this.loadingSource$.next(false);
        }
      );
  }

  continueWithEmail(): void {
    if (!this.registerFormGroup.get('email').invalid) {
      this.trackService.handleEvent({
        trackers: ['amplitude', 'mixpanel'],
        eventName:
          this.predefinedUserType === 'vendor'
            ? 'signup_vendor_page_click'
            : 'signup_host_popup_click',
        data: { signup_type: 'login_email' },
      });

      const email = this.registerFormGroup.get('email').value;
      this.authService.signUp({ email }).subscribe(
        () => {
          this.emailTemplate = this.emailScreenTemplate;
        },
        ({ error }) => {
          const errArray = error.errors;
          // console.log(errArray)
          this.registerFormGroup.get('email').markAsTouched();

          if (errArray) {
            for (const errorObject of errArray) {
              if (errorObject.message === 'Email must be valid') {
                this.registerFormGroup.get('email').setErrors({ email: true });
                return;
              }
              if (errorObject.message === 'Email exists') {
                this.registerFormGroup
                  .get('email')
                  .setErrors({ emailTaken: true });
                return;
              }
            }
          }

          this.emailTemplate = this.emailScreenTemplate;
        }
      );
    } else {
      this.registerFormGroup.get('email').markAsTouched();
    }
  }

  registerWithFacebook(): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName:
        this.predefinedUserType === 'vendor'
          ? 'signup_vendor_page_click'
          : 'signup_host_popup_click',
      data: { signup_type: 'login_facebook' },
    });

    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .catch(() => console.log('Something wrong with Facebook Auth'));
  }

  registerWithGoogle(): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName:
        this.predefinedUserType === 'vendor'
          ? 'signup_vendor_page_click'
          : 'signup_host_popup_click',
      data: { signup_type: 'login_google' },
    });

    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .catch(() => console.log('Something wrong with Google Auth'));
  }

  initSocialAuthStateChange() {
    this.socialAuthService.authState
      .pipe(
        // delay(100),
        takeUntil(this.destroy$),
        switchMap((user: SocialUser) => {
          if (user?.provider === 'FACEBOOK')
            return this.authService.facebookRegister(
              user.authToken,
              this.userType
            );
          if (user?.provider === 'GOOGLE')
            return this.authService.googleRegister(
              user.authToken,
              this.userType
            );

          return of(null);
        }),
        filter((info) => !!info)
      )
      .subscribe({
        next: () => {
          this.registered.emit();
          this.authService.registrationEvent$.next('success');
          this.authService.getCurrentUser();
          this.loadingSource$.next(false);

          this.close(true);
        },
        error: () => {
          this.authService.registrationEvent$.next('fail');
          this.registerFormGroup.get('email').setErrors({ emailTaken: true });
          this.loadingSource$.next(false);
        },
      });
  }

  emitWithoutRegistration() {
    console.log('without registration');
    this.navigationState.withoutRegistrationSubject.next();
    this.loadingSource$.next(false);
    this.close(true);
  }

  backToSocial() {
    this.firstScreen();
  }

  firstScreen(): void {
    this.emailTemplate = this.firstScreenTemplate;
  }

  close(success?: boolean): void {
    if (!success) {
      this.authService.registrationEvent$.next('cancel');
    }
    if (this.dialogRef && !this.emailPrefill) {
      this.dialogRef.close(success);
    }
  }
}
