<div
  class="bztt-flex bztt-flex-col bztt-justify-center bztt-items-center bztt-h-full bztt-p-10"
>
  <div
    class="bztt-max-w-[440px] bztt-flex bztt-flex-col bztt-justify-center bztt-items-center"
  >
    <img
      src="/assets/images/bzt-logo.svg"
      alt="Breezit Logo"
      width="90px"
      class="bztt-mb-10"
    />
  </div>
  <jarvis-auth-login [standalone]="true"></jarvis-auth-login>
</div>
