import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {UntypedFormGroup } from '@angular/forms';
import { JarvisUiModalService, JarvisUiRulesPoliciesComponent } from '@jarvis/ui';

@Component({
  selector: 'jarvis-reviews-add-review-contacts',
  templateUrl: './add-review-contacts.component.html',
  styleUrls: ['./add-review-contacts.component.scss']
})
export class AddReviewContactsComponent {

  @Input() contactsForm:UntypedFormGroup;
  @Output() submitEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();

  constructor(
    private modalService: JarvisUiModalService
  ) { }

  openTermsAndConditionsModal(activePage: 'rules' | 'privacy' = 'rules'): void {
    this.modalService.openDialog(JarvisUiRulesPoliciesComponent, {
      data: {
          activePage
      }
  });
  }

  submit(): void {
    this.submitEvent.emit();
  }

  back(): void {
    this.backEvent.emit();
  }

}
