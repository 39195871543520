import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LinkServiceCategories } from '@jarvis/types';

@Component({
  selector: 'jarvis-add-new-planner-item',
  templateUrl: './add-new-planner-item.component.html',
  styleUrls: ['./add-new-planner-item.component.scss']
})
export class AddNewPlannerItemComponent implements OnInit {
  categoryOptions = Object.values(LinkServiceCategories);

  customVendorForm: UntypedFormGroup;
  serviceTypeSelectorActive = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddNewPlannerItemComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.data && this.data.customLike) {
      this.customVendorForm = this.generateCustomVendorForm(this.data.customLike);
    }
  }

  generateCustomVendorForm(customVendor = null) {
    return this.fb.group({
      url: [customVendor && customVendor.url],
      vendorName: [customVendor && customVendor.vendorName, Validators.required],
      contactName: [customVendor && customVendor.contactName, Validators.required],
      budget: customVendor && customVendor.budget,
      email: [customVendor && customVendor.email],
      phoneNumber: customVendor && customVendor.phoneNumber,
      location: customVendor && customVendor.location,
      category: [customVendor && customVendor.category, Validators.required]
    });
  }

  addCustomVendor() {
    // const customVendorMockData = {
    //   businessName: 'Custom businessName',
    //   vendorName: 'Custom vendorName',
    //   contactName: 'Custom contactName',
    //   budget: 4999,
    //   email: 'testas@testas.lt',
    //   phoneNumber: 'Custom phoneNumber',
    //   location: 'Custom location',
    //   category: 'bartenders'
    // };

    this.customVendorForm = this.generateCustomVendorForm();
  }

  save() {
    this.dialogRef.close(this.customVendorForm.getRawValue());
  }

  handleClose() {
    this.dialogRef.close();
  }

  redirectToFeaturedVendors() {
    this.serviceTypeSelectorActive = true;
    // this.router.navigateByUrl('./featured');
    // this.dialogRef.close();
  }

}
