import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jarvis-saas-landing-pricing',
  templateUrl: './saas-landing-pricing.component.html',
  styleUrls: ['./saas-landing-pricing.component.scss']
})
export class SaasLandingPricingComponent implements OnInit {

  membershipsData = {
    labels: {
      bestFor: 'Best for',
      minTerm: 'Minimum term',
      yearMonthPrice: 'Yearly plan',
      yearDiscount: 'Yearly plan discount',
      bookingCommission: 'Booking commission',
      listingCreation: 'Professional listing creation',
      adsAndPromos: 'Advertising & promotions',
      crmEnabled: 'Client management software (CRM)',
      conversation: 'Conversation optimization ',
      paymentsEnabled: 'Contracting, invoicing & payments',
      cardFee: 'Card processing fee (Stripe)',
      // bookingFee: 'Booking fee (Breezit leads)'
    },
    memberships: [
      {
        mostPopular: false,
        name: 'Free profile & CRM',
        price: 'Free',
        bestFor: 'Client onboarding',
        minTerm: 'Unlimited',
        yearMonthPrice: '$0/month',
        yearDiscount: 'n/a',
        bookingCommission: '0%',
        listingCreation: false,
        adsAndPromos: false,
        crmEnabled: true,
        conversation: true,
        paymentsEnabled: true,
        cardFee: '2.9% + $0.30',
        bookingFee: '4%'
      },
      {
        mostPopular: true,
        name: 'Marketing Pro',
        price: {
          monthly: '$300 /month',
          yearly: '$1200 /year'
        },
        bestFor: 'Business growth',
        minTerm: '6 months',
        yearMonthPrice: '$225/month',
        yearDiscount: '25%',
        bookingCommission: '0%',
        listingCreation: true,
        adsAndPromos: true,
        crmEnabled: true,
        conversation: true,
        paymentsEnabled: true,
        cardFee: '2.9% + $0.30',
        bookingFee: '2%'
      },
      {
        mostPopular: false,
        name: 'Marketing Starter',
        price: {
          monthly: '$100 /month',
          yearly: '$600 /year'
        },
        bestFor: 'Business growth',
        minTerm: '6 months',
        yearMonthPrice: '$75/month',
        yearDiscount: '25%',
        bookingCommission: '5%',
        listingCreation: true,
        adsAndPromos: true,
        crmEnabled: true,
        conversation: true,
        paymentsEnabled: true,
        cardFee: '2.9% + $0.30',
        bookingFee: '2%'
      }
    ]
  };

  showOffer = true;

  constructor() { }

  ngOnInit(): void {

  }

}
