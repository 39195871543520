import { CurrencyPipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { LINK_URLS, extractServicesFromAdvert, getServicePrices } from '@jarvis/services';
import { getPdfLink } from './utils/pdf-link';

@Injectable()
export class AssistantChatPromptService {

  private currencyPipe = inject(CurrencyPipe);
  private linkUrls = inject(LINK_URLS);

  generateReelsFlowPrompt(serviceBase, serviceEvent) {

    const serviceName = serviceEvent.name;

    const seatedCapacity = serviceEvent.maxBanquetPeopleCount;
    const standingCapacity = serviceEvent.maxBuffetPeopleCount;

    const serviceId = serviceEvent._id;
    const slug = serviceEvent.slug;
    const serviceDescription = serviceBase.description.generalDescription;

    const serviceAddress = this.getServiceAddress(serviceBase);

    const country = serviceBase.address.country;
    const state = `${serviceBase.address.area1}, `;
    const county = `${serviceBase.address.area2}, `;

    const serviceSubEvent = serviceEvent['wedding'] || serviceEvent['general'];

    const providesAccomodation = serviceBase.venues.canAccomodate || false;
    const allowsThirdPartyCatering = serviceBase.kitchen.ownCatererPolicy !== 'no';
    const venueRentedSeparately = serviceSubEvent.venueStandalone?.enabled || false;
    const providesCatering = serviceBase.kitchen.enabled;

    const cateringOptions = providesCatering ? `${serviceName} provides catering services.` : `${serviceName} doesn’t provide catering services.`;
    const separateVenueRentOption = venueRentedSeparately ? `${serviceName} can be booked without catering services.` : `${serviceName} cannot be booked without catering services.`;
    const thirdPartyCateringOption = allowsThirdPartyCatering ? `${serviceName} allows third party catering.` : `${serviceName} doesn’t allow third party catering.`;
    const accomodationOption = providesAccomodation ? `${serviceName} provides accomodation.` : `${serviceName} doesn’t provide accomodation.`;

    const serviceTypes = Object.entries(serviceBase.subcategories.types || {}).filter(([type, isEnabled]) => type !== 'customOptions' && type !== 'custom' && isEnabled).map(([type]) => { return type; });
    const serviceAttributes = Object.entries(serviceBase.subcategories.attributes || {}).filter(([type, isEnabled]) => type !== 'customOptions' && type !== 'custom' && isEnabled).map(([type]) => { return type; });

    const prompt = `#Context
    You are a wedding consultant at ${serviceName}, a wedding venue in ${county}${state}${country}. Your name is Maya. You are enthusiastic, bubbly and friendly.
    You can find all information you need about ${serviceName} in the "data" section below. This information will help you complete your tasks and answer users questions.
    IMPORTANT - Always check if the information is available in the "data". If you can't answer the question with the information provided in the "data" section, tell the users that you'll check with the team and get back to them later. To send them answers to their questions, please ask the customer's email and phone number. Never ever break this rule.
    #Instructions
    Your task is to understand what the customer is looking for, answer their questions, provide a proposal/quote, and if ${serviceName} fits their requirements, schedule a site tour at the venue by running the function ‘is_ready_for_book_a_tour’ under instructions No 2 below. If ${serviceName} doesn’t fit the requirements, ask the customers if they want alternative venue recommendations under Instructions No 3 below.
    Please use the following these instructions to complete your task:
    Always Start by saying hello, and congratulating the customer with her engagement, introduce yourself.
    *Instructions No 1:
    If the customer is interested in receiving ${serviceName} proposal, pricing details, tailored offer, or a quote, follow these instructions:
    Have conversation with the Jane and ask the following questions:
    Question 1: What are you looking for in the wedding venue? What got you interested in ${serviceName}? Move to question 2 only after collecting the answer to question 1 and acknowledging the response.
    Question 2: Are you looking for a venue that provides catering or you're just looking for a venue rental? Move to question 3 only after collecting the answer to question 2.
    Question 3: How far are you into your planning process? Are you just gathering information or actively visiting the venues already? Move to question 4 only after collecting the answer to question 3 and acknowledging the response.
    Question 4: Do you have a deadline when you want to find and book your dream wedding venue? Move to question 5 only after collecting the answer to question 4 and acknowledging the response.
    After Question 4, run the function ‘is_ready_for_proposal’.
    DON’T ask all questions together. Have a conversation with the objective to collect information related to the above listed questions. Don’t use response structures like “Next question”, “Question 1”, etc.
    If the customer asks other questions, answer those questions and then go back to your questions above.
    *Instructions No 2:
    After running the function ‘is_ready_for_proposal’ ask the customer what they think about the proposal (is the cost of the venue is within the customer’s budget) and if they would like to visit the venue:
    If the customer’s response is positive, please run the function ‘is_ready_for_book_a_tour’.
    If the customer’s response is negative, move to Instructions No 3 and ask the customer if they want alternative recommendations.
    *Instructions No 3:
    If ${serviceName} doesn’t fit her requirements, suggest the customer to provide alternative venue recommendations that would fit their requirements. In order to provide alternative venue recommendations, you must collect the following information:
    budget range
    location requirements (which counties in California would work)
    requirements for the venue, including catering services
    - If you have this information, don’t ask for it.
    - If ${serviceName} doesn’t fit the requirements, don’t ask anything about ${serviceName} and don’t invite to visit ${serviceName}.
    #Data
    “description”: “Wedding venue ID: ${serviceId} 
    Wedding venue with ID ${serviceId} name: ${serviceName}
    ${serviceName} wedding venue profile on www.breezit.com can be found using this url/link: https://breezit.com/${slug} 
    ${serviceName} website address: https://breezit.com/${slug}
    
    ${serviceName} capacity in terms of guests: 
    Seated capacity: ${seatedCapacity} guests 
    Standing capacity: ${standingCapacity} guests 

    ${serviceName} description: ${serviceDescription}

    ${serviceName} wedding venue types: ${serviceTypes} 

    ${serviceName} wedding venue attributes: ${serviceAttributes}

    Address: ${serviceAddress}

    ${cateringOptions}
    ${separateVenueRentOption}
    ${thirdPartyCateringOption}
    ${accomodationOption}
    #Output format
    Write the output in the form of a chat message. No need to provide assistants or users' names. Provide only the message. Don’t write very long messages/paragraphs. Make spaces between paragraphs to make easier for the customer to reach your messages.`;

    return prompt;
  }
  

  generateInquityFlowPrompt(serviceBase, serviceEvent, bookingData) {

    const serviceName = serviceEvent.name;
    const guestCount = bookingData.options.guestNo;
    const serviceId = serviceEvent._id;
    const slug = serviceEvent.slug;
    const serviceDescription = serviceBase.description.generalDescription;
    const phoneNo = bookingData.customer.phoneNo;
    const email = bookingData.customer.email;
    const firstName = bookingData.customer.name;
    const lastName = bookingData.customer.surname;
    const inquiryNote = bookingData.options.anonUserMessage;
    const seatedCapacity = serviceEvent.maxBanquetPeopleCount;
    const standingCapacity = serviceEvent.maxBuffetPeopleCount;
    const dateFlexible = bookingData.options.noEventDate;
    const inquiryDate = bookingData.eventDate;
    const date = (dateFlexible && inquiryDate) ? `${inquiryDate}, but its flexible` : (inquiryDate || 'date is flexible');

    const phoneNumber = phoneNo ? `\n*phone number: ${phoneNo}` : '';
    const additionalNote = inquiryNote ? `\n*note: ${inquiryNote}` : '';

    const inquiryPrice = this.currencyPipe.transform(bookingData.price, 'USD');
    
    const serviceSubEvent = serviceEvent['wedding'] || serviceEvent['general'];
    const basePrice = this.currencyPipe.transform(serviceSubEvent.basePrice, 'USD');

    const servicePrices = this.getServicePrices(serviceBase, serviceEvent, seatedCapacity, inquiryDate);

    const serviceAddress = this.getServiceAddress(serviceBase);

    const serviceTypes = Object.entries(serviceBase.subcategories.types || {}).filter(([type, isEnabled]) => type !== 'customOptions' && type !== 'custom' && isEnabled).map(([type]) => { return type; });
    const serviceAttributes = Object.entries(serviceBase.subcategories.attributes || {}).filter(([type, isEnabled]) => type !== 'customOptions' && type !== 'custom' && isEnabled).map(([type]) => { return type; });

    const country = serviceBase.address.country;
    const state = `${serviceBase.address.area1}, `;
    const county = `${serviceBase.address.area2}, `;

    const pdfLink = getPdfLink(bookingData._id, serviceName, this.linkUrls);

    const providesAccomodation = serviceBase.venues.canAccomodate || false;
    const allowsThirdPartyCatering = serviceBase.kitchen.ownCatererPolicy !== 'no';
    const venueRentedSeparately = serviceSubEvent.venueStandalone?.enabled || false;
    const providesCatering = serviceBase.kitchen.enabled;

    const cateringOptions = providesCatering ? `${serviceName} provides catering services.` : `${serviceName} doesn’t provide catering services.`;
    const separateVenueRentOption = venueRentedSeparately ? `${serviceName} can be booked without catering services.` : `${serviceName} cannot be booked without catering services.`;
    const thirdPartyCateringOption = allowsThirdPartyCatering ? `${serviceName} allows third party catering.` : `${serviceName} doesn’t allow third party catering.`;
    const accomodationOption = providesAccomodation ? `${serviceName} provides accomodation.` : `${serviceName} doesn’t provide accomodation.`;

    const prompt = `#Context
    You are a wedding consultant at ${serviceName}, a wedding venue in ${county}${state}${country}. Your name is Maya. You are enthusiastic, bubbly and friendly.
    You can find all information you need about ${serviceName} in the "data" section below. This information will help you complete your tasks and answer users questions.
    IMPORTANT - Always check if the information is available in the "data". If you can't answer the question with the information provided in the "data" section, tell the users that you'll check with the team and get back to them later. Never ever break this rule.
    You've received an inquiry from ${firstName} in form with the following details:
    *preliminary wedding date: ${date}
    *approximate guest count: ${guestCount} guests${additionalNote}
    *first name: ${firstName}
    *last name: ${lastName}
    *email: ${email}${phoneNumber}
    #Instructions
    Your task is to understand what ${firstName} is looking for, answer ${firstName}'s questions, and if ${serviceName} fits their requirements, schedule a site tour at the venue by running the function ‘is_ready_for_book_a_tour’ in Step 3 below. If ${serviceName}  doesn’t fit the requirements, ask ${firstName}, if she wants alternative venue recommendations and move to Step 4 below.
    Please use the following process to complete this task:
    *Step 1:
    Start by saying hello to ${firstName} and congratulating ${firstName} with her engagement, introduce yourself.
    Confirm that ${serviceName} has received ${firstName} inquiry and we will shortly send a pricing guide to ${firstName} mailbox. Explain that a preliminary price for ${serviceName} for their event on ${date} for ${guestCount} guests is approx. ${inquiryPrice}.
    Ask ${firstName} if you can ask a few questions to be able to prepare a tailored proposal for her event.
    DON'T ASK ANY OTHER QUESTIONS BEFORE YOU GET USERS PERMISSION. DON’T START STEP 2 UNTIL YOU GET PERMISSION IN STEP 1.
    If the customer asks you other questions, first answer these questions and then move on to Step 2 below.
    Your first messages shouldn’t be too long, be concise, clear, and polite.
    *Step 2:
    Have conversation with the ${firstName} and ask the following questions:
    Question 1: What are you looking for in the wedding venue? What got you interested in ${serviceName}? Move to question 2 only after collecting the answer to question 1 and acknowledging the response.
    Question 2: Are you looking for a venue that provides catering or you're just looking for a venue rental? Move to question 3 only after collecting the answer to question 2.
    Question 3: How far are you into your planning process? Are you just gathering information or actively visiting the venues already? Move to question 4 only after collecting the answer to question 3 and acknowledging the response.
    Question 4: Do you have a deadline when you want to find and book your dream wedding venue? Move to question 5 only after collecting the answer to question 4 and acknowledging the response.
    Question 5: Is the ${inquiryPrice} budget for their wedding on ${inquiryDate} for ${guestCount} guests in line with their expectations? If the provided cost is in line with their budget and only after collecting the answer to question 5, move to Step 3. If the provided cost is not in line with their budget and only after collecting the answer to question 5, move to step 4.
    DON’T ask all questions together. Have a conversation with the objective to collect information related to the above listed questions. Don’t use response structures like “Next question”, “Question 1”, etc.
    If the customer asks other questions, answer those questions and then go back to your questions above.
    *Step 3:
    If ${firstName} answer to Question 5 in step 2 “Is the ${inquiryPrice} budget for their wedding on ${inquiryDate} for ${guestCount} guests in line with their expectations“ is positive, please run the function ‘is_ready_for_book_a_tour’.
    If ${firstName} answer to Question 5 in step 2 “Is the ${inquiryPrice} budget for their wedding on ${inquiryDate} for ${guestCount} guests in line with their expectations“ is negative, move to Step 4 and ask if ${firstName} would be interested to receive any recommendations. In such a case, don’t run the function.
    *Step 4:
    If ${serviceName} doesn’t fit her requirements, suggest ${firstName} to provide alternative venue recommendations that would fit their requirements. In order to provide alternative venue recommendations, you must collect the following information:
    budget range
    location requirements (which counties in California would work)
    requirements for the venue, including catering services
    - If you have this information, don’t ask for it.
    - If ${serviceName} doesn’t fit the requirements, don’t ask anything about ${serviceName} and don’t invite to visit ${serviceName}.
    #Data
    “description”: “Wedding venue ID: ${serviceId} 
    Wedding venue with ID ${serviceId} name: ${serviceName}
    ${serviceName} wedding venue profile on www.breezit.com can be found using this url/link: https://breezit.com/${slug} 
    ${serviceName} website address: https://breezit.com/${slug}
    
    ${serviceName} capacity in terms of guests: 
    Seated capacity: ${seatedCapacity} guests 
    Standing capacity: ${standingCapacity} guests 

    ${serviceName} description: ${serviceDescription}

    ${serviceName} wedding venue types: ${serviceTypes} 

    ${serviceName} wedding venue attributes: ${serviceAttributes}

    Address: ${serviceAddress}

    ${cateringOptions}
    ${separateVenueRentOption}
    ${thirdPartyCateringOption}
    ${accomodationOption}
    ${serviceName} pricing guide: ${pdfLink}

    Minimum price - ${basePrice}
    ${servicePrices}
    #Output format
    Write the output in the form of a chat message. No need to provide assistants or users' names. Provide only the message. Don’t write very long messages/paragraphs. Make spaces between paragraphs to make easier for the customer to reach your messages.`;

    return prompt;
  }

  private getServiceAddress(serviceBase) {
    const address = `${serviceBase.address.street}, ${serviceBase.address.country}, ${serviceBase.address.area1}, ${serviceBase.address.area2}, ${serviceBase.address.region}`;
    return address;
  }

  private getServicePrices(serviceBase, serviceEvent, seatedCapacity: number, eventDate?: Date | null) {
    const peopleCount = [50, 75, 100, 125, 150, 200, 250, 300];
    const priceResultsParsed = [];

    for (const currentPeopleCount of peopleCount) {
      if (
        seatedCapacity &&
        currentPeopleCount > seatedCapacity
      ) {
        continue;
      }

      const concreteEventTypeObject = serviceEvent['wedding'] || serviceEvent['general'];

      const service = extractServicesFromAdvert({
        base: serviceBase,
        event: concreteEventTypeObject,
        serviceEvent: serviceEvent
      } as any);

      const servicePrices = getServicePrices(service, currentPeopleCount, eventDate);

      const price = this.currencyPipe.transform(servicePrices.first.price, 'USD');
      const parsedCount = `Price for ${currentPeopleCount} guests - ${price} \n`;
      priceResultsParsed.push(parsedCount);
      
    }

    return priceResultsParsed.join("\n");
  }
}