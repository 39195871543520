import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvertServiceVariant, AdvertsPriceUnits } from '@jarvis/services';
import { ServiceDescriptionUnitPipe } from '../../../pipes/service-description-unit.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { OverTimeDisplayPipe } from '../../../pipes/overtime-display.pipe';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AddCustomDescriptionPipe } from '../../../pipes/custom-description.pipe';

@Component({
  selector: 'jarvis-ui-package-description',
  standalone: true,
  imports: [
    CommonModule,
    ServiceDescriptionUnitPipe,
    AddCustomDescriptionPipe,
    TranslateModule,
    OverTimeDisplayPipe,
  ],
  templateUrl: './package-description.component.html',
  styleUrls: ['./package-description.component.scss'],
})
export class PackageDescriptionComponent implements OnInit, OnDestroy {
  @Input() serviceVariant: AdvertServiceVariant;
  @Input() customDescription: string;
  @Input() currencyCode: string;
  @Input() locale: string;
  @Input() showPricePerUnit = false;
  @Input() date = null;
  @Input() recalculate: Observable<void>;
  @Input() hideServiceChargeAndTaxDescription = false;
  @Input() overideMinimalBudget = null;
  get finalUnitPrice(): number {
    return this.serviceVariant.getFinalUnitPrice(this.date);
  }

  get totalPrice(): number {
    return this.serviceVariant.getFinalPrice(this.date);
  }

  get currentMinimalBudget(): number {
    if (this.overideMinimalBudget) return this.overideMinimalBudget;
    else return this.serviceVariant.getMinimalBudget(this.date);
  }

  priceUnit: string;
  tax: number;
  serviceCharge: number;
  serviceChargeType: string;
  taxChargeType: string;

  accomodationDescription: {
    maxPersonCount: string;
    priceUnit: string;
    startPrice: number;
  } = {
    maxPersonCount: null,
    startPrice: null,
    priceUnit: null,
  };

  Number = Number;

  destroy$ = new Subject<void>();

  orgService;

  ngOnInit(): void {
    if (this.serviceVariant.originalService.description?.priceValue) {
      this.orgService = this.serviceVariant.originalService.description;
    } else {
      this.orgService = this.serviceVariant.originalService;
    }
    if (this.recalculate) {
      this.recalculate.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.recalculatePrice();
      });
    }

    this.priceUnit = this.serviceVariant.priceUnit;
    this.tax = this.serviceVariant.tax;
    this.serviceCharge = this.serviceVariant.serviceCharge;
    this.serviceChargeType =
      this.serviceVariant.serviceChargeType ||
      this.serviceVariant.globalMinimalBudgetType;

    if (this.tax && this.serviceCharge) {
      this.taxChargeType = 'taxCharge';
    } else if (this.tax) {
      this.taxChargeType = 'tax';
    } else if (this.serviceCharge) {
      this.taxChargeType = 'charge';
    }

    let maxAccomodationCount =
      this.serviceVariant.maxQuantity > -1
        ? `${this.serviceVariant.maxQuantity}`
        : '';
    if (
      this.serviceVariant.priceUnit === AdvertsPriceUnits.priceForDoubleRoom
    ) {
      maxAccomodationCount = `${+maxAccomodationCount * 2}`;
    }

    this.accomodationDescription = {
      maxPersonCount: maxAccomodationCount,
      startPrice: this.serviceVariant.getFinalUnitPrice(),
      priceUnit: this.serviceVariant.priceUnit,
    };
  }

  private recalculatePrice(): void {
    this.accomodationDescription.startPrice =
      this.serviceVariant.getFinalUnitPrice(this.date);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
