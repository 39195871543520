import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, map, Observable } from 'rxjs';
import {
  BreezitReviewData,
  BreezitReviewDetails,
  ThirdPartyReview,
  ThirdPartyReviewData,
} from '../reviews.types';
import { User } from '@jarvis/auth';
import { BASE_URL } from '@jarvis/services';
import { ServiceBase, ServiceBaseWithUser } from '@jarvis/types';
import { CommonDialogService } from '@jarvis/ui';

type ServiceBaseWithoutEvent = Omit<ServiceBase, 'serviceEvents'> & {
  user: User;
};

export interface ReviewsInviteLinkBody {
  email?: string;
  name?: string;
  surname?: string;
  phoneNo?: string;
}

export interface ReviewsInviteInfo {
  _id: string;
  serviceBase: ServiceBaseWithoutEvent;
  hash: string;
  used: boolean;
  email: string;
  createdAt: Date;
  updatedAt: Date;
}

// /*
export interface ServiceReviewIndividualScores {
  quality: number;
  value: number;
  flexibility: number;
  responseTime: number;
  professionalism: number;
}

export interface ReviewsBase {
  individualScores: ServiceReviewIndividualScores;
  reviewsCount: number;
  score: number;
  serviceBase: ServiceBaseWithUser;
  updatedAt: Date;
  createdAt: Date;
}

export interface ReviewSummary {
  count: number;
  reviewsBase: ReviewsBase;
  docs: ServiceReview[];
}

export interface ServiceReview {
  email?: string;
  userInfo: any;
  score: number;
  individualScores: ServiceReviewIndividualScores;
  review: string;
  serviceBase?: string;
}

export enum ThirdPartyReviewsProviders {
  google,
  yelp,
  facebook,
}

export interface ThirdPartyReviews {
  _id: string;
  serviceBase: string;
  source: string;
  updatedAt: Date;
  count: number;
  link: string;
  placeId: string;
  rating: number;
  reviews: ThirdPartyReviewsDetail[];
}

export interface ThirdPartyReviewsDetail {
  _id: string;
  createdAt: Date;
  rating: number;
  image: string;
  review: string;
  reviewer: string;
}
//   */

@Injectable({ providedIn: 'root' })
export class ReviewsService {
  getReviewsEndpoint: string;
  generateReviewInviteEndpoint: string;
  useReviewEndpoint: string;
  getReviewEndpoint: string;
  createEditDeleteReplyEndpoint: string;
  reviewEndpoint: string;
  createReviewEndpoint: string;
  createReviewForProfileLinkEndpoint: string;
  verifyReviewEndpoint: string;
  checkReviewEndpoint: string;

  private commonDialogService = inject(CommonDialogService);
  private translateService = inject(TranslateService);

  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    private httpService: HttpClient
  ) {
    this.setEndpoints();
  }

  getServiceReviews(
    serviceBaseId: string,
    paginationOptions = {
      page: '1',
      size: '100',
    }
  ) {
    const params = new HttpParams({
      fromObject: paginationOptions,
    });

    const url = `${this.getReviewsEndpoint}/${serviceBaseId}`;
    return this.httpService.get<BreezitReviewData>(url, { params });
  }

  getReviewInviteInfo(hash: string): Observable<ReviewsInviteInfo> {
    const url = `${this.getReviewEndpoint}/${hash}`;
    return this.httpService.get<ReviewsInviteInfo>(url);
  }

  generateInviteLink(serviceBaseId: string, body: ReviewsInviteLinkBody) {
    const mergedBody = {
      serviceBaseId,
      ...body,
    };

    return this.httpService.post(this.generateReviewInviteEndpoint, mergedBody);
  }

  useReview(hash: string, review: BreezitReviewDetails) {
    const url = `${this.useReviewEndpoint}/${hash}`;
    return this.httpService.post(url, review);
  }

  verifyReview(hash: string) {
    const url = `${this.verifyReviewEndpoint}/${hash}`;
    return this.httpService.get(url);
  }

  checkReview(hash: string) {
    const url = `${this.checkReviewEndpoint}/${hash}`;
    return this.httpService.get(url);
  }

  createReview(bookingId: string, review: BreezitReviewDetails) {
    const data = {
      ...review,
      booking: bookingId,
    };
    return this.httpService.post(this.createReviewEndpoint, data);
  }

  createReviewForProfileLink(review: BreezitReviewDetails) {
    return this.httpService
      .post(this.createReviewForProfileLinkEndpoint, review)
      .pipe(
        catchError((e) => {
          let error = e?.error?.errors?.find(() => true)?.message;
          if (error == 'You have already reviewed this service') {
            error = this.translateService.instant(
              'reviews.errors.alreadyReviewed'
            );
          }
          return this.commonDialogService
            .openSuccessRx(
              'errors.errorMsg',
              'error',
              undefined,
              error ?? 'Error: ' + e?.statusText
            )
            .pipe(map(() => EMPTY));
        })
      );
  }

  createEditDeleteReply(id: string, body: string) {
    const url = `${this.createEditDeleteReplyEndpoint}/${id}`;
    return this.httpService.post(url, {
      reply: body,
    });
  }

  deleteReview(id: string) {
    const url = `${this.reviewEndpoint}/${id}`;
    return this.httpService.delete(url);
  }

  getBooking(id: string) {
    return this.httpService.get(`${this.baseUrl}/marketplace/booking/${id}`);
  }

  getThirdPartyReviews(
    serviceBaseId: string
  ): Observable<ThirdPartyReviewData[]> {
    return this.httpService.get(
      `${this.baseUrl}/reviews/thirdPartyReviews/${serviceBaseId}`
    ) as Observable<ThirdPartyReviewData[]>;
  }

  getSingleThirdPartyReviews(
    reviewsId: string,
    skip: number = 0,
    limit: number = 5
  ): Observable<{ reviews: ThirdPartyReview[]; count: number }> {
    return this.httpService.get(
      `${this.baseUrl}/reviews/getSingleReviews/${reviewsId}?skip=${skip}&limit=${limit}`
    ) as Observable<{ reviews: ThirdPartyReview[]; count: number }>;
  }

  private setEndpoints(): void {
    this.getReviewsEndpoint = `${this.baseUrl}/reviews/getReviews`;
    this.generateReviewInviteEndpoint = `${this.baseUrl}/reviews/generateReviewInvite`;
    this.useReviewEndpoint = `${this.baseUrl}/reviews/useReviewInvite`;
    this.getReviewEndpoint = `${this.baseUrl}/reviews/getReviewInvite`;
    this.createEditDeleteReplyEndpoint = `${this.baseUrl}/reviews/createReply`;
    this.reviewEndpoint = `${this.baseUrl}/reviews/review`;
    this.createReviewEndpoint = `${this.baseUrl}/reviews/newReview`;
    this.createReviewForProfileLinkEndpoint = `${this.baseUrl}/reviews/reviewWithoutAuth`;
    this.verifyReviewEndpoint = `${this.baseUrl}/reviews/verifyReview`;
    this.checkReviewEndpoint = `${this.baseUrl}/reviews/checkReviewInvite`;
  }
}
