<swiper
  [navigation]="true"
  class="functions-swiper"
  [slidesPerView]="1"
  [breakpoints]="{
    '834': {
      slidesPerView: colCount
    }
  }"
>
  <ng-template swiperSlide *ngFor="let item of configuration">
    <div class="slide">
      <div class="item-container">
        <div class="icon-container" [ngStyle]="{ backgroundColor: item.color }">
          <img [src]="'/assets/icons/' + item.icon" alt="" />
        </div>
        <div class="label">{{ item.label }}</div>
      </div>
    </div>
  </ng-template>
</swiper>
