// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  logging: false,
  // agmKey: 'AIzaSyCm70Ifqlbd9YhqL8wgobGwxsY_6-1XBtc'
  agmKey: 'AIzaSyCRbPOFjZygGsJTA858oNxAjnLGZhLymn8',
  mixpanelKey: 'dbf7b4f1626072437a9012464e09639e',
  amplitudeKey: 'ad0a41a856be9b5d83b2b10cdc707238',
  stripeClientID: 'ca_LYAQh05LoauFbsbUQdv7rfZMFwWyi5dZ',
  baseUrl: '',
  baseSocketUrl: '',  
  inviteUrl: 'https://marketplace.breezit.com/on-invitation/',
  marketplaceUrl: 'http://localhost:4201',
  saasUrl: 'http://localhost:4200',
  googleTagManagerId: 'GTM-MH4FBWP',
  ig_app_id: 592799155990414,
  hotjarId: '2948973',
  hotjarIdLT: '3570299',  
  hash: `${new Date().toISOString().replace(/\.|:|-/g,'')}`
};

export const environmentByHostName = [{
  hostName: /^.*ennea\.lt$/,
  inviteUrl: 'http://breezit-dev-mp-en.ennea.lt/on-invitation/',
  marketplaceUrl: 'http://breezit-dev-mp-en.ennea.lt',
  saasUrl: 'http://breezit-dev-en.ennea.lt',
},
{
  hostName: /^.*\.lt$/,
  baseUrl: 'https://devserver.breezit.lt/',
  baseSocketUrl: 'https://devserver.breezit.lt',
  inviteUrl: 'https://marketplace.breezit.lt/on-invitation/',
  marketplaceUrl: 'https://marketplace.breezit.lt',
  saasUrl: 'https://development.breezit.lt'
}
];

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
