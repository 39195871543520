import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { DOMAIN_COUNTRY } from '../../services/url.utils';
import { BASE_URL } from '../../tokens/base-url.token';
import { format } from 'date-fns';
const STORAGE_KEY = 'language';

export const LANGUAGE_TOKEN = new InjectionToken('LANGUAGE_TOKEN');

@Injectable({ providedIn: 'root' })

export class JarvisLanguageService {

  private activeLanguage:string = SupportedLang.en;
  public currentLanguage$: Observable<string> = of(this.activeLanguage);

  constructor(
    private translationService: TranslateService,
    private httpService: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(DOMAIN_COUNTRY) private domainCountry: 'us' | 'lt',
    @Inject(LANGUAGE_TOKEN) private defaultLanguage
  ) {

    this.currentLanguage$ = this.translationService.onLangChange.pipe(
      startWith(this.translationService.currentLang || SupportedLang.en),
      map((langOrEvent) => {
        if (typeof langOrEvent === 'string') {
          return langOrEvent;
        }

        return langOrEvent.lang;
      }),
      shareReplay(1)
    );

    const lsLang = null; //localStorage.getItem(STORAGE_KEY);

    if (lsLang != null && (lsLang in SupportedLang)) {
      this.activeLanguage = lsLang;
    } else {
      this.setLanguageFromDomain();
    }

    if (this.domainCountry === 'us') {
      this.setLanguageFromDomain();
    }

    translationService.setTranslation('en', this.defaultLanguage);
    translationService.setDefaultLang('en');

    translationService.use(this.activeLanguage);


    this.currentLanguage$ = this.translationService.onLangChange.pipe(
      startWith(this.activeLanguage),
      map((langOrEvent) => {
        if (typeof langOrEvent === 'string') {
          return langOrEvent;
        }

        return langOrEvent.lang;
      }),
      shareReplay(1)
    );

  }

  public static formatShortTime(dateObj: Date, locale:string): string {
    switch (locale)  {
      case 'lt-LT': return format(dateObj, 'HH:mm');
      default: return format(dateObj, 'h:mm A');
    }
  }

  translateInitializerFactory() {
    return () => {
      this.translationService.setDefaultLang(this.activeLanguage);
      this.translationService.use(this.activeLanguage).toPromise();
    };
  }

  setActiveLanguage(language: string): void {
    this.activeLanguage = language;
  }

  getActiveLanguage(): string {
    return this.activeLanguage;
  }

  getLocaleByLanguage(): string {
    switch (this.activeLanguage) {
      case SupportedLang.en: return 'en-US';
      case SupportedLang.lt: return 'lt-LT';
    }
    return 'en-US';
  }


  setDefaultLanguageByCountry(country: string): void {
    switch (country?.toLowerCase()) {
     case 'lt': this.setDefaultLanguage(SupportedLang.lt); break;
     default: this.setDefaultLanguage(SupportedLang.en);
    }
  }

  setDefaultLanguage(language: string): void {
    if ((language in SupportedLang)) {
       this.setActiveLanguage(language);
       this.translationService.setDefaultLang(this.activeLanguage);
       this.translationService.use(this.activeLanguage);
       localStorage.setItem(STORAGE_KEY, this.activeLanguage);
    }
  }

  toggleLanguage(): void {
    switch (this.translationService.currentLang) {
      case SupportedLang.en: this.activeLanguage = SupportedLang.lt; break;
      case SupportedLang.lt: this.activeLanguage = SupportedLang.en; break;
    }

    this.translationService.use(this.activeLanguage);
    localStorage.setItem(STORAGE_KEY, this.activeLanguage);

    this.httpService.patch(`${this.baseUrl}/users/updateUser`, { language: this.activeLanguage }).subscribe();
  }

  private setLanguageFromDomain(): void {
    switch (this.domainCountry?.toLowerCase()) {
      case 'lt':  this.activeLanguage  =  SupportedLang.lt; break;
      default:  this.activeLanguage  =  SupportedLang.en; break;
  }
  }

}

export enum SupportedLang {
  en = "en",
  lt = "lt"
}
