import { Pipe, PipeTransform } from '@angular/core';
import { setHours, startOfDay, setMinutes, format } from 'date-fns';

@Pipe({
    name: 'MinuteTimeValue',
    pure: true
})
export class MinuteTimeValuePipe implements PipeTransform {
    transform(value: any, locale: string): any {
        const minutes = value % 60;
        const hours = (value - minutes) / 60;
        
        const dateObj = setMinutes(setHours(startOfDay(new Date()) , hours), minutes);
        if (locale === 'en-US') {
            return format(dateObj, 'h:mm A');
        }

        return format(dateObj, 'HH:mm');
    }
}
