/* eslint-disable @angular-eslint/directive-selector */
import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({ selector: '[noPadding]' })
export class FormFieldNoPaddingDirective implements AfterViewInit {
    constructor(
        private elementRef: ElementRef
    ) {
        
    }

    ngAfterViewInit(): void {
        const wrapperEl: HTMLDivElement = this.elementRef.nativeElement.querySelector('.mat-form-field-wrapper');
        if (wrapperEl) {
            wrapperEl.style.paddingBottom = 'unset';
        }
    }
}