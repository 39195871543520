import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { JarvisCommonDialogModule } from '@jarvis/ui/common-dialog';
import { ButtonModule } from '@jarvis/ui/button';

@Component({
  selector: 'jarvis-profile-selector-dialog',
  templateUrl: './profile-selector-dialog.component.html',
  styleUrls: ['./profile-selector-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    JarvisCommonDialogModule,
    ButtonModule
  ]
})
export class ProfileSelectorDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProfileSelectorDialogComponent>
  ) { }

  ngOnInit(): void { }

  submitProfile(type) {
    this.dialogRef.close(type);
  }

  close() {
    this.dialogRef.close();
  }
}
