import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  DEFAULT_CURRENCY_CODE,
  KeyValueDiffer,
  KeyValueDiffers,
  Optional,
  inject,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { BookingsDataService } from '../../bookings.service';
import { AgreementViewComponent } from '../agreement-view/agreement-view.component';
import * as moment from 'moment';
import {
  BookingDetailsType,
  BookingFacts,
  BookingStateEnum,
  BookingStateKeys,
  BookingsType,
  CustomerCompanyType,
  CustomerPersonType,
  ServiceBase,
  ServiceEvents,
} from '@jarvis/types';
import {
  DOMAIN_COUNTRY,
  ServicesService,
  BASE_URL,
  LINK_URLS,
  LinkUrls,
  JarvisDateAdapterService,
  JarvisCalendarService,
} from '@jarvis/services';
import { CustomerMailComponent } from './customer-mail.component';
import { ReferalEditComponent } from './referal.component';
import { TranslatePipe } from '@ngx-translate/core';
import {
  BookingAction,
  BookingsType as LibBookingsType,
  BookingsUtil,
  ServiceTypeCategoriesEnum,
} from '@jarvis/types';
//import { CauseOfCancellationComponent } from  './cause-of-cancellation.component';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ReviewsService } from '@jarvis/reviews';
import {
  CauseOfCancellationComponent,
  CommonDialogService,
  FileUploaderActionType,
  LayoutService,
} from '@jarvis/ui';
import {
  ServiceCurrencyPipe,
  JarvisLocaleValuesService,
} from '@jarvis/services';
import { URLUtils } from '@jarvis/utils';
import { FormActionType } from '@jarvis/ui/src/lib/components/d-form/d-form.component';
import { MatSelectChange } from '@angular/material/select';
import { BookingDetailsService } from '../../booking-details.service';
import { BookingDetailsOptions } from '../../bookings.types';
import { thumbnailsSettings } from 'lightgallery/plugins/thumbnail/lg-thumbnail-settings';

@Component({
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  providers: [JarvisDateAdapterService],
})
export class DetailComponent implements OnInit, OnDestroy {
  private bookingDetailsService = inject(BookingDetailsService);
  private layoutService = inject(LayoutService);

  get detailData(): BookingsType {
    return this.bookingDetailsService.detailData;
  }

  get differ(): KeyValueDiffer<string, any> {
    return this.bookingDetailsService.differ;
  }

  get options(): BookingDetailsOptions {
    return this.bookingDetailsService.options;
  }

  get prevState(): BookingStateKeys {
    return this.bookingDetailsService.prevState;
  }
  set prevState(val) {
    this.bookingDetailsService.prevState = val;
  }

  get locale(): string {
    return this.bookingDetailsService.locale;
  }

  get userId(): string {
    return this.bookingDetailsService.userId;
  }

  serviceBase: ServiceBase;
  serviceEvent: ServiceEvents;
  mobileMoreMenu = false;
  showMore = false;
  private destroy$ = new Subject<void>();

  // ******** UI states for modals
  public UIStates = UIStatesEnum;
  public uiState = new BehaviorSubject<{
    state: UIStatesEnum;
    initiator?: UIStatesEnum;
  }>({ state: UIStatesEnum.nop });

  public getUIState(state: UIStatesEnum): Observable<boolean> {
    return this.uiState.asObservable().pipe(map((v) => v.state == state));
  }
  public getStateInitiator(): UIStatesEnum | undefined {
    return this.uiState.value.initiator;
  }

  public setUIState(
    state: UIStatesEnum = UIStatesEnum.nop,
    initiator: UIStatesEnum = UIStatesEnum.nop
  ) {
    this.uiState.next({ state, initiator });
  }

  public clearUIState() {
    this.setUIState();
  }
  // *********************************************

  brandName: string;
  moreDescription: { [key: string]: boolean } = {};
  fullCustomerInfo = false;
  bookingsUtil = BookingsUtil;
  shortBookingStateEnum = Object.keys(BookingStateEnum).filter((item) => {
    return item != 'cancelled';
  });
  editState = { customer: false, price: false };
  addFiles: UntypedFormArray = new UntypedFormArray([]);
  guestChanged: Subject<number> = new Subject<number>();
  showMobileSendMenu = false;
  showMobilePreviewMenu = false;

  private linkUrls: LinkUrls = inject(LINK_URLS);

  @ViewChild('menuTrigger') menuTrigger;

  private extAction: BookingAction;
  public blockedTime: any[];

  get facts(): BookingFacts {
    const facts = BookingsUtil.getUIState(
      this.detailData as BookingsType,
      this.detailData.serviceBaseId as ServiceBase,
      'facts'
    );
    return facts;
  }

  constructor(
    private route: ActivatedRoute,
    @Inject(DEFAULT_CURRENCY_CODE) public currencyCode: string,
    private keyValueDiffers: KeyValueDiffers,
    private commonDialogService: CommonDialogService,
    private listService: BookingsDataService,
    private calendarDataService: JarvisCalendarService,
    private router: Router,
    private localeVal: JarvisLocaleValuesService,
    private reviewsService: ReviewsService,
    private translatePipe: TranslatePipe,
    private currencyPipe: ServiceCurrencyPipe,
    private servicesService: ServicesService,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string,
    @Optional() @Inject(BASE_URL) public baseUrl: string
  ) {
    this.bookingDetailsService.hideFooter();
  }

  handleProposalOpen() {
    this.clearUIState();
    if (!this.detailData.options.proposalSent) this.sendProposal();
  }

  handleSendContract(event: any) {
    if (event == 'preview') {
      this.setUIState(
        UIStatesEnum.showAgreementPreview,
        UIStatesEnum.showSendContract
      );
    } else {
      this.update();
      this.setUIState(UIStatesEnum.successSendContract);
    }
  }

  sendProposal = () => {
    this.showMobileSendMenu = false;
    this.setUIState(UIStatesEnum.showSendProposal);
  };

  sendContract = (checkIfLoaded = false) => {
    this.showMobileSendMenu = false;
    if (!checkIfLoaded || !this.detailData.options.customAgreement)
      this.setUIState(UIStatesEnum.showAgreementView);
    else this.setUIState(UIStatesEnum.showSendContract);
  };

  previewContract = () => {
    this.setUIState(
      this.detailData.options.customAgreement
        ? UIStatesEnum.showAgreementPreview
        : UIStatesEnum.showAgreementView
    );
  };

  updateIfChanged() {
    this.bookingDetailsService.updateIfChanged();
  }

  update() {
    this.bookingDetailsService.update();
  }

  public confirmDelete() {
    this.deleteBooking().subscribe();
  }

  getStateIndex() {
    return this.shortBookingStateEnum.indexOf(this.state);
  }

  public changeState(
    key: BookingStateKeys,
    event: MatSelectChange | null = null
  ) {
    try {
      this.detailData.state = key;
      if (BookingsUtil.changeState(this.prevState, this.detailData))
        of(0)
          .pipe(
            switchMap(() =>
              ['signing', 'payment', 'reserved'].includes(key) &&
              this.detailData.options.bookingRequest != 'addedManually'
                ? this.commonDialogService.openConfirmRx(
                    'bookings.confirmChangeState'
                  )
                : of(1)
            ),
            tap((v) => {
              if (v === 1) {
                this.prevState = key;
                this.updateIfChanged();
              } else this.detailData.state = this.prevState;
            })
          )
          .subscribe();
    } catch (e) {
      this.commonDialogService.openSuccess(
        'bookings.stateChangeError',
        'new_releases',
        undefined,
        this.translatePipe.transform('bookings.state.' + key + '.name')
      );
      this.detailData.state = this.prevState;
      if (event) event.source.writeValue(this.prevState);
    }
  }

  public getTotalPaid(): number {
    return (
      Number(this.detailData.paidCash ?? 0) + Number(this.detailData.paid ?? 0)
    );
  }

  fileUploaderAction(evnt: FileUploaderActionType[]) {
    evnt.forEach((oneevnt) => {
      if (oneevnt.type === 'add')
        this.detailData.additions.push({
          url: oneevnt.fileUrl,
          filename: oneevnt.fileName,
        });
    });
    this.update();
  }

  formAction(name: string, evnt: FormActionType<any>) {
    switch (name) {
      case 'person':
      case 'company':
        if (evnt.type == 'submit')
          Object.assign(this.detailData.customer, evnt.model);
        this.editState.customer = false;
        if (
          this.detailData.customer.type == 'company' &&
          'surname' in this.detailData.customer
        )
          delete (this.detailData.customer as CustomerPersonType).surname;
        this.update();
        break;
      case 'price':
        this.editState.price = false;
        if (evnt.type == 'submit') Object.assign(this.detailData, evnt.model);
        this.updateIfChanged();
        break;
    }
  }

  public cancelBooking(): Observable<any> {
    //  this.commonDialogService.openConfirm("bookings.detail.confirmCancel");
    return this.commonDialogService
      .openDialogRx(CauseOfCancellationComponent, {
        panelClass: 'cause-cancel-list-panel',
        i18nKey:
          'bookings.causeOfCancelation' +
          (this.facts.reserved ? '' : 'Request'),
        data: {},
      })
      .pipe(
        tap((v) => {
          if (v) {
            this.prevState = this.detailData.state;
            this.detailData.options.causeOfCancellation = v.cause;
            if (v.cause == 'other')
              this.detailData.options.otherCancelCause = v.otherCause;
            this.detailData.options.stateAtCancellation = this.detailData.state;

            this.listService
              .cancelBooking(this.detailData)
              .pipe(
                tap(() => (this.detailData.state = 'cancelled')),
                switchMap(() =>
                  v.cause != 'alreadyBooked'
                    ? of(0)
                    : this.calendarDataService.createEvent({
                        _id: null,
                        dateFrom: this.detailData.eventDate,
                        dateTo: this.detailData.eventTillDate,
                        type: 'block',
                        dateObject: { title: 'block' },
                        serviceEvent: (
                          this.detailData.serviceEventId as ServiceEvents
                        )._id,
                      })
                )
              )
              .subscribe();
          }
        })
      );
  }

  public restoreBooking() {
    this.listService
      .restoreBooking(this.detailData)
      .subscribe((d) => (this.detailData.state = d.state));
  }

  //   public dateChanged(range: boolean = false) {
  //     this.detailData.eventDate = moment(this.detailData.eventDate).toDate();
  //     //if (!range) {
  //     this.detailData.eventTillDate = this.options.timeScheduling
  //       ? moment(this.detailData.eventDate)
  //           .add(this.options.timePerClient ?? 0, 'minutes')
  //           .toDate()
  //       : moment(this.detailData.eventDate).endOf('day').toDate();
  //     //}
  //     this.updateIfChanged();
  //   }

  //   public dateTillChanged() {
  //     this.detailData.eventTillDate = moment(
  //       this.detailData.eventTillDate
  //     ).toDate();
  //     this.updateIfChanged();
  //   }

  public openedChange(opened: boolean) {
    if (opened) document.body.classList.add('disable-scroll');
    else document.body.classList.remove('disable-scroll');
  }

  public removeFile(index: number) {
    this.commonDialogService.openConfirm('bookings.detail.confirmRemoveFile');
    this.commonDialogService.confirmed().subscribe((confirmed) => {
      if (confirmed) {
        this.detailData.additions.splice(index, 1);
        this.update();
      }
    });
  }

  /******* MAIN LOGIC ***************/

  public deleteBooking(): Observable<any> {
    const d = this.detailData;

    return this.commonDialogService
      .openConfirmRx('bookings.detail.confirmDeleteBooking')
      .pipe(
        switchMap((v) =>
          v === 1 ? this.listService.deleteBooking(d._id) : EMPTY
        ),
        //switchMap(v=>d.options?.calendarId?this.calendarDataService.deleteEvent(d.options.calendarId):of(v)),
        tap(() =>
          this.router.navigate(['../bookings']).then(() => {
            window.location.reload();
          })
        )
      );
  }

  public payment(): Observable<any> {
    const d = this.detailData; //canChangeToPayment
    const paid = this.getTotalPaid();
    const facts = this.facts;
    enum action {
      nop,
      connectAccount,
      payDeposit,
      payBalance,
      pay,
    }

    return of(0).pipe(
      switchMap((v) =>
        this.options.stripeAccount
          ? of(v)
          : this.commonDialogService
              .openConfirmRx('bookings.detail.warningStripeAccount')
              .pipe(
                switchMap((v) => {
                  if (v === 1)
                    this.router.navigate(
                      [
                        '/services/',
                        (
                          this.detailData
                            .serviceBaseId as unknown as ServiceBase
                        )._id,
                        'view',
                        (this.detailData.serviceEventId as ServiceEvents)._id,
                      ],
                      { state: { NoconnectPayment: true } }
                    );
                  return EMPTY;
                })
              )
      ),
      switchMap((v) => (paid === 0 ? this.signContract(false) : of(v))),
      switchMap(() => {
        if (facts.contract === 'standard')
          if (facts.deposit.required)
            if (!facts.deposit.payed)
              return this.commonDialogService
                .openConfirmRx('bookings.detail.warningAgreementDeposit')
                .pipe(
                  switchMap((v) => (v === 1 ? of(action.payDeposit) : EMPTY))
                );
            else
              return this.commonDialogService
                .openConfirmRx('bookings.detail.warningAgreementBalance')
                .pipe(
                  switchMap((v) => (v === 1 ? of(action.payBalance) : EMPTY))
                );
          else
            return this.commonDialogService
              .openConfirmRx('bookings.detail.warningAgreementBalance')
              .pipe(switchMap((v) => (v === 1 ? of(action.pay) : EMPTY)));
        else if (facts.deposit.required)
          if (facts.payment.value == 0)
            return this.commonDialogService
              .openConfirmRx('bookings.detail.warningWOAgreementDeposit')
              .pipe(
                switchMap((v) => (v === 1 ? of(action.payDeposit) : EMPTY))
              );
          else
            return this.commonDialogService
              .openConfirmRx('bookings.detail.warningWOAgreementBalance')
              .pipe(
                switchMap((v) => (v === 1 ? of(action.payBalance) : EMPTY))
              );
        else
          return this.commonDialogService
            .openConfirmRx('bookings.detail.warningWOAgreementPay')
            .pipe(switchMap((v) => (v === 1 ? of(action.pay) : EMPTY)));
      }),
      switchMap((v) =>
        v == action.payBalance
          ? this.listService.bookingNotification(
              this.detailData._id,
              'Invitation to make the outstanding payment'
            )
          : of(v)
      ),
      switchMap(() =>
        d.customerId ? of(MacroActionEnum.nop) : this.inviteCustomer()
      ),
      tap(() => {
        if (paid == 0) {
          if (this.facts.waitingSigning)
            this.detailData.options.stateQueue = ['payment'];
          else this.detailData.state = 'payment';
          this.prevState = this.detailData.state;
          this.updateIfChanged();
        }
      }),
      switchMap((v) =>
        v == MacroActionEnum.inviteSentAndSuccessMessage
          ? of(1)
          : this.commonDialogService.openSuccessRx(
              'bookings.detail.successPaymentAction'
            )
      )
    );
  }

  public inviteCustomerUI(): Observable<any> {
    return of(0).pipe(
      switchMap(() =>
        !(
          this.detailData.invite &&
          this.detailData.invite.filter(
            (itm) =>
              itm.email === this.detailData.customer.email ||
              itm.email === 'anonym'
          ).length > 0
        )
          ? //&& this.detailData.paid===0 && !['signing','payment'].includes(this.detailData.state)
            this.payment()
          : this.inviteCustomer()
      )
    );
  }

  public referPartner(): Observable<any> {
    const item = {
      serviceType: (this.detailData.serviceBaseId as unknown as ServiceBase)
        .type as keyof typeof ServiceTypeCategoriesEnum,
      budget: this.detailData.price,
    };
    return this.listService.bookingReferalsList(item.serviceType).pipe(
      switchMap((lst) =>
        this.commonDialogService
          .openDialogRx(
            ReferalEditComponent,
            {
              i18nKey: 'bookings.referal',
              data: {
                item,
                lst,
                currencyCode: this.currencyCode,
                locale: this.locale,
              },
            },
            'referals'
          )
          .pipe(
            switchMap((v) => (v ? of(v) : EMPTY)),
            switchMap((v) =>
              v?.length > 0
                ? this.listService.saveBookingReferals(
                    this.detailData._id,
                    item.serviceType,
                    this.translatePipe.transform(
                      'serviceTypeCategories.' + item.serviceType
                    ),
                    v
                  )
                : EMPTY
            ),
            switchMap(() =>
              this.commonDialogService.openSuccessRx('bookings.successReferal')
            ),
            switchMap(() => this.cancelBooking())
          )
      )
    );
  }

  public referal(item: any): Observable<any> {
    return this.listService.bookingReferalsList(item.serviceType).pipe(
      switchMap((lst) =>
        this.commonDialogService
          .openDialogRx(
            ReferalEditComponent,
            {
              i18nKey: 'bookings.referalOther',
              data: {
                item,
                lst,
                currencyCode: this.currencyCode,
                locale: this.locale,
              },
            },
            'referals'
          )
          .pipe(
            switchMap((v) => (v ? of(v) : EMPTY)),
            tap((v) => {
              item.referals = v;
              this.update();
            }),
            switchMap((v) =>
              v?.length > 0
                ? this.listService.saveBookingReferals(
                    this.detailData._id,
                    item.serviceType,
                    this.translatePipe.transform(
                      'serviceTypeCategories.' + item.serviceType
                    ),
                    v
                  )
                : EMPTY
            ),
            switchMap(() =>
              this.commonDialogService.openSuccessRx('bookings.successReferal')
            )
          )
      )
    );
  }

  public review(): Observable<any> {
    return of(
      this.detailData?.customerId?.email || this.detailData?.customer?.email
    ).pipe(
      switchMap((v) =>
        v
          ? of(v)
          : this.commonDialogService
              .openDialogRx(CustomerMailComponent, {
                i18nkey: 'bookings.reviewsEMail',
                panelClass: 'customer-email',
              })
              .pipe(
                switchMap((val) => (val ? of(val) : EMPTY)),
                tap((val) => {
                  this.detailData.customer.email = val.email;
                  this.updateIfChanged();
                })
              )
      ),
      switchMap((v) =>
        this.reviewsService
          .generateInviteLink(
            (this.detailData.serviceBaseId as unknown as ServiceBase)._id,
            { email: v }
          )
          .pipe(
            switchMap(() =>
              this.commonDialogService.openSuccessRx(
                'bookings.detail.successReviewAction'
              )
            )
          )
      )
    );
  }

  public isStateVisible(state: BookingStateKeys) {
    return BookingsUtil.isStateVisible(state, this.facts);
  }

  public inviteCustomer(
    showInviteSuccessMsg: boolean = true
  ): Observable<MacroActionEnum> {
    const customer = this.detailData.customer;
    const d = this.detailData;
    enum action {
      nop,
      callInviteFunction,
      callInviteWhenCopyToClipboard,
      callEmptyInvite,
      copyToClipboard,
      firstInviteMsg,
      successSendInviteMsg,
    }

    return of(0).pipe(
      switchMap((v) =>
        d.customerId
          ? this.commonDialogService
              .openSuccessRx('bookings.detail.msgAlreadyJoined')
              .pipe(switchMap(() => EMPTY))
          : of(v)
      ),
      // stop here if already joined
      switchMap((v) =>
        customer.email
          ? of(v)
          : this.commonDialogService
              .openDialogRx(CustomerMailComponent, {
                data: { email: customer.email },
                panelClass: 'customer-email',
              })
              .pipe(
                switchMap((val) => (val ? of(val) : EMPTY)),
                tap((val) => {
                  customer.email = val.email;
                  this.update();
                })
              )
      ),
      switchMap(() =>
        d.invite &&
        d.invite.filter((itm) => itm.email === customer.email && itm.sendEmail)
          .length > 0
          ? this.commonDialogService
              .openConfirmRx('bookings.detail.msgAlreadySentInvite')
              .pipe(
                switchMap((v) =>
                  v === 1
                    ? of(action.nop)
                    : v === 0
                    ? of(action.copyToClipboard)
                    : EMPTY
                )
              )
          : of(action.callEmptyInvite)
      ),
      switchMap((v) =>
        v !== action.callEmptyInvite
          ? of(v)
          : this.listService.createInvite('anonym', d._id).pipe(
              tap((lv) => d.invite.push(lv)),
              switchMap(() =>
                this.commonDialogService
                  .openConfirmRx('bookings.detail.msgInvite')
                  .pipe(
                    switchMap((v) =>
                      v === 1
                        ? of(action.callInviteFunction)
                        : v === 0
                        ? of(action.copyToClipboard)
                        : EMPTY
                    )
                  )
              )
            )
      ),
      // call invite function
      switchMap((v) =>
        ![
          action.callInviteWhenCopyToClipboard,
          action.callInviteFunction,
        ].includes(v)
          ? of(v)
          : this.listService.createInvite(customer.email, d._id).pipe(
              tap((lv) => d.invite.push(lv)),
              switchMap(() =>
                v === action.callInviteFunction
                  ? of(action.successSendInviteMsg)
                  : of(action.copyToClipboard)
              )
            )
      ),
      // Success send invite message
      switchMap((v) =>
        v === action.successSendInviteMsg && showInviteSuccessMsg
          ? this.commonDialogService
              .openSuccessRx('bookings.detail.msgSendInvite')
              .pipe(
                switchMap(() => of(MacroActionEnum.inviteSentAndSuccessMessage))
              )
          : of(MacroActionEnum.nop)
      )
      // copy to clipboard
      //tap(v=>{ if (v===action.copyToClipboard) })
    );
  }

  public signContractUI(): Observable<any> {
    return this.signContract().pipe(switchMap(() => this.payment()));
  }

  //   public dateClass: MatCalendarCellClassFunction<Date> = (cellDate) => {
  //     const dt = moment(cellDate).toDate();
  //     if (
  //       this.blockedTime &&
  //       this.blockedTime.some(
  //         (itm) =>
  //           itm.dateFrom.getTime() <= dt.getTime() &&
  //           itm.dateTo.getTime() >= dt.getTime()
  //       )
  //     )
  //       return 'picker-block-day';
  //     else return '';
  //   };

  public signContract(showInviteSuccessMsg: boolean = false): Observable<any> {
    const d = this.detailData;
    const facts = this.facts;

    enum action {
      nop,
      changeListingForUseStandardContract,
      uploadNewContract,
      viewCustomContract,
      useStandardContract,
    }

    return of(action.useStandardContract).pipe(
      // Must be defined customer agreement
      //switchMap(v=>!d.customerId?this.inviteCustomer(showInviteSuccessMsg).pipe(switchMap(v=>of(action.useStandardContract))):of(v)),

      switchMap((v) =>
        facts.contract != 'unknown'
          ? of(v)
          : this.commonDialogService
              .openConfirmRx('bookings.detail.warningAgreementNotFound')
              .pipe(
                switchMap((v) =>
                  v === 1
                    ? of(action.changeListingForUseStandardContract)
                    : v === 0
                    ? of(action.uploadNewContract)
                    : EMPTY
                )
              )
      ),

      switchMap((v) =>
        facts.contract != 'custom' ? of(v) : of(action.viewCustomContract)
      ),
      // switch to our contract
      switchMap((v) =>
        v === action.changeListingForUseStandardContract
          ? this.listService
              .changeServiceBaseProp(
                (d.serviceBaseId as unknown as ServiceBase)._id,
                { contractExample: { ourContract: true } }
              )
              .pipe(
                tap((v) => ((d.serviceBaseId as unknown as ServiceBase) = v)),
                tap(
                  () =>
                    (this.options.contractState = ContractStateType.standard)
                ),
                switchMap(() => of(action.useStandardContract))
              )
          : of(v)
      ),
      switchMap((v) => (facts.waitingSigning ? of(action.nop) : of(v))),
      switchMap((v) =>
        v !== action.uploadNewContract
          ? of(v)
          : this.agreementView(true, false, false)
      ),
      switchMap((v) =>
        v !== action.viewCustomContract
          ? of(v)
          : this.agreementView(false, false, false)
      )
    );
  }

  public agreementViewUI(): Observable<any> {
    return this.agreementView(false, false, false).pipe(
      switchMap((v) =>
        v == MacroActionEnum.signingInitiated
          ? this.payment()
          : of(MacroActionEnum.nop)
      )
    );
  }

  public handleAgreement(result: any) {
    if (result.changes) this.update();
    let newState = UIStatesEnum.nop;
    console.log(result);
    switch (result.state) {
      case 'addSign':
        newState = UIStatesEnum.showAddSignature;
        break;
      case 'signed':
        newState = UIStatesEnum.showAgreementView;
        break;
      case 'successAddSignClosed':
        newState = UIStatesEnum.showAgreementView;
        break;
      case 'send':
        newState = UIStatesEnum.showSendContract;
        break;
      case 'preview':
        newState = UIStatesEnum.showAgreementPreview;
        break;
      case 'previewPay':
        newState = UIStatesEnum.showAddSignaturePreview;
        break;
      case 'addSignPreviewsigned':
        newState = UIStatesEnum.showPaymentPreview;
        break;
      default:
        if (result.presentationType == 'user')
          newState = this.getStateInitiator() || UIStatesEnum.nop;
    }
    this.setUIState(newState, UIStatesEnum.showAgreementView);
  }

  public agreementView(
    startUpload?: boolean,
    inviteCustomerRequired: boolean = true,
    inviteSignMsg: boolean = true
  ): Observable<any> {
    const d = this.detailData;
    enum action {
      nop,
      changeContractData,
      processSigning,
    }

    return of(0).pipe(
      switchMap((v) =>
        this.options.stripeAccount
          ? of(v)
          : this.commonDialogService
              .openConfirmRx('bookings.detail.warningStripeAccount')
              .pipe(
                switchMap((v) => {
                  if (v === 1)
                    this.router.navigate(
                      [
                        '/services/',
                        (
                          this.detailData
                            .serviceBaseId as unknown as ServiceBase
                        )._id,
                        'view',
                        (this.detailData.serviceEventId as ServiceEvents)._id,
                      ],
                      { state: { NoconnectPayment: true } }
                    );
                  return EMPTY;
                })
              )
      ),

      switchMap(() =>
        this.commonDialogService
          .openDialog(AgreementViewComponent, {
            data: {
              detailData: d,
              base: d.serviceBaseId,
              startUpload: startUpload,
              canChange: this.facts.canChangeContract,
              disableUpload: this.facts.deposit.payed,
            },
            panelClass: 'agreement-view-dialog',
          })
          .afterClosed()
      ),
      switchMap((v) =>
        !v
          ? EMPTY
          : v.state
          ? of(action.processSigning)
          : v.changes
          ? of(action.changeContractData)
          : EMPTY
      ),
      tap((v) => {
        if (v === action.changeContractData) this.update();
      }),
      switchMap((v) =>
        v == action.processSigning && !d.customerId && inviteCustomerRequired
          ? this.inviteCustomer().pipe(
              switchMap(() => of(action.processSigning))
            )
          : of(v)
      ),
      tap((v) => {
        if (v === action.processSigning) {
          this.detailData.state = 'signing';
          this.prevState = this.detailData.state;
          this.update();
        }
      }),
      switchMap((v) =>
        v === action.processSigning && inviteSignMsg && !d.customerId
          ? this.commonDialogService
              .openSuccessRx('bookings.detail.msgSignInvite')
              .pipe(switchMap(() => EMPTY))
          : of(v)
      ),
      switchMap((v) =>
        v === action.processSigning
          ? of(MacroActionEnum.signingInitiated)
          : of(MacroActionEnum.nop)
      )
    );
  }

  public is(control: string): boolean {
    switch (control) {
      case 'inProccessAndDepositPayed':
        return (
          ![
            'cancelled',
            'canceledByCustomer',
            'completed',
            'payment',
            'pendingPayment',
          ].includes(this.detailData.state) &&
          (this.detailData.price != 0 || this.detailData.deposit != 0) &&
          !this.is('balance') &&
          this.is('isSignedAgreement')
        );
      case 'shouldBePayment':
        return [
          'new',
          'communication',
          'meetingBooked',
          'proposalSubmitted',
          'signed',
          'payment',
        ].includes(this.detailData.state);
      case 'isWaitingDeposit':
        return (
          ['payment'].includes(this.detailData.state) &&
          this.detailData.deposit > 0 &&
          this.getTotalPaid() == 0 &&
          this.is('isSignedAgreement')
        );
      case 'inProccess':
        return !['cancelled', 'canceledByCustomer', 'completed'].includes(
          this.detailData.state
        );
      case 'isSignedAgreement':
        return (
          this.options.contractState == ContractStateType.standard ||
          this.detailData.options?.contractSigned
        );
      case 'inCanSignAgreement':
        return (
          [
            'new',
            'communication',
            'meetingBooked',
            'proposalSubmitted',
            'signing',
          ].includes(this.detailData.state) &&
          this.options.contractState != ContractStateType.standard &&
          !this.detailData.options?.contractSigned
        );

      case 'inCanInitiateSign':
        return (
          this.detailData.state != 'signing' && this.is('inCanSignAgreement')
        );
      case 'isWaitingSigning':
        return (
          this.detailData.state == 'signing' && this.is('inCanSignAgreement')
        );
      case 'completed':
        return this.detailData.state == 'completed';
      case 'cancelled':
        return ['cancelled', 'canceledByCustomer'].includes(
          this.detailData.state
        );
      case 'payDeposit':
        return (
          this.detailData.deposit > 0 &&
          this.getTotalPaid() < this.detailData.deposit
        );
      case 'balance':
        return (
          this.detailData.price - this.detailData.discount <=
          this.getTotalPaid()
        );
      case 'canInvite':
        return !this.detailData.customerId;
      case 'canDelete':
        return (
          ['cancelled', 'canceledByCustomer'].includes(this.detailData.state) ||
          !(this.detailData.customerId || this.detailData.invite.length > 0)
        );
      case 'inAddedManually':
        return this.detailData.options.bookingRequest == 'addedManually';
      case 'sendProposal':
        return true;
      case 'sendContract':
        return true;
    }

    return false;
  }

  public toChatRoom(item: BookingsType) {
    if (
      this.facts.empty &&
      this.detailData.options.bookingRequest == 'addedViaEmail'
    )
      this.commonDialogService
        .openConfirmRx('bookings.detail.msgWarnChatIfEmpty')
        .subscribe();
    else this.listService.toChatRoom(item);
  }

  get state(): BookingStateKeys {
    return BookingsUtil.transformState(this.detailData as LibBookingsType);
  }
  set state(newState: BookingStateKeys) {
    this.detailData.state = newState;
  }

  public getFullDate(): string {
    return 'null';
    // if (!this.detailData.eventDate) return null;
    // return (
    //   moment(this.detailData.eventDate).utc().locale(this.locale).format('L') +
    //   (this.detailData.eventTillDate &&
    //   moment(this.detailData.eventTillDate)
    //     .utc()
    //     .startOf('day')
    //     .diff(moment(this.detailData.eventDate).utc().startOf('day'), 'days')
    //     ? ` - ${moment(this.detailData.eventTillDate)
    //         .locale(this.locale)
    //         .format('L')}`
    //     : '')
    // );
  }

  public toTop(elm: HTMLElement) {
    elm.scrollIntoView({ behavior: 'smooth', block: 'start' }); // this will scroll elem to the top
  }

  public warnIfEmpty() {
    this.commonDialogService
      .openConfirmRx('bookings.detail.msgWarnIfEmpty')
      .subscribe();
  }

  private checkStripeAccount(base: ServiceBase): Observable<boolean> {
    if ((base.address?.country ?? 'US') != 'US') return of(true);
    return this.listService.getStripeAccount(base.entityInfo.entity).pipe(
      catchError(() => of(false)),
      switchMap((v) => (v?.charges_enabled ? of(true) : of(false)))
    );
  }

  public preformatHistory(history: any): any {
    switch (history.event) {
      case 'bookingDatesUpdated':
        if (this.options.timeScheduling)
          return {
            v1:
              moment(history.value[0]).format('yyyy-MM-DD hh:mm') +
              (history.value[1]
                ? ' - ' + moment(history.value[1]).format('yyyy-MM-DD hh:mm')
                : ''),
          };
        else {
          if (
            history.value[1] == null ||
            moment(history.value[0])
              .startOf('day')
              .isSame(moment(history.value[1]).startOf('day'))
          )
            return { v1: moment(history.value[0]).format('yyyy-MM-DD') };
          else
            return {
              v1:
                moment(history.value[0]).format('yyyy-MM-DD') +
                ' - ' +
                moment(history.value[1]).format('yyyy-MM-DD'),
            };
        }

      case 'priceUpdate':
        return {
          v1: this.currencyPipe.transform(
            history.value[0],
            this.detailData.serviceBaseId as unknown as ServiceBase
          ),
        };
      default:
        return {};
    }
  }

  ngOnInit(): void {
    this.bookingDetailsService.detailData = this.route.snapshot.data.detail;
    this.bookingDetailsService.initDetailData();

    this.serviceBase = this.detailData.serviceBaseId as ServiceBase;
    this.serviceEvent = this.detailData.serviceEventId as ServiceEvents;

    if (this.detailData.discount == null) this.detailData.discount = 0;
    if (this.detailData.deposit == null) this.detailData.deposit = 0;

    this.bookingDetailsService.userId =
      this.route.snapshot.data?.currentUser?._id;
    this.prevState = this.detailData.state;

    this.detailData?.additions.forEach((item) =>
      this.addFiles.push(
        new UntypedFormGroup({
          fileName: new UntypedFormControl(item.filename),
          fileUrl: new UntypedFormControl(item.url),
        })
      )
    );

    this.bookingDetailsService.differ.booking = this.keyValueDiffers
      .find(this.detailData)
      .create();
    this.bookingDetailsService.differ.options = this.keyValueDiffers
      .find(this.detailData.options)
      .create();

    this.bookingDetailsService.differ.booking.diff(this.detailData);
    this.bookingDetailsService.differ.options.diff(this.detailData.options);

    const service = (Array.isArray(this.detailData.serviceBaseId)
      ? this.detailData.serviceBaseId[0]
      : this.detailData.serviceBaseId) as unknown as ServiceBase;

    const localeValues = this.localeVal.getLocaleValues(service);
    this.bookingDetailsService.currencyCode = localeValues.currencyCode;
    this.bookingDetailsService.locale = localeValues.localeId;

    /************** Financial data collect **************/
    this.options.deposit = {
      required: !service.advances.fullAdvance,
      value: service.advances.price,
      unit: service.advances.priceUnit,
      paymentOption: service.advances.partialPaymentOption,
      paymentDays: service.advances.paymentDays ?? 0,
      fullAdvance: service.advances.fullAdvance,
      type: service.advances.fullAdvance
        ? 3
        : service.advances.partialPaymentOption == 'before_event'
        ? 1
        : 2,
    };

    this.options.timeScheduling = service?.scheduling?.multiClientPerDay
      ? true
      : false; //concreteTimeForClient
    this.options.timePerClient = service?.scheduling?.timePerClient;
    if (service.contractExample.ourContract)
      this.options.contractState = ContractStateType.standard;
    else this.options.contractState = ContractStateType.custom;
    if (service.contractExample?.contractExampleFiles?.length > 0)
      this.options.contractState = ContractStateType.customDefined;
    if (this.detailData.options?.customAgreement)
      this.options.contractState = ContractStateType.customUploaded;
    this.options.listingContractState = this.options.contractState;
    this.options.type = service.type;

    /**************** Additional file collect ****************/
    this.options.files = this.options.files
      .concat(
        service?.contractExample?.contractExampleFiles?.map((item) => ({
          filename: item.fileName,
          url: item.fileUrl,
        })),
        service?.kitchen?.foodMenu?.map((item) => ({
          filename: item.fileName,
          url: item.fileUrl,
        })),
        service?.kitchen?.drinksMenu?.map((item) => ({
          filename: item.fileName,
          url: item.fileUrl,
        })),
        service?.venues?.layoutPlans?.map((item) => ({
          filename: item.fileName,
          url: item.fileUrl,
        })),
        service?.venues?.accomodationPlans?.map((item) => ({
          filename: item.fileName,
          url: item.fileUrl,
        }))
      )
      .filter((item) => item);

    /**************************************************/
    this.checkStripeAccount(service).subscribe(
      (v) => (this.options.stripeAccount = v)
    );
    this.listService
      .getCalendar(
        (this.detailData.serviceEventId as ServiceEvents)._id,
        moment(new Date()).add(-2, 'months').toDate(),
        moment(new Date()).add(2, 'years').toDate()
      )
      .subscribe((v) => {
        if (v)
          this.blockedTime = v
            .filter((itm) => itm.type == 'block')
            .map((itm) => ({
              dateFrom: new Date(itm.dateFrom),
              dateTo: new Date(itm.dateTo),
            }));
        else this.blockedTime = [];
      });

    this.brandName = this.servicesService.extractServiceEventName(
      service,
      (this.detailData.serviceEventId as ServiceEvents)._id
    );

    switch (this.extAction) {
      case BookingAction.pay:
        this.payment().subscribe();
        break;
      case BookingAction.review:
        this.review().subscribe();
        break;
      case BookingAction.inviteCustomer:
        this.checkStripeAccount(service)
          .pipe(
            tap((v) => (this.options.stripeAccount = v)),
            switchMap(() =>
              this.facts.empty ? this.inviteCustomer() : this.payment()
            )
          )
          .subscribe();
        break;
      case BookingAction.signContract:
        this.signContract().subscribe();
        break;
      case BookingAction.wantToBook:
        console.log('wantToBook');
        break;
      case BookingAction.complete:
        this.detailData.state = 'completed';
        this.updateIfChanged();
        break;
      case BookingAction.delete:
        this.deleteBooking().subscribe();
        break;
      case BookingAction.reserve:
        this.detailData.state = 'reserved';
        this.updateIfChanged();
        break;
      //   case BookingAction.serviceEdit:
      //     console.log('service edit');
      //     this.serviceListAction();
      //     break;
    }

    this.prevState = this.detailData.state;

    if (
      this.route.snapshot.data?.openEdit ||
      this.route.snapshot.queryParamMap.get('edit')
    ) {
      this.setUIState(UIStatesEnum.showBookingEdit);
      //this.showBookingEdit.next(true);
    }
  }

  onDetailsEdit() {}

  ngOnDestroy(): void {
    this.bookingDetailsService.hideFooter(false);
    this.destroy$.next();
    this.destroy$.complete();
  }

  get proposalURL() {
    return this.listService.getProposalLink(
      URLUtils.encodeUUID(this.detailData._id as string),
      '/preview'
    );
  }

  get pdfURL() {
    return `${this.linkUrls.marketplace}/proposal/${encodeURIComponent(
      this.detailData.serviceName.replace(/ /g, '-')
    )}/${URLUtils.encodeUUID(this.detailData._id)}`;
  }
  get tourURL() {
    return `${this.linkUrls.marketplace}/${
      (this.detailData?.serviceEventId as any)?.slug
    }/book-a-tour/${this.detailData._id}`;
  }
}

export interface ServiceDetailsListType extends BookingDetailsType {
  isMain: boolean;
}

enum MacroActionEnum {
  nop,
  inviteSentAndSuccessMessage,
  signingInitiated,
}

enum ContractStateType {
  standard,
  custom,
  customDefined,
  customUploaded,
}

interface CustomerCompanyTypeExt extends CustomerCompanyType {
  _email: string;
  _name: string;
  _surname: string;
}

interface CustomerPersonTypeExt extends CustomerPersonType {
  _email: string;
  _name: string;
  _surname: string;
}

enum UIStatesEnum {
  nop,
  showBookingEdit,
  showSendProposal,
  showSendContract,
  successSendProposal,
  showAgreementView,
  showAgreementPreview,
  showAddSignature,
  showAddSignaturePreview,
  successSendContract,
  successAddSign,
  showPaymentPreview,
}
