

<div 
  class='phone-no-container'
  role="group"
  autocomplete="off"
  [formGroup]="parts"
  [attr.aria-labelledby]="formField?.getLabelId()"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
>

  <div>
    <mat-select placeholder="Pick" [typeaheadDebounceInterval]="200" formControlName="internationalCode" (selectionChange)="handleSelectionChange($event)">
      <mat-icon matSuffix>domain</mat-icon>
      <mat-select-trigger class="select-wrapper">
        <ng-container *ngIf="parts.get('internationalCode').value as codeInfo">
          <span class="flag-icon flag-icon-{{codeInfo.code.toLowerCase()}}"></span> {{codeInfo.dialCode}}
        </ng-container>
      </mat-select-trigger>

      <mat-option  *ngFor="let codeInfo of countryCodes" [value]="codeInfo" >
        <span class="flag-icon flag-icon-{{codeInfo.code.toLowerCase()}}"></span> {{codeInfo.name}} {{codeInfo.dialCode}}
      </mat-option>

      <!-- <cdk-virtual-scroll-viewport itemSize="42" style="height: 300px; width: 250px;">
        <mat-option  *cdkVirtualFor="let codeInfo of countryCodes" [value]="codeInfo" >
          <span class="flag-icon flag-icon-{{codeInfo.code.toLowerCase()}}"></span> {{codeInfo.name}} {{codeInfo.dialCode}}
        </mat-option>
      </cdk-virtual-scroll-viewport> -->
    </mat-select>
  </div>
  <div>
    <input
    autocomplete="off"
    appPhoneMask
    [country]="domainCountry"
    class="element"
    formControlName="number"
    aria-label="Number"
    #number
    (input)="handleInput()"
  />
  </div>
</div>
