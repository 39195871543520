<nav
  class="bztt-gap-0 lg:bztt-gap-7 bztt-flex-col lg:bztt-flex-row bztt-items-start bztt-justify-start lg:bztt-items-center lg:bztt-justify-center bztt-pt-5 lg:bztt-pt-0"
>
  <ng-container *ngFor="let link of headerService.menuItems">
    <marketplace-menu-item
      *ngIf="
        (!(headerService.saasHeader$ | async) && link.type !== 'saas') ||
        ((headerService.saasHeader$ | async) &&
          link.type === 'saas' &&
          domainCountry === link.country)
      "
      [link]="!(isLoggedIn$ | async) && link.auth ? 'auth/login' : link.path"
      [notifications]="link.notifications"
      [dropdown]="link.dropdown"
      [onlyIcon]="onlyIcon"
      [disableLink]="link.action"
      [state]="link.state || null"
      (click)="handleMenuItemClick(link)"
      [class.bztt-cursor-pointer]="link.action"
      [showArrow]="link.showArrow"
      class="bztt-flex bztt-justify-center bztt-items-center lg:bztt-w-fit"
    >
      {{ link.text ? link.text : ('header.' + link.label | translate) }}
    </marketplace-menu-item>
  </ng-container>

  <mat-divider
    *ngIf="headerService.isEnabled(viewConstraints.MobileDivider) | async"
    class="lg:bztt-hidden bztt-flex display bztt-w-full bztt-my-2"
  >
  </mat-divider>

  <ng-container *ngIf="!(headerService.saasHeader$ | async)">
    <ng-container *ngIf="isLoggedIn$ | async">
      <div *ngIf="((userData$ | async)?.userType || 'user') === 'vendor'">
        <button
          (click)="moveToSaas()"
          class="menu-button bztt-bg-transparent bztt-border-[#ef5518] border-solid bztt-text-[#ef5518] hover:bztt-bg-[#ef5518] hover:bztt-text-white border-2 bztt-rounded-full bztt-px-4 bztt-whitespace-nowrap bztt-h-10 bztt-font-medium bztt-text-[14px]"
        >
          {{ 'header.vendorPortal' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        (headerService.isEnabled(viewConstraints.ServiceProvider) | async) &&
        (!(isLoggedIn$ | async) ||
          ((userData$ | async)?.userType || 'user') === 'user')
      "
    >
      <a
        [href]="domainCountry === 'lt' ? '/registracija' : '/sign-up'"
        class="bztt-border-b-0 bztt-hidden lg:bztt-flex"
      >
        <button
          class="bztt-bg-transparent bztt-border-[#ef5518] border-solid bztt-text-[#ef5518] hover:bztt-bg-[#ef5518] hover:bztt-text-white border-2 bztt-rounded-full bztt-px-4 bztt-h-10 bztt-font-medium bztt-text-[14px] bztt-whitespace-nowrap"
          (click)="handleTemplateTrck('signup_vendor_click')"
        >
          {{ 'landing.homepage.areYouVendorHeader' | translate }}
        </button>
      </a>
    </ng-container>
    <marketplace-menu-item
      *ngIf="
        !(isLoggedIn$ | async) &&
        (headerService.isEnabled(viewConstraints.ServiceProvider) | async)
      "
      [link]="domainCountry === 'lt' ? '/registracija' : '/sign-up'"
      class="lg:bztt-hidden bztt-flex bztt-items-center"
    >
      {{ 'landing.homepage.areYouVendorHeader' | translate }}
    </marketplace-menu-item>
    <mat-divider
      *ngIf="
        !(isLoggedIn$ | async) &&
        (headerService.isEnabled(viewConstraints.MobileDivider) | async)
      "
      class="lg:bztt-hidden bztt-flex display bztt-w-full bztt-my-2"
    >
    </mat-divider>
  </ng-container>
  <ng-container *ngIf="headerService.saasHeader$ | async">
    <ng-container *ngIf="isLoggedIn$ | async">
      <button
        (click)="moveToSaas()"
        class="menu-button bztt-bg-transparent bztt-border-[#ef5518] border-solid bztt-text-[#ef5518] hover:bztt-bg-[#ef5518] hover:bztt-text-white border-2 bztt-rounded-full bztt-px-4 bztt-whitespace-nowrap bztt-h-10 bztt-font-medium bztt-text-[14px]"
      >
        {{ 'header.vendorPortal' | translate }}
      </button>
    </ng-container>
    <button
      *ngIf="!(isLoggedIn$ | async)"
      (click)="handleVendorSignup('header')"
      class="bztt-hidden lg:bztt-block bztt-bg-transparent bztt-border-[#ef5518] border-solid bztt-text-[#ef5518] hover:bztt-bg-[#ef5518] hover:bztt-text-white border-2 bztt-rounded-full bztt-px-4 bztt-h-10 bztt-font-medium bztt-text-[14px] bztt-whitespace-nowrap"
    >
      {{ 'landing.saas.createAccount' | translate }}
    </button>
  </ng-container>

  <ng-container
    *ngIf="
      !(isLoggedIn$ | async) &&
      (headerService.isEnabled(viewConstraints.AuthSelector) | async)
    "
  >
    <ng-template [ngTemplateOutlet]="profileAuthSelector"></ng-template>
  </ng-container>

  <div
    *ngIf="userData$ | async as user"
    class="profile bztt-self-center bztt-hidden lg:bztt-flex bztt-min-w-fit"
    #profile
    (click)="openProfileDropdown()"
  >
    <img
      *ngIf="
        !(notificationService.newBookingCount$ | async) &&
        !(messagingService.newMessagesCount$() | async)
      "
      [src]="
        user?.profilePhoto ? user?.profilePhoto : 'assets/images/noUser.jpeg'
      "
    />
    <img
      *ngIf="
        (notificationService.newBookingCount$ | async) ||
        (messagingService.newMessagesCount$() | async)
      "
      src="/assets/icons/ic_notification_bell.svg"
    />
    <span
      class="bztt-flex lg:bztt-hidden bztt-text-base bztt-font-semibold bztt-px-2"
    >
      {{ 'header.me' | translate }}
    </span>
    <mat-icon>{{
      showMobileProfileDorpdown ? 'expand_less' : 'expand_more'
    }}</mat-icon>
  </div>
  <div
    *ngIf="true || showMobileProfileDorpdown"
    class="bztt-w-full lg:bztt-hidden bztt-flex"
  >
    <jarvis-profile-dropdown-content
      class="w-100"
    ></jarvis-profile-dropdown-content>
  </div>
</nav>

<ng-template #profileAuthSelector>
  <div class="bztt-w-full lg:bztt-w-auto">
    <div class="bztt-hidden lg:bztt-flex bztt-w-max">
      <div
        class="profile"
        #profile="matMenuTrigger"
        [matMenuTriggerFor]="headerAuthMenu"
        (click)="handleTemplateTrck('profile_click')"
      >
        <img [src]="'assets/images/no_user_icon.svg'" />
        <mat-icon>{{
          profile.menuOpen ? 'expand_less' : 'expand_more'
        }}</mat-icon>
      </div>

      <mat-menu
        yPosition="below"
        xPosition="before"
        class="landing-auth-menu"
        #headerAuthMenu="matMenu"
      >
        <div
          class="dropdown-wrapper mat-elevation-z10 bztt-px-8 bztt-py-6 bztt-w-80"
        >
          <ng-template [ngTemplateOutlet]="registrationDropdown"></ng-template>
        </div>
      </mat-menu>
    </div>

    <div
      class="lg:bztt-hidden bztt-flex bztt-flex-col bztt-gap-5 bztt-justify-center bztt-items-center"
    >
      <div
        *ngIf="!showMobileRegistrationDorpdown"
        class="bztt-w-full bztt-flex bztt-flex-col lg:bztt-gap-5 bztt-gap-0 bztt-justify-start bztt-items-start"
      >
        <button
          class="gaSignup bztt-w-full bztt-h-[40px] bztt-text-[15px] lg:bztt-text-[16px] bztt-font-semibold bztt-text-start"
          (click)="handleHeaderAuth('Signup')"
        >
          {{ 'header.popup-signup' | translate }}
        </button>
        <button
          class="gaLogin bztt-w-full bztt-h-[40px] bztt-text-[15px] lg:bztt-text-[16px] bztt-font-semibold bztt-text-start"
          (click)="handleHeaderAuth('Login')"
        >
          {{ 'header.popup-login' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #registrationDropdown>
  <div>
    <button
      class="bztt-border-[#ef5518] border-solid bztt-bg-[#ef5518] bztt-text-white border-2 bztt-rounded-full bztt-px-4 bztt-h-10 bztt-font-normal gaSignup bztt-w-full bztt-mb-3 bztt-text-sm"
      (click)="handleHeaderAuth('Signup')"
    >
      {{ 'header.popup-signup' | translate }}
    </button>
    <button
      class="bztt-bg-transparent bztt-border-[#ef5518] border-solid bztt-text-[#ef5518] border-2 bztt-rounded-full bztt-px-4 bztt-h-10 bztt-font-normal gaLogin bztt-w-full bztt-text-sm"
      (click)="handleHeaderAuth('Login')"
    >
      {{ 'header.popup-login' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #venueEventTypeSelectorDropdown> </ng-template>
