<jarvis-ui-card-wrapper
  [class.smaller]="type !== null"
  *ngIf="
    !(
      hideIfNoReviews &&
      !thirdPartReviewData?.length &&
      !reviewData?.docs?.length
    )
  "
>
  <h2 [class.smaller]="type !== null" class="bztt-mb-7 bztt-font-maple-r">
    {{ 'reviews.reviews' | translate | titlecase }}
  </h2>
  <mat-tab-group class="bztt-z-0" [selectedIndex]="defTabIndex">
    <mat-tab>
      <ng-template mat-tab-label>
        <div
          (click)="handleTabClick('breezit')"
          class="bztt-py-3 bztt-px-3 md:bztt-py-3 md:bztt-px-5 bztt-flex bztt-flex-row"
        >
          <img class="bztt-h-5 bztt-mr-1" src="assets/images/bzt-logo.svg" />
          <span class="bztt-text-[#717171]">{{
            getBreezitReviewCount() ? '(' + getBreezitReviewCount() + ')' : ''
          }}</span>
        </div>
      </ng-template>
      <ng-container *ngTemplateOutlet="breezitReviews"></ng-container>
    </mat-tab>
    <ng-container *ngFor="let provider of providers">
      <mat-tab *ngIf="isThirdPartyByProvider(provider)">
        <ng-template mat-tab-label>
          <div
            (click)="handleTabClick(provider)"
            class="bztt-py-3 bztt-px-5 bztt-flex bztt-flex-row"
          >
            <img
              class="bztt-h-5 bztt-w-5 bztt-mr-1"
              [src]="'assets/images/reviews-provider-' + provider + '.png'"
            />
            <span class="bztt-text-[#717171]">{{
              ' (' + getThirdPartyByProvider(provider).count + ')'
            }}</span>
          </div>
        </ng-template>

        <ng-container
          *ngTemplateOutlet="
            provider === 'facebook' ? facebookReviews : thirdPartyReviews;
            context: { reviews: getThirdPartyByProvider(provider) }
          "
        ></ng-container>
      </mat-tab> </ng-container
    >>
  </mat-tab-group>

  <ng-template let-reviews="reviews" #facebookReviews>
    <div class="bztt-p-1 bztt-flex bztt-w-full bztt-pb-2 bztt-mt-5">
      <span class="bztt-ml-1 bztt-mr-2" *ngIf="domainCountry === 'us'">
        {{ reviews.count }}
        {{ 'reviews.thirdPartyCount' | translate }}</span
      >
      <span class="bztt-ml-1 bztt-mr-2" *ngIf="domainCountry === 'lt'">
        {{ 'reviews.reviewsCount' | translate }} {{ reviews.count }}
      </span>
    </div>
    <div
      class="bztt-overflow-auto reviews-list bztt-flex bztt-flex-row md:bztt-flex-col bztt-gap-3 bztt-py-3"
    >
      <ng-container *ngFor="let review of reviews.reviews || []">
        <div
          class="bztt-hidden bztt-bg-white md:bztt-flex bztt-border-[#ddd] bztt-border-solid bztt-p-6 bztt-rounded-xl bztt-border bztt-text-sm bztt-min-w-[85%]"
        >
          <div
            class="bztt-h-12 bztt-mx-3 bztt-w-12 bztt-overflow-hidden bztt-shrink-0"
          >
            <img
              appImgFallback
              class="bztt-object-cover bztt-w-full bztt-h-full"
              [src]="
                review.data.profilePictureUrl || 'assets/images/noUser.jpeg'
              "
            />
          </div>
          <div class="bztt-flex-col bztt-flex bztt-w-full">
            <div
              class="bztt-flex-wrap bztt-justify-between bztt-mb-4 bztt-gap-2"
            >
              <div class="bztt-flex-col bztt-inline-flex bztt-text-left">
                <span class="bztt-font-semibold">
                  {{ review.reviewer }}
                </span>
                <span>
                  {{
                    review.data.reviewedOn
                      | date : 'MMM d, y' : undefined : locale
                  }}
                </span>
              </div>
              <!-- <div>
                <div
                  *ngIf="review.data?.recommendation"
                  class="bztt-flex bztt-justify-start bztt-items-center bztt-gap-4"
                >
                  <mat-icon>{{
                    review.data?.recommendation === "doesn't recommend"
                      ? 'thumb_down'
                      : 'thumb_up'
                  }}</mat-icon>
                  <div>
                    {{
                      (review.data?.recommendation === "doesn't recommend"
                        ? 'reviews.doesNotRecommend'
                        : 'reviews.recommends'
                      ) | translate
                    }}
                  </div>
                </div>
              </div> -->
            </div>
            <div *ngIf="reviews" class="bztt-mb-6 bztt-text-justify">
              {{ review.review }}
            </div>
            <div
              class="replied-message"
              *ngIf="
                review.data.replies.length && review.data.replies[0] as reply
              "
            >
              <img alt="Profile photo" [src]="'assets/images/noUser.jpeg'" />

              <div class="text-and-actions">
                <span class="text-bolder">{{ reply.repliedBy }}</span>
                <span>{{ reply.text }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile layout -->
        <div
          class="reviewMobileCard md:bztt-hidden bztt-flex bztt-flex-col bztt-gap-3 bztt-border-[#ddd] bztt-border-solid bztt-p-6 bztt-rounded-xl bztt-border bztt-text-sm bztt-min-w-[85%]"
          #reviewMobileCard
          [class.expanded]="reviewMobileCard['expanded']"
        >
          <div class="reviewMobileCardContainer bztt-relative">
            <div class="bztt-h-12 bztt-w-12 bztt-overflow-hidden bztt-shrink-0">
              <img
                class="bztt-object-cover bztt-w-full bztt-h-full"
                appImgFallback
                [src]="
                  review.data.profilePictureUrl || 'assets/images/noUser.jpeg'
                "
              />
            </div>
            <div #reviewMobileText class="bztt-flex-col bztt-flex bztt-w-full">
              <div
                class="bztt-flex-wrap bztt-justify-between bztt-mb-4 bztt-gap-2"
              >
                <div class="bztt-flex-col bztt-inline-flex bztt-text-left">
                  <span class="bztt-font-semibold">
                    {{ review.reviewer }}
                  </span>
                  <span>
                    {{
                      review.data.reviewedOn
                        | date : 'MMM d, y' : undefined : locale
                    }}
                  </span>
                </div>
                <!-- <div>
                <div
                  *ngIf="review.data?.recommendation"
                  class="bztt-flex bztt-justify-start bztt-items-center bztt-gap-4"
                >
                  <mat-icon>{{
                    review.data?.recommendation === "doesn't recommend"
                      ? 'thumb_down'
                      : 'thumb_up'
                  }}</mat-icon>
                  <div>
                    {{
                      (review.data?.recommendation === "doesn't recommend"
                        ? 'reviews.doesNotRecommend'
                        : 'reviews.recommends'
                      ) | translate
                    }}
                  </div>
                </div>
              </div> -->
              </div>
              <div *ngIf="reviews" class="bztt-mb-6 bztt-text-left">
                {{ review.review }}
              </div>
              <div
                class="replied-message bztt-flex bztt-flex-col"
                *ngIf="
                  review.data.replies.length && review.data.replies[0] as reply
                "
              >
                <img alt="Profile photo" [src]="'assets/images/noUser.jpeg'" />

                <div class="text-and-actions">
                  <span class="text-bolder">{{ reply.repliedBy }}</span>
                  <span>{{ reply.text }}</span>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                !reviewMobileCard['expanded'] &&
                reviewMobileText.clientHeight > 300
              "
              class="more-gradient"
            ></div>
          </div>

          <div
            class="bztt-w-full md:bztt-hidden bztt-bg-[#F9F9F9]"
            *ngIf="reviewMobileText.clientHeight > 300"
          >
            <a
              (click)="
                reviewMobileCard['expanded'] = !reviewMobileCard['expanded']
              "
              class="bztt-text-[#222]"
              >{{
                (reviewMobileCard['expanded']
                  ? 'listing.seeLess'
                  : 'inquiryLink.showMore'
                ) | translate
              }}</a
            >
          </div>
        </div>
      </ng-container>
      <div
        *ngIf="reviews.reviews.length !== reviews.count"
        class="md:bztt-hidden bztt-flex bztt-flex-col bztt-gap-3 bztt-border-[#ddd] bztt-border-solid bztt-p-6 bztt-rounded-xl bztt-border bztt-text-sm bztt-min-w-[85%] bztt-justify-center bztt-items-center"
        (click)="showMoreReviews(reviews)"
      >
        <div
          class="see-more-button bztt-h-20 bztt-w-20 bztt-rounded-full bztt-flex bztt-items-center bztt-justify-center"
        >
          <mat-icon>arrow_forward</mat-icon>
        </div>

        <a>{{ 'reviews.showMoreReviews' | translate }}</a>
      </div>
    </div>
    <div
      class="bztt-hidden md:bztt-flex bztt-w-full bztt-justify-center bztt-pt-3"
    >
      <button
        *ngIf="reviews.reviews.length !== reviews.count"
        mat-button
        class="bztt-text-[#717171]"
        (click)="showMoreReviews(reviews)"
      >
        <a>{{ 'reviews.showMoreReviews' | translate }}</a>
      </button>
    </div>
  </ng-template>

  <ng-template let-reviews="reviews" #thirdPartyReviews>
    <div class="bztt-p-1 bztt-flex bztt-w-full bztt-pb-2 bztt-mt-5">
      <img src="/assets/icons/ic_star_active.svg" /><span
        class="bztt-ml-1 bztt-mr-2"
        >{{ reviews.rating }} · {{ reviews.count }}
        {{ 'reviews.thirdPartyCount' | translate }}</span
      >
    </div>
    <div
      class="bztt-overflow-auto reviews-list bztt-flex bztt-flex-row md:bztt-flex-col bztt-gap-3 bztt-py-3"
    >
      <ng-container *ngFor="let review of reviews.reviews || []">
        <div
          class="bztt-hidden bztt-bg-white md:bztt-flex bztt-border-[#ddd] bztt-border-solid bztt-p-6 bztt-rounded-xl bztt-border bztt-text-sm bztt-min-w-[85%]"
        >
          <div
            [ngSwitch]="review.image == null"
            class="bztt-h-12 bztt-mx-3 bztt-w-12 bztt-overflow-hidden bztt-shrink-0"
          >
            <img
              appImgFallback
              *ngSwitchCase="true"
              class="bztt-object-cover bztt-w-full bztt-h-full"
              src="assets/images/noUser.jpeg"
            />
            <img
              appImgFallback
              *ngSwitchDefault
              class="bztt-object-cover bztt-w-full bztt-h-full"
              [src]="review.image"
            />
          </div>
          <div class="bztt-flex-col bztt-flex bztt-w-full">
            <div class="bztt-flex bztt-justify-between bztt-mb-4">
              <div class="bztt-flex-col bztt-inline-flex bztt-text-left">
                <span class="bztt-font-semibold">
                  {{ review.reviewer }}
                </span>
                <span>
                  {{
                    review.createdAt | date : 'MMM d, y' : undefined : locale
                  }}
                </span>
              </div>
              <div>
                <jarvis-reviews-review-stars
                  [value]="review.rating"
                ></jarvis-reviews-review-stars>
              </div>
            </div>
            <div *ngIf="reviews" class="bztt-mb-6 bztt-text-justify">
              {{ review.review }}
            </div>
          </div>
        </div>
        <!-- Mobile template -->
        <div
          #thirdPartyReviewCard
          [class.expanded]="
            (isMobile$ | async) && thirdPartyReviewCard['expanded']
          "
          class="thirdPartyReviewMobileCard md:bztt-hidden bztt-flex bztt-flex-col bztt-gap-3 bztt-border-[#ddd] bztt-border-solid bztt-p-6 bztt-rounded-xl bztt-border bztt-text-sm bztt-min-w-[85%]"
        >
          <div class="breezitReviewCardContainer bztt-relative">
            <div
              [ngSwitch]="review.image == null"
              class="bztt-h-12 bztt-w-12 bztt-overflow-hidden bztt-shrink-0"
            >
              <img
                *ngSwitchCase="true"
                class="bztt-object-cover bztt-w-full bztt-h-full"
                src="assets/images/noUser.jpeg"
              />
              <img
                *ngSwitchDefault
                class="bztt-object-cover bztt-w-full bztt-h-full"
                [src]="review.image"
              />
            </div>
            <div
              #thirdPartyReviewMobileText
              class="bztt-flex-col bztt-flex bztt-w-full"
            >
              <div class="bztt-flex bztt-justify-between bztt-mb-4">
                <div class="bztt-flex-col bztt-inline-flex bztt-text-left">
                  <span class="bztt-font-semibold">
                    {{ review.reviewer }}
                  </span>
                  <span>
                    {{
                      review.createdAt | date : 'MMM d, y' : undefined : locale
                    }}
                  </span>
                </div>
              </div>
              <div class="bztt-mb-3">
                <jarvis-reviews-review-stars
                  [value]="review.rating"
                ></jarvis-reviews-review-stars>
              </div>
              <div *ngIf="reviews" class="bztt-mb-6 bztt-text-left">
                {{ review.review }}
              </div>
            </div>
            <div
              *ngIf="
                !thirdPartyReviewCard['expanded'] &&
                thirdPartyReviewMobileText.clientHeight > 300
              "
              class="more-gradient"
            ></div>
          </div>
          <div
            class="bztt-w-full md:bztt-hidden bztt-bg-[#F9F9F9]"
            *ngIf="thirdPartyReviewMobileText.clientHeight > 300"
          >
            <a
              (click)="
                thirdPartyReviewCard['expanded'] =
                  !thirdPartyReviewCard['expanded']
              "
              class="bztt-text-[#222]"
              >{{
                (thirdPartyReviewCard['expanded']
                  ? 'listing.seeLess'
                  : 'inquiryLink.showMore'
                ) | translate
              }}</a
            >
          </div>
        </div>
      </ng-container>
    </div>
    <div class="bztt-flex bztt-w-full bztt-justify-center bztt-pt-3">
      <a
        target="_blank"
        [href]="reviews.link"
        (click)="handleShowMoreReviewsTrack(reviews.source)"
        >{{ 'reviews.showMore' | translate }}
      </a>
    </div>
  </ng-template>

  <ng-template #breezitReviews>
    <div class="bztt-pt-3 list-container">
      <jarvis-reviews-review-summary
        [interactive]="false"
        [data]="reviewData"
      ></jarvis-reviews-review-summary>

      <div
        class="reviews-container bztt-flex bztt-flex-row md:bztt-flex-col bztt-gap-3 bztt-overflow-auto bztt-py-3"
      >
        <ng-container *ngFor="let review of reviewData?.docs || []">
          <div
            class="review-container breezitReviewCard"
            #breezitReviewCard
            [class.expanded]="
              (isMobile$ | async) && breezitReviewCard['expanded']
            "
          >
            <div *ngIf="(isMobile$ | async) === false" class="photo">
              <img
                appImgFallback
                alt="Profile photo"
                [src]="
                  review?.user?.profilePhoto
                    ? review.user.profilePhoto
                    : 'assets/images/noUser.jpeg'
                "
              />
            </div>
            <div class="info">
              <div class="header">
                <div *ngIf="(isMobile$ | async) === true" class="photo">
                  <img
                    appImgFallback
                    alt="Profile photo"
                    [src]="
                      review?.user?.profilePhoto
                        ? review.user.profilePhoto
                        : 'assets/images/noUser.jpeg'
                    "
                  />
                </div>
                <div class="user-info">
                  <span class="text-bolder">
                    <ng-container *ngIf="review.user">
                      {{ review.user.name }} {{ review.user.surname }}
                    </ng-container>
                    <ng-container *ngIf="!review.user">
                      {{ review.userInfo.name }} {{ review.userInfo.surname }}
                    </ng-container>
                  </span>
                  <span>
                    {{
                      review.createdAt | date : 'MMM d, y' : undefined : locale
                    }}
                    <ng-container *ngIf="review.verified">
                      ({{ 'reviews.manage.verified' | translate }})
                    </ng-container>
                  </span>
                </div>
                <div class="service-rating">
                  <jarvis-reviews-review-stars
                    cdkOverlayOrigin
                    #ratingOverlayAnchor="cdkOverlayOrigin"
                    [value]="review.score"
                  ></jarvis-reviews-review-stars>

                  <ng-container *ngIf="type !== 'listing'">
                    <ng-container
                      *ngIf="{
                        overlayOpen: false
                      } as vm"
                    >
                      <mat-icon
                        class="bztt-cursor-pointer"
                        (click)="vm.overlayOpen = true"
                        >info_outline</mat-icon
                      >

                      <jarvis-overlay
                        (closed)="vm.overlayOpen = false"
                        *ngIf="vm.overlayOpen"
                        [anchor]="ratingOverlayAnchor"
                      >
                        <jarvis-reviews-list-details
                          [data]="review"
                        ></jarvis-reviews-list-details>
                      </jarvis-overlay>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="breezitReviewCardContainer bztt-relative">
                <div
                  #breezitReviewText
                  class="breezitReviewText bztt-flex-col bztt-flex bztt-w-full"
                >
                  <div class="review-text">
                    <span>
                      {{ review.review }}
                    </span>
                  </div>
                  <ng-container *ngIf="!review.reply">
                    <div class="actions" *ngIf="replyInput === review._id">
                      <div class="reply-field">
                        <mat-form-field>
                          <mat-label>{{
                            'reviews.manage.writeYourReply' | translate
                          }}</mat-label>
                          <textarea
                            #replyInput
                            matInput
                            cdkTextareaAutosize
                          ></textarea>
                          <div matSuffix class="reply-actions">
                            <mat-icon
                              (click)="sendReply(review._id, replyInput)"
                              >send</mat-icon
                            >
                            <mat-icon (click)="cancelReply()">clear</mat-icon>
                          </div>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="actions" *ngIf="type !== 'listing'">
                      <div
                        *ngIf="!review.booking"
                        (click)="deleteReview(review._id)"
                      >
                        <mat-icon> delete </mat-icon>
                        <a>{{ 'reviews.manage.delete' | translate }}</a>
                      </div>
                      <div *ngIf="!replyInput" (click)="addReply(review._id)">
                        <mat-icon> reply </mat-icon>
                        <a>{{ 'reviews.manage.reply' | translate }}</a>
                      </div>
                    </div>
                  </ng-container>

                  <div class="replied-message" *ngIf="review.reply">
                    <div class="photo">
                      <img
                        appImgFallback
                        alt="Profile photo"
                        [src]="
                          vendorProfilePhotoUrl || 'assets/images/noUser.jpeg'
                        "
                      />
                    </div>

                    <div class="text-and-actions">
                      <span class="text-bolder">{{ vendorName }}</span>
                      <span>{{ review.reply }}</span>

                      <div
                        class="reply-field"
                        *ngIf="replyEditInput === review._id"
                      >
                        <mat-form-field>
                          <mat-label>{{
                            'reviews.manage.editYourReply' | translate
                          }}</mat-label>
                          <textarea
                            [value]="review.reply"
                            #replyEditInputField
                            matInput
                            cdkTextareaAutosize
                          ></textarea>
                          <div matSuffix class="reply-actions">
                            <mat-icon
                              (click)="
                                sendEditReply(review._id, replyEditInputField)
                              "
                              >send</mat-icon
                            >
                            <mat-icon (click)="cancelEditReply()"
                              >clear</mat-icon
                            >
                          </div>
                        </mat-form-field>
                      </div>

                      <div
                        class="actions"
                        *ngIf="!replyEditInput && type !== 'listing'"
                      >
                        <div *ngIf="true" (click)="deleteReply(review._id)">
                          <mat-icon> delete </mat-icon>
                          <a>{{ 'reviews.manage.delete' | translate }}</a>
                        </div>
                        <div *ngIf="true" (click)="editReply(review._id)">
                          <mat-icon> edit </mat-icon>
                          <a>{{ 'reviews.manage.edit' | translate }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
                    (isMobile$ | async) &&
                    !breezitReviewCard['expanded'] &&
                    breezitReviewText.clientHeight > 200
                  "
                  class="more-gradient"
                ></div>
              </div>
              <div
                class="bztt-w-full md:bztt-hidden bztt-bg-white"
                *ngIf="
                  (isMobile$ | async) && breezitReviewText.clientHeight > 200
                "
              >
                <a
                  (click)="
                    breezitReviewCard['expanded'] =
                      !breezitReviewCard['expanded']
                  "
                  class="bztt-text-[#222]"
                  >{{
                    (breezitReviewCard['expanded']
                      ? 'listing.seeLess'
                      : 'inquiryLink.showMore'
                    ) | translate
                  }}</a
                >
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</jarvis-ui-card-wrapper>
