<div class="bzt-landing-root">
  <mat-sidenav-container #sidenav>
    <mat-sidenav role="navigation" position="end" fixedInViewport>
      <nav class="bztt-flex bztt-flex-col jus">
        <div class="links bztt-flex bztt-flex-col bztt-gap-y-4">
          <span routerLink="./" class="link-header bztt-pb-1">{{
            'landing.saas.header.solutions' | translate
          }}</span>
          <div routerLink="./" class="bztt-flex bztt-flex-col bztt-gap-y-2">
            <a
              class="link-subheader"
              *ngFor="let solutionItem of sections"
              (click)="navigateToSection(solutionItem.id)"
              >{{ solutionItem.header | translate }}</a
            >
          </div>
          <a
            *ngIf="domainCountry == 'us'"
            (click)="handleTrackClick('pricing_click')"
            routerLink="pricing"
            >{{ 'landing.saas.header.pricing' | translate }}</a
          >
          <a *ngIf="domainCountry !== 'us'" (click)="openPricingPopup()">{{
            'landing.saas.header.pricing' | translate
          }}</a>
          <a *ngIf="domainCountry == 'us'">
            <a
              (click)="handleTrackClick('book_call_click')"
              class="bztt-no-underline bztt-leading-none"
              href="https://meetings-eu1.hubspot.com/eva-from-breezit"
              target="_blank"
              >{{ 'landing.saas.header.bookCall' | translate }}</a
            >
          </a>
          <a class="link vendor-header-login" (click)="openLoginDialog()">{{
            'landing.saas.header.login' | translate
          }}</a>
        </div>
        <a
          [routerLink]="(vendorSignupLink | async).link"
          [queryParams]="{ source: 'vendor-header' }"
          class="bztt-no-underline"
        >
          <button
            *ngIf="domainCountry === 'us'"
            (click)="handleVendorSignup('vendor-header'); sidenav.close()"
            app-button
            color="accent"
            class="vendor-header-get-started bztt-px-4"
          >
            {{ 'landing.saas.createPage' | translate }}
          </button>
        </a>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content #content>
      <main class="bztt-mt-0">
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #landingHeader>
  <div class="landing-header">
    <div class="logo bztt-cursor-pointer" (click)="marketplaceRedirrect()">
      <img src="assets/images/bzt-logo.svg" />
    </div>
    <div
      (click)="sidenav.open()"
      class="mobile-button-container"
      *ngIf="isMobile"
    >
      <mat-icon>menu</mat-icon>
    </div>
    <div class="provider-button-container" *ngIf="!isMobile">
      <ul class="section-navigation-list">
        <!-- Commented by Arturas request
          <li>
            <a
            class="bztt-no-underline bztt-leading-none"
            href="https://meetings-eu1.hubspot.com/eva-from-breezit"
            target="_blank"
            >{{ "landing.saas.bookCall" | translate }} </a
            >
          </li>
        -->
        <li [matMenuTriggerFor]="solutionsMenu" routerLink="./">
          {{ 'landing.saas.header.solutions' | translate }}
        </li>
        <li
          *ngIf="domainCountry == 'us'"
          (click)="handleTrackClick('pricing_click')"
          routerLink="pricing"
        >
          {{ 'landing.saas.header.pricing' | translate }}
        </li>

        <li *ngIf="domainCountry !== 'us'" (click)="openPricingPopup()">
          {{ 'landing.saas.header.pricing' | translate }}
        </li>

        <!-- <li (click)="navigateToSection('faq')">FAQ</li> -->
        <!-- <li>
          <a [href]="marketplaceLandingLink" style="text-decoration: none"
            >Planning an event?</a
          >
        </li> -->
        <li class="vendor-header-login" (click)="openLoginDialog()">
          {{ 'landing.saas.header.login' | translate }}
        </li>
      </ul>
      <a
        [routerLink]="(vendorSignupLink | async).link"
        [queryParams]="{ source: 'vendor-header' }"
        class="bztt-no-underline"
      >
        <button
          (click)="handleVendorSignup('vendor-header')"
          app-button
          class="accent-outline vendor-bottom-getstarted bztt-w-44 bztt-px-4"
        >
          <span>{{ 'landing.saas.header.getStarted' | translate }}</span>
        </button>
      </a>
    </div>
  </div>
</ng-template>

<mat-menu #solutionsMenu="matMenu">
  <button
    *ngFor="let solutionItem of sections"
    mat-menu-item
    (click)="navigateToSection(solutionItem.id)"
  >
    {{ solutionItem.header | translate }}
  </button>
</mat-menu>
