export const howItWorksItems = [
  {
    title: 'Your requirements',
    description:
      'Describe the style, budget, services & locations in California.',
    image: 'assets/images/landing/ai-planner-landing/hiw1.png',
  },
  {
    title: 'AI-venue screening',
    description: 'AI-wedding planner screens >2136 venues in California.',
    image: 'assets/images/landing/ai-planner-landing/hiw2.png',
  },
  {
    title: 'Get venues & pricing',
    description: 'Venue recommendations with detailed, up-to-date pricing.',
    image: 'assets/images/landing/ai-planner-landing/hiw3.png',
  },
  {
    title: 'Book venue visits',
    description:
      'Our concierge team books venue visits based on your schedule.',
    image: 'assets/images/landing/ai-planner-landing/hiw4.png',
  },
];

export const whatWillGetItems = [
  {
    title: 'Venue recommendations',
    description:
      'Breezit AI-wedding planner is trained as a professional wedding planner. It has up-to-date pricing and service data on over 2,136  venues in California, with costs ranging from €1,500 to €40,000.',
    image: 'assets/images/landing/ai-planner-landing/what-will-get-1.png',
  },
  {
    title: 'Instant proposals',
    description:
      'All recommendations will be provided with detailed quotes and service descriptions of each venue without any hidden fees. You will negotiate prices directly with the venues.',
    image: 'assets/images/landing/ai-planner-landing/what-will-get-2.png',
  },
  {
    title: '24/7 concierge team support',
    description:
      'You will have a dedicated concierge team of seasoned professionals who will work with you to schedule tours at any wedding venue based on your and your partner’s availability.',
    image: 'assets/images/landing/ai-planner-landing/what-will-get-3.png',
  },

  {
    title: 'Free photo-album',
    description:
      'Breezit will gift you a $150 photo album from our partner, Liumy Albums, after you book your venue.',
    image: 'assets/images/landing/ai-planner-landing/what-will-get-4.png',
  },
  {
    title: 'Deposit back guarantee',
    description:
      'If you book a recommended venue, you will be covered with a free venue deposit-back guarantee in case of venue cancellation or your material family event.',
    image: 'assets/images/landing/ai-planner-landing/what-will-get-5.png',
  },
];

export const alternatives = [
  {
    header: {
      image: 'assets/images/landing/ai-planner-landing/bzt-logo.png',
      label: 'AI-wedding planner',
      best: true,
    },
    items: [
      {
        checked: true,
        label: 'The biggest venue database in California',
      },
      {
        checked: true,
        label: 'Personalised venue recomendations',
      },
      {
        checked: true,
        label: 'Instant quotes',
      },
      {
        checked: true,
        label: 'No hidden fees',
      },
      {
        checked: true,
        label: '24/7 personal assistance',
      },
      {
        checked: true,
        label: 'Venue deposit back guarantee',
      },
      {
        checked: true,
        label: 'No spam emails and calls',
      },
      {
        checked: true,
        label: 'Affordable service',
      },
    ],
  },
  {
    header: {
      image: 'assets/images/landing/ai-planner-landing/human.png',
      label: 'wedding planners',
    },
    items: [
      {
        checked: false,
        label: 'Limited venue network',
      },
      {
        checked: true,
        label: 'Personalised venue recomendations',
      },
      {
        checked: false,
        label: 'No instant quotes',
      },
      {
        checked: false,
        label: 'Risk of hidden fees',
      },
      {
        checked: false,
        label: 'Limited personal assistance',
      },
      {
        checked: false,
        label: 'No venue deposit back guarantee',
      },
      {
        checked: true,
        label: 'No spam emails and calls',
      },
      {
        checked: false,
        label: 'Starts from $2,500',
      },
    ],
  },
  {
    header: {
      image: 'assets/images/landing/ai-planner-landing/knot-zola-wire.png',
      mobileImage: 'assets/images/landing/ai-planner-landing/knot-zola-wire-mobile.png',
      label: null,
    },
    items: [
      {
        checked: false,
        label: 'Limited venue database',
      },
      {
        checked: false,
        label: 'No transparent venue recommendations ',
      },
      {
        checked: false,
        label: 'No instant quotes',
      },
      {
        checked: true,
        label: 'No hidden fees',
      },
      {
        checked: false,
        label: 'No personal assistance',
      },
      {
        checked: false,
        label: 'No venue deposit back guarantee',
      },
      {
        checked: false,
        label: 'Spam emails and calls',
      },
      {
        checked: true,
        label: 'Affordable service',
      },
    ],
  },
];

export const comments = [
  {
    comment:
      '‘After discovering Breezit, we stopped using other platforms. They simplify venue shopping, and their concierge team is extremely helpful coordinating everything. Without Breezit, we would still be planning our wedding.’',
    name: 'Loren Sy',
    label: 'She is having her wedding in 2024',
    profileImage: 'assets/images/landing/ai-planner-landing/loren-sy.png',
  },
  {
    comment:
      '‘I would recommend Breezit a hundred percent. I really appreciate the hands-on approach of the team in helping me find my venue and booking all the site tours along the way. That was extremely helpful. Absolutely.’',
    name: 'Camille Tsalik',
    label: 'Having her wedding in November 2024',
    profileImage: 'assets/images/landing/ai-planner-landing/camille-tsalik.png',
  },
  {
    comment:
      '‘Breezit was the only platform we used once we found it. Their team made connecting with venues effortless, providing assistance and connections.’',
    name: 'Jason & Erica',
    label: 'They are having their wedding in 2025',
    profileImage: 'assets/images/landing/ai-planner-landing/jason-erica.png',
  },
];
