import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PhoneNoDisplayComponent } from './components/phone-no-display/phone-no-display.component';

@NgModule({
    imports: [
      MatIconModule,
      CommonModule
    ],
    exports: [
      PhoneNoDisplayComponent
    ],
    declarations: [
      PhoneNoDisplayComponent
  ],
    providers: [],
})
export class JarvisUiPhoneNoDisplayModule { }
