import { AfterViewInit, Component, Host, Inject, Injector, OnDestroy, OnInit, Optional, Self, SkipSelf } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LayoutService } from '@jarvis/ui';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { map, take, tap, startWith, takeUntil, filter, switchMap } from 'rxjs/operators';
import { MessagingService } from '../services/messaging.service';
import { MessagingEnvType, MESSAGING_ENV_TYPE } from './services/messages-env.token';
import { MESSAGES_VIEW_SERVICE } from './services/messages-view.token';
import { MessagesService } from './services/messages.service';

declare const HelpCrunch: any

@Component({
  selector: 'messaging-messages',
  templateUrl: 'messages.component.html',
  styleUrls: [
    './messages.component.scss'
  ],
  providers: [
    { provide: MessagesService,
      // Use internal services if they have not been provided by the parents
      useFactory: (parentInjector: Injector, messagesService?: MessagesService) => {
        if (!messagesService) {
          const injector = Injector.create({ providers: [{ provide: MessagesService }], parent: parentInjector });
          messagesService = injector.get(MessagesService);
        }
        return messagesService;
      },        
      deps: [ Injector, [ new Optional(), new SkipSelf(), MessagesService ] ],
    },    
    {
      provide: MESSAGES_VIEW_SERVICE,
      useExisting: MessagesService
    }
  ]
})

export class JarvisMessagesComponent implements OnInit, OnDestroy {
  chatRoomCount$: Observable<number>;
  currentChatroomId$: Observable<string>;
  isMobile$ = this.layoutService.isLaptopObserver;
  currentMobileView$: Observable<any>;
  disableChatRoomPanel$: BehaviorSubject<boolean>;

  desktopView$: Observable<boolean>;

  private destroy$ = new Subject<void>();
  public messageCount0 = new BehaviorSubject(false);

  constructor(
    private messagingService: MessagingService,
    private messagesService: MessagesService,
    private layoutService: LayoutService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    @Optional() @Inject(MESSAGING_ENV_TYPE) public messagingEnv: MessagingEnvType
  ) { }


  ngOnInit() {
    const app = this.messagingEnv?.app ?? 'unknown';
    
    this.disableChatRoomPanel$ = this.messagingService.disableChatRoomPanel$;    

    if (app!=='admin') { 
        HelpCrunch('hideChatWidget');
        this.router.events.pipe(
           takeUntil(this.destroy$)
        ).subscribe(() => {
          HelpCrunch('hideChatWidget');
        });
    }    

    this.currentChatroomId$ = this.messagesService.currentChatroomId$;

    

    //this.chatRoomCount$ = this.messagesService.chatRoomCount$
     this.messagingService.navigateChatroom$.pipe(
        takeUntil(this.destroy$),
        tap(chatRoomId=>chatRoomId?this.setChatRoom(chatRoomId):null),
        //switchMap(chatRoomId => this.messagesService.chatRoomCount$.pipe(tap(x=>console.log(chatRoomId))))
        ).subscribe();


    combineLatest([
      this.messagesService.chatRoomCount$,
      this.currentChatroomId$
    ]).pipe(
      takeUntil(this.destroy$),
      tap(([count, id]) => {
        this.messageCount0.next(count == 0 && !id)
      })).subscribe();

    this.currentMobileView$ = combineLatest([
      this.messagesService.currentMobileView$,
      this.isMobile$,
      this.currentChatroomId$
    ]).pipe(map(([mobileView, isMobile, chatroomId]) => {
      if (!isMobile || !chatroomId) {
        return null;
      }
      if (app!=='admin') { HelpCrunch('hideChatWidget'); }

      return mobileView;
    }));

    this.desktopView$ = combineLatest([
      this.currentChatroomId$,
      this.isMobile$
    ]).pipe(
      map(([currentChatroomId, isMobile]) => {
        if (!isMobile) {
          return true;
        }

        if (!currentChatroomId) {
          return true;
        }

        return false;
      })
    )

    const lastChatroomId = localStorage.getItem('lastChatroom');

    combineLatest([
      this.messagingService.allChatroomCache$,
      this.currentChatroomId$,
      this.isMobile$
    ]).pipe(
      take(1)
    ).subscribe(([chatrooms, currentChatroomId, isMobile]) => {
      // Do not load last chatroom if we are on mobile or we navigated to child view (a concrete chatroom)
      // Note - currently currentChatroomId experiences a race condition (this function loads faster then subcomponent writes the id)

      const childLoaded = !!this.currentRoute.snapshot.firstChild;

      if (isMobile || childLoaded) {
        return;
      }
      if (app!=='admin') {
        if (lastChatroomId && chatrooms.find((chatroom) => chatroom._id === lastChatroomId)) {
           this.setChatRoom(lastChatroomId);
        } else {
        if (chatrooms[0]) {
          this.setChatRoom(chatrooms[0]._id);
        }
        }
      HelpCrunch('hideChatWidget'); 
      }
    });
  }

  ngOnDestroy(): void {
    if (this.messagingEnv?.app != 'admin') HelpCrunch('showChatWidget');
    this.destroy$.next();
    this.destroy$.complete();
  }

  setChatRoom(chatroomId: string): void {
    this.router.navigate([chatroomId], { relativeTo: this.currentRoute });
  }
}
