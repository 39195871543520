import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth/services';
import { take } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class JarvisUserProfilingCompletedGuard implements CanActivate {
    constructor(
        private authService: JarvisAuthService,
        private router: Router
    ) { }

    async canActivate() {
        const user = await this.authService.userData$.pipe(take(1)).toPromise();

        if (!user || (user.profiling?.data && user.profiling.data.completed !== false)) {
            this.router.navigate(['']);
            return false;
        }

        return true;
    }
}