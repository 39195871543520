import { Component, Input, OnInit } from '@angular/core';
import { MarketplaceCardTagComponent } from '../tag/marketplace-card-tag.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ServicePipesModule } from '@jarvis/services';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'jarvis-ui-marketplace-card-tag-container',
  templateUrl: 'marketplace-card-tags-container.component.html',
  imports: [
    CommonModule,
    MarketplaceCardTagComponent,
    MatIconModule,
    TranslateModule,
    ServicePipesModule
  ],
  standalone: true
})

export class MarketplaceCardTagsContainerComponent implements OnInit {
  
  @Input() data: any;
  @Input() cardType: string;

  ngOnInit() { }
}