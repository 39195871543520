import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonDialogService } from './services/common-dialog.service';
import { ModalWrapperComponent } from './modal-wrapper.component';
import { ConfirmDialogComponent } from './confirm-modal.component';
import { SuccessDialogComponent } from './success-modal.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { MatIconModule } from '@angular/material/icon';

//export * from './lib/components/modal-wrapper/modal-wrapper.component';
//export * from './lib/components/confirm-modal/confirm-modal.component';
//export * from './lib/components/success-modal/success-modal.component';
@NgModule({
  declarations: [
    ModalWrapperComponent,
    SuccessDialogComponent,
    ConfirmDialogComponent,    
  ],
  imports: [
    TranslateModule,
    MatIconModule,
    CommonModule,
    ButtonModule
  ],
  exports: [
    ModalWrapperComponent,
    SuccessDialogComponent,
    ConfirmDialogComponent,
  ],
  providers: [
     CommonDialogService,
     TranslatePipe
  ]  
})
export class JarvisCommonDialogModule { }