import { Pipe, PipeTransform } from '@angular/core';
import { ChatRoom } from '../../types/messaging.types';

@Pipe({
    name: 'CustomerPhoneNo'
})

export class CustomerPhoneNoPipe implements PipeTransform {
    transform(chatroom: ChatRoom): string | null {
        const mainProfile = chatroom.users.profile?.find(profile => profile.main);

        if (mainProfile && mainProfile.phoneNo) {
            return mainProfile.phoneNo;
        }

        return null;
    }
}