import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASE_URL, DOMAIN_COUNTRY, ServicesService } from '@jarvis/services';
import { Observable, of } from 'rxjs';
import { PartnerInfo, PartnershipPostRequestData, PartnershipRequestData } from '../types/partnes-list.types';

@Injectable()
export class JarvisPartnersListHttpService {
  private partnerListEndpoint = `${this.baseUrl}/partnerList`;
  private partnerListItemEndpoint = `${this.baseUrl}/partnerListItem`;

  constructor(
    private httpService: HttpClient,
    private servicesService: ServicesService,
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(DOMAIN_COUNTRY) private country: 'lt' | 'us'
  ) { }

  getPartnersListNew(slug: string | null, searchParams): Observable<any> {
    const stringifiedObj: Partial<{
      [key in keyof typeof searchParams]: string;
    }> = {};

    Object.keys(searchParams).forEach((objKey) => {
      stringifiedObj[objKey] = searchParams[objKey] + '';
    });

    stringifiedObj.limit = "24";

    const params = new HttpParams({
      fromObject: stringifiedObj
    });

    return this.httpService.get<any[]>(`${this.baseUrl}/marketplace/search/partnerList/${slug}?country=${this.country.toUpperCase()}`, { params });
  }

  getPartnersList(slug: string | null): Observable<any> {
    return this.httpService.get<any[]>(`${this.partnerListEndpoint}/public/${slug}`);
  }

  postPartnershipRequest(data: PartnershipPostRequestData): Observable<any> {
    return this.httpService.post(`${this.baseUrl}/marketplace/contact`, data);
  }

  getMarketplaceListing(slug: string | null) {
    if (!slug) {
      return of(null);
    }
    return this.servicesService.getMarketplaceListing(slug);
  }

  updatePartnerList(listId, data) {
    return this.httpService.patch(this.partnerListEndpoint + `/${listId}`, data);
  }

  updatePartnerListItem(partnerId, partnerList, data) {
    return this.httpService.patch(this.partnerListItemEndpoint + `/${partnerId}`, { partnerList, ...data });
  }

}
