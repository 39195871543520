<ng-container *ngIf="(isLoggedIn$ | async) && !(isMobile$ | async)">
    <nav>
        <app-menu-item *ngFor="let link of links" [iconName]="link.icon" [link]="link.path"
            [notifications]="link.notifications" [dropdown]="link.dropdown" [onlyIcon]="onlyIcon">
            {{'header.' + link.label | translate}}
        </app-menu-item>
        <app-menu-item *ngIf="domainCountry!='us'" iconName="language" (click)="changeLanguage()" [onlyIcon]="onlyIcon">
            {{'header.language' | translate}}
        </app-menu-item>
    </nav>
</ng-container>
<ng-container *ngIf="!(delayedIsLoggedIn$ | async)">
    <div class='auth-button-container'>
        <button (click)="openLogin()" class='accent-outline' app-button>
            <span>{{'header.login' | translate}}</span>
        </button>
        <button (click)="openRegister()" app-button color="accent">{{'header.register' | translate}}</button>
    </div>
</ng-container>
<span *ngIf="userData$ | async as user">
    <div *ngIf="!(isMobile$ | async)" class='profile' #profile (click)="openProfileDropdown()">
        <img [src]="user?.profilePhoto ? user?.profilePhoto : 'assets/images/noUser.jpeg'">
        <mat-icon>expand_more</mat-icon>
    </div>
    <div *ngIf="isMobile$ | async" class='mobile-menu-icon' (click)="openMobileMenu()">
        <mat-icon>menu</mat-icon>
    </div>
</span>
