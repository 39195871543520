<jarvis-ui-card-wrapper>
  <h2>{{ 'reviews.reviews' | translate | titlecase }}</h2>

  <section
    [formGroup]="reviewForm"
    class="review-container md:bztt-w-[440px]"
    *ngIf="type!=reviewTypeEnum.verify && (nextStepComponent | async) === null"
  >
    <div class="photo-text">
      <div class="photo">
        <img [src]="vendorProfilePhoto || 'assets/images/noUser.jpeg'" />
      </div>
      <span> {{ 'reviews.howWouldRate' | translate }} {{ brandName }}? </span>
    </div>
    <div class="ratings-container">
      <div>
        <div [class.error]="individualScoresForm | StarRatingError: 'quality'">
          <span>{{ 'reviews.serviceQuality' | translate }}</span>
          <span class="error-text">{{
            'reviews.pleaseRateService' | translate
          }}</span>
        </div>
        <jarvis-reviews-review-stars
          [interactive]="true"
          (valueChange)="changeRating($event, 'quality')"
          [value]="reviewForm.get(['individualScores', 'quality']).value"
        >
        </jarvis-reviews-review-stars>
      </div>

      <div>
        <div [class.error]="individualScoresForm | StarRatingError: 'value'">
          <span>{{ 'reviews.value' | translate }}</span>
          <span class="error-text">{{
            'reviews.pleaseRateService' | translate
          }}</span>
        </div>
        <jarvis-reviews-review-stars
          [interactive]="true"
          (valueChange)="changeRating($event, 'value')"
          [value]="reviewForm.get(['individualScores', 'value']).value"
        >
        </jarvis-reviews-review-stars>
      </div>

      <div>
        <div
          [class.error]="individualScoresForm | StarRatingError: 'flexibility'"
        >
          <span>{{ 'reviews.flexibility' | translate }}</span>
          <span class="error-text">{{
            'reviews.pleaseRateService' | translate
          }}</span>
        </div>
        <jarvis-reviews-review-stars
          [interactive]="true"
          (valueChange)="changeRating($event, 'flexibility')"
          [value]="reviewForm.get(['individualScores', 'flexibility']).value"
        >
        </jarvis-reviews-review-stars>
      </div>

      <div>
        <div
          [class.error]="individualScoresForm | StarRatingError: 'responseTime'"
        >
          <span>{{ 'reviews.responseTime' | translate }}</span>
          <span class="error-text">{{
            'reviews.pleaseRateService' | translate
          }}</span>
        </div>
        <jarvis-reviews-review-stars
          [interactive]="true"
          (valueChange)="changeRating($event, 'responseTime')"
          [value]="reviewForm.get(['individualScores', 'responseTime']).value"
        >
        </jarvis-reviews-review-stars>
      </div>

      <div>
        <div
          [class.error]="
            individualScoresForm | StarRatingError: 'professionalism'
          "
        >
          <span>{{ 'reviews.professionalism' | translate }}</span>
          <span class="error-text">{{
            'reviews.pleaseRateService' | translate
          }}</span>
        </div>
        <jarvis-reviews-review-stars
          [interactive]="true"
          [value]="
            reviewForm.get(['individualScores', 'professionalism']).value
          "
          (valueChange)="changeRating($event, 'professionalism')"
        ></jarvis-reviews-review-stars>
      </div>
    </div>
    <div>
      <mat-form-field>
        <textarea
          cdkTextareaAutosize
          [cdkAutosizeMinRows]="7"
          [cdkAutosizeMaxRows]="10"
          matInput
          placeholder="{{ 'reviews.writeAboutExperience' | translate }}"
          formControlName="review"
        ></textarea>
        <mat-error>{{ 'errors.fieldRequired' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <!-- <jarvis-ui-file-uploader
            class='uploader'
            title="Add photos (optional)"
        ></jarvis-ui-file-uploader> -->

    <ng-container [ngSwitch]="loggedIn$ | async">
      <button *ngSwitchCase="true" app-button color="accent" (click)="submit()">
        {{ 'reviews.submit' | translate }}
      </button>

      <button
        *ngSwitchDefault
        app-button
        color="accent"
        (click)="handleNextButton()"
      >
        {{ 'reviews.next' | translate }}
      </button>
    </ng-container>
  </section>

  <ng-container *ngTemplateOutlet="components; context: { component: (nextStepComponent | async) }"></ng-container>
  <ng-template #components  let-component="component">
    
    <jarvis-reviews-add-review-contacts
      *ngIf="component == reviewStepEnum.contacts"
      [contactsForm]="contactsForm"
      (submitEvent)="contactSubmit()"
      (backEvent)="switchToFirstScreen()"
    ></jarvis-reviews-add-review-contacts>

    <jarvis-reviews-success-engage
      *ngIf="component == reviewStepEnum.success"
      [successHeader]="'thankYouForReview'"
    ></jarvis-reviews-success-engage>

    <jarvis-reviews-verify *ngIf="component == reviewStepEnum.verify" 
        (submitEvent)="verify($event)">
    </jarvis-reviews-verify>

    <div
      *ngIf="component == reviewStepEnum.registration"
      class="registration-container"
    >
      <jarvis-auth-register></jarvis-auth-register>
    </div>

    <div 
      *ngIf="component == reviewStepEnum.error">
      <a routerLink="/" class="bztt-no-underline border-0">
        <section class="bztt-text-center bztt-border-none">
            <mat-icon class="bztt-text-red-700 bztt-w-fit bztt-h-fit bztt-text-6xl" >error</mat-icon>
            <h1 class="mb-3 mb-lg-4">
                {{ errorMsg | translate }}
            </h1>
        </section>      
      </a>
    </div>

    <jarvis-reviews-success-engage
      *ngIf="component == reviewStepEnum.confirmationSent"
      [successHeader]="'confirmationSent'"
    ></jarvis-reviews-success-engage>    

  </ng-template>
</jarvis-ui-card-wrapper>
