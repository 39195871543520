import { inject, NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes,
  ROUTES as NG_ROUTES,
  ActivatedRouteSnapshot,
  createUrlTreeFromSnapshot,
} from '@angular/router';
import { VideoPresentationGuard } from './shared/guards/video-presentation.guard';
import { AdminMoveSessionGuard } from './site/login/admin-move-session.guard';
import { LoginActivationGuard } from './site/login/login-activation.guard';
import { CalendarGoogleAuth } from './site/adverts/services/calendar/calendar-google-auth.component';
import { AppComponent } from './app.component';
import {
  JarvisUserProfilingCompletedGuard,
  JarvisUserProfilingGuard,
} from '@jarvis/user-profiling';
import { InstagramAuth } from './site/adverts/services/instagram-connect/instagram-auth.component';
import {
    JarvisAuthService,
  JarvisBackAfterLoginGuard,
  JarvisBackAfterRegisterGuard,
} from '@jarvis/auth';
import { ClaimServiceComponent } from './site/adverts/services/claim-service/claim-service.component';
import { StripeAuth } from './site/adverts/services/payments/stripe-auth.component';
import { DOMAIN_COUNTRY, DomainCountry } from '@jarvis/services';
import {
  BookingsNotRegisteredDetailResolver,
  CurrentUserResolver,
  DetailComponent,
} from '@jarvis/saved-bookings';
import { map } from 'rxjs';

const SHOWCASE_MODULE_CONFIG = {
  path: 'showcase',
  loadChildren: () =>
    import('./shared/showcase/showcase.module').then((m) => m.ShowcaseModule),
};

function routesFactory(country: DomainCountry) {
  const bookingRoutes = [];
  
  if (country === 'us') {
    bookingRoutes.push({
      path: 'bookings',
      loadChildren: () =>
        import('@jarvis/saved-bookings').then((m) => m.SavedBookingsModule),
    });
  } else {
    bookingRoutes.push({
      path: 'bookings',
      loadChildren: () =>
        import('./site/bookings/bookings.module').then((m) => m.BookingsModule),
    });
  }

  return [
    {
      path: 'onboarding',
      canActivate: [JarvisUserProfilingCompletedGuard],
      loadChildren: () =>
        import('@jarvis/user-profiling').then(
          (m) => m.JarvisUserProfilingModule
        ),
    },
    {
      path: 'claim-listing/:name/:hash',
      component: ClaimServiceComponent,
      canActivate: [JarvisBackAfterRegisterGuard],
    },
    {
      path: 'bookings/',
      canActivate: [JarvisBackAfterLoginGuard],
      loadChildren: () =>
        import('@jarvis/saved-bookings').then((m) => m.SavedBookingsModule),
    },
    {
        path: 'bookings/detail/:id/ed',
        redirectTo: 'bookings/detail/:id/nouser?edit=true',
        pathMatch : 'full'
    },
    {
      path: 'bookings/detail/:id/nouser',
      canActivate: [
        (next: ActivatedRouteSnapshot) => {
          return inject(JarvisAuthService).isLoggedIn$
            .pipe(
              map((isLoggedIn) => 
                isLoggedIn ? createUrlTreeFromSnapshot(next, ['..'], next.queryParams):true
              )
            );
        },
      ],
      
      resolve: {
        detail: BookingsNotRegisteredDetailResolver,
        currentUser: CurrentUserResolver,
      },
      providers: [BookingsNotRegisteredDetailResolver, CurrentUserResolver],
      component: DetailComponent,
    },
    {
      path: '',
      component: AppComponent,
      canActivate: [JarvisUserProfilingGuard],
      children: [
        {
          path: 'login',
          canActivate: [LoginActivationGuard],
          loadChildren: () =>
            import('./site/login/login.module').then((m) => m.LoginModule),
        },
        {
          path: 'calendar/google-auth',
          component: CalendarGoogleAuth,
        },
        {
          path: 'ig/auth',
          component: InstagramAuth,
        },
        {
          path: 'stripe/auth',
          component: StripeAuth,
        },
        {
          path: 'services',
          canActivate: [JarvisBackAfterLoginGuard],
          loadChildren: () =>
            import('./site/adverts/adverts.module').then(
              (m) => m.AdvertsModule
            ),
        },
        {
          path: 'calendar',
          canActivate: [JarvisBackAfterLoginGuard],
          loadChildren: () =>
            import('./site/calendar/calendar.module').then(
              (m) => m.CalendarEventsModule
            ),
        },
        ...bookingRoutes,
        {
          path: 'overview',
          canActivate: [JarvisBackAfterLoginGuard],
          loadChildren: () =>
            import('./site/overview/overview.module').then(
              (m) => m.OverviewModule
            ),
        },

        {
          path: 'partner-list',
          canActivate: [JarvisBackAfterLoginGuard],
          loadChildren: () =>
            import('./site/partner-list/partner-list.module').then(
              (m) => m.PartnerListModule
            ),
        },
        {
          path: 'messages',
          canActivate: [JarvisBackAfterLoginGuard],
          loadChildren: () =>
            import('./site/messages/messages.module').then(
              (m) => m.SaasMessagesModule
            ),
        },
        {
          path: 'auth',
          loadChildren: () =>
            import('./site/auth/auth.module').then((m) => m.AuthModule),
        },
        {
          path: 'profile',
          loadChildren: () =>
            import('./site/profile/profile.module').then(
              (m) => m.ProfileModule
            ),
        },
        {
          path: 'sponsored-listings',
          loadChildren: () =>
            import('./site/sponsored-listings/sponsored-listings.module').then(
              (m) => m.SponsoredListingsModule
            ),
        },

        // {
        //   path: 'pricing',
        //   component: SaasLandingPricingComponent
        // },
        {
          path: 'admin-move-session',
          canActivate: [AdminMoveSessionGuard],
          children: [],
        },
        {
          path: '',
          loadChildren: () =>
            import('./site/home/home.module').then((m) => m.HomeModule),
        },
        {
          path: 'partners',
          loadChildren: () =>
            import('./site/partners/partners.module').then(
              (m) => m.JarvisVendorPartnersModule
            ),
        },
        {
          path: 'on-booking-referal',
          redirectTo: 'auth/booking-referal',
        },
        {
          path: 'signup',
          redirectTo: 'auth/signup',
        },
        {
          path: 'signin',
          redirectTo: 'auth/signin',
        },
        {
          path: 'video-presentation',
          canActivate: [VideoPresentationGuard],
          children: [],
        },
        {
          path: '',
          loadChildren: () =>
            import('./site/rules-policies/rules-policies.module').then(
              (m) => m.PlatformRulesPoliciesModule
            ),
        },
      ],
    },
  ];
}

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      paramsInheritanceStrategy: 'always',
      initialNavigation: 'enabledNonBlocking',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      // Enable scrolling to anchors
      // anchorScrolling: 'enabled',
    }),
  ],
  providers: [
    {
      provide: NG_ROUTES,
      useFactory: routesFactory,
      deps: [DOMAIN_COUNTRY],
      multi: true,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
