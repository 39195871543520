<!-- <div>
    <p>Shadow right state: {{shadowRightState}}</p>
    <p>Shadow left state: {{shadowLeftState}}</p>
    <p>Shadow opacity: {{shadowOpacity}}</p>
</div> -->
<div class='slide-wrapper' [class.no-buttons]="noButtons">
    <div 
        class="section-indicator" 
        [ngClass]="{disabled: isFirstCard}"
        *ngIf="!noButtons"
    >
        <mat-icon (click)="translateToPreviousCard()">{{arrowIcons ? 'arrow_back' : 'arrow_back_ios'}}</mat-icon>
    </div>

    <div 
        class="section-content"
        #contentContainer
    >
            <div class="content text">
                <div 
                    class="shadow-left"
                    [@shadowOpacity]="{
                        value: shadowLeftState || 'none',
                        params: {
                            opacity: shadowOpacity
                        }
                    }"
                ></div>
                <div 
                    *ngFor="let card of content; let i = index;"
                    [@translateCard]="{
                        value: card.position || 'void',
                        params: {
                            animationDuration: animationDuration,
                            manualPosition: card.manualPosition
                        }
                    }"
                    (@translateCard.start)="onTranslateCardStarted($event)"
                    (@translateCard.done)="translateCardComplete($event)"
                    (pan)="panHandler($event, i)"
                    (panend)="panEndHandler($event, i)"
                    class="card"
                >
                    <ng-template [cdkPortalOutlet]="card.portal"></ng-template>
                </div>
                <div
                    class="shadow-right"
                    [@shadowOpacity]="{
                        value: shadowRightState || 'none',
                        params: {
                            opacity: shadowOpacity
                        }
                    }"
                ></div>
            </div>
    </div>

    <div *ngIf="!noButtons" class="section-indicator" [ngClass]="{disabled: isLastCard}">
        <mat-icon (click)="translateToNextCard()">{{arrowIcons ? 'arrow_forward' : 'arrow_forward_ios'}}</mat-icon>
    </div>
</div>
<div class='indicator-wrapper'>
    <ng-container *ngFor="let cardInfo of content">
        <div class="indicator" [ngClass]="{active: cardInfo.active}"></div>
    </ng-container>
</div>