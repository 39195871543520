import { filter, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd, NavigationStart, Event, NavigationCancel, NavigationCancellationCode } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { inject, Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, from, merge, NEVER, Observable, of } from 'rxjs';
import { DynamicSeoData, DynamicSeoPlusData, SEOCanonicalisationStrategy, SEODataFetchStrategy, SEOFooterTextStrategy, SEOMetaTagStrategy, SEORedirectStrategy, SEOStructuredDataStrategy } from './seo.types';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { BASE_URL } from '@jarvis/services/tokens';

export const NoIndexRoutes = [
  'rules-policies',
  'search-map'
];

const DEFAULT_SEO_PHOTOS: Record<string, string> = {
  us: '/assets/images/landing/homepage/homepage-top-alvaro.jpg',
  lt: '/assets/images/seo/default-meta-lt.jpg',
  default: '/assets/images/seo/default-meta-lt.jpg'
};

export const addPrerenderRedirectTags = (redirectUrl: string, redirectCode: number, metaService: Meta): void => {
  metaService.addTag({ name: 'prerender-status-code', content: `${redirectCode}` });
  metaService.addTag({ name: 'prerender-header', content: `Location: ${redirectUrl}` });
};

const addPrerendererNotFoundTags = (metaService: Meta): void => {
  metaService.addTag({ name: 'prerender-status-code', content: '404' });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const removePrerenderRedirectTags = (metaService: Meta): void => {
  metaService.removeTag('name=prerender-status-code');
  metaService.removeTag('name=prerender-header');
};

class DefaultCanonicalisationStrategy implements SEOCanonicalisationStrategy<void> {

  constructor(
    private dom: Document
  ) {}

  execute() {
    const url = this.dom.location.pathname;
    const canonicalisedUrl = this.canonicaliseUrl(url, { hostName: this.dom.location.origin });

    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', canonicalisedUrl);
  }

  private canonicaliseUrl(rawUrl: string, options: { hostName: string }) {
    const { hostName } = options;
    const url = rawUrl.split('?')[0];
    const splited = url.split('?').filter((item) => item !== '');
    const canonicalizedUrl = splited[0] ? [ hostName, splited[0] ].join('') : hostName;

    return canonicalizedUrl;
  }
}

class DefaultDataFetchStrategy implements SEODataFetchStrategy<DynamicSeoData | null, { baseUrl: string; pageUrl: string, domainCountry: string }> {
  getDataStream(httpService: HttpClient, options: { baseUrl: string; pageUrl: string, domainCountry: string }) {
    const urlWithoutQueryParams = options.pageUrl.split('?')[0];
    const seoEndpoint = `${options.baseUrl}/common/getSeoPlus`;

    const seoPlusBody = {
      base: {
        url: urlWithoutQueryParams,
        "data.country": options.domainCountry || 'us'
      },
      plus: {
        // Random data for filter to not match if base does not match as well
        base: true
      }
    };

    return httpService.post<DynamicSeoPlusData>(seoEndpoint, seoPlusBody).pipe(
      map((result) => {
        if (!result?.data) {
          return null;
        }

        return result.data;
      })
    );
  }
}

class DefaultRedirectStrategy implements SEORedirectStrategy {

  constructor(
    private metaService: Meta,
    private domainCountry: string
  ) {}

  execute(): void {
    addPrerendererNotFoundTags(this.metaService);
  }

  getRedirectURL(): string {
    return this.domainCountry === 'lt' ? '404-nerasta' : 'not-found';
  }
}

class DefaultMetaTagStrategy implements SEOMetaTagStrategy<DynamicSeoData> {

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private defaultTitle: string,
    private domainCountry: string
  ) { }

  execute(seoData: DynamicSeoData) {

    let title = this.defaultTitle;
    let description: string;

    if (this.domainCountry === 'lt') {
      description = 'Planuok vestuves ir kitus renginius vienoje modernioje platformoje. Šventės vietos, fotografai, atlikėjai, vedėjai ir šimtai kitų paslaugų teikėjų. Išbandyk dabar!';
    } else {
      description = 'Find new carefully selected venues and vendors for your wedding or other event. Detailed listings, prices and calendar availability. Learn more >>>';
    }

    if (seoData) {
      title = seoData.title as any;
      description = seoData.description as any;
    }

    this.titleService.setTitle(title);

    this.metaService.updateTag({
      name: 'description',
      content: description
    });

    this.metaService.updateTag({
      property: 'og:description',
      content: description
    });

    this.metaService.updateTag({
      property: 'og:title',
      content: title
    });

    this.metaService.updateTag({
      property: 'og:type',
      content: 'website'
    });

    this.metaService.updateTag({
      property: 'og:url',
      content: window.location.href
    });

    this.metaService.updateTag({
      property: 'og:image',
      content: seoData?.image ? seoData.image : (DEFAULT_SEO_PHOTOS[this.domainCountry] || DEFAULT_SEO_PHOTOS['default'])
    });
  }
}

class DefaultFooterTextStrategy implements SEOFooterTextStrategy {
  getDataStream(): Observable<any> {
    return of(null);
  }
}

class DefaultStructuredDataStrategy implements SEOStructuredDataStrategy {
  constructor(
    private _document: Document
  ) { }

  execute() {
    this.removeStructuredData();
  }

  private removeStructuredData(): void {
    const elements: Element[] = [];
    ['structured-data-website', 'structured-data-org'].forEach((c) => {
      elements.push(...Array.from(this._document.head.getElementsByClassName(c)));
    });
    elements.forEach((el) => this._document.head.removeChild(el));
  }
}

@Injectable({ providedIn: 'root' })
export class JarvisSeoService {
  private defaultTitle: string;
  private _footerContentSource = new BehaviorSubject<Observable<string> | null>(null);
  footerContent$ = this._footerContentSource.asObservable().pipe(
    switchMap((stream) => stream || of(null))
  );

  private _latestSeoDataSource = new BehaviorSubject<any>(null);
  latestSeoData$ = this._latestSeoDataSource.asObservable();

  canonicalisationStrategy!: SEOCanonicalisationStrategy<unknown>;
  dataFetchStrategy!: SEODataFetchStrategy<unknown, unknown>;
  redirectStrategy!: SEORedirectStrategy;
  metaTagStrategy!: SEOMetaTagStrategy<unknown>;
  footerTextStrategy!: SEOFooterTextStrategy;
  structuredDataStrategy!: SEOStructuredDataStrategy;

  private _defaultCanonicalisationStrategy: SEOCanonicalisationStrategy<void>;
  private _defaultDataFetchStrategy: SEODataFetchStrategy<DynamicSeoData | null, { baseUrl: string; pageUrl: string }>;
  private _defaultRedirectStrategy: SEORedirectStrategy;
  private _defaultMetaTagStrategy: SEOMetaTagStrategy<DynamicSeoData>;
  private _defaultFooterTextStrategy: SEOFooterTextStrategy;
  private _defaultStructuredDataStrategy: SEOStructuredDataStrategy;

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string,
    private metaService: Meta,
    private titleService: Title,
    private router: Router,
    private http: HttpClient
  ) {
    this.defaultTitle = this.titleService.getTitle();

    this._defaultCanonicalisationStrategy = new DefaultCanonicalisationStrategy(this.dom);
    this._defaultDataFetchStrategy = new DefaultDataFetchStrategy();
    this._defaultRedirectStrategy = new DefaultRedirectStrategy(this.metaService, this.domainCountry);
    this._defaultMetaTagStrategy = new DefaultMetaTagStrategy(this.metaService, this.titleService, this.defaultTitle, this.domainCountry);
    this._defaultFooterTextStrategy = new DefaultFooterTextStrategy();
    this._defaultStructuredDataStrategy = new DefaultStructuredDataStrategy(this.dom);

    this.setDefaultStrategies();
    this.createRouteStream();
  }

  /**
   * We listen for any navigation start event
   * After that, we can have two paths - either we suceed without any interuptions and get to navigation finish event
   * If we have a nav cancel event before, we execute redirect strategies and repeat the stream until we get to a finish event
   * We can have a situation that we don't have a finish event. In that case, we just do nothing as our navigation failed for some reason (redirect to same URL, cancel because of a guard, etc...)
   * 
   * Call this method one time only in a singleton - otherwise memory leaks or race conditions will occur
   */
  private createRouteStream(): void {

    const navStartEvent = this.router.events.pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart)
    );

    const navCancelEvent = this.router.events.pipe(
      filter((event): event is NavigationCancel => event instanceof NavigationCancel)
    );

    const navEndEvent = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    );

    const mergedCancelEndEvent = merge(
      navCancelEvent,
      navEndEvent
    );

    navStartEvent.pipe(
      tap(event => {
        this.handleNoIndexUrls(event.url);
        // removePrerenderRedirectTags(this.metaService);
      }),
  
      switchMap(() => mergedCancelEndEvent),
      filter((cancelOrEndEvent: Event) => {
        if (cancelOrEndEvent instanceof NavigationCancel) {
          // Special case - if resolver returns EMPTY (emits no data and completes), we execute redirect strategy.
          // With this we filter only these events and handle redirecting in this stream
          if (cancelOrEndEvent.code !== NavigationCancellationCode.NoDataFromResolver) {
            return false;
          }
        }

        return true;
      }),
      switchMap((cancelOrEndEvent: Event) => {
        if (cancelOrEndEvent instanceof NavigationEnd) {
          const fetchDataOptions = {
            baseUrl: this.baseUrl,
            pageUrl: this.dom.location.pathname,
            domainCountry: this.domainCountry
          };

          return this.dataFetchStrategy.getDataStream(this.http, fetchDataOptions);
        }

        this.redirectStrategy.execute();
        const redirectURL = this.redirectStrategy.getRedirectURL();
        // If in prerenderer context, we can stop here
        // We could try and stop the navigation, return currently added tags for prerenderer or ignore tags and just navigate to the correct URL
        // The better way would be to have different platforms (server, client) and handle seo strategies accordingly
        // this.applicationRef.destroy();
        this.setDefaultStrategies();
        // removePrerenderRedirectTags(this.metaService);

        return from(
          this.router.navigate([redirectURL])
        ).pipe(
          map(() => NEVER)
        );
      }),
    ).subscribe((result) => {

      let defaultsSet = false;
      // TODO: This can be confusing. If, with default or any other strategy we do not return truthy data, all strategies will be reverted!
      if (!result) {
        // No results, revert to defaults
        defaultsSet = true;
        this._latestSeoDataSource.next(null);
        this.setDefaultStrategies();
      }

      // TODO: Should be declarative (no imperative subscribes should be the norm)
      this._latestSeoDataSource.next(result);

      this.metaTagStrategy.execute(result);
      this.canonicalisationStrategy.execute();
      this.structuredDataStrategy.execute();
      const newFooterContentStream = this.footerTextStrategy.getDataStream(result);
      this._footerContentSource.next(newFooterContentStream);

      // After finishing revert to default strategies
      // Each navigation sets strategies every time on navigation start event
      if (!defaultsSet) {
        this.setDefaultStrategies();
      }
    });
  }

  private setDefaultStrategies(): void {
    this.canonicalisationStrategy = this._defaultCanonicalisationStrategy;
    this.dataFetchStrategy = this._defaultDataFetchStrategy;
    this.redirectStrategy = this._defaultRedirectStrategy;
    this.metaTagStrategy = this._defaultMetaTagStrategy;
    this.footerTextStrategy = this._defaultFooterTextStrategy;
    this.structuredDataStrategy = this._defaultStructuredDataStrategy;
  }

  private handleNoIndexUrls(url: string) {
    const firstRoute = url.split('/').filter((item) => item)[0];

    if (NoIndexRoutes.includes(firstRoute)) {
      this.metaService.addTag({ name: 'robots', content: 'noindex' });
      this.metaService.addTag({ name: 'googlebot', content: 'noindex' });
    } else {
      this.metaService.removeTag('name=robots');
      this.metaService.removeTag('name=googlebot');
    }
  }
}
