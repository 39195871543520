<div class="bztt-pb-2 bztt-pt-4 md:bztt-pt-0" (click)="activateSearchMode()">
  <div class="filters-container">
    <div *ngIf="isSearchMode$ | async" class="bztt-flex bztt-items-center bztt-justify-center bztt-flex-grow-0 bztt-mb-1 bztt-cursor-pointer">
      <mat-icon (click)="exitFilterState($event)">arrow_back</mat-icon>
    </div>
  
    <div
        [ngClass]="{
            'expanded': expandedFilter === filterTypes.service,
            'shrunk': expandedFilter !== filterTypes.service
        }"
        *ngIf="vendorType === 'vendor'"
        (click)="expandFilter(filterTypes.service, $event)"
    >
        <mat-form-field noInfix>
            <mat-select class="bztt-leading-[10px]" [formControl]="serviceFilterControl" [placeholder]="expandedFilter === filterTypes.service ? ('messages.searchServices'| translate)  : ''">
                <mat-option *ngFor="let service of serviceBases | async" [value]="service._id">
                    {{service.brandName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div 
        [ngClass]="{
            'expanded': expandedFilter === filterTypes.search, 
            'shrunk': expandedFilter !== filterTypes.search
        }"
        (click)="expandFilter(filterTypes.search, $event)"
    >
        <mat-form-field noInfix>
            <input class="bztt-leading-[10px]" autocomplete="off" matInput [placeholder]="expandedFilter === filterTypes.search ? ('messages.search' | translate) : ''" [formControl]="searchControl" />
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
    </div>
  </div>   
  <div class="bztt-pl-8 bztt-pt-4 filters-container bztt-items-center"
  *ngIf="(expandedFilter === filterTypes.service && vendorType === 'vendor')">
  <span  class="bztt-bg-[#f1f1f1] bztt-px-[9px] bztt-py-[7px]  bztt-rounded-[15px] bztt-flex-grow-0">
    <mat-checkbox color="primary" [formControl]="unreadFilterControl">    
  </mat-checkbox> </span> 
  <span class="bztt-text-[#959595] bztt-text-sm">{{'messages.unread'| translate}}</span>
  
  </div>
</div>  