import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Optional, Inject } from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'button[app-button]',
    exportAs: 'app-button',
    templateUrl: './button.html',
    styleUrls: ['button.scss']
})
export class AppButtonComponent extends MatButton {

    private nativeElement: HTMLElement;

    constructor(
        elementRef: ElementRef,
        focusMonitor: FocusMonitor,
        @Optional() @Inject(ANIMATION_MODULE_TYPE) animationMode: string
    ) {
        super(elementRef, focusMonitor, animationMode);
        this.nativeElement = elementRef.nativeElement;

        if (this._hasHostAttributes('uppercase')) {
            this.nativeElement.classList.add('uppercase');
        }
    }
}
