import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { ChatMessagesActionsComponent } from './actions/actions.component';
import { Subject, filter, fromEvent, switchMap, takeUntil } from 'rxjs';
import { ChatMessageComponent } from './message/message.component';
import { LayoutService } from '@jarvis/ui';
import { iOS } from '@jarvis/utils';

@Component({
  templateUrl: './chat-messages.component.html',
  styleUrls: [
    './chat-messages.component.scss'
  ],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    OverlayModule,
    ChatMessagesActionsComponent,
    ChatMessageComponent
  ]
})
export class ChatMessagesStandaloneComponent implements OnInit, OnDestroy {

  @Input() messages: any[] = [];

  @Input() domainCountry: string;
  @Input() messagesType: string;

  isExpanded = false;

  private layoutService = inject(LayoutService);
  isMobile$ = this.layoutService.isMobileObserver;

  private document = inject(DOCUMENT);

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    const windowObj = this.document.defaultView;

    if (windowObj && iOS() && windowObj.visualViewport) {
      fromEvent(windowObj.visualViewport, 'resize')
        .pipe(
          switchMap(() => this.isMobile$),
          filter((isMobile) => isMobile),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          const elList = document.getElementsByClassName('actions-container-wrapper');
          const targetEl = elList[0] as HTMLDivElement;
          if (targetEl && this.isExpanded) {
            targetEl.style.setProperty(
              '--dynamic-fixed-height',
              `${window.visualViewport.height}px`
            );
            windowObj.scroll({ top: 0 });
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}