import { Component, OnInit } from '@angular/core';
import { MENU_DROPDOWN_ANIMATIONS } from '../../../../shared/animations/menu-dropdown';

@Component({
  selector: 'app-more-dropdown',
  templateUrl: './more-dropdown.component.html',
  styleUrls: ['./more-dropdown.component.scss'],
  animations: MENU_DROPDOWN_ANIMATIONS
})
export class MoreDropdownComponent implements OnInit {

  animationState = 'enter';

  constructor() { }

  ngOnInit(): void {
  }

}
