import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'marketplace-messages-no-messages',
    templateUrl: 'no-messages.component.html',
    styleUrls: [
        './no-messages.component.scss'
    ]
})

export class MessagesNoMessagesComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}