import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jarvis-membership-item',
  templateUrl: './membership-item.component.html',
  styleUrls: ['./membership-item.component.scss']
})
export class MembershipItemComponent implements OnInit {
  @Input() plan;
  @Input() labels;
  @Input() range = 'monthly';

  constructor() { }

  ngOnInit(): void {
  }

}
