export enum ServiceEventTypes {
    general = 'general',
    wedding = 'wedding',
    businessEvent = 'businessEvent',
    privateEvent = 'privateEvent',
}

export enum ServiceTypes {
    mainService = 'mainService',
    additionalService = 'additionalService',
    venueVenueStandalone = 'venueVenueStandalone', // Either the price for whole venue, or a question whether 
    venueVenueSeparate = 'venueVenueSeparate', // Conditional - If venue standalone is false - 
    // venueCateringTasting = 'venueCateringTasting',
    // venueCateringKidsMenu = 'venueCateringKidsMenu', // Was not in the original
    // venueCateringDessert = 'venueCateringDessert',
    venueCateringMenuMain = 'venueCateringMenuMain', // Main catering menu
    venueCateringMenuAdditional = 'venueCateringMenuAdditional',
    cateringTasting = 'cateringTasting', // same as venueCateringTasting
    cateringKidsMenu = 'cateringKidsMenu', // same as venueCateringKidsMenu
    cateringDessert = 'cateringDessert', // same as venueCateringDessert
    otherAdditionalHour = 'otherAdditionalHour',
}

export enum ListingTypes {
    venues = 'venues',
    catering = 'catering',
    ceremonyVenue = 'ceremony_venue',
    ceremonyRegale = 'ceremony_regale',
    bartenders = 'bartenders',
    foodtruck = 'foodtruck',
    videographer = 'videographer',
    photographer = 'photographer',
    photoBooth = 'photo_booth',
    liveMusic = 'live_music',
    planning = 'planning',
    host = 'host',
    cakesDesserts = 'cakes_desserts',
    makeupHairdressing = 'makeup_hairdressing',
    dj = 'dj',
    brideClothing = 'bride_clothing',
    groomClothing = 'groom_clothing',
    secondaryDress = 'secondary_dress',
    transport = 'transport',
    decor = 'decor',
    invitationsPrinting = 'invitations_printing',
    decorFloristics = 'decor_floristics',
    pavilionFurniture = 'pavilion_furniture',
    soundLights = 'sound_lights',
    screensProjectors = 'screens_projectors',
    show = 'show',
    training = 'training',
    entertainment = 'entertainment',
    jewelery = 'jewelery',
    presents = 'presents',
    honeymoon = 'honeymoon'
}
