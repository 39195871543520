import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PhoneMaskDirective } from './components/phone-number-mask.directive';
import { JarvisPhoneNumberComponent } from './components/phone-number.component';

@NgModule({
    imports: [
        CommonModule,
        MatSelectModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule
    ],
    exports: [JarvisPhoneNumberComponent, PhoneMaskDirective],
    declarations: [JarvisPhoneNumberComponent, PhoneMaskDirective],
    providers: []
})
export class JarvisPhoneNumberModule { }
