
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import {
  Component,
  EventEmitter,
  OnInit,
  Optional,
  Output,
  Input,
  OnDestroy,
  inject,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JarvisAuthService } from '../../services/auth.service';
import { of, Subject } from 'rxjs';
import { JarvisValidators } from '@jarvis/utils';
import { JarvisLanguageService, JarvisTrackingService } from '@jarvis/services';

@Component({
  selector: 'jarvis-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class JarvisAuthLoginComponent implements OnInit, OnDestroy {
  @Input() standalone = false;
  @Input() hideClose = false;
  @Input() hideTitle = false;
  @Input() disableRegisterNavigation = false;

  closeButton = false;

  @Output() loggedIn = new EventEmitter();
  @Output() logInError = new EventEmitter();
  @Output() registerClicked = new EventEmitter<void>();

  showPassword = false;

  loginFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
    password: new UntypedFormControl('', Validators.required),
    // rememberMe: newUntypedFormControl(false)
  });

  emailErrors$ = JarvisValidators.generateErrorStateMatcher(
    this.loginFormGroup,
    'email'
  );
  passwordErrors$ = JarvisValidators.generateErrorStateMatcher(
    this.loginFormGroup,
    'password'
  );
  formErrors$ = JarvisValidators.generateErrorStateMatcher(
    this.loginFormGroup,
    ''
  );

  private trackService = inject(JarvisTrackingService);

  destroy$ = new Subject<void>();

  constructor(
    private authService: JarvisAuthService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private languageService: JarvisLanguageService,
    private socialAuthService: SocialAuthService,
    @Optional() private dialogRef: MatDialogRef<JarvisAuthLoginComponent>
  ) {}

  ngOnInit(): void {
    if (this.dialogRef) {
      this.closeButton = true;
    }

    this.initSocialAuthStateChange();
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  reopenRegister() {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'register_clicked',
    });

    if (this.disableRegisterNavigation) {
      this.registerClicked.emit();
      return;
    }

    const currentQueryParams = this.currentRoute.snapshot.queryParams;
    if (this.standalone) {
      const url = ['/signup'];
      if (this.currentRoute.snapshot.params?.hash)
        url.push(this.currentRoute.snapshot.params?.hash);
      return this.router.navigate(url, { queryParams: currentQueryParams });
    }

    this.router.navigate(['/auth/register'], {
      queryParams: currentQueryParams,
    });
    return this.close(true);
  }

  resendConfirmation(): void {
    const email = this.loginFormGroup.get('email').value;
    this.authService.resendEmailConfirmation(email).subscribe(
      () => {
        this.loginFormGroup.reset();
        this.loginFormGroup.get('email').setValue(email);
        this.loginFormGroup.get('password').setErrors({});
        // console.log(this.loginFormGroup);
      },
      () => {
        alert('Something went wrong...');
      }
    );
  }

  login(): void {
    if (this.loginFormGroup.invalid) {
      this.loginFormGroup.markAllAsTouched();
      return;
    }

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'login_email',
    });

    const loginData = this.loginFormGroup.getRawValue();

    this.authService.login(loginData).subscribe(
      (data) => {
        this.loggedIn.emit(data);
        this.languageService.setDefaultLanguageByCountry(
          data.country.toLowerCase() || 'US'
        );
        this.authService.loginEvent$.next('success');
      },
      ({ error }) => {
        const errArray: { message: string }[] = error.errors;
        if (errArray) {
          for (const errorObject of errArray) {
            if (errorObject.message === 'Wrong email or password') {
              this.loginFormGroup.setErrors({ wrongCredentials: true });
              return;
            }

            if (errorObject.message === 'Email is not verified') {
              this.loginFormGroup.setErrors({ notVerified: true });
              return;
            }

            if (errorObject.message === 'Email must be valid') {
              this.loginFormGroup.setErrors({ email: true });
              return;
            }
          }
        }

        this.authService.loginEvent$.next('fail');
        this.loginFormGroup.setErrors({ somethingWrong: true });
      }
    );
  }

  close(success?: boolean): void {
    if (!success) {
      this.authService.loginEvent$.next('cancel');
    }
    if (this.dialogRef) {
      this.dialogRef.close(success);
    }
  }

  registerWithFacebook(): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'login_facebook',
    });

    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .catch((e) => console.log('Something wrong with Facebook Auth', e));
  }

  registerWithGoogle(): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'login_google',
    });

    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .catch((e) => console.log('Something wrong with Google Auth', e));
  }

  initSocialAuthStateChange() {
    this.socialAuthService.authState
      .pipe(takeUntil(this.destroy$))
      .pipe(
        switchMap((user: SocialUser) => {
          if (user?.provider === 'FACEBOOK')
            return this.authService.facebookRegister(user.authToken);
          if (user?.provider === 'GOOGLE')
            return this.authService.googleRegister(user.authToken);

          return of(null);
        }),
        filter((info) => !!info)
      )
      .subscribe({
        next: () => {
          this.authService.loginEvent$.next('success');
          this.authService.getCurrentUser();
          this.close(true);
        },
        error: () => {
          this.authService.loginEvent$.next('fail');
          this.loginFormGroup.setErrors({ wrongCredentials: true });
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
