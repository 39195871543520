import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import SwiperCore, { Navigation, Pagination } from 'swiper';

SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

@Component({
  selector: 'landings-reviews-slide',
  templateUrl: './reviews-slide.component.html',
  styleUrls: ['./reviews-slide.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewsSlideComponent implements OnInit {
  @Input() config: {
    image: string,
    label: string,
    description: string,
  }[]

  configuration = [
    //vendor
    {
      image: 'auders.jpg',
      label: 'Auders Photography',
      description: 'Since I started using Breezit, I noticed that the new coming clients rarely have questions about prices, so answering their questions takes much faster and I can focus more on fulfilling their wishes at the event.'
    },
    {
      image: 'big-bam-jam.jpg',
      label: 'Lukas, Big Bam Jam',
      description: 'With Breezit my clients finally have no trouble booking my services - previously I would need to send information,  go back and forth multiple times with the invoices, contracts, and all the jazz. Right now, it takes minutes.'
    },
    //user
    {
      image: 'viktorija.jpg',
      label: 'Viktorija',
      description: 'With Breezit I don’t have to make multiple calls anymore, and most importantly, I have all the filters to fit my budget for my wedding.'
    },
    {
      image: 'evelina.jpg',
      label: 'Evelina',
      description: 'Before I found Breezit, I had my notes scattered across Excel files, a notebook and some random papers. Now I see everything I need in one place.'
    }
  ]

  constructor() { }

  ngOnInit(): void {
    if (this.config) this.configuration = this.config;
  }

}
