import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CalendarMonthViewDay } from 'angular-calendar';

@Pipe({
    name: 'CalendarDiscount',
    pure: true
})

export class CalendarDiscountPipe implements PipeTransform {
    constructor(
        private currencyPipe: CurrencyPipe
    ) { }

    transform(day: CalendarMonthViewDay): string | boolean {
        const discountEvent = day.events.find(event => event.title === 'discount');
        if (discountEvent) {
            if (discountEvent.meta.type === 'percent') {
                return `-${discountEvent.meta.value}%`;
            } else {
                const currency = this.currencyPipe.transform(discountEvent.meta.value, 'USD', 'symbol', '1.0-0');
                return `-${currency}`;
            }
        }

        return false;
    }
}