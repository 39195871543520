import { JarvisServiceLikesService } from '@jarvis/services/likes';
import { takeUntil, debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Component, Inject, Input, OnInit, inject } from '@angular/core';
import { JarvisUiModalService } from '@jarvis/ui';
import { LinkUrls, LINK_URLS, JarvisTrackingService } from '@jarvis/services';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

// import { PlannerService } from '@jarvis/planner';
// import { AddNewPlannerItemComponent } from '@jarvis/planner';
import { AddNewPlannerItemComponent } from 'libs/planner/src/lib/planner-page/components/dialogs/add-new-planner-item/add-new-planner-item.component';
import { PlannerService } from './../../../../planner/src/lib/services/planner.service';



@Component({
  selector: 'jarvis-ui-marketplace-card-planner-info',
  templateUrl: 'planner-info.component.html',
  styleUrls: ['./planner-info.component.scss'],
})
export class JarvisUiMarketplaceCardPlannerInfoComponent implements OnInit {
  @Input() data;

  destroy$ = new Subject();
  noteActive = false;
  noteForm: UntypedFormControl;

  bookingLink;

  get shared() {
    return !!this.plannerService?.sharedPlannerId;
  }

  translateService = inject(TranslateService);
  private trackService = inject(JarvisTrackingService);

  constructor(
    private plannerService: PlannerService,
    private likeService: JarvisServiceLikesService,
    private modalService: JarvisUiModalService,
    @Inject(LINK_URLS) public linkUrls: LinkUrls,
    private snack: MatSnackBar
  ) {}

  ngOnInit() {
    // if (this.data.plannerType === 'budget') console.log(this.data)
    this.noteForm = new UntypedFormControl(this.data?.note || '');

    if (this.data.booking)
      this.bookingLink = `/bookings-list/detail/${this.data.booking._id}`;

    this.noteForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(300), distinctUntilChanged())
      .subscribe((newComment) => {
        if (this.data.localLike) {
          this.data.note = newComment;
          if (this.data.plannerType === 'wishlist') {
            this.likeService.updateLocalLike(this.data.serviceEvent._id, {
              note: newComment,
            });
          }
          if (this.data.plannerType === 'budget') {
            this.plannerService.updateLocalPlannerItem(this.data._id, {
              note: newComment,
            });
          }
          return;
        }

        if (this.data.plannerType === 'wishlist') {
          this.plannerService
            .handleWishlistNoteChange(this.data._id, newComment)
            .subscribe(() => {
              this.data.note = newComment;
              this.plannerService.refreshPlanner.next(false);
            });
        }

        if (this.data.plannerType === 'budget') {
          this.plannerService
            .editPlannerItem(this.data._id, { note: newComment })
            .subscribe(() => {
              this.data.note = newComment;
              this.plannerService.refreshWishlist.next();
            });
        }
      });
  }

  openNote() {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'planner_item_add_note_click',
    });

    this.noteActive = true;
  }

  closeNote() {
    this.noteActive = false;
  }

  addToTeamAndBudget() {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'add_to_team_and_budget',
      data: {
        eventId: this.data.serviceEvent._id,
        local: !!this.data.localLike,
      },
    });

    if (this.data.localLike) {
      this.likeService.updateLike(this.data.serviceEvent._id, {
        plannerItem: true,
      });
    }

    this.plannerService
      .addPlannerBaseItem(this.data.serviceEvent._id, this.data)
      .subscribe((res) => {
        this.data.plannerItem = res;
        this.plannerService.refreshPlanner.next(
          this.data.plannerType === 'budget'
        );
        this.snack.open(
          this.translateService.instant('plannerPage.addedToBudget'),
          null,
          {
            duration: 2000,
          }
        );
      });
  }

  getAQuote() {
    console.log('Get a quote');
  }

  removeFromTeamAndBudget() {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'remove_from_team_and_budget',
    });

    this.plannerService
      .deletePlannerItem(
        this.data.plannerItem._id || this.data.serviceEvent._id || this.data._id
      )
      .subscribe((res) => {
        this.snack.open(
          this.translateService.instant('plannerPage.removedFromBudget'),
          null,
          {
            duration: 2000,
          }
        );
        this.data.plannerItem = null;
        //   if (this.data.plannerType === 'budget') this.plannerService.refreshWishlist.next();
        this.plannerService.refreshPlanner.next(
          this.data.plannerType === 'budget'
        );
      });
  }

  editCustomLike() {
    const dialogRef = this.modalService.openDialog(AddNewPlannerItemComponent, {
      maxHeight: '95vh',
      maxWidth: 'none',
      data: this.data,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (this.data.localLike) {
          this.likeService.updateLocalLike(this.data.serviceEvent._id, res);
          this.plannerService.refreshWishlist.next();
          return;
        }

        this.likeService
          .updateLike(this.data.like?._id || this.data._id, {
            customServiceEvent: res,
          })
          .subscribe(() => {
            this.plannerService.refreshPlanner.next(true);
          });
      }
    });
  }
}
