import { AdvertsPriceUnits } from '@jarvis/services';
import { AdvertServiceVariant } from '@jarvis/services';
import {
  ContractStateType,
  CustomerNeeds,
  ServiceBase,
  ServiceEvents,
} from '@jarvis/types';

/***** Choice of bookings state options ******/
export enum BookingStateEnum {
  new = '',
  communication = '',
  signing = '',
  meetingBooked = '',
  proposalSubmitted = '',
  payment = '',
  reserved = '',
  completed = '',
  cancelled = '',
}

export type BookingDetailsOptions = {
  stripeAccount: boolean;
  deposit: {
    required: boolean;
    value?: number;
    unit?: string;
    fullAdvance: boolean;
    paymentDays?: number;
    paymentOption?: string;
    type?: number;
  };
  contractState: ContractStateType;
  listingContractState: ContractStateType;
  penaltiesCancel: any;
  reschedule: any;
  files: { filename: string; url: string }[];
  timeScheduling: boolean;
  timePerClient?: number;
  bookingCancel: any;
  type?: string;
  customDeposit?: boolean;
};

export type BookingStateKeys =
  | keyof typeof BookingStateEnum
  | 'deleted'
  | 'canceledByCustomer'
  | 'pendingPayment';

export enum ServiceTypeEnum {
  wedding = 'wedding',
  business = 'business',
  private = 'private',
  general = 'general',
  privateEvent = 'privateEvent',
  businessEvent = 'businessEvent',
}

export type ServiceTypeKeys = keyof typeof ServiceTypeEnum;

export enum BookingRequestEnum {
  viaListing = 'viaListing',
  viaLink = 'viaLink',
  addedManually = 'addedManually',
  viaPersonalListing = 'viaPersonalListing',
  addedViaEmail = 'addedViaEmail',
}

export type BookingRequestKeys = keyof typeof BookingRequestEnum;

/***** Structure for storing detailed booking data in the database *****/
export interface BookingDetailsType {
  /*** Main data ***/
  name: string;
  qnt: number;
  additionalQnt?: number;
  unit: string;
  price: number;
  maxQuantity: number;
  minQuantity: number;
  minimalBudget: number;
  description?: string;
  hash: string;
  /*** Additional data ***/
  isCustomPrice: boolean;
  serviceVariant?: AdvertServiceVariant;
  alwaysIncluded?: boolean;
  savedMinimalBudget?: number;
}

/***** Structure for storing data in a database *****/
export interface BookingsObjType {
  _id?: string;

  /*** Information about the client and his terms ***/
  customer?: CustomerType;
  customerId?: UserInfoType;
  eventDate?: Date;
  eventTillDate?: Date;
  location?: string;

  /*** Service booking information ***/
  serviceName: string;
  serviceType: ServiceTypeKeys;
  serviceBaseId: string | ServiceBase;
  serviceEventId: string | ServiceEvents;
  details: BookingDetailsType[];

  /*** Financial information and booking status ***/
  state: BookingStateKeys;
  price: number;
  tax?: number;
  serviceCharge?: number;
  paid: number;
  paidCash: number;
  discount: number;
  deposit: number;

  /*** Additional information ***/
  notes?: string;
  additions: AdditionFileType[];
  options: BookingOptions;
  customerNeeds?: CustomerNeeds;
}

/***** Structure for use in frontend *****/
export interface BookingsType extends BookingsObjType {
  /*** information retrieved from the database ***/
  msgCount?: number;
  newMsgCount?: number;
  stripeAccountId?: string;
  history: HistoryOfChangesType[];
  invite?: CustomerInviteType[];
  chatRoom?: ChatRoomType;
  vendor?: VendorType;
  review?: any;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface BookingsResult {
  count: number;
  docs: BookingsType[];
}

export interface CustomerInviteType {
  _id: string;
  email: string;
  hash: string;
  status: string;
}

export interface ChatRoomType {
  _id: string;
  notified: string[];
  readBy: string[];
  serviceBase: string;
  users: string[];
}

export interface HistoryOfChangesType {
  changeDate: Date;
  description: string;
}

export interface AdditionFileType {
  url: string;
  filename: string;
}

export interface UserInfoType {
  _id: string;
  email?: string;
  profilePhoto?: string;
  userType: string;
  name?: string;
  surname?: string;
}

export enum CauseOfCancellation {
  eventCanceled,
  eventRescheduled,
  personalReason,

  customerDisappeared,
  disagreedOnService,
  disagreedOnPrice,
  alreadyBooked,
  chosenAnotherProvider,
  canceledByCustomer,

  providerDisappeared,
  canceled,
  other,
}

export enum CustomerTypeEnum {
  person = '',
  company = '',
}
export type CustomerTypeKeys = keyof typeof CustomerTypeEnum;

export interface BookingDetailsEditType extends BookingDetailsType {
  amount: number;
  isIncluded: boolean;
  isMain: boolean;
  tax?: number;
  serviceCharge?: number;
  key?: string;
}

export interface BookingOptions {
  hideServiceType: boolean;
  calendarId?: string;
  customAgreement?: string;
  contractSigned?: boolean;
  contractVendorSigned?: boolean;
  bookingRequest?: BookingRequestKeys;
  causeOfCancellation?: CauseOfCancellation;
  otherCancelCause?: string;
  stateAtCancellation?: string;
  customDeposit?: boolean;
  partnerId?: string;
  stateQueue?: BookingStateKeys[];
  anonUserMessage?: string;
  guestNo?: number;
  noEventDate?: boolean;
  eventTypeInFilter?: string;
  channel?: string;
  requestDate?: Date;
  eventTimeFrom?: Date;
  eventTimeTo?: Date;
  proposalValidityPeriod?: number;
  noProposalValidity?: boolean;
}

export interface VendorType {
  _id: string;
  email: string;
  emailVerified: boolean;
  profilePhoto?: string;
  userType: string;
}
export interface CustomerType {
  _id?: string;
  name: string;
  surname?: string;
  type: CustomerTypeKeys;
  email?: string;
  avatar?: string;
  phoneNo?: string;
  entityCode?: string;
  address?: string;
}

export interface CustomerPersonType extends CustomerType {
  surname: string;
}

export interface CustomerCompanyType extends CustomerType {
  vatCode?: string;
  nameOfRepresentative?: string;
  surnameOfRepresentative?: string;
  addressCorrespondence?: string;
}

/***** Helpers *****/
export interface ServiceListType {
  _id: string;
  name: string;
  timeScheduling: boolean;
  timePerClient?: number;
  type?: string;
  events: {
    types: ServiceTypeKeys[];
    name: string;
    serviceEventId: string;
  }[];
}

export abstract class BookingsUtil {
  public static getCustomerName(
    item: CustomerType,
    profile: UserInfoType
  ): string {
    if (profile && profile.name && profile.surname)
      return profile.name + ' ' + profile.surname;
    if ('surname' in item)
      return (
        (item as CustomerPersonType).name +
        ' ' +
        (item as CustomerPersonType).surname
      );
    return item.email ?? item.phoneNo;
  }

  public static getUnit(unit: string): AdvertsPriceUnits {
    if ((<any>Object).values(AdvertsPriceUnits).includes(unit))
      return unit as AdvertsPriceUnits;
    else return AdvertsPriceUnits.fixedFee;
  }

  public static aliasServiceObjByTypeName(type: ServiceTypeKeys): string {
    switch (type) {
      case 'private':
        return 'privateEvent';
      case 'privateEvent':
        return 'privateEvent';
      case 'businessEvent':
        return 'businessEvent';
      case 'general':
        return 'wedding';
      case 'business':
        return 'businessEvent';
      case 'wedding':
        return 'wedding';
    }
    return null;
  }

  public static haveNewMessages(userId: string, item: BookingsType): boolean {
    const chatRoom = item.chatRoom;
    if (chatRoom && chatRoom?.readBy?.length > 0)
      return !chatRoom.readBy.includes(userId);
    return false;
  }

  public static changeState(
    oldState: BookingStateKeys,
    item: BookingsType
  ): boolean {
    //return true;

    const invalidState = 'invalidState';
    if (item.customerId)
      switch (item.state) {
        case 'new':
          if (oldState != 'new') throw new Error(invalidState);
          break;
        case 'meetingBooked':
        case 'proposalSubmitted':
        case 'communication':
          if (
            ![
              'new',
              'communication',
              'meetingBooked',
              'proposalSubmitted',
            ].includes(oldState)
          )
            throw new Error(invalidState);
          break;
        case 'signing':
          throw new Error(invalidState);
        case 'payment':
          throw new Error(invalidState);
        case 'reserved':
          if (
            ![
              'new',
              'communication',
              'meetingBooked',
              'proposalSubmitted',
              'payment',
            ].includes(oldState) ||
            item.paidCash + item.paid < item.deposit
          )
            throw new Error(invalidState);
          break;
        case 'completed':
          if (
            !['reserved'].includes(oldState) ||
            item.paidCash + item.paid < item.price - item.discount
          )
            throw new Error(invalidState);
          break;
        //!item.customerId ||
      }
    return true;
  }

  public static flatServiceOptionList(
    optionList: {
      id: any;
      text?: string;
      data?: any;
      options?: { id: any; text?: string; data?: any }[];
    }[]
  ): { id: any; text?: string; data?: any }[] {
    return optionList.concat(
      optionList
        .filter((v) => v.options.length)
        .reduce((a, v) => a.concat(v.options.filter((x, i) => i !== 0)), [])
    );
  }

  public static transformToServiceOptionList(serviceList: ServiceListType[]): {
    id: any;
    text: string;
    data: any;
    options?: { id: any; text: string; data: any }[];
  }[] {
    return serviceList.map((item) => ({
      id: item.events[0].serviceEventId,
      text: item.name,
      data: {
        types: item.events[0].types,
        timeScheduling: item.timeScheduling,
        timePerClient: item.timePerClient,
        serviceBaseId: item._id,
      },
      options: item.events
        .map((subitem) =>
          item.events.length != 1
            ? {
                id: subitem.serviceEventId,
                data: {
                  types: subitem.types,
                  timeScheduling: item.timeScheduling,
                  timePerClient: item.timePerClient,
                  item,
                  serviceBaseId: item._id,
                },
                text: subitem.name,
              }
            : null
        )
        .filter((itm) => itm),
    }));
  }

  public static transformServiceBase(
    serviceBase: ServiceBase[]
  ): ServiceListType[] {
    return serviceBase
      .filter((checkItem) => {
        return checkItem.completed;
      }) // only completed services
      .map((item) => ({
        _id: item._id,
        name: item.brandName,
        timeScheduling: item?.scheduling?.multiClientPerDay ? true : false, //concreteTimeForClient
        timePerClient: item?.scheduling?.timePerClient,
        type: item.type,
        events: [
          {
            name: item.brandName,
            serviceEventId: item.serviceEvents?.length
              ? item.serviceEvents[0]._id
              : null,
          },
        ]

          .concat(
            []
              .concat(item.venues?.areas, [item.venues?.backyard])
              .filter((itm) => itm?.serviceId) // contains serviceId
              .map((area) => ({
                name: area.name,
                serviceEventId: area.serviceId,
              }))
          )
          .map((area) => {
            const event = item?.serviceEvents.find(
              (serviceEventToCheck) =>
                serviceEventToCheck._id === area.serviceEventId
            );
            return {
              ...area,
              // check if events is completed?
              types: [
                event?.businessEvent?.completed
                  ? ServiceTypeEnum.business
                  : null,
                event?.privateEvent?.completed ? ServiceTypeEnum.private : null,
                event?.wedding?.completed && event?.customPricing
                  ? ServiceTypeEnum.wedding
                  : null,
                event?.wedding?.completed && !event?.customPricing
                  ? ServiceTypeEnum.general
                  : null,
              ].filter((itm) => itm), // contains Event description
            };
          })
          .filter((itm) => itm.types.length > 0), // contains at least one description
      }))
      .filter((itm) => itm.events.length > 0); //interested only non-empty elements
  }
}
