import { Component, inject, Inject } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MessagesType, MESSAGES_TYPE } from '../services/messages-type.token';
import { MessagingService } from '../../services/messaging.service';
import { ChatRoom } from '../../types/messaging.types';
import { MessagesService } from '../services/messages.service';
import { LayoutService } from '@jarvis/ui';
import { ServiceEvents } from '@jarvis/types';
import { DOMAIN_COUNTRY } from '@jarvis/services';

const BOOKING_LINKS = {
  user: { 
    LT: ['/visos-rezervacijos', 'detales'],
    US: ['/bookings-list', 'detail']
  },
  vendor: {
    LT: ['/bookings', 'detail'],
    US: ['/bookings', 'detail']
  }
};

@Component({
  selector: 'messaging-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss']
})
export class MessagingChatHeaderComponent {
  domainCountry = inject(DOMAIN_COUNTRY).toUpperCase();
  isMobile$ = this.layoutService.isLaptopObserver;
  phoneShown = false;
  vendorType = inject(MESSAGES_TYPE);

  currentChatroom$: Observable<ChatRoom>;
  uniqueServiceEvents$: Observable<ServiceEvents[]>;
  route2Bookings = new BehaviorSubject<string[]>(null);

  constructor(
    @Inject(MESSAGES_TYPE) public messagesType: MessagesType,
    private layoutService: LayoutService,
    private messagingService: MessagingService,
    private messagesService: MessagesService
  ) {
    this.currentChatroom$ = combineLatest([
      this.messagingService.allChatroomCache$,
      this.messagesService.currentChatroomId$
    ]).pipe(
      switchMap(([chatrooms, currentId]) => {
        if (!currentId) {
          return of(null);
        }
        this.route2Bookings.next(null);
        const chatroomFromCache = chatrooms.find(chatroom => chatroom._id === currentId);

        if (!chatroomFromCache) {
          return this.messagingService.getChatroom(currentId).pipe(
            map((chatroomRaw) => chatroomRaw[0])
          );
        }

        return of(chatroomFromCache);
      })
    );

    this.uniqueServiceEvents$ = this.messagesService.uniqueServiceEvents$;

    const bookingsRoute = BOOKING_LINKS[this.vendorType]?.[this.domainCountry] || [];

    this.messagesService.currentBookings$.subscribe(bookings => {        
      const bookingCount = bookings.length;
      
      if (!bookingCount) {
        return;
      }
      const route = bookingCount > 1 ? bookingsRoute[0] : [ ...bookingsRoute, bookings[0]._id ];
      this.route2Bookings.next(route);
    });
  }


}
