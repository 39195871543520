import {UntypedFormControl,UntypedFormGroup } from '@angular/forms';

export const checkFormOrGroupInvalid = (formOrGroup:UntypedFormControl |UntypedFormGroup) => {
    if (formOrGroup.invalid) {
        formOrGroup.markAllAsTouched();
        return false;
    }

    return true;
};
