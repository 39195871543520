import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'jarvis-read-more-link',
  templateUrl: './read-more-link.component.html',
  styleUrls: ['./read-more-link.component.scss'],
  imports: [CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class JarvisReadMoreLinkComponent implements AfterViewInit, OnDestroy {
  @ViewChild('linkText') linkText: ElementRef;

  @Input() text: string;
  @Input() maxHeight = '100';
  @Input() fontSize = '16px';
  @Input() lineHeight = '150%';

  isCollapsed = false;
  isCollapsable = false;

  private resizeEvent$: Subject<MouseEvent> = new Subject<MouseEvent>();
  private destroyed$: Subject<void> = new Subject();

  @HostListener('window:resize', ['$event'])
  onResize(event: MouseEvent) {
    this.resizeEvent$.next(event);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.checkCollapsed();
    this.cdr.detectChanges();
    this.subscribeToResizeEvent();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  readClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }

  private subscribeToResizeEvent(): void {
    this.resizeEvent$
      .pipe(
        debounceTime(200),
        tap(() => {
          this.cdr.markForCheck();
          this.checkCollapsed();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  private checkCollapsed(): void {
    const currentHeight = this.linkText.nativeElement.scrollHeight;
    this.isCollapsed = this.isCollapsable = currentHeight > this.maxHeight;
  }
}
