import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'mat-label',
  standalone: true,
})
export class ChromiumDropdownFixDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.removeAriaOwns();
  }

  private removeAriaOwns(): void {
    const parentElement = this.elementRef?.nativeElement?.parentElement;

    if (parentElement) {
      parentElement.removeAttribute('aria-owns');
    }
  }
}
