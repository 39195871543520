import { NgModule } from '@angular/core';
import {
  JarvisServicesDepositPipe,
  JarvisServicesGlobalMinimalBudgetPipe,
  JarvisServicesIncludedServicesPipe,
  JarvisServicesServiceChargePipe,
  JarvisServicesServiceDiscountPipe,
  JarvisServicesTaxPipe,
  JarvisServicesTotalPriceTaxDifferencePipe,
  JarvisServicesTotalServicePricePipe
} from '.';

const PIPES = [
  JarvisServicesServiceDiscountPipe,
  JarvisServicesIncludedServicesPipe,
  JarvisServicesTotalServicePricePipe,
  JarvisServicesGlobalMinimalBudgetPipe,
  JarvisServicesServiceChargePipe,
  JarvisServicesTaxPipe,
  JarvisServicesTotalPriceTaxDifferencePipe,
  JarvisServicesDepositPipe
];

@NgModule({
  imports: [],
  providers: [],
  exports: [...PIPES],
  declarations: [...PIPES]
})
export class JarvisServicesListingPipesModule {}
