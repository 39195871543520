import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'
import { CommonDialogService } from '@jarvis/ui';
import { AdvertsListingService } from '../listing.service';
import { switchMap } from 'rxjs/operators';

@Component({template:`<div></div>`}
          )

export class InstagramAuth implements OnInit {
   
   constructor (private activatedRoute: ActivatedRoute,
                private router: Router,
                private listingService: AdvertsListingService,
                private commonDialogService:CommonDialogService,
                private httpService: HttpClient
                ) {}

   ngOnInit () {
      const {code,state} =  this.activatedRoute.snapshot.queryParams;

       if (!(code && state))          
           this.commonDialogService.openSuccessRx("errors.errorMsg", "error");
       else {    
         const stateObj = JSON.parse(decodeURIComponent(state));
         this.httpService.post(`${environment.baseUrl}api/instagram/connect`,
         {serviceBase:stateObj.serviceBase, code:code, redirect_uri: `${environment.saasUrl}/ig/auth/`}).pipe(
            switchMap((v:any)=> this.listingService.editBaseService(stateObj.serviceBase, 
                  { "extra.igUserId": v.igUserId, "extra.igUser":v.instaUser}))
         ).subscribe(v=>this.router.navigateByUrl(stateObj.url, { state: { connectInstagram: true }} ),
        error => {
         this.commonDialogService.openSuccessRx("errors.errorMsg", "error", null,"Error: " + error?.error?.errors.map(x => x.message).join(", "));
        }
         );
      }
   }

}