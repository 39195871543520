import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Pipe({
  name: 'ImagePreview',
  standalone: true
})
export class ImagePreviewPipe implements PipeTransform, OnDestroy {

  private result = new Subject<string | ArrayBuffer>();

  transform(file: File): Observable<string | ArrayBuffer> {
    const fileReader = new FileReader();

    fileReader.onload = (loadEvent) => {
      this.result.next(loadEvent.target.result);
    };

    fileReader.readAsDataURL(file);

    return this.result.asObservable();
  }

  ngOnDestroy(): void {
    this.result.complete();
  }
}