import { Pipe, PipeTransform } from '@angular/core';
import { getMonth } from 'date-fns';

@Pipe({
    name: 'CurrentMonth',
    pure: true
})

export class CurrentMonthPipe implements PipeTransform {
    transform(value: number): boolean {
        const currentMonth = getMonth(new Date());
        return value  === currentMonth;
    }
}