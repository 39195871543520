import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JarvisOverlayComponent } from '@jarvis/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'messages-chat-messages-actions',
  templateUrl: './chat-actions.component.html',
  styleUrls: [
    './chat-actions.component.scss'
  ],
  standalone: true,
  imports: [
    CommonModule,
    JarvisOverlayComponent,
    TranslateModule
  ]
})

export class MessagesChatMessagesActionsComponent {
  @Input() actionOverlayData: any;
  @Input() messagesType: string;
  @Input() domainCountry: string;
  @Output() closed = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() markUnread = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() autoReply = new EventEmitter();

  close(): void {
    this.closed.emit();
  }

  markAsUnread(): void {
    this.markUnread.emit();
  }
  
  startMessageEdit(id: string, message: string): void {
    this.edit.emit({ id, message });
  }
  
  deleteMessage(id: string): void {
    this.delete.emit(id);
  }

  enableAutoReply(): void {
    this.autoReply.emit();
  }

}

