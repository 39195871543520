import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ServiceDiscount',
  pure: false
})
export class JarvisServicesServiceDiscountPipe implements PipeTransform {
  transform(value: any, discount: any): any {
    // const discount = this.reservationService.bookingReservationForm.get('discount').value;
    if (discount.type && discount.value) {
      if (discount.type === 'percent') {
        return value * (1 - discount.value / 100);
      }

      if (discount.type === 'fixed') {
        return value - discount.value;
      }
    }

    return value;
  }
}
