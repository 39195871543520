
import { NgModule } from '@angular/core';
import { JarvisHttpStateRequestErrorPipe, JarvisHttpStateRequestLoadingPipe } from './http-state.pipes';

const PIPES = [
    JarvisHttpStateRequestLoadingPipe,
    JarvisHttpStateRequestErrorPipe
];

@NgModule({
    exports: [
        ...PIPES
    ],
    declarations: [
        ...PIPES
    ]
})
export class JarvisHttpStateModule { }
