import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { JarvisAuthService } from '@jarvis/auth/services';

@Injectable({providedIn: 'root'})
export class JarvisUserProfilingGuard implements CanActivate {
  constructor(
      private authService: JarvisAuthService,
      private router: Router
  ) { }

  async canActivate() {
    const user = await this.authService.userData$.pipe(take(1)).toPromise();

    if (!user || user.userType !== 'vendor') {
      return true;
    }

    if (user.profiling?.data && user.profiling.data.completed === false) {
      this.router.navigate(['onboarding']);
      return true;
    }

    return true;
  }
}