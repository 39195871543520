import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'OnlyImages',
  standalone: true
})
export class OnlyImagesPipe implements PipeTransform {

  transform(files: any[]): boolean {
    if (!files) {
      return false;
    }
    const isImageArray: boolean[] = files.map(file => file.fileType.includes('image'));

    if (!isImageArray.length) {
      return false;
    }
  
    return isImageArray.every(value => value);
  }
}