import { Injectable, TemplateRef } from '@angular/core';
import { ModalService } from './modal.service';
import { LayoutService } from './layout.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '../components/confirm-modal/confirm-modal.component';
import { SuccessDialogComponent } from '../components/success-modal/success-modal.component';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  ComponentPortal,
  ComponentType,
  TemplatePortal,
} from '@angular/cdk/portal';
import { ModalWrapperComponent } from '../components/modal-wrapper/modal-wrapper.component';

@Injectable()
export class CommonDialogService extends ModalService {
  constructor(dialog: MatDialog, layoutService: LayoutService) {
    super(dialog, layoutService);
  }

  dialogRef: MatDialogRef<any>;

  /****************
   *  Usage:
   *
   *  constructor(private commonDialogService: CommonDialogService, ....
   *
   *  ....
   *  this.commonDialogService.openConfirm();
   *  this.commonDialogService.confirmed().subscribe(confirmed => {
   *     if (confirmed) { // console.log("Action is confirmed")
   *  }
   *
   *   Or
   *
   *  this.commonDialogService.openConfirm("key-to-custom-messages");
   *
   *  Where "key-to-custom-messages" - is key in translations. Example:
   *    "key-to-custom-messages": { "message":"Custom confirm message", "confirm":"Yes", "canclel":"No"}
   *
   * **************/

  public openConfirm(i18nKey?: string): MatDialogRef<boolean> {
    this.dialogRef = super.openDialog(ConfirmDialogComponent, {
      width: '672px',
      panelClass: 'confirm-dialog',
      data: { i18nKey },
    });
    return this.dialogRef;
  }

  public openConfirmRx(i18nKey?: string, addData?: object): Observable<number> {
    this.dialogRef = super.openDialog(ConfirmDialogComponent, {
      width: '672px',
      panelClass: 'confirm-dialog',
      data: { ...{ i18nKey: i18nKey }, ...addData },
    });
    return this.dialogRef
      .afterClosed()
      .pipe(map((value) => (value == null ? -1 : value ? 1 : 0)));
  }

  public openSuccess(
    i18nKey?: string,
    icon: string = null,
    title: string = null,
    message: string = null,
    values: any = null,
    height = '332px'
  ): MatDialogRef<boolean> {
    this.dialogRef = super.openDialog(SuccessDialogComponent, {
      height,
      width: '672px',
      panelClass: 'success-dialog',
      data: { i18nKey, icon, title, message, values },
    });
    return this.dialogRef;
  }

  public openSuccessRx(
    i18nKey?: string,
    icon: string = null,
    title: string = null,
    message: string = null,
    values: any = null
  ): Observable<number> {
    this.dialogRef = super.openDialog(SuccessDialogComponent, {
      height: '332px',
      width: '672px',
      panelClass: 'success-dialog',
      data: { i18nKey, icon, title, message, values },
    });
    return this.dialogRef
      .afterClosed()
      .pipe(map((value) => (value == null ? -1 : value ? 1 : 0)));
  }

  /* public openTemplateDialog<T>(template: TemplateRef<unknown>, data?: any, panelClass?: string): MatDialogRef<T> {
    const wrapperPortal = new ComponentPortal(ModalWrapperComponent);
    wrapperPortal.attach()
    this.dialogRef = super.openDialog(wrapperPortal, { height: '332px', width: '672px', panelClass, data });
    return this.dialogRef;
  } */

  public openCustomDialog<T>(
    component: ComponentType<T>,
    data?: any,
    panelClass?: string,
    height = '332px'
  ): MatDialogRef<T> {
    this.dialogRef = super.openDialog(component, {
      height,
      width: '672px',
      panelClass,
      data,
    });
    return this.dialogRef;
  }

  public openCustomWDialog<T>(
    component: ComponentType<T>,
    data?: any,
    panelClass?: string
  ): MatDialogRef<T> {
    this.dialogRef = super.openDialog(component, {
      width: '672px',
      panelClass,
      data,
    });
    return this.dialogRef;
  }

  public openDialogRx<T>(
    component: ComponentType<T>,
    data?: any,
    panelClass?: string
  ): Observable<any> {
    return super
      .openDialog(component, { width: '672px', panelClass, data })
      .afterClosed();
  }

  public closed(): Observable<any> {
    return this.confirmed();
  }

  public confirmed(
    dialogRef: MatDialogRef<any> = this.dialogRef
  ): Observable<any> {
    return dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      })
    );
  }
}
