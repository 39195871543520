import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ImagePreviewPipe } from '../../pipes/image-preview.pipe';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PickerModule as EmojiPickerModule } from '@ctrl/ngx-emoji-mart';
import { ButtonModule } from '@jarvis/ui';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'chat-messages-actions',
  standalone: true,
  templateUrl: './actions.component.html',
  styleUrls: [ './actions.component.scss' ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    ButtonModule,
    ImagePreviewPipe,
    ReactiveFormsModule,
    MatInputModule,
    OverlayModule,
    MatTooltipModule,
    EmojiPickerModule
  ]
})
export class ChatMessagesActionsComponent implements OnInit {

  @ViewChild('messageInput')
  set messageInput(element: ElementRef<HTMLInputElement>) {
    if (element) {
      this._messageInputElement = element;
      element.nativeElement.placeholder = this.translateService.instant(this._placeholder);
    }
  }
  protected _messageInputElement: ElementRef<HTMLInputElement>;

  @Input()
  set message(message: string) {
    this.messageControl.setValue(message);
  }

  get message() {
    return this.messageControl.value;
  }

  @Input() 
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    if (this._messageInputElement) {
      this._messageInputElement.nativeElement.placeholder = this.translateService.instant(this._placeholder);
    }
  };
  protected _placeholder  = 'messages.writeMessage';

  @Input() messageActionType = 'text';
  @Input() isMobile = false;
  @Input() isExpanded = false;
  @Input() expandEnabled = false;
  @Input() showMessageActionButton = true;
  @Input() disableAutoHeight = false;
  @Input() messageActionButtonDisabled = false;
  @Input() editMode = false;
  @Input() minRows = 1;
  @Input() maxRows = 10;

  @Input() showActions = true;

  @Output() sendMessage = new EventEmitter<string>();
  @Output() expand = new EventEmitter<string>();
  @Output() inputBlurred = new EventEmitter<string>();

  emojiPickerOpen = false;
  
  messageControl = new FormControl<string>('');

  textAreaFocused = false;
  uploadFiles = [];

  private translateService = inject(TranslateService);

  ngOnInit(): void {
    
  }


  fileInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = input.files;

    const fileArray = Array.from(files);

    this.uploadFiles = [
      ...this.uploadFiles,
      ...fileArray
    ];

    input.value = null;
  }

  toggleTextAreaFocus(event: 'focus' | 'blur'): void {
    if (event === 'blur') {
      this.textAreaFocused = false;
      this.inputBlurred.emit();
      return;
    }

    this.textAreaFocused = true;
  }

  handleInputKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey && !this.isMobile) {
      if (this.editMode) {
        this.confirmMessageEditHandler();
      } else {
        this.sendMessageHandler();
      }
      const target = event.target as HTMLInputElement;
      target.blur();
      event.preventDefault();
    }
  }

  sendMessageHandler() {
    const textMessage = this.messageControl.value;

    if (!textMessage || this.messageActionButtonDisabled) {
      return;
    }

    this.sendMessage.emit(textMessage);
    this.messageControl.reset();
  }

  confirmMessageEditHandler() {

  }

  toggleTextAreaExpand() {
    this.expand.emit();
  }

  cancelMessageEdit() {
    // this.editMode = false;
  }

  removeFile(index: number) {
    this.uploadFiles.splice(index, 1);
  }

  closeEmojiPicker(): void {
    this.emojiPickerOpen = false;
  }

  openEmojiPicker(): void {
    this.emojiPickerOpen = true;
  }

  addEmoji(event: any) {
    const emojiChar = event.emoji.native;
    const currentValue = (this.messageControl.value as string) ?? '';
    let newValue: string;
    if (currentValue.charAt(currentValue.length - 1) !== ' ') {
      newValue = `${currentValue} ${emojiChar}`;
    } else {
      newValue = `${currentValue}${emojiChar}`;
    }

    this.messageControl.setValue(newValue);
    this.closeEmojiPicker();
  }
}