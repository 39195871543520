import { Pipe, PipeTransform } from '@angular/core';
import { kebabCaseToCamelCase, serviceEventPictureSort } from '@jarvis/utils';

export const ICON_DATA = {
  new: '/assets/icons/icon-types/uplifted/ic_new.svg',
  venues: '/assets/icons/icon-types/uplifted/ic_venues.svg',
  ceremony_venue: '/assets/icons/icon-types/ic_ceremony.svg',
  catering: '/assets/icons/icon-types/ic_food.svg',
  ceremony_regale: '/assets/icons/icon-types/ic_regale.svg',
  bartenders: '/assets/icons/icon-types/ic_bartender.svg',
  foodtruck: '/assets/icons/icon-types/ic_food_cart.svg',
  videographer: '/assets/icons/icon-types/ic_video.svg',
  photographer: '/assets/icons/icon-types/ic_photo.svg',
  live_music: '/assets/icons/icon-types/ic_music.svg',
  planning: '/assets/icons/icon-types/ic_planning.svg',
  host: '/assets/icons/icon-types/ic_microphone.svg',
  cakes_desserts: '/assets/icons/icon-types/ic_cake.svg',
  makeup_hairdressing: '/assets/icons/icon-types/ic_makeup.svg',
  dj: '/assets/icons/icon-types/ic_dj.svg',
  bride_clothing: '/assets/icons/icon-types/ic_dress.svg',
  groom_clothing: '/assets/icons/icon-types/ic_groom.svg',
  secondary_dress: '/assets/icons/icon-types/ic_market.svg',
  transport: '/assets/icons/icon-types/ic_transport.svg',
  decor: '/assets/icons/icon-types/ic_decorations.svg',
  invitations_printing: '/assets/icons/icon-types/ic_invitation.svg',
  decor_floristics: '/assets/icons/icon-types/ic_flowers.svg',
  pavilion_furniture: '/assets/icons/icon-types/ic_pavilion.svg',
  sound_lights: '/assets/icons/icon-types/ic_effects.svg',
  screens_projectors: '/assets/icons/icon-types/ic_screens.svg',
  entertainment: '/assets/icons/icon-types/ic_party.svg',
  show: '/assets/icons/icon-types/ic_show.svg',
  training: '/assets/icons/icon-types/uplifted/ic_training.svg',
  jewelery: '/assets/icons/icon-types/ic_rings.svg',
  presents: '/assets/icons/icon-types/ic_present.svg',
  honeymoon: '/assets/icons/icon-types/ic_honeymoon.svg',
  photo_booth: '/assets/icons/icon-types/ic_photobooth.svg',
  ceremony_host: '/assets/icons/icon-types/ic_ceremony_host.svg',
  visual_entertainment: '/assets/icons/icon-types/ic_visual_entertainment.svg',
};

@Pipe({
  name: 'PlannerCardPictures',
})
export class PlannerCardPicturesPipe implements PipeTransform {
  transform(value: any, eventType: string = 'wedding'): any {
    if (value.customServiceEvent) {
      return [ICON_DATA[value.category || value.customServiceEvent.category]];
    }
    const serviceEvent =
      value.localEvent || value.eventId
        ? value
        : value.serviceEvent[0] || value.serviceEvent;

    const customPricing = serviceEvent.customPricing === true;
    const eventTypeParsed = customPricing
      ? kebabCaseToCamelCase(eventType)
      : 'wedding';

    const photos =
      serviceEvent.venue?.photos ||
      serviceEvent[eventTypeParsed]?.photos ||
      serviceEvent['wedding']?.photos;

    if (!photos) {
      return [];
    }
    const sortedPhotos = serviceEventPictureSort(photos);

    return sortedPhotos;
  }
}
