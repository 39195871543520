import { NgModule } from '@angular/core';
import { JarvisDateInputMaskDirective } from './date-input-formatter.directive';

const DIRECTIVES = [
    JarvisDateInputMaskDirective
];

@NgModule({
    imports: [],
    exports: [
        ...DIRECTIVES
    ],
    declarations: [
        ...DIRECTIVES
    ],
    providers: [],
})
export class JarvisUiInputFormatsModule { }
