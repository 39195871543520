import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JarvisUiModalService, LayoutService } from '@jarvis/ui';
import {
  DOMAIN_COUNTRY,
  JarvisLanguageService,
  JarvisTrackingService,
} from '@jarvis/services';
import { map, mergeAll, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { TitleCasePipe } from '@angular/common';
import { ReviewsService } from '../services/reviews.service';
import { BreezitReviewData, ThirdPartyReviewData, ThirdPartyReviewProvider } from '../reviews.types';
import { ReviewsUtils } from '../utils/review-utils';

@Component({
  selector: 'jarvis-reviews-list',
  templateUrl: './list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./list.component.scss'],
})
export class ReviewsListComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() listing: any;
  @Input() hideIfNoReviews: boolean;

  reviewDate = new Date();
  reviewData: BreezitReviewData;
  thirdPartReviewData: ThirdPartyReviewData[];
  isMobile$ = this.layoutService.isMobileObserver;
  vendorProfilePhotoUrl: string;
  defTabIndex = 0;

  replyInput = null;
  replyEditInput = null;
  listingData;
  vendorProfilePicture;
  vendorName: string;
  locale: string;
  providers = ReviewsUtils.thirdPartyReviewProviders;

  paginationSkip = {
    facebook: 0,
  };

  private destroy$ = new Subject<void>();
  domainCountry = inject(DOMAIN_COUNTRY);
  private trackService = inject(JarvisTrackingService);

  constructor(
    private layoutService: LayoutService,
    private currentRoute: ActivatedRoute,
    private reviewsService: ReviewsService,
    private modalService: JarvisUiModalService,
    private languageService: JarvisLanguageService,
    private translationService: TranslateService
  ) {
    this.locale = languageService.getLocaleByLanguage();
  }

  ngOnInit(): void {
    this.listingData =
      this.type && this.type === 'listing' && !this.listing
        ? this.currentRoute.snapshot.data.listing.service
        : this.listing
          ? this.listing
          : this.currentRoute.snapshot.data.listing;

    // this.vendorProfilePicture = this.listingData;
    of(this.getReviewDataObservable(), this.getThirdPartyReviews())
      .pipe(mergeAll())
      .subscribe(() => {
        if (
          this.thirdPartReviewData &&
          this.thirdPartReviewData.length &&
          this.reviewData &&
          !this.reviewData.reviewsBase
        )
          this.defTabIndex = 1;
      });

    this.languageService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((language) => {
        this.translationService.use(language);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addReply(id: string): void {
    this.replyInput = id;
  }

  cancelReply(): void {
    this.replyInput = false;
  }

  sendReply(id: string, replyInput: HTMLTextAreaElement): void {
    const value = replyInput.value;
    this.reviewsService.createEditDeleteReply(id, value).subscribe(() => {
      this.replyInput = false;
      this.getReviewData();
    });
  }

  sendEditReply(id: string, replyInput: HTMLTextAreaElement): void {
    const value = replyInput.value;

    this.reviewsService.createEditDeleteReply(id, value).subscribe(() => {
      this.replyEditInput = false;
      this.getReviewData();
    });
  }

  deleteReply(id: string): void {
    this.reviewsService.createEditDeleteReply(id, null).subscribe(() => {
      this.getReviewData();
    });
  }

  editReply(id: string): void {
    this.replyEditInput = id;
  }

  cancelEditReply(): void {
    this.replyEditInput = false;
  }

  deleteReview(id: string): void {
    this.reviewsService.deleteReview(id).subscribe((data) => {
      this.getReviewData();
    });
  }

  public getThirdPartyByProvider(
    provider: ThirdPartyReviewProvider
  ): ThirdPartyReviewData {
    return this.thirdPartReviewData?.find(
      (v) => v.source == provider.toString()
    );
  }

  public isThirdPartyByProvider(
    provider: ThirdPartyReviewProvider
  ): boolean {
    return (this.getThirdPartyByProvider(provider)?.count ?? 0) > 0;
  }

  private getThirdPartyReviews(): Observable<ThirdPartyReviewData[]> {
    const serviceBaseId = this.listingData._id;
    return this.reviewsService.getThirdPartyReviews(serviceBaseId).pipe(
      switchMap((data) => {
        const observables = data.map((review) => {
          if (review.count && review.reviews.length === 0) {
            return this.reviewsService
              .getSingleThirdPartyReviews(review._id)
              .pipe(
                map((data: any) => {
                  review.reviews = data.reviews;
                  return review;
                })
              );
          }

          return of(review);
        });

        return forkJoin(observables);
      }),
      tap((data) => {
        this.thirdPartReviewData = data;
      })
    );
  }

  public getBreezitReviewCount() {
    return this.reviewData?.reviewsBase?.reviewsCount | 0;
  }

  private getReviewData() {
    this.getReviewDataObservable().subscribe();
  }

  handleShowMoreReviewsTrack(source: string) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'showMoreReviews',
      data: {
        source,
      },
    });
  }

  showMoreReviews(provider) {
    this.handleShowMoreReviewsTrack(provider.source);
    this.paginationSkip[provider.source] =
      this.paginationSkip[provider.source] + 5;

    this.reviewsService
      .getSingleThirdPartyReviews(
        provider._id,
        this.paginationSkip[provider.source]
      )
      .subscribe((data: any) => {
        provider.reviews = provider.reviews.concat(data.reviews);
      });
  }

  private getReviewDataObservable(): Observable<BreezitReviewData> {
    const serviceBaseId = this.listingData._id;
    return this.reviewsService.getServiceReviews(serviceBaseId).pipe(
      tap((data) => {
        this.reviewData = data;
        if (data?.reviewsBase?.reviewsCount == 0)
          this.reviewData.reviewsBase = null;
        if (data.reviewsBase) {
          this.vendorProfilePhotoUrl =
            data.reviewsBase.serviceBase.user.profilePhoto;
          this.vendorName = data.reviewsBase.serviceBase.brandName;
        }
      })
    );
  }

  handleTabClick(providerName: string) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: `Switched to ${TitleCasePipe.prototype.transform(
        providerName
      )} reviews`,
    });
  }
}
