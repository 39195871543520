import { SaasLandingTemplateComponent } from './saas-landing-template.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions, MatLegacyFormFieldModule as MatFormFieldModule, MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { LANDING_TYPE } from '../services';
import { RouterModule, Routes } from '@angular/router';
import { ButtonModule } from '@jarvis/ui';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { LandingsModule } from '../landings.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { MatLegacyMenuDefaultOptions as MatMenuDefaultOptions, MatLegacyMenuModule as MatMenuModule, MAT_LEGACY_MENU_DEFAULT_OPTIONS as MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/legacy-menu';

const MENU_DEFAULT_OPTIONS: Partial<MatMenuDefaultOptions> = {
  overlayPanelClass: 'bzt-menu-overlay'
}

const FORM_FIELD_DEFAULTS: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

const ROUTES: Routes = [
  {
    path: 'client-management-software',
    component: SaasLandingTemplateComponent
  },
  {
    path: 'jody-limited-offer',
    component: SaasLandingTemplateComponent
  },
  {
    path: 'photoboss',
    component: SaasLandingTemplateComponent
  },
  {
    path: 'marketplace-event-venues',
    component: SaasLandingTemplateComponent
  },
  {
    path: 'breezit-photographers',
    component: SaasLandingTemplateComponent
  },
  {
    path: 'profile-link',
    component: SaasLandingTemplateComponent
  }
];

@NgModule({
  declarations: [
    SaasLandingTemplateComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    LayoutModule,
    ButtonModule,
    TranslateModule.forChild(),
    HttpClientModule,
    LandingsModule,
    MatSidenavModule,
    MatMenuModule
  ],
  providers: [
    TranslatePipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: FORM_FIELD_DEFAULTS
    },
    {
      provide: LANDING_TYPE,
      useValue: 'vendor'
    },
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: MENU_DEFAULT_OPTIONS
    }
  ],
  exports: [
    SaasLandingTemplateComponent
  ]
})
export class SaasLandingTemplateModule { }
