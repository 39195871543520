import { OverlayRef } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { FormControl } from '@angular/forms';

export const GOOGLE_LOCATION_FILTER_OVERLAY_REF =
  new InjectionToken<OverlayRef>('Google location filter overlay ref token');
export const GOOGLE_LOCATION_FILTER_FORM_CONTROL =
  new InjectionToken<FormControl>('Google location filter form control token');
export const GOOGLE_LOCATION_FILTER_DISPLAY_VALUE = new InjectionToken<
  (value: string) => void
>('Google location filter display value token');
export const LOCATION_FILTER_CONFIGURATION = new InjectionToken<{
  hideSearch: any;
}>('Location filter configuration');
