import { Pipe, PipeTransform, inject } from '@angular/core';
import {
  DOMAIN_COUNTRY,
  ServicesService,
  extractServicesFromAdvert,
  extractTextAddressFromService,
  getServicePrices,
} from '@jarvis/services';
import { TranslateService } from '@ngx-translate/core';
import { ServiceCurrencyPipe } from '@jarvis/services';
import { kebabCaseToCamelCase, kebabCaseToSnakeCase } from '@jarvis/utils';
import { ReviewsUtils } from '@jarvis/reviews';

@Pipe({
  name: 'PlannerCardData',
  pure: true,
})
export class PlannerCardDataPipe implements PipeTransform {
  private country = inject(DOMAIN_COUNTRY);

  constructor(
    private translateService: TranslateService,
    private currencyPipe: ServiceCurrencyPipe,
    private servicesService: ServicesService
  ) {}

  transform(
    value: any,
    eventType: string = 'wedding',
    date = new Date(),
    guests
  ): any {
    const serviceEvent =
      value.localEvent || value.customServiceEvent
        ? value
        : value.serviceEvent[0] || value.serviceEvent;
    if (value.customServiceEvent) {
      const customLike =
        value.localEvent || typeof value.customServiceEvent === 'boolean'
          ? value
          : value.like[0]?.customServiceEvent || value.customServiceEvent;
      const objectToReturn = {
        _id: value._id,
        customLike,
        eventType: value.eventType,
        like: value.like?.[0],
        plannerType: 'budget',
        type:
          'categoriesMarketplace.' + kebabCaseToSnakeCase(customLike.category),
        address: customLike.location,
        booking: null,
        customServiceEvent: true,
        plannerItem: true,
        title: customLike.vendorName,
        rating: `-`,
        ratingCount: `-`,
        indicativePrice: this.currencyPipe.transform(
          customLike.budget,
          null,
          '1.0-0',
          this.country.toUpperCase()
        ),
        priceUnit: '',
        slug: customLike.url,
        instagramLink: null,
        venueInfo: null,
        priceAddition: null,
        serviceBase: null,
        serviceEvent: { _id: value.eventId },
        localLike: value.localEvent,
        note: value.comment || value.note,
      };
      return objectToReturn;
    }

    const serviceBase = value.serviceBase[0] || value.serviceBase;

    if (!['venues', 'catering'].includes(serviceBase.type)) guests = 1;

    const serviceType = serviceBase.type;
    const subcategories = serviceBase.subcategories?.types;
    const firstSubtype = subcategories
      ? Object.keys(subcategories).find(
          (categoryType) => subcategories[categoryType] === true
        )
      : '';

    let avgRating = null;
    let ratingCount = '-';
    let rating = '-';

    if (value?.thirdPartyReviews) {
      avgRating = ReviewsUtils.averageRating(
        value?.thirdPartyReviews,
        value.reviewBase?.[0]
      );
      ratingCount = avgRating.count;
      rating = avgRating.rating;
    }

    let venueInfo = null;
    if (serviceType === 'venues') {
      const hasCatering =
        serviceEvent.cateringPrice &&
        serviceEvent.cateringPrice <= serviceEvent.price;
      const hasAccomodation = serviceEvent.accomodationPrice;
      const hasDrinks =
        hasCatering && serviceEvent.venue.catering?.alcoholPriceIncluded;
      const guestCount = serviceBase.venues.maxBanquetPeopleCount || null;
      const filteredGuestCount = guests;

      venueInfo = {
        hasCatering,
        hasAccomodation,
        hasDrinks,
        guestCount,
        filteredGuestCount,
      };
    }

    const cardAddress = extractTextAddressFromService(
      serviceBase,
      this.translateService
    );
    const brandName = this.servicesService.extractServiceEventName(
      serviceBase,
      serviceEvent._id
    );
    const breezitExclusivePrice = serviceEvent.extra?.breezitExclusive ?? null;
    const extracted = extractServicesFromAdvert({
      base: serviceBase,
      event: serviceEvent.wedding,
    });

    const servicePrices = getServicePrices(extracted, guests || 1, date);

    let indicativePrice = servicePrices.first.price;
    const priceAddition: {
      hoursIncluded?: number;
      negotiablePricing: boolean;
      breezitExclusive?: { enabled: boolean; value: number } | null;
    } = {
      negotiablePricing: serviceBase?.advances?.canRebate,
    };

    const defaultPrice = serviceEvent.defaultPrice?.description;
    if (
      defaultPrice &&
      ['photographer', 'videographer'].includes(serviceType)
    ) {
      priceAddition.hoursIncluded = defaultPrice.hoursIncluded;

      if (
        defaultPrice.priceUnit === 'hour' &&
        defaultPrice.minimalBudget &&
        defaultPrice.priceValue
      )
        priceAddition.hoursIncluded =
          Math.round(
            (defaultPrice.minimalBudget / defaultPrice.priceValue) * 10
          ) / 10;
      if (!defaultPrice.additionalPriceEnabled)
        indicativePrice = serviceEvent.defaultPrice?.description?.minimalBudget
          ? serviceEvent.defaultPrice?.description?.minimalBudget
          : indicativePrice;
    }

    // console.log(brandName, value);

    let notAvailable = null;
    if (venueInfo?.filteredGuestCount > venueInfo?.guestCount) {
      notAvailable = 'NO_SPACE';
    }
    if (
      value.blocks > 0 ||
      (value.bookings && value.bookings?.length >= value.maxClientCount)
    )
      notAvailable = 'NO_TIME';

    if (notAvailable === 'NO_SPACE')
      venueInfo.filteredGuestCount = venueInfo.guestCount;

    return {
      _id: value._id,
      plannerType: 'budget',
      type: firstSubtype
        ? `subcategories.${serviceType}.${firstSubtype}`
        : 'categories.' + kebabCaseToSnakeCase(serviceType),
      address: cardAddress,
      title: brandName,
      category: serviceType,
      booking: value.bookings?.[0] || null,
      plannerItem: value,
      rating: `${rating}`,
      ratingCount: `${ratingCount}`,
      priceNumber: indicativePrice,
      eventType: value.eventType || eventType,
      eventTypeCamel: kebabCaseToCamelCase(value.eventType || eventType),
      indicativePrice: this.currencyPipe.transform(
        indicativePrice,
        serviceBase,
        '1.0-0'
      ),
      priceUnit:
        serviceEvent.defaultPrice?.description?.priceUnit ||
        serviceEvent?.venue?.catering?.priceUnit ||
        serviceEvent.innerPrices?.service?.priceUnit ||
        '',
      slug:
        `${serviceEvent.slug || serviceEvent._id}` +
        (serviceEvent.customPricing ? `/${eventType}` : ''),
      instagramLink: serviceBase?.contacts?.instagramLink,
      venueInfo,
      localLike: value.localEvent,
      priceAddition: {
        ...priceAddition,
        breezitExclusive: breezitExclusivePrice,
      },
      serviceBase: serviceBase,
      serviceEvent: serviceEvent,
      note: value.note,
      notAvailable,
    };
  }
}
