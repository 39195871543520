import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BookingsType, BookingsResult } from './bookings.types';
import { BookingsDataService } from './bookings.service';
import { ServiceBase } from '@jarvis/foxy-vendor/app/core/types/adverts/ServiceBase.interface';
import { JarvisAuthService } from '@jarvis/auth';

@Injectable()
export class BookingsListResolver implements Resolve<BookingsResult> {
  constructor(private bookingsService: BookingsDataService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<BookingsResult> {
    return this.bookingsService.getBookingsList(
      1,
      BookingsDataService.pageSize
    );
  }
}

@Injectable()
export class BookingsDetailResolver implements Resolve<BookingsType> {
  constructor(private bookingsService: BookingsDataService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<BookingsType> {
    return this.bookingsService.getDetail(route.paramMap.get('id'));
  }
}

@Injectable()
export class BookingsNotRegisteredDetailResolver implements Resolve<BookingsType> {
  constructor(private bookingsService: BookingsDataService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<BookingsType> {
    return this.bookingsService.getNotRegisteredDetail(route.paramMap.get('id'));
  }
}

@Injectable()
export class CurrentUserResolver implements Resolve<any> {
  constructor(private auth: JarvisAuthService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.auth.getCurrentUser();
  }
}

@Injectable()
export class ServiceBaseResolver implements Resolve<ServiceBase[]> {
  constructor(private bookingsService: BookingsDataService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ServiceBase[]> {
    return this.bookingsService.getServiceBaseReduced('compactList');
  }
}
