import { Review, ListItem, LocalBusiness, PostalAddress } from 'schema-dts';

interface ParsedReview {
  text: string;
  review?: string;
  score: number;
  userInfo: string | { name: string; surname: string };
  date: string;
}

interface ParsedReviewProvider {
  score: number;
  totalReviews: number;
  reviews: ParsedReview[];
}

type ListingPhotos = Record<
  string,
  { originalPhotoUrl: string; croppedPhotoUrl: string; caption?: string }[]
>;

interface CarouselRawData {
  rawImages: ListingPhotos;
  name: string;
  reviews: ParsedReviewProvider[];
  url: string;
  price: number;
  address: ListingAddress;
}

interface ListingAddress {
  country: string;
  street: string;
  area2?: string;
}

const PRICE_RANGE_MAP = [
  {
    min: 0,
    max: 10000,
    value: '$',
  },
  {
    min: 10000,
    max: 30000,
    value: '$$',
  },
  {
    min: 30000,
    max: Infinity,
    value: '$$$',
  },
];

export class SeoStructuredDataUtils {
  public static generateAggregateRatingSchema(
    allReviews: ParsedReviewProvider[]
  ) {
    const reviewsCount = allReviews.reduce((total, reviewProvider) => {
      return total + reviewProvider.totalReviews;
    }, 0);

    if (reviewsCount === 0) {
      return null;
    }

    const averageParsed = allReviews.reduce(
      (scoreObject, reviewProvider) => {
        if (!reviewProvider.score || !reviewProvider.totalReviews) {
          return scoreObject;
        }

        return {
          count: scoreObject.count + 1,
          score: scoreObject.score + reviewProvider.score,
        };
      },
      { count: 0, score: 0 }
    );

    const totalAverage = averageParsed.score / averageParsed.count;

    const aggregateRatingSchema = {
      '@type': 'AggregateRating' as const,
      reviewCount: reviewsCount,
      ratingValue: Math.round(totalAverage * 10) / 10,
      worstRating: 0,
      bestRating: 5,
    };

    return aggregateRatingSchema;
  }

  public static generateReviewSchema(allReviews: ParsedReviewProvider[]) {
    const reviewSchema: Review[] = [];

    allReviews.forEach((reviewProvider) => {
      reviewProvider.reviews = reviewProvider.reviews.sort((a, b) => {
        return b.score - a.score;
      });
    });

    const mappedReviews: ParsedReview[] = [];

    allReviews.reduce((mapped, reviewProvider) => {
      mapped.push(...reviewProvider.reviews);
      return mapped;
    }, mappedReviews);

    const firstReviews = mappedReviews.slice(0, 3);

    firstReviews.forEach((review) => {
      let userName: string;

      if (typeof review.userInfo === 'object') {
        userName = review.userInfo.surname
          ? `${review.userInfo.name} ${review.userInfo.surname}`
          : review.userInfo.name;
      } else {
        userName = review.userInfo;
      }

      const date = new Date(review.date).toISOString().split('T')[0];

      if (!userName) {
        return;
      }

      const reviewLocalSchema: Review = {
        '@type': 'Review',
        datePublished: date,
        description: review.text || review.review,
        author: {
          '@type': 'Person',
          name: userName,
        },
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.score,
          bestRating: 5,
          worstRating: 0,
        },
      };

      reviewSchema.push(reviewLocalSchema);
    });

    if (reviewSchema.length === 0) {
      return null;
    }

    return reviewSchema;
  }

  public static generateCarouselSchema(rawData: CarouselRawData[]) {
    const carouselList: ListItem[] = [];

    rawData.forEach((item, index) => {
      const priceRangeInfo = PRICE_RANGE_MAP.find((rangeInfo) => {
        return item.price >= rangeInfo.min && item.price < rangeInfo.max;
      });

      const address = item.address;
      const addressSchema = this.generateAddressSchema(address);

      const carouselItemData: LocalBusiness = {
        '@type': 'LocalBusiness',
        name: item.name,
        url: item.url,
        address: addressSchema,
      };

      if (priceRangeInfo) {
        carouselItemData.priceRange = priceRangeInfo.value;
      }

      const itemRating = this.generateAggregateRatingSchema(item.reviews);
      const imageList: string[] = [];

      Object.keys(item.rawImages).forEach((photoKey) => {
        const photoArray = item.rawImages[photoKey];

        if (!Array.isArray(photoArray)) {
          return;
        }

        photoArray.forEach((photoObject) => {
          const link =
            photoObject.croppedPhotoUrl || photoObject.originalPhotoUrl;
          imageList.push(link);
        });
      });

      if (itemRating) {
        carouselItemData.aggregateRating = itemRating;
      }

      carouselItemData.image = imageList;

      const carouselItem: ListItem = {
        '@type': 'ListItem',
        position: index + 1,
        item: carouselItemData,
      };

      carouselList.push(carouselItem);
    });

    const carouselSchema = {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      itemListElement: carouselList,
    };

    return carouselSchema;
  }

  public static generateAddressSchema(address: ListingAddress) {
    const { country, street, area2 } = address;

    const addressSchema: PostalAddress = {
      '@type': 'PostalAddress' as const,
      streetAddress: street,
      // "addressLocality": area2,
      addressRegion: country,
    };

    if (area2) {
      addressSchema.addressLocality = area2;
    }

    return addressSchema;
  }

  public static generateImageSchema(photos: ListingPhotos) {
    const parsedPhotos: string[] = [];

    if (!photos) {
      return null;
    }

    Object.keys(photos).forEach((photoKey) => {
      const categoryImageArr = photos[photoKey];

      categoryImageArr.forEach((photoObject) => {
        const link =
          photoObject.croppedPhotoUrl || photoObject.originalPhotoUrl;
        const description = photoObject.caption;

        const innerImageSchema: any = {
          '@type': 'ImageObject',
          url: link,
        };

        if (description) {
          innerImageSchema.description = description;
        }

        parsedPhotos.push(innerImageSchema);
      });
    });

    if (parsedPhotos.length === 0) {
      return null;
    }

    return parsedPhotos;
  }

  static generateFaqSchema(faqData: any[], originLink: string) {
    const questionMapped = faqData.map((data) => {
      const { question, answerBlocks } = data;

      const parsedAnswer = answerBlocks
        .map((block: any) => {
          if (block.type === 'text') {
            return `<p>${block.output}</p>`;
          }

          if (block.type === 'list') {
            return block.output
              .map((listItem: any) => {
                return `<a href="${originLink}/${listItem.link}">${listItem.text}</a>`;
              })
              .join('');
          }
        })
        .join('');

      return {
        '@type': 'Question',
        name: question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: parsedAnswer,
        },
      };
    });

    const faqSchema = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: questionMapped,
    };

    return [faqSchema];
  }

  static generateBreadcrumbsSchema(breadCrumbs: any[], document: Document) {
    const originUrl = new URL(document.URL).origin;
    const itemListElement = breadCrumbs.map((breadcrumb, index) => {
      const mappedUrl =
        breadcrumb.slug?.reduce((url: string, slug: string) => {
          return slug !== '/' ? url + '/' + slug : url + '/';
        }, originUrl) || document.URL.toString();

      const breadcrumbSchema = {
        '@type': 'ListItem',
        position: index + 1,
        name: breadcrumb?.label,
        item: mappedUrl,
      };

      return breadcrumbSchema;
    });

    const breadcrumbsSchema = {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement,
    };

    return [breadcrumbsSchema];
  }

  static generateArticleSchema(blogPageData: any) {
    const articleSchema = {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      headline: blogPageData.name,
      image: [blogPageData.author.photo],
      datePublished: blogPageData.author.date,
      dateModified: blogPageData.author.date,
      author: [
        {
          '@type': 'Person',
          name: blogPageData.author.name,
        },
      ],
    };

    return [articleSchema];
  }
}
