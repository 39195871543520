import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { JarvisUiModalService } from './services/modal.service';
import { DefaultModalComponent } from './components/default-modal/default-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from '../button/button.module';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
    imports: [
        MatDialogModule,
        TranslateModule,
        MatIconModule,
        ButtonModule,
        CommonModule,
        PortalModule
    ],
    providers: [
        JarvisUiModalService
    ],
    declarations: [
      DefaultModalComponent
    ],
})
export class JarvisUiModalModule { }
