
export const SAAS_LANDING_TEMPLATE_CONFIGURATION = {
  'client-management-software': {
    title: 'landing.saas.templates.client-management-software.title',
    description: 'landing.saas.templates.client-management-software.description',
    topImage: 'landing/crm-reservations.png',
    descriptionSmall: "landing.saas.templates.client-management-software.descriptionSmall",
    createAccount: "landing.saas.templates.client-management-software.createAccount",
    ourApproach: {
      title: 'landing.saas.templates.client-management-software.ourApproach.title',
      description: 'landing.saas.templates.client-management-software.ourApproach.description',
    },
    bookConsultation: {
      title: 'landing.saas.templates.client-management-software.bookCallTitle',
      description: 'landing.saas.templates.client-management-software.bookCallDescription'
    },
    endorsment: {
      title: 'landing.saas.templates.client-management-software.endorsment.title',
      image: 'auders-photography.jpg',
      label: 'landing.saas.templates.client-management-software.endorsment.label',
      description: 'landing.saas.templates.client-management-software.endorsment.description'
    },
    sections: [
      {
        id: 'item1',
        header: 'landing.saas.templates.client-management-software.sections.section-1.title',
        text: 'landing.saas.templates.client-management-software.sections.section-1.description',
        subtexts: [
          'landing.saas.templates.client-management-software.sections.section-1.item-1',
          'landing.saas.templates.client-management-software.sections.section-1.item-2'
        ],
        videoPoster: 'assets/images/saas-landing-top-img.png'
      },
      {
        id: 'item-2',
        header: 'landing.saas.templates.client-management-software.sections.section-2.title',
        text: 'landing.saas.templates.client-management-software.sections.section-2.description',
        subtexts: [
          'landing.saas.templates.client-management-software.sections.section-2.item-1',
          'landing.saas.templates.client-management-software.sections.section-2.item-2'
        ],
        videoLink: 'assets/videos/vendors_1.mp4',
        videoPoster: 'assets/images/landing/get-bookings-easier-lukas.jpg'
      },
      {
        id: 'item3',
        header: 'landing.saas.templates.client-management-software.sections.section-3.title',
        text: 'landing.saas.templates.client-management-software.sections.section-3.description',
        subtexts: [
          'landing.saas.templates.client-management-software.sections.section-3.item-1',
          'landing.saas.templates.client-management-software.sections.section-3.item-2'
        ],
        videoLink: 'assets/videos/vendors_3.mp4',
        videoPoster: 'assets/images/landing/easily-onboard-clients.png'
      },
      {
        id: 'item4',
        header: 'landing.saas.templates.client-management-software.sections.section-4.title',
        text: 'landing.saas.templates.client-management-software.sections.section-4.description',
        subtexts: [
          'landing.saas.templates.client-management-software.sections.section-4.item-1',
          'landing.saas.templates.client-management-software.sections.section-4.item-2'
        ],
        videoLink: 'assets/videos/vendors_4.mp4',
        videoPoster: 'assets/images/landing/collect-payments.png'
      },
    ],
    compare: {
      package1: {
        title: 'landing.saas.templates.client-management-software.packages.package-1.title',
        items: [
          'landing.saas.templates.client-management-software.packages.package-1.item-1',
          'landing.saas.templates.client-management-software.packages.package-1.item-2',
          'landing.saas.templates.client-management-software.packages.package-1.item-3',
          'landing.saas.templates.client-management-software.packages.package-1.item-4',
          'landing.saas.templates.client-management-software.packages.package-1.item-5',
          'landing.saas.templates.client-management-software.packages.package-1.item-6',
        ],
      },
      package2: {
        title: 'landing.saas.templates.client-management-software.packages.package-2.title',
        items: [
          'landing.saas.templates.client-management-software.packages.package-2.item-1',
          'landing.saas.templates.client-management-software.packages.package-2.item-2',
          'landing.saas.templates.client-management-software.packages.package-2.item-3',
          'landing.saas.templates.client-management-software.packages.package-2.item-4',
          'landing.saas.templates.client-management-software.packages.package-2.item-5',
        ],
      },
    },
  },
  'jody-limited-offer': {
    title: 'landing.saas.templates.jody-limited-offer.title',
    description: 'landing.saas.templates.jody-limited-offer.description',
    topImage: 'landing/jody_main_image.png',
    topImageVideo: 'https://www.youtube.com/embed/vhWRyD04-n0',
    descriptionSmall: "landing.saas.templates.jody-limited-offer.descriptionSmall",
    createAccount: "landing.saas.templates.jody-limited-offer.createAccount",
    ourApproach: {
      title: 'landing.saas.templates.jody-limited-offer.ourApproach.title',
      description: 'landing.saas.templates.jody-limited-offer.ourApproach.description',
    },
    bookConsultation: {
      title: 'landing.saas.templates.jody-limited-offer.bookCallTitle',
      description: 'landing.saas.templates.jody-limited-offer.bookCallDescription'
    },
    endorsment: {
      title: 'landing.saas.templates.jody-limited-offer.endorsment.title',
      image: 'jody_profile_image.png',
      label: 'landing.saas.templates.jody-limited-offer.endorsment.label',
      description: 'landing.saas.templates.jody-limited-offer.endorsment.description'
    },
    sections: [
      {
        id: 'item1',
        header: 'landing.saas.templates.jody-limited-offer.sections.section-1.title',
        text: 'landing.saas.templates.jody-limited-offer.sections.section-1.description',
        subtexts: [
          'landing.saas.templates.jody-limited-offer.sections.section-1.item-1',
          'landing.saas.templates.jody-limited-offer.sections.section-1.item-2'
        ],
        videoPoster: 'assets/images/saas-landing-top-img.png'
      },
      {
        id: 'item-2',
        header: 'landing.saas.templates.jody-limited-offer.sections.section-2.title',
        text: 'landing.saas.templates.jody-limited-offer.sections.section-2.description',
        subtexts: [
          'landing.saas.templates.jody-limited-offer.sections.section-2.item-1',
          'landing.saas.templates.jody-limited-offer.sections.section-2.item-2'
        ],
        videoLink: 'assets/videos/vendors_1.mp4',
        videoPoster: 'assets/images/landing/get-bookings-easier-lukas.jpg'
      },
      {
        id: 'item3',
        header: 'landing.saas.templates.jody-limited-offer.sections.section-3.title',
        text: 'landing.saas.templates.jody-limited-offer.sections.section-3.description',
        subtexts: [
          'landing.saas.templates.jody-limited-offer.sections.section-3.item-1',
          'landing.saas.templates.jody-limited-offer.sections.section-3.item-2'
        ],
        videoLink: 'assets/videos/vendors_3.mp4',
        videoPoster: 'assets/images/landing/easily-onboard-clients.png'
      },
      {
        id: 'item4',
        header: 'landing.saas.templates.jody-limited-offer.sections.section-4.title',
        text: 'landing.saas.templates.jody-limited-offer.sections.section-4.description',
        subtexts: [
          'landing.saas.templates.jody-limited-offer.sections.section-4.item-1',
          'landing.saas.templates.jody-limited-offer.sections.section-4.item-2'
        ],
        videoLink: 'assets/videos/vendors_4.mp4',
        videoPoster: 'assets/images/landing/collect-payments.png'
      },
    ],
    compare: {
      package1: {
        title: 'landing.saas.templates.jody-limited-offer.packages.package-1.title',
        items: [
          'landing.saas.templates.jody-limited-offer.packages.package-1.item-1',
          'landing.saas.templates.jody-limited-offer.packages.package-1.item-2',
          'landing.saas.templates.jody-limited-offer.packages.package-1.item-3',
          'landing.saas.templates.jody-limited-offer.packages.package-1.item-4',
          'landing.saas.templates.jody-limited-offer.packages.package-1.item-5',
          'landing.saas.templates.jody-limited-offer.packages.package-1.item-6',
          'landing.saas.templates.jody-limited-offer.packages.package-1.item-7'
        ],
      },
      package2: {
        title: 'landing.saas.templates.jody-limited-offer.packages.package-2.title',
        items: [
          'landing.saas.templates.jody-limited-offer.packages.package-2.item-1',
          'landing.saas.templates.jody-limited-offer.packages.package-2.item-2',
          'landing.saas.templates.jody-limited-offer.packages.package-2.item-3',
          'landing.saas.templates.jody-limited-offer.packages.package-2.item-4',
          'landing.saas.templates.jody-limited-offer.packages.package-2.item-5',
        ],
      },
    },
  },
  'marketplace-event-venues': {
    title: 'landing.saas.templates.marketplace-event-venues.title',
    description: 'landing.saas.templates.marketplace-event-venues.description',
    topImage: 'landing/venues_LP_main.png',
    descriptionSmall: "landing.saas.templates.marketplace-event-venues.descriptionSmall",
    createAccount: "landing.saas.templates.marketplace-event-venues.createAccount",
    ourApproach: {
      title: 'landing.saas.templates.marketplace-event-venues.ourApproach.title',
      description: 'landing.saas.templates.marketplace-event-venues.ourApproach.description',
    },
    bookConsultation: {
      title: 'landing.saas.templates.marketplace-event-venues.bookCallTitle',
      description: 'landing.saas.templates.marketplace-event-venues.bookCallDescription'
    },
    endorsment: {
      title: 'landing.saas.templates.marketplace-event-venues.endorsment.title',
      image: 'venues_LP_aditional.png',
      label: 'landing.saas.templates.marketplace-event-venues.endorsment.label',
      description: 'landing.saas.templates.marketplace-event-venues.endorsment.description'
    },
    sections: [
      {
        id: 'item1',
        header: 'landing.saas.templates.marketplace-event-venues.sections.section-1.title',
        text: 'landing.saas.templates.marketplace-event-venues.sections.section-1.description',
        subtexts: [
          'landing.saas.templates.marketplace-event-venues.sections.section-1.item-1',
          'landing.saas.templates.marketplace-event-venues.sections.section-1.item-2'
        ],
        videoPoster: 'assets/images/saas-landing-top-img.png'
      },
      {
        id: 'item-2',
        header: 'landing.saas.templates.marketplace-event-venues.sections.section-2.title',
        text: 'landing.saas.templates.marketplace-event-venues.sections.section-2.description',
        subtexts: [
          'landing.saas.templates.marketplace-event-venues.sections.section-2.item-1',
          'landing.saas.templates.marketplace-event-venues.sections.section-2.item-2'
        ],
        videoLink: 'assets/videos/vendors_1.mp4',
        videoPoster: 'assets/images/landing/get-bookings-easier-lukas.jpg'
      },
      {
        id: 'item3',
        header: 'landing.saas.templates.marketplace-event-venues.sections.section-3.title',
        text: 'landing.saas.templates.marketplace-event-venues.sections.section-3.description',
        subtexts: [
          'landing.saas.templates.marketplace-event-venues.sections.section-3.item-1',
          'landing.saas.templates.marketplace-event-venues.sections.section-3.item-2'
        ],
        videoLink: 'assets/videos/vendors_3.mp4',
        videoPoster: 'assets/images/landing/easily-onboard-clients.png'
      },
      {
        id: 'item4',
        header: 'landing.saas.templates.marketplace-event-venues.sections.section-4.title',
        text: 'landing.saas.templates.marketplace-event-venues.sections.section-4.description',
        subtexts: [
          'landing.saas.templates.marketplace-event-venues.sections.section-4.item-1',
          'landing.saas.templates.marketplace-event-venues.sections.section-4.item-2'
        ],
        videoLink: 'assets/videos/vendors_4.mp4',
        videoPoster: 'assets/images/landing/collect-payments.png'
      },
    ],
    compare: {
      package1: {
        title: 'landing.saas.templates.marketplace-event-venues.packages.package-1.title',
        items: [
          'landing.saas.templates.marketplace-event-venues.packages.package-1.item-1',
          'landing.saas.templates.marketplace-event-venues.packages.package-1.item-2',
          'landing.saas.templates.marketplace-event-venues.packages.package-1.item-3',
          'landing.saas.templates.marketplace-event-venues.packages.package-1.item-4',
          'landing.saas.templates.marketplace-event-venues.packages.package-1.item-5',
          'landing.saas.templates.marketplace-event-venues.packages.package-1.item-6',
        ],
      },
      package2: {
        title: 'landing.saas.templates.marketplace-event-venues.packages.package-2.title',
        items: [
          'landing.saas.templates.marketplace-event-venues.packages.package-2.item-1',
          'landing.saas.templates.marketplace-event-venues.packages.package-2.item-2',
          'landing.saas.templates.marketplace-event-venues.packages.package-2.item-3',
          'landing.saas.templates.marketplace-event-venues.packages.package-2.item-4',
          'landing.saas.templates.marketplace-event-venues.packages.package-2.item-5',
        ],
      },
    },
  },
  'breezit-photographers': {
    title: 'landing.saas.templates.breezit-photographers.title',
    description: 'landing.saas.templates.breezit-photographers.description',
    topImage: 'saas-landing-top2.png',
    descriptionSmall: "landing.saas.templates.client-management-software.descriptionSmall",
    createAccount: "landing.saas.templates.client-management-software.createAccount",
    bookConsultation: {
      title: 'landing.saas.templates.client-management-software.bookCallTitle',
      description: 'landing.saas.templates.client-management-software.bookCallDescription'
    },
    endorsment: {
      title: 'landing.saas.templates.client-management-software.title',
      image: 'auders-photography.jpg',
      label: 'landing.saas.templates.client-management-software.label',
      description: 'landing.saas.templates.client-management-software.description'
    },
    sections: [
      {
        id: 'item1',
        header: 'landing.saas.templates.client-management-software.sections.section-1.title',
        text: 'landing.saas.templates.client-management-software.sections.section-1.description',
        subtexts: [
          'landing.saas.templates.client-management-software.sections.section-1.item-1',
          'landing.saas.templates.client-management-software.sections.section-1.item-2'
        ],
        videoPoster: 'assets/images/saas-landing-top-img.png'
      },
      {
        id: 'item-2',
        header: 'landing.saas.templates.client-management-software.sections.section-2.title',
        text: 'landing.saas.templates.client-management-software.sections.section-2.description',
        subtexts: [
          'landing.saas.templates.client-management-software.sections.section-2.item-1',
          'landing.saas.templates.client-management-software.sections.section-2.item-2'
        ],
        videoLink: 'assets/videos/vendors_1.mp4',
        videoPoster: 'assets/images/landing/get-bookings-easier-lukas.jpg'
      },
      {
        id: 'item3',
        header: 'landing.saas.templates.client-management-software.sections.section-3.title',
        text: 'landing.saas.templates.client-management-software.sections.section-3.description',
        subtexts: [
          'landing.saas.templates.client-management-software.sections.section-3.item-1',
          'landing.saas.templates.client-management-software.sections.section-3.item-2'
        ],
        videoLink: 'assets/videos/vendors_3.mp4',
        videoPoster: 'assets/images/landing/crm-reservations.png'
      },
      {
        id: 'item4',
        header: 'landing.saas.templates.client-management-software.sections.section-4.title',
        text: 'landing.saas.templates.client-management-software.sections.section-4.description',
        subtexts: [
          'landing.saas.templates.client-management-software.sections.section-4.item-1',
          'landing.saas.templates.client-management-software.sections.section-4.item-2'
        ],
        videoLink: 'assets/videos/vendors_4.mp4',
        videoPoster: 'assets/images/landing/collect-payments.png'
      },
    ],
    compare: {
      package1: {
        title: 'landing.saas.templates.client-management-software.packages.package-1.title',
        items: [
          'landing.saas.templates.client-management-software.packages.package-1.item-1',
          'landing.saas.templates.client-management-software.packages.package-1.item-2',
          'landing.saas.templates.client-management-software.packages.package-1.item-3',
          'landing.saas.templates.client-management-software.packages.package-1.item-4',
          'landing.saas.templates.client-management-software.packages.package-1.item-5',
          'landing.saas.templates.client-management-software.packages.package-1.item-6',
        ],
      },
      package2: {
        title: 'landing.saas.templates.client-management-software.packages.package-1.title',
        items: [
          'landing.saas.templates.client-management-software.packages.package-2.item-1',
          'landing.saas.templates.client-management-software.packages.package-2.item-2',
          'landing.saas.templates.client-management-software.packages.package-2.item-3',
          'landing.saas.templates.client-management-software.packages.package-2.item-4',
          'landing.saas.templates.client-management-software.packages.package-2.item-5',
        ],
      },
    },
  },
  'photoboss': {
    title: 'landing.saas.templates.photoboss.title',
    description: 'landing.saas.templates.photoboss.description',
    topImage: 'landing/photoboss_landing.png',
    topImageVideo: null,
    descriptionSmall: "landing.saas.templates.photoboss.descriptionSmall",
    createAccount: "landing.saas.templates.photoboss.createAccount",
    ourApproach: {
      title: 'landing.saas.templates.photoboss.ourApproach.title',
      description: 'landing.saas.templates.photoboss.ourApproach.description',
    },
    // bookConsultation: {
    //   title: 'landing.saas.templates.photoboss.bookCallTitle',
    //   description: 'landing.saas.templates.photoboss.bookCallDescription'
    // },
    // endorsment: {
    //   title: 'landing.saas.templates.photoboss.endorsment.title',
    //   image: 'jody_profile_image.png',
    //   label: 'landing.saas.templates.photoboss.endorsment.label',
    //   description: 'landing.saas.templates.photoboss.endorsment.description'
    // },
    sections: [
      {
        id: 'item1',
        header: 'landing.saas.templates.photoboss.sections.section-1.title',
        text: 'landing.saas.templates.photoboss.sections.section-1.description',
        subtexts: [
          'landing.saas.templates.photoboss.sections.section-1.item-1',
          'landing.saas.templates.photoboss.sections.section-1.item-2'
        ],
        videoPoster: 'assets/images/saas-landing-top-img.png'
      },
      {
        id: 'item-2',
        header: 'landing.saas.templates.photoboss.sections.section-2.title',
        text: 'landing.saas.templates.photoboss.sections.section-2.description',
        subtexts: [
          'landing.saas.templates.photoboss.sections.section-2.item-1',
          'landing.saas.templates.photoboss.sections.section-2.item-2'
        ],
        videoLink: 'assets/videos/vendors_1.mp4',
        videoPoster: 'assets/images/landing/get-bookings-easier-lukas.jpg'
      },
      {
        id: 'item3',
        header: 'landing.saas.templates.photoboss.sections.section-3.title',
        text: 'landing.saas.templates.photoboss.sections.section-3.description',
        subtexts: [
          'landing.saas.templates.photoboss.sections.section-3.item-1',
          'landing.saas.templates.photoboss.sections.section-3.item-2'
        ],
        videoLink: 'assets/videos/vendors_3.mp4',
        videoPoster: 'assets/images/landing/crm-reservations.png'
      },
      {
        id: 'item4',
        header: 'landing.saas.templates.photoboss.sections.section-4.title',
        text: 'landing.saas.templates.photoboss.sections.section-4.description',
        subtexts: [
          'landing.saas.templates.photoboss.sections.section-4.item-1',
          'landing.saas.templates.photoboss.sections.section-4.item-2'
        ],
        videoLink: 'assets/videos/vendors_4.mp4',
        videoPoster: 'assets/images/landing/collect-payments.png'
      },
    ],
    // compare: {
    //   package1: {
    //     title: 'landing.saas.templates.photoboss.packages.package-1.title',
    //     items: [
    //       'landing.saas.templates.photoboss.packages.package-1.item-1',
    //       'landing.saas.templates.photoboss.packages.package-1.item-2',
    //       'landing.saas.templates.photoboss.packages.package-1.item-3',
    //       'landing.saas.templates.photoboss.packages.package-1.item-4',
    //       'landing.saas.templates.photoboss.packages.package-1.item-5',
    //       'landing.saas.templates.photoboss.packages.package-1.item-6',
    //     ],
    //   },
    //   package2: {
    //     title: 'landing.saas.templates.photoboss.packages.package-2.title',
    //     items: [
    //       'landing.saas.templates.photoboss.packages.package-2.item-1',
    //       'landing.saas.templates.photoboss.packages.package-2.item-2',
    //       'landing.saas.templates.photoboss.packages.package-2.item-3',
    //       'landing.saas.templates.photoboss.packages.package-2.item-4',
    //       'landing.saas.templates.photoboss.packages.package-2.item-5'
    //     ],
    //   },
    // },
  },
  'profile-link': {
    title: 'landing.saas.templates.profile-link.title',
    description: 'landing.saas.templates.profile-link.description',
    additionalTitle: 'Breezit Custom Pricing Navigation Tool',
    topImage: 'landing/what-services-are-you-offer.png',
    // topImageVideo: 'https://www.youtube.com/embed/vhWRyD04-n0',
    descriptionSmall: "landing.saas.templates.profile-link.descriptionSmall",
    createAccount: "landing.saas.templates.profile-link.createAccount",
    ourApproach: {
      title: 'landing.saas.templates.profile-link.ourApproach.title',
      description: 'landing.saas.templates.profile-link.ourApproach.description',
    },
    afterOurApproach: "Integrate with WeddingWire, the Knot, Instagram, Facebook, Pinterest, email responses, and your website.",
    bookConsultation: {
      title: 'landing.saas.templates.profile-link.bookCallTitle',
      description: 'landing.saas.templates.profile-link.bookCallDescription'
    },
    endorsment: {
      title: 'landing.saas.templates.profile-link.endorsment.title',
      image: 'jody_profile_image.png',
      label: 'landing.saas.templates.profile-link.endorsment.label',
      description: 'landing.saas.templates.profile-link.endorsment.description'
    },
    sections: [
      {
        id: 'item1',
        header: 'landing.saas.templates.profile-link.sections.section-1.title',
        text: 'landing.saas.templates.profile-link.sections.section-1.description',
        subtexts: [
          'landing.saas.templates.profile-link.sections.section-1.item-1',
          'landing.saas.templates.profile-link.sections.section-1.item-2'
        ],
        videoPoster: 'assets/images/landing/calendar-book.png'
      },
      {
        id: 'item-2',
        header: 'landing.saas.templates.profile-link.sections.section-2.title',
        text: 'landing.saas.templates.profile-link.sections.section-2.description',
        subtexts: [
          'landing.saas.templates.profile-link.sections.section-2.item-1',
          'landing.saas.templates.profile-link.sections.section-2.item-2'
        ],
        videoLink: 'assets/videos/vendors_1.mp4',
        videoPoster: 'assets/images/landing/dynamic-offerings.jpg'
      },
      {
        id: 'item3',
        header: 'landing.saas.templates.profile-link.sections.section-3.title',
        text: 'landing.saas.templates.profile-link.sections.section-3.description',
        subtexts: [
          'landing.saas.templates.profile-link.sections.section-3.item-1',
          'landing.saas.templates.profile-link.sections.section-3.item-2'
        ],
        videoPoster: 'assets/images/landing/autoreply.png'
      },
      {
        id: 'item4',
        header: 'landing.saas.templates.profile-link.sections.section-4.title',
        text: 'landing.saas.templates.profile-link.sections.section-4.description',
        subtexts: [
          'landing.saas.templates.profile-link.sections.section-4.item-1',
          'landing.saas.templates.profile-link.sections.section-4.item-2'
        ],
        videoLink: 'assets/videos/vendors_4.mp4',
        videoPoster: 'assets/images/landing/easily-onboard-clients.png'
      },
      {
        id: 'item5',
        header: 'landing.saas.templates.profile-link.sections.section-5.title',
        text: 'landing.saas.templates.profile-link.sections.section-5.description',
        subtexts: [
          'landing.saas.templates.profile-link.sections.section-5.item-1',
          'landing.saas.templates.profile-link.sections.section-5.item-2'
        ],
        videoLink: 'assets/videos/vendors_4.mp4',
        videoPoster: 'assets/images/landing/collect-payments.png'
      },

    ],
    compare: {
      package1: {
        title: 'landing.saas.templates.profile-link.packages.package-1.title',
        items: [
          'landing.saas.templates.profile-link.packages.package-1.item-1',
          'landing.saas.templates.profile-link.packages.package-1.item-2',
          'landing.saas.templates.profile-link.packages.package-1.item-3',
          'landing.saas.templates.profile-link.packages.package-1.item-4',
          'landing.saas.templates.profile-link.packages.package-1.item-5',
          'landing.saas.templates.profile-link.packages.package-1.item-6',
        ],
      },
      package2: {
        hideItemIcon: true,
        title: 'landing.saas.templates.profile-link.packages.package-2.title',
        items: [
          'landing.saas.templates.profile-link.packages.package-2.item-1',
          'landing.saas.templates.profile-link.packages.package-2.item-2',
          'landing.saas.templates.profile-link.packages.package-2.item-3',
          'landing.saas.templates.profile-link.packages.package-2.item-4',
          'landing.saas.templates.profile-link.packages.package-2.item-5',
          'landing.saas.templates.profile-link.packages.package-2.item-6',
        ],
      },
    },
  },
};
