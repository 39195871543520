import { Pipe, PipeTransform } from '@angular/core';
import { ServiceTypes } from '@jarvis/services';

const SERVICES_TO_EXPAND: string[] = [
  // ServiceTypes.venueStandalone,
  ServiceTypes.venueAccomodation,
  ServiceTypes.serviceCharge,
  ServiceTypes.globalMinimalBudget,
  ServiceTypes.venueOvertime,
];

@Pipe({
  name: 'AddCustomDescription',
  pure: true,
  standalone: true,
})
export class AddCustomDescriptionPipe implements PipeTransform {
  transform(value: any, item: any, customDescription: any): any {
    if (
      customDescription &&
      item.description?.includes('booking.defaultDescriptions')
    )
      return customDescription + '\n' + (value ?? '');
    else return `${value}`;
  }
}
