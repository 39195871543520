<jarvis-ui-modal-wrapper (closed)="close()" [closeButton]="closeButton" class="modal-wrapper medium-modal">
    <h1>{{"passwordRemind.changeHeader" | translate }}</h1>

    <div class='form-wrapper'>
        <form [formGroup]="passwordChangeForm">
            <span class='small-text'>{{"passwordRemind.passwordRule" | translate }}</span>
    
            <mat-form-field appearance="outline">
                <mat-label>{{"passwordRemind.password" | translate }}</mat-label>
                <input #passInput autocomplete="new-password" matInput type="password" formControlName="password" />
                <mat-icon class="selectable" matSuffix
                    (click)="passInput.type = passInput.type === 'password' ? 'text' : 'password'">
                    {{passInput.type === 'password' ? 'visibility' : 'visibility_off'}}
                </mat-icon>
                <mat-error>{{ ('errors.' + (passwordError$ | async)) | translate}}</mat-error>
            </mat-form-field>
    
            <mat-form-field appearance="outline">
                <mat-label>{{"passwordRemind.repeatPassword" | translate }}</mat-label>
                <input #passRepeatInput autocomplete="new-password" matInput type="password" formControlName="passwordRepeat" [errorStateMatcher]="passwordRepeatErrorMatcher"/>
                <mat-icon class="selectable" matSuffix
                    (click)="passRepeatInput.type = passRepeatInput.type === 'password' ? 'text' : 'password'">
                    {{passRepeatInput.type === 'password' ? 'visibility' : 'visibility_off'}}
                </mat-icon>
                <mat-error *ngIf="(passwordRepeatError$ | async) as passwordRepeatError">
                    {{ ('errors.' + passwordRepeatError) | translate }}
                </mat-error>
                <mat-error 
                    *ngIf="
                        passwordChangeForm.hasError('passwordMatch') &&
                        !(passwordRepeatError$ | async)
                    "
                >
                    {{ 'errors.passwordMatch' | translate}}
                </mat-error>
                <mat-error 
                    *ngIf="
                        passwordChangeForm.hasError('linkAlreadyUsed') &&
                        !(passwordRepeatError$ | async)
                    "
                >
                    {{ 'errors.linkAlreadyUsed' | translate}}
                </mat-error>
                <mat-error 
                    *ngIf="
                        passwordChangeForm.hasError('somethingWrong') &&
                        !(passwordRepeatError$ | async)
                    "
                > 
                    {{ 'errors.somethingWrong' | translate}}
                </mat-error>
            </mat-form-field>
    
            <!-- <div class='form-errors' *ngIf="passwordChangeForm.invalid && passwordChangeForm.touched && (formErrors$ | async) as formErrorMessage">
                <mat-error>{{ 'errors.' + formErrorMessage | translate}}</mat-error>
            </div> -->
    
            <button app-button color="accent" (click)="changePassword()">{{"passwordRemind.updatePassword" | translate }}</button>
        </form>
    </div>
</jarvis-ui-modal-wrapper>