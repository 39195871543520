import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {UntypedFormBuilder,UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { LandingContactsService, LandingPageType, LANDING_TYPE } from '../services';

@Component({
  selector: 'marketplace-landing-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnDestroy {

  isLoading = false;
  uploaded = false;

  clientOptions = [
    {
      label: 'Wedding',
      value: 'wedding'
    },
    {
      label: 'Private event',
      value: 'private-event'
    },
    {
      label: 'Corporate event',
      value: 'business-event'
    }
  ];

  vendorOptions = [
    { label: 'Event venue', value: 'venues' },
    { label: 'Catering', value: 'catering' },
    { label: 'Ceremony place', value: 'ceremony_venue' },
    { label: 'Snacks after ceremony', value: 'ceremony_regale' },
    { label: 'Bartender', value: 'bartenders' },
    { label: 'Food truck', value: 'foodtruck' },
    { label: 'Videographer', value: 'videographer' },
    { label: 'Photographer', value: 'photographer' },
    { label: 'Live music', value: 'live_music' },
    { label: 'Event planning', value: 'planning' },
    { label: 'Event host', value: 'host' },
    { label: 'Cakes & desserts', value: 'cakes_desserts' },
    { label: 'Beauty', value: 'makeup_hairdressing' },
    { label: 'DJ', value: 'dj' },
    { label: 'Bride attire', value: 'bride_clothing' },
    { label: 'Groom attire', value: 'groom_clothing' },
    { label: 'Second hand attire', value: 'secondary_dress' },
    { label: 'Transport', value: 'transport' },
    { label: 'Decor rentals', value: 'decor' },
    { label: 'Invitations', value: 'invitations_printing' },
    { label: 'Event decor & flowers', value: 'decor_floristics' },
    { label: 'Tents & furniture', value: 'pavilion_furniture' },
    { label: 'Sound & lights', value: 'sound_lights' },
    { label: 'Screens', value: 'screens_projectors' },
    { label: 'Shows', value: 'show' },
    { label: 'Classes', value: 'training' },
    { label: 'Entertainment', value: 'entertainment' },
    { label: 'Jewelry', value: 'jewelery' },
    { label: 'Gifts', value: 'presents' },
    { label: 'Honey moon', value: 'honeymoon' },
    { label: 'Other', value: 'other' }
  ];

  events: { label: string, value: string}[];
  isClientSide = false;
  form:UntypedFormGroup;

  destory$ = new Subject<void>();

  constructor(
    private formBuilder:UntypedFormBuilder,
    private dialogRef: MatDialogRef<ContactFormComponent>,
    private contactsService: LandingContactsService,
    @Inject(LANDING_TYPE) private landingPageType: LandingPageType
  ) {
    this.isClientSide = this.landingPageType === 'user';
    this.isClientSide ? (this.events = this.clientOptions) : (this.events = this.vendorOptions);
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      eventType: [null, [Validators.required]],
      otherType: [{ value: null, disabled: true}, [Validators.required]],
      phoneNo: null
    });
  }

  ngOnInit(): void {
    const eventTypeFormField = this.form.get('eventType');
    const otherEventTypeFormField = this.form.get('otherType');

    eventTypeFormField.valueChanges.pipe(
      startWith(eventTypeFormField.value),
      takeUntil(this.destory$)
    ).subscribe((value) => {
      if (value === 'other') {
        otherEventTypeFormField.enable();
      } else {
        otherEventTypeFormField.disable();
      }
    });
  }

  ngOnDestroy(): void {
    this.destory$.next();
    this.destory$.complete();
  }

  submit(event: any): void {
    event.preventDefault();

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    const vendorOrClient = this.isClientSide ? 'client' : 'vendor';
    this.contactsService.postComments(this.form.value, vendorOrClient).subscribe(
      () => {
        this.uploaded = true;
      },
      () => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    )
  }

  close(): void {
    this.dialogRef.close();
  }

}
