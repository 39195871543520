import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  inject,
} from '@angular/core';

import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ServicePipesModule } from '@jarvis/services';
import { JarvisDFormModule } from '@jarvis/ui';
import { DFormComponent } from '@jarvis/ui/src/lib/components/d-form/d-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { BookingDetailsService } from 'libs/saved-bookings/src/lib/booking-details.service';

@Component({
  selector: 'jarvis-booking-edit-balance-edit',
  templateUrl: './balance-edit.component.html',
  styleUrls: ['./balance-edit.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    JarvisDFormModule,
    ServicePipesModule,
  ],
  standalone: true,
})
export class BookingEditBalanceEditComponent implements OnInit {
  @Output() submitData: any = new EventEmitter<any>();
  @Input() booking: any;
  @Input() deposit: any;
  @Input() currencyCode: any;
  @Input() locale: any;

  @ViewChild('dform') dform;

  bookingDetailsService = inject(BookingDetailsService);

  balanceEditData: BalanceEditType;

  balanceEditForm: any = DFormComponent.newFormDescriptor<BalanceEditType>({
    validators: [this.checkAmount()],
    controls: {
      discount: { type: 'number', required: true },
      deposit: { type: 'number', required: true },
      nodeposit: { required: false, hidden: true, disabled: true },
      price: { required: false, hidden: true, disabled: true },
      paidCash: { type: 'number', required: true },
    },
  });

  get hasTaxes() {
    return this.bookingDetailsService.facts.hasTaxes;
  }

  constructor() {}

  saveChanges() {
    this.submitData.emit(this.dform.dformGroup.value);
  }

  ngOnInit(): void {
    this.balanceEditData = this.initBalanceData();

    this.balanceEditForm.controls.deposit.translateArgs = {
      currencyCode: this.currencyCode,
    };
    this.balanceEditForm.controls.paidCash.translateArgs = {
      currencyCode: this.currencyCode,
    };
    this.balanceEditForm.controls.discount.translateArgs = {
      currencyCode: this.currencyCode,
    };

    this.balanceEditForm.controls.deposit.hidden =
      this.balanceEditData.deposit == null;
    this.balanceEditForm.controls.nodeposit.hidden =
      !this.balanceEditForm.controls.deposit.hidden;

    this.balanceEditForm.controls.paidCash.validators = [
      Validators.min(this.balanceEditData.minPaid),
    ];
    this.balanceEditForm.controls.deposit.validators = [Validators.min(0)];
    this.balanceEditForm.controls.discount.validators = [
      Validators.max(this.balanceEditData.price),
    ];
  }

  initBalanceData() {
    return {
      price: this.booking.price - this.booking.tax,
      discount: Math.round(this.booking.discount * 100) * 0.01,
      paidCash: this.booking.paidCash ?? 0, //(this.booking.paidCash ?? 0) + (this.booking.paid ?? 0),
      paid: this.booking.paid ?? 0,
      minPaid: this.booking.paid ?? 0,
      deposit: this.deposit,
    } as BalanceEditType;
  }

  private checkAmount(): ValidatorFn {
    return function (c: AbstractControl) {
      if (
        c.get('deposit').value != null &&
        c.get('discount').value != null &&
        c.get('paidCash').value != null
      ) {
        const price = c.get('price').value - c.get('discount').value;
        if (c.get('paidCash').value > price) {
          c.get('paidCash').setErrors({ max: price });
          return { max: c.value };
        }
        if (c.get('deposit').value > price) {
          c.get('deposit').setErrors({ max: price });
          return { max: c.value };
        }
      }
      return null;
    };
  }
}

type BalanceEditType = {
  deposit: number;
  discount: number;
  nodeposit: number;
  paidCash: number;
  price: number;
  paid: number;
  minPaid: number;
};
