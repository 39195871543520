<div class="mobile-menu">
  <div class="content-wrapper">
    <div class="top-container bztt-flex bztt-flex-row bztt-justify-end bztt-items-end">
        <!-- <mat-icon>search</mat-icon> -->
        <mat-icon (click)="closeMenu()">close</mat-icon>
    </div>

    <main-header-menu></main-header-menu>
  </div>
</div>
