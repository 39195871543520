<jarvis-ui-modal-wrapper class="modal-wrapper large-modal" (closed)="close()">
  <div class="d-flex flex-column h-100 justify-content-center overflow-hidden">
    <div class="w-100 balance-container d-flex flex-column align-items-center justify-content-between">
      <div class="d-flex w-100 flex-column align-items-center pt-4 pb-2">
        <div class="pb-1 fs-4 fw-bolder">{{i18nKey + '.title' | translate}}</div>
        <ng-container [ngSwitch]="domainCountry === 'lt'">
          <ng-container *ngSwitchCase="true">
            <div>
              {{'bookings.referal.priceDescription' | translate}}
              <span class="fw-bolder">
                {{data.item.budget | currency:data.currencyCode:'symbol':null:dt.data.locale}}
              </span>
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <div>
              {{'bookings.referal.description1' | translate}}
              <span class="fw-bolder">{{('categories.' + data.item.serviceType) | translate}}</span>
              {{'bookings.referal.description2' | translate}}
              <span class="fw-bolder">
                {{data.item.budget | currency:data.currencyCode:'symbol':null:dt.data.locale}}
              </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="h-auto mt-3 overflow-auto main-view">

      <div class="container">
        <div [formGroup]="referal" *ngFor="let referal of referals.controls; let i = index" class="row">
          <mat-form-field appearance="outline" class="col col-md-4 form-field">
            <mat-label>{{'bookings.referal.partner' | translate}}</mat-label>
            <input type="text" matInput formControlName="name" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="referalSelected($event,i)">
              <mat-option *ngFor="let option of data.lst" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col form-field">
            <mat-label>{{'bookings.referal.email' | translate}}</mat-label>
            <input type="text" matInput formControlName="email">
          </mat-form-field>
          <mat-form-field appearance="outline" class="col form-field">
            <mat-label>{{'bookings.referal.phone' | translate}}</mat-label>
            <input type="text" matInput formControlName="phone">
          </mat-form-field>
          <div (click)="removeItem(i)" class="col-auto d-flex align-items-center pb-3"><mat-icon
              class="material-icons">delete</mat-icon></div>
        </div>
      </div>
      <div class="d-flex justify-content-between ps-4 pe-4 controls">
        <a [class.disabled]="!canAddNew()" class="d-flex fw-bolder align-items-center" (click)="addReferal()"><mat-icon
            class="material-icons">add</mat-icon>{{'bookings.referal.addMore' | translate}}</a>
        <span class="d-flex fw-bolder align-items-center"></span>
      </div>
    </div>


    <div class="d-flex mt-3 mb-3 justify-content-around">
      <button [disabled]="!canInvite()" (click)="invite()" class="send-btn" type="button" app-button color="accent">
        <span>{{'bookings.referal.send' | translate}}</span>
      </button>
    </div>

  </div>
</jarvis-ui-modal-wrapper>