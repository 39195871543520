import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { JarvisUiServiceListItemComponent } from './list/list.component';
import { ServiceDescriptionUnitPipe } from './pipes/service-description-unit.pipe';
import { OverTimeDisplayPipe } from './pipes/overtime-display.pipe';
import { PackageDescriptionComponent } from './list/components/package-description/package-description.component';
import { AddCustomDescriptionPipe } from './pipes/custom-description.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    ButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    PackageDescriptionComponent,
    ServiceDescriptionUnitPipe,
    AddCustomDescriptionPipe,
    OverTimeDisplayPipe,
  ],
  exports: [JarvisUiServiceListItemComponent],
  declarations: [JarvisUiServiceListItemComponent],
  providers: [],
})
export class JarvisUiServiceListModule {}
