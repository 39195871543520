import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Inject,
  Optional,
  Input,
  Output,
  EventEmitter,
  inject,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { BookingsUtil, ServiceBase } from '@jarvis/types';
import { CommonDialogService, FileUploaderActionType } from '@jarvis/ui';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { BASE_URL, LinkUrls, LINK_URLS } from '@jarvis/services';
import { BehaviorSubject, Observable, from, switchMap } from 'rxjs';
import { LayoutService } from '@jarvis/ui/layout';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throws } from 'assert';

@Component({
  selector: 'app-agreement-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './agreement-view.component.html',
  styleUrls: ['./agreement-view.component.scss'],
  providers: [TranslatePipe],
})
export class AgreementViewComponent implements OnInit, AfterViewInit {
  public doc: any;
  public isCustom = false;
  private changes = false;
  public canSign: boolean = false;
  public uploadType = Object.keys(UploadType).filter((v: any) => isNaN(v));

  public selectedUploadType = UploadType.notSigned;

  @ViewChild('uploader') uploader: ElementRef | undefined;
  @ViewChild('downloader') downloader: ElementRef | undefined;

  @Input() presentationType: 'vendor' | 'user' = 'vendor';

  @Input() data: ParamDataType = {} as ParamDataType;
  @Output() event = new EventEmitter<any>();

  private baseUrl: string = inject(BASE_URL);
  private httpService: HttpClient = inject(HttpClient);
  private layoutService = inject(LayoutService);
  protected isLoading = new BehaviorSubject(false);
  private clipboard = inject(Clipboard);
  private linkUrls: LinkUrls = inject(LINK_URLS);
  private snackBar = inject(MatSnackBar);
  isMobile$: Observable<boolean> = this.layoutService.isMobileObserver;

  constructor(
    @Optional() private dialogR: MatDialogRef<any>,
    @Optional() private commonDialogService: CommonDialogService,
    @Inject(TranslatePipe) private translatePipe: TranslatePipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private dataParam: ParamDataType
  ) {
    if (dataParam) this.data = dataParam;
  }

  public upload(tp: keyof typeof UploadType) {
    this.selectedUploadType = UploadType[tp];
    if (this.uploader) this.uploader.nativeElement.click();
  }

  public download() {
    if (this.downloader) this.downloader.nativeElement.click();
  }

  public initiateSigning() {
    this.close({ state: 'signing' });
  }

  public copy() {
    this.clipboard.copy(
      `${this.linkUrls.marketplace}/sign-contract/${this.data.detailData._id}`
    );
    this.snackBar.open('Link copied!', '', {
      duration: 1000,
    });
  }

  close(result?: any): void {
    if (this.dialogR)
      this.dialogR.close({
        ...result,
        changes: this.changes,
        presentationType: this.presentationType,
      });
    else
      this.event.emit({
        ...result,
        changes: this.changes,
        presentationType: this.presentationType,
      });
  }

  public removeFile() {
    this.commonDialogService.openConfirm('bookings.detail.confirmRemoveFile');
    this.commonDialogService.confirmed().subscribe((confirmed) => {
      if (confirmed) {
        this.doc = null;
        this.changes = true;
        if (this.data.detailData.options.customAgreement)
          this.data.detailData.additions.splice(
            this.data.detailData.additions.findIndex(
              (itm: any) =>
                itm.url == this.data.detailData.options.customAgreement
            ),
            1
          );
        else this.data.detailData.additions.splice(0, 1);
        // if (this.data.detailData.state=="signing") this.data.detailData.state="new"

        if (this.data.base.contractExample?.contractExampleFiles?.length > 0) {
          this.doc =
            this.data.base.contractExample?.contractExampleFiles[0].fileUrl;
          this.isCustom = false;
        }
      }
    });
  }

  onFileChange(event: any) {
    const file = event.target.files[0];

    from(file.arrayBuffer())
      .pipe(
        switchMap((fileArrayBuffer) => {
          this.isLoading.next(true);
          const formData = new FormData();
          formData.append(
            'file',
            new Blob([fileArrayBuffer as BlobPart]),
            file.name
          );
          formData.append('type', 'ServiceDocument');
          formData.append('booking', this.data.detailData._id);
          return this.httpService.post(
            `${this.baseUrl}/file/saveConcierge`,
            formData
          );
        })
      )
      .subscribe({
        next: (result: any) => {
          const fileUrl = result.fileUrl;
          this.data.detailData.additions.push({
            url: fileUrl,
            filename: result.fileName,
          });

          const data = this.data.detailData;
          const options = data.options;

          options.customAgreement = null;

          switch (this.selectedUploadType) {
            case UploadType.notSigned:
              options.contractSigned = null;
              options.contractVendorSigned = null;
              break;
            case UploadType.vendorSigned:
              options.contractSigned = null;
              options.contractVendorSigned = true;
              break;
            case UploadType.bothSigned:
              options.contractVendorSigned = true;
              options.contractSigned = true;
              if (data.state!="reserved") data.state = "signing";
              break;
          }

          options.customAgreement = fileUrl;
          this.doc = fileUrl;
          this.isCustom = true;
          this.changes = true;
        },
        error: (e: any) => {
          console.log(e);
        },
        complete: () => this.isLoading.next(false),
      });
  }

  fileUploaderAction(evnt: FileUploaderActionType[]) {
    evnt.forEach((oneevnt) => {
      if (oneevnt.type === 'add')
        if (this.data.detailData.options.customAgreement)
          this.data.detailData.additions.splice(
            this.data.detailData.additions.findIndex(
              (itm: any) =>
                itm.url == this.data.detailData.options.customAgreement
            ),
            1
          );

      this.data.detailData.additions.push({
        url: oneevnt.fileUrl,
        filename: oneevnt.fileName,
      });
      this.data.detailData.options.customAgreement = oneevnt.fileUrl;
      this.doc = oneevnt.fileUrl;
      this.isCustom = true;
      this.changes = true;
    });
  }

  ngOnInit(): void {
    this.canSign = BookingsUtil.getUIState(
      this.data.detailData,
      this.data.detailData.serviceBaseId,
      'facts'
    ).payment.notYetPaidState;

    if (this.data.startUpload) {
    } else if (this.data.detailData.options.customAgreement) {
      this.doc = this.data.detailData.options.customAgreement;
      this.isCustom = true;
    } else if (
      this.data.detailData?.serviceBaseId?.contractExample?.contractExampleFiles
        ?.length > 0
    )
      this.doc =
        this.data.base.contractExample?.contractExampleFiles[0].fileUrl;

    if (this.doc) this.isLoading.next(true);
    //else if (this.data.detailData?.additions?.length>0) { this.doc = this.data.detailData?.additions[0].url; this.isCustom = true; }
  }

  ngAfterViewInit(): void {
    //if (this.data.startUpload) this.upload();
  }
}

interface ParamDataType {
  detailData: any;
  base: any;
  startUpload: boolean;
  canChange: boolean;
  disableUpload: boolean;
}

enum UploadType {
  notSigned,
  vendorSigned,
  bothSigned,
}
