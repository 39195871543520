<swiper
  [navigation]="true"
  class="reviews-swiper"
  [slidesPerView]="1"
  [spaceBetween]="24"
  [pagination]="{
    clickable: true
  }"
  [breakpoints]="{
    '834': {
      slidesPerView: 2
    }
  }"
>
  <ng-template swiperSlide *ngFor="let item of configuration">
    <div class="review-container">
      <div
        *ngIf="item.image"
        class="profile-image"
        [style.backgroundImage]="
          'url(/assets/images/landing/' + item.image + ')'
        "
      ></div>

      <div class="text-container">
        <div class="title">{{ item.label | translate }}</div>
        <div class="description">“{{ item.description | translate }}”</div>
      </div>
    </div>
  </ng-template>
</swiper>
