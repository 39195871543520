import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'
import { CommonDialogService } from '@jarvis/ui';
import { AdvertsListingService } from '../listing.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { JarvisAuthService } from '@jarvis/auth';
import { of } from 'rxjs';

@Component({template:`<div></div>`}
          )

export class ClaimServiceComponent implements OnInit {
   
   constructor (private activatedRoute: ActivatedRoute,
                private router: Router,
                private listingService: AdvertsListingService,
                private commonDialogService:CommonDialogService,
                private httpService: HttpClient,
                private authService: JarvisAuthService
                ) {}

   ngOnInit () {
      const hash = this.activatedRoute.snapshot.params.hash;
      if (!(hash))          
         this.commonDialogService.openSuccessRx("errors.errorMsg", "error");
      else {    

          this.authService.userData$.pipe(take(1)).pipe(
          switchMap((v)=>(v && v.profiling.data && !v.profiling.data.completed)?this.httpService.post(`${environment.baseUrl}api/common/editProfiling`, { data: {completed:true} }):of(0)),
          switchMap(()=>this.authService.getCurrentUser()),
          switchMap((v)=>this.httpService.get(`${environment.baseUrl}api/claimService/${hash}`)),
          switchMap((v:any)=>this.httpService.get(`${environment.baseUrl}api/serviceBase/${v._id}`)),
          map((v:any)=>`/services/${v._id}/view/${v.serviceEvents[0]._id}`)
        )        
        .subscribe(v=>{
            this.router.navigateByUrl(v).then(()=>{               
               // this.commonDialogService.openSuccessRx("dialogs.claimServiceSuccess");
             });
            },
            error => {
                if (error?.error?.errors[0]?.message == 'Invalid claim service')
                this.commonDialogService.openSuccessRx("errors.claimServiceErrorMsg", "error");
                else this.commonDialogService.openSuccessRx("errors.errorMsg", "error", null,"Error: " + error?.error?.errors.map(x => x.message).join(", "));
            }         
        );

      }  
/*
       if (!(code && state))          
           this.commonDialogService.openSuccessRx("errors.errorMsg", "error");
       else {    
         const stateObj = JSON.parse(decodeURIComponent(state));
         this.httpService.post(`${environment.baseUrl}api/instagram/connect`,
         {serviceBase:stateObj.serviceBase, code:code, redirect_uri: `${environment.saasUrl}/ig/auth/`}).pipe(
            switchMap((v:any)=> this.listingService.editBaseService(stateObj.serviceBase, { extra: {igUserId: v.igUserId}}))
         ).subscribe(v=>this.router.navigateByUrl(stateObj.url, { state: { connectInstagram: true }} ),
        error => {
         this.commonDialogService.openSuccessRx("errors.errorMsg", "error", null,"Error: " + error?.error?.errors.map(x => x.message).join(", "));
        }
         );
      }*/
   }

}