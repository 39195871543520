import { Component, Inject, OnInit, Optional, inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';

const BUTTONS_US = [
  {
    label: 'rulesPolicies.rules',
    value: 'rules',
    link: 'rules-of-use'
  },
  {
    label: 'rulesPolicies.privacy',
    value: 'privacy',
    link: 'privacy-policy'
  },
  {
    label: 'rulesPolicies.cookie',
    value: 'cookies',
    link: 'cookie-policy'
  }
];

const BUTTONS_LT = [
  {
    label: 'rulesPolicies.rules',
    value: 'rules',
    link: 'paslaugu-teikimo'
  },
  {
    label: 'rulesPolicies.privacy',
    value: 'privacy',
    link: 'privatumo-politika'
  },
  {
    label: 'rulesPolicies.cookie',
    value: 'cookies',
    link: 'slapuku-politika'
  }
];

@Component({
  selector: 'jarvis-ui-rules-policies',
  templateUrl: './rules-policies.component.html',
  styleUrls: ['./rules-policies.component.scss']
})
export class JarvisUiRulesPoliciesComponent implements OnInit {

  isModal = false;

  buttons: any[];

  activeButton: any;

  private domainCountry = inject(DOMAIN_COUNTRY);

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private modalData: any,
    @Optional() private dialogRef: MatDialogRef<JarvisUiRulesPoliciesComponent>
  ) {
    this.buttons = this.domainCountry === 'lt' ? BUTTONS_LT : BUTTONS_US;
  }

  ngOnInit(): void {
    if (this.dialogRef) {
      this.isModal = true;
    }

    if (!this.activeButton) {
      this.activeButton = this.buttons[0];
    }

    if (this.modalData?.activePage) {
      this.activeButton = this.buttons.find(button => button.value === this.modalData.activePage) || this.buttons[0];
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  changeActiveTab(buttonItem): void {
    this.activeButton = buttonItem;
  }

}
