import { Directive, ElementRef, HostListener, Input, inject } from '@angular/core';

@Directive({ 
  selector: '[imageError]',
  standalone: true
})
export class ImageErrorDirective {
  @Input() imageError: string;

  private elRef = inject(ElementRef);

  @HostListener('error')
  onError() {
    const nativeEl = this.elRef?.nativeElement;
    const isImgElement = nativeEl instanceof HTMLImageElement;
    if (isImgElement && typeof this.imageError === 'string' && nativeEl.src !== this.imageError) {
      // console.log(`Defaulting to ${this.imageError}`, `current: ${nativeEl.src}`, this.elRef);
      nativeEl.src = this.imageError;
    }
  }
}
