<jarvis-ui-modal-wrapper class="modal-wrapper medium-modal" (closed)="onClose()">
    <div class='form-wrapper'>
        <form #daForm>
            <h1>{{'passwordRemind.header' | translate}}</h1>
        
            <mat-form-field appearance="outline">
                <mat-label>{{'passwordRemind.email' | translate}}</mat-label>
                <input matInput type="email" [formControl]="emailControl" />
            </mat-form-field>

            <div *ngIf="submitError" class='form-errors'>
                <mat-error>Something went wrong</mat-error>
            </div>
        
            <button (click)="sendReminder($event)" app-button color="accent">{{'passwordRemind.sendReminder' | translate}}</button>
            
        </form>
    </div>
</jarvis-ui-modal-wrapper>