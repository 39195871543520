import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth';
import { BookingsType } from '@jarvis/types';
import { CommonDialogService } from '@jarvis/ui';

import { JarvisValidators, ServiceNamePipe } from '@jarvis/utils';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
  ReviewsInviteInfo,
  ReviewsService
} from '../services/reviews.service';
import { BreezitReviewDetails } from '../reviews.types';

interface RegisterOpenTermsModalAction {
  termsConditions: 'rules' | 'privacy';
}

type RegisterModalActions = RegisterOpenTermsModalAction;

@Component({
  selector: 'jarvis-reviews-add-review',
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.scss'],
})
export class ReviewsAddReviewComponent implements OnInit, OnDestroy {
  reviewForm: UntypedFormGroup;
  userData: any;

  brandName: string;
  vendorProfilePhoto: string;

  isSuccess = false;
  nextStepComponent = new BehaviorSubject<ReviewStepEnum | null>(null);

  loggedIn$ = this.authService.isLoggedIn$;
  action$ = new Subject<RegisterModalActions>();

  destroy$ = new Subject<void>();

  private reviewInfo: ReviewsInviteInfo =
    this.currentRoute.snapshot.data.review;
  private bookingInfo: BookingsType =
    this.currentRoute.snapshot.data.bookingReview;
  public type: ReviewTypeEnum = this.currentRoute.snapshot.data.type;

  public reviewStepEnum = ReviewStepEnum; 
  public reviewTypeEnum = ReviewTypeEnum;

  errorMsg = 'reviews.errors.somethingWrong';

  get contactsForm(): UntypedFormGroup {
    return this.reviewForm.get('contacts') as UntypedFormGroup;
  }

  get individualScoresForm(): UntypedFormGroup {
    return this.reviewForm.get('individualScores') as UntypedFormGroup;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private currentRoute: ActivatedRoute,
    private reviewsService: ReviewsService,
    private authService: JarvisAuthService,
    private serviceNamePipe: ServiceNamePipe,
    private commonDialogService: CommonDialogService
  ) {
    this.reviewForm = this.formBuilder.group({
      review: [null, Validators.required],
      individualScores: this.formBuilder.group({
        quality: [0, Validators.min(1)],
        value: [0, Validators.min(1)],
        flexibility: [0, Validators.min(1)],
        responseTime: [0, Validators.min(1)],
        professionalism: [0, Validators.min(1)],
      }),
      contacts: this.formBuilder.group({
        name: [null, Validators.required],
        surname: [null, Validators.required],
        phoneNo: null,
        email: [
          null,
          [Validators.required, JarvisValidators.fullEmailValidator],
        ],
      }),
    });
  }

  ngOnDestroy(): void {
    this.action$.complete();
    this.destroy$.next();
  }

  ngOnInit(): void {
    if (this.type === ReviewTypeEnum.verify) {
      this.reviewsService
        .verifyReview(this.currentRoute.snapshot.params.hash)
        .subscribe(
          () => {
            this.nextStepComponent.next(ReviewStepEnum.success);
          },
          (err) => {
            this.errorMsg =
              err?.error?.errors.length > 0
                ? err.error.errors[0].message
                : 'reviews.errors.somethingWrong';
            if (this.errorMsg === 'Invite doesnt exist')
              this.errorMsg = 'reviews.errors.inviteDoesntExists';
            this.nextStepComponent.next(ReviewStepEnum.error);
          }
        );
      //verifyReview
    } else if (this.type === ReviewTypeEnum.afterEvent) {
      this.brandName = this.serviceNamePipe.transform(
        this.bookingInfo.serviceBaseId as any
      );
      this.vendorProfilePhoto = this.bookingInfo.vendor.profilePhoto;
    } else if (this.type !== ReviewTypeEnum.profileLink) {
      if (this.currentRoute.snapshot.params.hash)
        this.reviewsService
          .checkReview(this.currentRoute.snapshot.params.hash)
          .subscribe(
            (v) => v,
            (err) => {
              this.errorMsg =
                err?.error?.errors.length > 0
                  ? err.error.errors[0].message
                  : 'reviews.errors.somethingWrong';
              if (this.errorMsg === 'Invite doesnt exist')
                this.errorMsg = 'reviews.errors.inviteDoesntExists';
              this.nextStepComponent.next(ReviewStepEnum.error);
            }
          );
      this.brandName = this.serviceNamePipe.transform(
        this.reviewInfo.serviceBase
      );
      this.vendorProfilePhoto = this.reviewInfo.serviceBase.user.profilePhoto;
    }

    this.authService.isLoggedIn$.pipe(take(1)).subscribe((loggedIn) => {
      if (loggedIn) { // || this.type === ReviewTypeEnum.profileLink) {
        this.reviewForm.get('contacts').disable();
      }

      // if (this.type === 'afterEvent' && !loggedIn) {

      // }
    });
  }

  changeRating(rating: number, key: string): void {
    const formControl = this.reviewForm.get(['individualScores', key]);

    formControl.setValue(rating);
    formControl.markAsTouched();
  }

  handleNextButton() {
    //if (this.type === ReviewTypeEnum.profileLink) return this.switchToRegistration();

    this.switchToContacts();
  }

  switchToContacts(): void {
    if (
      this.reviewForm.get('review').invalid ||
      this.individualScoresForm.invalid
    ) {
      this.reviewForm.markAllAsTouched();
      return;
    }
    this.nextStepComponent.next(ReviewStepEnum.contacts);
  }

  switchToRegistration(): void {
    if (
      this.reviewForm.get('review').invalid ||
      this.individualScoresForm.invalid
    ) {
      this.reviewForm.markAllAsTouched();
      return;
    }
    this.nextStepComponent.next(ReviewStepEnum.registration);

    this.authService
      .createRegisterOrLoginSuccessStream()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          this.submit();
          this.nextStepComponent.next(ReviewStepEnum.success);
        }
      });
  }

  switchToFirstScreen(): void {
    this.nextStepComponent.next(null);
  }

  verify(value: any): void {
    if (value.type === 'email') {

      const userInfo = this.reviewForm.get('contacts').value;

      const data: BreezitReviewDetails = {
        email: userInfo.email,
        userInfo,
        individualScores: this.reviewForm.get('individualScores').value,
        review: this.reviewForm.get('review').value,
        score: this.getAverageScore(),
        serviceBase: this.reviewInfo?.serviceBase?._id??this.currentRoute.snapshot.params.id
      };

      this.reviewsService.createReviewForProfileLink(data).subscribe(v => {
        if (v==EMPTY) return;
        this.nextStepComponent.next(ReviewStepEnum.confirmationSent);
      });
    } else if (value.type === 'registered') this.submit();
    else if (value.type === 'error') this.nextStepComponent.next(ReviewStepEnum.error);
  }
  contactSubmit(): void {
    this.nextStepComponent.next(ReviewStepEnum.verify);
  }

  submit(): void {
    if (this.reviewForm.invalid) {
      // console.log('form invalid: ', this.reviewForm)

      this.reviewForm.markAllAsTouched();
      return;
    }

    this.authService.userData$.pipe(filter(v=>!!v),take(1)).subscribe((userData) => {
      // console.log(userData)

      const hash = this.currentRoute.snapshot.params.hash;

      let userInfo;

      if (userData) {
        userInfo = {
          name: userData.name,
          surname: userData.surname,
          email: userData.email,
        };
      } else {
        userInfo = this.reviewForm.get('contacts').value;
      }

      const data: BreezitReviewDetails = {
        userInfo,
        email: userData.email,
        individualScores: this.reviewForm.get('individualScores').value,
        review: this.reviewForm.get('review').value,
        score: this.getAverageScore(),
        serviceBase: this.currentRoute.snapshot.params.id,
      };

      if (this.type === ReviewTypeEnum.afterEvent) {
        this.reviewsService
          .createReview(this.bookingInfo._id, data)
          .subscribe(() => {
            this.nextStepComponent.next(ReviewStepEnum.success);
          });
      } else if (this.type === ReviewTypeEnum.profileLink) {
        // console.log('save review')
        this.reviewsService.createReviewForProfileLink(data).subscribe((v) => {
          if (v==EMPTY) return;
          this.nextStepComponent.next(ReviewStepEnum.success);
          setTimeout(() => close(), 4000);
        });
      } else {
        this.reviewsService.useReview(hash, data).subscribe(
          () => {
            this.nextStepComponent.next(ReviewStepEnum.success);
          },
          (err) => {
            this.errorMsg =
              err?.error?.errors.length > 0
                ? err.error.errors[0].message
                : 'reviews.errors.somethingWrong';
            if (this.errorMsg === 'Invite doesnt exist') {
              this.errorMsg = 'reviews.errors.inviteDoesntExists';
              this.nextStepComponent.next(ReviewStepEnum.error);
            } else if (
              this.errorMsg === 'You have already reviewed this service'
            ) {
              this.errorMsg = 'reviews.errors.alreadyReviewed';
              this.nextStepComponent.next(ReviewStepEnum.error);
            } else if (this.errorMsg === 'You cannot review your own service') {
              this.commonDialogService
                .openSuccessRx('reviews.reviewYourselfError', 'error')
                .subscribe();
            } else this.nextStepComponent.next(ReviewStepEnum.error);
          }
        );
      }
    });
  }

  private getAverageScore(): number {
    const individualScores = this.reviewForm.get('individualScores').value;
    const values = Object.values<number>(individualScores);
    const sum = values.reduce((average: number, value: number) => {
      return average + value;
    }, 0);

    return sum / values.length;
  }
}

enum ReviewTypeEnum { 
  afterEvent = 'afterEvent', 
  profileLink = 'profile-link', 
  verify  = 'verify'
}

enum ReviewStepEnum {
  contacts,
  registration,
  verify,
  error,
  confirmationSent,
  success
}
