import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { JarvisLocaleValuesService } from '@jarvis/services';

import { BookingsType, ServiceBase, ServiceEvents } from '@jarvis/types';

@Component({
  selector: 'bookings-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements OnInit {
  @Input() detailData!: BookingsType;
  @Input() showMore!: boolean;
  @Output() showMoreChange = new EventEmitter<boolean>();

  private localeVal = inject(JarvisLocaleValuesService);

  serviceBase!: ServiceBase;
  serviceEvent!: ServiceEvents;
  entity: any;

  phoneNo = null;
  phoneShown = false;
  locale!: string;
  tz!: string;

  ngOnInit(): void {
    this.serviceBase = this.detailData.serviceBaseId as ServiceBase;
    this.serviceEvent = this.detailData.serviceEventId as ServiceEvents;
    this.entity = this.serviceBase?.entityInfo?.entity;

    const localeValues = this.localeVal.getLocaleValues(this.serviceBase);
    this.locale = localeValues.localeId;
    this.tz = localeValues.tz;
  }

  openNewTab(url: string, metric?: string) {
    window.open(url, '_blank');
  }

  public showLess() {
    this.showMoreChange.emit(false);
  }
}
