// Sift4 - common version
// online algorithm to compute the distance between two strings in O(n)
// maxOffset is the number of characters to search for matching letters
// maxDistance is the distance at which the algorithm should stop computing the value and just exit (the strings are too different anyway)
export function sift4(s1: string, s2: string, maxOffset: number, maxDistance: number) {
  if (!s1 || !s1.length) {
    if (!s2) {
      return 0;
    }
    return s2.length;
  }

  if (!s2 || !s2.length) {
    return s1.length;
  }

  const l1 = s1.length;
  const l2 = s2.length;

  let c1 = 0; //cursor for string 1
  let c2 = 0; //cursor for string 2
  
  let lcss = 0; //largest common subsequence
  let local_cs = 0; //local common substring
  let trans = 0; //number of transpositions ('ab' vs 'ba')

  const offset_arr: { c1: number; c2: number; trans: boolean; }[] = []; //offset pair array, for computing the transpositions

  while ((c1 < l1) && (c2 < l2)) {
    if (s1.charAt(c1) == s2.charAt(c2)) {
      local_cs++;
      var isTrans = false;
      //see if current match is a transposition
      var i = 0;
      while (i < offset_arr.length) {
        var ofs = offset_arr[i];
        if (c1 <= ofs.c1 || c2 <= ofs.c2) {
          // when two matches cross, the one considered a transposition is the one with the largest difference in offsets
          isTrans = Math.abs(c2 - c1) >= Math.abs(ofs.c2 - ofs.c1);
          if (isTrans) {
            trans++;
          } else {
            if (!ofs.trans) {
              ofs.trans = true;
              trans++;
            }
          }
          break;
        } else {
          if (c1 > ofs.c2 && c2 > ofs.c1) {
            offset_arr.splice(i, 1);
          } else {
            i++;
          }
        }
      }
      offset_arr.push({
        c1: c1,
        c2: c2,
        trans: isTrans
      });
    } else {
      lcss += local_cs;
      local_cs = 0;
      if (c1 != c2) {
        c1 = c2 = Math.min(c1, c2); //using min allows the computation of transpositions
      }
      //if matching characters are found, remove 1 from both cursors (they get incremented at the end of the loop)
      //so that we can have only one code block handling matches 
      for (var i = 0; i < maxOffset && (c1 + i < l1 || c2 + i < l2); i++) {
        if ((c1 + i < l1) && (s1.charAt(c1 + i) == s2.charAt(c2))) {
          c1 += i - 1;
          c2--;
          break;
        }
        if ((c2 + i < l2) && (s1.charAt(c1) == s2.charAt(c2 + i))) {
          c1--;
          c2 += i - 1;
          break;
        }
      }
    }
    c1++;
    c2++;
    if (maxDistance) {
      var temporaryDistance = Math.max(c1, c2) - lcss + trans;
      if (temporaryDistance >= maxDistance) return Math.round(temporaryDistance);
    }
    // this covers the case where the last match is on the last token in list, so that it can compute transpositions correctly
    if ((c1 >= l1) || (c2 >= l2)) {
      lcss += local_cs;
      local_cs = 0;
      c1 = c2 = Math.min(c1, c2);
    }
  }
  lcss += local_cs;
  return Math.round(Math.max(l1, l2) - lcss + trans); //add the cost of transpositions to the final result
}
