<div class='mobile-menu'>
    <div class='content-wrapper'>
        <div class='top-container'>
            <div class='actions-left'>
                <div *ngIf="domainCountry!='us'" class='menu-item' (click)="changeLanguage()">
                    <mat-icon>language</mat-icon>
                    <span>{{'header.language' | translate}}</span>
                </div> 
            </div>
            <div class='actions-right'>
                <!-- <mat-icon>search</mat-icon> -->
                <mat-icon (click)="closeMenu()">close</mat-icon>
            </div>
        </div>
        <nav>
            <app-menu-item *ngFor="let link of links" [iconName]="link.icon" [link]="link.path"
                [notifications]="link.notifications" [dropdown]="link.dropdown" class='extended'>
                {{'header.' + link.label | translate}}
            </app-menu-item>
        </nav>
        <div class='profile-info' [class.extended]="profileExtended">

            <div class='slide-menu'>
                <a (click)="navigateToSettings()">{{'header.profileSettings' | translate}}</a>
                <a (click)="openDemoRequest()">{{'header.requestDemo' | translate}}</a>
                <a routerLink="/rules-policies">{{'header.rulesPolicies' | translate}}</a>
                <ng-container *ngIf="domainCountry === 'lt'">
                  <!-- <a target="_blank" href="https://foxywedding.lt/auth/login">{{'header.foxySelfService' | translate}}</a> -->
                  <a target="_blank" href="https://foxyevents.crunch.help/lt/breezit-paslaugu-teikejams">{{'header.helpCenter' | translate}}</a>
                </ng-container>
                <a (click)="moveToMarketplace()">{{'header.becomeBuyer' | translate}}</a>
                <a (click)="logout()">{{'header.logout' | translate}}</a>
            </div>
            
            <div class='info-wrapper' (click)="extendProfile()" *ngIf="userData$ | async as user">
                <div class='photo-disc'>
                    <img [src]="user?.profilePhoto ? user?.profilePhoto : 'assets/images/noUser.jpeg'">
                </div>
                <span>
                    <ng-container [ngSwitch]="!!user.name && !!user.email">
                        <span *ngSwitchCase="true">{{user.name}} {{user.surname[0]}}.</span>
                        <span *ngSwitchDefault>{{user.email}}</span>
                    </ng-container>
                </span>
                <mat-icon [class.flipped]="isFlipped">expand_less</mat-icon>
            </div>
            
        </div>
    </div>

</div>