<div class='dialog-header'>
    <div class='close' (click)="close()">
        <mat-icon>clear</mat-icon> <a>Close</a>
    </div>
</div>

<form *ngIf="!uploaded" [formGroup]="form" class='contacts-form'>
    <h2>Registration</h2>

    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error>Required field</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="email" />
        <mat-error *ngIf="form.get('email').hasError('required')">Required field</mat-error>
        <mat-error *ngIf="form.get('email').hasError('email')">Please enter a valid email address</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label *ngIf="isClientSide">Event type</mat-label>
        <mat-label *ngIf="!isClientSide">Service type</mat-label>
        <mat-select formControlName="eventType">
            <mat-option [value]="event.value" *ngFor="let event of events">{{event.label}}</mat-option>
        </mat-select>
        <mat-error>Required field</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="form.get('eventType').value === 'other'">
        <mat-label *ngIf="isClientSide">Your event type</mat-label>
        <mat-label *ngIf="!isClientSide">Your service type</mat-label>
        <input matInput formControlName="otherType"/>
        <mat-error>Required field</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Phone number</mat-label>
        <input matInput formControlName="phoneNo" />
    </mat-form-field>

    <button (click)="submit($event)" app-button color="accent">Submit</button>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</form>

<div *ngIf="uploaded" class='submitted-successfully'>
    <img src="assets/icons/ic_success_large.svg">
    <h2 class="mt-3 mb-3 mb-lg-4">Submitted successfully</h2>
    <span class='smaller-text'>Thank you. You have been added to our waiting list. We will contact you when the waiting list will be opened.</span>
</div>
