import { NgModule } from '@angular/core';
import { JarvisTimePickerComponent } from './time-picker.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        FormsModule,
        CommonModule
    ],
    exports: [
        JarvisTimePickerComponent
    ],
    declarations: [
        JarvisTimePickerComponent
    ],
    providers: [],
})
export class JarvisTimePickerModule { }
