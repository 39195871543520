<ng-container *ngIf="!noteActive; else noteContainer">
  <div class="bztt-flex bztt-justify-between bztt-mb-2">
    <p
      class="bztt-font-montserrat bztt-m-0 bztt-text-sm bztt-text-[#717171] bztt-whitespace-nowrap bztt-overflow-ellipsis bztt-overflow-hidden"
    >
      {{ data | CardListingType }}
    </p>
    <div class="bztt-min-w-fit" *ngIf="data.ratingCount != '-'">
        <div class="bztt-flex bztt-items-center">
          <span class="bztt-text-[#ef5518] bztt-mr-2">★</span>
          <span class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171]"
            >{{ data.rating != '-' ? data.rating : '' }} ({{
              data.ratingCount
            }})</span
          >
        </div>
      </div>
  </div>

  <div class="bztt-min-h-[18px] bztt-mb-1">
    <div *ngIf="data.address" class="bztt-flex bztt-text-[#717171]">
      <mat-icon class="bztt-text-xs bztt-h-3 bztt-w-3">location_on</mat-icon>
      <span
        class="bztt-font-montserrat bztt-text-xs bztt-h-4 bztt-text-ellipsis bztt-w-64 bztt-overflow-hidden bztt-whitespace-nowrap"
      >
        <!-- bztt-whitespace-nowrap bztt-overflow-hidden bztt-text-ellipsis bztt-h-4"> -->
        {{ data.address }}
      </span>
    </div>
    <div *ngIf="!data.address" class="bztt-h-4"></div>

    <div class="bztt-min-h-[4rem]">
      <h2
        class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222] card-title-overflow-elipses"
      >
        {{ data.title }}
      </h2>
    </div>
  </div>

  <div
    class="bztt-flex bztt-gap-1 bztt-items-center bztt-mt-auto bztt-min-h-[44px]"
  >
    <div class="bztt-flex-col">
      <div class="bztt-text-[#717171] bztt-pb-2">
        {{ 'listing.cart.priceTitle' | translate }}
        <span
          class="bztt-flex-initial bztt-justify-center bztt-border bztt-border-[#515151] bztt-pt-1 bztt-relative bztt-top-0.5 bztt-pl-0.5 bztt-pr-0.5 bztt-rounded-full"
          *ngIf="
            data?.venueInfo?.hasCatering ||
            data?.venueInfo?.hasDrinks ||
            data?.venueInfo?.hasAccomodation
          "
        >
          <mat-icon
            class="bztt-h-3.5 bztt-w-3.5 bztt-ml-px bztt-text-[12px]"
            *ngIf="data.venueInfo.hasCatering"
            >restaurant</mat-icon
          >
          <mat-icon
            class="bztt-h-3.5 bztt-w-3.5 bztt-text-[12px]"
            *ngIf="data.venueInfo.hasDrinks"
            >local_bar</mat-icon
          >
          <mat-icon
            class="bztt-h-3.5 bztt-w-3.5 bztt-text-[12px]"
            *ngIf="data.venueInfo.hasAccomodation"
            >king_bed</mat-icon
          >
        </span>
      </div>
      <ng-container [ngSwitch]="!!data.discountedPrice">
        <ng-container *ngSwitchCase="true">
          <span
            class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#ef5518]"
            >{{ data.discountedPrice }}</span
          >
          <span
            class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171] bztt-line-through"
            >{{ data.indicativePrice }}</span
          >
        </ng-container>

        <ng-container *ngSwitchDefault>
          <span
            class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222]"
            >{{ data.indicativePrice }}</span
          >
        </ng-container>
      </ng-container>

      <span class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171]">{{
        data.priceUnit | PriceUnitLabel | async
      }}</span>
    </div>

    <div
      class="bztt-ml-auto bztt-flex bztt-flex-col bztt-items-start"
      *ngIf="data.venueInfo"
    >
      <div class="bztt-text-[#717171] bztt-pb-2">
        {{ 'listing.cart.guestCount' | translate }}
      </div>
      <span
        [ngSwitch]="data.venueInfo.filteredGuestCount > 0"
        class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222]"
        ><ng-container *ngSwitchCase="true">{{
          data.venueInfo.filteredGuestCount
        }}</ng-container>
        <ng-container *ngSwitchDefault>{{
          'listing.cart.guestCountUpTo' | translate : data.venueInfo
        }}</ng-container>
      </span>
    </div>
    <div
      class="bztt-ml-auto bztt-flex bztt-flex-col bztt-items-start"
      *ngIf="data?.priceAddition?.hoursIncluded"
    >
      <div class="bztt-text-[#717171] bztt-pb-2">
        {{ 'listing.cart.hoursIncluded' | translate }}
      </div>
      <span
        class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222]"
      >
        {{
          'listing.cart.hoursIncludedVal' | translate : data.priceAddition
        }}</span
      >
      <mat-icon
        matTooltip="Included in price"
        class="bztt-mr-2"
        *ngIf="data.venueInfo.hasCatering"
        >restaurant</mat-icon
      >
      <mat-icon
        matTooltip="Included in price"
        class="bztt-mr-2"
        *ngIf="data.venueInfo.hasDrinks"
        >local_bar</mat-icon
      >
      <mat-icon
        matTooltip="Included in price"
        *ngIf="data.venueInfo.hasAccomodation"
        >king_bed</mat-icon
      >
    </div>
  </div>

  <div
    *ngIf="!shared || (shared && data.note)"
    class="bztt-flex bztt-flex-col bztt-bg-[#FFF7F3] bztt-mt-4 bztt-m-[-18px] bztt-p-[18px]"
    (click)="$event.preventDefault(); $event.stopPropagation()"
  >
    <div
      (click)="openNote(); $event.preventDefault(); $event.stopPropagation()"
    >
      <ng-container *ngIf="!data.note && !shared">
        <div
          class="bztt-mb-4 bztt-flex bztt-flex-row bztt-gap-1 bztt-justify-start bztt-items-center bztt-py-[2px]"
        >
          <mat-icon
            class="bztt-text-[#EF5518] bztt-text-base bztt-flex bztt-justify-center bztt-items-center"
            >note_alt</mat-icon
          >
          <span class="bztt-text-[#EF5518] bztt-font-medium">{{
            'plannerPage.cardInfo.addNote' | translate
          }}</span>
        </div>
        <mat-divider class="bztt-mb-5"></mat-divider>
      </ng-container>
      <ng-container *ngIf="data.note">
        <div
          class="bztt-w-full bztt-h-12 bztt-bg-[#F9F9F9] bztt-border-[1px] bztt-border-solid bztt-border-[#DCDCDC] bztt-rounded-md bztt-flex bztt-flex-col bztt-p-[6px] bztt-justify-between bztt-mb-4"
        >
          <div class="bztt-text-xs bztt-text-[#6B6060] bztt-font-normal">
            {{ 'plannerPage.cardInfo.noteAdded' | translate }}
          </div>
          <div
            class="bztt-text-sm bztt-font-normal bztt-text-[#1F1F1F] bztt-overflow-hidden bztt-text-ellipsis bztt-whitespace-nowrap"
          >
            {{ data.note }}
          </div>
        </div>
      </ng-container>
    </div>
    <div class="bztt-flex bztt-flex-row bztt-gap-2" *ngIf="!shared">
      <div
        *ngIf="data.customLike"
        (click)="editCustomLike()"
        class="bztt-flex bztt-flex-col bztt-border-[1px] bztt-border-solid bztt-border-[#EF5518] bztt-rounded-md bztt-p-2 bztt-min-w-[75px] bztt-min-h-[64px] bztt-justify-center"
      >
        <mat-icon class="bztt-text-[#EF5518] bztt-text-sm"
          >manage_accounts</mat-icon
        >
        <span
          class="bztt-text-[#EF5518] bztt-font-medium bztt-text-[11px] bztt-w-max"
          >{{ 'plannerPage.cardInfo.edit' | translate }}</span
        >
      </div>
      <div
        *ngIf="false && !data.booking && !data.customLike"
        (click)="getAQuote()"
        class="bztt-flex bztt-flex-col bztt-border-[1px] bztt-border-solid bztt-border-[#EF5518] bztt-rounded-md bztt-p-2 bztt-min-h-[64px] bztt-justify-center"
      >
        <mat-icon class="bztt-text-[#EF5518] bztt-text-sm">send</mat-icon>
        <span
          class="bztt-text-[#EF5518] bztt-font-medium bztt-text-[11px] bztt-w-max"
          >{{ 'plannerPage.cardInfo.getAQuote' | translate }}</span
        >
      </div>
      <div
        [routerLink]="bookingLink"
        (click)="$event.stopPropagation(); $event.preventDefault()"
        *ngIf="data.booking"
        class="bztt-flex bztt-flex-col bztt-border-[1px] bztt-border-solid bztt-border-[#EF5518] bztt-rounded-md bztt-p-2 bztt-min-h-[64px] bztt-justify-center"
      >
        <mat-icon class="bztt-text-[#EF5518] bztt-text-sm">event_note</mat-icon>
        <span
          class="bztt-text-[#EF5518] bztt-font-medium bztt-text-[11px] bztt-w-max"
          >{{ 'plannerPage.cardInfo.viewBooking' | translate }}</span
        >
      </div>

      <div
        *ngIf="!data.plannerItem"
        (click)="addToTeamAndBudget()"
        class="add_to_team bztt-flex bztt-flex-col bztt-border-[1px] bztt-border-solid bztt-border-[#EF5518] bztt-rounded-md bztt-p-2 bztt-bg-[#EF5518] bztt-w-full bztt-min-h-[64px] bztt-justify-center"
      >
        <mat-icon class="bztt-text-[#FFF7F3] bztt-text-sm">add</mat-icon>
        <span class="bztt-text-[#FFF7F3] bztt-font-medium bztt-text-[11px]">{{
          'plannerPage.cardInfo.addToTeam' | translate
        }}</span>
      </div>
      <div
        *ngIf="data.plannerItem"
        (click)="
          removeFromTeamAndBudget();
          $event.stopPropagation();
          $event.preventDefault()
        "
        class="add_to_team bztt-flex bztt-flex-col bztt-border-[1px] bztt-border-solid bztt-border-[#EF5518] bztt-rounded-md bztt-p-2 bztt-w-full bztt-min-h-[64px] bztt-justify-center"
      >
        <mat-icon class="bztt-text-[#EF5518] bztt-text-sm">remove</mat-icon>
        <span class="bztt-text-[#EF5518] bztt-font-medium bztt-text-[11px]">{{
          'plannerPage.cardInfo.removeFromTeam' | translate
        }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noteContainer>
  <div
    class="bztt-w-full"
    (click)="$event.preventDefault(); $event.stopPropagation()"
  >
    <div class="bztt-text-base bztt-font-semibold bztt-mb-2">
      {{ 'plannerPage.cardInfo.note' | translate }}
    </div>
    <mat-form-field class="bztt-w-full">
      <textarea
        [readonly]="shared"
        matInput
        [formControl]="noteForm"
        [placeholder]="'plannerPage.cardInfo.notePlaceholder' | translate"
        cdkTextareaAutosize
        cdkAutosizeMinRows="12"
        cdkAutosizeMaxRows="14"
      ></textarea>
    </mat-form-field>

    <div
      class="bztt-flex bztt-flex-row bztt-gap-3 bztt-justify-start bztt-items-center"
      (click)="closeNote(); $event.preventDefault(); $event.stopPropagation()"
    >
      <mat-icon>chevron_left</mat-icon>
      <span>{{ 'plannerPage.cardInfo.goBack' | translate }}</span>
    </div>
  </div>
</ng-template>
