// import { init as logRocketInit } from 'logrocket';

export const LoggingLogRocketFactory = (enabled: boolean) => {
    return () => {
        return () => {
            if (enabled) {
              // logRocketInit('gmppr1/breezit');
            }
        };
    };
};