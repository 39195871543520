import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: 'img[appImgFallback]', standalone: true })
export class ImageFallbackDirective {
  @Input() appImgFallback: string;

  constructor(private elRef: ElementRef) {}

  @HostListener('error')
  loadFallbackOnError() {
    const img: any = this.elRef.nativeElement;
    img.src = this.appImgFallback || 'assets/images/noUser.jpeg';
    
    // console.log(img);
  }
}
