import { animate, state, style, transition, trigger } from '@angular/animations';

const MENU_DROPDOWN_ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';
export const MENU_DROPDOWN_ANIMATIONS = [
    trigger('fade', [
        state('fadeOut', style({ opacity: 0 })),
        state('fadeIn', style({ opacity: 1 })),
        transition('* => fadeIn', animate(MENU_DROPDOWN_ANIMATION_TIMINGS))
    ]),
    trigger('slideContent', [
        state(
            'void',
            style({ transform: 'translate3d(0, -20%, 0) scale(0.9)', opacity: 0 })
        ),
        state('enter', style({ transform: 'none', opacity: 1 })),
        state(
            'leave',
            style({ transform: 'translate3d(0, -20%, 0)', opacity: 0 })
        ),
        transition('* => *', animate(MENU_DROPDOWN_ANIMATION_TIMINGS))
    ])
];
