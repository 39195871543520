import { animate, state, style, transition, trigger } from '@angular/animations';

export const openCloseAnimation = trigger('openClose', [
  state(
    'open',
    style({
      opacity: 1
    }),
  ),
  state(
    'void',
    style({
      opacity: 0
    }),
  ),
  state(
    'closed',
    style({
      opacity: 0
    }),
  ),
  transition('open => closed', [animate('0.2s')]),
  transition('void => open', [animate('0.1s')])
]);
