import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export function mapDisabledState(form: UntypedFormGroup | UntypedFormArray): {
  [key: string]: boolean;
} {
  const result = { rootFormDisabledStatus: form.disabled };
  Object.keys(form.controls).forEach((key) => {
    const abstractControl = form.controls[key];
    if (
      abstractControl instanceof UntypedFormGroup ||
      abstractControl instanceof UntypedFormArray
    ) {
      result[key] = mapDisabledState(abstractControl);
    } else {
      result[key] = abstractControl.disabled;
    }
  });
  return result;
}

export function restoreFormDisabledState(
  form: UntypedFormGroup | UntypedFormArray,
  keymap: { [pathKey: string]: any }
): void {
  Object.keys(keymap).forEach((key) => {
    if (key === 'rootFormDisabledStatus') {
      if (keymap[key]) {
        form.disable({ onlySelf: true, emitEvent: false });
      }
    } else {
      const abstractControl: UntypedFormGroup = form.controls[key];
      const result = keymap[key];
      if (typeof result === 'object') {
        restoreFormDisabledState(abstractControl, keymap[key]);
      } else if (result) {
        abstractControl.disable({ onlySelf: true, emitEvent: false });
      }
    }
  });
}
