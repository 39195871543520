<div
  class="bztt-font-bold bztt-text-[#6B6060] bztt-text-start bztt-absolute bztt-top-0 bztt-z-10 bztt-h-10 bztt-flex bztt-items-center"
>
  {{
    'bookings.detail.currentStep' | translate : { step: currentStep, total: 3 }
  }}
</div>
<div [class.bztt-hidden]="currentStep !== 1">
  <jarvis-edit-event-details
    (hideMainDialog)="handleDialogHide($event)"
  ></jarvis-edit-event-details>
</div>

<div [class.bztt-hidden]="currentStep !== 2">
  <jarvis-booking-edit-services-list
    (hideMainDialog)="handleDialogHide($event)"
  ></jarvis-booking-edit-services-list>
</div>

<div *ngIf="currentStep === 3">
  <jarvis-booking-edit-balance-edit
    #balanceEdit
    [booking]="detailData"
    [currencyCode]="currencyCode"
    [locale]="locale"
    [deposit]="deposit"
    (submitData)="handleBalanceEditChange($event)"
  ></jarvis-booking-edit-balance-edit>
</div>

<div
  class="bztt-flex bztt-flex-col bztt-absolute bztt-bottom-0 bztt-bg-white bztt-h-[105px] md:bztt-h-32 bztt-justify-end bztt-border-t md:bztt-border-none bztt-left-0 bztt-w-full md:bztt-rounded-b-xl"
  [class.bztt-border-t]="currentStep === 2"
>
  <div
    class="bztt-flex bztt-justify-center bztt-items-center"
    *ngIf="currentStep !== 3"
  >
    <span class="bztt-font-semibold">
      {{ 'bookings.servicesModal.total' | translate }}
      {{
        detailData.price - detailData.tax
          | currency : currencyCode : 'symbol' : null : locale
      }}
      <!-- {{
        servicesEditData
          | selectedServicesTotalPrice
          | currency : currencyCode : 'symbol' : null : locale
      }} -->
    </span>
  </div>

  <div class="bztt-py-3 md:bztt-py-4 bztt-bottom-0">
    <div class="bztt-flex bztt-items-center bztt-justify-center bztt-gap-4">
      <div
        (click)="previousStep()"
        *ngIf="currentStep !== 1"
        class="bztt-h-12 bztt-w-12 bztt-border bztt-border-[#EF5518] bztt-rounded-full bztt-justify-center bztt-items-center bztt-flex bztt-cursor-pointer"
      >
        <mat-icon
          class="bztt-text-[#EF5518] bztt-h-7 bztt-w-7 bztt-text-[28px] bztt-leading-none"
          >arrow_back</mat-icon
        >
      </div>
      <button
        app-button
        color="accent"
        (click)="nextStep()"
        class="bztt-w-52"
        *ngIf="currentStep === 1"
      >
        {{ 'bookings.list.next' | translate }}
      </button>
      <button
        app-button
        color="accent"
        (click)="handleServiceDetailsListChange(); nextStep()"
        class="bztt-w-52"
        *ngIf="currentStep === 2"
      >
        {{ 'bookings.list.next' | translate }}
      </button>
      <button
        app-button
        color="accent"
        (click)="balanceEditComponent.saveChanges(); handleProposalOpen()"
        class="bztt-w-52"
        *ngIf="currentStep === 3"
      >
        {{ ('bookings.detail.' + (detailData.options.proposalSent?'save':'saveSend')) | translate }}
      </button>
    </div>
  </div>
</div>
