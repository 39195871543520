import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from '../tokens/base-url.token';

type FileTypes = 'ServiceDocument' | 'ServicePhoto';

@Injectable({ providedIn: 'root' })
export class JarvisFileUploadService {

    private saveEndpoint = `${this.baseUrl}/file/save`;
    private saveThumbnailEndpoint = `${this.baseUrl}/file/saveThumbnail`;
    private 

    constructor(
        @Inject(BASE_URL) private baseUrl: string,
        private httpService: HttpClient
    ) { }

    saveFile(file: string | Blob, fileName: string, type: FileTypes): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, fileName);
        formData.append('type', type);

        return this.httpService.post(this.saveEndpoint, formData);
    }

    saveConcierge(file: string | Blob, fileName: string, customSaveParams): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, fileName);
        formData.append('type', 'ServiceDocument');
        
        Object.keys(customSaveParams).forEach(key => {
            formData.append(key, customSaveParams[key]);
        });

        return this.httpService.post(`${this.baseUrl}/file/saveConcierge`, formData);
    }

    saveThumbnail(file: string | Blob, fileName: string, type: FileTypes): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, fileName);
        formData.append('type', type);

        return this.httpService.post(this.saveThumbnailEndpoint, formData);
    }
}
