<div
  class="message-wrapper bztt-px-0 md:bztt-px-6 hover:bztt-bg-opacity-5 hover:bztt-bg-gray-500"
  [class.own-message]="currentUserId === message.sender"
  [class.deleted]="message.type === 'deleted'"
  [class.left]="messageStyle === 'left'"
  (click)="messageTap($event)"
>
  <div class="message-photo-container">
    <div
      class="profile-photo"
      *ngIf="
        messageStyle !== 'left' &&
        currentUserId !== message.sender &&
        message.type !== 'serviceEventMessage'
      "
    >
      <img
        *ngIf="showVendorPhoto || showTimePassed"
        appImgFallback
        [src]="vendorImage || 'assets/images/noUser.jpeg'"
        alt="profile-photo"
      />
    </div>

    <div
      class="profile-photo bztt-self-start"
      *ngIf="messageStyle === 'left' && message.type !== 'serviceEventMessage'"
    >
      <ng-container [ngSwitch]="currentUserId === message.sender">
        <img
          *ngSwitchCase="true"
          appImgFallback
          [src]="userImage || 'assets/images/noUser.jpeg'"
          alt="profile-photo"
        />

        <img
          *ngSwitchDefault
          appImgFallback
          [src]="vendorImage || 'assets/images/noUser.jpeg'"
          alt="profile-photo"
        />
      </ng-container>
    </div>

    <div class="event-start" *ngIf="message.type === 'serviceEventMessage'">
      <span>
        {{ serviceName }}
      </span>
    </div>

    <div class="message-bubble" *ngIf="message.type === 'inline'">
      <ng-content select="[slot=message]"></ng-content>
    </div>

    <div *ngIf="message.type === 'loading'" class="message-bubble">
      <jarvis-loading-indicator-simple
        class="bztt-block"
        [color]="currentUserId === message.sender ? 'white' : '#A3A3A3'"
      ></jarvis-loading-indicator-simple>
    </div>

    <div
      *ngIf="message.type === 'text' || message.type === 'autoText'"
      class="bztt-flex bztt-relative bztt-flex-col file-container group-hover:md:bztt-bg-gray-200 bztt-bg-opacity-10 group-hover:md:bztt-shadow-lg"
    >
      <p [class.bztt-opacity-80]="message.error">
        <span [innerHTML]="message.message | urlify"></span>

        <ng-content select="[slot=inner_msg]"></ng-content>
      </p>

      <span
        *ngIf="message.error && !showTimePassed"
        class="bztt-text-red-500 bztt-text-xs bztt-mt-2"
      >
        {{ 'messages.sendError' | translate }}
        <a
          (click)="retrySendMessageHandler(message._id)"
          class="bztt-border-none bztt-underline bztt-underline-offset-4 bztt-p-0 bztt-font-normal"
          >{{ 'messages.retry' | translate }}?</a
        >
      </span>
    </div>

    <p *ngIf="message.type === 'deleted'">
      {{ 'messages.deleted' | translate }}
    </p>

    <div
      class="bztt-flex bztt-relative bztt-flex-col file-container group-hover:md:bztt-bg-gray-200 bztt-bg-opacity-10 group-hover:md:bztt-shadow-lg"
      *ngIf="message.type === 'files'"
    >
      <div
        *ngIf="message.uploading"
        class="bztt-h-full bztt-w-full bztt-absolute bztt-top-0 bztt-left-0 bztt-bg-gray-400 bztt-bg-opacity-30 bztt-flex bztt-items-center bztt-justify-center"
      >
        <mat-progress-spinner
          [strokeWidth]="2"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>

      <p
        [class.bztt-opacity-80]="message.error"
        class="bztt-mb-1"
        *ngIf="!!message.message"
        [innerHTML]="message.message | urlify"
      ></p>

      <ng-container [ngSwitch]="message.files | OnlyImages">
        <ng-container *ngSwitchCase="true">
          <div class="files" [class.bztt-opacity-80]="message.error">
            <div
              [class.half]="message.files.length > 1"
              [class.third]="message.files.length > 4"
              class="image-grid"
            >
              <ng-container
                *ngIf="{
                  inactive: message.uploading || message.error
                } as galleryOptions"
              >
                <a
                  (click)="openGalleryImageHandler(i, galleryOptions.inactive)"
                  *ngFor="let image of message.files; let i = index"
                >
                  <img [src]="image.url" alt="message-picture" />
                </a>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div class="bztt-flex bztt-flex-col bztt-gap-2 bztt-justify-end">
            <div *ngFor="let file of message.files" class="files-doc">
              <div
                class="bztt-border bztt-border-[#A1A1A1] bztt-rounded-lg bztt-mr-2 bztt-inline-flex bztt-items-center bztt-content-center"
              >
                <mat-icon
                  class="bztt-text-[32px] bztt-h-[32px] bztt-w-[32px] bztt-m-1"
                  >attach_file</mat-icon
                >
              </div>
              <a
                [href]="file.url"
                target="_blank"
                class="bztt-cursor-pointer bztt-border-none bztt-overflow-hidden"
              >
                <div
                  class="bztt-inline-flex bztt-flex-col bztt-justify-between bztt-overflow-hidden bztt-w-full"
                >
                  <span
                    class="bztt-text-sm bztt-underline bztt-font-semibold bztt-text-ellipsis bztt-whitespace-nowrap bztt-overflow-hidden"
                    >{{ file.fileName || file.url }}</span
                  >
                  <span
                    class="bztt-text-xs bztt-font-normal bztt-text-ellipsis bztt-whitespace-nowrap bztt-overflow-hidden"
                    >.{{ file.fileName?.split('.')[1] | uppercase }}</span
                  >
                </div>
              </a>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <span
        *ngIf="message.error && !showTimePassed"
        class="bztt-text-red-500 bztt-text-xs bztt-mt-2"
      >
        {{ 'messages.sendError' | translate }}
        <a
          (click)="retrySendMessageHandler(message._id)"
          class="bztt-border-none bztt-underline bztt-underline-offset-4 bztt-p-0 bztt-font-normal"
          >{{ 'messages.retry' | translate }}?</a
        >
      </span>
    </div>

    <div
      *ngIf="
        autoReplyActive &&
        message.sender === currentUserId &&
        lastMessage &&
        domainCountry === 'us' &&
        messagesType === 'vendor'
      "
      class="bztt-flex bztt-items-center bztt-justify-center bztt-mr-2"
    >
      <mat-icon>forum</mat-icon>
    </div>

    <div
      *ngIf="
        showActionsButton &&
        (message | ShowActionsInline : !!currentUserId : lastMessage)
      "
      class="additional-actions"
    >
      <mat-icon
        (click)="
          openMessageActionsOverlay(moreActionsOrigin, message, lastMessage)
        "
        cdkOverlayOrigin
        #moreActionsOrigin="cdkOverlayOrigin"
      >
        more_horiz
      </mat-icon>
    </div>

    <ng-content select="[slot=inline]"></ng-content>
  </div>

  <ng-content select="[slot=column]"></ng-content>

  <span class="time-passed bztt-flex bztt-flex-col" *ngIf="showTimePassed">
    <ng-container [ngSwitch]="message.sender !== currentUserId">
      <ng-container *ngSwitchCase="true">
        {{ message.createdAt | TimePassed }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{
          readByBoth
            ? ('messages.seen' | translate | titlecase)
            : (message.createdAt | TimePassed)
        }}
      </ng-container>
    </ng-container>

    <span
      *ngIf="message.error"
      class="bztt-text-red-500 bztt-text-xs bztt-mt-2"
    >
      {{ 'messages.sendError' | translate }}
      <a
        (click)="retrySendMessageHandler(message._id)"
        class="bztt-border-none bztt-underline bztt-underline-offset-4 bztt-p-0 bztt-font-normal"
        >{{ 'messages.retry' | translate }}?</a
      >
    </span>
  </span>
</div>

<messages-chat-messages-actions
  *ngIf="messageActionsOverlayData$ | async as actionOverlayData"
  [actionOverlayData]="actionOverlayData"
  [messagesType]="messagesType"
  [domainCountry]="domainCountry"
  (closed)="closeMessageActionsOverlay()"
  (edit)="editMessageHandler()"
  (delete)="deleteMessageHandler()"
  (markUnread)="markAsUnreadHandler()"
  (autoReply)="autoReplyHandler()"
></messages-chat-messages-actions>
