import { catchError } from 'rxjs/operators';

import { Component, inject, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { BASE_URL, JarvisTrackingService } from '@jarvis/services';
import { HttpClient } from '@angular/common/http';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  JarvisUiModalService,
  JarvisUiRulesPoliciesComponent,
} from '@jarvis/ui';
import { Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: 'like-save-email.component.html',
})
export class LikeSaveEmailModalComponent implements OnInit {
  private trackService = inject(JarvisTrackingService);

  loading = false;
  sentSuccessfully = false;
  emailControls: UntypedFormArray;
  private likesEndpoint = `${this.baseUrl}/marketplace/likes/notifySaveLikes`;
  private analyticsEndpoint = `${this.baseUrl}/common/saveAnalytics`;

  constructor(
    private modalRef: MatDialogRef<LikeSaveEmailModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpClient,
    private modalService: JarvisUiModalService,
    private router: Router,
    private translateService: TranslateService,
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit(): void {
    this.emailControls = this.createEmailControls();
  }

  close(): void {
    this.modalRef.close();
  }

  createEmailControls(data?: string[]): UntypedFormArray {
    const defaults = [null];

    const detailsForm = this.formBuilder.array(
      (data || defaults).map((value) =>
        this.formBuilder.control(value, [Validators.required, Validators.email])
      )
    );

    return detailsForm;
  }

  addEmail(): void {
    this.emailControls.push(
      this.formBuilder.control(null, [Validators.required, Validators.email])
    );
  }

  removeEmail(index: number): void {
    this.emailControls.removeAt(index);
  }

  openTermsAndConditionsModal(activePage: 'rules' | 'privacy' = 'rules'): void {
    this.modalService.openDialog(JarvisUiRulesPoliciesComponent, {
      data: {
        activePage,
      },
    });
  }

  signUp(): void {
    this.router.navigateByUrl('/auth/register');
    this.close();
  }

  submit(): void {
    if (this.loading) return;
    const serviceEventId = this.dialogData?.serviceEventId;

    if (this.emailControls.invalid || !serviceEventId) {
      return;
    }

    const emails = this.emailControls.value;

    const body = {
      serviceEventId,
      emails,
    };

    this.loading = true;

    this.submitRequest(body)
      .pipe(
        catchError(() => {
          this.loading = false;
          // this.snackBar.open(this.translateService.instant('errors.somethingWrong'), '', { duration: 1000 });
          return of(null);
        }),
        switchMap(() => this.saveDataRequest(body))
      )
      .subscribe(() => {
        this.loading = false;
        this.sentSuccessfully = true;
      });
  }

  private saveDataRequest(data) {
    const { emails, serviceEventId } = data;
    const date = new Date();

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'local-like-saved',
      data: {
        emails,
        serviceEventId,
        date,
      },
    });

    return this.httpService.post(this.analyticsEndpoint, {
      type: 'localLikeSaved',
      data: {
        emails,
        serviceEventId,
        date,
      },
    });
  }

  private submitRequest(data) {
    return this.httpService.post(this.likesEndpoint, data);
  }
}
