<div class="bztt-w-full bztt-h-full bztt-flex bztt-justify-center bztt-items-center bztt-relative">
    <div class="bztt-h-full bztt-w-full bztt-absolute bztt-z-[-1]">
        <div class="bztt-absolute bztt-top-0 bztt-left-0 bztt-w-full bztt-h-full bztt-bg-black bztt-opacity-50"></div>
        <img src="/assets/images/service-edit-screen.png" class="bztt-absolute bztt-top-0 bztt-left-0 bztt-w-full bztt-h-full bztt-blur-sm bztt-z-[-1]" />
    </div>
    
    <div class="bztt-w-full bztt-h-full md:bztt-w-[500px] md:bztt-min-h-[350px] md:bztt-h-fit md:bztt-rounded-lg bztt-bg-white bztt-p-8 bztt-pt-24 md:bztt-pt-10">
        <div class="bztt-flex bztt-flex-col bztt-h-full bztt-min-h-[310px] bztt-items-center bztt-text-center">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>