<h2 class="bztt-font-maple-r bztt-text-2xl">{{'profiling.experience.title' | translate}}</h2>

<mat-form-field (click)="dp.open()" class="bztt-w-full bztt-cursor-pointer">
    <input matInput
        #inputField
        autocomplete="off"
        type="number" min="0"
        (focus)="inputField.blur()"
        [matDatepicker]="dp"
        [formControl]="formControl"
        class="bztt-pointer-events-none"
        placeholder="{{'profiling.experience.workingSince' | translate}}"
        [matDatepickerFilter]="yearFilter">
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp 
        startView="multi-year"
        (viewChanged)="viewChanged(dp)"
        (yearSelected)="yearSelected($event, dp)"
        panelClass="year-picker"
        [touchUi]="isMobile | async"
        
    >
    </mat-datepicker>
    <mat-error>{{'errors.fieldRequired' | translate}}</mat-error>
</mat-form-field>

<button class="md:bztt-mt-auto" color="accent" [disabled]="formControl.invalid" (click)="next()" app-button>{{'profiling.next' | translate}}</button>
