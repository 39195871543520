<section>
  <div class="info-container">
    <div class="text-container">
      <h2 [class.no-subtext]="!showSubtext" *ngIf="showTitle">
        {{ (i18nKey ? i18nKey + '.title' : 'main.firstThings') | translate }}
      </h2>
      <span *ngIf="showSubtext">
        <ng-container [ngSwitch]="!!i18nKey">
          <ng-container *ngSwitchCase="true">
            {{ i18nKey + '.message1' | translate }}
          </ng-container>

          <ng-container *ngSwitchDefault>
            {{ 'main.beginBySettingUp.0' | translate }}
            <a routerLink="/profile/settings">{{
              'main.beginBySettingUp.1' | translate
            }}</a>
            {{ 'main.beginBySettingUp.2' | translate }}
            <a routerLink="/services">{{
              'main.beginBySettingUp.3' | translate
            }}</a>
            {{ 'main.beginBySettingUp.4' | translate }}
          </ng-container>
        </ng-container>
      </span>
    </div>
    <button
      *ngIf="showButton"
      app-button
      color="accent"
      (click)="customButton ? handleButtonClick() : openServices()"
    >
      <span class="bold-button-text">{{
        (i18nKey ? i18nKey + '.buttonTitle' : 'main.startWith') | translate
      }}</span>
    </button>
  </div>
</section>
