export enum LinkServiceCategories {
  venues = 'venues',
  planning = 'planning',
  decorFloristics = 'decor_floristics',
  photographer = 'photographer',
  videographer = 'videographer',
  host = 'host',
  liveMusic = 'live_music',
  dj = 'dj',
  soundLights = 'sound_lights',
  catering = 'catering',
  bartenders = 'bartenders',
  foodtruck = 'foodtruck',
  cakesDesserts = 'cakes_desserts',
  ceremonyVenue = 'ceremony_venue',
  ceremonyRegale = 'ceremony_regale',
  makeupHairdressing = 'makeup_hairdressing',
  brideClothing = 'bride_clothing',
  groomClothing = 'groom_clothing',
  secondaryDress = 'secondary_dress',
  transport = 'transport',
  decor = 'decor_elements',
  invitationsPrinting = 'invitations_printing',
  pavilionFurniture = 'pavilion_furniture',
  screensProjectors = 'screens_projectors',
  entertainment = 'entertainment',
  show = 'show',
  training = 'training',
  jewelery = 'jewelery',
  presents = 'presents',
  honeymoon = 'honeymoon',
  photoBooth = 'photo_booth',
  visualEntertainment = 'visual_entertainment',
  engagementTraining = 'engagement_training',
  ceremonyHost = 'ceremony_host'
}

export enum ServiceTypeCategoriesEnum {
  venues,
  catering,
  ceremony_venue,
  ceremony_regale,
  bartenders,
  foodtruck,
  videographer,
  photographer,
  photo_booth,
  live_music,
  planning,
  host,
  cakes_desserts,
  makeup_hairdressing,
  dj,
  bride_clothing,
  groom_clothing,
  secondary_dress,
  transport,
  decor,
  invitations_printing,
  decor_floristics,
  pavilion_furniture,
  sound_lights,
  screens_projectors,
  show,
  training,
  entertainment,
  jewelery,
  presents,
  honeymoon
}


export const ServiceTypeCategoriesIconData: { [key in keyof typeof ServiceTypeCategoriesEnum]: string } = {
  venues: '/assets/icons/icon-types/ic_pin.svg',
  ceremony_venue: '/assets/icons/icon-types/ic_ceremony.svg',
  catering: '/assets/icons/icon-types/ic_food.svg',
  ceremony_regale: '/assets/icons/icon-types/ic_regale.svg',
  bartenders: '/assets/icons/icon-types/ic_bartender.svg',
  foodtruck: '/assets/icons/icon-types/ic_food_cart.svg',
  videographer: '/assets/icons/icon-types/ic_video.svg',
  photographer: '/assets/icons/icon-types/ic_photo.svg',
  photo_booth: '/assets/icons/icon-types/ic_photobooth.svg',
  live_music: '/assets/icons/icon-types/ic_music.svg',
  planning: '/assets/icons/icon-types/ic_planning.svg',
  host: '/assets/icons/icon-types/ic_microphone.svg',
  cakes_desserts: '/assets/icons/icon-types/ic_cake.svg',
  makeup_hairdressing: '/assets/icons/icon-types/ic_makeup.svg',
  dj: '/assets/icons/icon-types/ic_dj.svg',
  bride_clothing: '/assets/icons/icon-types/ic_dress.svg',
  groom_clothing: '/assets/icons/icon-types/ic_groom.svg',
  secondary_dress: '/assets/icons/icon-types/ic_market.svg',
  transport: '/assets/icons/icon-types/ic_transport.svg',
  decor: '/assets/icons/icon-types/ic_decorations.svg',
  invitations_printing: '/assets/icons/icon-types/ic_invitation.svg',
  decor_floristics: '/assets/icons/icon-types/ic_flowers.svg',
  pavilion_furniture: '/assets/icons/icon-types/ic_pavilion.svg',
  sound_lights: '/assets/icons/icon-types/ic_effects.svg',
  screens_projectors: '/assets/icons/icon-types/ic_screens.svg',
  entertainment: '/assets/icons/icon-types/ic_party.svg',
  show: '/assets/icons/icon-types/ic_show.svg',
  training: '/assets/icons/icon-types/ic_training.svg',
  jewelery: '/assets/icons/icon-types/ic_rings.svg',
  presents: '/assets/icons/icon-types/ic_present.svg',
  honeymoon: '/assets/icons/icon-types/ic_honeymoon.svg',
};
