import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, TemplateRef, Type, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { merge, Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { LayoutService } from '../../../../shared/services/layout.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent implements OnInit {

  @Input() iconName = 'error';
  @Input() notifications: Observable<string | number>;
  @Input() dropdown: Type<any>;
  @Input() link: string;
  @Input() imageLink: string | null = null;
  @Input() onlyIcon = false;

  @ViewChild('iconOutlet') iconOutlet: TemplateRef<any>;

  constructor(
    private layoutService: LayoutService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
  }

  openDropdown(clickEvent: MouseEvent): void {
    if (this.dropdown) {
      clickEvent.preventDefault();
      this.layoutService.createOverlay(this.dropdown, this.elementRef);
    }
  }
}
