<div class="bztt-flex bztt-items-center bztt-justify-between bztt-mb-2">
  <p
    class="bztt-font-montserrat bztt-m-0 bztt-text-sm bztt-text-[#717171] bztt-whitespace-nowrap bztt-overflow-ellipsis bztt-overflow-hidden"
  >
    {{ data.type | translate }}
  </p>
  <!-- <div> | titlecase }}
    <span class="bztt-text-[#ef5518] bztt-mr-2">★</span>
    <span class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171]"
      >{{ data.rating }} ({{ data.ratingCount }})</span
    >
  </div> -->
</div>

<div *ngIf="data.address" class="bztt-flex bztt-mb-1 bztt-text-[#717171]">
  <mat-icon class="bztt-text-xs bztt-h-3 bztt-w-3">location_on</mat-icon>
  <span class="bztt-font-montserrat bztt-text-xs">
    {{ data.address }}
  </span>
</div>
<div *ngIf="!data.address" class="bztt-h-4"></div>

<div class="bztt-min-h-[4rem]">
  <h2
    class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222] card-title-overflow-elipses"
  >
    {{ data.title }}
  </h2>
</div>

<div class="bztt-flex bztt-gap-1 bztt-items-center bztt-mt-auto">
  <ng-container [ngSwitch]="!!data.discountedPrice">
    <ng-container *ngSwitchCase="true">
      <span
        class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#ef5518]"
        >{{ data.discountedPrice }}</span
      >
      <span
        class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171] bztt-line-through"
        >{{ data.indicativePrice }}</span
      >
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span
        class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222]"
        >{{ data.indicativePrice }}</span
      >
    </ng-container>
  </ng-container>

  <span class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171]">{{
    data.priceUnit | PriceUnitLabel | async
  }}</span>

  <div class="bztt-ml-auto" *ngIf="data.venueInfo">
    <mat-icon
      matTooltip="Included in price"
      class="bztt-mr-2"
      *ngIf="data.venueInfo.hasCatering"
      >restaurant</mat-icon
    >
    <mat-icon
      matTooltip="Included in price"
      class="bztt-mr-2"
      *ngIf="data.venueInfo.hasDrinks"
      >local_bar</mat-icon
    >
    <mat-icon
      matTooltip="Included in price"
      *ngIf="data.venueInfo.hasAccomodation"
      >king_bed</mat-icon
    >
  </div>
</div>
