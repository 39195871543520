export enum BookingAction {
    none,
    review,
    pay,
    signContract,
    wantToBook, 
    complete,
    delete,
    reserve,
    serviceEdit,
    priceEdit,
    inviteCustomer,
    successPayment
}

export type BookingActionType = keyof typeof BookingAction;

export interface BookingActionUIState {
  visible?:boolean; 
  enabled?:boolean;
  action: BookingAction;
  title?:string;
}