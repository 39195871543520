<app-modal-wrapper
  (closed)="close()"
  class="modal-wrapper request-dialog-container"
>
  <div
    class="bztt-h-full bztt-w-full bztt-flex bztt-flex-col bztt-justify-center bztt-items-center"
  >
    <div class="text-wrapper">
      <img src="assets/icons/ic_success_large.svg" />
      {{ 'demoRequest.pleaseBookATime' | translate }}
    </div>
    <div class="bztt-flex bztt-flex-col bztt-justify-center bztt-items-center">
      <a
        [href]="
          domainCountry === 'us'
            ? 'https://meetings-eu1.hubspot.com/arturas-asakavicius/intro-to-breezit'
            : 'https://meetings-eu1.hubspot.com/gintare-sudziute/breezit-skambutis'
        "
        target="_blank"
        class="bztt-border-none"
      >
        <button
          class="bztt-mt-5 bztt-w-80 bztt-self-center"
          color="accent"
          app-button
        >
          {{ 'demoRequest.bookMeeting' | translate }}
        </button>
      </a>
    </div>
  </div>
</app-modal-wrapper>
