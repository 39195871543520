import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {
  GoogleLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import {
  combineLatest,
  firstValueFrom,
  from,
  merge,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  switchMapTo,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { SocketService } from '@jarvis/services/socket';
import { BASE_URL } from '@jarvis/services/tokens';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { JarvisLanguageService } from '@jarvis/services/language';
import {
  Identify,
  track,
  setUserId,
  setGroup,
  identify,
} from '@amplitude/analytics-browser';
import { UserProfilingData } from '@jarvis/user-profiling';
import { NgxHotjarService } from 'ngx-hotjar';
import { JarvisUiModalService } from '@jarvis/ui';
import { SuccessDialogComponent } from '@jarvis/ui/src/lib/components/common-dialog/success-modal.component';
import mixpanel from 'mixpanel-browser';
import { JarvisTrackingService } from '@jarvis/services';

interface AuthData {
  email: string;
  password: string;
  remember?: boolean;
}

interface LoginResponse {
  email: string;
  id: string;
  type: string;
  language?: string;
  country?: string;
}

export interface UserProfilingDataObj {
  _id: string;
  createdAt: Date;
  user: string;
  data: { completed?: boolean } & UserProfilingData;
}

export type UserProfilingErrors = { message: string }[];

export interface User {
  _id: string;
  userName?: string;
  email: string;
  name?: string;
  surname?: string;
  profilePhoto?: any;
  country: string;
  userType: 'user' | 'vendor';
  profiling?: UserProfilingDataObj;
}

interface RegisterUser {
  name?: string;
  surname?: string;
  email: string;
  password?: string;
  userType?: 'user' | 'vendor';
  language?: string;
  country?: string;
  bookingId?: string;
}

declare const freshpaint: any;
declare const HelpCrunch: any;

export type AuthEvent = 'success' | 'fail' | 'cancel';

const UNAUTH_ID_STORAGE_KEY = 'unauthId';

@Injectable({ providedIn: 'root' })
export class JarvisAuthService {
  private trackService = inject(JarvisTrackingService);

  // private userDataSource$ = new BehaviorSubject<User | null>(null);
  private currentUserReload$ = new Subject<void | false>();

  registrationEvent$ = new Subject<AuthEvent>();
  loginEvent$ = new Subject<AuthEvent>();

  userData$: Observable<User>;
  isLoggedIn$: Observable<boolean>;

  unauthId = null;

  private loginEndpoint: string;
  private logoutEndpoint: string;
  private currentUserEndpoint: string;
  private forgotPasswordEndpoint: string;
  private changeForgottenPasswordEndpoint: string;
  private changePasswordEndpoint: string;
  private signUpEndpoint: string;
  private confirmEmailEndpoint: string;
  private updateUserEndpoint: string;
  private resendEmailConfirmationEndpoint: string;
  private moveSessionEndpoint: string;
  private facebookAuthEndpoint: string;
  private googleAuthEndpoint: string;
  private userProfilingEndpoint: string;

  static checkLoginFactory(authService: JarvisAuthService): () => Promise<any> {
    return () => firstValueFrom(authService.getCurrentUser());
  }

  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    private httpService: HttpClient,
    private socketService: SocketService,
    private socialAuthService: SocialAuthService,
    private languageService: JarvisLanguageService,
    private gtmService: GoogleTagManagerService,
    private hotjarService: NgxHotjarService,
    private modalService: JarvisUiModalService,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string
  ) {
    this.setUnauthId();

    if (domainCountry === 'lt') {
      HelpCrunch('init', 'foxyevents', {
        applicationId: 1,
        applicationSecret:
          'Nfx2WiDDwTC2SvOIhhXgi9hbwg4zr4TcrVTSs8/k9+14d6XRwBwJAg2jdBzk+ssfzxPutpLewNt9/FqJX2/Zxg==',
      });

      HelpCrunch('showChatWidget');
    }

    this.setEndpoints();

    this.userData$ = this.constructUser$();
    this.isLoggedIn$ = this.userData$.pipe(map((data) => Boolean(data)));

    this.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.socketService.initSocket();
        return;
      } else {
        this.socketService.destroySocket();
      }
    });

    // DEBUG
    /* merge(this.loginEvent$.asObservable(), this.registrationEvent$.asObservable()).subscribe(
        (event) => {
            // console.log('Got event from auth streams: ', event);
        }
    ); */
  }

  showRegistrationSuccessDialog() {
    const dialogRef = this.modalService.openDialog(SuccessDialogComponent, {
      data: { i18nKey: 'register.registrationSuccessful' },
    });

    setTimeout(() => dialogRef.close(), 3000);
  }

  login(data: AuthData): Observable<User> {
    const url = this.loginEndpoint;
    return this.httpService
      .post<LoginResponse>(
        url,
        {
          email: data.email,
          password: data.password,
        },
        { observe: 'response' }
      )
      .pipe(
        tap((auth) =>
          localStorage.setItem('foxyAuth', auth.headers.get('foxyAuth'))
        ),
        switchMap(() =>
          this.getCurrentUser(true).pipe(
            filter((data) => !!data),
            take(1)
          )
        )
      );
  }

  // Change to inner method, use shared streams for currentUser and isLoggedIn accross apps
  getCurrentUser(hotObservable = false): Observable<User> {
    this.currentUserReload$.next();
    return hotObservable ? this.userData$ : this.userData$.pipe(take(1));
  }

  amplitudeUserIdentify(userData) {
    const identifyObj = new Identify();
    identifyObj.set('email', userData.email);
    identifyObj.set('country', userData.country);
    identifyObj.set('createdAt', userData.createdAt);
    identifyObj.set('emailVerified', userData.emailVerified);
    identifyObj.set('language', userData.language);
    identifyObj.set('name', userData.name);
    identifyObj.set('profile_picture', userData.profile_picture);
    identifyObj.set('surname', userData.surname);
    identifyObj.set('userType', userData.userType);
    identifyObj.set(
      'planningStatus',
      userData.privateInfo?.data?.planningStatus
    );

    setGroup('typeGroup', userData.userType);
    identify(identifyObj);
    setUserId(userData.email);
    this.hotjarService.hj('identify', userData.email, {});
  }

  mixpanelUserIdentify(userData) {
    mixpanel.identify(userData.email);
    mixpanel.people.set({
      email: userData.email,
      created: userData.createdAt,
      country: userData.country,
      createdAt: userData.createdAt,
      emailVerified: userData.emailVerified,
      language: userData.language,
      name: userData.name,
      profile_picture: userData.profile_picture,
      surname: userData.surname,
      userType: userData.userType,
      planningStatus: userData.privateInfo?.data?.planningStatus,
    });
  }

  helpCrunchUserIdentify(userData: User) {
    HelpCrunch('userAuth', {
      email: userData.email,
      user_id: userData._id,
    });
    HelpCrunch('updateUser', {
      email: userData.email,
      name: userData.name,
      user_id: userData._id,
      custom_data: {
        user_type: userData.userType,
      },
    });
  }

  signOut(): Observable<any> {
    // this.socialAuthService.authState.pipe(take(1)).subscribe(console.log);
    return this.httpService.post(this.logoutEndpoint, {}).pipe(
      switchMapTo(from(this.safeSocialLogout())),
      switchMap((socialLogoutResult) => {
        // console.log(socialLogoutResult);

        try {
          HelpCrunch('logout');
        } catch (e) {
          console.error(e);
        }

        this.currentUserReload$.next(false);
        return this.userData$.pipe(take(1));
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    const url = this.forgotPasswordEndpoint;
    return this.httpService.post(url, {
      email,
    });
  }

  signUp(user: RegisterUser): Observable<any> {
    const url = this.signUpEndpoint;

    const userWithRegionalSettings = {
      ...user,
      language: this.languageService.getActiveLanguage(),
      country: this.domainCountry.toUpperCase(),
    };

    return this.httpService
      .post(url, userWithRegionalSettings, { observe: 'response' })
      .pipe(
        tap((auth) => {
          localStorage.setItem('foxyAuth', auth.headers.get('foxyAuth'));
          this.handleRegistrationSuccess('Email');
        })
      );
  }

  handleRegistrationSuccess(type) {
    this.showRegistrationSuccessDialog();
    this.trackAccountCreated(type);
  }

  trackAccountCreated(type) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel', 'gtm', 'hotjar'],
      eventName: 'Sign-Account_created',
      data: { type, page_url: window.location.href },
    });
  }

  changeForgottenPassword(hash: string, password: string): Observable<any> {
    return this.httpService.post(this.changeForgottenPasswordEndpoint, {
      hash,
      password,
    });
  }

  changePassword(
    currentPassword: string,
    newPassword: string
  ): Observable<any> {
    return this.httpService.post(this.changePasswordEndpoint, {
      password: currentPassword,
      newPassword,
    });
  }

  confirmEmail(hash: string): Observable<any> {
    return this.httpService.post(this.confirmEmailEndpoint, {
      hash,
    });
  }

  updateProfilePhoto(profilePhotoUrl: string): Observable<any> {
    return this.httpService.patch(this.updateUserEndpoint, {
      profilePhoto: profilePhotoUrl,
    });
  }

  updateNameAndSurname(name: string, surname: string): Observable<any> {
    return this.httpService.patch(this.updateUserEndpoint, {
      name,
      surname,
    });
  }

  updateUserType(userType: 'user' | 'vendor'): Observable<User> {
    return this.httpService.patch<User>(this.updateUserEndpoint, {
      userType,
    });
  }

  resendEmailConfirmation(email: string): Observable<any> {
    return this.httpService.post(
      this.resendEmailConfirmationEndpoint,
      {
        email,
      },
      { responseType: 'text' }
    );
  }

  createMoveSession(): Observable<any> {
    return this.httpService.get(this.moveSessionEndpoint);
  }

  useMoveSession(hash: string): Observable<any> {
    return this.httpService.get(`${this.moveSessionEndpoint}/${hash}`);
  }

  /**
   * Creates a stream that emits a success event if login or register succeeds
   * Otherwise, the stream will complete if innerClose is emitted or login or register
   * streams send a cancel event.
   * If register or login streams send a fail event - we filter it.
   */
  createRegisterOrLoginSuccessStream(
    innerClose$?: Observable<any>
  ): Observable<any> {
    const streams: Observable<any>[] = [
      merge(
        this.registrationEvent$.asObservable().pipe(startWith(null)),
        this.loginEvent$.asObservable().pipe(startWith(null))
      ),
      ...(innerClose$ ? [innerClose$] : [of(null)]),
    ];

    return combineLatest(streams).pipe(
      takeWhile(([regEvent, innerCloseSub]) => {
        if (innerCloseSub) {
          return false;
        }

        return !(regEvent === 'success' || regEvent === 'cancel');
      }, true),
      filter(([regEvent]) => regEvent === 'success'),
      switchMap(() =>
        this.getCurrentUser(true).pipe(
          filter((data) => !!data),
          take(1)
        )
      )
    );
  }

  facebookLogin(data: AuthData): Observable<User> {
    const url = this.facebookAuthEndpoint;
    return this.httpService
      .post<any>(
        url,
        {
          email: data.email,
          password: data.password,
        },
        { observe: 'response' }
      )
      .pipe(
        tap((auth) =>
          localStorage.setItem('foxyAuth', auth.headers.get('foxyAuth'))
        ),
        switchMap(() => this.getCurrentUser())
      );
  }

  facebookRegister(code: string, userType?: string): Observable<any> {
    const url = this.facebookAuthEndpoint;
    const userData = {
      code,
      userType,
      language: this.languageService.getActiveLanguage(),
      country: this.domainCountry.toUpperCase(),
    };

    return this.httpService.post(url, userData, { observe: 'response' }).pipe(
      tap((auth) => {
        localStorage.setItem('foxyAuth', auth.headers.get('foxyAuth'));

        this.handleRegistrationSuccess('Facebook');
      })
    );
  }

  googleRegister(code: string, userType?: string): Observable<any> {
    const url = this.googleAuthEndpoint;
    const userData = {
      code,
      userType,
      language: this.languageService.getActiveLanguage(),
      country: this.domainCountry.toUpperCase(),
    };

    return this.httpService.post(url, userData, { observe: 'response' }).pipe(
      tap((auth) => {
        localStorage.setItem('foxyAuth', auth.headers.get('foxyAuth'));
        this.handleRegistrationSuccess('Google');
      })
    );
  }

  get moveUserToSaas() {
    return this.userData$.pipe(
      take(1),
      switchMap((userData) => {
        if (userData.userType === 'user') {
          return this.updateUserType('vendor');
        }

        return of(userData);
      })
    );
  }

  private constructUser$(): Observable<User> {
    const url = this.currentUserEndpoint;

    const currentUser$ = this.httpService.get(url).pipe(
      /* catchError((err) => {
          // console.log(err);
          return of(null);
      }), */
      tap((userData: User) => {
        try {
          if (this.domainCountry === 'lt')
            this.helpCrunchUserIdentify(userData);
        } catch (e) {
          console.log(e);
        }
      }),
      tap((userData: User) => {
        this.mixpanelUserIdentify(userData);
        this.amplitudeUserIdentify(userData);
        try {
          freshpaint.identify(userData.email);
        } catch (e) {
          console.log(e);
        }
      }),
      switchMap((userData: User) => {
        if (!localStorage.getItem('foxyAuth')) {
          // TODO: Bug when navigating to the other project
          // But also bug if we dont have the auth header set (sockets will not work)
          // return this.signOut().pipe(mapTo(null));
        }
        return this.httpService.get(this.userProfilingEndpoint).pipe(
          catchError(() => {
            return of(null);
          }),
          map((profilingData) => ({
            ...userData,
            profiling: profilingData,
          }))
        );
      }),
      catchError(() => of(undefined))
    );

    return this.currentUserReload$.pipe(
      switchMap((reloadValue) => {
        if (reloadValue === false) {
          return of(null);
        }

        return currentUser$ as Observable<User>;
      }),
      shareReplay(1)
    );
  }

  private setUnauthId() {
    const localStorage = window?.localStorage;

    if (!localStorage) {
      return;
    }

    const keyFromStorage = localStorage.getItem(UNAUTH_ID_STORAGE_KEY);

    if (keyFromStorage) {
      this.unauthId = keyFromStorage;
    } else {
      const newId = this.generateNonAuthUserToken();
      this.unauthId = newId;
      localStorage.setItem(UNAUTH_ID_STORAGE_KEY, newId);
    }
  }

  private generateNonAuthUserToken(): string {
    const timeStamp = new Date().toISOString();
    const randSequenc = this.makeId(5);

    const unauthId = `${timeStamp}-${randSequenc}`;
    return unauthId;

    // const base64 = Buffer.from(`${timeStamp}-${randSequenc}`).toString('base64');
    // return base64;
  }

  private makeId(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  private setEndpoints(): void {
    this.loginEndpoint = `${this.baseUrl}/users/signin`;
    this.logoutEndpoint = `${this.baseUrl}/users/signout`;
    this.currentUserEndpoint = `${this.baseUrl}/users/getCurrentUser`;
    this.forgotPasswordEndpoint = `${this.baseUrl}/users/forgotPassword`;
    this.changeForgottenPasswordEndpoint = `${this.baseUrl}/users/forgotPasswordChange`;
    this.changePasswordEndpoint = `${this.baseUrl}/users/changePassword`;
    this.signUpEndpoint = `${this.baseUrl}/users/signup`;
    this.confirmEmailEndpoint = `${this.baseUrl}/users/confirmEmail`;
    this.updateUserEndpoint = `${this.baseUrl}/users/updateUser`;
    this.resendEmailConfirmationEndpoint = `${this.baseUrl}/users/resendConfirmEmail`;
    this.moveSessionEndpoint = `${this.baseUrl}/users/moveSession`;
    this.facebookAuthEndpoint = `${this.baseUrl}/users/facebookAuth`;
    this.googleAuthEndpoint = `${this.baseUrl}/users/googleAuth`;
    this.userProfilingEndpoint = `${this.baseUrl}/common/getProfiling`;
  }

  private async safeSocialLogout() {
    try {
      await this.socialAuthService.signOut(true);
      return true;
    } catch (e) {
      return false;
    }
  }
}
