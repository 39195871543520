export const ALL_CATEGORIES_US: any[] = [
  {
    label: 'listing.selectCategory.findYourProvider',
    items: [
      { title: 'new', category: 'featured', iconName: 'new', link: 'featured' },
      { title: 'venues', category: 'venues', iconName: 'venues', link: 'wedding-venues', mobileOnly: true },
      { title: 'dj', category: 'dj', iconName: 'dj', link: 'wedding-djs' },
      { title: 'planning', category: 'planning', iconName: 'planning', link: 'wedding-planners' }
    ]
  },
  {
    label: '',
    items: [
      { title: 'photographer', category: 'photographer', iconName: 'photographer', link: 'wedding-photographers' },
      { title: 'videographer', category: 'videographer', iconName: 'videographer', link: 'wedding-videographers' },
      { title: 'live_music', category: 'live_music', iconName: 'live_music', link: 'wedding-live-music' }
    ]
  },
  {
    label: '',
    items: [
      { title: 'decor_floristics', category: 'decor_floristics', iconName: 'decor_floristics', link: 'florists' }
    ]
  }/* ,
  {
    label: '',
    items: [
    ]
  } */
];

export const ALL_CATEGORIES_LT: any[] = [
  {
    label: 'listing.selectCategory.findYourProvider',
    items: [
      { title: 'transport', category: 'transport', iconName: 'transport', hiddenForCountries: ['US'], link: 'automobiliu-nuoma' },
      { title: 'ceremony_host', category: 'ceremony_host', iconName: 'ceremony_host', hiddenForCountries: ['US'], link: 'ceremonijos-vedejai' },
      { title: 'ceremony_venue', category: 'ceremony_venue', iconName: 'ceremony_venue', hiddenForCountries: ['US'], link: 'ceremonijos-vieta' },
      { title: 'decor', category: 'decor', iconName: 'decor', hiddenForCountries: ['US'], link: 'dekoraciju-nuoma' },
      { title: 'dj', category: 'dj', iconName: 'dj', link: 'dj' },
      { title: 'visual_entertainment', category: 'visual_entertainment', iconName: 'visual_entertainment', hiddenForCountries: ['US'], link: 'foto-video-pramogos' },
      { title: 'photographer', category: 'photographer', iconName: 'photographer', link: 'fotografai' }
    ]
  },
  {
    label: '',
    items: [
      { title: 'live_music', category: 'live_music', iconName: 'live_music', link: 'gyva-muzika' },
      { title: 'catering', category: 'catering', subcategory: null, iconName: 'catering', hiddenForCountries: ['US'], link: 'isvaziuojamieji-banketai' },
      { title: 'jewelery', category: 'jewelery', iconName: 'jewelery', hiddenForCountries: ['US'], link: 'juvelyrika' },
      { title: 'invitations-printing', category: 'invitations_printing', iconName: 'invitations_printing', hiddenForCountries: ['US'], link: 'kvietimai' },
      { title: 'foodtruck', category: 'catering', subcategory: 'foodTruck', iconName: 'foodtruck', hiddenForCountries: ['US'], link: 'isvaziuojamieji-banketai/food-truck' },
      { title: 'makeup_hairdressing', category: 'makeup_hairdressing', iconName: 'makeup_hairdressing', hiddenForCountries: ['US'], link: 'grozio-paslaugos' },
      { title: 'bartenders', category: 'bartenders', iconName: 'bartenders', hiddenForCountries: ['US'], link: 'mobilus-baras' }
    ]
  },
  {
    label: '',
    items: [
      { title: 'pavilion_furniture', category: 'pavilion_furniture', iconName: 'pavilion_furniture', hiddenForCountries: ['US'], link: 'baldu-nuoma' },
      { title: 'training', category: 'training', iconName: 'training', hiddenForCountries: ['US'], link: 'mokymai' },
      { title: 'planning', category: 'planning', iconName: 'planning', link: 'renginiu-planavimas' },
      { title: 'entertainment', category: 'entertainment', iconName: 'entertainment', hiddenForCountries: ['US'], link: 'pramogos' },
      { title: 'decor_floristics', category: 'decor_floristics', iconName: 'decor_floristics', link: 'renginio-dekoras' },
      { title: 'sound_lights', category: 'sound_lights', iconName: 'sound_lights', hiddenForCountries: ['US'], link: 'renginio-igarsinimas-apsvietimas' },
      { title: 'cakes_desserts', category: 'cakes_desserts', iconName: 'cakes_desserts', hiddenForCountries: ['US'], link: 'saldus-stalas' }
    ]
  },
  {
    label: '',
    items: [
      { title: 'ceremony_regale', category: 'ceremony_regale', iconName: 'ceremony_regale', hiddenForCountries: ['US'], link: 'sampano-staliukai' },
      { title: 'show', category: 'show', iconName: 'show', hiddenForCountries: ['US'], link: 'sou' },
      { title: 'venues', category: 'venues', iconName: 'venues', link: 'sventes-vietos', mobileOnly: false },
      { title: 'bride_clothing', category: 'bride_clothing', iconName: 'bride_clothing', link: 'sventine-apranga-moterims' },
      { title: 'groom_clothing', category: 'groom_clothing', iconName: 'groom_clothing', link: 'sventine-apranga-vyrams' },
      { title: 'host', category: 'host', iconName: 'host', hiddenForCountries: ['US'], link: 'vedejai' },
      { title: 'videographer', category: 'videographer', iconName: 'videographer', link: 'videografai' }   
    ]
  }
];