export enum AdvertsPriceUnits {
  fixedFee = 'fixed',
  percentageFee = 'percentage',
  currencyPerUnit = 'currency_per_unit',
  currencyPerPerson = 'currency_per_person',
  currencyPerHour = 'currency_per_hour',
  currencyPerWeight = 'currency_per_weight',
  currencyPerDistance = 'currency_per_distance',
  hourlyPrice = 'hour',
  priceForWhole = 'price_for_whole',
  priceForDoubleRoom = 'price_for_double_room',
  priceFrom = 'price_from',
  priceTo = 'price_to',
  startingPrice = 'starting_price',
  ratesStartAt = 'rates_start_at',
  fixedPercentage = 'fixed_percentage',
  minimalOrderSum = 'minimal_order_sum',
  pricePerHourPerPerson = 'per_hour_per_person',
  fixedFeeByGuest = 'fixed_by_guest',
  fixedPerPerson = 'fixed_per_person'
}

export type AdvertsPriceUnitsKeys = (typeof AdvertsPriceUnits) [ keyof (typeof AdvertsPriceUnits)];