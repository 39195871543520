import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { JarvisSeoService } from '../seo.service';
import { Observable } from 'rxjs';
import { LayoutService } from '@jarvis/ui';

@Component({
  selector: 'jarvis-seo-footer',
  templateUrl: 'seo-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe
  ]
})

export class JarvisSeoFooterComponent implements OnInit {

  footerContent$!: Observable<string | null>;

  showFooter$ = this.layoutService.footerEnabled$;

  constructor(
    private seoService: JarvisSeoService,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.footerContent$ = this.seoService.footerContent$;
  }
}
