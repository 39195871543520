import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth/services';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProfilingCountryResolver implements Resolve<string> {

    constructor(private authService: JarvisAuthService) {}

    resolve(): Observable<string> {
        return this.authService.userData$.pipe(
            take(1),
            map((user) => {
                return user?.country ?? 'US';
            })
        );
    }
}