import { AdvertsPriceUnits } from '../../types/service-price-units.types';
import { AdvertServiceVariant } from './pricing-calculator';

interface PathConfig {
    type: ServiceTypes,
    subservices?: any;
    location?: { location: PathLocation, path: string },
    maximums?: { [parameter: string]: { location: PathLocation, path: string } | null },
    disabledPricingModifiers?: PricingModifiers[],
    description?: string;
    name?: string;
    enabled?: { location: PathLocation, path: string };
}

type ExtractorPathConfigs = {
    main: PathConfig[],
    additional: PathConfig[]
}

// All service examples

// Should be objects

enum ServiceTypes {
    mainService = 'mainService', // Has description
    venueRent = 'venueRent',
    additionalService = 'additionalService', // Has description
    venueStandalone = 'venueStandalone', // Either the price for whole venue, or a question whether. No description. Unit values?
    venueSeparate = 'venueSeparate', // Disabled if catering is disabled - only standalone price applies. Conditional - If venue standalone is false - . No description. Unit values?
    venueCeremony = 'venueCeremony',
    venueCeremonyFee = 'venueCeremonyFee',
    venueAccomodation = 'venueAccomodation', // No description. Has max unit value.
    venueCateringMenuMain = 'venueCateringMenuMain', // Main catering menu. Only if catering is enabled. Has description. Has max unit value.
    venueCateringMenuAdditional = 'venueCateringMenuAdditional', // Only if catering is enabled. Has description. Has max unit value.
    venueCateringMenuOther = 'venueCateringMenuOther', // Only if catering is enabled. Has description. Has max unit value.
    venueCateringMenuChildren = 'venueCateringMenuChildren', // Only if catering is enabled
    venueCateringMenuBeverages = 'venueCateringMenuBeverages', // Only if catering is enabled
    venueCateringMenuBeveragesChildren = 'venueCateringMenuBeveragesChildren', // Only if catering is enabled
    venueOvertime = 'venueOvertime',
    venueLinen = 'venueLinen',
    venueSeat = 'venueSeat',
    venueTable = 'venueTable',
    personelOvertime = 'personelOvertime',
    cateringTasting = 'cateringTasting', // same as venueCateringTasting. No description. Has max unit value?
    cateringKidsMenu = 'cateringKidsMenu', // same as venueCateringKidsMenu. No description. Has max unit value?
    cateringDessert = 'cateringDessert', // same as venueCateringDessert. No description. Has max unit value?
    cateringBuffetMenu = 'cateringBuffetMenu',
    cateringBanquetMenu = 'cateringBanquetMenu',
    cateringFoodTruck = 'cateringFoodTruck',
    cateringSnacks = 'cateringSnacks',
    othersAdditionalHour = 'othersAdditionalHour', // No description
    serviceCharge = 'serviceCharge',
    globalMinimalBudget = 'globalMinimalBudget'
}

enum MarkupDiscountActions {
    markup = 'markup',
    discount = 'discount'
}

enum AdvertTypes {
    venues = 'venues',
    catering = 'catering',
    others = 'others'
}


enum PathLocation {
    base = 'base',
    event = 'event'
}


enum PricingModifiers {
    all = 'all',
    markupDiscount = 'markupDiscount',
    weeklyPricing = 'weeklyPricing',
    seasonalPricing = 'seasonalPricing'
}

// Pricing flags:
// weeklyPricingEnabled
// seasonalPricingEnabled
// markupDiscountEnabled

// Flags:
// Person count dependant (unit - price_per_person)

// Maximum limit of quantity - for specific price units as well

interface ServiceDescriptor {
    name?: string; // Service Name - usually optional
    id: string; // Unique UUID of a service
    type: string; // Type - could be a value object - picks values from enum
    description?: any;
    priceUnit?: AdvertsPriceUnits;
    priceValue: number;
    minimalBudget?: number;
    markupDiscount?: any;
    weeklyPricing?: any;
    seasonalPricing?: any;
    pricingModifiers?: any;
    tax?: number;
    serviceCharge?: number;
    startQuantityAt?: number;
    other?: any; // We can expand this
    // alcoholPriceIncluded - catering in venues (service event object)
    // cateringType - Object with checkboxes. buffet - true/false, banquet - true/false. Only one can be true at a time
}

interface ServiceInfo {
    base: any;
    event: any;
}

/* interface ServiceExtractorConfig {
    guestCount?: number; // Preliminary (or not) guest count for all services. Applies it by default, if a service should need this information
} */

interface ExtractedServices {
    main: AdvertServiceVariant[],
    additional: AdvertServiceVariant[]
}

export {
  ExtractedServices,
  PathConfig,
  PathLocation,
  ServiceTypes,
  AdvertsPriceUnits,
  ExtractorPathConfigs,
  ServiceDescriptor,
  MarkupDiscountActions,
  AdvertTypes,
  ServiceInfo,
  PricingModifiers
};
