import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayContainsValues',
  standalone: true,
})
export class ArrayContainsValuesPipe implements PipeTransform {
  transform(value: any, containValues: { [key: string]: any }): any {
    if (!value) return null;
    if (!containValues) return value;

    const data = value.filter(function (data: any) {
      return containValues.find(function (condition) {
        const key = Object.keys(condition)[0];
        if (condition[key] === null) return true;
        return data[key] == condition[key];
      });
    });

    return data;
  }
}

@Pipe({
  name: 'containsValues',
  standalone: true,
})
export class ContainsValuesPipe implements PipeTransform {
  transform(value: any, containValues: { [key: string]: any }[]): any {
    if (!value) return null;
    if (!containValues || containValues.length < 1) return value;

    return value.filter(function (data: any) {
      // find any match
      return containValues.find(function (condition) {
        const key = Object.keys(condition)[0];
        if (condition[key] === null) return true;
        return data[key] == condition[key];
      });
    });
  }
}

@Pipe({
  name: 'notContainsValues',
  standalone: true,
})
export class NotContainsValuesPipe implements PipeTransform {
  transform(value: any, containValues: { [key: string]: any }[]): any {
    if (!value) return null;
    if (!containValues || containValues.length < 1) return value;

    return value.filter(function (data: any) {
      // find any match
      return containValues.every(function (condition) {
        const key = Object.keys(condition)[0];
        if (condition[key] === null) return true;
        return data[key] != condition[key];
      });
    });
  }
}

@Pipe({
  name: 'containsAllValues',
  standalone: true,
})
export class ContainsAllValuesPipe implements PipeTransform {
  transform(value: any, ...pipeValues: { [key: string]: any }[]): any {
    if (!value) return null;
    const containValues = pipeValues.reduce(function (r, a) {
      return a ? r.concat(a) : r;
    }, []);

    if (!containValues || containValues.length < 1) return value;

    const containsGroup = containValues.reduce(function (r, a) {
      const key = Object.keys(a)[0];
      r[key] = r[key] || [];
      r[key].push(a[key]);
      return r;
    }, Object.create(null));

    return value.filter(function (data: any) {
      // find any match
      return Object.keys(containsGroup).every(function (key) {
        return containsGroup[key].find(function (subcondition) {
          return data[key] == subcondition;
        });
      });
    });
  }
}
