import { environment } from '../environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router, NavigationEnd } from '@angular/router';
import { HeaderComponent } from './core/components/header/header.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { JarvisHeaderService, LayoutService } from '@jarvis/ui';
import { Observable } from 'rxjs';
import { ModuleLoadingIndicatorService } from './core/services/module-loading-indicator.service';
import { DOMAIN_COUNTRY } from '@jarvis/services';
import { PixelService } from 'ngx-pixel';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  moduleLoading$: Observable<boolean>;

  iconConfig = [
    { iconName: 'facebook', path: '../assets/icons/facebook.svg' },
    { iconName: 'google', path: '../assets/icons/google.svg' },
    { iconName: 'apple', path: '../assets/icons/apple.svg' },
    { iconName: 'advert_general', path: '../assets/icons/advert_general.svg' },
    { iconName: 'advert_pricing', path: '../assets/icons/advert_pricing.svg' },
    { iconName: 'pricing_guarantee', path: '../assets/icons/pricing_guarantee.svg' },
    { iconName: 'pricing_taxes', path: '../assets/icons/pricing_taxes.svg' },
  ];

  footerEnabled$ = this.layoutService.footerEnabled$;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private moduleLoadingService: ModuleLoadingIndicatorService,
    private layoutService: LayoutService,
    private headerService: JarvisHeaderService,
    private router: Router,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string,
    private gtmService: GoogleTagManagerService,
    private pixelService: PixelService,
    private meta: Meta
  ) {
    this.iconConfig.forEach(iconConfig => {
      this.matIconRegistry.addSvgIcon(
        iconConfig.iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconConfig.path)
      );
    });

    this.moduleLoading$ = this.moduleLoadingService.loading$;
  }

  ngOnInit(): void {
    this.headerService.setDefaultHeader(HeaderComponent);

    // if (this.domainCountry !== 'us') {
    //   this.meta.addTag({ name: 'robots', content: 'noindex' });
    //   this.meta.addTag({ name: 'googlebot', content: 'noindex' });
    // }

    if (this.domainCountry === 'lt') {
      this.pixelService.remove();
      this.pixelService.initialize('1484365301768601');
    } else {
      this.pixelService.initialize();
    }

    if (environment.production) {
      this.router.events.forEach((event: any) => {
        if (event instanceof NavigationEnd) {

          const gtmTag = {
            event: 'page',
            pageName: event.url
          };

          this.gtmService.pushTag(gtmTag);
        }
      });
    }
  }
}
