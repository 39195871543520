<div
  class="packages-container bztt-flex bztt-justify-center bztt-items-center bztt-h-full bztt-w-full"
>
  <div
    class="packages-inner-container bztt-max-w-[84rem] bztt-w-full bztt-flex bztt-flex-row bztt-justify-between bztt-gap-12 xl:bztt-px-9"
  >
    <div class="package-section-title">
      {{ 'landing.homepageUplift.sections.title' | translate }}
    </div>
    <div class="swiper2-container">
      <swiper [config]="packagesConfig" class="swiper2">
        <ng-template swiperSlide *ngFor="let section of sections">
          <div
            class="package-container bztt-items-center bztt-justify-between bztt-flex bztt-flex-row bztt-px-12"
          >
            <div class="text-container bztt-flex bztt-items-center bztt-h-full">
              <div
                class="bztt-h-full bztt-flex bztt-justify-center bztt-flex-col"
              >
                <h2>{{ section.header | translate }}</h2>
                <p class="bztt-mb-5">{{ section.text | translate }}</p>
                <div>
                  <div class="subtext" *ngFor="let subtext of section.subtexts">
                    <mat-icon>check_circle</mat-icon>{{ subtext | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="video-container">
              <img
                class="bztt-object-contain bztt-max-h-[350px]"
                [src]="
                  'assets/images/landing/homepage-uplift/packages/' +
                  (section.image | translate) +
                  '.png'
                "
                alt="section-image"
              />
            </div>
          </div>
        </ng-template>
      </swiper>
      <div
        class="bztt-mt-5 bztt-flex bztt-flex-row bztt-justify-start bztt-gap-6"
      >
        <div
          class="slide-counter desktop bztt-flex bztt-flex-row bztt-justify-start bztt-gap-[5px]"
        >
          <div class="current-slide">
            {{ swiper?.swiperRef.realIndex + 1 | number: '2.0' }}
          </div>
          <div class="total-slides">
            / {{ (swiper?.activeSlides | async)?.length | number: '2.0' }}
          </div>
        </div>
        <div
          class="navigation-buttons bztt-flex bztt-flex-row bztt-justify-start bztt-gap-x-2 bztt-items-center"
        >
          <button
            class="swiper-button-prev bztt-static bztt-mt-0 after:bztt-content-none"
            [class.disabled]="swiper?.swiperRef?.isBeginning"
            mat-fab
            (click)="swipePrev()"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>

          <div
            class="slide-counter mobile bztt-flex bztt-flex-row bztt-justify-start bztt-gap-[5px]"
          >
            <div class="current-slide">
              {{ swiper?.swiperRef.realIndex + 1 | number: '2.0' }}
            </div>
            <div class="total-slides">
              / {{ (swiper?.activeSlides | async)?.length | number: '2.0' }}
            </div>
          </div>

          <button
            class="swiper-button-next bztt-static bztt-mt-0 after:bztt-content-none"
            [class.disabled]="swiper?.swiperRef?.isEnd"
            mat-fab
            (click)="swipeNext()"
          >
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
