import { Pipe, PipeTransform } from '@angular/core';
import { CalendarMonthViewDay } from 'angular-calendar';
import { isEqual, isWithinRange, isBefore, setHours, setMinutes } from 'date-fns';


@Pipe({
    name: 'CalendarClasses',
    pure: true
})

export class CalendarClassesPipe implements PipeTransform {
    transform(day: CalendarMonthViewDay): string {
        let classes = ''
        if (day.events.length) {
            if (day.events.some(e => e.title === 'booked' && e.allDay)) {
                classes += ' disabled';
            } else {
                if (day.events.some(e => e.title === 'discount')) {
                    classes += ' discount';
                }

                if (day.events.some(e => e.title === 'markup')) {
                    classes += ' markup';
                }

                const currentSelectedPeriodEvent = day.events.find(event => event.title === 'selectedPeriod');
                if (currentSelectedPeriodEvent) {

                    if (isEqual(currentSelectedPeriodEvent.start, currentSelectedPeriodEvent.end)) {
                        classes += ' period';
                    } else {
                        if (isEqual(currentSelectedPeriodEvent.start, day.date)) {
                            classes += ' periodStart';
                        }
                        if (isEqual(currentSelectedPeriodEvent.end, day.date)) {
                            classes += ' periodEnd';
                        }
                    }

                }
            }
        }

        return classes;
    }
}