<a class='menu-item-wrapper' [class.only-icon]="onlyIcon" [routerLink]="link ? link : null" [routerLinkActive]="link ? 'route-active' : ''"
    (click)="openDropdown($event)">
    <div class='icon'>
        <div class='notif-indicator' *ngIf="notifications | async as notifCount">
            {{notifCount}}
        </div>

        <ng-container *ngIf="imageLink != null; else iconBlock">
            <img slot="icon" [src]="imageLink" alt="Profile picture" class="profile-image" />
        </ng-container>

        <ng-template #iconBlock>
            <mat-icon>
                {{iconName}}
            </mat-icon>
        </ng-template>
    </div>
    <div class='text' *ngIf="!onlyIcon">
        <span>
            <ng-content></ng-content>
        </span>
        <mat-icon *ngIf="dropdown">
            arrow_drop_down
        </mat-icon>
    </div>
</a>
