import { Component, Input } from '@angular/core';

@Component({
  selector: 'jarvis-plans-preview',
  templateUrl: './plans-preview.component.html',
  styleUrls: ['./plans-preview.component.scss']
})
export class PlansPreviewComponent {

  Object = Object;
  @Input() membershipsData;

  constructor() { }
}
