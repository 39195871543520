import { sections } from './../../saas-landing.constants';

import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth';
import {
  DOMAIN_COUNTRY,
  LinkUrls,
  LINK_URLS,
  JarvisTrackingService,
} from '@jarvis/services';
import { LayoutService } from '@jarvis/ui';
import { Subject, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'jarvis-saas-landing-main',
  templateUrl: './saas-landing-main.component.html',
  styleUrls: [
    './saas-landing-main.component.scss',
    '../../saas-landing.component.scss',
  ],
})
export class SaasLandingMainComponent implements OnInit, OnDestroy {
  private trackService = inject(JarvisTrackingService);

  window = window;

  marketplaceLandingLink = `${this.linkUrls.marketplace}/event-hosts`;

  isMobile = false;
  loggedIn$ = this.authService.isLoggedIn$;
  private destroy$ = new Subject<void>();

  titleConfigurationOptions = {
    photographers: {
      title: 'Take your photography business to the next level',
      topImage: 'saas-landing-top-img.png',
    },
  };

  titleConfiguration = {
    title: 'landing.saas.title',
    topImage: 'saas-landing-top2.png',
  };

  sections = sections;

  endorsmentConfig = [
    {
      image: 'auders.jpg',
      label: 'Auders Photography',
      // eslint-disable-next-line max-len
      description:
        'Since I started using Breezit, I rarely get generic questions about my pricing and availability. This makes the booking process efficient and allows me to have more meaningful discussions with my clients.',
    },
    {
      image: 'big-bam-jam.jpg',
      label: 'Lukas, Big Bam Jam',
      // eslint-disable-next-line max-len
      description:
        'Breezit makes the booking process for my services seamless. Previously I had to send generic information, and have multiple email exchanges regarding invoices, contracts, etc. With Breezit, bookings are done in minutes.',
    },
  ];

  functionsConfig = [
    {
      icon: 'ic_create_account.svg',
      color: '#e5e7f8',
      label: 'Create an account',
    },
    {
      icon: 'ic_set_up_your_listing.svg',
      color: '#fdeae3',
      label: 'Set up your listing',
    },
    {
      icon: 'ic_update_your_calendar.svg',
      color: '#fff3e0',
      label: 'Update your calendar',
    },
    {
      icon: 'ic_start_taking_bookings.svg',
      color: '#ebebeb',
      label: 'Start taking bookings',
    },
  ];

  compareNegatives = [
    'landing.saas.marketing.item1',
    'landing.saas.marketing.item2',
    'landing.saas.marketing.item3',
    'landing.saas.marketing.item4',
    'landing.saas.marketing.item5',
  ];

  comparePositives: string[];

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private authService: JarvisAuthService,
    @Inject(LINK_URLS) private linkUrls: LinkUrls,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string
  ) {
    const comparePositiveItems = [
      'landing.saas.profile.item1',
      'landing.saas.profile.item2',
      'landing.saas.profile.item3',
      'landing.saas.profile.item4',
    ];

    if (this.domainCountry === 'us') {
      comparePositiveItems.push(
        ...['landing.saas.profile.item5', 'landing.saas.profile.item6']
      );
    }

    if (this.domainCountry === 'lt') {
      this.titleConfiguration.topImage = 'saas-landing-top-lt-img.png';
    }

    this.comparePositives = comparePositiveItems;
  }

  ngOnInit(): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'page_load',
      data: {
        page_type: 'saas_landing',
      },
    });

    // const subPathUrl = this.route.snapshot.url[0];
    // if (subPathUrl && this.titleConfigurationOptions[subPathUrl.path]) {
    //   this.titleConfiguration = this.titleConfigurationOptions[subPathUrl.path];
    // }

    this.layoutService.isMobileObserver
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => (this.isMobile = isMobile));

    setTimeout(() => window.scrollTo({ top: 0 }), 200);
  }

  marketplaceRedirrect() {
    window.location.href = this.linkUrls.marketplace;
  }

  saasRedirrect() {
    window.location.href = this.linkUrls.saas;
  }

  get vendorSignupLink(): Observable<any> {
    return this.authService.userData$.pipe(
      take(1),
      map((userData) => {
        if (userData) {
          return {
            link: this.getFirstPageNavigationUrl(),
          };
        }

        const signUpLink =
          this.domainCountry === 'lt' ? '/registracija' : '/sign-up';

        return {
          link: signUpLink,
        };
      })
    );
  }

  private getFirstPageNavigationUrl(): string {
    return './' + this.linkUrls.saas;
  }

  handleVendorSignup(location: string) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'create_profile_click',
      data: { location },
    });

    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.saasRedirrect();
        return;
      }
      // window.location.replace(window.location.origin + '/sign-up?source=' + location);
      const signUpLink =
        this.domainCountry === 'lt' ? 'registracija' : 'sign-up';

      this.router.navigate([signUpLink], {
        queryParams: { source: location },
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
