<marketplace-messages-chatrooms-filters [hidden]="messagingEnv?.app =='admin'"
></marketplace-messages-chatrooms-filters>

<div class="md:bztt-pb-3 bztt-h-full">
<div #scrollRoot class="chatrooms md:bztt-rounded-lg md:bztt-border">
    <div *ngIf="chatrooms$ | async as chatrooms; else initialLoadingPlaceholders" class="chatrooms-scroll-container">
        <div class='chatroom-wrapper bztt-rounded-lg' [class.selected]="chatroom._id === (selectedChatroomId$ | async)"
            *ngFor="let chatroom of chatrooms" (click)="selectChat(chatroom)">
            <div class='profile-photo'>
                <img imageError="/assets/images/noUser.jpeg" [src]="chatroom.users?.profilePhoto || '/assets/images/noUser.jpeg'" />
            </div>
            <div class="user-info bztt-text-[#6B6060] bztt-text-sm">
                <div class="user-name" *ngIf="vendorType === 'vendor'">{{chatroom | ChatUserName}}</div>
                <div class="user-name" *ngIf="vendorType === 'user'">{{chatroom.serviceBase | ChatListingName}}</div>
                <div class="last-read " [class.unread]="!(chatroom | ReadByUser:currentUserId)">
                    <span class="bztt-text-[#959595] bztt-text-xs">{{ chatroom.updatedAt | TimePassed }}</span>
                    <div class="new-message-indicator"></div>
                </div>
            </div>
            <ng-container *ngIf="chatroom.messages?.[(chatroom.messages?.length || 0) - 1]?.sender !== currentUserId" [ngSwitch]="!(chatroom | ReadByUser : currentUserId)">
              <div [matTooltip]="'messages.markAsRead' | translate" [matTooltipDisabled]="isMobile$ | async" *ngSwitchCase="true" (click)="markChatroomAsRead(chatroom._id, $event)" class="mark-as-read-container">
                <mat-icon class="color=">drafts</mat-icon>
              </div>
              <div [matTooltip]="'messages.markAsUnread' | translate" [matTooltipDisabled]="isMobile$ | async" *ngSwitchCase="false" (click)="markChatroomAsUnread(chatroom._id, $event)" class="mark-as-read-container">
                <mat-icon class="color=">mail</mat-icon>
              </div>
            </ng-container>
        </div>

        <ng-container [ngSwitch]="searchMessage$ | async">
          <marketplace-messages-chatrooms-information
            *ngSwitchCase="'empty'"
            icon="search"
            [message]="vendorType === 'user' ? 'messages.startSearchUser' : 'messages.startSearchVendor'"
          ></marketplace-messages-chatrooms-information>

          <marketplace-messages-chatrooms-information
            *ngSwitchCase="'not-found'"
            icon="search"
            message="messages.searchNoResults"
          ></marketplace-messages-chatrooms-information>

          <marketplace-messages-chatrooms-information
            *ngSwitchCase="'no-unread'"
            icon="search"
            message="messages.noUnread"
          ></marketplace-messages-chatrooms-information>

          <marketplace-messages-chatrooms-information
            *ngSwitchCase="'error'"
            icon="clear"
            message="messages.searchError"
          ></marketplace-messages-chatrooms-information>
        </ng-container>

        <!-- <div class="bztt-flex bztt-items-center bztt-flex-col bztt-justify-center bztt-text-gray-500">
          <mat-icon class="bztt-text-[5rem] bztt-h-[5rem] bztt-w-[5rem] bztt-opacity-50">search</mat-icon>
          <p class="bztt-text-center bztt-font-montserrat-r">
            Please enter a search term or select a service to find a relevant chats
          </p>
        </div> -->
        <!-- <div class="bztt-flex bztt-items-center bztt-flex-col bztt-justify-center bztt-text-gray-500">
          <mat-icon class="bztt-text-[5rem] bztt-h-[5rem] bztt-w-[5rem] bztt-opacity-50">search</mat-icon>
          <p class="bztt-text-center bztt-font-montserrat-r">
            No results found. Please check your filters and try again.
          </p>
        </div> -->

        <div #additionalChatroomsLoading *ngIf="showLoadingPlaceholder$ | async">
          <ng-container *ngTemplateOutlet="additionalLoadingPlaceholders"></ng-container>
        </div>
    </div>
</div>
</div>

<ng-template #initialLoadingPlaceholders>
  <ng-container  *ngFor="let _ of [null, null, null, null, null, null]">
    <ng-container *ngTemplateOutlet="loadingPlaceholder"></ng-container>
  </ng-container>
</ng-template>

<ng-template #additionalLoadingPlaceholders>
  <ng-container  *ngFor="let _ of [null, null]">
    <ng-container *ngTemplateOutlet="loadingPlaceholder"></ng-container>
  </ng-container>
</ng-template>

<ng-template #loadingPlaceholder>
  <div class='chatroom-wrapper bztt-rounded-lg bztt-pointer-events-none'>
    <div class='profile-photo bztt-animate-pulse bztt-bg-gray-200'></div>
    <div class="user-info">
        <div class="bztt-animate-pulse bztt-rounded-xl bztt-h-4 bztt-w-[40%]  bztt-bg-gray-200"></div>
        <div class="bztt-animate-pulse bztt-rounded-xl bztt-h-4 bztt-w-[90%] bztt-bg-gray-200"></div>
    </div>
  </div>
</ng-template>