import { Component, inject, Inject, OnInit, ViewChild } from '@angular/core';
import {UntypedFormArray,UntypedFormBuilder,UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { combineLatest } from 'rxjs';
import { ReviewsService } from '../services/reviews.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { DOMAIN_COUNTRY, LinkUrls, LINK_URLS } from '@jarvis/services';
import { CommonDialogService } from '@jarvis/ui';

@Component({
  selector: 'jarvis-reviews-ask-for-review',
  templateUrl: './ask-for-review.component.html',
  styleUrls: ['./ask-for-review.component.scss']
})
export class ReviewsAskForReviewComponent implements OnInit {

  @ViewChild('copiedTooltip', { read: MatTooltip }) tooltip: MatTooltip;

  contactForms = new UntypedFormArray([]);
  isSuccess = false;
  
  private serviceBaseId: string;
  private inviteLink: string = null;
  domainCountry = inject(DOMAIN_COUNTRY);
  
  constructor(
    private dialogRef: MatDialogRef<ReviewsAskForReviewComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private formBuilder:UntypedFormBuilder,
    private reviewsService: ReviewsService,
    private commonDialogService: CommonDialogService,
    private clipboard: Clipboard,
    @Inject(LINK_URLS) private linkUrls: LinkUrls
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.contactForms.push(this.createContactsForm());
    this.serviceBaseId = this.dialogData.serviceBaseId || null;


    this.reviewsService.generateInviteLink(this.serviceBaseId, {}).subscribe((data: any) => {
      const link = `${this.linkUrls.marketplace}/${this.domainCountry === 'lt' ? 'palikti-atsiliepima' : 'review'}/${data.hash}`;
      this.inviteLink = link;      
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.contactForms.invalid) {
      this.contactForms.markAllAsTouched();
      return;
    }

    const requests = [];

    this.contactForms.value.forEach((formData: any) => {
      requests.push(
        this.reviewsService.generateInviteLink(this.serviceBaseId, formData)
      );
    });

    combineLatest(requests).subscribe(data => {
      this.isSuccess = true;
    });

  }

  generateLinkToClipboard(): void {
    if (this.inviteLink) {
      this.clipboard.copy(this.inviteLink);
      this.commonDialogService.openSuccess("reviews.inviteCopyLinkMsg");
      this.tooltip.show();
      setTimeout(() => {
        this.tooltip.hide();
      }, 3000);
    }
  }

  addForm(): void {
    this.contactForms.push(this.createContactsForm());
  }

  removeForm(index: number): void {
    this.contactForms.removeAt(index);
  }

  createContactsForm():UntypedFormGroup {
    return this.formBuilder.group({
      name: [null, Validators.required],
      surname: [null, Validators.required],
      phoneNo: null,
      email: [null, [Validators.required, Validators.email]],
    });
  }

}
