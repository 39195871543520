import { AbstractControl } from '@angular/forms';

// Test reactive form debugging tool
const DEBUG_FIELD_STATE = (control: AbstractControl) => {
    const disableFn = control.disable;
    const enableFn = control.enable;

    control.disable = function (args) {
        console.log('Disabling...');
        disableFn.call(this, args);
    };

    control.enable = function (args) {
        console.log('Enabling...');
        enableFn.call(this, args);
    };

    const restoreOriginal = () => {
        control.disable = disableFn;
        control.enable = enableFn;
    };

    console.log('Debug registered to field...');

    return restoreOriginal;
};

// (window as any).ctlDebug = DEBUG_FIELD_STATE;
