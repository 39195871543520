<mat-sidenav-container #sidenav>
  <mat-sidenav
    role="navigation"
    position="end"
    fixedInViewport
    [class.topPosition]="headerTopPosition"
  >
    <nav>
      <div class="links">
        <a rel="noreferrer" class="menu-item-wrapper" (click)="openServicesOverlay()">
          <div class="icon">
            <ng-template>
              <mat-icon> celebrate </mat-icon>
            </ng-template>
          </div>
          <!-- <div class="text">
            {{ 'header.services' | translate }}
          </div> -->
        </a>
        <!-- For Mantas testing  style="display: none" -->
        <a

          class="menu-item-wrapper"
          (click)="openServicesOverlay()"
        >
          <div class="text">
            {{ 'header.services' | translate }}
          </div>
        </a>
        <a rel="noreferrer" [href]="'/service-providers'">
          {{ 'landing.homepage.areYouVendorHeader' | translate }}</a
        >
        <!-- <a
          class="link header-login"
          (click)="openLoginDialog()"
          style="text-decoration: none"
          >Log in</a
        > -->
      </div>
      <!-- <button
        (click)="openUserTypeSelector(); sidenav.close()"
        app-button
        color="accent"
        class="header-get-started"
      >
        Get started
      </button> -->
      <ng-template [ngTemplateOutlet]="profileAuthSelector"></ng-template>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <main>
      <div class="main-container">
        <!-- <div
          #intersection
          class="landing-image"

        ></div> -->
        <img src="/assets/images/Breezit_Favicon.png" alt="Homepage hero main image" class="bztt-hidden">
        <div
          class="landing-image"
          [ngStyle]="{
            backgroundImage:
              'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) ), url(/assets/images/landing/homepage/homepage-top-alvaro-3.jpg)'
          }"
          #intersection
        >
          <cite class="bztt-text-gray-400 bztt-text-xs"
            >Photo by Álvaro CvG</cite
          >
        </div>
        <div class="content bztt-pb-12 md:bztt-pb-24">
          <div class="form-container bztt-shadow-md">
            <div class="form-first-container bztt-w-full">
              <div class="header bztt-mb-3">
                <h1>{{ 'landing.homepage.planYourEvent' | translate }}</h1>
              </div>

              <ng-content *ngTemplateOutlet="weddingDateSelector"></ng-content>
            </div>

            <mat-divider class="bztt-hidden md:bztt-block"></mat-divider>

            <div class="description bztt-mt-7 md:bztt-mt-5">
              <ng-content *ngTemplateOutlet="ratingElement"></ng-content>
            </div>
          </div>
        </div>
      </div>
      <!-- <section class="reviews-endorsments">
        <div class="img-shadow">
          <img
            class="bztt-w-full"
            src="\assets\images\landing\wedding-date-landing.jpg"
            alt=""
          />
        </div>
      </section> -->

      <section class="bztt-max-w-none md:bztt-w-full bztt-mb-32">
        <div
          class="bztt-flex bztt-flex-row bztt-justify-center bztt-w-full bztt-relative"
        >
          <h2 class="bztt-text-center bztt-mb-12">
            {{ 'landing.homepage.serviceProviders' | translate }}
          </h2>

          <!-- <div class="bztt-absolute bztt-right-0 bztt-mt-2">
            <button mat-icon-button (click)="providersArrowClickHandler()">
              <mat-icon>arrow_back</mat-icon>
            </button>
            <button
              class="bztt-ml-2.5"
              mat-icon-button
              (click)="providersArrowClickHandler()"
            >
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div> -->
        </div>
        <div class="services-container bztt-w-full md:bztt-max-w-[1370px]">
          <jarvis-ui-marketplace-card
            class="md:bztt-max-w-[324px] bztt-min-w-[291px] md:bztt-min-w-[unset]"
            *ngFor="let service of servicesToShow"
            [images]="service.images"
            [liked]="service.likeId"
            (likeEvent)="serviceLikeHandler($event, service)"
            [data]="service"
            pageType="homepage"
            cardClickEventType="event-host-homepage"
            [isMobile]="isMobile"
          ></jarvis-ui-marketplace-card>
          <div
            *ngIf="services.length"
            class="see-more-card md:bztt-max-w-[324px] bztt-min-w-[291px] md:bztt-min-w-[unset] bztt-w-full bztt-flex bztt-items-center bztt-justify-center bztt-cursor-pointer"
            (click)="handleShowMore()"
            matRipple
          >
            <div
              class="bztt-flex bztt-flex-col bztt-gap-y-3 bztt-items-center bztt-font-semibold"
            >
              <div
                class="see-more-button bztt-h-20 bztt-w-20 bztt-rounded-full bztt-flex bztt-items-center bztt-justify-center"
              >
                <mat-icon>arrow_forward</mat-icon>
              </div>
              {{ 'landing.homepage.seeMore' | translate }}
            </div>
          </div>
        </div>
      </section>

      <ng-template
        [ngTemplateOutlet]="orangeContainer"
        [ngTemplateOutletContext]="{
          title: 'landing.homepage.orangeContainer-1.title',
          description: 'landing.homepage.orangeContainer-1.description'
        }"
      ></ng-template>

      <section class="sections-container bztt-gap-y-16 bztt-w-full">
        <h2 class="bztt-text-center bztt-justify-center">
          {{ 'landing.homepage.sections.title' | translate }}
        </h2>

        <ng-container *ngFor="let section of sections; let i = index">
          <div [id]="section.id" class="bztt-justify-between">
            <div class="text-container">
              <h2>{{ section.header | translate }}</h2>
              <p>{{ section.text | translate }}</p>
              <div>
                <div class="subtext" *ngFor="let subtext of section.subtexts">
                  <mat-icon>check_circle</mat-icon>{{ subtext | translate }}
                </div>
              </div>
              <a rel="noreferrer" [routerLink]="marketplaceRouterLink.link">
                <div
                  [ngClass]="section.id + ' learn-more mt-4'"
                  (click)="scrollTop()"
                >
                  {{ 'landing.homepage.more' | translate }}
                </div></a
              >
              <!-- <div class="link" (click)="()">
                <a>Start now</a>
                <mat-icon>arrow_forward</mat-icon>
              </div> -->
            </div>
            <div class="video-container bztt-h-[342px]">
              <img
                [src]="section.image"
                alt="section-image"
                class="bztt-object-contain"
              />
            </div>
          </div>
        </ng-container>
      </section>

      <section class="sections-container bztt-gap-y-16 bztt-w-full">
        <h2 class="bztt-text-center bztt-justify-center">
          {{ 'landing.homepage.getAllDone.title' | translate }}
        </h2>

        <div
          class="bztt-w-full bztt-flex bztt-justify-between bztt-flex-col md:bztt-flex-row"
        >
          <div
            *ngFor="let item of getAllDoneItems"
            class="bztt-flex bztt-flex-col bztt-gap-y-5 bztt-justify-center bztt-items-center bztt-pb-5"
          >
            <img
              [src]="item.image"
              [alt]="item.label"
              class="bztt-w-36 bztt-ml-6"
            />
            <p>{{ item.label | translate }}</p>
          </div>
        </div>
      </section>

      <ng-template
        [ngTemplateOutlet]="orangeContainer"
        [ngTemplateOutletContext]="{
          title: 'landing.homepage.orangeContainer-2.title',
          description: 'landing.homepage.orangeContainer-2.description'
        }"
      ></ng-template>

      <section class="reviews-endorsments">
        <h2 #endorsmentcount>
          {{ 'landing.homepage.endorsments.title' | translate }}
        </h2>

        <landings-reviews-slide
          [config]="endorsmentConfig"
        ></landings-reviews-slide>
      </section>

      <section
        class="section-fullwidth bztt-bg-[#fdeae3] bztt-justify-center let-breezit-container md:bztt-p-20 bztt-p-8 bztt-px-3"
      >
        <!-- <landings-lets-breezit-section
          [type]="'user'"
        ></landings-lets-breezit-section> -->
        <div
          class="bztt-flex xl:bztt-flex-row bztt-gap-x-4 bztt-w-full bztt-flex-col bztt-gap-y-4"
        >
          <div
            class="bztt-w-full bztt-flex bztt-flex-col xl:bztt-flex-row bztt-justify-between bztt-items-center bztt-bg-[white] bztt-rounded-xl bztt-p-3 xl:bztt-p-8 bztt-gap-x-4 bztt-gap-y-4"
          >
            <mat-form-field
              (click)="openDatePicker()"
              #dateAnchor
              class="bztt-w-full bztt-min-w-[320px]"
            >
              <mat-label>{{
                'landing.homepage.weddingDate.whenPlanning' | translate
              }}</mat-label>
              <input
                readonly
                dateMask
                matInput
                [formControl]="homepageService.eventDateControl"
              />
              <mat-icon matSuffix>date_range</mat-icon>
            </mat-form-field>
            <a
              [routerLink]="marketplaceRouterLink.link"
              [queryParams]="marketplaceRouterLink.queryParams"
              [state]="marketplaceRouterLink.state"
              class="bztt-w-full bztt-min-w-[150px] bztt-max-w-[600px] bztt-no-underline"
            >
              <button app-button color="accent" (click)="scrollTop()">
                {{ 'landing.homepage.weddingDate.search' | translate }}
              </button>
            </a>

            <a
              [routerLink]="marketplaceRouterLink.link"
              [state]="marketplaceRouterLink.state"
            >
              <div
                class="dont-have-date bztt-w-[30rem]"
                (click)="dontHaveDateHandler()"
              >
                {{ 'landing.homepage.weddingDate.dontHaveDate' | translate }}
              </div>
            </a>
          </div>
          <div
            class="bztt-rounded-lg bztt-bg-[white] bztt-p-6 bztt-min-w-[200px] xl:bztt-max-w-[300px]"
          >
            <jarvis-reviews-review-stars
              class="bztt-mb-3 xl:bztt-justify-start bztt-justify-center"
              [value]="5"
            ></jarvis-reviews-review-stars>
            <div class="bztt-w-full">
              <div
                class="xl:bztt-text-left bztt-text-center"
                style="font-size: 14px"
              >
                {{ 'landing.homepage.carefullySelectedVendors' | translate }}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="bztt-justify-center let-breezit-container md:bztt-py-20 bztt-p-10 bztt-px-6 bztt-mb-0 bztt-max-w-[600px] bztt-w-full"
      >
        <div class="bztt-font-semibold bztt-pb-6 bztt-text-lg">
          {{ 'landing.homepage.areYouVendor' | translate }}
        </div>
        <a
          [routerLink]="(vendorSignupLink | async).link"
          [queryParams]="{ source: 'homepage-footer' }"
          class="bztt-w-full bztt-min-w-[150px] bztt-max-w-[600px] bztt-no-underline"
        >
          <button
            app-button
            (click)="handleVendorSignup('homepage-footer', true)"
            class="accent-outline vendor-signup-button gaVendorCTA-homepage-footer"
          >
            <div>{{ 'landing.homepage.createProfile' | translate }}</div>
          </button>
        </a>
      </section>
    </main>

    <!-- <landings-landing-footer></landings-landing-footer> -->
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #orangeContainer let-title="title" let-description="description">
  <section class="our-approach sections-container">
    <div class="filled"></div>
    <div class="border"></div>
    <div class="approach-container bztt-px-4 md:bztt-x-7 bztt-pt-14">
      <div
        class="approach-content bztt-flex bztt-flex-col bztt-gap-x-7 bztt-justify-center bztt-max-w-2xl bztt-text-center"
      >
        <h2 class="label bztt-mb-5 bztt-justify-center">
          {{ title | translate }}
        </h2>
        <p class="description bztt-text-center bztt-whitespace-pre-line">
          {{ description | translate }}
        </p>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #landingHeader>
  <div
    class="landing-header"
    [class.topPosition]="headerTopPosition"
    [class.lowerPosition]="!headerTopPosition"
  >
    <div class="logo bztt-cursor-pointer" (click)="scrollTop(true)">
      <img
        class="logo-image"
        alt="logo"
        src="assets/images/bzt-logo.svg"
        [class.whiteFilter]="headerTopPosition"
      />
    </div>
    <div
      (click)="sidenav.open()"
      class="mobile-button-container"
      *ngIf="isMobile"
    >
      <mat-icon>menu</mat-icon>
    </div>
    <div class="provider-button-container" *ngIf="!isMobile">
      <ul class="section-navigation-list">
        <!-- <li (click)="navigateToSection('faq')">FAQ</li> -->

        <li>
          <!--style="display: none"-->
          <a
            class="menu-item-wrapper"
            (click)="openServicesOverlay()"
          >
            <div class="icon">
              <ng-template>
                <mat-icon> celebrate </mat-icon>
              </ng-template>
            </div>
            <!-- <div class="text">
              {{ 'header.services' | translate }}
            </div> -->
          </a>
        </li>
        <li>
          <!-- For Mantas testing style="display: none" routerLink="/photographer" style="display: none" -->
          <a
            class="menu-item-wrapper bztt-no-underline"
            (click)="openServicesOverlay()"

          >
            <div class="text">
              {{ 'header.services' | translate }}
            </div>
          </a>
        </li>
        <li>
          <a rel="noreferrer" [href]="'/service-providers'" style="text-decoration: none">
            {{ 'landing.homepage.areYouVendorHeader' | translate }}
          </a>
        </li>
        <!-- <li (click)="openLoginDialog()" class="header-login">Log in</li> -->
      </ul>
      <!-- <a rel="noreferrer" href="https://breezit.com"></a> -->
      <!-- <button
        (click)="openUserTypeSelector()"
        app-button
        class="accent-outline header-get-started"
      >
        <span>Get started</span>
      </button> -->
      <ng-template [ngTemplateOutlet]="profileAuthSelector"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #smallCalendarOverlay>
  <div
    class="calendar-container mat-elevation-z4 p-2 pt-3 bztt-w-full bztt-h-full"
  >
    <jarvis-ui-small-calendar
      [events]="[]"
      [date]="homepageService.eventDateControl.value"
      (daySelected)="handleWeddingDateSelect($event)"
    ></jarvis-ui-small-calendar>
  </div>
</ng-template>

<ng-template #weddingDateSelector>
  <mat-form-field
    (click)="openDatePicker(true)"
    #dateAnchor2
    class="bztt-w-full"
  >
    <mat-label>{{
      'landing.homepage.weddingDate.whenPlanning' | translate
    }}</mat-label>
    <input
      readonly
      dateMask
      matInput
      [formControl]="homepageService.eventDateControl"
    />
    <mat-icon matSuffix>date_range</mat-icon>
  </mat-form-field>

  <a
    [routerLink]="marketplaceRouterLink.link"
    [queryParams]="marketplaceRouterLink.queryParams"
    [state]="marketplaceRouterLink.state"
    class="bztt-w-full bztt-min-w-[150px] bztt-max-w-[600px] bztt-no-underline"
  >
    <button app-button color="accent" (click)="scrollTop()">
      {{ 'landing.homepage.weddingDate.search' | translate }}
    </button>
  </a>

  <a
    [routerLink]="marketplaceRouterLink.link"
    [state]="marketplaceRouterLink.state"
  >
    <div class="dont-have-date" (click)="dontHaveDateHandler()">
      {{ 'landing.homepage.weddingDate.dontHaveDate' | translate }}
    </div>
  </a>
</ng-template>

<ng-template #ratingElement>
  <!-- <jarvis-reviews-review-stars
    class="bztt-mb-3 bztt-justify-center"
    [value]="5"
  ></jarvis-reviews-review-stars> -->
  <div
    class="bztt-w-full bztt-flex bztt-flex-col bztt-justify-center bztt-px-4 md:bztt-px-12"
  >
    <!-- <div style="font-size: 14px; width: 216px">
      Carefully selected quality vendors for your special occasion
    </div> -->
    <div class="bztt-font-semibold bztt-pb-4">
      {{ 'landing.homepage.areYouVendor' | translate }}
    </div>
    <a
      [routerLink]="(vendorSignupLink | async).link"
      [queryParams]="{ source: 'homepage-hero' }"
      class="bztt-w-full bztt-min-w-[150px] bztt-max-w-[600px] bztt-no-underline"
    >
      <button
        app-button
        (click)="handleVendorSignup('homepage-hero', true)"
        class="accent-outline vendor-signup-button gaVendorCTA-homepage-hero bztt-mb-[26px]"
      >
        <div>{{ 'landing.homepage.createProfile' | translate }}</div>
      </button>
    </a>
  </div>
</ng-template>

<ng-template #profileAuthSelector>
  <div class="profile" #profile [matMenuTriggerFor]="headerAuthMenu">
    <img [src]="'assets/images/noUser.jpeg'" />
    <mat-icon>expand_more</mat-icon>
  </div>
  <mat-menu
    yPosition="below"
    xPosition="before"
    class="landing-auth-menu"
    #headerAuthMenu="matMenu"
  >
    <div class="dropdown-wrapper mat-elevation-z10">
      <div
        class="auth-menu-item gaSignup"
        mat-menu-item
        (click)="handleHeaderAuth('Signup')"
      >
        {{ 'landing.homepage.signup' | translate }}
      </div>
      <div
        class="auth-menu-item gaLogin"
        mat-menu-item
        (click)="handleHeaderAuth('Login')"
      >
        {{ 'landing.homepage.login' | translate }}
      </div>
    </div>
  </mat-menu>
</ng-template>

<ng-template #profileAuthSelector>
  <div class="profile" #profile [matMenuTriggerFor]="headerAuthMenu">
    <img [src]="'assets/images/noUser.jpeg'" />
    <mat-icon>expand_more</mat-icon>
  </div>
  <mat-menu
    yPosition="below"
    xPosition="before"
    class="landing-auth-menu"
    #headerAuthMenu="matMenu"
  >
    <div class="dropdown-wrapper mat-elevation-z10">
      <div
        class="auth-menu-item gaSignup"
        mat-menu-item
        (click)="handleHeaderAuth('Signup')"
      >
        {{ 'landing.homepage.signup' | translate }}
      </div>
      <div
        class="auth-menu-item gaLogin"
        mat-menu-item
        (click)="handleHeaderAuth('Login')"
      >
        {{ 'landing.homepage.login' | translate }}
      </div>
    </div>
  </mat-menu>
</ng-template>
