import { Subject, takeUntil } from 'rxjs';
import { LayoutService } from '@jarvis/ui/layout';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { LINK_URLS, LinkUrls } from '@jarvis/services/tokens';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'landings-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss'],
  standalone: true,
  imports: [TranslateModule, CommonModule],
})
export class LandingFooterComponent implements OnInit, OnDestroy {
  saasLandingLink = `${this.linkUrls.marketplace}`;
  marketplaceLandingLink = `${this.linkUrls.marketplace}`;
  footerUpperMargin$ = this.layoutService.footerUpperMargin$;
  hideNearMe$ = this.layoutService.hideNearMe$;

  currentYear = new Date().getFullYear();

  data: any[];

  nearMeLinks = [
    [
      {
        text: 'landing.footer.nearMe.venues',
        link: this.linkUrls.marketplace + '/venues/near-me',
        show: true,
      },
      {
        text: 'landing.footer.nearMe.weddingVenues',
        link: this.linkUrls.marketplace + '/wedding-venues/near-me',
        show: true,
      },
      {
        text: 'landing.footer.nearMe.musician',
        link: this.linkUrls.marketplace + '/live-music/near-me',
        show: true,
      },
      {
        text: 'landing.footer.nearMe.dj',
        link: this.linkUrls.marketplace + '/djs/near-me',
        show: true,
      },
    ],
    [
      {
        text: 'landing.footer.nearMe.photographers',
        link: this.linkUrls.marketplace + '/photographers/near-me',
        show: true,
      },
      {
        text: 'landing.footer.nearMe.weddingPhotographers',
        link: this.linkUrls.marketplace + '/wedding-photographers/near-me',
        show: true,
      },
      {
        text: 'landing.footer.nearMe.videographers',
        link: this.linkUrls.marketplace + '/videographers/near-me',
        show: true,
      },
    ],
    [
      {
        text: 'landing.footer.nearMe.planners',
        link: this.linkUrls.marketplace + '/planners/near-me',
        show: true,
      },
      {
        text: 'landing.footer.nearMe.weddingPlanners',
        link: this.linkUrls.marketplace + '/wedding-planners/near-me',
        show: true,
      },
      {
        text: 'landing.footer.nearMe.florists',
        link: this.linkUrls.marketplace + '/florists/near-me',
        show: true,
      },
    ],
  ];

  popularLinks = [];

  isMobile = false;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(LINK_URLS) private linkUrls: LinkUrls,
    public layoutService: LayoutService,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string
  ) {
    const buttonsUS = [
      {
        title: 'landing.footer.nearMe.weddingVenues',
        titleLink: this.linkUrls.marketplace + '/wedding-venues/near-me',
        links: [
          {
            text: 'landing.footer.nearMe.southernCalifornia',
            link:
              this.linkUrls.marketplace + '/wedding-venues/southern-california',
            show: true,
          },
          {
            text: 'landing.footer.nearMe.northernCalifornia',
            link:
              this.linkUrls.marketplace + '/wedding-venues/northern-california',
            show: true,
          },
          {
            text: 'landing.footer.nearMe.affordable',
            link: this.linkUrls.marketplace + '/wedding-venues/affordable',
            show: true,
          },
          {
            text: 'landing.footer.nearMe.allInclusive',
            link: this.linkUrls.marketplace + '/wedding-venues/all-inclusive',
            show: true,
          },
          {
            text: 'landing.footer.nearMe.waterfront',
            link: this.linkUrls.marketplace + '/wedding-venues/waterfront',
            show: true,
          },
          {
            text: 'landing.footer.nearMe.orangeCounty',
            link: this.linkUrls.marketplace + '/wedding-venues/orange-county',
            show: true,
          },
          {
            text: 'landing.footer.nearMe.affordableLosAngeles',
            link:
              this.linkUrls.marketplace +
              '/wedding-venues/affordable/los-angeles',
            show: true,
          },
        ],
      },
      {
        title: 'landing.footer.group1.title',
        links: [
          {
            text: 'landing.footer.group1.link1',
            link: this.linkUrls.marketplace + '/service-providers',
            show: true,
          },
          {
            text: 'landing.footer.group1.link2',
            link: this.linkUrls.marketplace,
            show: true,
          },
          {
            text: 'landing.footer.group1.link3',
            link:
              this.linkUrls.marketplace + '/free-dream-wedding-venue-finder',
            show: true,
          },
          // { text: 'Pricing', link: '/', show: true },
        ],
      },
      {
        title: 'landing.footer.group2.title',
        links: [
          {
            text: 'landing.footer.group2.link1',
            link: '/rules-policies/rules-of-use',
            show: true,
          },
          {
            text: 'landing.footer.group2.link2',
            link: '/rules-policies/privacy-policy',
            show: true,
          },
        ],
      },
      // {
      //   title: 'Resources',
      //   links: [
      //     { text: 'Help Center', link: '/', show: true },
      //     { text: 'FAQ', link: '/', show: true },
      //     { text: 'Blog', link: '/', show: true },
      //   ]
      // },
      // {
      //   title: 'Company',
      //   links: [
      //     { text: 'About us', link: '/', show: true },
      //     { text: 'Jobs', link: '/', show: true },
      //     { text: 'Contact us', link: '/', show: true },
      //     { text: 'Terms of Service', link: '/', show: true },
      //     { text: 'Privacy Policy', link: '/', show: true },
      //   ]
      // }
    ];

    const buttonsLT = [
      {
        title: 'landing.footer.popularCategories.title',
        links: [
          {
            text: 'landing.footer.popularCategories.weddingVenues',
            link: this.linkUrls.marketplace + '/vestuviu-vietos',
            show: true,
          },
          {
            text: 'landing.footer.popularCategories.businessVenues',
            link: this.linkUrls.marketplace + '/vietos-imones-renginiams',
            show: true,
          },
          {
            text: 'landing.footer.popularCategories.privateVenues',
            link: this.linkUrls.marketplace + '/asmeniniu-svenciu-vietos',
            show: true,
          },
        ],
      },
      {
        title: 'landing.footer.group1.title',
        links: [
          {
            text: 'landing.footer.group1.link1',
            link: this.linkUrls.marketplace + '/teikejams',
            show: true,
          },
          {
            text: 'landing.footer.group1.link2',
            link: this.linkUrls.marketplace,
            show: true,
          },
          // { text: 'Pricing', link: '/', show: true },
        ],
      },
      {
        title: 'landing.footer.group2.title',
        links: [
          //   { text: 'landing.footer.group2.link0', link: 'https://foxywedding.lt/auth/login', show: true, target:'_blank' },
          {
            text: 'landing.footer.group2.link1',
            link: '/taisykles/paslaugu-teikimo',
            show: true,
          },
          {
            text: 'landing.footer.group2.link2',
            link: '/taisykles/privatumo-politika',
            show: true,
          },
          {
            text: 'landing.footer.group2.link3',
            link: '/finansuoja-europos-sajunga',
            show: true,
          },
        ],
      },
    ];

    this.data = this.domainCountry === 'lt' ? buttonsLT : buttonsUS;
  }

  ngOnInit(): void {
    this.layoutService.isMobileObserver
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
