
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  StaticProvider,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import {
  BASE_URL,
  DOMAIN_COUNTRY,
  extractServicesFromAdvert,
  getServicePrices,
  JarvisTrackingService,
} from '@jarvis/services';
import { JarvisServiceLikesService } from '@jarvis/services/likes';
import { HomepageUpliftedService } from '../../services/homepage-uplifted.service';
import { JarvisUiModalService, LayoutService } from '@jarvis/ui';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  map,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import {
  CATEGORIES_V2_LT,
  CATEGORIES_V2_US,
  ICON_DATA,
} from '@jarvis/ui/marketplace-header/src/lib/data-access/service-type.data';
import { FormBuilder } from '@angular/forms';
import { HOMEPAGE_CATEGORIES } from '../../services/homepage-uplifted.constants';
import { JarvisAuthService } from '@jarvis/auth';
import {
  GOOGLE_LOCATION_FILTER_FORM_CONTROL,
  GOOGLE_LOCATION_FILTER_OVERLAY_REF,
  LOCATION_FILTER_CONFIGURATION,
} from '@jarvis/ui/google-location-dropdown';
import { UiGoogleLocationDropdownComponent } from '@jarvis/ui/google-location-dropdown';
import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Router } from '@angular/router';

@Component({
  selector: 'jarvis-homepage-listing-list',
  templateUrl: './listing-list.component.html',
  styleUrls: ['./listing-list.component.scss'],
})
export class HomepageListingListComponent implements OnInit {
  @ViewChild('overlayOrigin') overlayOrigin: ElementRef<HTMLDivElement>;

  private country = inject(DOMAIN_COUNTRY);
  private likesService = inject(JarvisServiceLikesService);
  private homepageService = inject(HomepageUpliftedService);
  private layoutService = inject(LayoutService);
  private fb = inject(FormBuilder);
  private authService = inject(JarvisAuthService);
  private injector = inject(Injector);
  private modalService = inject(JarvisUiModalService);
  private vcr = inject(ViewContainerRef);
  private overlay = inject(Overlay);
  private router = inject(Router);
  private trackService = inject(JarvisTrackingService);

  ICON_DATA = ICON_DATA;

  services = [];
  servicesToShow = [];

  seeMoreStep = 0;

  eventType = 'wedding';

  titleSelectorOptions = {
    lt: {
      wedding: 'landing.homepageUplift.hero.wedding',
      privateEvent: 'landing.homepageUplift.hero.celebration',
      businessEvent: 'landing.homepageUplift.hero.businessEvent',
    },
    lt_old: [
      {
        label: 'landing.homepageUplift.hero.wedding',
        value: 'wedding',
      },
      {
        label: 'landing.homepageUplift.hero.celebration',
        value: 'privateEvent',
      },
      {
        label: 'landing.homepageUplift.hero.businessEvent',
        value: 'businessEvent',
      },
    ],
    us: HOMEPAGE_CATEGORIES.us,
  };

  get isLt() {
    return this.country === 'lt';
  }

  get locationSelectionValue() {
    return (
      this.homepageService.eventLocationControl.value || {
        name: 'California',
        slug: 'california',
      }
    );
  }

  isMobile = this.layoutService.isMobileObserver;

  dynamicCardParam$: Observable<any> = new BehaviorSubject([]).asObservable();

  linkList = HOMEPAGE_CATEGORIES.lt;

  activeCategory =
    this.country === 'lt' && !this.layoutService.isMobile
      ? this.randomCategory
      : 'venues';
  activeCategoryIndex = 0;

  destroy$ = new Subject<void>();

  titleTypeSelection = this.fb.control(
    this.country === 'lt' ? this.randomType : 'venues'
  );

  get ltTitleOptions() {
    return Object.keys(this.titleSelectorOptions[this.country]);
  }

  get randomType() {
    return this.ltTitleOptions[
      Math.floor(Math.random() * this.ltTitleOptions.length)
    ];
  }

  get randomCategory() {
    return this.linkList[Math.floor(Math.random() * this.linkList.length)]
      .category;
  }

  constructor() {}

  ngOnInit(): void {
    this.initHomepageServices();

    this.titleTypeSelection.valueChanges.subscribe((value) => {
      this.eventType = value;
      this.initHomepageServices();
    });
  }

  compareTitleOptions(a, b) {
    if (a && b) {
      a === b;
    }
  }

  initHomepageServices() {
    this.homepageService.eventLocationControl.valueChanges
      .pipe(
        startWith(this.homepageService.eventLocationControl.value),
        switchMap((location) => {
          return combineLatest([
            this.homepageService.getHomepageServices(
              this.eventType,
              this.country,
              this.activeCategory,
              location
            ),
            this.authService.isLoggedIn$.pipe(take(1)),
          ]).pipe(
            map(([res, loggedIn]) => {
              return res.map((v: any) => {
                v.likeId = loggedIn
                  ? v.liked?.[0]?._id
                  : this.likesService.isLocalLiked(v._id)
                  ? v._id
                  : null;

                return {
                  ...v,
                  ...this.calculatePrice(v, v.serviceBase),
                };
              });
            })
          );
        })
      )
      .subscribe((services) => {
        this.services = services;
        this.servicesToShow = this.services.slice(0, 7);
      });
  }

  calculatePrice(serviceEvent: any, serviceBase: any) {
    const extracted = extractServicesFromAdvert({
      base: serviceBase,
      event: serviceEvent['wedding'],
    });

    const servicePrices = getServicePrices(extracted, 1, new Date());
    const firstPrice =
      servicePrices.first?.service?.originalService?.description;
    const defaultPrice = {
      description: {
        hoursIncluded:
          firstPrice?.priceUnit == 'hour'
            ? firstPrice?.minimalBudget
            : firstPrice?.hoursIncluded,
      },
    };
    return {
      price: servicePrices.first.price,
      innerPrices: servicePrices.first,
      defaultPrice,
    };
  }

  handleCategoryChange(category, i) {
    if (category === this.activeCategory) return;
    this.scrollContainer(i < this.activeCategoryIndex);
    this.activeCategory = category;
    this.activeCategoryIndex = i;
    this.initHomepageServices();
  }

  async serviceLikeHandler(service) {
    if (!service.likeId) {
      this.likesService
        .saveLike(
          service._id,
          null,
          service.slug,
          null,
          service.extra?.prioritised
        )
        .subscribe((likeResponse) => {
          service.likeId = likeResponse._id;
        });
      return;
    }

    this.likesService.removeLike(service.likeId, service._id).subscribe(() => {
      service.likeId = null;
    });
  }

  handleShowMore() {
    if (this.seeMoreStep) {
      this.trackService.handleEvent({
        trackers: ['amplitude', 'mixpanel'],
        eventName: 'see_more_2_click',
      });
      return this.navigateToMarketplace();
    }

    this.servicesToShow = this.services;
    this.seeMoreStep = 1;
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'see_more_1_click',
    });
    return null;
  }

  scrollContainer(goback = false) {
    const container = document.querySelector('#categoryScrollContainer');
    container.scrollTo({
      top: 0,
      left: goback ? container.scrollLeft - 150 : container.scrollLeft + 150,
      behavior: 'smooth',
    });
  }

  navigateToMarketplace() {
    if (this.country === 'us') {
      const locationSlug = this.locationSelectionValue.slug || '';

      const queryParams = {};

      if (!locationSlug) {
        const locationData = this.locationSelectionValue;

        if (locationData && locationData.key) {
          queryParams['locationKey'] = locationData.key;
        }
      }

      return this.router.navigate(['/wedding-venues/' + locationSlug], {
        queryParams,
        state: {
          fromHomePage: true,
        },
      });
    }

    const link = this.linkList.find(
      (v) => v.category === this.activeCategory
    ).link;
    return this.router.navigate([link]);
  }

  handleLocationOverlay() {
    const configuration = { hideSearch: true };

    if (this.layoutService.isMobile) {
      const modalProviders: StaticProvider[] = [
        {
          provide: GOOGLE_LOCATION_FILTER_FORM_CONTROL,
          useValue: this.homepageService.eventLocationControl,
        },
        {
          provide: LOCATION_FILTER_CONFIGURATION,
          useValue: configuration,
        },
      ];

      const modalInjector = Injector.create({
        providers: modalProviders,
        parent: this.injector,
      });

      const ref = this.modalService.openDialog(
        UiGoogleLocationDropdownComponent,
        {
          viewContainerRef: this.vcr,
          injector: modalInjector,
        }
      );

      //   ref.componentInstance.currentSelectedValue =
      //     this.filterDisplayValueControl.value;

      return;
    }

    const overlayPositions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
    ];

    const overlaySettings: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      minWidth: this.overlayOrigin.nativeElement.clientWidth,
      maxHeight: 400,
      minHeight: 200,
      panelClass: 'homepage-listings-list-selector',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.overlayOrigin)
        // .withPush(false)
        // .withGrowAfterOpen
        .withViewportMargin(20)
        .withPositions(overlayPositions)
        .withDefaultOffsetY(20),
    };

    const overlayRef = this.overlay.create(overlaySettings);

    const overlayProviders: StaticProvider[] = [
      {
        provide: GOOGLE_LOCATION_FILTER_OVERLAY_REF,
        useValue: overlayRef,
      },
      {
        provide: GOOGLE_LOCATION_FILTER_FORM_CONTROL,
        useValue: this.homepageService.eventLocationControl,
      },
      {
        provide: LOCATION_FILTER_CONFIGURATION,
        useValue: configuration,
      },
    ];

    const overlayInjector = Injector.create({
      providers: overlayProviders,
      parent: this.injector,
    });

    const componentPortal = new ComponentPortal(
      UiGoogleLocationDropdownComponent,
      this.vcr,
      overlayInjector
    );

    const ref = overlayRef.attach(componentPortal);

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.detach();
    });
  }
}
