<div class="icon-wrapper prefix">
    <ng-content select="[prefix]"></ng-content>
</div>

<div class="app-button-content mat-button-wrapper content" [class.accent]="color === 'accent' || color === 'primary'">
    <ng-content></ng-content>
</div>

<div class="icon-wrapper suffix">
    <ng-content select="[suffix]"></ng-content>
</div>

<span matRipple class="mat-button-ripple" [class.mat-button-ripple-round]="isRoundButton || isIconButton"
    [matRippleDisabled]="_isRippleDisabled()" [matRippleCentered]="isIconButton"
    [matRippleTrigger]="_getHostElement()"></span>

<span class="mat-button-focus-overlay"></span>