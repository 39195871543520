import {
  ElementRef,
  Injectable,
  Injector,
  StaticProvider,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { TemplatePortal, ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class JarvisHeaderService {
  private headerPortalSource$ = new BehaviorSubject<any>(null);
  headerPortal$ = this.headerPortalSource$.asObservable();
  headerRef: ElementRef;

  private defaultHeader: Type<unknown> = null;

  constructor() {}

  setHeaderTemplate(
    template: TemplateRef<any> | null,
    viewContainerRef: ViewContainerRef
  ): void {
    if (!template) {
      this.headerPortalSource$.next(null);
    }

    const templatePortal = new TemplatePortal(template, viewContainerRef);
    this.headerPortalSource$.next(templatePortal);
  }

  setHeaderComponent(
    component: Type<unknown>,
    providers: StaticProvider[] = []
  ): void {
    const injector = Injector.create({
      providers,
    });

    const componentPortal = new ComponentPortal(component, null, injector);
    this.headerPortalSource$.next(componentPortal);
  }

  setSticky(sticky = false): void {}

  hideHeader(): void {
    this.headerPortalSource$.next(null);
  }

  setDefaultHeader(defaultHeaderComponent?: Type<unknown>): void {
    if (defaultHeaderComponent) {
      this.setHeaderComponent(defaultHeaderComponent);
      this.defaultHeader = defaultHeaderComponent;
      return;
    }

    if (this.defaultHeader) {
      this.setHeaderComponent(this.defaultHeader);
      return;
    }

    this.headerPortalSource$.next(null);
  }
}
