import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'

@Component({template:`<div></div>`}
          )

export class CalendarGoogleAuth implements OnInit {
   
   constructor (private activatedRoute: ActivatedRoute,
                private router: Router,
                private httpService: HttpClient
                ) {}

   ngOnInit () {
      const code =  this.activatedRoute.snapshot.queryParams.code;
      const state =  JSON.parse(this.activatedRoute.snapshot.queryParams.state);
      this.httpService.get(`${environment.baseUrl}api/calendar/approveGoogleCalendarIntegration?code=${code}&redirect_uri=${environment.saasUrl+"/calendar/google-auth"}&profileId=${state.profileId}`).subscribe(
       v=>         
        { 
           this.router.navigateByUrl(state.url, { state: { connectCalendar: 'google' }} );
        } 
      );
   }

}