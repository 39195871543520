import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LayoutService } from './layout.service';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    constructor(
        private dialog: MatDialog,
        private layoutService: LayoutService
    ) { }

    openDialog<T>(component: ComponentType<T>, config?: MatDialogConfig): MatDialogRef<T> {
        const mobileConfig: MatDialogConfig = {
            ...config,
            /* height: '100%',
            minHeight: '100vh',
            maxWidth: 'none' */
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%'
        };

        const isMobile = this.layoutService.isMobile;

        return this.dialog.open(component, isMobile ? mobileConfig : config);
    }

}
