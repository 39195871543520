import { Pipe, PipeTransform } from '@angular/core';
import { ServiceBase } from '@jarvis/types';

@Pipe({
    name: 'ServiceName',
    pure: true
})

export class ServiceNamePipe implements PipeTransform {
    transform(serviceBase: ServiceBase, serviceEventId: string): string {
        if (serviceBase.type === 'venues') {
            const areasEnabled = serviceBase.venues.separateAreas;
            
            if (areasEnabled) {
                const area = serviceBase.venues.areas?.find(area => area.serviceId === serviceEventId);
                return area ? `${serviceBase.brandName}: ${area.name}` : serviceBase.brandName;
            }
        }

        return serviceBase.brandName;
    }
}