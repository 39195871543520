import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DOMAIN_COUNTRY } from '@jarvis/services';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  Observable,
  debounceTime,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  LocationSuggestionResult,
  ResultObject,
} from './services/google-location-filter.types';
import { GoogleMapsLocationFilterAutocompleteService } from './services/autocomplete.service';
import {
  GOOGLE_LOCATION_FILTER_FORM_CONTROL,
  GOOGLE_LOCATION_FILTER_OVERLAY_REF,
  LOCATION_FILTER_CONFIGURATION,
} from './services/provider-tokens';

import PlaceResult = google.maps.places.PlaceResult;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { JarvisLocationService } from './services/location.service';

@Component({
  selector: 'jarvis-ui-google-location-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [GoogleMapsLocationFilterAutocompleteService],
  templateUrl: './ui-google-location-dropdown.component.html',
  styleUrls: ['./ui-google-location-dropdown.component.scss'],
})
export class UiGoogleLocationDropdownComponent implements OnInit {
  country = inject(DOMAIN_COUNTRY);
  locationService = inject(JarvisLocationService);

  suggestionData: any[] | null = null;

  isModal = false;

  currentSelectedValue: string | null = null;

  autocompleteControl = new FormControl('');

  suggestionValue$: Observable<any[] | null> | null = null;

  private overlayRef = inject(GOOGLE_LOCATION_FILTER_OVERLAY_REF, {
    optional: true,
  });

  private modalRef = inject<MatDialogRef<UiGoogleLocationDropdownComponent>>(
    MatDialogRef,
    { optional: true }
  );

  configuration = inject(LOCATION_FILTER_CONFIGURATION, {
    optional: true,
  });

  private filterControl = inject(GOOGLE_LOCATION_FILTER_FORM_CONTROL);

  private autocompleteService = inject(
    GoogleMapsLocationFilterAutocompleteService
  );

  get showSearch() {
    return !this.configuration?.hideSearch;
  }

  ngOnInit() {
    if (this.modalRef) {
      this.isModal = true;
    }

    this.suggestionValue$ = this.autocompleteControl.valueChanges.pipe(
      debounceTime(300),
      startWith(this.autocompleteControl.value),
      switchMap((input) => {
        if (!input) {
          return of(this.locationService.locations);
        }

        return this.autocompleteService.getGoogleSuggestions(input).pipe(
          map((data) => {
            if (!data) {
              return data as null;
            }

            return data.map((result) => {
              const displayValue =
                result.terms
                  ?.map((term: any) => term.value)
                  .slice(0, result.terms.length - 1)
                  .join(', ') || result.description;

              return {
                displayValue,
                placeId: result.place_id,
              };
            });
          })
        );
      }),
      shareReplay(1)
    );
  }

  handleSuggestion(suggestion: LocationSuggestionResult) {
    if (suggestion.placeId) {
      this.autocompleteService
        .getPlaceResult(suggestion.placeId)
        .subscribe((result) => {
          this.autocompleteSelected(result, suggestion.displayValue);
        });
      return;
    }

    this.setPremade(suggestion);
  }

  clearFilter(): void {
    this.filterControl.reset();
    this.close();
  }

  autocompleteSelected(event: PlaceResult, displayValue?: string) {
    if (!event?.address_components) {
      return;
    }

    const addressToShow = displayValue || event.formatted_address;

    const resultObj: ResultObject = {
      distance: 240000,
      latitude: event.geometry?.location?.lat() || 0,
      longitude: event.geometry?.location?.lng() || 0,
    };

    if (this.country === 'lt') {
      resultObj.distance = 120000;
    }

    const components = event.address_components;

    const area1Result = this.findByComponentType(
      'administrative_area_level_1',
      components
    );
    if (area1Result) {
      resultObj.area1 = area1Result.long_name;
    }

    const area2Result = this.findByComponentType(
      'administrative_area_level_2',
      components
    );
    if (area2Result) {
      resultObj.area2 = area2Result.long_name;
    }

    const localityResult = this.findByComponentType('locality', components);
    if (localityResult) {
      resultObj.city = localityResult.long_name;
    }

    if (Object.keys(resultObj).length < 1) {
      return;
    }

    this.filterControl.setValue({
      displayValue: addressToShow,
      data: resultObj,
    });

    this.close();
  }

  setPremade(suggestion: any) {
    this.filterControl.setValue(suggestion);
    this.close();
  }

  close() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      return;
    }

    this.modalRef?.close();
  }

  private findByComponentType(
    type: string,
    components: GeocoderAddressComponent[]
  ) {
    return components.find((component) => component.types.includes(type));
  }
}
