export function snakeCaseToKebabCase(input: string): string {
    const split = input.split('_');
    return split.join('-');
}

export function kebabCaseToSnakeCase(input: string): string {
    const split = input.split('-');
    return split.join('_');
}

export function kebabCaseToCamelCase(input: string): string {
    if (!input || typeof input !== 'string') {
      return null;
    }

    const split = input.split('-');
    const capitalizedSplits = split.map((splitVal, index) => {
        if (index > 0) {
            return splitVal.charAt(0).toUpperCase() + splitVal.slice(1);
        }
        return splitVal;
    });
    return capitalizedSplits.join('');
}

export function camelCaseToKebabCase(input: string): string {
  if (!input) {
    return null;
  }
  
  return input.split('').map((letter, index) => {
    return letter.toUpperCase() === letter ? `${index !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter;
  }).join('');
}

export function camelCaseize(str) {
    return str?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

export function firstUpperCase(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str
}


export function camelCaseToNormalText(str: string) {
    if (!str) {
      return null;
    }
    // insert a space before all caps
    return str.replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function (str) { return str.toUpperCase(); })
}

export function snakeCaseToCamelCase(input: string): string {
    return input.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );
}
