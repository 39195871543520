/* eslint-disable indent */
/* eslint-disable max-len */
import { format } from 'date-fns';
import * as enLocale from 'date-fns/locale/en';

import {
  ExtractedServices,
  ExtractorPathConfigs,
  AdvertsPriceUnits,
  ServiceDescriptor,
  MarkupDiscountActions,
  ServiceTypes,
  AdvertTypes,
  ServiceInfo
} from './pricing-calculator.types';

import { venuesPaths, cateringPaths, othersPaths } from './path.config';

/**
 * Extracts concrete service from an adverts data model.
 * Note - data model is a combination of one service base and one service event.
 * @param serviceInfo
 */
export function extractServicesFromAdvert(
  serviceInfo: ServiceInfo
): ExtractedServices {
  const isOther = !(
    serviceInfo.base.type === AdvertTypes.venues ||
    serviceInfo.base.type === AdvertTypes.catering
  );
  const advertType: AdvertTypes = isOther
    ? AdvertTypes.others
    : serviceInfo.base.type;

  const serviceCharge =
    serviceInfo.event.charges?.serviceCharge?.priceValue || 0;
  const serviceChargeGlobal =
    serviceInfo.event.charges?.serviceCharge?.type === 'global';
  const serviceChargeType = serviceInfo.event.charges?.serviceCharge?.type;

  const globalMinimalBudget =
    serviceInfo.event.charges?.globalMinimalBudget?.priceValue || 0;
  const globalMinimalBudgetType =
    serviceInfo.event.charges?.globalMinimalBudget?.type;
  const globalMinimalBudgetTax =
    serviceInfo.event.charges?.globalMinimalBudget?.tax || 0;

  let extractorConfig: ExtractorPathConfigs;

  switch (advertType) {
    case AdvertTypes.venues:
      extractorConfig = venuesPaths;
      break;

    case AdvertTypes.catering:
      extractorConfig = cateringPaths;
      break;

    case AdvertTypes.others:
      extractorConfig = othersPaths;
      break;

    default:
      extractorConfig = {
        main: [],
        additional: []
      };
      break;
  }

  if (!extractorConfig) {
    throw new Error('No valid config for this service data model found');
  }

  const extractedMainServices: AdvertServiceVariant[] = [];
  const extractedAdditionalServices: AdvertServiceVariant[] = [];

  extractorConfig.main.forEach((config) => {
    const enabled = config.enabled
      ? accessObject(
          config.enabled.location + '.' + config.enabled.path,
          serviceInfo
        )
      : true;
    if (!enabled) {
      return;
    }
    const serviceObj = accessObject(
      config.location.location + '.' + config.location.path,
      serviceInfo
    );
    const finalConfig = {
      ...config,
      serviceCharge,
      serviceChargeGlobal,
      globalMinimalBudgetType
    };
    const extracted = parseServiceVariant(serviceInfo, finalConfig, serviceObj);
    extractedMainServices.push(...extracted);
  });

  extractorConfig.additional.forEach((config) => {
    const enabled = config.enabled
      ? accessObject(
          config.enabled.location + '.' + config.enabled.path,
          serviceInfo
        )
      : true;
    if (!enabled) {
      return;
    }
    const serviceObj = accessObject(
      config.location.location + '.' + config.location.path,
      serviceInfo
    );
    const finalConfig = {
      ...config,
      serviceCharge,
      serviceChargeGlobal,
      globalMinimalBudgetType
    };
    const extracted = parseServiceVariant(serviceInfo, finalConfig, serviceObj);
    extractedAdditionalServices.push(...extracted);
  });

  if (serviceInfo.event.charges?.globalMinimalBudget?.enabled) {
    const offSeasonMonths = [];
    const excludedMonthsObj =
      serviceInfo.event.seasonalPricingExcludedMonths || {};
    Object.keys(excludedMonthsObj).forEach((month) => {
      if (excludedMonthsObj[month]) {
        offSeasonMonths.push(month);
      }
    });

    const globalMinimalBudgetService = new AdvertServiceVariant(
      {
        priceValue: globalMinimalBudget,
        priceUnit: AdvertsPriceUnits.fixedFee,
        type: globalMinimalBudgetType,
        tax: globalMinimalBudgetTax,
        weeklyPricing:
          serviceInfo.event.charges.globalMinimalBudget.weeklyPricing,
        seasonalPricing:
          serviceInfo.event.charges.globalMinimalBudget.seasonalPricing,
        pricingModifiers: serviceInfo.event.charges.globalMinimalBudget.pricingModifiers,
        id: null,
        name: 'booking.defaultDescriptions.globalMinimalBudget.name',
        description:
          'booking.defaultDescriptions.globalMinimalBudget.description'
      },
      {
        serviceType: ServiceTypes.globalMinimalBudget,
        serviceCharge,
        pricingModifiers: {
          weeklyPricingEnabled: serviceInfo.event.weeklyPricingEnabled || false,
          seasonalPricingEnabled:
            serviceInfo.event.seasonalPricingEnabled || false
        },
        offSeasonMonths,
        globalMinimalBudgetType
      }
    );

    globalMinimalBudgetService.setAlwaysIncluded(true);
    extractedAdditionalServices.push(globalMinimalBudgetService);
  }

  // Venues service charge
  if (advertType === AdvertTypes.venues) {
    if (serviceInfo.event.charges?.serviceCharge?.enabled) {
      const serviceChargeService = new AdvertServiceVariant(
        {
          priceValue: serviceCharge,
          priceUnit: AdvertsPriceUnits.fixedPercentage,
          type: serviceChargeType,
          id: null,
          name: 'booking.defaultDescriptions.serviceCharge.name',
          description: 'booking.defaultDescriptions.serviceCharge.description'
        },
        {
          serviceType: ServiceTypes.serviceCharge,
          serviceChargeType
        }
      );

      serviceChargeService.setAlwaysIncluded(true);
      extractedAdditionalServices.push(serviceChargeService);
    }
  }

  return {
    main: extractedMainServices,
    additional: extractedAdditionalServices
  };
}

const accessObject = (path, object) => {
  try {
    return path.split('.').reduce((o, i) => o[i] || null, object);
  } catch (e) {
    return null;
  }
};

function parseServiceVariant(serviceInfo, config, serviceInQuestion) {
  const extracted: AdvertServiceVariant[] = [];
  if (!serviceInQuestion) {
    return [];
  }

  const offSeasonMonths = [];
  const excludedMonthsObj =
    serviceInfo.event.seasonalPricingExcludedMonths || {};
  Object.keys(excludedMonthsObj).forEach((month) => {
    if (excludedMonthsObj[month]) {
      offSeasonMonths.push(month);
    }
  });

  let serviceCharge = 0;
  let excludedFromGlobalMinBudget = config.globalMinimalBudgetType === 'global' ? true : false;

  if (
    config.serviceChargeGlobal ||
    [
      ServiceTypes.venueCateringMenuMain,
      ServiceTypes.venueCateringMenuAdditional,
      ServiceTypes.venueCateringMenuBeverages,
      ServiceTypes.venueCateringMenuChildren,
      ServiceTypes.venueCateringMenuBeveragesChildren,
      ServiceTypes.cateringDessert,
      ServiceTypes.cateringKidsMenu,
      ServiceTypes.cateringTasting,
      ServiceTypes.venueSeparate
    ].includes(config.type)
  ) {
    serviceCharge = config.serviceCharge;
  }

  if (
    config.globalMinimalBudgetType === 'global' ||
    [
      ServiceTypes.venueCateringMenuMain,
      ServiceTypes.venueCateringMenuAdditional,
      ServiceTypes.venueCateringMenuBeverages,
      ServiceTypes.venueCateringMenuChildren,
      ServiceTypes.venueCateringMenuBeveragesChildren,
      ServiceTypes.cateringDessert,
      ServiceTypes.cateringKidsMenu,
      ServiceTypes.cateringTasting
    ].includes(config.type)
  ) {
    if (config.globalMinimalBudgetType === 'catering') {
      excludedFromGlobalMinBudget = true;
    }
  }

  const serviceConfig: ServiceVariantConfig = {
    pricingModifiers: {
      weeklyPricingEnabled: serviceInfo.event.weeklyPricingEnabled || false,
      seasonalPricingEnabled: serviceInfo.event.seasonalPricingEnabled || false,
      markupDiscountEnabled: serviceInfo.event.markupDiscountEnabled || false
    },
    offSeasonMonths,
    defaultName: config.name || '',
    defaultDescription: config.description || '',
    serviceType: config.type,
    serviceKey: config.key,
    serviceCharge,
    excludedFromGlobalMinBudget
  };

  if (Array.isArray(serviceInQuestion)) {
    serviceInQuestion.forEach((serviceInQuestionInfo) => {
      const maximums = extractMaximum(
        serviceInfo,
        config,
        serviceInQuestionInfo
      );
      const minimums = extractMinimum(
        serviceInfo,
        config,
        serviceInQuestionInfo
      );
      extracted.push(
        new AdvertServiceVariant(serviceInQuestionInfo, {
          ...serviceConfig,
          maximums,
          minimums
        })
      );
    });
  } else if (typeof serviceInQuestion === 'object') {
    if (serviceInQuestion.priceValue || serviceInQuestion.description) {
      const maximums = extractMaximum(serviceInfo, config, serviceInQuestion);
      const minimums = extractMinimum(serviceInfo, config, serviceInQuestion);
      extracted.push(
        new AdvertServiceVariant(serviceInQuestion, {
          ...serviceConfig,
          maximums,
          minimums
        })
      );
    } else {
      const services = Object.keys(serviceInQuestion);
      services.forEach((name) => {
        const serviceInQuestionRecursive = serviceInQuestion[name];
        const newConfig = {
          ...config,
          key: name
        };
        const extractedRecursive = parseServiceVariant(
          serviceInfo,
          newConfig,
          serviceInQuestionRecursive
        );
        extracted.push(...extractedRecursive);
      });
    }
  }

  return extracted;
}

const minimumEnabledPriceUnits = [
  // AdvertsPriceUnits.currencyPerPerson,
  AdvertsPriceUnits.currencyPerUnit,
  AdvertsPriceUnits.hourlyPrice,
  AdvertsPriceUnits.currencyPerHour,
  AdvertsPriceUnits.currencyPerWeight
];

const quantityDisabledPriceUnits = [
  AdvertsPriceUnits.fixedFee,
  AdvertsPriceUnits.priceFrom,
  AdvertsPriceUnits.priceTo,
  AdvertsPriceUnits.priceForWhole,
  // AdvertsPriceUnits.ratesStartAt,
  AdvertsPriceUnits.startingPrice,
  AdvertsPriceUnits.fixedPercentage
];

const fakeQuantityServiceTypes = [
  ServiceTypes.venueStandalone
];

/**
 * Minimums quantities are applied to:
 * Price per person (minimum person count)
 * Price per unit (minimum unit count)
 * Price per hour (minimum hour count)
 * Price per weight (minimum weight unit count)
 * @param serviceInfo
 * @param config
 * @param serviceInQuestion
 * @returns
 */
function extractMinimum(serviceInfo, config, serviceInQuestion): number {
  const priceUnit =
    serviceInQuestion.priceUnit || serviceInQuestion.description?.priceUnit;
  if (priceUnit && minimumEnabledPriceUnits.includes(priceUnit)) {
    return (
      serviceInQuestion.minimalBudget ||
      serviceInQuestion.description?.minimalBudget ||
      -1
    );
  }

  return -1;
}

/**
 * Extracts maximums according to business rules
 */
function extractMaximum(serviceInfo, config, serviceInQuestion) {
  if (config.type === ServiceTypes.venueAccomodation) {
    // TODO - can be included in non-selectable
    /* if (serviceInQuestion.priceUnit === AdvertsPriceUnits.priceForWhole) {
      return 1;
    } */

    if (serviceInQuestion.priceUnit === AdvertsPriceUnits.priceForDoubleRoom) {
      return (
        Math.ceil(+serviceInfo.base.venues?.maxAccomodationPeopleCount / 2) ||
        -1
      );
    }

    return +serviceInfo.base.venues?.maxAccomodationPeopleCount || -1;
  }

  if (
    config.type === ServiceTypes.venueCateringMenuMain ||
    config.type === ServiceTypes.venueCateringMenuAdditional ||
    config.type === ServiceTypes.venueCateringMenuBeverages  ||
    config.type === ServiceTypes.venueCateringMenuChildren  ||
    config.type === ServiceTypes.venueCateringMenuBeveragesChildren
  ) {
    if (serviceInQuestion.cateringType?.banquet) {
      return serviceInfo.serviceEvent?.maxBanquetPeopleCount || -1;
    } else if (serviceInQuestion.cateringType?.buffet) {
      return serviceInfo.serviceEvent?.maxBuffetPeopleCount || -1;
    }
  }

  if (config.type === ServiceTypes.venueStandalone) {
    if (
      serviceInQuestion.priceUnit === AdvertsPriceUnits.currencyPerPerson ||
      serviceInQuestion.priceUnit === AdvertsPriceUnits.fixedFee
    ) {
      return serviceInfo.serviceEvent?.maxBuffetPeopleCount || -1;
    }
  }

  // if (config.type === ServiceTypes.mainService && serviceInfo.base.type === 'photographer') {
  /* if (
        (
            serviceInQuestion.description?.priceUnit === AdvertsPriceUnits.hourlyPrice ||
            serviceInQuestion.description?.priceUnit === AdvertsPriceUnits.currencyPerHour
        ) &&
        serviceInfo.base.type !== 'catering'
    ) {
        // const mainAdditionalEnabled = serviceInfo.event.mainAdditional?.enabled;
        if (!serviceInQuestion.description.additionalPriceEnabled) {
            return serviceInQuestion.minimalBudget || serviceInQuestion.description?.minimalBudget || -1;
        }
    } */

  return -1;
}

interface ServiceVariantConfig {
  pricingModifiers?: any;
  defaultName?: string;
  defaultDescription?: string;
  offSeasonMonths?: string[];
  maximums?: any;
  minimums?: any;
  serviceType: ServiceTypes;
  serviceKey?: string;
  serviceCharge?: number;
  serviceChargeType?: string;
  globalMinimalBudgetType?: string;
  excludedFromGlobalMinBudget?: boolean;
}

interface PricingRule {
  priceValue: number;
  minimalBudget?: number;
}

export class AdvertServiceVariant {
  defaultPrice: number;
  minimalBudget: number;
  priceUnit: AdvertsPriceUnits;
  name: string;
  description: string;
  type: ServiceTypes;
  key: string;
  additionalUnitPrice: number;
  additionalPriceUnit: string;
  tax: number;
  serviceCharge: number;
  excludedFromGlobalMinBudget: boolean;
  serviceChargeType: string = null;
  globalMinimalBudgetType: string = null;
  additionalQuantityEnabled = false;

  maxQuantity = -1;
  minQuantity = -1;
  quantityStartAt = -1;
  minAdditionalQuantity = -1;
  quantityDisabled = false;

  private _included = false;
  private _quantity = 0;
  private _additionalQuantity = 0;
  private offSeasonMonths: string[];
  private _alwaysIncluded = false;
  private _priceChange = null;

  set quantity(value: number) {
    if (this.quantityDisabled) {
      return;
    }

    let finalQuantity = value;

    if (this.maxQuantity > -1 && value > this.maxQuantity) {
      finalQuantity = this.maxQuantity;
    }

    if (
      this.minQuantity > -1 &&
      value < this.minQuantity &&
      !this.quantityDisabled
    ) {
      if (value > this._quantity) {
        finalQuantity = this.minQuantity;
      } else {
        finalQuantity = 0;
      }
    }

    if (value < 0) {
      finalQuantity = 0;
    }

    if (finalQuantity < 1) {
      this.included = false;
    } else {
      this.included = true;
    }

    this._quantity = +finalQuantity;
  }

  get quantity() {
    return this._quantity;
  }

  set additionalQuantity(value: number) {
    if (!this.additionalQuantityEnabled) {
      return;
    }

    let finalQuantity = value;


    if (
      this.minAdditionalQuantity > -1 &&
      value < this.minAdditionalQuantity &&
      this.additionalQuantityEnabled
    ) {
      finalQuantity = this.minAdditionalQuantity;
      /* if (value > this._additionalQuantity) {
        finalQuantity = this.minAdditionalQuantity;
      } else {
        finalQuantity = 0;
      } */
    }

    if (value < 0) {
      finalQuantity = this.minAdditionalQuantity;
    }

    this._additionalQuantity = +finalQuantity;
  }

  get additionalQuantity() {
    return this._additionalQuantity;
  }

  set included(value: boolean) {
    if (this._alwaysIncluded) {
      this._included = true;
      return;
    }
    this._included = value;
  }

  get included(): boolean {
    if (this.customPrice !== null && this.customPrice === 0) {
      return false;
    }
    if (this._alwaysIncluded) {
      return true;
    }
    return this._included;
  }

  get isAlwaysIncluded(): boolean {
    return this._alwaysIncluded;
  }

  originalService;

  private weeklyPricingEnabled: boolean;
  private seasonalPricingEnabled: boolean;
  private markupDiscountEnabled: boolean;

  private weeklyPricing: any;
  private seasonalPricing: any;
  private markupDiscount: any;

  private priceBrackets: any;

  private parsedSeasons: any = {};
  private parsedWeeklySeason: any = {};

  customPrice: number = null;

  /* private markupDiscountPriceUnits = [
        AdvertsPriceUnits.currencyPerPerson,
        AdvertsPriceUnits.priceForDoubleRoom,
        AdvertsPriceUnits.priceForWhole
    ] */

  additionalFixedCostFn: () => number = () => 0;
  additionalUnitFixedCostFn: () => number = () => 0;

  constructor(service: ServiceDescriptor, config: ServiceVariantConfig) {
    // TODO: Make all service of one data model (vendor project)
    if (typeof service.description === 'object') {
      this.defaultPrice = +service.description.priceValue;
      this.name = service.description.name;
      this.description = service.description.description;
      this.priceUnit =
        service.description.priceUnit || AdvertsPriceUnits.currencyPerUnit;
      this.minimalBudget = +service.description.minimalBudget || 0;
      this.tax = +service.description.tax || 0;
    } else {
      this.defaultPrice = +service.priceValue;
      this.name = service.name || config.defaultName;
      this.description = service.description || config.defaultDescription;
      this.priceUnit = service.priceUnit || AdvertsPriceUnits.currencyPerUnit;
      this.minimalBudget = +service.minimalBudget || 0;
      this.tax = +service.tax || 0;
    }

    this.originalService = service;

    if (config.serviceChargeType) {
      this.serviceChargeType = config.serviceChargeType;
    }

    if (config.globalMinimalBudgetType) {
      this.globalMinimalBudgetType = config.globalMinimalBudgetType;
    }

    /* if (config.serviceType === ServiceTypes.venueCateringMenuMain) {
            this.quantity = 1;
        } */

    this.type = config?.serviceType;
    this.key = config?.serviceKey;

    this.serviceCharge = config.serviceCharge || 0;
    this.excludedFromGlobalMinBudget = config.excludedFromGlobalMinBudget || false;

    this.weeklyPricingEnabled =
      config?.pricingModifiers?.weeklyPricingEnabled || false;
    this.seasonalPricingEnabled =
      config?.pricingModifiers?.seasonalPricingEnabled || false;
    this.markupDiscountEnabled =
      config?.pricingModifiers?.markupDiscountEnabled || false;

    if (this.weeklyPricingEnabled)
      this.weeklyPricing = service.weeklyPricing || [];
    if (this.seasonalPricingEnabled)
      this.seasonalPricing = service.seasonalPricing || [];
    if (this.markupDiscountEnabled)
      this.markupDiscount = service.markupDiscount || [];

    this.maxQuantity = config.maximums || -1;
    this.minQuantity = config.minimums || -1;

    this.offSeasonMonths = config.offSeasonMonths;

    if (quantityDisabledPriceUnits.includes(this.priceUnit) && !fakeQuantityServiceTypes.includes(this.type)) {
      this._quantity = 1;
      this.quantityDisabled = true;
    }

    /* if (config.serviceType === ServiceTypes.venueSeparate) {
            this.setAlwaysIncluded(true);
        } */

    if (
      this.priceUnit === AdvertsPriceUnits.currencyPerHour ||
      this.priceUnit === AdvertsPriceUnits.hourlyPrice
    ) {
      const additionalHourCost = service.description?.additionalPriceValue;
      if (additionalHourCost) {
        this.additionalUnitPrice = +additionalHourCost;
      }
    }

    if (this.priceUnit === AdvertsPriceUnits.fixedPerPerson) {
      this.quantityStartAt = service.startQuantityAt || service.description?.startQuantityAt;
    }
    

    if (this.originalService.priceBrackets && this.originalService.priceBrackets.enabled) {
      this.priceBrackets = this.originalService.priceBrackets.brackets;
      this.defaultPrice = this.priceBrackets[0].priceValue;
      const lastBracket = this.priceBrackets[this.priceBrackets.length - 1];
      this.maxQuantity = lastBracket.personCount;
    }

    // TODO: Remove original service direct access
    if (
      this.priceUnit === AdvertsPriceUnits.pricePerHourPerPerson &&
      (this.originalService.description?.additionalPriceEnabled || this.originalService.additionalPriceEnabled)
    ) {
      this.additionalQuantityEnabled = true;
      this.minAdditionalQuantity = this.originalService.description?.hourCount || this.originalService.hourCount;
      this._additionalQuantity = this.originalService.description?.hourCount || this.originalService.hourCount;
    }

    /* if (this.seasonalPricing?.[0]?.version === '2') {
      this.parsedSeasons = {};
      this.seasonalPricing.forEach(yearPricing => {
        const parsedSeason = this.parseSeasonsByMonth(this.seasonalPricing, yearPricing.year);
        this.parsedSeasons[yearPricing.year] = parsedSeason;
      });
    } */

    /* if (this.weeklyPricing?.[0]?.version === '2') {
      this.parsedWeeklySeason = {};
      this.weeklyPricing.forEach(yearPricing => {
        this.parsedWeeklySeason[yearPricing.year] = yearPricing.seasons[0].pricing;
      });
    } */

    if (this.originalService.pricingModifiers) {
      this.originalService.pricingModifiers.forEach(pricingModfierSeason => {
        const isSeasonal = Array.isArray(pricingModfierSeason.seasons);

        const daysConverted = {};

        pricingModfierSeason.pricing.forEach((pricingData, index) => {
          Object.keys(pricingData?.rules || {}).forEach(weekday => {
            if (index === 0) {
              daysConverted[weekday] = [
                {
                  personCount: pricingData.personCount,
                  ...pricingData.rules[weekday]
                }
              ];
            } else {
              daysConverted[weekday].push(
                {
                  personCount: pricingData.personCount,
                  ...pricingData.rules[weekday]
                }
              );
            }
          });
        });

        if (isSeasonal) {
          if (!this.parsedSeasons[pricingModfierSeason.year]) {
            this.parsedSeasons[pricingModfierSeason.year] = {};
          }

          pricingModfierSeason.seasons.forEach(seasonMonthName => {
            this.parsedSeasons[pricingModfierSeason.year][seasonMonthName] = daysConverted;
          });
        }

        if (!isSeasonal) {
          if (!this.parsedWeeklySeason[pricingModfierSeason.year]) {
            this.parsedWeeklySeason[pricingModfierSeason.year] = {};
          }

          this.parsedWeeklySeason[pricingModfierSeason.year] = daysConverted;
        }
      });
    }
    
    if (Object.keys(this.parsedSeasons).length === 0) {
      this.parsedSeasons = null;
    }

    if (Object.keys(this.parsedWeeklySeason).length === 0) {
      this.parsedWeeklySeason = null;
    }
  }

  setAlwaysIncluded(value: boolean): void {
    if (value) {
      this._alwaysIncluded = true;
      return;
    }

    this._alwaysIncluded = false;
  }

  setPriceChange(priceChange) {
    this._priceChange = priceChange;
  }

  forceInclude(value = true): void {
    this._included = value;
  }

  /**
   * Get final unit price after all pricing modifications and discounts are applied
   * @param date - The date at which this service will be provided
   */
  getFinalUnitPrice(date?: Date): number {
    let defaultPrice = this.defaultPrice;

    if (this.priceBrackets) {
      const bracket = this.priceBrackets.find(bracket => this.quantity <= bracket.personCount);
      const lastBracket = this.priceBrackets[this.priceBrackets.length - 1];
      if (bracket) {
        defaultPrice = bracket.priceValue;
      }

      if (this.quantity > lastBracket.personCount) {
        defaultPrice = lastBracket.priceValue;
      }

    }

    // If we don't have a date to depend on, we cannot calculate the price, so we return the default one
    if (!date) {
      if (this.additionalQuantityEnabled && this._additionalQuantity > (this.originalService.description?.hourCount || this.originalService.hourCount)) {
        const hourDiff = this._additionalQuantity - (this.originalService.description?.hourCount || this.originalService.hourCount);
        const additionalPrice = (this.originalService.description?.additionalPriceValue || this.originalService.additionalPriceValue) * hourDiff;
        defaultPrice += additionalPrice;
      }

      return defaultPrice;
    }

    const dayName = format(date, 'dddd', { locale: enLocale }).toLowerCase();
    const year = format(date, 'YYYY', { locale: enLocale });
    const month = format(date, 'MMMM').toLowerCase();

    // Check if modifiers are enabled. If not, return original price.

    let modifiedPrice = defaultPrice;

    if (this.seasonalPricingEnabled && this.parsedSeasons && this.parsedSeasons?.[+year]?.[month]) {
      const dayPricing = this.parsedSeasons?.[+year]?.[month]?.[dayName];
      let seasonDayPrice = dayPricing?.[0]?.priceValue;
      if (this.priceBrackets && dayPricing) {
        const seasonBracket = dayPricing.find(pricing => this.quantity <= pricing.personCount);
        const lastBracket = dayPricing[dayPricing.length - 1];
        if (seasonBracket) {
          seasonDayPrice = seasonBracket.priceValue;
        }

        if (this.quantity > lastBracket.personCount) {
          seasonDayPrice = lastBracket.priceValue;
        }
      }
      modifiedPrice = seasonDayPrice || defaultPrice;
    } else if (this.weeklyPricingEnabled && this.parsedWeeklySeason) {
      const dayPricing = this.parsedWeeklySeason?.[+year]?.[dayName];
      let seasonDayPrice = dayPricing?.[0]?.priceValue;
      if (this.priceBrackets && dayPricing) {
        const seasonBracket = dayPricing.find(pricing => this.quantity <= pricing.personCount);
        const lastBracket = dayPricing[dayPricing.length - 1];
        if (seasonBracket) {
          seasonDayPrice = seasonBracket.priceValue;
        }

        if (this.quantity > lastBracket.personCount) {
          seasonDayPrice = lastBracket.priceValue;
        }
      }
      modifiedPrice = seasonDayPrice || defaultPrice;
    } else if (this.seasonalPricingEnabled && this.offSeasonMonths.includes(month)) {
      const rule = this.getOffSeasonPriceRule(year, dayName);
      modifiedPrice = rule && rule.priceValue ? +rule.priceValue : defaultPrice;
    } else if (this.weeklyPricingEnabled) {
      const rule = this.getWeeklyPriceRule(year, dayName);
      modifiedPrice = rule && rule.priceValue ? +rule.priceValue : defaultPrice;
    }

    let finalPrice = 0;

    // Base Price
    finalPrice = finalPrice + modifiedPrice;

    // TODO: Remove original service access (make generic)
    if (this.additionalQuantityEnabled && this._additionalQuantity > (this.originalService.description?.hourCount || this.originalService.hourCount)) {
      const hourDiff = this._additionalQuantity - (this.originalService.description?.hourCount || this.originalService.hourCount);
      const additionalPrice = (this.originalService.description?.additionalPriceValue || this.originalService.additionalPriceValue) * hourDiff;
      finalPrice += additionalPrice;
    }

    return finalPrice;
  }

  /**
   * Sets the maximum quantity used in total price calculations. Set to -1 to disable.
   * @param value Maximum quantity to apply. -1 is the default value.
   */
  setMaximumQuantity(value: number): void {
    this.maxQuantity = value;
  }

  setMinimumQuantity(value: number): void {
    this.minQuantity = value;
  }

  /**
   * Get default unit price, as provided by the vendor
   */
  getDefaultUnitPrice(): number {
    return this.defaultPrice;
  }

  /**
   * Applies the closest markup/discount rule, if it exists. Otherwise, returns the same price.
   * Note, this function is used to apply the rule to the sum of the price.
   * Minimum value is 0
   * @param price - price to be modified by a markup/discount rule
   * @param peopleCount - value for rule selection
   * @returns Modified price. In case no rule applies, same price value is returned.
   */
  getMarkupedOrDiscountedPrice(price: number, peopleCount: number): any {
    if (!this.markupDiscount) {
      return price;
    }

    let modifiedPrice = price;



    const markupDiscountRules: any[] = this.markupDiscount.filter(
      (rule) => rule.peopleCount <= peopleCount
    );
    if (markupDiscountRules.length > 0) {
      markupDiscountRules.sort((rule1, rule2) => {
        if (rule1.peopleCount > rule2.peopleCount) return -1;
        if (rule1.peopleCount < rule2.peopleCount) return 1;
        return 0;
      });

      const closestRule = markupDiscountRules[0];

      switch (closestRule?.type) {
        case MarkupDiscountActions.discount:
          modifiedPrice =
            closestRule.priceUnit === 'percentage'
              ? modifiedPrice * (1 - closestRule.priceValue / 100)
              : modifiedPrice - closestRule.priceValue;
          break;

        case MarkupDiscountActions.markup:
          modifiedPrice =
            closestRule.priceUnit === 'percentage'
              ? modifiedPrice * (closestRule.priceValue / 100 + 1)
              : modifiedPrice + closestRule.priceValue;
          break;

        default:
          break;
      }
    }
    return modifiedPrice >= 0 ? modifiedPrice : 0;
  }

  getMarkupedOrDiscountedPriceV2(price: number, peopleCount: number): any {
    if (!this.markupDiscount) {
      return price;
    }


    const sortedRules = this.markupDiscount.sort((rule1, rule2) => {
      if (rule1.peopleCount > rule2.peopleCount) return 1;
      if (rule1.peopleCount < rule2.peopleCount) return -1;
      return 0;
    }).filter(rule => rule.peopleCount <= peopleCount);

    // No rule hit
    if (sortedRules.length === 0) {
      return price;
    }

    // Calculate one unit of price to apply for 0-bracket
    let modifiedPrice = 0;
    let peopleCountRemainder = peopleCount;
    let currentUnitPriceValue = price / peopleCount;
    let lastRulePeopleCount = 0;

    // We go through each rule. First rule tells us for how many units we apply default price. Other rulse just apply the logic
    sortedRules.forEach((rule, index) => {
      if (peopleCountRemainder === 0) {
        return;
      }

      if (index === 0) {
        const rulePeopleCount = rule.peopleCount - 1;
        modifiedPrice = (price / peopleCount) * (rulePeopleCount);
        peopleCountRemainder -= rulePeopleCount;
        // currentUnitPriceValue = rule.priceUnit === 'percentage' ? currentUnitPriceValue
        // previousRuleCount = rule.peopleCount;
      }

      // From remainder calculate for how much quantity we apply the rule. The maximum is rule people count.
      // const ruleQuantity = rule.peopleCount;
      let modifiedUnitPrice = 0;

      if (rule.type === MarkupDiscountActions.discount) {
        modifiedUnitPrice =  rule.priceUnit === 'percentage' ? currentUnitPriceValue * (rule.priceValue / 100) : rule.priceValue;
      }

      if (rule.type === MarkupDiscountActions.markup) {
        modifiedUnitPrice =  rule.priceUnit === 'percentage' ? currentUnitPriceValue * (1 + (rule.priceValue / 100)) : rule.priceValue;
      }

      currentUnitPriceValue = modifiedUnitPrice;
      const bracketQuantity = index === sortedRules.length - 1 ? Number.MAX_SAFE_INTEGER : rule.peopleCount - lastRulePeopleCount;
      const ruleQuantity = bracketQuantity < peopleCountRemainder ? bracketQuantity : peopleCountRemainder;
      peopleCountRemainder -= ruleQuantity;
      lastRulePeopleCount = rule.peopleCount;

      modifiedPrice += modifiedUnitPrice * ruleQuantity;
    });


    return modifiedPrice;
  }

  /**
   * Get total price of the service. Same as getFinalUnitPrice(), but takes quantity into account
   * @param date - The date at which this service will be provided
   * @param unitCount - Quantity of the provided service
   */
  getFinalPrice(date?: Date): any {

    let quantity = this.quantity;

    if (
      this.priceUnit === AdvertsPriceUnits.fixedFee &&
      fakeQuantityServiceTypes.includes(this.type) &&
      quantity > 0
    ) {
      quantity = 1;
    }

    if (quantity === 0) {
      return 0;
    }

    const unitPrice =
      this.customPrice !== null
        ? this.customPrice
        : this.getFinalUnitPrice(date);

    let totalPrice = unitPrice * quantity;

    if (this.priceBrackets) {
      totalPrice = unitPrice;
    }

    if (
      (this.priceUnit === AdvertsPriceUnits.currencyPerHour ||
        this.priceUnit === AdvertsPriceUnits.hourlyPrice) &&
      +quantity > +this.minQuantity &&
      this.additionalUnitPrice
    ) {
      totalPrice =
        unitPrice * this.minQuantity +
        this.additionalUnitPrice * (quantity - this.minQuantity);
    }

    // We don't want to apply minimal budget for minimal quantity based services
    const minimalBudget = this.minQuantity > -1 ? null : this.minimalBudget;

    if (!date) {
      if (this.priceUnit === AdvertsPriceUnits.fixedPerPerson) {
        const finalPrice = quantity >= this.quantityStartAt ? this.minimalBudget + ((quantity - this.quantityStartAt) * unitPrice) : this.minimalBudget;
        return finalPrice;
      }

      const finalPrice = totalPrice < minimalBudget ? minimalBudget : totalPrice;
      return finalPrice;
    }

    const dayName = format(date, 'dddd', { locale: enLocale }).toLowerCase();
    const year = format(date, 'YYYY', { locale: enLocale });
    const month = format(date, 'MMMM').toLowerCase();

    let modifiedMinimalBudget = minimalBudget;

    if (this.seasonalPricingEnabled && this.parsedSeasons && this.parsedSeasons?.[+year]?.[month]) {
      const dayPricing = this.parsedSeasons?.[+year]?.[month]?.[dayName];
      let seasonMinimalBudget = dayPricing?.[0]?.minimalBudget;
      if (this.priceBrackets && dayPricing) {
        const seasonBracket = dayPricing.find(pricing => quantity <= pricing.personCount);
        if (seasonBracket) {
          seasonMinimalBudget = seasonBracket.minimalBudget;
        }
      }
      modifiedMinimalBudget = seasonMinimalBudget || 0;
    } else if (this.weeklyPricingEnabled && this.parsedWeeklySeason) {
      const dayPricing = this.parsedWeeklySeason?.[+year]?.[dayName];
      let weeklyMinimalBudget = dayPricing?.[0]?.minimalBudget;
      if (this.priceBrackets && dayPricing) {
        const seasonBracket = dayPricing.find(pricing => quantity <= pricing.personCount);
        if (seasonBracket) {
          weeklyMinimalBudget = seasonBracket.minimalBudget;
        }
      }
      modifiedMinimalBudget = weeklyMinimalBudget || 0;
    } else if (this.seasonalPricingEnabled && this.offSeasonMonths.includes(month)) {
      const rule = this.getOffSeasonPriceRule(year, dayName);
      modifiedMinimalBudget = rule
        ? +rule.minimalBudget || 0
        : minimalBudget;
    } else if (this.weeklyPricingEnabled) {
      const rule = this.getWeeklyPriceRule(year, dayName);
      modifiedMinimalBudget =
        rule && rule.minimalBudget ? +rule.minimalBudget : minimalBudget;
    }

    if (
      this
        .markupDiscountEnabled /* && this.markupDiscountPriceUnits.includes(this.priceUnit) */ &&
        quantity > 0
    ) {
      totalPrice = this.getMarkupedOrDiscountedPriceV2(totalPrice, quantity);
    }

    // const additionalFixedCost = this.additionalFixedCostFn();

    let finalPrice = totalPrice < modifiedMinimalBudget ? modifiedMinimalBudget : totalPrice;

    if (this.priceUnit === AdvertsPriceUnits.fixedPerPerson) {
      finalPrice = quantity >= this.quantityStartAt ? modifiedMinimalBudget + ((quantity - this.quantityStartAt) * unitPrice) : modifiedMinimalBudget;
    }

    finalPrice = this.calculatePriceChange(finalPrice);
    return finalPrice;
  }

  getFinalUnitPriceWithCharges(date?: Date): number {
    const finalPrice = this.getFinalUnitPrice(date);
    return this.addServiceChargeandTax(finalPrice);
  }

  getFinalPriceWithCharges(date?: Date): number {
    const finalPrice = this.getFinalPrice(date);
    return this.addServiceChargeandTax(finalPrice);
  }

  getTax(date?: Date): number {
    const finalPrice = this.getFinalPrice(date);
    const serviceCharge = finalPrice * (this.serviceCharge / 100);
    const tax = (finalPrice + serviceCharge) * (this.tax / 100);
    return tax;
  }

  getServiceCharge(date?: Date): number {
    const taxFraction = this.tax / 100;
    const finalPrice = this.getFinalPrice(date);
    const serviceCharge = finalPrice * (this.serviceCharge / 100);
    const serviceChargeTax = serviceCharge * taxFraction;
    const finalServiceCharge = serviceCharge + serviceChargeTax;
    return finalServiceCharge;
  }

  getServiceChargeWithoutTax(date?: Date): number {
    const finalPrice = this.getFinalPrice(date);
    const serviceCharge = finalPrice * (this.serviceCharge / 100);
    return serviceCharge;
  }

  getMinimalBudget(date?: Date) {
    const dayName = format(date, 'dddd', { locale: enLocale }).toLowerCase();
    const year = format(date, 'YYYY', { locale: enLocale });
    const month = format(date, 'MMMM').toLowerCase();

    let modifiedMinimalBudget = this.minimalBudget;

    if (!date) {
      return modifiedMinimalBudget;
    }

    if (this.seasonalPricingEnabled && this.parsedSeasons && this.parsedSeasons?.[+year]?.[month]) {
      const dayPricing = this.parsedSeasons?.[+year]?.[month]?.[dayName];
      let seasonMinimalBudget = dayPricing?.[0]?.minimalBudget;
      if (this.priceBrackets && dayPricing) {
        const seasonBracket = dayPricing.find(pricing => this.quantity <= pricing.personCount);
        if (seasonBracket) {
          seasonMinimalBudget = seasonBracket.minimalBudget;
        }
      }
      modifiedMinimalBudget = seasonMinimalBudget || 0;
    } else if (this.weeklyPricingEnabled && this.parsedWeeklySeason) {
      const dayPricing = this.parsedWeeklySeason?.[+year]?.[dayName];
      let weeklyMinimalBudget = dayPricing?.[0]?.minimalBudget;
      if (this.priceBrackets && dayPricing) {
        const seasonBracket = dayPricing.find(pricing => this.quantity <= pricing.personCount);
        if (seasonBracket) {
          weeklyMinimalBudget = seasonBracket.minimalBudget;
        }
      }
      modifiedMinimalBudget = weeklyMinimalBudget || 0;
    } else if (this.seasonalPricingEnabled && this.offSeasonMonths.includes(month)) {
      const rule = this.getOffSeasonPriceRule(year, dayName);
      modifiedMinimalBudget = rule
        ? +rule.minimalBudget || 0
        : this.minimalBudget;
    } else if (this.weeklyPricingEnabled) {
      const rule = this.getWeeklyPriceRule(year, dayName);
      modifiedMinimalBudget =
        rule && rule.minimalBudget ? +rule.minimalBudget : this.minimalBudget;
    }

    return modifiedMinimalBudget;
  }

  getMinimalBudgetWithCharges(date?: Date): number {
    const baseMinimalBudget = this.getMinimalBudget(date);
    return this.addServiceChargeandTax(baseMinimalBudget);
  }

  getWeeklyPriceRule(year: string, dayName: string): PricingRule {
    return this.getPricingRule(this.weeklyPricing, year, dayName);
  }

  getOffSeasonPriceRule(year: string, dayName: string): PricingRule {
    return this.getPricingRule(this.seasonalPricing, year, dayName);
  }

  private addServiceChargeandTax(price: number): number {
    return price * (1 + this.serviceCharge / 100) * (1 + this.tax / 100);
  }

  private getPricingRule(
    ruleCollection: { year: number; rules: any }[],
    year: string,
    dayName: string
  ): PricingRule {
    const emptyRule: PricingRule = {
      priceValue: this.defaultPrice
    };

    const ruleResult = ruleCollection.find((rules) => `${rules.year}` === year)?.rules?.[dayName];
    return ruleResult || emptyRule;
  }

  private parseSeasonsByMonth(pricingForm: any, year: number) {
    const months = {
      january: null,
      february: null,
      march: null,
      april: null,
      may: null,
      june: null,
      july: null,
      august: null,
      september: null,
      october: null,
      november: null,
      december: null
    };

    const yearPricing = pricingForm.find(yearPricings => yearPricings.year === year);

    if (!yearPricing || yearPricing.version !== '2') {
      return months;
    }

    yearPricing.seasons.forEach(season => {
      const includedSeasons = Object.entries(season.includedMonths).filter(([, included]) => included);
      includedSeasons.forEach(includedMonth => {
        if (includedMonth[0] === 'customOptions') {
          return;
        }
        months[includedMonth[0]] = season.pricing;
      });
    });

    return months;
  }

  private calculatePriceChange(price): number {
    const priceChange = this._priceChange;
    if (!priceChange) {
      return price;
    }

    if (priceChange.type && priceChange.value) {
      if (priceChange.type === 'percent') {
        if (priceChange.direction === 'markup') {
          return price * (1 + priceChange.value / 100);
        }

        return price * (1 - priceChange.value / 100);
      }

      if (priceChange.type === 'fixed') {
        if (priceChange.direction === 'markup') {
          return price + priceChange.value;
        }

        return price - priceChange.value;
      }
    }

    return price;
  }
}
