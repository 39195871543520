import {
  ExtractorPathConfigs,
  ServiceTypes,
  PathLocation,
  PricingModifiers,
} from './pricing-calculator.types';

export const venuesPaths: ExtractorPathConfigs = {
  main: [
    {
      type: ServiceTypes.venueStandalone,
      location: {
        location: PathLocation.event,
        path: 'venueStandalone',
      },
      enabled: {
        location: PathLocation.event,
        path: 'venueStandalone.enabled',
      },
      disabledPricingModifiers: [PricingModifiers.all],
      // description: 'booking.defaultDescriptions.venueStandalone.description',
      name: 'booking.defaultDescriptions.venueStandalone.name',
    },
    {
      type: ServiceTypes.venueSeparate,
      location: {
        location: PathLocation.event,
        path: 'venuePricing',
      },
      enabled: {
        location: PathLocation.event,
        path: 'venuePricing.enabled',
      },
      disabledPricingModifiers: [PricingModifiers.all],
      description: 'booking.defaultDescriptions.venueSeparate.description',
      name: 'booking.defaultDescriptions.venueSeparate.name',
    },
    {
      type: ServiceTypes.venueCateringMenuMain,
      location: {
        location: PathLocation.event,
        path: 'catering',
      },
      maximums: {
        default: null,
        banquet: {
          location: PathLocation.base,
          path: 'kitchen.maxBanquetPeopleCount',
        },
        buffet: {
          location: PathLocation.base,
          path: 'kitchen.maxBuffetPeopleCount',
        },
      },
    },
    {
      type: ServiceTypes.venueCateringMenuAdditional,
      location: {
        location: PathLocation.event,
        path: 'cateringAdditional.additionalPackets',
      },
      enabled: {
        location: PathLocation.event,
        path: 'cateringAdditional.enabled',
      },
      maximums: {
        default: null,
        banquet: {
          location: PathLocation.base,
          path: 'kitchen.maxBanquetPeopleCount',
        },
        buffet: {
          location: PathLocation.base,
          path: 'kitchen.maxBuffetPeopleCount',
        },
      },
    },
    {
      type: ServiceTypes.venueCateringMenuChildren,
      location: {
        location: PathLocation.event,
        path: 'cateringChildren.additionalPackets',
      },
      enabled: {
        location: PathLocation.event,
        path: 'cateringChildren.enabled',
      },
      maximums: {
        default: null,
        banquet: {
          location: PathLocation.base,
          path: 'kitchen.maxBanquetPeopleCount',
        },
        buffet: {
          location: PathLocation.base,
          path: 'kitchen.maxBuffetPeopleCount',
        },
      },
    },
    {
      type: ServiceTypes.venueCateringMenuBeverages,
      location: {
        location: PathLocation.event,
        path: 'cateringBeverages.additionalPackets',
      },
      enabled: {
        location: PathLocation.event,
        path: 'cateringBeverages.enabled',
      },
      maximums: {
        default: null,
        banquet: {
          location: PathLocation.base,
          path: 'kitchen.maxBanquetPeopleCount',
        },
        buffet: {
          location: PathLocation.base,
          path: 'kitchen.maxBuffetPeopleCount',
        },
      },
    },
    {
      type: ServiceTypes.venueCateringMenuBeveragesChildren,
      location: {
        location: PathLocation.event,
        path: 'cateringBeveragesChildren.additionalPackets',
      },
      enabled: {
        location: PathLocation.event,
        path: 'cateringBeveragesChildren.enabled',
      },
      maximums: {
        default: null,
        banquet: {
          location: PathLocation.base,
          path: 'kitchen.maxBanquetPeopleCount',
        },
        buffet: {
          location: PathLocation.base,
          path: 'kitchen.maxBuffetPeopleCount',
        },
      },
    },
    {
      type: ServiceTypes.venueCeremony,
      location: {
        location: PathLocation.event,
        path: 'venueCeremony.services',
      },
      enabled: {
        location: PathLocation.event,
        path: 'venueCeremony.enabled',
      },
      disabledPricingModifiers: [PricingModifiers.all],
      name: 'booking.defaultDescriptions.venueCeremony.name'
    }
  ],
  additional: [
    {
      type: ServiceTypes.venueAccomodation,
      location: {
        location: PathLocation.event,
        path: 'accomodationPricing',
      },
      enabled: {
        location: PathLocation.event,
        path: 'accomodationPricing.enabled',
      },
      maximums: {
        default: {
          location: PathLocation.base,
          path: 'venues.maxAccomodationPeopleCount',
        },
      },
      disabledPricingModifiers: [PricingModifiers.markupDiscount],
      description: 'booking.defaultDescriptions.venueAccomodation.description',
      name: 'booking.defaultDescriptions.venueAccomodation.name',
    },
    {
      type: ServiceTypes.venueCeremonyFee,
      location: {
        location: PathLocation.event,
        path: 'venueCeremony.ceremonyFeeService',
      },
      // description: 'booking.defaultDescriptions.cateringDessert.description',
      name: 'booking.defaultDescriptions.venueCeremonyFee.name',
      enabled: {
        location: PathLocation.event,
        path: 'venueCeremony.ceremonyFeeEnabled',
      },
    },
    {
      type: ServiceTypes.additionalService,
      location: {
        location: PathLocation.event,
        path: 'additionalServices.services',
      },
      enabled: {
        location: PathLocation.event,
        path: 'additionalServices.enabled',
      },
    },
    {
      type: ServiceTypes.cateringDessert,
      location: {
        location: PathLocation.event,
        path: 'cateringOther.desserts',
      },
      description: 'booking.defaultDescriptions.cateringDessert.description',
      name: 'booking.defaultDescriptions.cateringDessert.name',
      enabled: {
        location: PathLocation.event,
        path: 'cateringOther.desserts.enabled',
      },
    },    
    {
      type: ServiceTypes.venueSeat,
      location: {
        location: PathLocation.event,
        path: 'inventoryPricing.seatsPricing',
      },
      enabled: {
        location: PathLocation.event,
        path: 'inventoryPricing.seatsEnabled',
      },
      name: 'booking.defaultDescriptions.seats.name'
    },
    {
      type: ServiceTypes.venueTable,
      location: {
        location: PathLocation.event,
        path: 'inventoryPricing.tablesPricing',
      },
      enabled: {
        location: PathLocation.event,
        path: 'inventoryPricing.tablesEnabled',
      },
      name: 'booking.defaultDescriptions.tables.name'
    },
    {
      type: ServiceTypes.venueLinen,
      location: {
        location: PathLocation.event,
        path: 'inventoryPricing.linensPricing',
      },
      enabled: {
        location: PathLocation.event,
        path: 'inventoryPricing.linensEnabled',
      },
      name: 'booking.defaultDescriptions.linens.name'
    },
    {
      type: ServiceTypes.cateringTasting,
      location: {
        location: PathLocation.event,
        path: 'cateringOther.tasting',
      },
      enabled: {
        location: PathLocation.event,
        path: 'cateringOther.tasting.enabled',
      },
      description: 'booking.defaultDescriptions.cateringTasting.description',
      name: 'booking.defaultDescriptions.cateringTasting.name',
    },
    {
      type: ServiceTypes.venueOvertime,
      location: {
        location: PathLocation.event,
        path: 'overtime.venueOvertime',
      },
      enabled: {
        location: PathLocation.event,
        path: 'overtime.venueOvertimeEnabled',
      },
      description: 'booking.defaultDescriptions.venueOvertime.description',
      name: 'booking.defaultDescriptions.venueOvertime.name',
    },
    {
      type: ServiceTypes.personelOvertime,
      location: {
        location: PathLocation.event,
        path: 'overtime.personelOvertime',
      },
      enabled: {
        location: PathLocation.event,
        path: 'overtime.personelOvertimeEnabled',
      },
      description: 'booking.defaultDescriptions.personelOvertime.description',
      name: 'booking.defaultDescriptions.personelOvertime.name',
    },
    /* {
          type: ServiceTypes.serviceCharge,
          location: {
            location: PathLocation.event,
            path: 'charges.serviceCharge'
          },
          enabled: {
              location: PathLocation.event,
              path: 'charges.serviceCharge.enabled'
          },
          name: 'TRX: Service charge'
        } */
  ],
};

export const cateringPaths: ExtractorPathConfigs = {
  main: [
    {
      type: ServiceTypes.cateringBuffetMenu,
      location: {
        location: PathLocation.event,
        path: 'mainServices.buffet',
      },
      enabled: {
        location: PathLocation.base,
        path: 'subcategories.types.buffet',
      },
    },
    {
      type: ServiceTypes.cateringBanquetMenu,
      location: {
        location: PathLocation.event,
        path: 'mainServices.banquet',
      },
      enabled: {
        location: PathLocation.base,
        path: 'subcategories.types.banquet',
      },
    },
    {
      type: ServiceTypes.cateringFoodTruck,
      location: {
        location: PathLocation.event,
        path: 'mainServices.foodTruck',
      },
      enabled: {
        location: PathLocation.base,
        path: 'subcategories.types.foodTruck',
      },
    },
    {
      type: ServiceTypes.cateringSnacks,
      location: {
        location: PathLocation.event,
        path: 'mainServices.snacks',
      },
      enabled: {
        location: PathLocation.base,
        path: 'subcategories.types.snacks',
      },
    },
  ],
  additional: [
    {
      type: ServiceTypes.additionalService,
      location: {
        location: PathLocation.event,
        path: 'additionalServices.services',
      },
      enabled: {
        location: PathLocation.event,
        path: 'additionalServices.enabled',
      },
    },
    {
      type: ServiceTypes.cateringDessert,
      location: {
        location: PathLocation.base,
        path: 'kitchen.desserts',
      },
      description: 'booking.defaultDescriptions.cateringDessert.description',
      name: 'booking.defaultDescriptions.cateringDessert.name',
      enabled: {
        location: PathLocation.base,
        path: 'kitchen.desserts.enabled',
      },
    },
    {
      type: ServiceTypes.cateringKidsMenu,
      location: {
        location: PathLocation.base,
        path: 'kitchen.kidsMenu',
      },
      enabled: {
        location: PathLocation.base,
        path: 'kitchen.kidsMenu.enabled',
      },
      description: 'booking.defaultDescriptions.cateringKidsMenu.description',
      name: 'booking.defaultDescriptions.cateringKidsMenu.name',
    },
    {
      type: ServiceTypes.cateringTasting,
      location: {
        location: PathLocation.base,
        path: 'kitchen.tasting',
      },
      enabled: {
        location: PathLocation.base,
        path: 'kitchen.tasting.enabled',
      },
      description: 'booking.defaultDescriptions.cateringTasting.description',
      name: 'booking.defaultDescriptions.cateringTasting.name',
    },
    {
      type: ServiceTypes.personelOvertime,
      location: {
        location: PathLocation.event,
        path: 'overtime.personelOvertime',
      },
      enabled: {
        location: PathLocation.event,
        path: 'overtime.personelOvertimeEnabled',
      },
      description: 'booking.defaultDescriptions.personelOvertime.description',
      name: 'booking.defaultDescriptions.personelOvertime.name',
    },
  ],
};

export const othersPaths: ExtractorPathConfigs = {
  main: [
    {
      type: ServiceTypes.mainService,
      location: {
        location: PathLocation.event,
        path: 'mainServices',
      },
    },
  ],
  additional: [
    {
      type: ServiceTypes.additionalService,
      location: {
        location: PathLocation.event,
        path: 'additionalServices.services',
      },
      enabled: {
        location: PathLocation.event,
        path: 'additionalServices.enabled',
      },
    },
    {
      type: ServiceTypes.othersAdditionalHour,
      location: {
        location: PathLocation.event,
        path: 'mainAdditional',
      },
      description:
        'booking.defaultDescriptions.othersAdditionalHour.description',
      name: 'booking.defaultDescriptions.othersAdditionalHour.name',
    },
  ],
};
