<div
  class="bztt-flex bztt-font-sora bztt-w-full bztt-h-full bztt-flex-col bztt-flex-grow bztt-justify-start bztt-items-center"
>
  <div
    class="hero section bztt-flex bztt-flex-col md:bztt-flex-row bztt-gap-3 bztt-pt-6"
  >
    <div
      class="bztt-rounded-[20px] bztt-bg-white bztt-shadow-md bztt-p-6 md:bztt-p-12 md:bztt-pr-24 bztt-flex bztt-flex-col bztt-gap-5 md:bztt-w-1/2 bztt-justify-around"
    >
      <div
        class="bztt-font-semibold bztt-text-4xl md:bztt-text-6xl bztt-text-[#111111] bztt-leading-tight md:bztt-leading-tight"
      >
        Smarter way to find your dream

        <span
          class="md:bztt-text-[#EF5518] bztt-font-semibold bztt-text-4xl md:bztt-text-6xl"
          >wedding venue</span
        >
      </div>
      <p class="bztt-text-base md:bztt-text-xl">
        Start chat to receive immediate venue recommendations tailored to your
        style, budget, and location
      </p>
      <button
        app-button
        (click)="handleCTOClick('hero-section')"
        color="accent"
        class="bztt-w-52"
      >
        Get Started
      </button>
      <div class="bztt-text-base md:bztt-text-xl">
        Over <span class="bztt-font-semibold"> 2,600 </span> customers in
        California
      </div>
    </div>
    <div class="md:bztt-w-1/2">
      <img
        class="md:bztt-h-full md:bztt-w-auto bztt-object-cover bztt-rounded-[20px] bztt-shadow-md"
        src="assets/images/landing/ai-planner-landing/hero.png"
        alt="AI Planner"
      />
    </div>
  </div>

  <div id="howItWorks" class="how-it-works section bztt-overflow-hidden">
    <div
      class="bztt-text-4xl md:bztt-text-6xl bztt-font-semibold bztt-text-[#111111] bztt-text-center bztt-mb-10 md:bztt-mb-20"
    >
      How it works
    </div>

    <jarvis-card-slider
      [hideOverflow]="false"
      #slider
      class="md:bztt-hidden bztt-flex bztt-p-4 bztt-w-80"
    >
      <jarvis-card-slider-card
        *ngFor="let hiwItem of howItWorksItems; let i = index"
      >
        <div
          class="bztt-bg-white bztt-flex bztt-flex-col bztt-rounded-lg bztt-shadow-md bztt-justify-between bztt-p-5 bztt-gap-3 bztt-mr-3"
        >
          <img
            [src]="hiwItem.image"
            [alt]="hiwItem.title"
            class="bztt-w-full bztt-max-w-[300px] bztt-self-center"
          />
          <div class="bztt-flex bztt-flex-col bztt-gap-4 bztt-min-h-40">
            <div
              class="bztt-rounded-full bztt-border bztt-border-[#EF5518] bztt-text-[#EF5518] bztt-text-[11px] bztt-px-3 bztt-w-fit"
            >
              Step {{ i + 1 }}
            </div>
            <div class="bztt-text-base md:bztt-text-xl bztt-font-semibold">
              {{ hiwItem.title }}
            </div>
            <div class="bztt-font-normal bztt-text-[#6B6060]">
              {{ hiwItem.description }}
            </div>
          </div>
        </div>
      </jarvis-card-slider-card>
    </jarvis-card-slider>

    <div
      class="bztt-hidden md:bztt-flex bztt-justify-center bztt-items-stretch bztt-gap-3"
    >
      <div
        *ngFor="let hiwItem of howItWorksItems; let i = index"
        class="bztt-bg-white bztt-flex bztt-flex-col bztt-rounded-lg bztt-shadow-md bztt-justify-between bztt-p-5 bztt-gap-3"
      >
        <img
          [src]="hiwItem.image"
          [alt]="hiwItem.title"
          class="bztt-w-full bztt-max-w-[300px] bztt-self-center"
        />
        <div class="bztt-flex bztt-flex-col bztt-gap-4">
          <div
            class="bztt-rounded-full bztt-border bztt-border-[#EF5518] bztt-text-[#EF5518] bztt-text-[11px] bztt-px-3 bztt-w-fit"
          >
            Step {{ i + 1 }}
          </div>
          <div class="bztt-text-base md:bztt-text-xl bztt-font-semibold">
            {{ hiwItem.title }}
          </div>
          <div class="bztt-font-normal bztt-text-[#6B6060]">
            {{ hiwItem.description }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="benefits" class="what-you-will-get full-section bztt-bg-white">
    <div class="content bztt-w-full md:bztt-max-w-[82rem] bztt-h-full">
      <div
        class="bztt-text-4xl md:bztt-text-6xl bztt-font-semibold bztt-text-[#111111] bztt-text-center bztt-mb-10 md:bztt-mb-20"
      >
        What you will get
      </div>
      <div class="bztt-flex bztt-flex-col bztt-gap-4">
        <div class="bztt-flex bztt-gap-4 bztt-flex-col md:bztt-flex-row">
          <div
            *ngFor="
              let whatWillGetItem of whatWillGetItems.slice(0, 2);
              let i = index
            "
            class="bztt-bg-white bztt-flex bztt-flex-col bztt-rounded-lg bztt-shadow-md bztt-gap-3 bztt-border bztt-border-[#F1F0F0]"
          >
            <img
              [src]="whatWillGetItem.image"
              [alt]="whatWillGetItem.title"
              class="md:bztt-h-[560px] bztt-self-center"
            />
            <div class="bztt-flex bztt-flex-col bztt-gap-4 bztt-p-5 bztt-pt-0">
              <div class="bztt-text-2xl bztt-font-semibold">
                {{ whatWillGetItem.title }}
              </div>
              <div class="bztt-font-normal bztt-text-[#6B6060]">
                {{ whatWillGetItem.description }}
              </div>
            </div>
          </div>
        </div>

        <div class="bztt-flex bztt-gap-6 bztt-flex-col md:bztt-flex-row">
          <div
            *ngFor="
              let whatWillGetItem of whatWillGetItems.slice(
                2,
                whatWillGetItems.length
              )
            "
            class="bztt-bg-white bztt-flex bztt-flex-col md:bztt-w-1/3 bztt-gap-4"
          >
            <img
              [src]="whatWillGetItem.image"
              [alt]="whatWillGetItem.title"
              class="bztt-w-full bztt-self-center"
            />
            <div class="bztt-flex bztt-flex-col bztt-gap-4">
              <div class="bztt-text-2xl bztt-font-semibold">
                {{ whatWillGetItem.title }}
              </div>
              <div class="bztt-font-normal bztt-text-[#6B6060]">
                {{ whatWillGetItem.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="money-back full-section bztt-bg-[#EF5518]" *ngIf="paid">
    <div
      class="content bztt-w-full md:bztt-max-w-[82rem] bztt-h-full bztt-flex bztt-pb-7 bztt-flex-col md:bztt-flex-row"
    >
      <div
        class="bztt-pt-14 bztt-flex bztt-justify-center bztt-items-start bztt-flex-col bztt-gap-6 md:bztt-w-1/2"
      >
        <div class="bztt-text-white bztt-text-4xl md:bztt-text-7xl">
          Money back guarantee
        </div>
        <div
          class="bztt-text-white bztt-text-base md:bztt-text-xl bztt-font-light"
        >
          In case we don’t help you book your wedding venue
          <span class="bztt-font-semibold"> within 2 months period</span>, get
          your money back.
        </div>
      </div>
      <div class="md:bztt-w-1/2 md:bztt-pr-0">
        <img
          class="bztt-w-full"
          src="assets/images/landing/ai-planner-landing/money-back.png"
          alt="Money back guarantee"
        />
      </div>
    </div>
  </div>

  <div
    id="whyBreezit"
    class="alternatives section bztt-flex bztt-flex-col bztt-gap-14 bztt-justify-start bztt-items-center bztt-w-full"
  >
    <div
      class="bztt-text-4xl md:bztt-text-6xl bztt-font-semibold bztt-text-[#111111] bztt-text-center md:bztt-mb-10 bztt-max-w-[900px]"
    >
      Here's your alternatives to wedding planning
    </div>

    <div
      class="bztt-flex bztt-shadow-lg bztt-w-full bztt-rounded-lg bztt-overflow-auto"
    >
      <div
        *ngFor="let item of alternatives"
        class="bztt-flex bztt-flex-col bztt-w-1/3"
        [class.bztt-shadow-md]="item.header.best"
        [style]="{
          backgroundColor: item.header.best
            ? '#ffffff'
            : 'rgba(255,255,255, 0.6)'
        }"
      >
        <div
          class="bztt-flex bztt-flex-col bztt-gap-1 bztt-justify-center bztt-items-center bztt-h-24"
        >
          <img
            [src]="item.header.image"
            [alt]="item.title"
            class="bztt-h-5 md:bztt-h-10 md:bztt-flex"
            [class.bztt-hidden]="item.header.mobileImage"
          />
          <img
            *ngIf="item.header.mobileImage"
            [src]="item.header.mobileImage"
            [alt]="item.title"
            class="bztt-h-16 md:bztt-h-10"
            [class.md:bztt-hidden]="item.header.mobileImage"
          />
          <div
            *ngIf="item.header.label"
            class="bztt-text-xs bztt-text-center bztt-px-3"
          >
            {{ item.header.label }}
          </div>
        </div>
        <div
          *ngFor="let innerItem of item.items"
          class="bztt-flex bztt-gap-4 bztt-p-3 md:bztt-p-5 bztt-border-t bztt-border-[#F1F0F0] bztt-items-start md:bztt-items-center bztt-flex-col md:bztt-flex-row bztt-h-[130px] md:bztt-h-[80px]"
        >
          <div>
            <div
              *ngIf="innerItem.checked"
              class="bztt-rounded-full bztt-bg-[#32ae303b] bztt-flex bztt-justify-center bztt-items-center bztt-h-9 bztt-w-9"
            >
              <mat-icon class="bztt-text-[#33AE30]">check</mat-icon>
            </div>
            <div
              *ngIf="!innerItem.checked"
              class="bztt-rounded-full bztt-border-[#ef551834] bztt-border-4 bztt-flex bztt-justify-center bztt-items-center bztt-h-9 bztt-w-9"
            >
              <mat-icon class="bztt-text-[#EF5518]">priority_high</mat-icon>
            </div>
          </div>
          <div
            [class.bztt-text-gray-400]="!innerItem.checked"
            class="bztt-text-[11px] md:bztt-font-normal bztt-leading-tight"
            [style]="{ wordBreak: 'break-word' }"
          >
            {{ innerItem.label }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="pricing"
    class="get-ai-planner section bztt-flex bztt-gap-3 bztt-flex-col md:bztt-flex-row"
    *ngIf="paid"
  >
    <div
      class="bztt-bg-[#5357F7] bztt-rounded-lg md:bztt-w-1/2 bztt-text-white bztt-px-8 bztt-py-16 bztt-flex bztt-flex-col bztt-gap-10 bztt-shadow-md"
    >
      <div class="bztt-text-4xl md:bztt-text-6xl bztt-leading-tight">
        Get Breezit AI Wedding planner
      </div>
      <div class="bztt-flex bztt-flex-col bztt-gap-7">
        <div class="bztt-flex bztt-gap-3 bztt-justify-start bztt-items-center">
          <div
            class="bztt-rounded-full bztt-bg-[#ffffff3b] bztt-flex bztt-justify-center bztt-items-center bztt-h-9 bztt-min-w-9"
          >
            <mat-icon class="bztt-text-white">check</mat-icon>
          </div>
          <div class="bztt-text-lg">
            40x cheaper than a human wedding planner
          </div>
        </div>
        <div class="bztt-flex bztt-gap-3 bztt-justify-start bztt-items-center">
          <div
            class="bztt-rounded-full bztt-bg-[#ffffff3b] bztt-flex bztt-justify-center bztt-items-center bztt-h-9 bztt-min-w-9"
          >
            <mat-icon class="bztt-text-white">check</mat-icon>
          </div>
          <div class="bztt-text-lg">24/7 personal assistance</div>
        </div>
        <div class="bztt-flex bztt-gap-3 bztt-justify-start bztt-items-center">
          <div
            class="bztt-rounded-full bztt-bg-[#ffffff3b] bztt-flex bztt-justify-center bztt-items-center bztt-h-9 bztt-min-w-9"
          >
            <mat-icon class="bztt-text-white">check</mat-icon>
          </div>
          <div class="bztt-text-lg">Risk free</div>
        </div>
      </div>
    </div>
    <div
      class="bztt-bg-white bztt-rounded-lg md:bztt-w-1/2 bztt-flex bztt-flex-col bztt-justify-center bztt-items-center bztt-text-black bztt-gap-4 bztt-py-20 bztt-shadow-md"
    >
      <div class="bztt-text-4xl md:bztt-text-6xl bztt-font-semibold">
        $99.00
      </div>
      <div class="bztt-text-2xl">for unlimited use</div>
      <button
        app-button
        (click)="handleCTOClick('buy-section')"
        color="accent"
        class="bztt-w-52"
      >
        Buy now
      </button>

      <img
        src="assets/images/landing/ai-planner-landing/payment-types.png"
        class="bztt-w-72"
      />
    </div>
  </div>

  <div class="join-section section">
    <div
      class="content bztt-w-full md:bztt-max-w-[82rem] bztt-h-full bztt-flex bztt-flex-col bztt-gap-6 bztt-justify-center bztt-items-center"
    >
      <div
        class="bztt-text-4xl md:bztt-text-6xl bztt-leading-tight bztt-text-black bztt-text-center bztt-font-semibold bztt-max-w-[1000px]"
      >
        Join over 2,600 couples already using AI-wedding planner to find their
        dream wedding venue
      </div>
      <div
        class="bztt-text-base md:bztt-text-xl bztt-leading-tight bztt-max-w-[800px] bztt-text-center bztt-font-normals"
      >
        Answer a few questions to receive immediate venue recommendations
        tailored to your style, budget, and location
      </div>
      <button
        app-button
        (click)="handleCTOClick('join-section2')"
        color="accent"
        class="bztt-w-52"
      >
        Get Started
      </button>
    </div>
  </div>

  <div
    class="comments section bztt-flex bztt-gap-3 bztt-flex-col md:bztt-flex-row"
  >
    <div
      *ngFor="let comment of comments"
      class="bztt-bg-white bztt-rounded-lg bztt-shadow-lg bztt-border bztt-border-[#F1F0F0] bztt-p-7 md:bztt-w-1/3 bztt-flex bztt-flex-col bztt-gap-4 bztt-justify-between"
    >
      <div
        class="bztt-text-base md:bztt-text-2xl bztt-leading-normal bztt-font-normal"
      >
        {{ comment.comment }}
      </div>
      <div
        class="bztt-flex bztt-gap-5 bztt-justify-center md:bztt-items-center bztt-items-start bztt-flex-col md:bztt-flex-row"
      >
        <img
          [src]="comment.profileImage"
          [alt]="comment.name"
          class="bztt-h-[72px] bztt-w-[72px] md:bztt-h-24 md:bztt-w-24"
        />
        <div>
          <div class="bztt-font-semibold bztt-text-sm">{{ comment.name }}</div>
          <div class="bztt-font-normal bztt-text-sm">{{ comment.label }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="join-section2 full-section bztt-bg-[#EF5518]">
    <div
      class="content bztt-w-full md:bztt-max-w-[82rem] bztt-h-full bztt-py-20 bztt-px-6 md:bztt-px-0 bztt-flex bztt-flex-col bztt-gap-6 bztt-justify-center bztt-items-center"
    >
      <div
        class="bztt-text-4xl md:bztt-text-6xl bztt-leading-tight bztt-text-white bztt-text-center bztt-font-semibold bztt-max-w-[1000px]"
      >
        Join over 2,600 couples already using AI-wedding planner
      </div>
      <div
        class="bztt-text-base md:bztt-text-xl bztt-leading-tight bztt-max-w-[800px] bztt-text-center bztt-text-white bztt-font-normal"
      >
        Answer a few questions to receive immediate venue recommendations
        tailored to your style, budget, and location
      </div>
      <button
        app-button
        (click)="handleCTOClick('buttom-join-section')"
        color="accent"
        class="bztt-w-64 bztt-bg-black"
      >
        Get Started
      </button>
    </div>
  </div>
</div>

<ng-template #customHeader>
  <div
    class="bztt-hidden md:bztt-flex bztt-justify-between bztt-items-center bztt-border-b bztt-border-[#F1F0F0] bztt-h-16 bztt-px-5 top bztt-font-sora bztt-text-[#1F1F1F]"
  >
    <div class="bztt-flex bztt-gap-5 bztt-text-[15px]">
      <div class="bztt-cursor-pointer" (click)="scrollTo('howItWorks')">
        How it works
      </div>
      <div class="bztt-cursor-pointer" (click)="scrollTo('benefits')">
        Benefits
      </div>
      <div class="bztt-cursor-pointer" (click)="scrollTo('whyBreezit')">
        Why Breezit?
      </div>
      <div
        *ngIf="paid"
        class="bztt-cursor-pointer"
        (click)="scrollTo('pricing')"
      >
        Pricing
      </div>
    </div>

    <a class="logo-container" routerLink="/">
      <img src="/assets/images/bzt-logo.svg" alt="Breezit Logo" />
    </a>

    <div class="bztt-flex bztt-justify-end bztt-items-center">
      <button
        app-button
        (click)="handleCTOClick('header-section')"
        color="accent"
        class="bztt-w-32 bztt-h-10"
      >
        Get Started
      </button>
    </div>
  </div>
  <div
    class="md:bztt-hidden bztt-flex bztt-flex-col bztt-items-center bztt-border-b bztt-border-[#F1F0F0] bztt-font-sora bztt-text-[#1F1F1F]"
  >
    <div
      class="bztt-flex bztt-justify-between bztt-text-[12px] bztt-w-full bztt-border-b bztt-border-[#F1F0F0] bztt-h-8 bztt-items-center bztt-px-4"
    >
      <div class="bztt-cursor-pointer" (click)="scrollTo('howItWorks')">
        How it works
      </div>
      <div class="bztt-cursor-pointer" (click)="scrollTo('benefits')">
        Benefits
      </div>
      <div class="bztt-cursor-pointer" (click)="scrollTo('whyBreezit')">
        Why Breezit?
      </div>
      <div
        *ngIf="paid"
        class="bztt-cursor-pointer"
        (click)="scrollTo('pricing')"
      >
        Pricing
      </div>
    </div>

    <div
      class="bztt-flex bztt-justify-between bztt-h-14 bztt-items-center bztt-w-full bztt-px-4"
    >
      <a class="logo-container bztt-h-7" routerLink="/">
        <img src="/assets/images/bzt-logo.svg" alt="Breezit Logo" />
      </a>

      <div class="bztt-flex bztt-justify-end bztt-items-center">
        <button
          app-button
          (click)="handleCTOClick('header-section')"
          color="accent"
          class="bztt-w-28 bztt-h-8"
        >
          Get Started
        </button>
      </div>
    </div>
  </div>
</ng-template>
