<jarvis-ui-card-wrapper>
    <a routerLink="/" class="bztt-no-underline border-0">
    <section>
        <img src="assets/icons/ic_success_large.svg">
        <h1 class="mb-3 mb-lg-4">
            {{ 'reviews.' + successHeader | translate }}
        </h1>
    </section>
    <section class='evaluate-others' *ngIf="false">
        <span class='grey-text'>Evaluate your other service providers</span>
        <ng-container *ngFor="let _ of [null, null, null]">
            <div class='other-vendor'>
                <div class='photo'>
                    <img [src]="null || 'assets/images/noUser.jpeg'" />
                </div>
                <span>Other vendor</span>
                <button app-button color="accent" (click)="evaluteNew()">Evaluate</button>
            </div>
        </ng-container>
    </section>
    </a>
</jarvis-ui-card-wrapper>