<ng-container [ngSwitch]="isModal === true">
  <div
    *ngSwitchCase="true"
    class="bztt-flex bztt-flex-col bztt-h-full bztt-max-h-full"
  >
    <div class="bztt-flex bztt-w-full bztt-justify-end">
      <button class="bztt-flex bztt-items-center" (click)="close()">
        <span class="bztt-font-montserrat-bold bztt-text-sm bztt-underline">{{
          'listing.close' | translate
        }}</span>
        <mat-icon>clear</mat-icon>
      </button>
    </div>

    <ng-container [ngTemplateOutlet]="innerContent"></ng-container>
  </div>

  <div
    *ngSwitchDefault
    class="calendar-container bztt-h-full bztt-flex bztt-flex-col mat-elevation-z4 p-2 pt-3 bztt-bg-white bztt-rounded-lg bztt-scroll-auto"
  >
    <ng-container [ngTemplateOutlet]="innerContent"></ng-container>
  </div>
</ng-container>

<ng-template #innerContent>
  <mat-form-field
    appearance="outline"
    class="form-field-input street"
    *ngIf="showSearch"
  >
    <input
      class="gaFilterChange"
      [placeholder]="'filters.searchLocation' | translate"
      matInput
      [formControl]="autocompleteControl"
    />
  </mat-form-field>

  <div class="overflow-container">
    <ng-container
      [ngSwitch]="$any(suggestionValue$ | async) === null"
      *ngIf="showSearch"
    >
      <mat-option *ngSwitchCase="true" class="title bztt-pointer-events-none">
        {{ 'filters.noResults' | translate }}
      </mat-option>

      <mat-option
        *ngSwitchDefault
        class="title bztt-text-gray-400 bztt-font-normal bztt-pointer-events-none"
      >
        {{ 'filters.suggested' | translate }}
      </mat-option>
    </ng-container>

    <ng-container *ngIf="suggestionValue$ | async as suggestionValues">
      <mat-option
        (click)="clearFilter()"
        *ngIf="country === 'lt'"
        [ngClass]="{
          'bztt-pointer-events-none selected': currentSelectedValue == null
        }"
      >
        Visa Lietuva
      </mat-option>
      <mat-option
        [ngClass]="{
          'bztt-pointer-events-none selected':
            currentSelectedValue === suggestion.name
        }"
        class="bztt-min-h-10 bztt-flex-shrink-0"
        *ngFor="let suggestion of suggestionValues"
        (click)="handleSuggestion(suggestion)"
      >
        {{ suggestion.name }}
        <span *ngIf="suggestion.avgPrice" class="bztt-text-gray-400 bztt-ml-5"
          >{{ 'filters.avgPrice' | translate }}: {{ suggestion.avgPrice }} </span
        >
      </mat-option>
    </ng-container>
  </div>
</ng-template>
