import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCommonModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { ButtonComponent } from './button.component';

@NgModule({
    imports: [
        CommonModule,
        MatRippleModule,
        MatCommonModule,
        MatIconModule,
    ],
    exports: [
        ButtonComponent
    ],
    declarations: [
        ButtonComponent
    ],
    providers: [],
})
export class ButtonModule { }
