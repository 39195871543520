<div class="individual-scores">
  <div>
    <span class="bztt-text-start">{{
      'reviews.serviceQuality' | translate
    }}</span>
    <div class="score-indicator">
      <div
        class="progress"
        [style.width]="
          ((data?.quality || 0) * 100) / 5 + '%'
        "
      ></div>
    </div>
    <span class="score-number">{{
      data?.quality || 0 | number: '1.0-1'
    }}</span>
  </div>
  
  <div>
    <span class="bztt-text-start">{{ 'reviews.value' | translate }}</span>
    <div class="score-indicator">
      <div
        class="progress"
        [style.width]="
          ((data?.value || 0) * 100) / 5 + '%'
        "
      ></div>
    </div>
    <span class="score-number">{{
      data?.value || 0 | number: '1.0-1'
    }}</span>
  </div>
  
  <div>
    <span class="bztt-text-start">{{
      'reviews.flexibility' | translate
    }}</span>
    <div class="score-indicator">
      <div
        class="progress"
        [style.width]="
          ((data?.flexibility || 0) * 100) / 5 +
          '%'
        "
      ></div>
    </div>
    <span class="score-number">{{
      data?.flexibility || 0 | number: '1.0-1'
    }}</span>
  </div>
  
  <div>
    <span class="bztt-text-start">{{
      'reviews.averageResponseTime' | translate
    }}</span>
    <div class="score-indicator">
      <div
        class="progress"
        [style.width]="
          ((data?.responseTime || 0) * 100) /
            5 +
          '%'
        "
      ></div>
    </div>
    <span class="score-number">{{
      data?.responseTime || 0 | number: '1.0-1'
    }}</span>
  </div>
  
  <div>
    <span class="bztt-text-start">{{
      'reviews.professionalism' | translate
    }}</span>
    <div class="score-indicator">
      <div
        class="progress"
        [style.width]="
          ((data?.professionalism || 0) * 100) /
            5 +
          '%'
        "
      ></div>
    </div>
    <span class="score-number">{{
      data?.professionalism || 0
        | number: '1.0-1'
    }}</span>
  </div>
</div>