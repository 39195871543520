import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @ViewChild(TemplateRef, {static: true}) cardContent: TemplateRef<any> | null = null;
  private contentPortal: TemplatePortal | null = null;

  constructor(private viewContainerRef: ViewContainerRef) { }

  get content(): TemplatePortal | null {
    return this.contentPortal;
  }

  ngOnInit(): void {
    // TODO - better error handling (TEST)
    if (!this.cardContent) {
      console.error('Cannot find template content!');
      return;
    }
    this.contentPortal = new TemplatePortal(this.cardContent, this.viewContainerRef);
  }

}
