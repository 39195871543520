import { Component } from '@angular/core';
import {UntypedFormControl } from '@angular/forms';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';
import { checkFormOrGroupInvalid } from '../../../../utils/profiler.utils';
import { usStatesCounties, ltAddresses } from '@jarvis/data/address';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { camelCaseToNormalText } from '@jarvis/utils';


@Component({
  templateUrl: './location.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class LocationComponent {

  filteredState$: Observable<string[]>;
  formControl:UntypedFormControl;
  currentCountry: 'LT' | 'US';

  private states: string[];

  constructor(
    private profilingFormService: JarvisProfilingFormService,
    private translateService: TranslateService
  ) {
    this.formControl = this.profilingFormService.getStepControl('location');
    if (['nonUS', 'nonLT'].includes(this.formControl.value)) {
      this.formControl.reset();
    }

    this.currentCountry = this.profilingFormService.currentCountry;

    this.states = Object.keys(
      this.currentCountry === 'LT' ? ltAddresses : usStatesCounties
    );

    this.filteredState$ = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterStates(value || ''))
    );
  }

  skip(): void {
    this.formControl.setValue(this.currentCountry === 'US' ? 'nonUS' : 'nonLT');
    this.profilingFormService.navigateToStep('business');
  }

  next(): void {
    const formValid = checkFormOrGroupInvalid(this.formControl);

    if (!formValid) {
      return;
    }

    this.profilingFormService.trackFieldCaptureEvent('location');
    this.profilingFormService.navigateToStep('business');
  }

  stateDisplayFn = (state: string) => {
    if (!state) {
      return '';
    }

    const titleCase = camelCaseToNormalText(state).replace(/\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });

    return this.currentCountry === 'US' ? titleCase : this.translateService.instant(
      `location.ltCounties.${state}`
    );
  };

  private filterStates(state: string): string[] {
    const filterVal = state.toLowerCase();

    return this.states.filter((state) => camelCaseToNormalText(state).toLowerCase().includes(filterVal));
  }
}
