import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'jarvis-ui-timepicker-toggle',
    templateUrl: './time-picker-toggle.component.html',
    styleUrls: [
        './time-picker-toggle.component.scss'
    ]
})

export class JarvisUiTimepickerToggleComponent implements OnInit {
    @Input() for: any;

    constructor() { }

    ngOnInit() {

    }

    openPicker(event: MouseEvent): void {
        event.stopPropagation();
        this.for.open();
    }
}