import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth/services';
import { MessagesType, MESSAGES_TYPE } from '@jarvis/messaging/tokens';
import { MessagingService } from '@jarvis/messaging/services';
import { JarvisNotificationService } from '@jarvis/notifications';
import { LinkUrls, LINK_URLS } from '@jarvis/services/tokens';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { JarvisLanguageService } from '@jarvis/services/language';
import { map } from 'rxjs/operators';

const LINKS_US = {
  user: {
    booking: {
      label: 'header.bookings',
      platform: 'marketplace',
      link: 'bookings-list'
    },
    message: {
      label: 'header.messages',
      platform: 'marketplace',
      link: 'messages'
    },
    menu: [
      {
        label: 'header.profileSettings',
        platform: 'marketplace',
        link: 'profile/settings'
      }
    ]
  },
  vendor: {
    booking: {
      label: 'header.bookings',
      platform: 'saas',
      link: 'bookings'
    },
    message: {
      label: 'header.messages',
      platform: 'saas',
      link: 'messages'
    },
    menu: [
      {
        label: 'header.overview',
        platform: 'saas',
        link: 'overview'
      },
      {
        label: 'header.listings',
        platform: 'saas',
        link: 'services'
      },
      {
        label: 'header.calendar',
        platform: 'saas',
        link: 'calendar'
      },
      {
        label: 'header.profileSettings',
        platform: 'marketplace',
        link: 'profile/settings'
      }
    ]
  }
};

const LINKS_LT = {
  user: {
    booking: {
      label: 'header.bookings',
      platform: 'marketplace',
      link: 'visos-rezervacijos'
    },
    message: {
      label: 'header.messages',
      platform: 'marketplace',
      link: 'zinutes'
    },
    menu: [
      {
        label: 'header.profileSettings',
        platform: 'marketplace',
        link: 'profilis/nustatymai'
      }/* ,
      {
        label: 'Foxy Wedding savitarna',
        link: 'https://foxywedding.lt/auth/login'
      } */
    ]
  },
  vendor: {
    booking: {
      label: 'header.bookings',
      platform: 'saas',
      link: 'bookings'
    },
    message: {
      label: 'header.messages',
      platform: 'saas',
      link: 'messages'
    },
    menu: [
      {
        label: 'header.overview',
        platform: 'saas',
        link: 'overview'
      },
      {
        label: 'header.listings',
        platform: 'saas',
        link: 'services'
      },
      {
        label: 'header.calendar',
        platform: 'saas',
        link: 'calendar'
      },
      {
        label: 'header.profileSettings',
        platform: 'marketplace',
        link: 'profilis/nustatymai'
      }
    ]
  }
};

@Component({
  selector: 'jarvis-profile-dropdown-content',
  templateUrl: './profile-dropdown-content.component.html',
  styleUrls: ['./profile-dropdown-content.component.scss']
})
export class ProfileDropdownContentComponent {

  userData$ = this.authService.userData$;
  isLoggedIn$ = this.authService.isLoggedIn$;
  bookingsCount$ = this.notificationService.navigationNotifications$('vendor').pipe(map(res => res.bookings));

  links;

  constructor(
    private authService: JarvisAuthService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    public notificationService: JarvisNotificationService,
    public messagingService: MessagingService,
    private languageService: JarvisLanguageService,
    @Inject(MESSAGES_TYPE) public userType: MessagesType,
    @Inject(LINK_URLS) public linkUrls: LinkUrls,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string,
  ) {
    if (domainCountry === 'lt') {
      this.links = LINKS_LT;
    } else {
      this.links = LINKS_US;
    }
  }

  navigateToSettings(): void {
    this.router.navigate(['/profile/settings'], { relativeTo: this.currentRoute });
  }

  logoutHost(): void {
    this.authService.signOut().subscribe(() => {
      this.router.navigateByUrl('/');
    });
  }

  logout(): void {
    this.authService.signOut().subscribe(() => {
      window.location.href = this.linkUrls.marketplace;
    });
  }

  moveToSaas(): void {
    window.location.href = this.linkUrls.saas;
    /* this.authService.moveUserToSaas.subscribe(() => {
      
    }); */
  }

  changeLanguage(): void {
    this.languageService.toggleLanguage();
  }

}
