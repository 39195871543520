import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DFormComponent, DFormActionService } from  './d-form.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [
    DFormComponent
  ],
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    TranslateModule,
    MatMenuModule,  
    MatIconModule,  
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule
    

  ],
  exports: [
    DFormComponent
  ],
  providers: [
    DFormActionService  
  ]  
})
export class JarvisDFormModule { }