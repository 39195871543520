<div
  class="claim-offer-container bztt-flex bztt-flex-col md:bztt-flex-row bztt-justify-between bztt-items-center bztt-w-full bztt-px-5 bztt-py-3 md:bztt-px-16 md:bztt-py-3"
>
  <div class="bztt-flex bztt-flex-col bztt-gap-3">
    <div class="bztt-flex bztt-flex-row bztt-gap-4">
      <div class="limited-offer-badge bztt-w-full md:bztt-w-auto">
        {{ 'pricingPage.claimOfferBar.limitedOffer' | translate }}
      </div>
    </div>
    <div class="title">
      {{ 'pricingPage.claimOfferBar.description1' | translate }}
      <!-- <a
        [routerLink]="'../../sign-up'"
        [queryParams]="{ source: 'pricing-page' }"
        ><span>{{
          'pricingPage.claimOfferBar.description2' | translate
        }}</span></a
      > {{ 'pricingPage.claimOfferBar.description3' | translate }} -->
    </div>
  </div>
  <div
    class="expires-value-mobile bztt-flex md:bztt-hidden"
    *ngIf="offersLeftCount"
  >
    {{
      'pricingPage.claimOfferBar.onlyLeftMobile'
        | translate : { count: offersLeftCount }
    }}
  </div>
  <div
    class="bztt-flex bztt-flex-row bztt-gap-16 bztt-items-center bztt-w-full md:bztt-w-auto"
  >
    <div
      class="expires-button md:bztt-flex bztt-flex-col bztt-justify-between bztt-items-center bztt-hidden bztt-min-w-[200px]"
    >
      <div class="expires-label">
        {{ 'pricingPage.claimOfferBar.membershipsLeft' | translate }}
      </div>
      <div class="expires-value" *ngIf="offersLeftCount">
        {{
          'pricingPage.claimOfferBar.onlyLeft'
            | translate : { count: offersLeftCount }
        }}
      </div>
    </div>

    <a
      class="bztt-w-full bztt-min-w-[200px]"
      [routerLink]="'../../sign-up'"
      [queryParams]="{ source: 'pricing-page' }"
    >
      <div
        class="claim-offer-button bztt-flex bztt-flex-col bztt-justify-center bztt-items-center"
        matRipple
        (click)="handleOfferClaim()"
      >
        {{ 'pricingPage.claimOfferBar.claimOffer' | translate }}
      </div>
    </a>
    <!-- <div
      class="close-offer-button bztt-flex bztt-justify-center bztt-items-center"
      matRipple
      (click)="handleOfferClose()"
    >
      <mat-icon>close</mat-icon>
    </div> -->
  </div>
</div>
