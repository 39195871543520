import { AdvertsPriceUnits, ExtractedServices, ServiceTypes } from './pricing-calculator.types';

const CATERING_SERVICE_TYPES = [
  ServiceTypes.venueCateringMenuMain,
  ServiceTypes.venueCateringMenuAdditional,
  ServiceTypes.venueSeparate,
  ServiceTypes.cateringDessert,
  ServiceTypes.cateringKidsMenu,
  ServiceTypes.cateringTasting
];

const BEVERAGE_SERVICE_TYPES = [
  ServiceTypes.venueCateringMenuBeverages,
  // ServiceTypes.venueCateringMenuBeveragesChildren
];

const VENUE_MAIN_SERVICES = [
  ServiceTypes.venueStandalone
];

const HOURLY_PRICE_UNITS = [
  AdvertsPriceUnits.hourlyPrice,
  AdvertsPriceUnits.currencyPerHour
];

export interface ServicePrices {
  lowestPrice: {
    service: any;
    price: number;
  };
  highestPrice: {
    service: any;
    price: number;
  };
  first: {
    service: any;
    price: number;
  };
  lowestWithCatering: {
    service: any;
    price: number;
  },
  lowestWithCateringAndBeverage: {
    service: any;
    price: number;
  },
  allPrices: {service: any; price: number;}[];
}

const createEmptyPrice = () => ({
  lowestPrice: {
    service: null,
    price: 0
  },
  highestPrice: {
    service: null,
    price: 0
  },
  first: {
    service: null,
    price: 0
  },
  lowestWithCatering: {
    service: null,
    price: 0
  },
  lowestWithCateringAndBeverage: {
    service: null,
    price: 0
  },
  allPrices: []
});

export function getServicePricesRaw(extractedServices: ExtractedServices, quantity: number, date: Date) {

  const mainServices = extractedServices.main;

  if (mainServices.length === 0) {
    return null;
  }

  const filteredServices = mainServices.filter(service => service.type !== ServiceTypes.venueSeparate);
  
  const globalMinBudgetService = extractedServices.additional.find(service => service.type === ServiceTypes.globalMinimalBudget);
  const venueSeparateService = extractedServices.main.find(service => service.type === ServiceTypes.venueSeparate);

  if (venueSeparateService) {
    venueSeparateService.quantity = quantity;
  }

  const globalMinBudget = globalMinBudgetService?.getFinalPrice(date) || 0;
  const globalMinBudgetType = globalMinBudgetService?.originalService.type;
  
  if (filteredServices.length === 0) {
    return null;
  }

  const prices = filteredServices.map(service => {
    const currentQuantity = service.quantity;
    const currentlyIncluded = service.included;
    
    service.quantity = 0;
    service.included = false;

    if (VENUE_MAIN_SERVICES.includes(service.type) && HOURLY_PRICE_UNITS.includes(service.priceUnit)) {
      service.quantity = 1;
    } else {
      service.quantity =  quantity;
    }

    let price = service.getFinalPrice(date);
    let finalPrice = service.getFinalPriceWithCharges(date);
    let isCatering = false;
    let isBeverage = false;

    if (CATERING_SERVICE_TYPES.includes(service.type) && !!venueSeparateService) {
      finalPrice += venueSeparateService.getFinalPriceWithCharges(date);
      price += venueSeparateService.getFinalPrice(date);
    }

    if (CATERING_SERVICE_TYPES.includes(service.type)) {
      isCatering = true;
    }

    if (BEVERAGE_SERVICE_TYPES.includes(service.type)) {
      isBeverage = true;
    }

    const diff = globalMinBudget - price;

    if (globalMinBudget && ( globalMinBudgetType === 'global' || (isCatering && globalMinBudgetType === 'catering') ) && diff > 0) {
      globalMinBudgetService.customPrice = diff;
      const minBudgetAdddition = globalMinBudgetService.getFinalPriceWithCharges(date);
      finalPrice += minBudgetAdddition;
      globalMinBudgetService.customPrice = null;
    }

    service.quantity = currentQuantity;
    service.included = currentlyIncluded;

    return {
      service,
      price: finalPrice,
      isCatering,
      isBeverage
    };
  });

  if (venueSeparateService) {
    venueSeparateService.included = false;
  }

  return prices;
}

export function getServicePrices(extractedServices: ExtractedServices, quantity = 1, date: Date = null): ServicePrices {
  const prices = getServicePricesRaw(extractedServices, quantity, date);

  if (!prices) {
    return createEmptyPrice();
  }

  const sorted = [...prices];

  sorted.sort((serviceA, serviceB) => {
    if (serviceA.price < serviceB.price) {
      return -1;
    }

    if (serviceA.price > serviceB.price) {
      return 1;
    }

    return 0;
  });

  const sortedWithoutZero = sorted.filter(service => service.price !== 0);
  const pricesWithoutZero = prices.filter(service => service.price !== 0);
  const pricesWithCatering = prices.filter(
    (service) => service.isCatering && service.price !== 0
  );
  const pricesWithBeverages = prices.filter(
    (service) => service.isBeverage && service.price !== 0
  );

  const lowest = sortedWithoutZero[0] || sorted[0];
  const lowestWithCatering = pricesWithCatering[0] || sorted[0];

  const lowestCatering = pricesWithCatering[0];
  const lowestBeverage = pricesWithBeverages[0];

  const lowestWithCateringAndBeverage = lowestBeverage && lowestCatering  ? lowestCatering.price + lowestBeverage.price : 0;

  const highest = sortedWithoutZero[sortedWithoutZero.length - 1] || sorted[sorted.length - 1];

  return {
    lowestPrice: {
      service: lowest.service,
      price: lowest.price
    },
    highestPrice: {
      service: highest.service,
      price: highest.price
    },
    first: {
      service: pricesWithoutZero[0]?.service,
      price: pricesWithoutZero[0]?.price
    },
    lowestWithCatering: {
      service: lowestWithCatering.service,
      price: lowestWithCatering.price,
    },
    lowestWithCateringAndBeverage: {
      price: lowestWithCateringAndBeverage,
      service: lowestBeverage?.service
    },
    allPrices: prices
  };
}