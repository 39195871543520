import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JarvisUiModalService } from '@jarvis/ui';
import { ReviewsAskForReviewComponent } from '../ask-for-review/ask-for-review.component';
import { BreezitReviewData } from '../reviews.types';

@Component({
  selector: 'jarvis-reviews-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class ReviewsSummaryComponent implements OnInit {

  @Input() data: BreezitReviewData;
  @Input() serviceBaseId: string;

  constructor(
    private modalService: JarvisUiModalService,
    private router: Router
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }

  editReviews(): void {
    this.router.navigateByUrl(`/services/${this.serviceBaseId}/reviews`);
  }

  askForReview(): void {
    const ref = this.modalService.openDialog(ReviewsAskForReviewComponent, {
      height: 'unset',
      maxHeight: '90vh',
      data: {
        serviceBaseId: this.serviceBaseId
      }
    });
    
  }

}
