import { Injectable, OnDestroy, Optional, SkipSelf, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

export enum JarvisDomains {
    US = 'US',
    LT = 'LT',
    default = 'default'
}

const REGIONAL_PARAMETERS = {
    US: {
        locale: 'en-US',
        countryCode: 'US',
        currency: 'USD'
    },
    LT: {
        locale: 'lt-LT',
        countryCode: 'LT',
        currency: 'EUR'
    },
    default: {
        locale: 'en-US',
        countryCode: 'default',
        currency: 'USD'
    }
};

/**
 * Currency service should NOT be used as a signleton
 * Provide this instance in root component of a specific module
 * Provide @Optional() decorator when injecting to avoid having null injector error
 */
@Injectable()
export class JarvisMeasurementUnitService implements OnDestroy {

    private activeDomain$ = new BehaviorSubject<Observable<keyof typeof JarvisDomains>>(of('default'));

    activeLocale$ = this.activeDomain$.pipe(
        switchMap(o => o),
        map((domain) => REGIONAL_PARAMETERS[domain].locale),
        shareReplay(1)
    );

    activeCurrencyCode$ = this.activeDomain$.pipe(
        switchMap(o => o),
        map((domain) => REGIONAL_PARAMETERS[domain].currency),
        shareReplay(1)
    );

    activeCountryCode$ = this.activeDomain$.pipe(
        switchMap(o => o),
        map((domain) => REGIONAL_PARAMETERS[domain].countryCode),
        shareReplay(1)
    );

    activeLocaleParameter$ = this.activeDomain$.pipe(
        switchMap(o => o),
        map((domain) => REGIONAL_PARAMETERS[domain]),
        shareReplay(1)
    );

    constructor(
        @Optional() @SkipSelf() parentService?: JarvisMeasurementUnitService,
        @Optional() vcr?: ViewContainerRef
    ) {
        (window as any).mTest = (domain: any) => {            
            this.setDomainObserver(domain);
        };

        if (parentService) {
            throw new Error('An instance of JarvisMeasurementUnitService is already loaded. Did you declare this service in a module?');
        }

        if (!vcr) {
            throw new Error('JarvisMeasurementUnitService was not declared in a component. This service should only be used as a component provider.');
        }
    }

    ngOnDestroy(): void {
        this.activeDomain$.complete();
    }

    setDomainObserver(observer: Observable<keyof typeof JarvisDomains> | keyof typeof JarvisDomains): void {
        const isObservable = observer instanceof Observable;
        this.activeDomain$.next(!isObservable ? of(observer) : observer);
    }
}