import { Pipe, PipeTransform } from '@angular/core';
import { HttpRequestState } from './http-state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'HttpRequestLoading',
    pure: true
})
export class JarvisHttpStateRequestLoadingPipe implements PipeTransform {
    transform<T>(value: Observable<HttpRequestState<T>> | null): Observable<boolean> {
        if (!value) {
            return null;
        }
        return value.pipe(map(state => state.isLoading));
    }
}

@Pipe({
    name: 'HttpRequestError',
    pure: true
})
export class JarvisHttpStateRequestErrorPipe implements PipeTransform {
    transform<T>(value: Observable<HttpRequestState<T>> | null): Observable<any> {
        if (!value) {
            return null;
        }
        return value.pipe(map(state => state.error));
    }
}