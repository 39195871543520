import { MAT_LEGACY_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER as MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/legacy-tooltip';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jarvis-ui-marketplace-card-review-info',
  templateUrl: 'review-info.component.html',
  styles: [
    `.text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
    }`
  ],
  providers: [MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER]
})

export class JarvisUiMarketplaceCardReviewInfoComponent implements OnInit {
  @Input() data;

  constructor() { }

  ngOnInit() {}
}
