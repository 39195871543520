
import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  inject,
} from '@angular/core';
import { JarvisTrackingService } from '@jarvis/services';

import SwiperCore, { Pagination, Navigation, Scrollbar } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Pagination, Navigation, Scrollbar]);

@Component({
  selector: 'jarvis-homepage-package-section',
  templateUrl: './homepage-package-section.component.html',
  styleUrls: ['./homepage-package-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomepagePackageSectionComponent implements AfterViewInit {
  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  private trackService = inject(JarvisTrackingService);

  packagesConfig = {
    slidesPerView: 1,
    grabCursor: true,
    scrollbar: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  };

  sections = [
    {
      id: 'section-1',
      header: 'landing.homepage.sections.section-1.title',
      text: 'landing.homepage.sections.section-1.description',
      subtexts: [
        'landing.homepage.sections.section-1.item-1',
        'landing.homepage.sections.section-1.item-2',
      ],
      image: 'landing.homepage.sections.section-1.image',
    },
    {
      id: 'section-2',
      header: 'landing.homepage.sections.section-2.title',
      text: 'landing.homepage.sections.section-2.description',
      subtexts: [
        'landing.homepage.sections.section-2.item-1',
        'landing.homepage.sections.section-2.item-2',
      ],
      image: 'landing.homepage.sections.section-2.image',
    },
    {
      id: 'section-3',
      header: 'landing.homepage.sections.section-3.title',
      text: 'landing.homepage.sections.section-3.description',
      subtexts: [
        'landing.homepage.sections.section-3.item-1',
        'landing.homepage.sections.section-3.item-2',
      ],
      image: 'landing.homepage.sections.section-3.image',
    },
  ];

  constructor(public cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cd.detectChanges();

    this.swiper.indexChange.subscribe(() => {
      this.trackService.handleEvent({
        trackers: ['amplitude', 'mixpanel'],
        eventName: 'feature_card_click',
      });

      this.cd.detectChanges();
    });
  }

  swipePrev() {
    this.swiper.swiperRef.slidePrev();
  }
  swipeNext() {
    this.swiper.swiperRef.slideNext();
  }
}
