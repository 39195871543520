
<h2 class="bztt-font-maple-r bztt-text-2xl">{{'profiling.price.title' | translate}}</h2>

<span class="bztt-text-sm bztt-text-[#717171] bztt-mb-6">{{'profiling.price.hint' | translate}}</span>

<mat-form-field class="bztt-w-full">
    <input autocomplete="off" placeholder="{{'profiling.price.enterPrice' | translate}}" [formControl]="formControl" matInput />
    <mat-error *ngIf="formControl.hasError('required')">{{'errors.fieldRequired' | translate}}</mat-error>
    <mat-error *ngIf="formControl.hasError('min')">{{'profiling.price.enterCorrectValue' | translate}}</mat-error>
    <mat-icon matSuffix>payments</mat-icon>
</mat-form-field>

<button class="md:bztt-mt-auto" color="accent" [disabled]="formControl.invalid" (click)="next()" app-button>{{'profiling.next' | translate}}</button>
