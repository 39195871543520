import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AIChatDataRecsLinkService {
  constructor() {}

  getSearchFilterParams(data, locations) {
    if (!data) return '';
    let {
      person_count,
      exact_event_date,
      budget,
      indoor_outdoor,
      ceremony,
      alcohol,
      catering_drinks,
      location,
      event_date,
    } = data;

    const params = new URLSearchParams();
    params.append('category', 'venues');
    params.append('eventType', 'wedding');

    // const venueSubtype = [];
    // if (indoor_outdoor) venueSubtype.push(indoor_outdoor);
    // if (ceremony) venueSubtype.push('ceremony');
    // if (venueSubtype.length)
    //     params.append('venueSubtype', venueSubtype.join(','));

    const cateringType = [];
    if (alcohol === 'yes' && catering_drinks === 'yes')
      cateringType.push('beverages');
    if (catering_drinks === 'yes' && (!alcohol || alcohol === 'no'))
      cateringType.push('catering');
    if (catering_drinks === 'no') cateringType.push('bringMyOwn');
    if (cateringType.length)
      params.append('cateringType', cateringType.join(','));

    if (person_count) params.append('personCount', person_count);

    if (exact_event_date) {
      params.append('dateFrom', this.checkIfPastAndAddToDate(exact_event_date));
    } else if (event_date) {
      const eventDate = this.getEventDate(event_date);
      params.append('dateFrom', eventDate);
    }

    if (budget) {
      if (budget > 10000) {
        const budgetPercentage = Math.round((budget * 0.7) / 1000) * 1000;
        params.append('priceFrom', budgetPercentage.toString());
      }

      params.append('priceTo', budget);
    }

    if (location && location.length) {
      location = location.filter((loc) => loc !== 'california');

      const foundLocationSearchData = this.findMostAccurateLocationSlug(
        location,
        locations
      );

      if (foundLocationSearchData)
        params.append(
          foundLocationSearchData.key,
          foundLocationSearchData.value
        );
    }
    console.log('params', params.toString());
    return params.toString();
  }

  checkIfPastAndAddToDate(date): string {
    try {
      const currentDate = moment();
      let loopCount = 0;
      const maxLoops = 5;
      while (
        moment(date).isBefore(currentDate, 'day') &&
        loopCount < maxLoops
      ) {
        date = moment(date).add(1, 'year').format('YYYY-MM-DD');
        loopCount++;
      }
      return date;
    } catch (error) {
      return date;
    }
  }

  getEventDate(eventData) {
    try {
      const { weekend, months, year } = eventData;

      const monthsArray = months.split(',').map((month) => month.trim());

      let date = moment(`${year}-${monthsArray[0]}-01`, 'YYYY-MMMM-DD');

      if (weekend === 'yes') {
        while (date.day() !== 6 && date.day() !== 0) {
          date.add(1, 'day');
        }
      } else {
        while ([1, 2, 3].includes(date.day())) {
          date.add(1, 'day');
        }
      }
      return this.checkIfPastAndAddToDate(date);
    } catch (error) {
      return '';
    }
  }

  findMostAccurateLocationSlug(locations, data) {
    try {
      const foundFromData = data.filter((d) => locations.includes(d.key));

      if (foundFromData.length === 1 && foundFromData[0].slug) {
        return {
          key: 'location',
          value: foundFromData[0].slug,
        };
      }

      if (
        foundFromData.length > 1 ||
        (foundFromData.length === 1 && !foundFromData[0].slug)
      ) {
        return {
          key: 'locationKeys',
          value: foundFromData.map((d) => d.key).join(','),
        };
      }

      const matches = this.findClosestMatches(locations, data);

      if (matches.length === 1 && matches[0].slug) {
        return {
          key: 'location',
          value: matches[0].slug,
        };
      }

      if (matches.length > 1 || (matches.length === 1 && !matches[0].slug)) {
        return {
          key: 'locationKeys',
          value: matches.map((m) => m.key).join(','),
        };
      }

      return null;
    } catch (error) {
      return null;
    }
  }

  findClosestMatches(locations, data) {
    if (locations && typeof locations !== 'object') locations = [locations];

    const matches = [];

    const normalizedLocations = locations.map((location) =>
      location.toLowerCase()
    );

    data
      .filter((loc) => !loc.isCity)
      .forEach((obj) => {
        if (!obj.key) return;

        const keyWords = obj.key.split('_').map((word) => word.toLowerCase());

        const matchCount = normalizedLocations.reduce((count, location) => {
          const wordMatches = keyWords.filter((word) =>
            location.includes(word)
          );
          return count + (wordMatches.length >= 2 ? 1 : 0);
        }, 0);

        if (matchCount > 0) {
          matches.push(obj);
        }
      });

    return matches;
  }
}
