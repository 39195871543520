import { OnDestroy, Optional, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { JarvisMeasurementUnitService } from './measurement-unit.service';

@Pipe({
  name: 'measurementCurrency',
  pure: false
})

export class JarvisMeasurementUnitCurrencyPipe implements PipeTransform, OnDestroy {

  private currentValue = new BehaviorSubject(null);
  private currentObservable = this.measurementUnitService?.activeLocaleParameter$.pipe(
    switchMap((localeParameters: any) => this.currentValue.pipe(
      map((value: { value: number, round: boolean }) => {
        const formatter = Intl.NumberFormat(localeParameters.locale, {
          style: 'currency',
          currency: localeParameters.currency,
          minimumFractionDigits: value.round ? 0 : 2,
          maximumFractionDigits: value.round ? 0 : 2
        });

        return formatter.format(value.value);
      })
    ))
  );

  constructor(
    @Optional() private measurementUnitService: JarvisMeasurementUnitService
  ) { }

  ngOnDestroy(): void {
    this.currentValue.complete();
  }

  transform(value: number, round = false): Observable<string> {
    this.currentValue.next({ value, round });
    return this.currentObservable;
  }
}
