import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASE_URL } from '../tokens/base-url.token';

@Injectable({ providedIn: 'root' })
export class VideoThumbnailsService {
  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    private httpService: HttpClient
  ) {}

  getVideoThumbnail(link: string) {
    return this.httpService.post(`${this.baseUrl}/common/videoThumbnails`, {
      link,
    });
  }
}
