<div class="bztt-flex bztt-flex-col bztt-gap-2">
  <span>{{'assistantChat.quoteSummary.eventDetails' | translate}}</span>
  <ul class="bztt-ml-2 bztt-flex bztt-flex-col bztt-gap-2 summary-list">
    <li>
      <span>{{'assistantChat.quoteSummary.eventDate' | translate}} {{(eventDate ? (eventDate | date) : 'assistantChat.quoteSummary.flexible') | translate}}</span>
    </li>
    <li>
      <span>{{'assistantChat.quoteSummary.guestCount' | translate}} {{guestCount}}</span>
    </li>
  </ul>
  <span>{{'assistantChat.quoteSummary.contactDetails' | translate}}</span>
  <ul class="bztt-ml-2 bztt-flex bztt-flex-col bztt-gap-2 summary-list">
    <li>
      <span>{{name}} {{surname}}</span>
    </li>
    <li>
      <span>{{email}}</span>
    </li>
    <li *ngIf="phoneNo">
      <span>{{phoneNo}}</span>
    </li>
  </ul>
</div>