import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerNeedsComponent } from  './customer-needs.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [
    CustomerNeedsComponent
  ],
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,  
    MatInputModule,
    MatSelectModule,
    MatButtonModule,    
  ],
  exports: [
    CustomerNeedsComponent
  ]
})
export class CustomerNeedsModule { }