import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { BASE_URL } from '@jarvis/services';

@Injectable({ providedIn: 'root' })
export class JarvisAuthUserInviteInfoResolver implements Resolve<any> {
    constructor(
        @Inject(BASE_URL) private baseUrl: string,
        private httpService: HttpClient
    ) {}
    resolve(route: ActivatedRouteSnapshot): any {
        return this.httpService.get(`${this.baseUrl}/invite/checkInvite/${route.params.hash}`);
    }
}