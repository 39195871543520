<app-modal-wrapper class="modal-wrapper success" (closed)="close()">
<div class="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
        <span><mat-icon class="icon bztt-h-16 bztt-w-16" [ngClass]="data.icon">{{data.icon?data.icon:'verified'}}</mat-icon></span>
        <span *ngIf="data.title" class="mt-3 fs-3 bolder">{{ data.title }}</span>
        <span *ngIf="!data.title" class="mt-3 fs-3 bolder">{{ i18nKey + ".title" | translate }}</span>

        <span *ngIf="data.message" class="mt-3 message">{{ data.message }}</span>
        <span *ngIf="!data.message" class="mt-3 message">{{ i18nKey + ".message" | translate: data.values }}</span>
</div>
</app-modal-wrapper>
