import { Component } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import {UntypedFormControl } from '@angular/forms';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';
import { checkFormOrGroupInvalid } from '../../../../utils/profiler.utils';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { LayoutService } from '@jarvis/ui/layout';

const YEAR_FORMAT = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    dateA11yLabel: 'LL'
  },
};

@Component({
  templateUrl: './experience.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: YEAR_FORMAT
    }
  ]
})
export class ExperienceComponent {

  formControl:UntypedFormControl;
  isMobile = this.layoutService.isMobileObserver;

  private currentYear = moment().year();

  constructor(
    private profilingFormService: JarvisProfilingFormService,
    private layoutService: LayoutService
  ) {
    this.formControl = this.profilingFormService.getStepControl('tenure');
  }

  yearSelected(normalizedYear: Moment, datepicker: MatDatepicker<Moment>): void {
    const yearString = normalizedYear.year().toString();
    this.formControl.setValue(yearString);
    datepicker.close();
  }

  viewChanged(datepicker: MatDatepicker<Moment>): void {
    datepicker.close();
  }

  next(): void {
    const formValid = checkFormOrGroupInvalid(this.formControl);

    if (!formValid) {
      return;
    }

    this.profilingFormService.trackFieldCaptureEvent('experience_information');
    this.profilingFormService.navigateToStep('location');
  }

  yearFilter = (date: Moment | null): boolean => {
    if (!date) {
      return false;
    }
    const valueToFilter = date.year();
    return  (1991 - 1 < valueToFilter) &&
            (this.currentYear + 1 > valueToFilter);
  };
}
