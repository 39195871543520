import { NgModule } from '@angular/core';
import { OnlyMobileDirective } from './directives/only-mobile';

@NgModule({
    imports: [],
    exports: [
        OnlyMobileDirective
    ],
    declarations: [
        OnlyMobileDirective
    ]
})
export class JarvisUiLayoutModule { }
