import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'quote-summary',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ],
  templateUrl: 'quote-summary.component.html',
  styleUrls: [
    './quote-summary.component.scss'
  ]
})

export class QuoteSummaryComponent implements OnInit {
  
  @Input() bookingData!: any;

  @Input() name: string;
  @Input() surname: string;
  @Input() email: string;
  @Input() phoneNo: string;
  @Input() eventDate: string;
  @Input() guestCount: string;

  ngOnInit() {
    if (this.bookingData) {
      this.name = this.bookingData.customer.name;
      this.surname = this.bookingData.customer.surname;
      this.email = this.bookingData.customer.email;
      this.phoneNo = this.bookingData.customer.phoneNo;
      this.eventDate = this.bookingData.eventDate;
      this.guestCount = this.bookingData.options.guestNo;
    }
  }

}
