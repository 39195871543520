
import { AfterViewInit, Component, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import SwiperCore, { Pagination, Navigation, Scrollbar } from "swiper";
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Pagination, Navigation, Scrollbar]);

@Component({
  selector: 'jarvis-pricing-reviews',
  templateUrl: './pricing-reviews.component.html',
  styleUrls: ['./pricing-reviews.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PricingReviewsComponent implements AfterViewInit {
  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  window = window;

  pricingReviewsConfig = {
    slidesPerView: 1,
    grabCursor: true,
    scrollbar: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  };

  reviews = [
    {
      image: 'slide-1-min.jpeg',
      name: 'pricingPage.reviews.review-1.name',
      vendor: 'pricingPage.reviews.review-1.vendor',
      description: 'pricingPage.reviews.review-1.description'
    },
    {
      image: 'slide-2-min.jpeg',
      name: 'pricingPage.reviews.review-2.name',
      vendor: 'pricingPage.reviews.review-2.vendor',
      description: 'pricingPage.reviews.review-2.description'
    },
    {
      image: 'slide-3-min.jpeg',
      name: 'pricingPage.reviews.review-3.name',
      vendor: 'pricingPage.reviews.review-3.vendor',
      description: 'pricingPage.reviews.review-3.description'
    },
    {
      image: 'slide-4-min.jpeg',
      name: 'pricingPage.reviews.review-4.name',
      vendor: 'pricingPage.reviews.review-4.vendor',
      description: 'pricingPage.reviews.review-4.description'
    },
    {
      image: 'slide-5-min.jpeg',
      name: 'pricingPage.reviews.review-5.name',
      vendor: 'pricingPage.reviews.review-5.vendor',
      description: 'pricingPage.reviews.review-5.description'
    },
    {
      image: 'slide-6-min.jpeg',
      name: 'pricingPage.reviews.review-6.name',
      vendor: 'pricingPage.reviews.review-6.vendor',
      description: 'pricingPage.reviews.review-6.description'
    },
  ];

  constructor(public cd: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.cd.detectChanges();

    this.swiper.indexChange.subscribe(() => {
      this.cd.detectChanges();
    });
  }

  swipePrev() {
    this.swiper.swiperRef.slidePrev();
  }
  swipeNext() {
    this.swiper.swiperRef.slideNext();
  }

}
