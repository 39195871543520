import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'Timer',
    pure: true
})

export class TimerPipe<T> implements PipeTransform {
    transform(repeatValue: T, timer: Observable<null>): Observable<T> {
        return timer.pipe(
            map(() => repeatValue)
        )
    }
}