import { AfterViewInit, Directive, ElementRef } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'mat-form-field[noInfix]' })
export class FormFieldNoInfixDirective implements AfterViewInit {
    constructor(
        private elementRef: ElementRef
    ) { }

    ngAfterViewInit(): void {
        const outlineEl: HTMLDivElement = this.elementRef.nativeElement.querySelector('.mat-form-field-outline');
        const outlineThickEl: HTMLDivElement = this.elementRef
            .nativeElement.querySelector('.mat-form-field-outline.mat-form-field-outline-thick');
        const infixEl: HTMLDivElement = this.elementRef.nativeElement.querySelector('.mat-form-field-infix');
        const suffixEl: HTMLDivElement = this.elementRef.nativeElement.querySelector('.mat-form-field-suffix');
        const outlineFlex: HTMLDivElement = this.elementRef
            .nativeElement.querySelector('.mat-form-field-flex');

        const arrowWrapperEl: HTMLDivElement = this.elementRef.nativeElement.querySelector('.mat-select-arrow-wrapper');

        if (arrowWrapperEl) {
            arrowWrapperEl.style.transform = 'unset';
        }

        if (outlineFlex) {
            outlineFlex.style.marginTop = 'unset';
        }

        if (outlineEl) {
            outlineEl.style.top = '-.25em';
            outlineEl.style.bottom = '-.2em';
        }

        if (outlineThickEl) {
            outlineThickEl.style.top = '-.25em';
            outlineThickEl.style.bottom = '-.2em';
        }

        if (infixEl) {
            infixEl.style.borderTop = 'unset';
        }

        if (suffixEl) {
            suffixEl.style.top = 'unset';
            suffixEl.style.alignSelf = 'center';
            suffixEl.style.lineHeight = '1';
        }
    }
}