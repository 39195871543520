import { formatDate } from '@angular/common';
import * as moment from 'moment';


export abstract class DateUtils {

public static convertTZ(dt: moment.Moment | Date | string | null | undefined, newTzOffset:number = 0, direction:number = 1): Date {   

 if (dt === null) return null;

 const dtVal = moment(dt).toDate();

 const utc = dtVal.getTime() + dtVal.getTimezoneOffset() * 60000 * direction;

 const dtValNew = new Date(utc + (3600000 * newTzOffset) );
 
 const resultDate = new Date(0)

 resultDate.setFullYear(dtValNew.getUTCFullYear(), dtValNew.getUTCMonth(), dtValNew.getUTCDate())
 resultDate.setHours(dtValNew.getUTCHours(), dtValNew.getUTCMinutes(), dtValNew.getUTCSeconds(), dtValNew.getUTCMilliseconds())
 
 return resultDate;
}

public static sameAsGMT(_date:Date | undefined) {
  if (!_date) return _date;
  const date = moment(_date).toDate();
  return moment.utc().set({
      year: date?.getFullYear(),
      month: date?.getMonth(),
      date: date?.getDate(),
      hours: date?.getHours(),
      minutes: date?.getMinutes(),
      seconds: date?.getSeconds(),
      milliseconds: date?.getMilliseconds(),
    }).toDate();
}  

public static convertTZasMoment(dt: moment.Moment | Date | string | null | undefined, newTzOffset:number = 0, direction:number = 1): moment.Moment {   
  return moment.utc(DateUtils.convertTZ(dt,newTzOffset, direction));
}

public static generateDateTicks(locale:string, format:string, from = 0, to = 14, dt = new Date() ):
   {dt:Date, title: string}[] {
  const start = moment(dt).startOf("day");
  const dayTicks = [];

  for (let i = from; i<to; i++) {
    const nextDay = moment(start).add(i,"day");
    dayTicks.push({
      dt : nextDay.toDate(),
      title: nextDay.locale(locale).format(format)
    });
  };

  return dayTicks;  
}    

public static generateTimeTicks(locale:string, tick = 60, from = 0, to = 24 ):{minutes:number, title:string}[] {
  const timeTicks = [];
  for (let i = from * 60 ; i < to * 60; i = i + tick)
    timeTicks.push({
        minutes: i,
        caption: formatDate(moment( 
            Math.floor(i / 60).toString()
                .padStart(2, '0') + ':' +
            (i % 60).toString().padStart(2, '0'),
            'HH:mm'
          ).toDate(),
          'shortTime',
          locale
        )
      });  
  return timeTicks;         
}

public static getMinutes(date: Date | undefined, def: number) : number {
   if (!date) return def;
   return moment.duration(moment(date).format("HH:mm")).asMinutes();
} 

public static addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

}

