import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from 'date-fns';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'TimePassed',
  pure: true,
  standalone: true
})
export class TimePassedPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private translationService: TranslateService
  ) {}

  transform(value: Date): string {
    const currentDate = new Date();
    const minuteDiff = differenceInMinutes(currentDate, value);

    if (minuteDiff < 60) {
      if (minuteDiff === 0) {
        return this.translationService.instant('messages.timePipe.now');
      }

      return this.translationService.instant(
        minuteDiff > 1 ? 'messages.timePipe.minutesAgo' : 'messages.timePipe.minuteAgo',
        { val: minuteDiff }
      );
    }

    const hourDiff = differenceInHours(currentDate, value);

    if (hourDiff < 24) {
      return this.translationService.instant(
        hourDiff > 1 ? 'messages.timePipe.hoursAgo' : 'messages.timePipe.hourAgo',
        { val: hourDiff }
      );
    }

    const dayDiff = differenceInDays(currentDate, value);

    if (dayDiff < 7) {
      return this.translationService.instant(
        dayDiff > 1 ? 'messages.timePipe.daysAgo' : 'messages.timePipe.dayAgo',
        { val: dayDiff }
      );
    }

    return this.datePipe.transform(value, 'short');
  }
}
