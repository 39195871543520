
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LandingDialogService } from '../services';
import { Router } from '@angular/router';


@Component({
  selector: 'landings-lets-breezit-section',
  templateUrl: './lets-breezit-section.component.html',
  styleUrls: ['./lets-breezit-section.component.scss']
})
export class LetsBreezitSectionComponent implements OnInit {
  @Input() type: 'user' | 'vendor' = 'vendor';
  @Input() texts: { title: string, description: string };
  @Input() buttonText: string;
  @Input() contactUrl: string;
  @Input() routerUrl: string;
  @Input() qparams: any;

  window = window;

  @Output() buttonClick = new EventEmitter();

  configuration = {
    vendor: {
      title: 'landing.saas.letsBreezit.title',
      description: `landing.saas.letsBreezit.description`
    },
    user: {
      title: 'Time to Breezit!',
      description: 'Drop notebooks, spreadsheets and papers. Turn your event planning into a breeze.'
    }
  }

  constructor(
    private dialogService: LandingDialogService,
    private router: Router
  ) { }

  ngOnInit(): void { }

  openContactFormDialog(): void {
    // this.dialogService.openContactFormDialog();
    this.router.navigateByUrl('/auth/register');
  }

  handleButtonClick() {
    this.buttonClick.emit()
  }
}
