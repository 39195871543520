<jarvis-ui-card-wrapper
  class="bztt-px-5 bztt-pb-5 bookings-detail"
  [class.bztt-pt-8]="!userId"
>
  <a
    *ngIf="userId"
    [routerLink]="['/bookings']"
    class="bztt-border-0 bztt-pt-0 bztt-text-base bztt-flex bztt-flex-row bztt-items-center bztt-mt-3 bztt-mb-3"
  >
    <mat-icon>chevron_left</mat-icon>{{ 'bookings.detail.back' | translate }}
  </a>
  <div
    class="bztt-w-full bztt-row bztt-grid bztt-p-3 md:bztt-p-6 bztt-rounded-lg bztt-grid-cols-1 md:bztt-grid-cols-2 bztt-gap-4 bztt-border bztt-border-[#e5e7eb] bztt-bg-white bztt-shadow-md"
  >
    <div class="bztt-w-full">
      <div class="bztt-w-full">
        <button
          mat-button
          [class]="'state-' + state"
          class="md:bztt-hidden bztt-flex bztt-rounded-lg bztt-text-sm bztt-bg-[#FFF7F3] bztt-text-[#1f1f1f] bztt-mr-6 bztt-font-semibold bztt-px-3 bztt-py-2 bztt-pointer-events-none"
        >
          {{ 'bookings.state.' + state + '.name' | translate }}
        </button>
        <div
          class="bztt-hidden md:bztt-flex bztt-text-xl bztt-font-semibold bztt-text-[#1F1F1F] bztt-border-0 bztt-p-0"
        >
          {{ brandName }}
        </div>
        <div
          class="bztt-hidden md:bztt-flex bztt-text-xs md:bztt-text-sm bztt-pb-1"
        >
          {{ 'serviceTypeCategories.' + serviceBase.type | translate }}
        </div>
      </div>
    </div>
    <div
      class="bztt-justify-self-end bztt-hidden md:bztt-flex bztt-flex-row bztt-items-center"
    >
    <!-- [matMenuTriggerFor]="previewButtonMenu" -->
      <div class="bztt-flex bztt-flex-row bztt-items-center bztt-gap-3">
        <div
          *ngIf="userId"
          class="bztt-ml-5 bztt-mr-1 bztt-flex bztt-flex-row bztt-items-center bztt-cursor-pointer bztt-gap-2"
          (click)="toChatRoom(detailData)"
        >
          <mat-icon
            [matBadgeHidden]="!bookingsUtil.haveNewMessages(userId, detailData)"
            [matBadge]="1"
            class="material-icons-outlined"
            >message</mat-icon
          >
          {{ 'bookings.detail.message' | translate }}
        </div>
        <a
        target="_blank"
        [href]="proposalURL"
        class="bztt-border-0 bztt-leading-0"
        *ngIf="!detailData.options.customAgreement"
      >
        <div
          class="bztt-w-fit bztt-px-5 bztt-hidden md:bztt-flex bztt-font-normal bztt-text-base bztt-flex-row bztt-items-center bztt-cursor-pointer bztt-gap-2"
        >
          <mat-icon class="material-icons-outlined">visibility</mat-icon>
          {{ 'bookings.detail.previewProposal' | translate }}
        </div>
       </a>

       <a
       class="bztt-border-0 bztt-leading-0"
       *ngIf="detailData.options.customAgreement"
       [matMenuTriggerFor]="previewButtonMenu"
     >
       <div
         class="bztt-w-fit bztt-px-5 bztt-hidden md:bztt-flex bztt-font-normal bztt-text-base bztt-flex-row bztt-items-center bztt-cursor-pointer bztt-gap-2"
       >
         <mat-icon class="material-icons-outlined">visibility</mat-icon>
         {{ 'bookings.detail.preview' | translate }}
       </div>
      </a>

        <mat-menu #previewButtonMenu="matMenu" class="send-menu">
          <a
            mat-menu-item
            target="_blank"
            [href]="proposalURL"
            class="bztt-border-0 bztt-leading-0"
          >
            <button
              class="bztt-max-w-[148px] md:bztt-max-w-[250px] bztt-min-w-[148px] md:bztt-min-w-[180px] bztt-text-left"
            >
              {{ 'bookings.detail.proposal' | translate }}
            </button>
          </a>
          <button
            mat-menu-item
            (click)="sendContract()"
            class="bztt-max-w-[148px] md:bztt-max-w-[250px] bztt-min-w-[148px] md:bztt-min-w-[180px] bztt-font-semibold"
          >
            {{ 'bookings.detail.agreement' | translate }}
          </button>
        </mat-menu>

        <ng-container *ngTemplateOutlet="bookingActions"></ng-container>
      </div>
    </div>
    <div class="bztt-h-full">
      <div
        class="bztt-flex bztt-flex-col bztt-items-start bztt-gap-4 md:bztt-sticky md:bztt-top-3"
      >
        <button
          mat-button
          class="bztt-hidden md:bztt-flex bztt-rounded-lg bztt-text-sm bztt-text-[#1f1f1f] bztt-mr-6 bztt-font-semibold bztt-px-3 bztt-py-2 bztt-pointer-events-none"
          [class]="'state-' + state"
        >
          {{ 'bookings.state.' + state + '.name' | translate }}
        </button>
        <div class="md:bztt-hidden bztt-flex bztt-flex-col bztt-gap-2">
          <a
            class="bztt-text-xl bztt-font-semibold bztt-text-[#1F1F1F] bztt-border-0 bztt-p-0"
            [routerLink]="['/', serviceEvent.slug]"
            target="_blank"
          >
            {{ brandName }}
          </a>
          <div class="bztt-text-xs md:bztt-text-sm bztt-pb-1">
            {{ 'serviceTypeCategories.' + serviceBase.type | translate }}
          </div>
        </div>
        <div
          class="bztt-w-full bztt-p-3 bztt-rounded-lg bztt-bg-[#F9F9F9] md:bztt-p-0 md:bztt-bg-transparent"
        >
          <div
            class="bztt-text-2xl bztt-font-semibold bztt-text-[#1F1F1F] bztt-border-0 bztt-p-0"
          >
            <div class="bztt-flex bztt-gap-4 bztt-items-center">
              <img
                *ngIf="detailData.customerId?.profilePhoto"
                class="bztt-h-14 bztt-w-14 bztt-object-cover bztt-rounded-full"
                [src]="detailData.customerId?.profilePhoto"
              />
              <div
                class="bztt-h-14 bztt-w-14 bztt-object-cover bztt-text-xl bztt-rounded-full bztt-bg-[#F9F9F9] bztt-flex bztt-justify-center bztt-items-center"
                *ngIf="!detailData.customerId?.profilePhoto"
              >
                {{
                  bookingsUtil.getCustomerName(
                    detailData.customer,
                    detailData.customerId
                  )
                    | personInitials
                    | uppercase
                }}
              </div>

              <div class="bztt-text-sm md:bztt-text-lg bztt-font-semibold">
                {{
                  bookingsUtil.getCustomerName(
                    detailData.customer,
                    detailData.customerId
                  )
                }}
              </div>
            </div>
          </div>

          <div
            [class.bztt-hidden]="!showMore"
            class="bztt-w-full bztt-mt-2 bztt-py-3 bztt-px-3 md:bztt-py-6 md:bztt-px-6 md:bztt-grid bztt-grid-cols-1 bztt-gap-3 bztt-border bztt-rounded-lg bztt-bg-[#F9F9F9] bztt-border-[#F9F9F9] bztt-flex-col bztt-items-start"
          >
            <!-- Contacts -->
            <bookings-contact-info
              [(showMore)]="showMore"
              [detailData]="detailData"
            ></bookings-contact-info>
          </div>
          <div
            (click)="showMore = !showMore"
            class="md:bztt-hidden bztt-text-sm bztt-pb-0 bztt-w-full bztt-flex bztt-justify-end bztt-items-center"
          >
            <span>
              {{
                'bookings.detail.view' + (showMore ? 'Less' : 'More')
                  | translate
              }}
            </span>
            <mat-icon>{{ showMore ? 'expand_less' : 'expand_more' }}</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <!-- Inquiry details -->
    <div
      class="bztt-w-full bztt-p-3 md:bztt-px-6 md:bztt-pb-6 bztt-rounded-lg bztt-bg-[#F9F9F9]"
    >
      <bookings-services-list
        [detailData]="detailData"
        (openBookingEdit)="setUIState(UIStates.showBookingEdit)"
      ></bookings-services-list>
    </div>
    <div
      class="bztt-w-full md:bztt-col-span-2 bztt-pb-2 bztt-px-3 bztt-pt-3 bztt-rounded-lg bztt-bg-[#F9F9F9]"
    >
      <div
        #notesElm
        class="bztt-text-lg bztt-pb-3 bztt-font-semibold bztt-text-[#1F1F1F]"
      >
        {{ 'bookings.detail.notesTitle' | translate }}
      </div>
      <mat-form-field
        appearance="outline"
        floatLabel="never"
        class="note w-100"
      >
        <textarea
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="15"
          (blur)="updateIfChanged()"
          (focus)="toTop(notesElm)"
          placeholder="{{ 'bookings.detail.notesPlaceHolder' | translate }}"
          type="text"
          [(ngModel)]="detailData.notes"
          matInput
        >
        </textarea>
      </mat-form-field>
    </div>

    <div
      class="bztt-w-full descriptor md:bztt-col-span-2 bztt-px-3 md:bztt-px-6 bztt-rounded-lg bztt-bg-[#F9F9F9]"
    >
      <mat-accordion displayMode="flat">
        <mat-expansion-panel
          class="mat-elevation-z0 bztt-bg-transparent bztt-p-0"
        >
          <mat-expansion-panel-header
            class="hover:bztt-bg-transparent bztt-font-medium bztt-p-0 bztt-pr-1"
          >
            <mat-panel-title
              class="bztt-font-sora bztt-text-lg bztt-font-semibold bztt-text-[#1F1F1F]"
              >{{
                'bookings.detail.additionTitle' | translate
              }}</mat-panel-title
            >
          </mat-expansion-panel-header>
          <div class="col-md">
            <div class="row">
              <div class="col d-flex file-uploader-container pb-3">
                <jarvis-ui-file-uploader
                  [noUser]="!userId"
                  [customSaveParams]="{ booking: detailData._id }"
                  class="bztt-max-w-full"
                  (onAction)="fileUploaderAction($event)"
                ></jarvis-ui-file-uploader>
              </div>
            </div>

            <div class="row" *ngIf="facts.contract == 'standard'">
              <div class="col pb-3 pt-3 d-flex align-items-center">
                <a (click)="agreementViewUI().subscribe()" class="border-0">
                  <mat-icon class="material-icons">attach_file</mat-icon></a
                >
                {{ 'bookings.detail.breezitContractTitle' | translate }}
              </div>
            </div>

            <div
              class="row"
              *ngFor="let item of options.files; let last = last"
            >
              <div class="col pb-3 pt-3 d-flex align-items-center border-top">
                <a [href]="item.url" target="_blank" class="border-0">
                  <mat-icon class="material-icons">attach_file</mat-icon></a
                >{{ item.filename }}
              </div>
            </div>

            <div
              class="row"
              *ngFor="
                let item of detailData.additions;
                let index = index;
                let last = last
              "
            >
              <div class="col pb-3 pt-3 d-flex align-items-center border-top">
                <a [href]="item.url" target="_blank" class="border-0">
                  <mat-icon class="material-icons">attach_file</mat-icon></a
                >{{ item.filename }}
              </div>
              <div
                class="col-auto pb-3 pt-3 d-flex align-items-center border-top"
              >
                <mat-icon
                  (click)="removeFile(index)"
                  class="cursor-pointer material-icons"
                  >clear</mat-icon
                >
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="bztt-h-[60px] bztt-flex md:bztt-hidden"></div>

  <div
    [class.bztt-rounded-tl-xl]="mobileMoreMenu"
    [class.bztt-rounded-tr-xl]="mobileMoreMenu"
    class="bztt-w-full bztt-flex bztt-flex-col md:bztt-hidden bztt-fixed bztt-left-0 bztt-bottom-0 bztt-border-t bztt-bg-white bztt-z-10"
  >
    <!-- <div
      [class.bztt-hidden]="!mobileMoreMenu"
      class="transition transform bztt-ease-in-out bztt-duration-300 bztt-w-full bztt-flex bztt-flex-col bztt-p-2 bztt-border-b"
    >
      <span
        class="bztt-w-full bztt-flex bztt-justify-end bztt-items-end"
        (click)="mobileMoreMenu = false"
      >
        <mat-icon>close</mat-icon>
      </span>
      <div class="bztt-w-full bztt-grid bztt-px-4 bztt-gap-4">
        <button app-button (click)="cancelBooking()" *ngIf="is('inProccess')">
          {{ 'bookings.detail.cancel' | translate }}
        </button>
      </div>
    </div> -->

    <div class="bztt-w-full bztt-flex bztt-justify-around bztt-p-3">
      <a
        *ngIf="userId"
        class="bztt-flex bztt-flex-col bztt-items-center bztt-justify-center bztt-border-0 bztt-p-0"
        (click)="toChatRoom(detailData)"
      >
        <mat-icon
          [matBadgeHidden]="!bookingsUtil.haveNewMessages(userId, detailData)"
          [matBadge]="1"
          class="material-icons-outlined"
          >message</mat-icon
        >
        {{ 'bookings.detail.message' | translate }}
      </a>

      <a
        class="bztt-flex bztt-flex-col bztt-items-center bztt-justify-center bztt-border-0 bztt-p-0"
        (click)="showMobilePreviewMenu = true"
      >
        <mat-icon class="material-icons-outlined">visibility</mat-icon>
        {{ 'bookings.detail.preview' | translate }}
      </a>

      <ng-container *ngTemplateOutlet="bookingActions"></ng-container>
    </div>
  </div>
</jarvis-ui-card-wrapper>

<!-- ******************** Templates ******************** -->

<ng-template
  #actionButton
  let-title="title"
  let-visible="visible"
  let-action="action"
  let-disabled="disabled"
>
  <button
    *ngIf="is(visible)"
    [disabled]="disabled"
    app-button
    color="accent"
    class="bztt-h-[42px] md:bztt-h-[50px] bztt-max-w-[148px] md:bztt-max-w-[250px] bztt-min-w-[148px] md:bztt-min-w-[180px] bztt-p-4 disabled:bztt-opacity-20"
    (click)="action()"
  >
    <span>{{ 'bookings.detail.' + title | translate }}</span>
  </button>
</ng-template>

<ng-template #bookingActions>
  <button
    app-button
    color="accent"
    [matMenuTriggerFor]="proposalButtonMenu"
    class="bztt-w-fit bztt-px-5 bztt-hidden md:bztt-flex bztt-mr-6"
  >
    <div class="bztt-flex bztt-justify-center bztt-items-center">
      <span>
        {{ 'bookings.detail.send' | translate }}
      </span>
      <mat-icon>expand_more</mat-icon>
    </div>
  </button>

  <button
    app-button
    color="accent"
    (click)="showMobileSendMenu = true"
    class="bztt-w-fit bztt-px-5 md:bztt-hidden"
  >
    <div class="bztt-flex bztt-justify-center bztt-items-center">
      <span>
        {{ 'bookings.detail.send' | translate }}
      </span>
      <mat-icon>expand_more</mat-icon>
    </div>
  </button>

  <jarvis-overlay
    *ngIf="showMobileSendMenu"
    type="global"
    [bottom]="0"
    (closed)="showMobileSendMenu = false"
  >
    <div
      class="bztt-relative bztt-bg-[#f9f9f9] bztt-rounded-t-lg bztt-w-full bztt-p-4 bztt-flex bztt-flex-col bztt-gap-3"
    >
      <!-- <div
        (click)="showMobileSendMenu = false"
        class="close-wrapper bztt-absolute bztt-right-2 bztt-top-2 bztt-z-10"
      >
        <span>{{ 'actions.close' | translate }}</span>
        <mat-icon>clear</mat-icon>
      </div> -->
      <div
        class="bztt-text-xl bztt-py-3 bztt-border-b bztt-border-[#F1F0F0] bztt-text-center bztt-font-semibold"
      >
        {{ 'bookings.detail.send' | translate }}
      </div>

      <button
        app-button
        (click)="sendProposal()"
        class="bztt-w-full bztt-border-b bztt-border-[#EF5518]"
      >
        <span class="bztt-text-[#EF5518]">
          {{ ('bookings.detail.' + (detailData.options.proposalSent? 'sendNewProposal':'sendProposal')) | translate }}
        </span>
      </button>
      <button
        app-button
        (click)="sendContract()"
        class="bztt-w-full bztt-border-b bztt-border-[#EF5518] bztt-text-[#EF5518]"
      >
        <span class="bztt-text-[#EF5518]">
          {{ ('bookings.detail.' + (detailData.options.contractSent? 'sendNewContract':'sendContract')) | translate }}
        </span>
      </button>
    </div>
  </jarvis-overlay>

  <jarvis-overlay
    *ngIf="showMobilePreviewMenu"
    type="global"
    [bottom]="0"
    (closed)="showMobilePreviewMenu = false"
  >
    <div
      class="bztt-relative bztt-bg-[#f9f9f9] bztt-rounded-t-lg bztt-w-full bztt-p-4 bztt-flex bztt-flex-col bztt-gap-3"
    >
      <div
        class="bztt-text-xl bztt-py-3 bztt-border-b bztt-border-[#F1F0F0] bztt-text-center bztt-font-semibold"
      >
        {{ 'bookings.detail.preview' | translate }}
      </div>

      <a
        target="_blank"
        [href]="proposalURL"
        class="bztt-border-0 bztt-leading-none"
      >
        <button
          app-button
          class="bztt-w-full bztt-border-b bztt-border-[#EF5518]"
        >
          <span class="bztt-text-[#EF5518]">
            {{ 'bookings.detail.proposal'  | translate }}
          </span>
        </button>
      </a>
      <button
        app-button
        class="bztt-w-full bztt-border-b bztt-border-[#EF5518] bztt-text-[#EF5518]"
        (click)="sendContract()"
      >
        <span class="bztt-text-[#EF5518]">
          {{ 'bookings.detail.agreement' | translate }}
        </span>
      </button>
    </div>
  </jarvis-overlay>

  <mat-menu #proposalButtonMenu="matMenu" class="send-menu">
    <button
      mat-menu-item
      (click)="sendProposal()"
      class="bztt-max-w-[148px] md:bztt-max-w-[250px] bztt-min-w-[148px] md:bztt-min-w-[180px] bztt-font-semibold"
    >
      {{ ('bookings.detail.' + (detailData.options.proposalSent? 'sendNewProposal':'sendProposal'))  | translate }}
    </button>
    <button
      mat-menu-item
      (click)="sendContract()"
      class="bztt-max-w-[148px] md:bztt-max-w-[250px] bztt-min-w-[148px] md:bztt-min-w-[180px] bztt-font-semibold"
    >
      {{ ('bookings.detail.' + (detailData.options.contractSent? 'sendNewContract':'sendContract')) | translate }}
    </button>
  </mat-menu>
</ng-template>

<ng-template
  #accordion
  let-title="title"
  let-value="value"
  let-suffix="suffix"
  let-last="last"
>
  <div
    class="bztt-w-full descriptor md:bztt-col-span-2 bztt-px-3 md:bztt-px-6 bztt-border bztt-rounded-lg bztt-bg-[#F9F9F9] bztt-border-[#DCDCDC]"
  >
    <mat-accordion displayMode="flat">
      <mat-expansion-panel
        class="mat-elevation-z0 bztt-bg-transparent bztt-p-0"
      >
        <mat-expansion-panel-header
          class="hover:bztt-bg-transparent bztt-font-medium bztt-p-0 bztt-pr-1"
        >
          <mat-panel-title class="bztt-text-base">{{
            'bookings.detail.' + title | translate
          }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let item of value">
          {{ 'bookings.detail.' + suffix + item.type | translate : item }}
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-template>

<jarvis-modal
  maxHeight="90vh"
  [modalClass]="['mat-dialog-no-padding', 'booking-edit-dialog']"
  *ngIf="getUIState(UIStates.showBookingEdit) | async"
  (modalClose)="clearUIState()"
>
  <jarvis-ui-modal-wrapper
    class="modal-wrapper bztt-flex"
    (closed)="clearUIState()"
  >
    <jarvis-booking-edit
      class="bztt-h-full bztt-flex bztt-flex-col bztt-justify-between"
      (proposalOpen)="handleProposalOpen()"
    ></jarvis-booking-edit>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>


<jarvis-modal
  maxHeight="90vh"
  [modalClass]="['agreement-view-dialog']"
  *ngIf="getUIState(UIStates.showAgreementView) | async"
  (modalClose)="clearUIState()"
>
  <jarvis-ui-modal-wrapper [closeButton]="false"
    class="modal-wrapper bztt-flex"
    (closed)="clearUIState()"
  >
   <app-agreement-view [data]="{
     detailData: detailData,
     base: detailData.serviceBaseId,
     startUpload: false ,
     canChange: true,
     disableUpload: false}" (event)="handleAgreement($event)"></app-agreement-view>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>
<!-- !detailData.options.customAgreement, -->

<jarvis-modal
  maxHeight="90vh"
  [modalClass]="['agreement-view-dialog']"
  *ngIf="getUIState(UIStates.showAgreementPreview) | async"
  (modalClose)="setUIState(getStateInitiator())"
>
  <jarvis-ui-modal-wrapper [closeButton]="false"
    class="modal-wrapper bztt-flex"
    (closed)="setUIState(getStateInitiator())"
  >
   <app-agreement-view [data]="{detailData: detailData, disableUpload : true}"
     presentationType="user"
     (event)="handleAgreement($event)"></app-agreement-view>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>

<jarvis-modal
  maxHeight="90vh"
  *ngIf="getUIState(UIStates.showAddSignature) | async"
  (modalClose)="setUIState(UIStates.showAgreementView)"
>
  <jarvis-ui-modal-wrapper [closeButton]="true"
    class="modal-wrapper large-modal bztt-flex"
    (closed)="setUIState(UIStates.showAgreementView)"
  >
   <jarvis-add-signature [data]="detailData" (event)="handleAgreement($event)"></jarvis-add-signature>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>

<jarvis-modal
  maxHeight="90vh"
  *ngIf="getUIState(UIStates.showAddSignaturePreview) | async"
  (modalClose)="setUIState(UIStates.showAgreementPreview)"
>
  <jarvis-ui-modal-wrapper [closeButton]="true"
    class="modal-wrapper bztt-flex"
    (closed)="setUIState(UIStates.showAgreementPreview)"
  >
   <jarvis-add-signature [data]="detailData" [mode]="'preview'" (event)="handleAgreement($event)"></jarvis-add-signature>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>

<jarvis-modal
  *ngIf="getUIState(UIStates.showSendProposal) | async"
  (modalClose)="clearUIState()"
>
  <jarvis-ui-modal-wrapper
    class="modal-wrapper medium-modal bztt-flex"
    (closed)="clearUIState()"
  >
    <jarvis-proposal-send-modal
      [detailData]="detailData"
      (proposalSent)="setUIState(UIStates.successSendProposal)"
    ></jarvis-proposal-send-modal>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>

<jarvis-modal
  *ngIf="getUIState(UIStates.successSendProposal) | async"
  (modalClose)="clearUIState()"
>
  <jarvis-ui-modal-wrapper
    [fullHeight]="true"
    class="modal-wrapper medium-modal bzzt-flex bztt-h-full"
    (closed)="clearUIState()"
  >
    <div
      class="bztt-flex bztt-flex-col bztt-items-center bztt-h-full bztt-justify-center"
    >
      <mat-icon class="bztt-mt-2 bztt-mb-8 bztt-text-[#EF5518] bztt-scale-[3]"
        >task_alt</mat-icon
      >
      <div class="bztt-text-lg bztt-font-bold bztt-mb-6">
        {{ 'bookings.detail.successProposalAction.title' | translate }}
      </div>
      <div class="bztt-mb-6">
        {{ 'bookings.detail.successProposalAction.message' | translate }}
      </div>
    </div>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>


<jarvis-modal
  *ngIf="getUIState(UIStates.successSendContract) | async"
  (modalClose)="clearUIState()"
>
  <jarvis-ui-modal-wrapper
    [fullHeight]="true"
    class="modal-wrapper medium-modal bzzt-flex bztt-h-full"
    (closed)="clearUIState()"
  >
    <div
      class="bztt-flex bztt-flex-col bztt-items-center bztt-h-full bztt-justify-center"
    >
      <mat-icon class="bztt-mt-2 bztt-mb-8 bztt-text-[#EF5518] bztt-scale-[3]"
        >task_alt</mat-icon
      >
      <div class="bztt-text-lg bztt-font-bold bztt-mb-6">
        {{ 'bookings.detail.successContractSendAction.title' | translate }}
      </div>
      <div class="bztt-mb-6">
        {{ 'bookings.detail.successContractSendAction.message' | translate }}
      </div>
    </div>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>


<jarvis-modal
  *ngIf="getUIState(UIStates.showPaymentPreview) | async"
  (closed)="clearUIState()"
>
  <jarvis-ui-modal-wrapper
    [fullHeight]="true"
    class="modal-wrapper medium-modal bzzt-flex bztt-h-full"
    (closed)="clearUIState()"
  >
  <div class="fs-3 fw-bold bztt-min-h-64"> {{
       'bookings.agreement.payment' | translate
    }}
  </div>
  <div class="button-container border-top d-flex pt-3 ps-4 pe-4 pb-4 pb-md-0 justify-content-center align-middle align-items-center">
    <button
    app-button
    color="accent"
    disabled
    class="bztt-w-[200px]">
     <span>{{ 'bookings.agreement.payAmount' | translate }} {{ (detailData.deposit??0) | currency : currencyCode : 'symbol' : null : locale}}</span>
  </button>
</div>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>

<jarvis-modal
  *ngIf="getUIState(UIStates.successAddSign) | async"
  (modalClose)="setUIState(UIStates.showAgreementView)"
>
  <jarvis-ui-modal-wrapper
    [fullHeight]="true"
    class="modal-wrapper medium-modal bzzt-flex bztt-h-full"
    (closed)="setUIState(UIStates.showAgreementView)"
  >
    <div
      class="bztt-flex bztt-flex-col bztt-items-center bztt-h-full bztt-justify-center"
    >
      <mat-icon class="bztt-mt-2 bztt-mb-8 bztt-text-[#EF5518] bztt-scale-[3]"
        >task_alt</mat-icon
      >
      <div class="bztt-text-lg bztt-font-bold bztt-mb-6">
        {{ 'bookings.detail.successAddSignAction.title' | translate }}
      </div>
      <div class="bztt-mb-6">
        {{ 'bookings.detail.successAddSignAction.message' | translate }}
      </div>
    </div>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>

<jarvis-modal
  *ngIf="getUIState(UIStates.showSendContract) | async"
  (modalClose)="clearUIState()"
>
  <jarvis-ui-modal-wrapper
    class="modal-wrapper medium-modal bztt-flex"
    (closed)="clearUIState()"
  >
    <jarvis-contract-send-modal
      [detailData]="detailData"
      (contractSent)="handleSendContract($event)"
    ></jarvis-contract-send-modal>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>
