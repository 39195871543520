import { Component, Inject, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

type SuccessDialogData = { i18nKey?: string, title?: string, message?: string, icon?: string, values?:any };

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessDialogComponent {
  public i18nKey = 'success-modal';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SuccessDialogData,
    private mdDialogRef: MatDialogRef<SuccessDialogComponent>
  ) {
    if (data.i18nKey) {
      this.i18nKey = data.i18nKey;
    }
  }

  public close(): void {
    this.mdDialogRef.close(true);
  }
}
