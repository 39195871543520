import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BASE_URL } from '../tokens/base-url.token';

interface CalendarEvent {
  dateFrom: Date;
  dateTo: Date;
  booking?: any;
}

export interface CalendarAttributes {
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
  dateObject: any;
  booking?: string;
  serviceEvent?: string;
  dateFrom: Date;
  dateTo: Date;
  vendor?: string;
  type: any;
}

@Injectable({ providedIn: 'root' })
class JarvisCalendarService {
  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    private httpService: HttpClient
  ) {}

  getMarketplaceCalendarAvailability(
    dateFrom: Date,
    dateTo: Date,
    serviceEventId: string
  ): Observable<CalendarEvent[]> {
    const dateFromCopy = new Date(dateFrom);
    dateFromCopy.setHours(0, 0, 0);

    // Worked when this was dateFrom - investigate if bugs appear beacause of this
    const dateToCopy = new Date(dateTo);
    dateToCopy.setHours(24, 0, 0);

    const endpoint = `${
      this.baseUrl
    }/marketplace/calendar?id=${serviceEventId}&dateFrom=${+dateFromCopy}&dateTo=${+dateToCopy}`;
    return this.httpService.get<CalendarEvent[]>(endpoint).pipe(
      map((res) => {
        return res.filter((record) => {
          const isNewBooking =
            record.booking?.state === 'new' ||
            record.booking?.state === 'communication';
          return !isNewBooking;
        });
      })
    );
  }

  createEvent(data: CalendarAttributes): Observable<any> {
    return this.httpService.post(`${this.baseUrl}/calendar`, data);
  }
}

export { CalendarEvent, JarvisCalendarService };
