import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { format, getDay } from 'date-fns';

@Injectable()
export class CalendarDateFormatterService extends CalendarDateFormatter {
    // TODO: add explicit constructor

    // you can override any of the methods defined in the parent class

    public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
        const weekNames = ['Sk', 'Pr', 'An', 'Tr', 'Kt', 'Pn', 'Št'];
        
        // TODO: Make us of date fns locale objects when updated to have certain locales
        if (locale === 'lt-LT') {
            const dayIndex = getDay(date);
            return weekNames[dayIndex];
        }

        return format(date, 'dd', { locale });

        // return new DatePipe(locale).transform(date, weekNames[date.getDay()], locale);
        // return weekNames[date.getDay()];

    }

    public monthViewTitle({ date, locale }: DateFormatterParams): string {
        return new DatePipe(locale).transform(date, 'MMM y', locale);
    }

}