import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlify',
  standalone: true,
})
export class UrlifyPipe implements PipeTransform {
  transform(text: any): any {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z\d+&@#/%?=~_|!:,.;]*[-A-Z\d+&@#/%=~_|])(?![^<]*>|[^<>]*<\/)/gi;

    let newText = text.replace(urlRegex, (url) => {
      return (
        '<a class="text-decoration-underline bztt-border-none" target="_blank" href="' +
        url +
        '">' +
        url +
        '</a>'
      );
    });

    newText = newText.replaceAll('*', '');

    return newText;
  }
}
