import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jarvis-reviews-success-engage',
  templateUrl: './success-engage.component.html',
  styleUrls: ['./success-engage.component.scss']
})
export class ReviewsSuccessEngageComponent implements OnInit {

  @Input() successHeader: string;

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }

  evaluteNew(): void {
    
  }

}
