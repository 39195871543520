import { formatCurrency, formatNumber, getCurrencySymbol } from '@angular/common';
/***** metrics used to analyze activity ******/
/***** Visit vs Views = Registered user vs anonymous *****/
export enum MetricsEnum {
    listingViews,
    listingVisits,
    listingLikes,
    listingEmailClick,
    listingPhoneClick,
    listingFacebookClick,
    listingInstagramClick,
    listingTikTokClick,
    listingOtherClick,
    listingWebClick,
    listingRequestsSummary,
    listingRequestsSummaryAmount,
    listingRequest,
    listingRequestAmount,

    personalBookingViews,

    personalBookingRequest,
    personalBookingRequestAmount,

    bookingSummary,
    bookingSummaryAmount,
    booking,
    bookingAmount,
    bookingViews,
    bookingListing,
    bookingLink,

    bookingViaPersonalBooking,
    bookingViaPersonalBookingAmount,

    payAmount,
    outstandingAmount,
    freeDaySeason,
    bookedDateDaySeason,
    freeDayOffSeason,
    bookedDateDayOffSeason,
    cancelledBooking,
    cancelledByCustomerBooking,
    photoViews,
    photoLikes,

    revenue,

    receivedPayments,
    receivedPaymentsAmount,

    outstandingPayments,
    outstandingPaymentsAmount,

    views,
    linkViews,

    bookingRequestsViaLink,
    bookingRequestsViaListing,
    bookingRequestsViaEmail,
    bookingRequestsViaTCG,
    bookings,
    bookingsValue,


    bookingsRequestsCount,
    bookingsRequestsValue,

    bookingsCountViaLink,
    bookingsValueViaLink,
    bookingsManually,
    bookingsValueManually,
    bookingsRequestManually,
    bookingsRequestValueManually,

    bookingsRequestCountViaLink,
    bookingsRequestValueViaLink,
    bookingsCountViaListing,
    bookingsValueViaListing,
    bookingsRequestCountViaListing,
    bookingsRequestValueViaListing,


    bookingsCanceledByVendorCount,
    bookingsCanceledByUserCount,
    bookingsRequestCanceledByVendorCount,
    bookingsRequestCanceledByUserCount,
    likes,

    partnerListView,
    partnerListItemClick,

    paidListingClick,
    impressions,

    bannerClick,
    bannerView,
    calendarInteraction,
    calls,

    getAQuateClick,
    requestMorePhotoClick,
    bookingRequestsAddedManually,
    tcgPhoneCalls,
    creditPoints
}

export enum ToDoEnum {
 calendarAvailability,
 connectAccount,
 serviceReviews,
 addVideo,
 addPhone,
 addEmail,
 addCalendarBookingLink,
 addPersonalCalendar,
 addBreezitEmail,
 checkPricing,
 //checkAgreement
}

 export enum ChartGroupEnum {
    revenue,
    payments,
    bookings,
    bookingViaLink,
    bookingRequestsViaLink,
    bookingViaListing,
    bookingRequestsViaListing,
    impressions,
    impression,    
    views,
    linkViews,
    listingVisits,
    listingLikes,
    bookingRequests,        
    listingCommClick,    
    listingSocialClick,    
    listingEmailClick,
    listingPhoneClick,
    listingWebClick,
    listingFacebookClick,
    listingInstagramClick,
    outstanding,

  }


 export abstract class OverviewUtil {

    public static formatValue(metric:MetricsEnum, value:number, currencyCode:string ="") {

      switch (metric) {
       case MetricsEnum.creditPoints:        
         return formatNumber(value, "en-us", "1.0-2");         
       case MetricsEnum.listingRequestsSummaryAmount:
       case MetricsEnum.bookingViaPersonalBookingAmount:
       case MetricsEnum.receivedPaymentsAmount:
       case MetricsEnum.listingRequestAmount:
       case MetricsEnum.receivedPayments:
       case MetricsEnum.payAmount:
       case MetricsEnum.revenue:
       case MetricsEnum.bookingsValue:
       case MetricsEnum.outstandingAmount:
       case MetricsEnum.bookingsRequestsValue:
       case MetricsEnum.bookingsValueViaLink:
       case MetricsEnum.bookingsRequestValueViaLink:
       case MetricsEnum.bookingsValueViaListing:
       case MetricsEnum.bookingsRequestValueManually:
       case MetricsEnum.bookingsValueManually:
       case MetricsEnum.outstandingPaymentsAmount:
       case MetricsEnum.bookingsRequestValueViaListing:

          return formatCurrency(
          value,
          "en-us",
          getCurrencySymbol(currencyCode, 'wide'),
          currencyCode
        );


          //getCurrencySymbol(currencyCode,"wide") + (value==null?"0":value.toString());
       default: return value==null?"0":value.toString();
      }
    }

 }
