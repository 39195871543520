import { SAAS_LANDING_TEMPLATE_CONFIGURATION } from './configuration.constant';

import { DOCUMENT } from '@angular/common';
import {
  Component,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JarvisAuthService, User } from '@jarvis/auth';
import {
  LinkUrls,
  LINK_URLS,
  DOMAIN_COUNTRY,
  JarvisTrackingService,
} from '@jarvis/services';
import { JarvisHeaderService, LayoutService } from '@jarvis/ui';
import { Subject, Observable } from 'rxjs';
import { take, takeUntil, map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'jarvis-saas-landing-template',
  templateUrl: './saas-landing-template.component.html',
  styleUrls: ['./saas-landing-template.component.scss'],
})
export class SaasLandingTemplateComponent implements OnInit, OnDestroy {
  @ViewChild('landingHeader', { static: true })
  landingHeader: TemplateRef<HTMLDivElement>;

  private trackService = inject(JarvisTrackingService);

  SAAS_LANDING_TEMPLATE_CONFIGURATION = SAAS_LANDING_TEMPLATE_CONFIGURATION;

  window = window;
  isProfileLink: boolean;

  marketplaceLandingLink = `${this.linkUrls.marketplace}/event-hosts`;

  isMobile = false;
  loggedIn$ = this.authService.isLoggedIn$;

  private destroy$ = new Subject<void>();

  configurationType = '';

  configuration;

  constructor(
    private layoutService: LayoutService,
    private headerService: JarvisHeaderService,
    private router: Router,
    private route: ActivatedRoute,
    private vcr: ViewContainerRef,
    private authService: JarvisAuthService,
    public sanitizer: DomSanitizer,
    @Inject(LINK_URLS) private linkUrls: LinkUrls,
    @Inject(DOCUMENT) private document: Document,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string
  ) {}

  ngOnInit(): void {
    const subPathUrl = this.route.snapshot.url[0];
    if (subPathUrl && SAAS_LANDING_TEMPLATE_CONFIGURATION[subPathUrl.path]) {
      this.configurationType = subPathUrl.path;
      this.configuration = SAAS_LANDING_TEMPLATE_CONFIGURATION[subPathUrl.path];
    }

    this.isProfileLink = subPathUrl.path === 'profile-link';

    if (this.isProfileLink) {
      this.layoutService.hideNearMeSubject.next(true);
    }

    this.layoutService.isMobileObserver
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => (this.isMobile = isMobile));

    this.headerService.setHeaderTemplate(this.landingHeader, this.vcr);
    this.layoutService.disableFooterMargin();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.layoutService.hideNearMeSubject.next(false);
    this.headerService.setDefaultHeader();
    this.layoutService.enableFooterMargin();
  }

  openContactFormDialog(): void {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (!userData) {
        this.router.navigateByUrl('/auth/register', {
          state: { userType: 'vendor' },
        });
        this.authService.createRegisterOrLoginSuccessStream().subscribe(() => {
          window.location.href = this.linkUrls.saas;
          // this.router.navigateByUrl('/profile/settings');
        });
        return;
      }

      if (userData.userType === 'user') {
        this.router.navigateByUrl('/planner');
        return;
      }

      window.location.href = this.linkUrls.saas;
    });
  }

  marketplaceRedirrect() {
    if (this.isProfileLink) return;

    window.location.href = this.linkUrls.marketplace;
  }

  private navigateToFirstHomePage(userType: 'user' | 'vendor'): void {
    if (userType === 'user') {
      this.router.navigateByUrl('/photographer');
      return;
    }

    window.location.href = this.linkUrls.saas;
  }

  get vendorSignupLink(): Observable<any> {
    return this.authService.userData$.pipe(
      take(1),
      map((userData) => {
        if (userData) {
          return {
            link: this.getFirstPageNavigationUrl(userData.userType),
          };
        }

        return {
          link: '/sign-up',
        };
      })
    );
  }

  private getFirstPageNavigationUrl(userType: 'user' | 'vendor'): string {
    if (userType === 'user') {
      return './photographer';
    }

    return './' + this.linkUrls.saas;
  }

  handleVendorSignup(location: string) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'gaVendorCTA',
      data: { segment: location },
    });
    // this.authService.userData$.pipe(take(1)).subscribe((userData) => {
    //   if (userData) {
    //     this.navigateToFirstHomePage(userData.userType);
    //     return;
    //   }

    //   window.location.replace(window.location.origin + '/sign-up?source=' + location);

    //   // this.router.navigate(['sign-up'], {
    //   //   queryParams: { source: location }
    //   // });
    // });
  }

  openLoginDialog(): void {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (!userData) {
        this.router.navigateByUrl('/auth/login');
        this.authService
          .createRegisterOrLoginSuccessStream()
          .subscribe((newUserData: User) => {
            if (newUserData && newUserData.userType === 'user') {
              this.router.navigateByUrl('/planner');
              return;
            }

            window.location.href = this.linkUrls.saas;
          });
        return;
      }

      if (userData.userType === 'user') {
        this.router.navigateByUrl('/planner');
        return;
      }

      window.location.href = this.linkUrls.saas;
    });
  }

  navigateToSection(sectionId): void {
    const html = document.getElementById('bContent');
    const element = document.getElementById(sectionId) as HTMLElement;
    html.scrollTo({ top: element.offsetTop - 125, behavior: 'smooth' });
  }

  handlePricingClick() {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'pricing_click',
    });
    
    if (this.isProfileLink)
      return this.navigateToSection('profile-link-pricing');

    this.router.navigate(['../service-providers/pricing']);

    // const dialogRef = this.modalService.openDialog(PricingPopupDialogComponent, { panelClass: 'pricing-popup-dialog', scrollStrategy: new NoopScrollStrategy() });
    // dialogRef.afterClosed().subscribe(res => {
    //   if (!res) return;

    //   window.scrollTo({ top: 0 });
    //   this.handleVendorSignup(this.configurationType + '-vendor-pricing-popup');
    // });
  }
}
