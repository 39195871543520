import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, timer } from 'rxjs';
import { openCloseAnimation } from './banner.animations';


@Component({
  selector: 'jarvis-banner',
  templateUrl: 'banner.component.html',
  standalone: true,
  imports: [
    CommonModule
  ],
  animations: [
    openCloseAnimation
  ]
})
export class JarvisBannerComponent implements OnInit, OnDestroy {
  
  @Input() duration: number = null;
  @Output() closed = new EventEmitter();

  private isOpen = true;

  private destroy$ = new Subject<void>();

  @HostBinding('@openClose')
  get animationState() {
    return this.isOpen ? 'open' : 'closed';
  }
  
  @HostListener('@openClose.done', ['$event'])
  openCloseAnimationDone(animationEvent) {
    if (animationEvent.toState === 'closed') {
      this.closed.emit();
    }
  }

  ngOnInit(): void {
    if (this.duration) {
      timer(this.duration).subscribe(() => {
        this.handleClose();
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleClose(): void {
    this.isOpen = false;
  }

}
