<div
  class="read-more-link"
  [style.font-size]="fontSize"
  [style.line-height]="lineHeight"
>
  <div
    #linkText
    [ngClass]="{ 'read-more-link-text--collapsed': isCollapsed }"
    class="bztt-mb-2"
    [innerHTML]="text"
    [style.height]="isCollapsed ? maxHeight + 'px' : '100%'"
  ></div>
  <a
    *ngIf="isCollapsable"
    (click)="readClick($event)"
    [style.font-size]="fontSize"
  >
    {{
      isCollapsed
        ? ('readLink.more' | translate)
        : ('readLink.less' | translate)
    }}</a
  >
</div>
