
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { BASE_URL, DOMAIN_COUNTRY } from '@jarvis/services';
import { LayoutService } from '@jarvis/ui';

@Component({
  selector: 'jarvis-curated-lists',
  templateUrl: './curated-lists.component.html',
  styleUrls: ['./curated-lists.component.scss'],
})
export class HomepageCuratedListsComponent implements OnInit {


  private baseUrl = inject(BASE_URL);
  private layoutService = inject(LayoutService);
  private httpService = inject(HttpClient);
  country = inject(DOMAIN_COUNTRY);

  curatedLists = [];
  listsToShow = [];

  constructor() {}

  ngOnInit(): void {
    this.httpService
      .get(`${this.baseUrl}/common/configuration?type=CURATED_LIST`)
      .subscribe((curatedLists) => {
        this.curatedLists = this.shuffle(
          Object.keys(curatedLists)
            .filter((item) => {
              return curatedLists[item].country === this.country;
            })
            .filter((item) => {
              return curatedLists[item].active;
            })
            //   .sort((a, b) => {
            //     return curatedLists[a].showIndex - curatedLists[b].showIndex;
            //   })
            .map((key) => {
              return { slug: key, ...curatedLists[key] };
            })
        );

        this.listsToShow = this.layoutService.isMobile
          ? this.curatedLists
          : this.curatedLists.slice(0, 3);
      });
  }

  shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  handleSeeMore() {
    this.listsToShow = this.curatedLists;
  }
}
