<div
  class="form-container bztt-shadow-md bztt-p-6 md:bztt-px-10 bztt-flex bztt-flex-col bztt-gap-3 bztt-justify-around"
>
  <div
    class="top-section bztt-w-full bztt-flex bztt-flex-row bztt-justify-start bztt-items-center bztt-gap-14"
  >
    <div
      class="bztt-flex bztt-flex-row combined-button bztt-relative bztt-text-left bztt-pl-3 md:bztt-pl-5"
    >
      <ng-container *ngIf="domainCountry === 'lt'">
        <mat-icon>date_range</mat-icon>
        <input
          (click)="openDatePicker(true)"
          #dateAnchor2
          [placeholder]="
            'landing.homepageUplift.eventDateSelector.whenIsEvent' | translate
          "
          readonly
          dateMask
          matInput
          [formControl]="homepageService.eventDateControl"
        />
      </ng-container>

      <ng-container *ngIf="domainCountry === 'us'">
        <mat-icon>location_on</mat-icon>
        <input
          (click)="handleLocationOverlay()"
          #overlayOrigin
          [placeholder]="'Wedding location' | translate"
          readonly
          matInput
          [value]="homepageService.eventLocationControl.value?.name"
        />
      </ng-container>

      <a
        [routerLink]="marketplaceLink.link"
        [queryParams]="marketplaceLink.queryParams"
        [state]="marketplaceLink.state"
        class="bztt-no-underline bztt-border-none bztt-absolute bztt-right-[-4px]"
      >
        <button
          app-button
          color="accent"
          (click)="
            scrollTop();
            handleTrackClick('search_click', {
              location: position
            })
          "
        >
          {{ 'landing.homepage.weddingDate.search' | translate }}
        </button>
      </a>
    </div>

    <div>
      <a
        [routerLink]="
          domainCountry === 'lt' ? marketplaceLink.link : '/wedding-venues'
        "
        [state]="marketplaceLink.state"
        class="bztt-border-none"
        (click)="
          handleTrackClick('no_date_click', {
            location: position
          });
          resetForms()
        "
      >
        <div class="dont-have-date bztt-w-fit">
          {{
            (domainCountry === 'lt'
              ? 'landing.homepageUplift.eventDateSelector.noDateYet'
              : 'All venues'
            ) | translate
          }}
        </div>
      </a>
    </div>
  </div>
  <div
    class="quick-links-container bztt-w-full bztt-flex bztt-flex-row bztt-justify-start bztt-items-start md:bztt-items-center bztt-gap-3 bztt-text-[13px] bztt-font-medium"
  >
    <span class="bztt-min-w-[80px] bztt-text-[#6B6060]">
      {{ 'landing.homepageUplift.eventDateSelector.quickLinks' | translate }}:
    </span>
    <div
      class="bztt-w-full bztt-flex bztt-flex-row bztt-justify-between bztt-items-start md:bztt-items-center bztt-gap-3 bztt-text-[13px] bztt-font-medium bztt-flex-wrap"
    >
      <a
        *ngFor="let qlink of quickLinks"
        [routerLink]="qlink.link"
        class="bztt-flex bztt-flex-row bztt-border-none bztt-gap-2 bztt-justify-center bztt-items-center"
        (click)="
          handleTrackClick('quick_link_click', {
            location: position,
            service_selected: qlink.name
          })
        "
      >
        <!-- <img class="bztt-max-h-4"
      [src]="'assets/images/landing/homepage-uplift/icons/' + qlink.icon"
      [alt]="qlink.name"
    /> -->
        <mat-icon
          class="bztt-text-[#6B6060] material-icons-outlined"
          *ngIf="qlink.iconName"
          >{{ qlink.iconName }}</mat-icon
        >
        <span class="bztt-text-[#6B6060] bztt-font-normal">
          {{ qlink.label | translate }}
        </span>
      </a>

      <!-- <ng-container *ngIf="domainCountry === 'us'">
        <a
          *ngFor="let qlink of quickLinks"
          [routerLink]="qlink.link"
          class="bztt-flex bztt-flex-row bztt-border-none bztt-gap-2 bztt-justify-center bztt-items-center"
          (click)="
            handleTrackClick('quick_link_click', {
              location: position,
              service_selected: qlink.name,
            })
          "
        >
          <mat-icon
            class="bztt-text-[#6B6060] material-icons-outlined"
            *ngIf="qlink.iconName"
            >{{ qlink.iconName }}</mat-icon
          >
          <span class="bztt-text-[#6B6060] bztt-font-normal">
            {{ 'categoriesMarketplace.' + qlink.category | translate }}
          </span>
        </a>
      </ng-container> -->
    </div>
  </div>
</div>
<ng-template #smallCalendarOverlay>
  <div
    class="calendar-container mat-elevation-z4 p-2 pt-3 bztt-w-full bztt-h-full"
  >
    <jarvis-ui-small-calendar
      [disablePastDays]="true"
      [events]="[]"
      [date]="homepageService.eventDateControl.value"
      [viewDate]="homepageService.eventDateControl.value || today"
      (daySelected)="handleWeddingDateSelect($event)"
    ></jarvis-ui-small-calendar>
  </div>
</ng-template>
