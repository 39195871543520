<div
  class="bztt-z-10 bztt-bg-white md:bztt-w-screen bztt-p-0 md:bztt-flex md:bztt-justify-center"
>
  <div class="bztt-my-8 md:bztt-my-0 bztt-flex bztt-flex-row">
    <!-- <div class="md:bztt-m-8">
      <img alt="logo" width="120px" src="assets/images/bzt-logo.svg" />
    </div> -->

    <div
      *ngIf="isMobile$ | async"
      class="bztt-flex bztt-w-full bztt-justify-end"
    >
      <button class="bztt-flex bztt-items-center" (click)="close()">
        <span class="bztt-font-montserrat-bold bztt-text-sm bztt-underline">{{
          'actions.close' | translate
        }}</span>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <h2 *ngIf="isMobile$ | async" class="bztt-font-maple-r bztt-text-[1.75rem]">
    {{ 'header.services' | translate }}
  </h2>

  <jarvis-service-type-selector class="md:bztt-mt-28" (typeSelected)="close()"></jarvis-service-type-selector>
</div>

<div
  *ngIf="(isMobile$ | async) === false"
  (click)="this.close()"
  style="
    background: #ef5518 !important;
    background-color: #ef5518 !important;
    opacity: 0.2 !important;
  "
  class="bztt-fixed bztt-h-full bztt-w-full"
></div>
