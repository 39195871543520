<ng-container *ngIf="(currentMobileView$ | async) === null; else mobileView">
    <jarvis-ui-card-wrapper>
        <ng-container *ngIf="!(messageCount0 | async) || (disableChatRoomPanel$ | async); else noMessages">
            <!-- <h1>{{ "messages.title" | translate }}</h1> -->
            <div class="messages-container" [class.disable-chatrooms]="disableChatRoomPanel$ | async">
                <marketplace-messages-chatrooms
                  (chatroomSelected)="setChatRoom($event)"
                ></marketplace-messages-chatrooms> 
    
                <router-outlet></router-outlet>
            </div>
        </ng-container>
        <ng-template #noMessages>
            <marketplace-messages-no-messages class="bztt-pt-12"></marketplace-messages-no-messages>
             <router-outlet [hidden]="true"></router-outlet> 
        </ng-template>
    </jarvis-ui-card-wrapper>    
</ng-container>

<ng-template #mobileView>
    <router-outlet></router-outlet>
</ng-template>