import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  merge,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  exhaustMap,
  filter,
  map,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  MessagesType,
  MESSAGES_TYPE,
} from '../messages/services/messages-type.token';
import {
  AllChatrooms,
  ChatRoom,
  ChatRoomMessage,
  ChatroomPayload,
  ChatRoomsUpdateEvent,
  ChatroomUpdatePayload,
  ChatService,
  MessageContent,
  MessagingEvent,
  NewChatroomPayload,
} from '../types/messaging.types';
import { JarvisAuthService } from '@jarvis/auth/services';
import { BASE_URL } from '@jarvis/services/tokens';
import { SocketService } from '@jarvis/services/socket';

@Injectable()
export class MessagingService implements OnDestroy {
  private chatroomsEndpoint: string;
  private sendMessageEndpoint: string;
  private markAsSeenEndpoint: string;
  private markAsUnreadEndpoint: string;
  private startChatroomEndpoint: string;
  private startChatroomWOUserEndpoint: string;
  private sendBookingMessageEndpoint: string;
  private messageEndpoint: string;

  private innerMessageRead$ = new Subject<any>();
  private newChatRoom$ = new Subject<any>();

  private allChatroomCacheSource = new BehaviorSubject<ChatRoom[]>([]);
  allChatroomCache$ = this.allChatroomCacheSource.asObservable();
  // allChatroomCache$: Observable<ChatRoom[]>;

  private currentUserId: string;
  private chatroomLoadSize = 15;
  private totalChatrooms = 0;
  private loadedChatrooms = 0;
  private searchChatroomsData = [];
  private allChatroomsLoaded = new BehaviorSubject(false);
  allChatroomsLoaded$ = this.allChatroomsLoaded.asObservable();
  
  navigateChatroom$ = new Subject<string>();

  loadMoreChatroom$ = new Subject<'search' | 'all'>();

  disableChatRoomPanel$ = new BehaviorSubject(false);

  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(MESSAGES_TYPE) public messagesType: MessagesType,
    private socketService: SocketService,
    private authService: JarvisAuthService,
    private httpService: HttpClient
  ) {
    this.setEndpoints();

    this.chatroomRaw$().subscribe((chatrooms) =>
      this.allChatroomCacheSource.next(chatrooms)
    );
    // this.allChatroomCache$ = this.chatroomRaw$();

    // (window as any).startChat = (userId: string, serviceBaseId: string) =>
    //   this.startChatroomWithUser(userId, serviceBaseId);

    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      this.currentUserId = userData?._id;
    });
  }

  ngOnDestroy(): void {
    this.innerMessageRead$.complete();
  }
  newMessagesCount$() {
    return combineLatest([
      this.allChatroomCache$,
      this.authService.userData$,
    ]).pipe(
      map(([chatrooms, userData]) => {
        return chatrooms.reduce((newMessageCount, chatRoom) => {
          const isUnread = !chatRoom.readBy.includes(userData._id);
          return isUnread ? newMessageCount + 1 : newMessageCount;
        }, 0);
      }),
      shareReplay(1)
    );
  }

  getChatrooms$() {
    return this.allChatroomCache$;
  }

  chatroomRaw$() {
    const updateStream = (initialData: ChatRoom[]): Observable<ChatRoom[]> => {
      const initialDataParsed = initialData.map((chatroom) => {
        const parsedChatroom = {
          ...chatroom,
          messages: chatroom.messages.reverse(),
        };

        return parsedChatroom;
      });

      return this.chatRoomUpdate$().pipe(
        //tap(event => console.log('Chatroom update event: ', event)),
        
        scan((allChatrooms, updateEvent) => {
          if (!updateEvent || 
              !updateEvent.data || 
              (Array.isArray(updateEvent.data) && !updateEvent.data[0]))
          {
            return allChatrooms;
          }

          // TODO: Hacky, should return good data from backend
          if (updateEvent.data[0]) {
            updateEvent.data = updateEvent.data[0];
          }

          /* if (updateEvent.message === ChatRoomsUpdateEvent.newChatroom) {
            return [
              ...allChatrooms,
              updateEvent.data
            ];
          } */

          const currentChatroom = allChatrooms.find(
            (chatroom) => chatroom._id === updateEvent.data._id
          );

          if (currentChatroom) {
            const filteredRooms = allChatrooms.filter(
              (chatroom) => chatroom._id !== updateEvent.data._id
            );

            const updatedChatroom = {
              ...currentChatroom,
              updatedAt:
                updateEvent.data.updatedAt || currentChatroom.updatedAt,
              readBy: updateEvent.data.readBy || currentChatroom.readBy,
              messages: updateEvent.data.messages || currentChatroom.messages,
              autoReply:
                updateEvent.data.autoReply || currentChatroom.autoReply,
            };
            return [...filteredRooms, updatedChatroom];
          }

          // Do not update on mark message read
          if (!(updateEvent.message === ChatRoomsUpdateEvent.markMessageRead)) {
            return [...allChatrooms, updateEvent.data];
          }

          return allChatrooms;
        }, initialDataParsed),
        startWith(initialDataParsed)
      );
    };

    const chatrooms$ = this.getAllChatrooms().pipe(
      filter((newChatrooms) => !!newChatrooms),
      scan((currenChatrooms, newChatrooms) => {
        return [...currenChatrooms, ...newChatrooms];
      }, []),
      switchMap((originalData) => updateStream(originalData)),
      map((chatrooms) => (chatrooms ? chatrooms : [])),
      map((chatrooms) =>
        chatrooms.sort(
          (roomA, roomB) =>
            +new Date(roomB.updatedAt) - +new Date(roomA.updatedAt)
        )
      ),
      shareReplay(1)
    );

    return chatrooms$;
  }

  getChatroom$(
    chatroomId: string,
    selfMessage$: Subject<ChatroomPayload>,
    initialChatroomMessages?: ChatRoomMessage[]
  ) {
    if (!chatroomId) {
      return of(null);
    }

    const newMessages = (
      initialMessages: ChatRoomMessage[]
    ): Observable<ChatRoomMessage[]> =>
      merge(this.getChatroomMessage$(chatroomId), selfMessage$).pipe(
        withLatestFrom(
          this.authService.getCurrentUser().pipe(map((user) => user._id))
        ),
        scan((allMessages, [newPayload, userId]) => {
          if (!newPayload) {
            return allMessages;
          }

          // console.log(`Chatroom ${chatroomId}`, ` EVENT: | ${newPayload.message} |`, newPayload.data);

          const existingMessageIndex = allMessages.findIndex((message) =>
            newPayload.data.lastId && newPayload.data.sender === userId
              ? message._id === newPayload.data.lastId
              : message._id === newPayload.data._id
          );

          if (existingMessageIndex !== -1) {
            // NOTE: The sequence of spreads and uploading, error are important, as self messages contain these parameters in newPayloads, and old messages should have them revertet.
            const updatedMessage: ChatRoomMessage = {
              ...allMessages[existingMessageIndex],
              uploading: false,
              error: false,
              ...newPayload.data,
            };

            const updatedMessages = Object.assign(allMessages.slice(), {
              [existingMessageIndex]: updatedMessage,
            });

            return updatedMessages;
          }

          return [...allMessages, newPayload.data];
        }, initialMessages),
        startWith(initialMessages)
      );

    if (initialChatroomMessages) {
      return newMessages(initialChatroomMessages);
    }

    return this.getChatroom(chatroomId).pipe(
      map((chatroom) => chatroom[0]?.messages || []),
      switchMap((initialMessages) => newMessages(initialMessages))
    );
  }

  getChatroomMessage$(chatroomId: string) {
    return this.socketService.socket.pipe(
      switchMap((socket) => {
        if (socket) {
          return fromEvent<ChatroomPayload>(socket, chatroomId);
        }

        return of(null);
      })
    );
  }

  getExistingServiceBases(): Observable<ChatService[]> {
    return this.getChatroomsManual(1, 0).pipe(map((data) => data.services));
  }

  getAllChatrooms(): Observable<ChatRoom[]> {
    return this.socketService.socket.pipe(
      switchMap((socket) => {
        if (!socket) {
          return of(null);
        }

        // TODO: Refactor to a pipe operator
        return this.loadMoreChatroom$.pipe(
          startWith('all'),
          filter((v) => v === 'all'),
          switchMap(() => this.allChatroomsLoaded),
          exhaustMap((allChatroomsLoaded) => {
            if (allChatroomsLoaded) {
              return of(null);
            }

            // const totalPageSize = Math.ceil(this.totalChatrooms / this.chatroomLoadSize);
            const skipValue = this.loadedChatrooms;

            return this.getChatroomsManual(
              this.chatroomLoadSize,
              skipValue
            ).pipe(
              tap((payload) => {
                const totalSize = payload.metadata?.[0]?.total || 0;
                const payloadDataSize = payload.data.length;
                this.totalChatrooms = totalSize;
                this.loadedChatrooms += payloadDataSize;

                if (this.totalChatrooms === this.loadedChatrooms) {
                  this.allChatroomsLoaded.next(true);
                }
              }),
              map((data) => data.data)
            );
          }),
          filter((value) => !!value)
        );
      })
    );
  }

  searchChatrooms(searchParameters: {
    textSearch?: string;
    serviceId?: string;
    unreadOnly?: boolean;
  }): Observable<{ data: ChatRoom[]; total: number }> {
    let additionalSearchObj = {};

    // Search for unread messages for the current user
    if (searchParameters.unreadOnly && this.currentUserId) {
      additionalSearchObj['$expr'] = {
        $not: { $in: [{ $toObjectId: this.currentUserId }, '$readBy'] },
      };
    }

    if (searchParameters.serviceId) {
      additionalSearchObj['$$serviceBase._id'] = searchParameters.serviceId;
    }

    if (searchParameters.textSearch) {
      const searchValue = searchParameters.textSearch;

      const orOptions = [
        {
          name: { $regex: searchValue, $options: 'i' },
        },
        {
          surname: { $regex: searchValue, $options: 'i' },
        },
        {
          'users.name': { $regex: searchValue, $options: 'i' },
        },
        {
          'users.surname': { $regex: searchValue, $options: 'i' },
        },
        {
          'users.email': { $regex: searchValue, $options: 'i' },
        },
        {
          email: { $regex: searchValue, $options: 'i' },
        },
        {
          'serviceBase.brandName': { $regex: searchValue, $options: 'i' },
        },
      ];

      additionalSearchObj['$or'] = orOptions;
    }

    const searchParams: any = {
      userType: this.messagesType,
    };

    if (Object.keys(additionalSearchObj).length > 0) {
      searchParams.additionalParams = JSON.stringify(additionalSearchObj);
    }

    searchParams.limit = this.chatroomLoadSize;

    this.searchChatroomsData = [];

    return this.loadMoreChatroom$.pipe(
      // proccess when the search mode is active
      startWith('search'),
      filter((v) => v == 'search'),

      switchMap((v) => {
        searchParams.skip = this.searchChatroomsData.length;
        const params = new HttpParams({
          fromObject: searchParams,
        });

        return this.httpService
          .get<AllChatrooms>(this.chatroomsEndpoint, { params })
          .pipe(
            map((data) => {
              this.searchChatroomsData = [
                ...this.searchChatroomsData,
                ...data.data,
              ];
              return {
                data: this.searchChatroomsData,
                total: data.metadata?.[0]?.total,
              };
            })
          );
      })
    );
  }

  getChatroom(chatroomId: string): Observable<ChatRoom[]> {
    return this.httpService.get<ChatRoom[]>(
      `${this.chatroomsEndpoint}/${chatroomId}`
    );
  }

  startChatroomWithUser(
    userId: string,
    serviceBase: string,
    serviceEvent?: string,
    booking?: string
  ) {
    const postData: {
      userId: string;
      serviceBase: string;
      serviceEvent?: string;
      booking?: string;
    } = {
      userId,
      serviceBase,
      booking,
    };

    if (serviceEvent) {
      postData.serviceEvent = serviceEvent;
    }
    return this.httpService
      .post<NewChatroomPayload>(this.startChatroomEndpoint, postData)
      .pipe(
        tap((newChatroom) => {
          this.newChatRoom$.next({
            message: 'new chatroom',
            data: newChatroom[0],
          });
        })
      );
  }

  startChatroomWOUser(bookingId: string) {
    return this.httpService
      .post<NewChatroomPayload>(this.startChatroomWOUserEndpoint, { bookingId })
      .pipe(
        tap((newChatroom) => {
          this.newChatRoom$.next({
            message: 'new chatroom',
            data: newChatroom[0],
          });
        })
      );
  }

  sendBookingMessage(bookingId: string, message:string, to = 'vendor') {
    return this.httpService
      .post<NewChatroomPayload>(this.sendBookingMessageEndpoint + '/' + bookingId + '/' + to, {message});
  }  

  sendMessage(messageContent: MessageContent): Observable<any> {
    return this.httpService.post(this.sendMessageEndpoint, {
      ...messageContent,
    });
  }

  markChatroomAsRead(
    chatroomId: string,
    readByUserId: string
  ): Observable<any> {
    /* const newReadObj = {
            message: SingleChatRoomUpdateEvent.innerChatRoomRead,
            data: {
                _id: chatroomId,
                readBy: [ readByUserId ]
            }
        } */

    // this.innerMessageRead$.next(newReadObj);

    return this.httpService.post(this.markAsSeenEndpoint, {
      chatRoomId: chatroomId,
    });
  }

  markChatroomAsUnread(
    chatroomId: string,
    readByUserId: string
  ): Observable<any> {
    /* const newReadObj = {
            message: SingleChatRoomUpdateEvent.innerChatRoomRead,
            data: {
                _id: chatroomId,
                readBy: [ readByUserId ]
            }
        } */

    // this.innerMessageRead$.next(newReadObj);

    return this.httpService.post(this.markAsUnreadEndpoint, {
      chatRoomId: chatroomId,
    });
  }

  chatRoomUpdate$(): Observable<ChatroomUpdatePayload> {
    const message$ = this.socketService.socket.pipe(
      // tap(() => // console.log('Socket change...')),
      switchMap((socket) => {
        if (socket) {
          return fromEvent<ChatroomUpdatePayload>(
            socket,
            MessagingEvent.chatRoomsUpdate
          );
        }

        return of(null);
      })
    );

    return merge(message$, this.innerMessageRead$, this.newChatRoom$);
  }

  editMessage(messageId: string, message: string): Observable<any> {
    return this.httpService.patch(`${this.messageEndpoint}/${messageId}`, {
      message,
    });
  }

  deleteMessage(messageId: string): Observable<any> {
    return this.httpService.delete(`${this.messageEndpoint}/${messageId}`);
  }

  private getChatroomsManual(pageSize: number, offset: number) {
    const params = new HttpParams({
      fromObject: {
        userType: this.messagesType,
        skip: offset,
        limit: pageSize,
      },
    });

    return this.httpService.get<AllChatrooms>(this.chatroomsEndpoint, {
      params,
    });
  }

  handleAutoReplyClosed(data:any) {

  }

  private setEndpoints(): void {
    this.chatroomsEndpoint = `${this.baseUrl}/chat`;
    this.sendMessageEndpoint = `${this.baseUrl}/chat/sendMessage`;
    this.markAsSeenEndpoint = `${this.baseUrl}/chat/markMessageRead`;
    this.markAsUnreadEndpoint = `${this.baseUrl}/chat/markMessageUnread`;
    this.startChatroomEndpoint = `${this.baseUrl}/chat/startChat`;
    this.startChatroomWOUserEndpoint = `${this.baseUrl}/chat/startChatWOUser`;
    this.sendBookingMessageEndpoint = `${this.baseUrl}/chat/sendBookingMessage`;
    
    this.messageEndpoint = `${this.baseUrl}/chat/message`;
  }
}
