interface Photo {
  caption: string;
  originalPhotoUrl: string;
  croppedPhotoUrl: string;
}

interface Photos {
  main: Photo[];
  [key: string]: Photo[];
}

export const PHOTO_SORT = Object.freeze([
  'main',
  'general',
  'buffet',
  'banquet',
  'catering',
  'accomodation'
]);

export function serviceEventPictureSortRaw(photos: Photos) {
  const images: any[] = [];
  const videos: any[] = [];

  if (!photos) {
    return {
      images,
      videos
    };
  }

  PHOTO_SORT.forEach(photoGroupKey => {
    const photosFromGroup = photos[photoGroupKey];
    if (Array.isArray(photosFromGroup)) {
      images.push(...photosFromGroup);
    }
  });

  if (photos.videoUrls) {
    videos.push(...photos.videoUrls);
  }

  const missingKeys = Object.keys(photos).filter((photoGroupKey) => {
    if (photoGroupKey === 'videoUrls') {
      return false;
    }

    const isInSortArray = PHOTO_SORT.includes(photoGroupKey);

    if (isInSortArray) {
      return false;
    }

    return true;
  });

  missingKeys.forEach((photoGroupKey) => {
    const photosFromGroup = photos[photoGroupKey];
    if (Array.isArray(photosFromGroup)) {
      images.push(...photosFromGroup);
    }
  });

  return {
    images,
    videos
  };
}

export function serviceEventPictureSort(photos: Photos): any {
  if (!photos) {
    return [];
  }

  const sortedPhotos = serviceEventPictureSortRaw(photos);

  const images = sortedPhotos.images;
  const videoUrls = sortedPhotos.videos.map((videoUrl) => ({link: videoUrl, type: 'video'})) || [];

  const parsedImages = images.map(imageObj => ({
    link: imageObj?.croppedPhotoUrl || imageObj?.originalPhotoUrl,
    type: 'image'
  }));

  return [
    ...parsedImages,
    ...videoUrls
  ];
}