import { NgModule } from '@angular/core';
import { CamelCaseToNormalTextPipe, KebabCaseToCamelCasePipe, KebabCaseToSnakeCasePipe, FirstUpperCasePipe, SnakeCaseToCamelCasePipe, CamelCaseToKebabCasePipe, PropKebabCaseToSnakeCasePipe } from './case-pipes';

const CASE_PIPES = [
  CamelCaseToNormalTextPipe,
  KebabCaseToCamelCasePipe,
  KebabCaseToSnakeCasePipe,
  FirstUpperCasePipe,
  SnakeCaseToCamelCasePipe,
  CamelCaseToKebabCasePipe,
  PropKebabCaseToSnakeCasePipe
];
@NgModule({
  imports: [],
  exports: [
    ...CASE_PIPES
  ],
  declarations: [
    ...CASE_PIPES
  ],
  providers: [
    ...CASE_PIPES
  ],
})
export class CasePipesModule { }
