import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { AssistantChatService } from '../services/assistant-chat.service';
import { ChatMessageComponent } from '../chat-messages-standalone/message/message.component';
import { BehaviorSubject, Observable, Subject, combineLatest, delay, map, of, scan, shareReplay, startWith, switchMap, take, takeUntil, timer, NEVER } from 'rxjs';
import { ChatMessagesActionsComponent } from '../chat-messages-standalone/actions/actions.component';
import { ShowTimePassedPipe } from '../pipes/next-user-different.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PortalModule } from '@angular/cdk/portal';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { loadComponent } from './utils/component-loader';
import { QuoteSummaryComponent } from './quote-summary/quote-summary.component';
import { BookATourSummaryComponent } from './book-a-tour-summary/book-a-tour-summary.component';
import { DebugPromptComponent } from './debug-prompt/debug-prompt.component';
import { INQUIRY_FLOW_PROMPT, REELS_PROMPT } from './assistant-chat.data';
import { format } from 'date-fns';
import { getPdfLink, getShortenedProposalId } from './utils/pdf-link';
import { ButtonModule } from '@jarvis/ui';
import { BASE_URL, LINK_URLS, ServicesService } from '@jarvis/services';
import { AssistantChatPromptService } from './prompts';

interface ChatInfo {
  chatMessages: any[];
  chatRoom: any;
}

let GLOBAL_SELF_ASSISTANT_MESSAGE_ID = 0;

// Action example
/*
{
    "status": "requires_action",
    "toolCalls": [
        {
            "id": "call_6fjbRiIpQpdwV9YBTdLXm9Ai",
            "type": "function",
            "function": {
                "name": "is_ready_for_proposal",
                "arguments": "{\"answer\":\"yes\"}"
            }
        }
    ]
}

{
    "status": "requires_action",
    "toolCalls": [
        {
            "id": "call_NVAWoZf2iEIJY9MqP6rBMhe6",
            "type": "function",
            "function": {
                "name": "is_ready_for_book_a_tour",
                "arguments": "{\n  \"answer\": \"yes\"\n}"
            }
        }
    ]
}

{
    "_id": "6165798beb9b6409e413a448",
    "selectedDates": [
        "2024-02-29T14:00:00.656Z"
    ],
    "talkToConfirm": 1,
    "eventDate": null,
    "guestCount": 8,
    "message": null,
    "noEventDate": true,
    "name": "Linas",
    "surname": "Einingis",
    "phoneNo": "863073262",
    "email": "linas.einingis@gmail.com",
    "url": "https://marketplace.breezit.com",
    "country": "LT",
    "serviceName": "Test Venue B",
    "slug": "test-venue-b"
}
*/

@Component({
  selector: 'messaging-assistant-chat',
  templateUrl: 'assistant-chat.component.html',
  styleUrls: [
    './assistant-chat.component.scss'
  ],
  standalone: true,
  imports: [
    CommonModule,
    ChatMessageComponent,
    ChatMessagesActionsComponent,
    ShowTimePassedPipe,
    ButtonModule,
    TranslateModule,
    PortalModule,
    ReactiveFormsModule,
    QuoteSummaryComponent,
    BookATourSummaryComponent,
    DebugPromptComponent
  ],
  providers: [
    AssistantChatPromptService
  ]
})

export class MessagingAssistantChatComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('messageContainer')
  set messagesContainer(content: ElementRef<HTMLDivElement>) {
    if (content) {
      this._messagesContainer = content;
      this.scrollToBottom();
      return;
    }

    this._messagesContainer = null;
  }
  get messagesContainer() {
    return this._messagesContainer;
  }
  _messagesContainer: ElementRef<HTMLDivElement>;

  @ViewChild(ChatMessagesActionsComponent) messageActions: ChatMessagesActionsComponent;

  // TODO: Remove after tests
  @Input() serviceEventId = '61541674063125b8f361dcd6';
  @Input() serviceBaseId = '61541674063125b8f361dcd5';
  @Input() currentUserId = 'TEST3';
  @Input() bookingData: any;
  @Input() placeholder = 'assistantChat.chatActionPlaceholder';
  
  @Input() type = 'after_inquiry';

  @Output() messageCount = new EventEmitter<number>();
  @Output() inputBlurred = new EventEmitter<void>();

  messageInProgress = false;

  promptControl: FormControl<string>;

  private assistantService = inject(AssistantChatService);
  private vcr = inject(ViewContainerRef);
  private translateService = inject(TranslateService);
  private linkUrls = inject(LINK_URLS);
  private baseUrl = inject(BASE_URL);
  private servicesService = inject(ServicesService);
  private promptService = inject(AssistantChatPromptService);

  private returnedResponsesSource = new Subject<any>();
  private returnedResponses$ = this.returnedResponsesSource.pipe(
    scan((messages, newMessage) => {
      
      // Replace messages with same lastId
      if (newMessage.lastId) {
        const existingMessageIndex = messages.findIndex(message => message.lastId === newMessage.lastId);
        if (existingMessageIndex > -1) {
          const newMessageModified = {
            ...messages[existingMessageIndex],
            type: newMessage.type || messages[existingMessageIndex].type,
            message: newMessage.message || messages[existingMessageIndex].message,
            status: newMessage.status || messages[existingMessageIndex].status,
            source: newMessage.source || messages[existingMessageIndex].source,
            options: newMessage.options || messages[existingMessageIndex].options,
            error: newMessage.error || false
          };

          if (newMessage.error) {
            newMessageModified.error = newMessage.error;
          }
          
          const messageCopy = [...messages];

          messageCopy.splice(existingMessageIndex, 1, ...(newMessage.delete ? [] : [newMessageModified]));

          return messageCopy;
        }
      }

      return [
        ...messages,
        newMessage
      ];
    }, []),
    startWith([]),
    shareReplay(1)
  );

  private chatRoomUpdateSource = new Subject<any>();
  private chatRoomUpdate$ = this.chatRoomUpdateSource.asObservable().pipe(
    startWith(null),
    shareReplay(1)
  );

  chatInfo$: Observable<ChatInfo>;
  bookingData$;
  listingData$;
  tourData$;
  bookingDate$: Observable<string>;
  listingMainPhoto$: Observable<string>;
  prompt$: Observable<string>;

  private getAQuoteLoader$;
  private bookATourLoader$;
  private pdfPreviewLoader$;

  private showGetAQuoteFlowSource = new BehaviorSubject<boolean>(false);
  private showBookATourSource = new BehaviorSubject<boolean>(false);
  private showDebugPromptSource = new BehaviorSubject<boolean>(false);
  private showPdfPreviewSource = new BehaviorSubject<boolean>(false);

  showDebugPrompt$ = this.showDebugPromptSource.asObservable();
  
  getAQuoteFlowPortal$ = this.showGetAQuoteFlowSource.asObservable().pipe(
    switchMap((isOpen) => {
      if (isOpen) {
        return this.getAQuoteLoader$;
      }

      return of(null);
    })
  );

  bookATourFlowPortal$ = this.showBookATourSource.asObservable().pipe(
    switchMap((isOpen) => {
      if (isOpen) {
        return this.bookATourLoader$;
      }

      return of(null);
    })
  );

  pdfPreviewPortal$ = this.showPdfPreviewSource.asObservable().pipe(
    switchMap((isOpen) => {
      if (isOpen) {
        return this.pdfPreviewLoader$;
      }

      return of(null);
    })
  );

  private destroy$ = new Subject<void>();

  @Input()
  focusActions(focus: boolean) {
    if (focus) {
      this.messageActions?.messageInput?.nativeElement.focus();
      return;
    }

    this.messageActions?.messageInput?.nativeElement.blur();
  }

  ngOnInit(): void {
    this.promptControl = new FormControl(this.type === 'reels' ? REELS_PROMPT : INQUIRY_FLOW_PROMPT, { validators: [ Validators.required ]});
    this.chatInfo$ = this.generateChatStream();
    this.bookingData$ = this.generateBookingDataStream(this.chatInfo$);
    this.bookingDate$ = this.generateBookingFormattedDateStream(this.bookingData$);
    this.tourData$ = this.generateBookATourStream(this.chatInfo$);
    this.listingData$ = this.generateListingDataStream(this.bookingData$);
    this.listingMainPhoto$ = this.generateListingMainPhotoStream(this.listingData$);
    this.prompt$ = this.generatePromptStream(this.listingData$, this.bookingData$);

    this.getAQuoteLoader$ = this.generateGetAQuoteLoader();
    this.pdfPreviewLoader$ = this.generatePDFPreviewLoader(this.bookingData$);
    this.bookATourLoader$ = this.generateBookATourLoader(this.chatInfo$.pipe(
      map((chatInfo: any) => {
        return chatInfo.chatRoom.booking;
      })
    ));

    this.chatInfo$.pipe(
      delay(0),
      takeUntil(this.destroy$)
    ).subscribe((chatInfo) => {
      const messageCount = chatInfo.chatMessages.length;
      this.messageCount.emit(messageCount);
      this.scrollToBottom();
    });
  }

  ngAfterViewInit() {
    this.chatInfo$.pipe(
      take(1),
    ).subscribe((chatInfo) => {
      if (chatInfo.chatMessages.length === 0) {
        this.sendMessage('');
      }
    });
  }

  ngOnDestroy(): void {
    this.showGetAQuoteFlowSource.complete();
    this.showDebugPromptSource.complete();
    this.showBookATourSource.complete();
    this.showPdfPreviewSource.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  inputBlurredHandler(): void {
    this.inputBlurred.emit();
  }
  
  continueWithoutQuoteHandler() {
    const message = this.translateService.instant('assistantChat.noFirstProposal');
    
    this.assistantService.sendMessage(
      this.serviceBaseId,
      this.currentUserId,
      message
    ).subscribe((message) => {
      this.returnedResponsesSource.next(message);
    });
  }

  openPdfPreview() {
    this.showPdfPreviewSource.next(true);
  }

  closePdfPreview() {
    this.showPdfPreviewSource.next(false);
  }

  showDebugPrompt() {
    this.showDebugPromptSource.next(true);
  }

  closeDebugPrompt() {
    this.showDebugPromptSource.next(false);
  }

  getAQuoteFlowHandler() {
    this.showGetAQuoteFlowSource.next(true);
  }

  getAQuoteNotReadyHandler() {
    const negativeReply = this.translateService.instant('assistantChat.notReadyForQuote');
    this.sendMessage(negativeReply);
  }

  closeGetAQuote() {
    this.showGetAQuoteFlowSource.next(false);
  }

  bookATourFlowHandler() {
    this.showBookATourSource.next(true);
  }

  bookATourNotReadyHandler() {
    const negativeReply = this.translateService.instant('assistantChat.notReadyForTour');
    this.sendMessage(negativeReply);
  }

  closeBookATour() {
    this.showBookATourSource.next(false);
  }

  private sendMessageDebug(selfMessageId, selfAssistantMessageId) {
    
    timer(1000).subscribe(() => {
      this.messageInProgress = false;

      /* const response = {
        lastId: selfMessageId,
        options: {
          toolCall: {
            "id": "call_NVAWoZf2iEIJY9MqP6rBMhe6",
            "type": "function",
            "function": {
              "name": "is_ready_for_book_a_tour",
              "arguments": "{\n  \"answer\": \"yes\"\n}"
            }
          },
          toolCall: {
            "id": "call_6fjbRiIpQpdwV9YBTdLXm9Ai",
            "type": "function",
            "function": {
              "name": "is_ready_for_proposal",
              "arguments": "{\"answer\":\"yes\"}"
            }
          }
        }
      };

      this.returnedResponsesSource.next(response); */
      
      /* const placeHolderDeleteMessage = {
        lastId: selfAssistantMessageId,
        delete: true
      };

      // Deletes placeholder
      this.returnedResponsesSource.next(placeHolderDeleteMessage);
      
      const actionReplaceMessage = {
        lastId: selfMessageId,
        source: 'gptAction'
      };

      // Replaces self message
      this.returnedResponsesSource.next(actionReplaceMessage); */

      this.messageInProgress = false;
      const errorMessage = {
        lastId: selfMessageId,
        type: "text",
        createdAt: new Date(),
        error: true
      };

      // Replaces self message
      this.returnedResponsesSource.next(errorMessage);

      const placeHolderDeleteMessage = {
        lastId: selfAssistantMessageId,
        type: "error",
        text: "error",
        delete: true
      };

      // Deletes placeholder
      this.returnedResponsesSource.next(placeHolderDeleteMessage);
    });

    return;
  }

  retrySendMessage(messageData) {
    const placeHolderDeleteMessage = {
      lastId: messageData.lastId,
      type: "error",
      text: "error",
      delete: true
    };

    // Deletes placeholder
    this.returnedResponsesSource.next(placeHolderDeleteMessage);

    // Resends message
    this.sendMessage(messageData.message);
  }

  sendMessage(message: string, selfMessageLastId?: number) {
    this.messageInProgress = true;

    const selfMessageId = selfMessageLastId || `${++GLOBAL_SELF_ASSISTANT_MESSAGE_ID}`;
    const selfMessage = {
      lastId: selfMessageId,
      message,
      type: "text",
      createdAt: new Date(),
      sender: this.currentUserId
    };

    const selfAssistantMessageId = `${++GLOBAL_SELF_ASSISTANT_MESSAGE_ID}`;
    const loadingAssistantMessage = {
      lastId: selfAssistantMessageId,
      type: "loading",
      message: "loading",
      createdAt: new Date()
    };

    this.returnedResponsesSource.next(selfMessage);
    this.returnedResponsesSource.next(loadingAssistantMessage);
    
    this.prompt$.pipe(
      switchMap(prompt => {
        if (!prompt) {
          return NEVER;
        }

        return this.assistantService.startChatUnregistered(
          this.serviceBaseId,
          this.currentUserId,
          message,
          prompt
        );
      })
    ).subscribe({
      next: (res) => {
        this.messageInProgress = false;
        if (res.status === 'requires_action') {
          const actionReplaceMessage = {
            lastId: selfMessageId,
            options: {
              toolCall: res.toolCalls[0]
            }
          };
    
          // Replaces self message
          this.returnedResponsesSource.next(actionReplaceMessage);
    
          const placeHolderDeleteMessage = {
            lastId: selfAssistantMessageId,
            delete: true
          };
    
          // Deletes placeholder
          this.returnedResponsesSource.next(placeHolderDeleteMessage);
          
          return;
        }
        
        this.returnedResponsesSource.next({
          ...res,
          lastId: selfAssistantMessageId
        });
      },
      error: () => {
        this.messageInProgress = false;
        const errorMessage = {
          lastId: selfMessageId,
          type: "text",
          createdAt: new Date(),
          error: true
        };
  
        // Replaces self message
        this.returnedResponsesSource.next(errorMessage);
  
        const placeHolderDeleteMessage = {
          lastId: selfAssistantMessageId,
          type: "error",
          text: "error",
          delete: true
        };
  
        // Deletes placeholder
        this.returnedResponsesSource.next(placeHolderDeleteMessage);
      }
    });
  }

  scrollToBottom() {
    if (!this.messagesContainer) {
      return;
    }

    const scrollContainerHeight = this.messagesContainer.nativeElement.scrollHeight;

    this.messagesContainer.nativeElement.scroll({
      top: scrollContainerHeight,
    });
  }

  messageTrackBy(index: number, el: any) {
    return el._id;
  }

  private generateChatStream() {
    const initialChatWithFirstMessage$ = this.assistantService.getChat(
      this.serviceBaseId,
      this.currentUserId
    ).pipe(
      switchMap((response) => {
        // Placeholder for future

        if (!response.chatRoom.booking && this.type === 'after_inquiry' && this.bookingData?._id) {
          const bookingData = this.bookingData;
          
          return this.assistantService.addBookingToChat(this.serviceBaseId, this.currentUserId, bookingData._id, ' ').pipe(
            map(() => {
              return {
                ...response,
                chatRoom: {
                  ...response.chatRoom,
                  booking: bookingData
                }
              };
            })
          );
        }

        if (this.type === 'reels' && response.chatMessages.length === 0) {
          const message = this.translateService.instant('assistantChat.reelsFirstMessage');

          return this.assistantService.sendMessage(this.serviceBaseId, this.currentUserId, message).pipe(
            map((firstMessage) => {
              const chatMessages = [
                firstMessage,
                ...response.chatMessages
              ];
    
              return {
                ...response,
                chatMessages
              };
            })
          );
        }

        /* if (response.chatMessages && response.chatMessages.length === 0) {
          return this.assistantService.startChatUnregistered(this.serviceBaseId, this.currentUserId, '', this.promptControl.value).pipe(
            map((firstMessageResponse) => {
              response.chatMessages = [
                firstMessageResponse,
                ...response.chatMessages
              ];
  
              return response;
            })
          );
        } */

        return of(response);
      })
    );

    return combineLatest([
      initialChatWithFirstMessage$,
      this.returnedResponses$,
      this.chatRoomUpdate$
    ]).pipe(
      map(([response, returnedMessages, chatRoomUpdate]) => {
  
        if (chatRoomUpdate?.booking) {
          response.chatRoom.booking = chatRoomUpdate.booking;
        }

        if (chatRoomUpdate?.bookATour) {
          response.chatRoom.options = {
            ...response.chatRoom.options,
            bookATour: chatRoomUpdate.bookATour
          };
        }
  
        const chatMessages = response.chatMessages
          .map(message => {
            if (message.sender) {
              return message;
            }
    
            return {
              ...message,
              sender: this.currentUserId
            };
          });
  
        const mergedMessages = [
          ...chatMessages,
          ...returnedMessages
        ].filter((message) => {
          if (message.type === 'text') {
            // TODO: Any whitespace validation should filter out
            return !!message.message && message.message !== ' ';
          }

          return true;
        });
  
        const sortedMessages = mergedMessages.sort((a, b) => {
          return a.createdAt < b.createdAt ? -1 : 1;
        });
  
        return {
          ...response,
          chatMessages: sortedMessages
        };
      }),
      shareReplay(1)
    );
  }

  // TODO: Inefficient, since we subscribe for EACH! message
  private generateBookingDataStream(chatInfoStream: Observable<any>) {
    return chatInfoStream.pipe(
      map((chatInfo) => {
        return chatInfo.chatRoom?.booking;
      }),
      shareReplay(1)
    );
  }

  private generateBookingFormattedDateStream(bookingDataStream: Observable<any>) {
    return bookingDataStream.pipe(
      map((bookingData) => {
        if (bookingData.options?.noEventDate) {
          return null;
        }

        const rawDate = bookingData.eventDate;
        const formattedDate = format(rawDate, 'ddd, MMM Do `YY');
        return formattedDate;
      }),
      shareReplay(1)
    );
  }

  private generateListingDataStream(bookingDataStream: Observable<any>) {
    return bookingDataStream.pipe(
      take(1),
      switchMap((bookingData) => {
        if (!bookingData) {
          return this.servicesService.getMarketplaceListing(this.serviceEventId);
        }

        // TODO: Change event sources to return listing data?
        const serviceEventId = bookingData.serviceEventId?._id || bookingData.serviceEventId;

        return this.servicesService.getMarketplaceListing(serviceEventId);
      }),
      shareReplay(1)
    );
  }

  private generateListingMainPhotoStream(listingDataStream: Observable<any>) {
    return listingDataStream.pipe(
      map((listingData: any) => {
        if (!listingData) {
          return null;
        }

        const generalServiceObj = listingData["general"];
        const weddingServiceObj = listingData["wedding"];
        
        const mainPhotoLink = 
          weddingServiceObj?.photos?.main[0]?.croppedPhotoUrl ||
          weddingServiceObj?.photos?.main[0]?.originalPhotoUrl ||
          generalServiceObj?.photos?.main[0]?.croppedPhotoUrl ||
          generalServiceObj?.photos?.main[0]?.originalPhotoUrl;

        return mainPhotoLink;
      }),
      shareReplay(1)
    );
  }
  
  private generateBookATourStream(chatInfoStream: Observable<any>) {
    return chatInfoStream.pipe(
      map((chatInfo) => {
        return chatInfo.chatRoom?.options?.bookATour;
      }),
      shareReplay(1)
    );
  }

  private generatePromptStream(listingDataStream: Observable<any>, bookingDataStream: Observable<any>) {
    return combineLatest({
      listingData: listingDataStream,
      bookingData: bookingDataStream
    }).pipe(
      map(({ listingData, bookingData }) => {
        if (!listingData) {
          return null;
        }

        const serviceEvent = listingData;
        const serviceSubEvent = listingData["general"] || listingData["general"];
        const serviceBase = listingData.serviceBase;

        if (this.type === 'after_inquiry') {
          if (!bookingData) {
            return null;
          }

          const prompt = this.promptService.generateInquityFlowPrompt(serviceBase, serviceEvent, bookingData);
          return prompt;
        }

        if (this.type === 'reels') {
          const prompt = this.promptService.generateReelsFlowPrompt(serviceBase, serviceEvent);
          return prompt;
        }

        throw new Error('Bad assistant chat type');
      })
    );
  }

  private generateGetAQuoteLoader() {
    return loadComponent(() => import('./get-a-quote-wrapper'), { serviceEventId: this.serviceEventId, currentUserId: this.currentUserId }, this.vcr, (ref) => {
      const { instance } = ref;
      // Set Outputs
      instance.closed.pipe(
        takeUntil(this.destroy$),
        takeUntil(instance.destroy$)
      ).subscribe(() => {
        this.closeGetAQuote();
      });
  
      instance.bookingAddedMessage.pipe(
        takeUntil(this.destroy$),
        takeUntil(instance.destroy$),
      ).subscribe((response) => {
        const { messages, booking } = response;
        messages.forEach(message => {
          this.returnedResponsesSource.next(message);
        });
        this.chatRoomUpdateSource.next({
          booking
        });
        this.closeGetAQuote();
      });
    });
  }

  private generateBookATourLoader(booking$: Observable<any>) {
    return booking$.pipe(
      switchMap((booking) => loadComponent(() => import('./book-a-tour-wrapper'), { bookingId: (booking?._id ?? null), serviceEventId: this.serviceEventId }, this.vcr, (ref) => {
        const { instance } = ref;

        // Set Outputs
        instance.closed.pipe(
          takeUntil(this.destroy$),
          takeUntil(instance.destroy$)
        ).subscribe({
          next: () => {
            this.closeBookATour();
          }
        });
    
        instance.tourBooked.pipe(
          takeUntil(this.destroy$),
          takeUntil(instance.destroy$),
          switchMap((bookedTourData) => {
            return this.chatInfo$.pipe(
              take(1),
              map((chatInfo: any) => {
                const chatRoomId: string = chatInfo.chatRoom._id;

                return {
                  bookedTourData,
                  chatRoomId
                };
              })
            );
          }),
          switchMap(({bookedTourData, chatRoomId}) => {
            return this.assistantService.addBookATour(chatRoomId, this.currentUserId, bookedTourData).pipe(
              map(() => bookedTourData)
            );
          }),
          switchMap((bookedTourData) => {
            const message = this.translateService.instant('assistantChat.tourBookedMessage', { phoneNo: '+1 (213) 471-2442'});
            return this.assistantService.sendMessage(this.serviceBaseId, this.currentUserId, message).pipe(
              map((newMessage) => ({bookedTourData, newMessage}))
            );
          }),
        ).subscribe((response) => {
          const { bookedTourData, newMessage } = response;
          this.returnedResponsesSource.next(newMessage);
          this.chatRoomUpdateSource.next({
            bookATour: bookedTourData
          });
          this.closeBookATour();
        });
      }))
    );
  }

  private generatePDFPreviewLoader(booking$: Observable<any>) {return booking$.pipe(
    switchMap((booking) => {
      if (!booking) {
        return NEVER;
      }

      const { serviceName, _id: bookingId } = booking;

      const pdfLink = getPdfLink(bookingId, serviceName, this.linkUrls);

      // Only in development
      // const shortProposalId = getShortenedProposalId(bookingId);
      // const pdfLink = `/${this.baseUrl}/pdf/${shortProposalId}/listing?country=US`;

      return loadComponent(() => import('./pdf-preview'), { pdfLink }, this.vcr, (ref) => {
        const { instance } = ref;
        // Set Outputs
        instance.closed.pipe(
          takeUntil(this.destroy$),
          takeUntil(instance.destroy$)
        ).subscribe(() => {
          this.closePdfPreview();
        });
  
      });
    })
  );
  }
    
}