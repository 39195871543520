<div
  class="listings-container bztt-flex bztt-flex-col bztt-justify-center bztt-items-center bztt-h-full bztt-w-full bztt-py-[78px] bztt-px-6"
>
  <div
    class="listings-inner-container bztt-max-w-[84rem] bztt-w-full bztt-flex bztt-flex-col bztt-gap-8 md:bztt-gap-12 xl:bztt-px-9 bztt-mb-10"
  >
    <div class="listings-section-title">
      <span class="bztt-text-[#1F1F1F]">
        {{ 'landing.homepageUplift.findOutstandingVendors.title' | translate }}
      </span>
      <span *ngIf="isLt">
        <!-- <mat-form-field appearance="none" class="bztt-h-[50px] md:bztt-h-[57px] bztt-w-fit bztt-border-b-2 bztt-border-[#EF5518]">
          <mat-select
            class="bztt-w-fit titleSelect"
            [formControl]="titleTypeSelection"
            panelClass="titleSelectorPanel"
          >
            <mat-option
              *ngFor="let option of titleOptions"
              [value]="option.value"
              >{{ option.label | translate }}</mat-option
            >
          </mat-select>
        </mat-form-field> -->
        <span>
          <span
            class="title-selector-text bztt-text-[#ef5518] bztt-text-[35px] md:bztt-text-[57px] bztt-font-semibold bztt-border-b-2 bztt-border-[#ef5518] bztt-cursor-pointer"
            [matMenuTriggerFor]="typeSelectMenu"
          >
            {{
              titleSelectorOptions.lt[titleTypeSelection.value]
                | translate
                | lowercase
            }}
          </span>
          <mat-icon
            class="bztt-text-[#ef5518] bztt-h-[37px] bztt-w-[57px] bztt-text-[50px] -bztt-ml-4 md:bztt-h-[47px] md:bztt-w-[74px] md:bztt-text-[65px]"
            >arrow_drop_down</mat-icon
          >
        </span>

        <mat-menu
          #typeSelectMenu="matMenu"
          class="typeSelectorPanel"
          [xPosition]="'after'"
        >
          <ng-container *ngFor="let optionKey of ltTitleOptions">
            <button
              *ngIf="optionKey !== titleTypeSelection.value"
              mat-menu-item
              (click)="titleTypeSelection.setValue(optionKey)"
            >
              <span>{{ titleSelectorOptions.lt[optionKey] | translate }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </span>
      <!-- <span
        class="bztt-text-[#ef5518] bztt-text-[35px] md:bztt-text-[57px] bztt-font-semibold"
        *ngIf="!isLt"
      >
        California
      </span> -->
      <span *ngIf="!isLt" (click)="handleLocationOverlay()" #overlayOrigin>
        <span
          class="title-selector-text bztt-text-[#ef5518] bztt-text-[35px] md:bztt-text-[57px] bztt-font-semibold bztt-border-b-2 bztt-border-[#ef5518] bztt-cursor-pointer"
        >
          {{ locationSelectionValue.name }}
        </span>
        <mat-icon
          class="bztt-text-[#ef5518] bztt-h-[37px] bztt-w-[57px] bztt-text-[50px] -bztt-ml-4 md:bztt-h-[47px] md:bztt-w-[74px] md:bztt-text-[65px]"
          >arrow_drop_down</mat-icon
        >
      </span>
    </div>
    <div
      id="categoryScrollContainer"
      class="bztt-flex bztt-flex-row bztt-self-start bztt-w-full bztt-overflow-x-scroll scrollbar-hide"
      *ngIf="isLt"
    >
      <div
        class="bztt-flex bztt-flex-col bztt-cursor-pointer"
        (click)="handleCategoryChange(category.category, i)"
        *ngFor="let category of linkList; let i = index"
        [class.activeCategory]="activeCategory === category.category"
      >
        <div
          class="bztt-pr-10 bztt-py-6 bztt-flex bztt-flex-row bztt-justify-start bztt-items-center bztt-gap-2"
        >
          <div
            class="icon bztt-h-7 bztt-w-7 bztt-min-h-[28px] bztt-min-w-[28px] bztt-rounded-full bztt-flex bztt-justify-center bztt-items-center bztt-bg-[#A8A8A8]"
          >
            <!-- <img
              class="bztt-h-[13px] bztt-object-contain bztt-invert"
              [src]="ICON_DATA[category.iconName]"
            /> -->
            <mat-icon
              class="material-icons-outlined bztt-text-white bztt-text-[17px] bztt-h-[17px] bztt-w-[17px]"
              >{{ category.iconName + '_outlined' }}</mat-icon
            >
          </div>
          <div
            class="bztt-font-semibold bztt-text-[#A8A8A8] text bztt-whitespace-nowrap"
          >
            {{ 'categoriesMarketplace.' + category.category | translate }}
          </div>
        </div>
        <div class="bztt-h-1 bztt-bg-[#A8A8A8] line"></div>
      </div>
    </div>
  </div>

  <div
    *ngIf="servicesToShow && servicesToShow.length > 0; else noData"
    class="services-container bztt-w-full md:bztt-max-w-[84rem] xl:bztt-px-9 bztt-pb-[18px]"
  >
    <jarvis-ui-marketplace-card
      class="md:bztt-max-w-[324px] bztt-min-w-[291px] md:bztt-min-w-[unset]"
      *ngFor="let service of servicesToShow"
      [data]="service | CardData : eventType : dynamicCardParam$ | async"
      [images]="service | CardPictures : eventType"
      [liked]="service.likeId"
      (likeEvent)="serviceLikeHandler(service)"
      cardClickEventType="homepage"
      [isMobile]="isMobile | async"
    ></jarvis-ui-marketplace-card>
    <div
      *ngIf="services?.length"
      class="see-more-card md:bztt-max-w-[324px] bztt-min-w-[291px] md:bztt-min-w-[unset] bztt-w-full bztt-flex bztt-items-center bztt-justify-center bztt-cursor-pointer"
      (click)="handleShowMore()"
      matRipple
    >
      <div
        class="bztt-flex bztt-flex-col bztt-gap-y-3 bztt-items-center bztt-font-semibold"
      >
        <div
          class="see-more-button bztt-h-20 bztt-w-20 bztt-rounded-full bztt-flex bztt-items-center bztt-justify-center"
        >
          <mat-icon>arrow_forward</mat-icon>
        </div>
        {{ 'landing.homepage.seeMore' | translate }}
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <div
    class="bztt-relative bztt-flex bztt-items-center bztt-justify-center bztt-flex-col bztt-gap-10 bztt-py-16"
  >
    <div class="bztt-text-3xl bztt-font-montserrat-bold">
      {{ 'filters.noData.nothingHere' | translate }}
    </div>
    <div class="bztt-text-base bztt-font-montserrat-bold">
      {{ 'filters.noData.areYouVendor' | translate }}
      <a
        class="bztt-text-base bztt-font-montserrat-bold bztt-font-normal"
        [href]="
          isLt
            ? '/registracija?source=marketplace-search-page'
            : '/sign-up?source=marketplace-search-page'
        "
      >
        {{ 'filters.noData.linkText' | translate }}</a
      >
    </div>
  </div>
</ng-template>
