import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ShowActionsInline',
  pure: true,
  standalone: true
})

export class ShowActionsInlinePipe implements PipeTransform {
  transform(messageData: any, isOwn: boolean, lastMessage = false): boolean {

    if (!isOwn && lastMessage) {
      return true;
    }

    if (!isOwn) {
      return false;
    }

    if (messageData.type === 'deleted') {
      return false;
    }

    if (messageData.type === 'serviceEventMessage') {
      return false;
    }

    if (messageData.uploading || messageData.error) {
      return false;
    }

    return true;
  }
}