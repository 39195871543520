<div class="coop-plans-container bztt-flex bztt-flex-row">
  <div
    class="pricing-description-container bztt-min-w-[300px] bztt-hidden md:bztt-block"
  >
    <div style="height: 144px"></div>
    <div
      *ngFor="let label of Object.values(membershipsData.labels)"
      class="pricing-description-item bztt-pr-14"
    >
      {{ label }}
    </div>
  </div>
  <div
    class="coop-plan-items bztt-flex bztt-flex-col md:bztt-flex-row bztt-gap-5 bztt-w-full md:bztt-w-auto"
  >
    <ng-container *ngFor="let plan of membershipsData.memberships">
      <jarvis-membership-item
        [plan]="plan"
        [labels]="membershipsData.labels"
      ></jarvis-membership-item>
    </ng-container>
  </div>
</div>
