import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rules-policies',
  templateUrl: './rules-policies.component.html',
  styleUrls: ['./rules-policies.component.scss']
})
export class RulesPoliciesComponent implements OnInit {

  buttons = [
    {
      label: 'rulesPolicies.rules',
      value: 'rules',
    },
    {
      label: 'rulesPolicies.privacy',
      value: 'privacy'
    },
    {
      label: 'rulesPolicies.cookie',
      value: 'cookies'
    }
  ];

  activeButton: any;

  ngOnInit(): void {
    if (!this.activeButton) {
      this.activeButton = this.buttons[0];
    }
  }

  changeActiveTab(buttonItem): void {
    this.activeButton = buttonItem;
  }

}
