import { Component, Inject, Optional, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./confirm-modal.component.scss'],
  providers: [ TranslatePipe ]
})
export class ConfirmDialogComponent {
  public i18nKey = 'confirm-modal';
  titleSet = false;
  confirmButtonSet = false;
  cancelButtonSet = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { i18nKey?: string, onCancel?: () => void; onConfirm?: () => void; },
    @Optional() private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private translatePipe: TranslatePipe
  ) {
    if (data.i18nKey) {
      this.i18nKey = data.i18nKey;
      this.titleSet = this.isMessageSet('title');
      this.confirmButtonSet = this.isMessageSet('confirm');
      this.cancelButtonSet = this.isMessageSet('cancel');
    }
  }

  public isMessageSet(key: string): boolean {
    return this.translatePipe.transform(this.i18nKey + '.' + key) !== (this.i18nKey + '.' + key);
  }

  public cancel(): void {
    if (this.data.onCancel) this.data.onCancel();
    this.close(false);
  }

  public close(value): void {
    this.dialogRef.close(value);
  }

  public confirm(): void {
    if (this.data.onConfirm) this.data.onConfirm();
    this.close(true);
  }
}
