import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'messaging-overlay-menu',
    templateUrl: 'overlay-menu.component.html',
    styleUrls: [
        './overlay-menu.component.scss'
    ]
})

export class MessagesOverlayMenuComponent {

    @Input()
    overlayOrigin: CdkOverlayOrigin;

    @Input()
    isOpen = false;

    @Output()
    isOpenChange = new EventEmitter<boolean>();

    constructor() { }

    close() {
        this.isOpen = false;
        this.isOpenChange.next(this.isOpen);
    }
}