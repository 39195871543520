export const HOMEPAGE_CATEGORIES = {
  lt: [
    {
      title: 'venues',
      category: 'venues',
      iconName: 'location_on',
      link: 'vestuviu-vietos',
      label: 'Šventės vietos',
      description: 'Šventės vietos aprašymas',
      image: 'assets/images/landing/homepage-uplift/categories/lt/venues.png',
      count: 1000,
      countLabel: 'vietų',
    },
    {
      title: 'photographer',
      category: 'photographer',
      iconName: 'photo_camera',
      link: 'vestuviu-fotografija',
      label: 'Fotografai',
      description: 'Fotografų aprašymas',
      image:
        'assets/images/landing/homepage-uplift/categories/lt/photographer.png',
      count: 400,
      countLabel: 'fotografų',
    },
    {
      title: 'host',
      category: 'host',
      iconName: 'mic',
      link: 'vestuviu-vedejai',
      label: 'Vedėjai',
      description: 'vedeju aprašymas',
      image: 'assets/images/landing/homepage-uplift/categories/lt/host.png',
      count: 700,
      countLabel: 'vedėjų',
    },
    {
      title: 'live_music',
      category: 'live_music',
      iconName: 'music_note',
      link: 'gyva-muzika-vestuvems',
      label: 'Gyva muzika',
      description: 'muzikantu aprašymas',
      image:
        'assets/images/landing/homepage-uplift/categories/lt/live_music.png',
      count: 3000,
      countLabel: 'muzikantų',
    },
    {
      title: 'planning',
      category: 'planning',
      iconName: 'checklist',
      link: 'vestuviu-planavimas',
      label: 'Planavimas',
      description: 'planuotojų aprašymas',
      image: 'assets/images/landing/homepage-uplift/categories/lt/planner.png',
      count: 800,
      countLabel: 'planuotojų',
    },
  ],
  us: [
    {
      link: 'wedding-venues/northern-california',
      label: 'Northern California',
      value: 'norCa',
      description: 'Šventės vietos aprašymas',
      image: 'assets/images/landing/homepage-uplift/categories/us/norCa.png',
      count: 1000,
      countLabel: 'places',
      showInCategories: true,
    },
    {
      link: 'wedding-venues/southern-california',
      label: 'Southern California',
      value: 'soutCa',
      description: 'Šventės vietos aprašymas',
      image: 'assets/images/landing/homepage-uplift/categories/us/souCa.png',
      count: 1000,
      countLabel: 'places',
      showInCategories: true,
    },
    {
      link: 'wedding-venues/los-angeles',
      label: 'Los Angeles',
      value: 'losAngeles',
      description: 'Šventės vietos aprašymas',
      image:
        'assets/images/landing/homepage-uplift/categories/us/losAngeles.png',
      count: 1000,
      countLabel: 'places',
      showInCategories: true,
    },
    {
      link: 'wedding-venues/orange-county',
      label: 'Orange County',
      value: 'orangeCounty',
      description: 'Šventės vietos aprašymas',
      image:
        'assets/images/landing/homepage-uplift/categories/us/orangeCounty.png',
      count: 1000,
      countLabel: 'places',
      showInCategories: true,
    },
    {
      link: 'wedding-venues/san-diego',
      label: 'San Diego',
      description: 'Šventės vietos aprašymas',
      image: 'assets/images/landing/homepage-uplift/categories/us/sanDiego.png',
      count: 1000,
      countLabel: 'places',
      showInCategories: true,
    },
  ],
};
