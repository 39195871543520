import { of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef, inject } from '@angular/core';
import { JarvisAuthService } from '../../services/auth.service';
import { takeUntil, switchMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BASE_URL, LinkUrls, LINK_URLS } from '@jarvis/services';
import { JarvisHeaderService, LayoutService } from '@jarvis/ui';
import { MessagesType, MESSAGES_TYPE } from '@jarvis/messaging';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';

@Component({
  selector: 'signup-standalone-page',
  templateUrl: './signup-standalone-page.component.html',
  styleUrls: ['./signup-standalone-page.component.scss']
})
export class SignupStandalonePageComponent implements OnInit, OnDestroy {
  dataOptions;

  data;
  serviceName: string;

  destroy$ = new Subject<void>();

  hash: any;

  private domainCountry = inject(DOMAIN_COUNTRY);

  constructor(
    private headerService: JarvisHeaderService,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private authService: JarvisAuthService,
    private httpService: HttpClient,
    private router: Router,
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(LINK_URLS) private linkUrls: LinkUrls,
    @Inject(MESSAGES_TYPE) private messagesType: MessagesType,
  ) {
    this.layoutService.disableFooter();
    this.serviceName = this.route.snapshot.data.inviteData?.name || this.route.snapshot.queryParams.name || 'Service provider';
    this.hash = this.route.snapshot.params.hash;
  }

  ngOnInit(): void {
    this.headerService.hideHeader();

    this.data = 'login.signupSA.' + this.messagesType;

    this.authService.createRegisterOrLoginSuccessStream().pipe(
      takeUntil(this.destroy$),
      switchMap((userData) => {
        if (this.hash) {
          return this.httpService.get(`${this.baseUrl}/invite/${this.hash}`).pipe(
            map((newBookingInfo) => ({
              userData,
              newBookingInfo
            }))
          );
        }

        return of({
          userData,
          newBookingInfo: null
        });
      })
    ).subscribe((data) => {

      if (data.newBookingInfo) {
        if (this.domainCountry === 'lt') {
          return this.router.navigate(['/visos-rezervacijos', 'detales', data.newBookingInfo._id]);
        } else {
          return this.router.navigate(['/bookings-list', 'detail', data.newBookingInfo._id]);
        }
      }

      const user = data.userData;

      const plannerUrl = this.domainCountry === 'lt' ? '/planuoklis' : '/planner';
    // Disabled by request: Bug | Users registracija  2023-15-06
    //  if (user.userType === 'user') return this.router.navigateByUrl(plannerUrl);
      if (user.userType === 'vendor') return location.replace(this.linkUrls.saas);

      return this.router.navigate([]);

    });

  }

  ngOnDestroy(): void {
    this.headerService.setDefaultHeader();
    this.layoutService.enableFooter();
    this.destroy$.next();
  }

}
