import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  ButtonModule,
  FileUploaderActionType,
  JarvisFileUploaderModule,
} from '@jarvis/ui';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { LinkUrls, LINK_URLS, BASE_URL } from '@jarvis/services';
import { BookingsDataService } from '../../bookings.service';
import {
    BehaviorSubject,
  forkJoin,
  from,
  map,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { MessageContent, MessagingService } from '@jarvis/messaging';
import { BookingsObjType } from '@jarvis/types';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'jarvis-proposal-send-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ButtonModule,
    TextFieldModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    MatIconModule,
    JarvisFileUploaderModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './proposal-send-modal.component.html',
  styleUrls: ['./proposal-send-modal.component.scss'],
})
export class ProposalSendModalComponent implements OnInit, OnDestroy {
  @Input() detailData: any;
  @Output() proposalSent = new EventEmitter<void>();
  @ViewChild('uploader') uploader: any;

  proposalForm = new FormControl('');
  private linkUrls: LinkUrls = inject(LINK_URLS);
  private bookingService: BookingsDataService = inject(BookingsDataService);
  private messagingService: MessagingService = inject(MessagingService);
  private dateFormatPipe: DatePipe = inject(DatePipe);
  private baseUrl: string = inject(BASE_URL);
  private httpService: HttpClient = inject(HttpClient);
  private sendProposal$ = new Subject<void>();
  protected destroy$ = new Subject<void>();
  processed = new BehaviorSubject(false);


  files: any[] = [];
  fileLinks: any[] = [];

  getProposalLink(id: string, mode = '') {
    return this.bookingService.getProposalLink(id, mode);
  }

  constructor() {}

  ngOnInit(): void {
    this.proposalForm.setValue(
      `Hi ${
        this.detailData.customer?.name || ''
      },\n\nThanks for the visit today. I'm sending you the reviewed proposal. If you have any questions, just reply to this email.\n`
    );

    this.sendProposal$
      .pipe(
        takeUntil(this.destroy$),
        tap(()=>this.processed.next(true)),
        // upload files (if needed)
        switchMap(() =>
          this.files.length == 0
            ? of([])
            : forkJoin(
                this.files.map((file) => {
                  return from(file.arrayBuffer()).pipe(
                    switchMap((fileArrayBuffer) => {
                      const blob = new Blob([fileArrayBuffer as BlobPart]);
                      const formData = new FormData();
                      formData.append('file', blob, file.name);
                      formData.append('type', 'ServiceDocument');
                      formData.append('booking', this.detailData._id);
                      return this.httpService.post(
                        `${this.baseUrl}/file/saveConcierge`,
                        formData
                      );
                    })
                  );
                })
              )
        ),
        // create proposal
        switchMap((fileLinks: any[]) =>
          this.bookingService.createProposal(this.detailData).pipe(
            map((proposalId) => {
              //  send messages and modify booking data
              let message = this.proposalForm.value;
              message =
                (message?`${message}\n`:"") +
                `To view your proposal, please click here:\n${this.getProposalLink(
                  proposalId
                )}`;

              // Add files to attachments and into the message
              if (fileLinks.length) {
                fileLinks.forEach((file) =>
                  this.addToAttachments(file.fileUrl, file.fileName)
                );
                message =
                  (message?`${message}\n`:"") +
                  'Attachments:\n' +
                  fileLinks.map((file) => file.fileUrl).join('\n');
              }
              return { message, proposalId };
            }),

            // add proposal as file to attachments
            switchMap((data) =>
              this.addToAttachments(
                this.getProposalLink(data.proposalId),
                `Proposal ${this.dateFormatPipe.transform(new Date(), 'short')}`
              ).pipe(map(() => data))
            ),

            tap((data) => {
                this.detailData.state = 'proposalSubmitted';
                this.detailData.options.proposalSent = true;
                this.detailData.options.proposal = this.getProposalLink(data.proposalId);
                this.detailData.options.noEventDate = false;
            }),            

            switchMap((data) => this.sendProposalMessages(data.message)),


            // change booking state
            switchMap(() =>
              this.bookingService.changeStateUnregistered({
                _id: this.detailData._id,
                state: this.detailData.state,
                options: {
                  ...this.detailData.options,
                  noEventDate: false,
                  proposal: this.detailData.options.proposal,
                  proposalSent: true
                },
              } as BookingsObjType)
            ),

            tap(() => this.proposalSent.emit())
          )
        )
      )
      .subscribe({
        next: v=>{this.processed.next(true)},
        error: (error) =>  { console.log(error); this.processed.next(true)},
        complete: ()=>{}
      }
      );
  }

  private addToAttachments(url: string, filename: string) {
    this.detailData.additions = [
      ...(this.detailData?.additions || []),
      { url, filename },
    ];
    return this.bookingService.changeStateUnregistered({
      _id: this.detailData._id,
      additions: this.detailData.additions,
    } as BookingsObjType);
  }

  fileUploaderAction(evnt: FileUploaderActionType[]) {
    evnt.forEach((oneevnt) => {
      if (oneevnt.type === 'add') {
        this.files.push({ name: oneevnt.fileName, url: oneevnt.fileUrl });
        this.detailData.additions = [
          ...(this.detailData?.additions || []),
          { url: oneevnt.fileUrl, filename: oneevnt.fileName },
        ];
      }
    });
  }

  private sendProposalMessages(message: string) {
    const userId = this.detailData.customerId?._id;
    const serviceBaseId = this.detailData.serviceBaseId as string;
    const serviceEventId = this.detailData.serviceEventId as string;

    const chatRoom$ = userId
      ? this.messagingService.startChatroomWithUser(
          userId,
          serviceBaseId,
          serviceEventId,
          this.detailData._id
        )
      : this.messagingService.startChatroomWOUser(this.detailData._id);

    return chatRoom$.pipe(
      switchMap((room: any, idx) => {
        return this.messagingService.sendMessage({
          chatRoomId: room[0]?._id,
          booking: this.detailData._id,
          message,
          type: 'proposal',
        } as MessageContent);
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendProposal() {
    this.sendProposal$.next();
  }

  onFileChange(event: any) {
    this.files = [...this.files, ...Array.from(event.target.files)];
  }

  removeFile(index: number) {
    this.detailData.additions.splice(
      this.detailData.additions.findIndex(
        (v: any) => v.url == this.files[index].url
      ),
      1
    );
    this.files.splice(index, 1);
  }

  previewProposal() {
    // preview proposal
  }
}
