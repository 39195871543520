import { takeUntil, debounceTime } from 'rxjs/operators';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { Portal } from '@angular/cdk/portal';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { LayoutService } from '@jarvis/ui/layout';
import { HeaderService } from './services/header.service';

@Component({
  selector: 'jarvis-main-header',
  templateUrl: 'main-header.component.html',
  styleUrls: [
    './main-header.component.scss'
  ]
})

export class MainHeaderComponent implements OnInit, OnDestroy {

  @ViewChild('header', { read: ElementRef, static: true }) headerRef: ElementRef;
  @ViewChild('defaultMenu', { static: true }) defaultMenu: TemplateRef<unknown>;
  currentPortal$: Observable<Portal<any>>;

  menuState$: Observable<any>;
  destroy$ = new Subject<void>();

  constructor(
    public headerService: HeaderService,
    private layoutService: LayoutService
  ) {
    const scrollContainer = document.getElementById('bContent');

    fromEvent(scrollContainer, 'scroll')
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((scrollEvent: any) => {
        const scrolledFromTop = scrollEvent.target.scrollTop;
        if (scrolledFromTop > 20) {
          this.headerRef.nativeElement.classList.add('header-border');
        } else {
          this.headerRef.nativeElement.classList.remove('header-border');
        }
      });
  }

  ngOnInit(): void {
    this.currentPortal$ = this.headerService.headerPortal$;
  }

  openMobileMenu(): void {
    this.layoutService.createMobileMenuOverlay(MobileMenuComponent, {

    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
