import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { CardAnimations } from './card-types';

export const cardAnimations: CardAnimations = {
    /** Animation translates a tab along the X axis. */
    translateCard: trigger('translateCard', [
        // Note: transitions to `none` instead of 0, because some browsers might blur the content.
        state('center, void', style({ transform: 'none'})),

        // If the tab is either on the left or right, we additionally add a `min-height` of 1px
        // in order to ensure that the element has a height before its state changes. This is
        // necessary because Chrome does seem to skip the transition in RTL mode if the element does
        // not have a static height and is not rendered. See related issue: #9465
        // We also have overflow hidden on manual states and left, state, so that the scrollbar does not show when
        // overflowing content is hidden or inbetween the slide animation
        state('left', style({ transform: 'translate3d(-100%, 0, 0)', minHeight: '1px', overflow: 'hidden' })),
        state('right', style({ transform: 'translate3d(100%, 0, 0)', minHeight: '1px', overflow: 'hidden' })),
        state('manual', style(
            {
                transform: 'translate3d({{manualPosition}}px, 0, 0)', minHeight: '1px', overflow: 'hidden'
            }),
            {
                params: {
                    manualPosition: '0'
                }
            }
        ),
        state('manual-left', style(
            {
                transform: 'translate3d(calc(-100% + {{manualPosition}}px), 0, 0)', minHeight: '1px'/* , overflow: 'hidden' */
            }),
            {
                params: {
                    manualPosition: '0'
                }
            }
        ),
        state('manual-right', style(
            {
                transform: 'translate3d(calc(100% + {{manualPosition}}px), 0, 0)', minHeight: '1px'/* , overflow: 'hidden' */
            }),
            {
                params: {
                    manualPosition: '0'
                }
            }
        ),
        transition('void => left', [
            style({ transform: 'translate3d(-100%, 0, 0)' })
        ]),
        transition('void => right', [
            style({ transform: 'translate3d(100%, 0, 0)' })
        ]),
        transition(`* => left, * => right, * => center, left => center, right => center, manual => center`,
            animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)'))
    ]),
    shadowOpacity: trigger('shadowOpacity', [
        state('none', style({ opacity: '0'})),
        state('manual', style({ opacity: '{{opacity}}'}), { params: { opacity: 0 }}),
        transition('manual => none',
            animate('300ms cubic-bezier(0.35, 0, 0.25, 1)'))
    ])
};
