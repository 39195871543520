<div class="bztt-flex bztt-justify-between bztt-mb-2">
  <p
    class="bztt-font-montserrat bztt-m-0 bztt-text-sm bztt-text-[#717171] bztt-whitespace-nowrap bztt-overflow-ellipsis bztt-overflow-hidden"
  >
    {{ data | CardListingType }}
  </p>
  <div class="bztt-min-w-fit" *ngIf="data.ratingCount != '-'">
    <div class="bztt-flex bztt-items-center">
      <span class="bztt-text-[#ef5518] bztt-mr-2">★</span>
      <span class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171]"
        >{{ data.rating != '-' ? data.rating : '' }} ({{
          data.ratingCount
        }})</span
      >
    </div>
  </div>
</div>

<div *ngIf="data.address" class="bztt-flex bztt-mb-1 bztt-text-[#717171]">
  <mat-icon class="bztt-text-xs bztt-h-3 bztt-w-3">location_on</mat-icon>
  <span
    class="bztt-font-montserrat bztt-text-xs bztt-h-4 bztt-text-ellipsis bztt-w-64 bztt-overflow-hidden bztt-whitespace-nowrap"
  >
    <!-- bztt-whitespace-nowrap bztt-overflow-hidden bztt-text-ellipsis bztt-h-4"> -->
    {{ data.address }}
  </span>
</div>
<div *ngIf="!data.address" class="bztt-h-4"></div>

<div class="bztt-min-h-[4rem]">
  <h2
    class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222] card-title-overflow-elipses"
  >
    {{ data.title }}
  </h2>
</div>

<div class="bztt-flex bztt-gap-1 bztt-items-center bztt-mt-auto">
  <div class="bztt-flex-col">
    <div class="bztt-text-[#717171] bztt-pb-2">
      {{ (data.priceTitle ? 'listing.cart.' + data.priceTitle : 'listing.cart.priceTitle') | translate }}
      <span
        class="bztt-flex-initial bztt-justify-center bztt-border bztt-border-[#515151] bztt-pt-1 bztt-relative bztt-top-0.5 bztt-pl-0.5 bztt-pr-0.5 bztt-rounded-full"
        *ngIf="
          data?.venueInfo?.hasCatering ||
          data?.venueInfo?.hasDrinks ||
          data?.venueInfo?.hasAccomodation ||
          data?.venueInfo?.hasBeverages
        "
      >
        <mat-icon
          class="bztt-h-3.5 bztt-w-3.5 bztt-ml-px bztt-text-[12px]"
          *ngIf="data.venueInfo.hasCatering"
          >restaurant</mat-icon
        >
        <mat-icon
          class="bztt-h-3.5 bztt-w-3.5 bztt-text-[12px]"
          *ngIf="data.venueInfo.hasDrinks"
          >local_bar</mat-icon
        >
        <mat-icon
          class="bztt-h-3.5 bztt-w-3.5 bztt-text-[12px]"
          *ngIf="data.venueInfo.hasAccomodation"
          >king_bed</mat-icon
        >
        <mat-icon
          class="bztt-h-3.5 bztt-w-3.5 bztt-text-[12px]"
          *ngIf="data.venueInfo.hasBeverages"
          >local_bar</mat-icon
        >
      </span>
    </div>
    <ng-container [ngSwitch]="!!data.discountedPrice">
      <ng-container *ngSwitchCase="true">
        <span
          class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#ef5518]"
          >{{ data.discountedPrice }}</span
        >
        <span
          class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171] bztt-line-through"
          >{{ data.indicativePrice }}</span
        >
      </ng-container>

      <ng-container *ngSwitchDefault>
        <span
          class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222]"
        >
          {{ data.indicativePrice }}
        </span>
      </ng-container>
    </ng-container>

    <span *ngIf="data.category !== 'cakes_desserts'" class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171]">
      {{ data.priceUnit | PriceUnitLabel | async }}
    </span>
  </div>

  <div
    class="bztt-ml-auto bztt-flex bztt-flex-col bztt-items-start"
    *ngIf="data.venueInfo"
  >
    <div class="bztt-text-[#717171] bztt-pb-2">
      {{ (data.guestCountTitle ? 'listing.cart.' + data.guestCountTitle : 'listing.cart.guestCount') | translate }}
    </div>
    <span
      [ngSwitch]="data.venueInfo.filteredGuestCount > 0"
      class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222]"
    >
      <ng-container *ngSwitchCase="true">{{
        data.venueInfo.filteredGuestCount
      }}</ng-container>
      <ng-container *ngSwitchDefault>{{
        'listing.cart.guestCountUpTo' | translate : data.venueInfo
      }}</ng-container>
    </span>
  </div>
  <div
    class="bztt-ml-auto bztt-flex bztt-flex-col bztt-items-start"
    *ngIf="data?.priceAddition?.hoursIncluded"
  >
    <div class="bztt-text-[#717171] bztt-pb-2">
      {{ 'listing.cart.hoursIncluded' | translate }}
    </div>
    <span class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222]">
      {{ 'listing.cart.hoursIncludedVal' | translate : data.priceAddition }}
    </span>
  </div>
</div>

<ng-content></ng-content>