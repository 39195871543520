<div class="venues-blog-service-statuses">
  <ul class="bztt-mt-0" id="venues-tags-list">
    <ng-container
      *ngIf="listingData.serviceEvent.extra?.breezitExclusive?.enabled"
    >
      <li
        [matTooltip]="
          'listing.details.exclusiveOfferNotification'
            | translate
              : {
                  price:
                    (listingData.serviceEvent.extra.breezitExclusive.value
                    | measurementCurrency : true
                    | async)
                }
        "
      >
        <div
          class="bztt-w-3 bztt-h-3 bztt-flex bztt-items-center bztt-justify-center bztt-mr-1"
        >
          <mat-icon svgIcon="breezit"></mat-icon>
        </div>
        <span
          >{{
            listingData.serviceEvent.extra?.breezitExclusive.value
              | currency
                : (activeCurrencyCode$ | async)
                : 'symbol'
                : '1.0-0'
                : (activeLocale$ | async)
          }}
          {{ 'listing.cashback' | translate }}</span
        >
      </li>
    </ng-container>
    <li *ngIf="listingData?.serviceBase?.advances?.canRebate">
      <div class="bztt-flex bztt-items-center bztt-justify-center bztt-mr-1">
        <mat-icon>local_offer</mat-icon>
      </div>
      <span>{{ 'listing.negotiablePricing' | translate }}</span>
    </li>

    <li
      *ngIf="
        listingData?.serviceEvent?.wedding?.charges?.globalMinimalBudget
          ?.priceValue
      "
    >
      <div
        class="bztt-w-6 bztt-h-6 bztt-flex bztt-items-center bztt-justify-center bztt-mr-1"
      >
        <mat-icon>payments</mat-icon>
      </div>
      <span>{{
        'listing.minimumSpend'
          | translate
            : {
                minSpend:
                  listingData?.serviceEvent?.wedding?.charges
                    ?.globalMinimalBudget?.priceValue
                  | currency
                    : (activeCurrencyCode$ | async)
                    : 'symbol'
                    : '1.0-0'
                    : (activeLocale$ | async)
              }
      }}</span>
    </li>
    <ng-container *ngIf="listingData.serviceBase.type == 'venues'">
      <li
        *ngIf="
          !listingData.serviceBase?.kitchen?.enabled &&
          listingData?.serviceEvent?.wedding?.venueStandalone?.enabled
        "
      >
        <div class="bztt-flex bztt-items-center bztt-justify-center bztt-mr-1">
          <mat-icon>restaurant</mat-icon>
        </div>
        <span>{{ 'listing.cateringOutside' | translate }}</span>
      </li>
      <li
        *ngIf="
          listingData.serviceBase?.kitchen?.enabled &&
          listingData?.serviceEvent?.wedding?.venueStandalone?.enabled &&
          listingData.serviceBase?.kitchen?.ownCateringPolicy != 'no'
        "
      >
        <div class="bztt-flex bztt-items-center bztt-justify-center bztt-mr-1">
          <mat-icon>restaurant</mat-icon>
        </div>
        <span>{{ 'listing.cateringAllowed' | translate }}</span>
      </li>
      <li *ngIf="listingData.serviceBase.address.country === 'US'">
        <div class="bztt-flex bztt-items-center bztt-justify-center bztt-mr-1">
          <mat-icon>hourglass_top</mat-icon>
        </div>
        <span>{{
          'listing.responseTime'
            | translate : { responseTime: fakeResponseTime }
        }}</span>
      </li>
      <li *ngIf="listingData.serviceBase.restrictions.petsPolicy">
        <div class="bztt-flex bztt-items-center bztt-justify-center bztt-mr-1">
          <mat-icon>pets</mat-icon>
        </div>
        <span
          *ngIf="listingData.serviceBase.restrictions.petsPolicy === 'allowed'"
          >{{ 'listing.petsAllowed' | translate }}</span
        >
        <span
          *ngIf="
            listingData.serviceBase.restrictions.petsPolicy === 'notAllowed'
          "
          >{{ 'listing.petsNotAllowed' | translate }}</span
        >
        <span
          *ngIf="
            listingData.serviceBase.restrictions.petsPolicy === 'caseByCase'
          "
          >{{ 'listing.petsCaseByCase' | translate }}</span
        >
      </li>
    </ng-container>
  </ul>
</div>
