import { PixelService } from 'ngx-pixel';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { inject, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MarketplaceLike } from '../types/likes.types';
import { filter, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { BASE_URL, JarvisTrackingService } from '@jarvis/services';
import { JarvisAuthService } from '@jarvis/auth';
import { NgxHotjarService } from 'ngx-hotjar';
import { JarvisNotificationService } from '@jarvis/notifications';

const LIKES_STORAGE_KEY = 'liked_services';
declare const pintrk;

export interface LocalLike {
  eventId: string;
  note: string;
  eventType?: string;
}

@Injectable({ providedIn: 'root' })
export class JarvisServiceLikesService {
  private trackService = inject(JarvisTrackingService);

  likesEndpoint: string;
  likesEndpointNew: string;
  byIdsLikesEndpoint: string;
  multipleLikesEndpoint: string;

  private localSavedLikes = [];

  lastLikeSubject = new Subject<any>();
  lastLikeClosed = new Subject<void>();

  constructor(
    private httpService: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
    private gtmService: GoogleTagManagerService,
    private authService: JarvisAuthService,
    private pixelService: PixelService,
    private hotjarService: NgxHotjarService,
    private notificationService: JarvisNotificationService
  ) {
    this.initializeEndpoints();
    const localSavedLikes = localStorage.getItem(LIKES_STORAGE_KEY);
    if (localSavedLikes) {
      this.localSavedLikes = JSON.parse(localSavedLikes);
    }

    this.authService.registrationEvent$
      .pipe(
        filter((event) => event === 'success'),
        take(1)
      )
      .subscribe(() => {
        this.transferLikes();
      });
  }

  isLocalLiked(eventId): boolean {
    return this.localSavedLikes.some((like) => like.eventId === eventId);
  }

  saveLike(
    serviceEventId: string,
    category?: string,
    slug?,
    eventType?,
    priority?,
    trackingData = {}
  ): Observable<any> {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel', 'gtm', 'hotjar', 'pixel'],
      eventName: 'listing_like',
      data: {
        category: category,
        slug,
        priority: priority ? 'yes' : 'no',
        ...trackingData,
      },
    });

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'VAA-plus',
    });

    this.trackService.handleEvent({
      trackers: ['pixel'],
      eventName: 'AddToWishlist',
      data: {
        content_ids: [serviceEventId],
      },
    });

    this.trackService.handleEvent({
      trackers: ['pinterest'],
      eventName: 'custom',
      data: { value: 'AddToWishlist' as any },
    });

    return this.authService.isLoggedIn$.pipe(
      take(1),
      switchMap((loggedIn) => {
        if (loggedIn) {
          return this.httpService.post<MarketplaceLike>(this.likesEndpoint, {
            serviceEventId,
            eventType,
          });
        }

        // this.modalService.openDialog(LikeSaveEmailModalComponent, {
        //   data: {
        //     serviceEventId
        //   }
        // });
        return this.saveLocalLike({
          eventId: serviceEventId,
          note: '',
          eventType,
        });
      }),
      tap(() => {
        this.notificationService.manualUpdate$.next({
          message: 'add',
          data: {
            likes: 1,
          },
        });
      })
    );
  }

  removeLike(likeId: string, eventId?: string) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel', 'gtm'],
      eventName: 'listing_dislike',
    });

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'VAA-plus',
    });

    return this.authService.isLoggedIn$.pipe(
      switchMap((loggedIn) => {
        if (loggedIn) {
          return this.httpService.delete<MarketplaceLike>(
            `${this.likesEndpoint}/${likeId}`
          );
        }

        return this.removeLocalLike(likeId);
      }),
      tap(() => {
        this.notificationService.manualUpdate$.next({
          message: 'remove',
          data: {
            likes: 1,
          },
        });
      })
    );
  }

  getAllUserLikes(skip, limit, category, userId?, dateFrom?, dateTo?) {
    return this.httpService.get(
      `${this.likesEndpointNew}?skip=${skip}&limit=${limit}&category=${category}&userId=${userId}&dateFrom=${dateFrom}&dateTo=${dateTo}`
    );
  }

  updateLike(likeId: string, data: any) {
    return this.httpService.patch(`${this.likesEndpoint}/${likeId}`, data);
  }

  updateLocalLike(eventId: string, data: any) {
    const foundlocalLike = this.localSavedLikes.find(
      (localLike) => localLike.eventId === eventId
    );
    if (foundlocalLike) {
      Object.assign(foundlocalLike, data);
      localStorage.setItem(
        LIKES_STORAGE_KEY,
        JSON.stringify(this.localSavedLikes)
      );
    }
  }

  private saveLocalLike(localLike: LocalLike) {
    return of(localLike).pipe(
      tap((innerLocalLike) => {
        this.localSavedLikes.push(innerLocalLike);
        localStorage.setItem(
          LIKES_STORAGE_KEY,
          JSON.stringify(this.localSavedLikes)
        );
      }),
      mapTo({ ...localLike, _id: localLike.eventId })
    );
  }

  private removeLocalLike(eventId: string) {
    return of(eventId).pipe(
      tap((innerIventId) => {
        const indexToDelete = this.localSavedLikes.findIndex(
          (item: LocalLike) => item.eventId === innerIventId
        );
        this.localSavedLikes.splice(indexToDelete, 1);
        localStorage.setItem(
          LIKES_STORAGE_KEY,
          JSON.stringify(this.localSavedLikes)
        );
      }),
      mapTo({ _id: eventId })
    );
  }

  getLocalPlannerItems() {
    const localSavedLikes = localStorage.getItem(LIKES_STORAGE_KEY);
    if (localSavedLikes) {
      this.localSavedLikes = JSON.parse(localSavedLikes);
    }

    const ids = this.localSavedLikes
      .filter((item) => !item.customServiceEvent)
      .map((item: LocalLike) => item.eventId);
    return this.httpService.post(this.byIdsLikesEndpoint, { ids }).pipe(
      map((likeServices: MarketplaceLike[]) => {
        likeServices.forEach((likeService: MarketplaceLike) => {
          const localLike = this.localSavedLikes.find(
            (item: LocalLike) => item.eventId === likeService._id
          );
          Object.assign(likeService, localLike);
        });
        return [
          ...likeServices,
          ...this.localSavedLikes.filter((item) => item.customServiceEvent),
        ];
      })
    );
  }

  private transferLikes(): void {
    const serviceEventIds = Array.from(this.localSavedLikes);

    if (serviceEventIds.length === 0) {
      return;
    }

    const body = {
      serviceEventIds,
    };

    this.httpService.post(this.multipleLikesEndpoint, body).subscribe(() => {
      localStorage.removeItem(LIKES_STORAGE_KEY);
    });
  }

  private initializeEndpoints(): void {
    this.likesEndpoint = `${this.baseUrl}/marketplace/likes`;
    this.likesEndpointNew = `${this.baseUrl}/marketplace/likes/new`;
    this.byIdsLikesEndpoint = `${this.baseUrl}/marketplace/likes/byids`;
    this.multipleLikesEndpoint = `${this.baseUrl}/marketplace/likes/multipleLikes`;
  }
}
