import { CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceCountryKeyPipe, ServiceMarketplaceCardImageCountPipe } from './pipes';
import { ServiceCurrencyPipe } from './pipes/currency.pipe';
import { ServiceMarketplaceCardDataPipe } from './pipes/card-data.pipe';
import { ServiceMarketplaceCardPicturesPipe } from './pipes/card-pictures.pipe';
import { ServiceDatePipe } from './pipes/date.pipe';


const PIPES = [ServiceCurrencyPipe, ServiceDatePipe, ServiceCountryKeyPipe, ServiceMarketplaceCardPicturesPipe, ServiceMarketplaceCardDataPipe, CurrencyPipe, DatePipe, ServiceMarketplaceCardImageCountPipe];

@NgModule({
  imports: [...PIPES],
  exports: [...PIPES],
  providers: [
    ...PIPES
  ]
})
export class ServicePipesModule {}
