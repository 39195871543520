import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from '@jarvis/ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagesService } from '../services/messages.service';

@Component({
    selector: 'messaging-chat-booking-container',
    templateUrl: './chat-booking-container.component.html',
    styleUrls: [
        './chat-booking-container.component.scss'
    ]
})

export class MessagesChatBookingContainerComponent implements OnInit, OnDestroy {

    bookingInfoOpen = false;

    isMobile$ = this.layoutService.isLaptopObserver;
    currentMobileView$ = this.messagesService.currentMobileView$;

    private destroy$ = new Subject<void>();

    constructor(
        private layoutService: LayoutService,
        private currentRoute: ActivatedRoute,
        private messagesService: MessagesService
    ) {}

    ngOnInit() {
        this.currentRoute.params.pipe(
            takeUntil(this.destroy$)
        ).subscribe(params => {
            const { messageId } = params;
            this.messagesService.currentChatroomId$.next(messageId);
        });

        if (this.layoutService.isLaptop) {
            this.messagesService.openChatView();
        }
    }

    openBookingInfo(): void {
        this.bookingInfoOpen = true;
    }

    closeBookingView(): void {
        this.bookingInfoOpen = false;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}