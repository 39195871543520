import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TranslateModule } from '@ngx-translate/core';
import { JarvisUiMarketplaceCardModule } from './../../../../ui/marketplace-card/marketplace-card.module';
import { ReviewStarsModule } from '@jarvis/reviews';
import { LandingsModule } from '../landings.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions,
  MatLegacyFormFieldModule as MatFormFieldModule,
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/legacy-form-field';
import { LANDING_TYPE } from '../services';
import { RouterModule, Routes } from '@angular/router';
import { ButtonModule, JarvisCommonDialogModule, SmallCalendarModule } from '@jarvis/ui';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EventHostHomepageComponent } from './event-host-homepage.component';
import { JarvisUiInputFormatsModule } from '@jarvis/ui/src/lib/directives/input-formats/input-formats.module';
import { MatDividerModule } from '@angular/material/divider';
import { HomepageCalendarComponent } from './components/homepage-calendar/homepage-calendar.component';
import { ProfileSelectorDialogComponent } from './components/profile-selector-dialog/profile-selector-dialog.component';
import { ServicePipesModule } from '@jarvis/services';
import { MatRippleModule } from '@angular/material/core';

const FORM_FIELD_DEFAULTS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

const ROUTES: Routes = [
  {
    path: '',
    component: EventHostHomepageComponent,
  },
];

@NgModule({
  declarations: [
    EventHostHomepageComponent,
    HomepageCalendarComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    LayoutModule,
    ButtonModule,
    HttpClientModule,
    MatSidenavModule,
    LandingsModule,
    SmallCalendarModule,
    ReviewStarsModule,
    JarvisUiInputFormatsModule,
    MatDividerModule,
    JarvisUiMarketplaceCardModule,
    TranslateModule,
    JarvisCommonDialogModule,
    MatMenuModule,
    ServicePipesModule,
    MatRippleModule,
    ProfileSelectorDialogComponent
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: FORM_FIELD_DEFAULTS,
    },
    {
      provide: LANDING_TYPE,
      useValue: 'user',
    },
  ],
  exports: [EventHostHomepageComponent],
})
export class EventHostHomepageModule { }
