import { HeaderService, ViewConstraints } from '../services/header.service';
import {
  HEADER_MENU_ACTIONS,
  HEADER_MENU_ITEMS_US,
  HEADER_MENU_ITEMS_LT,
} from './../header.constants';
// TODO: Move to analytics/tracking service

import { BreakpointObserver } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
  inject,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth/services';
import { MessagingService } from '@jarvis/messaging/services';
import { JarvisNotificationService } from '@jarvis/notifications';
import { merge, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { MobileMenuComponent } from '../mobile-menu/mobile-menu.component';
import { ProfileDropdownComponent } from '../profile-dropdown/profile-dropdown.component';
import { LinkUrls, LINK_URLS } from '@jarvis/services/tokens';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
// TODO: Move to UI or similair library (bad component API practice)
import { ProfileSelectorDialogComponent } from '@jarvis/landings/event-host-homepage/components/profile-selector-dialog';
import { JarvisUiModalService } from '@jarvis/ui/modal';
import { LayoutService } from '@jarvis/ui/layout';
import { MenuServiceListComponent } from '../service-list/service-list.component';
import { JarvisTrackingService } from '@jarvis/services';

const MOBILE_MENU_WIDTH = 574;

@Component({
  selector: 'main-header-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MainHeaderMenuComponent implements OnDestroy, AfterViewInit {
  window = window;
  public viewConstraints = ViewConstraints;

  @ViewChild('profile') profileRef: ElementRef;
  onlyIcon = false;

  @Input() headerRef: HTMLElement;

  domainCountry = inject(DOMAIN_COUNTRY);
  private trackService = inject(JarvisTrackingService);



  profileDropdown = ProfileDropdownComponent;

  isLoggedIn$ = this.authService.isLoggedIn$;

  userData$ = this.authService.userData$;

  isMobile$ = this.breakpointObserver
    .observe([`(max-width: ${MOBILE_MENU_WIDTH}px)`])
    .pipe(map((state) => state.matches));

  private destroy$ = new Subject<void>();
  private lastWidth = 0;

  showMobileRegistrationDorpdown = false;
  showMobileProfileDorpdown = false;

  constructor(
    public headerService: HeaderService,
    private authService: JarvisAuthService,
    private layoutService: LayoutService,
    private router: Router,
    private modalService: JarvisUiModalService,
    private elementRef: ElementRef,
    private breakpointObserver: BreakpointObserver,
    private overlay: Overlay,
    public notificationService: JarvisNotificationService,
    public messagingService: MessagingService,
    @Inject(LINK_URLS) public linkUrls: LinkUrls
  ) {}

  ngAfterViewInit(): void {
    this.lastWidth = this.elementRef.nativeElement.scrollWidth;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openLogin(): void {
    this.router.navigateByUrl('/auth/login?headerLogin=true');
  }

  openRegister(): void {
    this.router.navigateByUrl('/auth/register');
  }

  openProfileDropdown(): void {
    this.isMobile$.pipe(take(1)).subscribe((mob) => {
      if (mob) this.showMobileProfileDorpdown = !this.showMobileProfileDorpdown;
      else {
        this.layoutService.createOverlay(
          ProfileDropdownComponent,
          this.profileRef
        );
      }
    });
  }

  openMobileMenu(): void {
    this.layoutService.createMobileMenuOverlay(MobileMenuComponent, {});
  }

  handleHeaderAuth(type: 'Login' | 'Signup') {
    if (type === 'Login') {
      this.handleTemplateTrck('log_in_click');
      this.openLoginDialog();
    }
    if (type === 'Signup') {
      this.handleTemplateTrck('signup_click');
      this.openUserTypeSelector();
    }
  }

  openUserTypeSelector() {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      const dialog = this.modalService.openDialog(
        ProfileSelectorDialogComponent
      );

      dialog.afterClosed().subscribe((profileType) => {
        if (!profileType) return;

        if (profileType === 'vendor')
          return this.handleVendorSignup('homepage-header');
        this.openLoginDialog(profileType, 'register');
      });
    });
  }

  handleVendorSignup(location: string) {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      const signUpLink =
        this.domainCountry === 'lt'
          ? '/registracija?source='
          : '/sign-up?source=';

      window.location.replace(window.location.origin + signUpLink + location);

      // this.router.navigate(['sign-up'], {
      //   queryParams: { source: location }
      // });
    });
  }

  openLoginDialog(
    userType: 'user' | 'vendor' = 'user',
    dialogType: 'login' | 'register' = 'login'
  ): void {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      this.router.navigate(['/auth/' + dialogType], {
        state: { userType },
      });

      this.authService
        .createRegisterOrLoginSuccessStream()
        .subscribe((newUserData) => {
          this.navigateToFirstHomePage(newUserData.userType);
        });
    });
  }

  private navigateToFirstHomePage(userType: 'user' | 'vendor'): void {
    if (userType === 'user') {
      this.router.navigateByUrl('/planner');
      return;
    }

    window.location.href = this.linkUrls.saas;
  }

  openServicesOverlay(disableFeatured = false): void {
    // if (this.domainCountry.toUpperCase() === 'US') {
    //   this.router.navigate(['/new']);
    //   window.scrollTo(0, 0);
    //   return;
    // }

    this.isMobile$.pipe(take(1)).subscribe((mob) => {
      if (mob) {
        const dialogRef = this.modalService.openDialog(
          MenuServiceListComponent
        );
        dialogRef.componentInstance.disableFeatured = disableFeatured;
        dialogRef.componentInstance.rearrangeItems = disableFeatured;
        dialogRef.componentInstance.disableFooter = disableFeatured;
      } else {
        const overlayRef = this.overlay.create({
          hasBackdrop: true,
          backdropClass: '.service-type-selector-backdrop',
          scrollStrategy: this.overlay.scrollStrategies.block(),
          // height: '100vh',
          positionStrategy: this.overlay
            .position()
            .flexibleConnectedTo(this.headerRef)
            .withPositions([
              {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
              },
            ]),
          // .withDefaultOffsetY(5)
        });

        const componentPortal = new ComponentPortal(MenuServiceListComponent);

        const componentRef = overlayRef.attach(componentPortal);

        componentRef.instance.disableFeatured = disableFeatured;
        componentRef.instance.disableFooter = disableFeatured;
        componentRef.instance.rearrangeItems = disableFeatured;

        const close$ = componentRef.instance.close$;

        merge(
          overlayRef.backdropClick(),
          overlayRef.detachments(),
          close$,
          this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
          )
        )
          .pipe(take(1))
          .subscribe(() => {
            overlayRef.detach();
          });
      }
    });
  }

  handleMenuItemClick(menuItem) {
    switch (menuItem.action) {
      case HEADER_MENU_ACTIONS.OPEN_SERVICES:
        this.openServicesOverlay();
        break;
      case HEADER_MENU_ACTIONS.OPEN_SERVICES_WO_FEATURED:
        this.openServicesOverlay(true);
        break;
      case HEADER_MENU_ACTIONS.OPEN_EVENT_TYPE_DROPDOWN:
        this.openEventTypeDropdown();
        break;
    }
  }

  openEventTypeDropdown(): void {}

  moveToMarketplace(): void {
    window.location.href = `${this.linkUrls.marketplace}/profile/settings?from=login`;
  }

  moveToSaas(): void {
    window.location.href = this.linkUrls.saas;
  }

  handleTemplateTrck(name): void {
    this.trackService.handleEvent({
      eventName: name,
      trackers: ['amplitude', 'mixpanel'],
    });
  }
}
