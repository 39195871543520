<form
  class="d-form"
  [formGroup]="dformGroup"
  (ngSubmit)="onSubmit()"
  [ngClass]="{ disabled: dformGroup.disabled }"
>
  <div
    class="d-flex"
    [class.control-row]="
      !descriptor.controls[key].hidden &&
      descriptor.controls[key].type != 'hidden'
    "
    *ngFor="let key of unorderedKeys(descriptor.controls)"
  >
    <ng-container
      *ngIf="!descriptor.controls[key].hidden"
      [ngSwitch]="descriptor.controls[key].type"
    >
      <div *ngSwitchCase="'checkbox'" class="mt-1 mb-1">
        <mat-checkbox [formControlName]="key">{{
          i18nKey + '.' + key
            | translate : descriptor.controls[key]?.translateArgs
        }}</mat-checkbox>
      </div>
      <div *ngSwitchCase="'toggle'" class="mt-1 mb-1">
        <mat-slide-toggle [formControlName]="key">{{
          i18nKey + '.' + key
            | translate : descriptor.controls[key]?.translateArgs
        }}</mat-slide-toggle>
      </div>
      <ng-container *ngSwitchCase="'hidden'"></ng-container>
      <ng-container
        *ngSwitchDefault
        [ngSwitch]="descriptor.controls[key].control"
      >
        <ng-container
          *ngSwitchCase="1"
          [ngTemplateOutlet]="template1"
          [ngTemplateOutletContext]="{ form: dformGroup }"
        ></ng-container>
        <ng-content
          *ngSwitchCase="0"
          select="[control1]"
          [ngTemplateOutletContext]="{ form: dformGroup }"
        ></ng-content>
        <ng-content *ngSwitchCase="2" select="[control2]"></ng-content>
        <ng-content *ngSwitchCase="3" select="[control3]"></ng-content>
        <ng-content *ngSwitchCase="4" select="[control4]"></ng-content>
        <mat-form-field
          *ngSwitchDefault
          appearance="outline"
          [ngClass]="[
            'form-field-' + descriptor.controls[key].type,
            descriptor.controls[key].class ? descriptor.controls[key].class : ''
          ]"
        >
          <mat-label>{{
            i18nKey + '.' + key
              | translate : descriptor.controls[key]?.translateArgs
          }}</mat-label>
          <ng-container [ngSwitch]="descriptor.controls[key].type">
            <mat-select
              *ngSwitchCase="'select'"
              [panelClass]="key + '-panel'"
              [formControlName]="key"
            >
              <mat-select-trigger *ngIf="descriptor.controls[key].trigger">{{
                dformGroup.value[key][descriptor.controls[key].trigger]
              }}</mat-select-trigger>
              <ng-container
                *ngFor="let option of descriptor.controls[key].options"
                [ngSwitch]="
                  option.hasOwnProperty('options') && option.options.length != 0
                "
              >
                <mat-optgroup
                  *ngSwitchCase="true"
                  [label]="optionText(key, option)"
                >
                  <ng-container
                    *ngFor="let suboption of option.options"
                    [ngSwitch]="
                      suboption.hasOwnProperty('options') &&
                      suboption.options.length != 0
                    "
                  >
                    <mat-optgroup
                      *ngSwitchCase="true"
                      [label]="optionText(key, suboption)"
                    >
                      <mat-option
                        *ngFor="let sub2option of suboption.options"
                        [value]="sub2option.id"
                        >{{ optionText(key, sub2option) }}
                      </mat-option>
                    </mat-optgroup>
                    <mat-option *ngSwitchDefault [value]="suboption.id"
                      >{{ optionText(key, suboption) }}
                    </mat-option>
                  </ng-container>
                </mat-optgroup>
                <mat-option *ngSwitchDefault [value]="option.id"
                  >{{ optionText(key, option) }}
                </mat-option>
              </ng-container>
            </mat-select>

            <div *ngSwitchCase="'date'" class="d-flex align-items-center">
              <input
                matInput
                [matDatepicker]="picker"
                (click)="picker.open()"
                [formControlName]="key"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>

            <div *ngSwitchCase="'datetime'" class="d-flex align-items-center">
              <input
                inputmode="none"
                matInput
                [matDatepicker]="picker"
                (click)="picker.open()"
                [formControlName]="key"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #picker
                [dateClass]="dateClass(key)"
              ></mat-datepicker>
              <ng-container *ngIf="!descriptor.controls[key].disableTime">
                <span style="width: 32px"></span>
                <mat-select
                  #timePicker
                  class="time-picker"
                  [formControlName]="key + '_time$$'"
                  (selectionChange)="timeSelection(key, $event)"
                >
                  <mat-option
                    [value]="timeunit.tm"
                    [class]="timeClass(key, timeunit.tm)"
                    *ngFor="let timeunit of timeTick"
                    >{{ timeunit.name }}
                  </mat-option>
                </mat-select>
                <mat-icon (click)="timePicker.open()" matSuffix
                  >query_builder</mat-icon
                >
              </ng-container>
            </div>

            <textarea
              *ngSwitchCase="'textarea'"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              [cdkAutosizeMinRows]="
                descriptor.controls[key].minRows
                  ? descriptor.controls[key].minRows
                  : 4
              "
              [cdkAutosizeMaxRows]="
                descriptor.controls[key].maxRows
                  ? descriptor.controls[key].maxRows
                  : 15
              "
              type="text"
              [formControlName]="key"
              matInput
            >
            </textarea>

            <input
              *ngSwitchDefault
              [type]="descriptor.controls[key].type"
              [formControlName]="key"
              min="0"
              matInput
            />
          </ng-container>
          <mat-error *ngIf="dformGroup.get(key).errors">
            <ng-container
              *ngFor="let item of dformGroup.get(key).errors | keyvalue"
            >
              {{
                (isMessageSet('errors.' + key + '.' + item.key)
                  ? i18nKey + '.errors.' + key + '.' + item.key
                  : 'd-form.errors.' + item.key
                ) | translate
              }}
            </ng-container>
          </mat-error>
          <!-- <mat-error *ngIf="dformGroup.get(key).hasError('email')">
               {{"d-form.errors.email" | translate }}
            </mat-error> 
            <mat-error *ngIf="dformGroup.get(key).hasError('pattern')">
               {{"d-form.errors.pattern" | translate }}
            </mat-error>             
            <mat-error *ngIf="dformGroup.get(key).hasError('parse')">
               {{"d-form.errors.parse" | translate }}               
            </mat-error>
            <mat-error *ngIf="dformGroup.get(key).hasError('matDatepickerParse')">
               {{"d-form.errors.dataParse" | translate }}               
            </mat-error>             
 -->
        </mat-form-field>
      </ng-container>
    </ng-container>
  </div>
  <div
    *ngIf="!readonly && !noButton"
    class="d-flex mt-3 justify-content-around"
  >
    <button
      *ngIf="!descriptor.cancelButtonHide"
      type="button"
      app-button
      class="me-1 cancel-button"
      (click)="onEvnt('cancel')"
    >
      <span>{{ i18nKey + '.cancel' | translate }}</span>
    </button>
    <button
      *ngIf="!descriptor.resetAfterSubmit"
      type="submit"
      [disabled]="dformGroup.invalid"
      app-button
      color="accent"
      class="ms-1 save-button"
    >
      <span>{{ i18nKey + '.save' | translate }}</span>
    </button>

    <button
      *ngIf="descriptor.resetAfterSubmit"
      type="reset"
      (click)="onSubmit()"
      [disabled]="dformGroup.invalid"
      app-button
      color="accent"
      class="ms-1 save-button"
    >
      <span>{{ i18nKey + '.save' | translate }}</span>
    </button>
  </div>
</form>
