import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { merge, Observable } from 'rxjs';
import { delay, filter, mapTo, startWith, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ModuleLoadingIndicatorService {
    loading$: Observable<boolean>;

    constructor(private router: Router) {
        const loadStart$ = this.router.events.pipe(
            filter(e =>
                e instanceof RouteConfigLoadStart/*  ||
                e instanceof NavigationStart */
            ),
            mapTo(true)
        );
        const loadEnd$ = this.router.events.pipe(
            filter(e =>
                e instanceof RouteConfigLoadEnd ||
                e instanceof NavigationCancel ||
                e instanceof NavigationError ||
                e instanceof NavigationEnd
            ),
            delay(200),
            mapTo(false)
        );

        this.loading$ = merge(loadStart$, loadEnd$).pipe(startWith(false));
    }
}
