import { Component, Inject, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth';
import { MENU_DROPDOWN_ANIMATIONS } from '../../../../shared/animations/menu-dropdown';
import { SharedService } from '../../../../shared/services/shared.service';
import { LinkUrls, LINK_URLS } from '@jarvis/services';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
  animations: MENU_DROPDOWN_ANIMATIONS
})
export class ProfileDropdownComponent implements OnInit {

  animationState = 'enter';

  domainCountry = inject(DOMAIN_COUNTRY);

  constructor(
    private authService: JarvisAuthService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private sharedService: SharedService,
    @Inject(LINK_URLS) private linkUrls: LinkUrls
  ) { }

  ngOnInit(): void {
  }

  navigateToSettings(): void {
    this.router.navigate(['/profile/settings'], { relativeTo: this.currentRoute });
  }

  openDemoRequest(): void {
    this.sharedService.openDemoRequestModal();
  }

  moveToMarketplace(): void {
    /* this.authService.createMoveSession().subscribe(newSessionData => {
      window.location.href = this.linkUrls.marketplace;
    }); */
    window.location.href = `${this.linkUrls.marketplace}/profile/settings?from=login`;
  }

  logout(): void {
    this.authService.signOut().subscribe(() => {
      window.location.href = this.linkUrls.marketplace;
    });
  }

  close(): void {
    // this.overlayRef.detach();
  }

}
