<div
  class="bztt-font-sora bztt-flex bztt-w-full bztt-justify-center bztt-items-center"
>
  <div
    class="bztt-max-w-[82rem] bztt-flex bztt-flex-col bztt-justify-start bztt-items-center bztt-p-10 bztt-gap-10"
  >
    <div
      class="bztt-flex bztt-justify-start bztt-items-center bztt-mb-5 bztt-w-full"
    >
      <button
        [routerLink]="['/']"
        class="bztt-flex bztt-items-center bztt-justify-center bztt-p-2 bztt-px-4 bztt-rounded-full bztt-bg-gray-200 bztt-text-gray-600 bztt-hover:bg-gray-300 bztt-transition-colors"
      >
        <mat-icon>arrow_back_ios</mat-icon> Atgal
      </button>
    </div>
    <div
      class="bztt-flex bztt-justify-center bztt-items-center bztt-w-full bztt-mb-7 md:bztt-mb-14 bztt-gap-8 bztt-flex-col lg:bztt-flex-row"
    >
      <img
        class="md:bztt-h-48 "
        src="/assets/images/eu-funding/eu-funding-logo2.png"
        alt="Naujos kartos lietuva"
      />
      <img
        class="md:bztt-h-48"
        src="/assets/images/eu-funding/eu-funding-logo1.png"
        alt="Finansuoja europos sąjunga"
      />
    </div>

    <div>
      <div class="bztt-pb-8 bztt-text-xl bztt-leading-7 md:bztt-text-2xl md:bztt-leading-10">
        <strong>
          Projektas „Dirbtiniu intelektu paremtos renginių planavimo sistemos
          sukūrimas“
        </strong>
      </div>
      <p class=" bztt-leading-7 md:bztt-text-xl md:bztt-leading-10">
        <strong>Vesele Tech, UAB</strong> įgyvendina projektą „Dirbtiniu
        intelektu paremtos renginių planavimo sistemos sukūrimas“ Nr.
        02-018-K-0047, kurio tikslas yra sukurti dirbtinio intelekto
        technologijomis paremtą renginių planavimo sistemą.
      </p>

      <p class=" bztt-leading-7 md:bztt-text-xl md:bztt-leading-10">
        Projekto metu numatoma sukurti dirbtiniu intelektu (DI) paremtą
        <strong>Breezit AI</strong> renginių planavimo sistemą (sprendimo
        prototipą). Breezit platformoje įdiegta DI paremta renginių planavimo
        sistema suteiks galimybę privačių renginių paslaugų teikėjams realiu
        laiku atsakyti į klientų klausimus apie teikiamas paslaugas, jų sudėtį,
        kainodarą, užimtumą, o esant kliento susidomėjimui, rezervuoti skambučio
        ir (ar) susitikimo laiką su paslaugų teikėju.
      </p>

      <p class=" bztt-leading-7 md:bztt-text-xl md:bztt-leading-10">
        Projektas įgyvendinamas pagal 2022–2030 metų Lietuvos Respublikos
        ekonomikos ir inovacijų ministerijos ekonomikos transformacijos ir
        konkurencingumo plėtros programos pažangos priemonę „Skatinti įmones
        skaitmenizuotis“, veiklą „Finansinės paskatos startuoliams ir atžalinėms
        įmonėms kurti DI, blokų grandinės technologijų, robotikos procesų
        automatizavimo produktus ir sprendimus“.
      </p>

      <p class=" bztt-leading-7 md:bztt-text-xl md:bztt-leading-10">
        <strong>Bendra projekto vertė</strong> – 171 779,80 Eur.
      </p>
      <p class=" bztt-leading-7 md:bztt-text-xl md:bztt-leading-10">
        <strong
          >Ekonomikos gaivinimo ir atsparumo didinimo priemonės lėšomis
          projektui skirtas finansavimas</strong
        >
        – 85 889,90 Eur.
      </p>
      <p class=" bztt-leading-7 md:bztt-text-xl md:bztt-leading-10">
        <strong>Pareiškėjo nuosavos lėšos</strong> – 85 889,90 Eur.
      </p>
      <p class=" bztt-leading-7 md:bztt-text-xl md:bztt-leading-10">
        <strong>Projekto įgyvendinimo pradžia</strong> – 2024 m. kovo 1 d.,
        <strong>pabaiga</strong> – 2025 m. birželio 30 d.
      </p>
    </div>
  </div>
</div>
