export const ICON_DATA = {
  new: '/assets/icons/icon-types/uplifted/ic_new.svg',
  venues: '/assets/icons/icon-types/uplifted/ic_venues.svg',
  ceremony_venue: '/assets/icons/icon-types/ic_ceremony.svg',
  catering: '/assets/icons/icon-types/ic_food.svg',
  ceremony_regale: '/assets/icons/icon-types/ic_regale.svg',
  bartenders: '/assets/icons/icon-types/ic_bartender.svg',
  foodtruck: '/assets/icons/icon-types/ic_food_cart.svg',
  videographer: '/assets/icons/icon-types/ic_video.svg',
  photographer: '/assets/icons/icon-types/ic_photo.svg',
  live_music: '/assets/icons/icon-types/ic_music.svg',
  planning: '/assets/icons/icon-types/ic_planning.svg',
  host: '/assets/icons/icon-types/ic_microphone.svg',
  cakes_desserts: '/assets/icons/icon-types/ic_cake.svg',
  makeup_hairdressing: '/assets/icons/icon-types/ic_makeup.svg',
  dj: '/assets/icons/icon-types/ic_dj.svg',
  bride_clothing: '/assets/icons/icon-types/ic_dress.svg',
  groom_clothing: '/assets/icons/icon-types/ic_groom.svg',
  secondary_dress: '/assets/icons/icon-types/ic_market.svg',
  transport: '/assets/icons/icon-types/ic_transport.svg',
  decor: '/assets/icons/icon-types/ic_decorations.svg',
  invitations_printing: '/assets/icons/icon-types/ic_invitation.svg',
  decor_floristics: '/assets/icons/icon-types/ic_flowers.svg',
  pavilion_furniture: '/assets/icons/icon-types/ic_pavilion.svg',
  sound_lights: '/assets/icons/icon-types/ic_effects.svg',
  screens_projectors: '/assets/icons/icon-types/ic_screens.svg',
  entertainment: '/assets/icons/icon-types/ic_party.svg',
  show: '/assets/icons/icon-types/ic_show.svg',
  training: '/assets/icons/icon-types/uplifted/ic_training.svg',
  jewelery: '/assets/icons/icon-types/ic_rings.svg',
  presents: '/assets/icons/icon-types/ic_present.svg',
  honeymoon: '/assets/icons/icon-types/ic_honeymoon.svg',
  photo_booth: '/assets/icons/icon-types/ic_photobooth.svg',
  ceremony_host: '/assets/icons/icon-types/ic_ceremony_host.svg',
  visual_entertainment: '/assets/icons/icon-types/ic_visual_entertainment.svg',
};

export const CATEGORIES_V2_LT = {
  wedding: [
    { title: 'venues', category: 'venues', image: null, iconName: 'venues', link: 'vestuviu-vietos', main: true },
    { title: 'photographer', category: 'photographer', image: null, iconName: 'photographer', link: 'vestuviu-fotografija', main: true },
    { title: 'host', category: 'host', image: null, iconName: 'host', link: 'vestuviu-vedejai', main: true },
    { title: 'live_music', category: 'live_music', image: null, iconName: 'live_music', link: 'gyva-muzika-vestuvems', main: true },
    { title: 'planning', category: 'planning', image: null, iconName: 'planning', link: 'vestuviu-planavimas', main: true },

    { title: 'catering', category: 'catering', image: null, iconName: 'catering', link: 'isvaziuojamieji-banketai-vestuvems' },
    { title: 'videographer', category: 'videographer', image: null, iconName: 'videographer', link: 'vestuviu-filmavimas' },
    { title: 'dj', category: 'dj', image: null, iconName: 'dj', link: 'dj-vestuvems' },
    { title: 'jewelery', category: 'jewelery', image: null, iconName: 'jewelery', link: 'vestuvine-juvelyrika' },
    { title: 'ceremony_regale', category: 'ceremony_regale', image: null, iconName: 'ceremony_regale', link: 'sampano-staliukai-vestuvems' },
    { title: 'decor_floristics', category: 'decor_floristics', image: null, iconName: 'decor_floristics', link: 'vestuviu-dekoras' },
    { title: 'entertainment', category: 'entertainment', image: null, iconName: 'entertainment', link: 'pramogos-vestuvems' },
    { title: 'bride_clothing', category: 'bride_clothing', image: null, iconName: 'bride_clothing', link: 'vestuvines-sukneles' },
    { title: 'bartenders', category: 'bartenders', image: null, iconName: 'bartenders', link: 'mobilus-baras-vestuvems' },
    { title: 'decor', category: 'decor_elements', image: null, iconName: 'decor', link: 'vestuviu-dekoracijos' },
    { title: 'visual_entertainment', category: 'visual_entertainment', image: null, iconName: 'visual_entertainment', link: 'foto-video-pramogos-vestuvems' },
    { title: 'groom_clothing', category: 'groom_clothing', image: null, iconName: 'groom_clothing', link: 'jaunikio-kostiumas' },
    { title: 'foodtruck', category: 'catering', subcategory: 'foodTruck', image: null, iconName: 'foodtruck', link: 'isvaziuojamieji-banketai-vestuvems/food-truck' },
    { title: 'invitations_printing', category: 'invitations_printing', image: null, iconName: 'invitations_printing', link: 'vestuviu-kvietimai' },
    { title: 'show', category: 'show', image: null, iconName: 'show', link: 'sou-vestuvems' },
    { title: 'makeup_hairdressing', category: 'makeup_hairdressing', image: null, iconName: 'makeup_hairdressing', link: 'grozio-paslaugos-vestuvems' },
    { title: 'cakes_desserts', category: 'cakes_desserts', image: null, iconName: 'cakes_desserts', link: 'saldus-stalas-vestuvems' },
    { title: 'pavilion_furniture', category: 'pavilion_furniture', image: null, iconName: 'pavilion_furniture', link: 'baldai-vestuvems' },
    { title: 'sound_lights', category: 'sound_lights', image: null, iconName: 'sound_lights', link: 'vestuviu-igarsinimas-apsvietimas' },
    { title: 'training', category: 'training', image: null, iconName: 'training', link: 'mokymai-vestuvems' },
    { title: 'ceremony_venue', category: 'ceremony_venue', image: null, iconName: 'ceremony_venue', link: 'ceremonijos-vieta' },
    { title: 'transport', category: 'transport', image: null, iconName: 'transport', link: 'automobiliu-nuoma-vestuvems' },
    { title: 'ceremony_host', category: 'ceremony_host', image: null, iconName: 'ceremony_host', link: 'vestuviu-ceremonijos-vedejai' },
  ],
  business: [
    { title: 'venues', category: 'venues', image: null, iconName: 'venues', link: 'vietos-imones-renginiams', main: true },
    { title: 'live_music', category: 'live_music', image: null, iconName: 'live_music', link: 'gyva-muzika-imones-renginiams', main: true },
    { title: 'host', category: 'host', image: null, iconName: 'host', link: 'imones-renginiu-vedejai', main: true },
    { title: 'show', category: 'show', image: null, iconName: 'show', link: 'sou-imones-renginiams', main: true },
    { title: 'catering', category: 'catering', image: null, iconName: 'catering', link: 'isvaziuojamieji-banketai-imones-renginiams', main: true },
    
    { title: 'dj', category: 'dj', image: null, iconName: 'dj', link: 'dj-imones-renginiams' },
    { title: 'photographer', category: 'photographer', image: null, iconName: 'photographer', link: 'imones-renginiu-fotografai' },
    { title: 'decor_floristics', category: 'decor_floristics', image: null, iconName: 'decor_floristics', link: 'imones-renginiu-dekoras' },
    { title: 'business_planning', category: 'planning', image: null, iconName: 'planning', link: 'imones-renginiu-planavimas' },
    { title: 'entertainment', category: 'entertainment', image: null, iconName: 'entertainment', link: 'pramogos-imones-renginiams' },
    { title: 'videographer', category: 'videographer', image: null, iconName: 'videographer', link: 'imones-renginiu-filmavimas' },
    { title: 'decor', category: 'decor_elements', image: null, iconName: 'decor', link: 'imones-renginiu-dekoracijos' },
    { title: 'bride_clothing', category: 'bride_clothing', image: null, iconName: 'bride_clothing', link: 'moteriska-sventine-apranga-imones-renginiams' },
    { title: 'visual_entertainment', category: 'visual_entertainment', image: null, iconName: 'visual_entertainment', link: 'foto-video-pramogos-imones-renginiams' },
    { title: 'bartenders', category: 'bartenders', image: null, iconName: 'bartenders', link: 'mobilus-baras-imones-renginiams' },
    { title: 'pavilion_furniture', category: 'pavilion_furniture', image: null, iconName: 'pavilion_furniture', link: 'baldai-imones-renginiams' },
    { title: 'groom_clothing', category: 'groom_clothing', image: null, iconName: 'groom_clothing', link: 'vyriska-sventine-apranga-imones-renginiams' },
    { title: 'training', category: 'training', image: null, iconName: 'training', link: 'mokymai-imones-renginiams' },
    { title: 'foodtruck', category: 'catering', subcategory: 'foodTruck', image: null, iconName: 'foodtruck', link: 'isvaziuojamieji-banketai-imones-renginiams/food-truck' },
    { title: 'invitations_printing', category: 'invitations_printing', image: null, iconName: 'invitations_printing', link: 'kvietimai-imones-renginiams' },
    { title: 'makeup_hairdressing', category: 'makeup_hairdressing', image: null, iconName: 'makeup_hairdressing', link: 'grozio-paslaugos-imones-renginiams' },
    { title: 'sound_lights', category: 'sound_lights', image: null, iconName: 'sound_lights', link: 'imones-renginiu-igarsinimas-apsvietimas' },
    { title: 'cakes_desserts', category: 'cakes_desserts', image: null, iconName: 'cakes_desserts', link: 'saldus-stalas-imones-renginiams' },
    { title: 'transport', category: 'transport', image: null, iconName: 'transport', link: 'automobiliu-nuoma-imones-renginiams' },
  ],
  private: [
    { title: 'venues', category: 'venues', image: null, iconName: 'venues', link: 'asmeniniu-svenciu-vietos', main: true },
    { title: 'photographer', category: 'photographer', image: null, iconName: 'photographer', link: 'fotografai-asmeninems-sventems', main: true },
    { title: 'host', category: 'host', image: null, iconName: 'host', link: 'asmeniniu-svenciu-vedejai', main: true },
    { title: 'entertainment', category: 'entertainment', image: null, iconName: 'entertainment', link: 'pramogos-asmeninems-sventems', main: true },
    { title: 'catering', category: 'catering', image: null, iconName: 'catering', link: 'isvaziuojamieji-banketai-asmeninems-sventems', main: true },

    { title: 'bartenders', category: 'bartenders', image: null, iconName: 'bartenders', link: 'mobilus-baras-asmeninems-sventems' },
    { title: 'videographer', category: 'videographer', image: null, iconName: 'videographer', link: 'asmeniniu-svenciu-filmavimas' },
    { title: 'live_music', category: 'live_music', image: null, iconName: 'live_music', link: 'gyva-muzika-asmeninems-sventems' },
    
    { title: 'planning', category: 'planning', image: null, iconName: 'planning', link: 'asmeniniu-svenciu-planavimas' },

    { title: 'ceremony_regale', category: 'ceremony_regale', image: null, iconName: 'ceremony_regale', link: 'sampano-staliukai-asmeninems-sventems' },
    { title: 'decor_floristics', category: 'decor_floristics', image: null, iconName: 'decor_floristics', link: 'asmeniniu-svenciu-dekoras' },
    { title: 'dj', category: 'dj', image: null, iconName: 'dj', link: 'dj-asmeninems-sventems' },


    { title: 'jewelery', category: 'jewelery', image: null, iconName: 'jewelery', link: 'juvelyrika-asmeninems-sventems' },
    { title: 'cakes_desserts', category: 'cakes_desserts', image: null, iconName: 'cakes_desserts', link: 'saldus-stalas-asmeninems-sventems' },
    { title: 'decor', category: 'decor_elements', image: null, iconName: 'decor', link: 'asmeniniu-svenciu-dekoracijos' },
    { title: 'show', category: 'show', image: null, iconName: 'show', link: 'sou-asmeninems-sventems' },

    { title: 'bride_clothing', category: 'bride_clothing', image: null, iconName: 'bride_clothing', link: 'moteriski-rubai-asmeninems-sventems' },
    { title: 'foodtruck', category: 'catering', subcategory: 'foodTruck', image: null, iconName: 'foodtruck', link: 'isvaziuojamieji-banketai-asmeninems-sventems/food-truck' },
    { title: 'invitations_printing', category: 'invitations_printing', image: null, iconName: 'invitations_printing', link: 'kvietimai-asmeninems-sventems' },
    { title: 'visual_entertainment', category: 'visual_entertainment', image: null, iconName: 'visual_entertainment', link: 'foto-video-pramogos-asmeninems-sventems' },
    
    { title: 'groom_clothing', category: 'groom_clothing', image: null, iconName: 'groom_clothing', link: 'vyriski-rubai-asmeninems-sventems' },
    { title: 'transport', category: 'transport', image: null, iconName: 'transport', link: 'automobiliu-nuoma-asmeninems-sventems' },
    { title: 'pavilion_furniture', category: 'pavilion_furniture', image: null, iconName: 'pavilion_furniture', link: 'baldai-asmeninems-sventems' },
    { title: 'sound_lights', category: 'sound_lights', image: null, iconName: 'sound_lights', link: 'asmeniniu-svenciu-igarsinimas-apsvietimas' },

    { title: 'makeup_hairdressing', category: 'makeup_hairdressing', image: null, iconName: 'makeup_hairdressing', link: 'grozio-paslaugos-asmeninems-sventems' },

    { title: 'training', category: 'training', image: null, iconName: 'training', link: 'mokymai-asmeninese-sventese' },
  ]
};

export const CATEGORIES_V2_US = {
  wedding: [
    { title: 'venues', category: 'venues', image: null, iconName: 'venues', link: 'wedding-venues', main: true },
    { title: 'photographer', category: 'photographer', image: null, iconName: 'photographer', link: 'wedding-photographers', main: true },
    { title: 'videographer', category: 'videographer', image: null, iconName: 'videographer', link: 'wedding-videographers', main: true },
    { title: 'live_music', category: 'live_music', image: null, iconName: 'live_music', link: 'wedding-live-music', main: true },
    { title: 'dj', category: 'dj', image: null, iconName: 'dj', link: 'wedding-djs', main: true },
    
    { title: 'decor_floristics', category: 'decor_floristics', image: null, iconName: 'decor_floristics', link: 'florists' },
    { title: 'planning', category: 'planning', image: null, iconName: 'planning', link: 'wedding-planners' },
  ],
  business: [
    { title: 'venues', category: 'venues', image: null, iconName: 'venues', link: 'event-venues', main: true },
    { title: 'photographer', category: 'photographer', image: null, iconName: 'photographer', link: 'photographers', main: true },
    { title: 'videographer', category: 'videographer', image: null, iconName: 'videographer', link: 'videographers', main: true },
    { title: 'live_music', category: 'live_music', image: null, iconName: 'live_music', link: 'live-music', main: true },
    { title: 'dj', category: 'dj', image: null, iconName: 'dj', link: 'djs', main: true },
    
    { title: 'decor_floristics', category: 'decor_floristics', image: null, iconName: 'decor_floristics', link: 'florists' },
    { title: 'planning', category: 'planning', image: null, iconName: 'planning', link: 'planners' },
  ],
  private: [
    { title: 'venues', category: 'venues', image: null, iconName: 'venues', link: 'venues', main: true },
    { title: 'photographer', category: 'photographer', image: null, iconName: 'photographer', link: 'event-photographers', main: true },
    { title: 'videographer', category: 'videographer', image: null, iconName: 'videographer', link: 'event-videographers', main: true },
    { title: 'live_music', category: 'live_music', image: null, iconName: 'live_music', link: 'event-live-music', main: true },
    { title: 'dj', category: 'dj', image: null, iconName: 'dj', link: 'event-djs', main: true },
    
    { title: 'decor_floristics', category: 'decor_floristics', image: null, iconName: 'decor_floristics', link: 'florists' },
    { title: 'planning', category: 'planning', image: null, iconName: 'planning', link: 'planners' },
  ]
};

/* export const CATEGORIES_V2_US = {
  wedding: [
    { title: 'ranches', category: 'venues', image: null, iconName: '', link: 'wedding-venues/ranch-farm', main: true },
    { title: 'golf-club', category: 'venues', image: null, iconName: '', link: 'wedding-venues/country-golf-club', main: true },
    { title: 'vineyards', category: 'venues', image: null, iconName: '', link: 'wedding-venues/vineyard', main: true },
    { title: 'mansions', category: 'venues', image: null, iconName: '', link: 'wedding-venues/manor-estate-villa', main: true },
    
    { title: 'planning', category: 'planning', image: null, iconName: 'planning', link: 'wedding-planners' },
  ],
  business: [],
  private: []
}; */

// Ranches, farms, vineyards, mansions