import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { JarvisModalComponent, JarvisUiModalWrapperModule } from '@jarvis/ui';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'debug-prompt',
  templateUrl: 'debug-prompt.component.html',
  standalone: true,
  imports: [
    CommonModule,
    JarvisModalComponent,
    JarvisUiModalWrapperModule,
    ReactiveFormsModule
  ]
})

export class DebugPromptComponent {

  @Input() promptControl = new FormControl('', { validators: [ Validators.required ]});

  @Output() closed = new EventEmitter<void>();

  closeHandler() {
    this.closed.emit();
  }
}