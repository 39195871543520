import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { JarvisUiPaginatorComponent } from './paginator.component';

const PUBLIC_COMPONENTS = [
    JarvisUiPaginatorComponent
];

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule,
        RouterModule
    ],
    exports: [
        ...PUBLIC_COMPONENTS
    ],
    declarations: [
        ...PUBLIC_COMPONENTS
    ],
    providers: [],
})
export class JarvisUiPaginatorModule { }
