/* eslint-disable max-len */
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth';
import { LinkUrls, LINK_URLS } from '@jarvis/services';
import { JarvisHeaderService, LayoutService } from '@jarvis/ui';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'jarvis-marketplace-landing-root',
  templateUrl: './marketplace-landing.component.html',
  styleUrls: ['./marketplace-landing.component.scss'],
})
export class MarketplaceLandingComponent implements OnInit, OnDestroy {

  @ViewChild('landingHeader', {static: true}) landingHeader: TemplateRef<HTMLDivElement>;

  isMobile = false;
  loggedIn$ = this.authService.isLoggedIn$;

  saasLandingLink = `${this.linkUrls.marketplace}`;

  endorsmentConfig = [
    {
      image: 'viktorija.jpg',
      label: 'Viktorija',
      description: 'With Breezit I don’t have to make multiple calls anymore, and most importantly, I have all the filters to fit my budget for my wedding.'
    },
    {
      image: 'evelina.jpg',
      label: 'Evelina',
      description: 'Before I found Breezit, I had my notes scattered across Excel files, a notebook and some random papers. Now I see everything I need in one place.'
    }
  ];

  sections = [
    {
      header: 'Discover service providers',
      text: 'Discover best service providers for your event. See their availability, services and pricing at a click of a button.',
      videoLink: 'assets/videos/users_1.mp4',
      videoPoster: ''
    },
    {
      header: 'Create your own wishlist',
      text: 'Select, save and compare your favorite service providers in an easy-to-use workspace. Get live updates and notifications.',
      // videoLink: 'assets/videos/users_1.mp4',
      imageLink: 'assets/images/planner.png',
      videoPoster: ''
    },
    {
      header: 'Communicate directly',
      text: 'Forget about numerous phone calls and emails. Easily manage all communications in one single platform.',
      videoLink: 'assets/videos/users_3.mp4',
      videoPoster: ''
    },
    {
      header: 'Securely book services',
      text: 'Book, contract and pay for services using a range of secure and verified payment methods.',
      videoLink: 'assets/videos/users_4.mp4',
      videoPoster: ''
    }
  ];

  functionsConfig = [
    {
      icon: 'ic_create_account.svg',
      color: '#e5e7f8',
      label: 'Create an account'
    },
    {
      icon: 'ic_message_service_providers.svg',
      color: '#fdeae3',
      label: 'Message service providers'
    },
    {
      icon: 'ic_update_your_calendar.svg',
      color: '#fff3e0',
      label: 'Book services'
    },
  ]

  private destroy$ = new Subject<void>();

  constructor(
    private layoutService: LayoutService,
    private headerService: JarvisHeaderService,
    private router: Router,
    private authService: JarvisAuthService,
    @Inject(LINK_URLS) private linkUrls: LinkUrls,
    private vcr: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.layoutService.isMobileObserver
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((isMobile) => this.isMobile = isMobile);

    // this.headerService.hideHeader();
    // this.layoutService.setFooterStyle();
    this.headerService.setHeaderTemplate(this.landingHeader, this.vcr);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.headerService.setDefaultHeader();
  }

  openContactFormDialog(): void {
    this.loggedIn$.pipe(
      take(1)
    ).subscribe(loggedIn => {
      if (loggedIn) {
        // window.location.href = this.linkUrls.saas;
        this.router.navigateByUrl('/planner');
        return;
      }

      this.router.navigateByUrl('/auth/register');
      this.authService.createRegisterOrLoginSuccessStream().subscribe(() => {
        // window.location.href = this.linkUrls.saas;
        this.router.navigateByUrl('/planner');
      });
    });
  }

  openLoginDialog(): void {
    this.loggedIn$.pipe(
      take(1)
    ).subscribe(loggedIn => {
      if (loggedIn) {
        // window.location.href = this.linkUrls.saas;
        this.router.navigateByUrl('/planner');
        return;
      }

      this.router.navigateByUrl('/auth/login');
      this.authService.createRegisterOrLoginSuccessStream().subscribe(() => {
        // window.location.href = this.linkUrls.saas;
        this.router.navigateByUrl('/planner');
      });
    });
  }
}
