import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketplaceLandingComponent } from './marketplace-landing.component';
import { MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions, MatLegacyFormFieldModule as MatFormFieldModule, MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { LANDING_TYPE } from '../services';
import { RouterModule, Routes } from '@angular/router';
import { ButtonModule } from '@jarvis/ui';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { LandingsModule } from '../landings.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';


const FORM_FIELD_DEFAULTS: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

const ROUTES: Routes = [
  {
    path: '',
    component: MarketplaceLandingComponent
  }
];

@NgModule({
  declarations: [
    MarketplaceLandingComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    TranslateModule.forChild(),    
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    LayoutModule,
    ButtonModule,
    HttpClientModule,
    LandingsModule,
    MatSidenavModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: FORM_FIELD_DEFAULTS
    },
    {
      provide: LANDING_TYPE,
      useValue: 'vendor'
    }
  ],
  exports: [
    MarketplaceLandingComponent
  ]
})
export class MarketplaceLandingModule { }
