import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CardListingTypePipe } from '../../pipes/card-listing-type.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PriceUnitLabelPipe } from '../../pipes/price-unit.pipe';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { JarvisOverlayComponent } from '@jarvis/ui';
import { DOMAIN_COUNTRY } from '@jarvis/services';

@Component({
  selector: 'jarvis-ui-marketplace-card-default-info-new',
  templateUrl: 'default-info-new.component.html',
  styleUrls: [
    'default-info-new.component.scss'
  ],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    CardListingTypePipe,
    PriceUnitLabelPipe,
    CdkOverlayOrigin,
    JarvisOverlayComponent
  ]
})

export class JarvisUiMarketplaceCardDefaultInfoNewComponent implements OnInit {

  @Output() overlayStateChange = new EventEmitter<boolean>();
  
  @Input() theme: 'dark' | 'bright' = 'dark';
  @Input() type: 'default' | 'shorts' = 'default';
  
  @Input() data;

  domainCountry = inject(DOMAIN_COUNTRY);

  hasOverlayInformation = false;
  infoOverlayShown = false;
  
  ngOnInit() {
    this.hasOverlayInformation = this.data.venueInfo?.seatsOverCapacity;
  }

  handleInfoOverlayOpen(): void {
    this.infoOverlayShown = true;
    this.overlayStateChange.emit(true);
  }

  handleInfoOverlayClose(): void {
    this.infoOverlayShown = false;
    this.overlayStateChange.emit(false);
  }
}