
import { Component, inject } from '@angular/core';
import { DOMAIN_COUNTRY } from '@jarvis/services';

@Component({
  selector: 'jarvis-planner-section',
  templateUrl: './planner-section.component.html',
  styleUrls: ['./planner-section.component.scss'],
})
export class HomepagePlannerSectionComponent {


  country = inject(DOMAIN_COUNTRY)

  constructor() {}
}
