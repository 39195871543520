<section [formGroup]="contactsForm" class="review-container">
    <span>
        {{ 'reviews.contacts.enterContactInfo' | translate }}
    </span>

    <div class='contacts-container'>
        <mat-form-field>
            <mat-label>{{ 'reviews.contacts.name' | translate }}</mat-label>
            <input autocomplete="given-name" matInput formControlName="name" />
            <mat-error>{{'errors.fieldRequired' | translate}}</mat-error>
        </mat-form-field>
    
        <mat-form-field>
            <mat-label>{{ 'reviews.contacts.surname' | translate }}</mat-label>
            <input autocomplete="family-name" matInput formControlName="surname" />
            <mat-error>{{'errors.fieldRequired' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'reviews.contacts.email' | translate }}</mat-label>
            <input type="email" matInput formControlName="email" />
            <mat-error>{{'errors.fieldRequired' | translate}}</mat-error>
        </mat-form-field>
    
        <mat-form-field>
            <mat-label>{{ 'reviews.contacts.phoneNo' | translate }}</mat-label>
            <input type="phone" matInput formControlName="phoneNo" />
            <mat-error>{{'errors.fieldRequired' | translate}}</mat-error>
        </mat-form-field>
    
    </div>

    <div class='text-wrapper'>
        <span>
            {{'reviews.byContinuing' | translate}} Breezit
            <a (click)="openTermsAndConditionsModal('rules')">
                {{'reviews.termsAndConditions' | translate}}
            </a>{{'reviews.and' | translate}}
            <a (click)="openTermsAndConditionsModal('privacy')">
                {{'reviews.privacyPolicy' | translate}}
            </a>
        </span>
    </div>

    <button [disabled]="contactsForm.invalid" app-button color="accent" (click)="submit()">
        {{ 'reviews.submit' | translate }}
    </button>

    <a (click)="back()">
        {{ 'reviews.goBack' | translate }}
    </a>
</section>