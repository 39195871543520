import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth/services';
import { LinkUrls, LINK_URLS } from '@jarvis/services/tokens';
import { animate, state, style, transition, trigger } from '@angular/animations';

const MENU_DROPDOWN_ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';
export const MENU_DROPDOWN_ANIMATIONS = [
  trigger('fade', [
    state('fadeOut', style({ opacity: 0 })),
    state('fadeIn', style({ opacity: 1 })),
    transition('* => fadeIn', animate(MENU_DROPDOWN_ANIMATION_TIMINGS))
  ]),
  trigger('slideContent', [
    state(
      'void',
      style({ transform: 'translate3d(0, -20%, 0) scale(0.9)', opacity: 0 })
    ),
    state('enter', style({ transform: 'none', opacity: 1 })),
    state(
      'leave',
      style({ transform: 'translate3d(0, -20%, 0)', opacity: 0 })
    ),
    transition('* => *', animate(MENU_DROPDOWN_ANIMATION_TIMINGS))
  ])
];

@Component({
  selector: 'marketplace-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
  animations: MENU_DROPDOWN_ANIMATIONS
})
export class ProfileDropdownComponent implements OnInit {

  animationState = 'enter';

  constructor(
    private authService: JarvisAuthService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    @Inject(LINK_URLS) private linkUrls: LinkUrls
  ) { }

  ngOnInit(): void {
  }

  moveToSaas(): void {
    this.authService.moveUserToSaas.subscribe(() => {
      window.location.href = this.linkUrls.saas;
    });
  }

  navigateToSettings(): void {
    this.router.navigate(['/profile/settings'], { relativeTo: this.currentRoute });
  }

  openDemoRequest(): void {
    // this.sharedService.openDemoRequestModal();
  }

  logout(): void {
    this.authService.signOut().subscribe(() => {
      this.router.navigateByUrl('/');
    });
  }

  moveToMarketplace(): void {
    window.location.href = `${this.linkUrls.marketplace}/profile/settings?from=login`;
  }

  close(): void {
    // this.overlayRef.detach();
  }

}
