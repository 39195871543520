import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, InjectionToken, Injector, Type, ViewContainerRef } from '@angular/core';
import { defer, map, shareReplay } from 'rxjs';

type ExpectedImportType<T, K> = {
  component: Type<T>;
  dataToken?: InjectionToken<K>;
}

type ComponentAttachCallback<T> = (componentRef: ComponentRef<T>) => void;

export function loadComponent<T, K>(importStatement: () => Promise<ExpectedImportType<T, K>>, data: K, vcr: ViewContainerRef, attachCallback: ComponentAttachCallback<T>) {
  return defer(importStatement).pipe(
    map((loadResult) => {
      const component = loadResult.component;
      const dataToken = loadResult.dataToken;

      const injector = Injector.create({
        providers: [
          {
            provide: dataToken,
            useValue: data
          }
        ],
        parent: vcr?.injector || null
      });
      
      const componentPortal = new ComponentPortal(component, null, injector);

      return {
        portal: componentPortal,
        attachCb: attachCallback
      };

    }),
    shareReplay(1)
  );
}
