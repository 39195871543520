import { sift4 } from '../algorithms/sift4';
import { DOMAINS, HOSTS, TLDS } from './email.data';

export const EMAIL = /^\S+@\S+\.\S+$/u;

type Match = {
  distance: number;
  match: string;
};

function findClosest(haystack: Array<string>, needle: string, threshold: number = 2): Match | undefined {
  const closest = haystack.reduce<Match | undefined>((prev: Match | undefined, d: string) => {
      const distance = sift4(needle, d, 5, 13);
      
      const current: Match = {
        distance,
        match: d
      };

      if (!prev) {
        return current;
      }

      return (current.distance < prev.distance) ? current : prev;
    },
    undefined
  );

  if (closest && closest.distance > threshold) {
    return undefined;
  }

  return closest;
}

function suggest(email: string): string | undefined {
  // const match = /(\S+?@)(\S+?(\.\S{2,}|\S{3})?)$/u.exec(email);
  const match = /(\S+?@)(\S+?(\.\S{2,})?)$/u.exec(email);

  if (!match) {
    return undefined;
  }

  const [, user, domain, tld] = match;

  // Check full domain
  if (DOMAINS.includes(domain)) {
    return undefined;
  }

  const closestDomain = findClosest(DOMAINS, domain);

  if (closestDomain) {
    return `${user}${closestDomain.match}`;
  }

  // Check host and top-level domains
  if (tld) {
    const host = domain.slice(0, (-1 * tld.length));
    const closestHost = findClosest(HOSTS, host);
    const strippedTld = (tld[0] === '.')
      ? tld.slice(1)
      : tld;
    const threshold = (domain.indexOf('.') >= 0) ? 2 : 1;
    const closestTld = findClosest(TLDS, strippedTld, threshold);

    if (!closestHost) {
      if (!closestTld) {
        return undefined;
      }

      let suggest = `${user}${host}.${closestTld.match}`;

      if (suggest === email) {
        return undefined;
      }

      return suggest;
    }
    else if (!closestTld || ((closestHost.distance === 0) && (closestTld.distance === 0))) {
      return undefined;
    }

    return `${user}${closestHost.match}.${closestTld.match}`;
  }

  return undefined;
}

function validate(email: string): boolean {
  if (!EMAIL.test(email)) {
    return false;
  }

  return true;
}

export {
  suggest as suggestEmail,
  validate as validateEmail
}