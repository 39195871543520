import { Pipe, PipeTransform, inject } from '@angular/core';
import { LINK_URLS } from '@jarvis/services';

/**
 * 
 * hostUrl +
        '/' +
        data?.slug +
        (cardType === 'partnerList'
          ? '?partnerId=' + data?.['partnerData'].partnerListItemId
          : '')
 */

@Pipe({
  name: 'Link',
  pure: true,
})
export class LinkPipe implements PipeTransform {
  private linkUrls = inject(LINK_URLS);

  transform(data, cardType): any {
    let baseUrl = '';

    // if (cardType === 'partnerList') {
    //   baseUrl = this.linkUrls.marketplace;
    //   console.log('baseUrl', baseUrl);
    // }

    const link = `${baseUrl}/${data?.slug}`;

    return link;
  }
}

@Pipe({
  name: 'LinkQueryParams',
  pure: true,
})
export class LinkQueryParamsPipe implements PipeTransform {
  transform(data, string = false) {
    const queryParams: any = new URLSearchParams();

    if (data?.partnerData) {
      queryParams.set('partnerId', data.partnerData.partnerListItemId);
    }

    if (data?.paid) {
      queryParams.set('paid', true);
    }

    if (data?.queryParams?.service) {
      queryParams.set('service', data.queryParams.service);
    }

    if (data?.queryParams?.personCount) {
      queryParams.set('personCount', data.queryParams.personCount);
    }

    if (data?.queryParams?._recs) {
      queryParams.set('_recs', data.queryParams._recs);
    }

    if (string) {
      const stringVal = queryParams.toString();
      return stringVal ? `?${stringVal}` : '';
    }

    return Object.fromEntries(queryParams.entries());
  }
}
