import { NgModule } from '@angular/core';

import { ServiceMainPhotoPipe } from './service-main-photo.pipe';

@NgModule({
  exports: [ServiceMainPhotoPipe],
  declarations: [ServiceMainPhotoPipe],
  providers: [ServiceMainPhotoPipe]
})
export class ServiceMainPhotoPipeModule {}
