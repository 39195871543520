<div
  class="bztt-text-xl bztt-font-semibold bztt-mb-4 bztt-text-start bztt-absolute bztt-top-0 bztt-z-10">
  Send contract
</div>

<div class="bztt-font-semibold bztt-mb-4 bztt-text-start">
  To: &nbsp;&nbsp; {{ detailData?.customer?.name }}
  {{ detailData?.customer?.surname }}
</div>

<div class="bztt-relative">
<div class="bztt-z-10 bztt-absolute bztt-top-0 bztt-left-0 bztt-items-center bztt-bg-[#ffffff8f] bztt-w-full bztt-h-full bztt-flex bztt-flex-col bztt-justify-center" *ngIf="processed | async">
   <mat-progress-spinner  mode="indeterminate" strokeWidth="2"></mat-progress-spinner>    
 </div>       
<mat-form-field appearance="outline" floatLabel="never" class="note w-100">
  <textarea
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="10"
    cdkAutosizeMaxRows="15"
    placeholder="Message"
    [formControl]="contractForm"
    type="text"
    matInput
  ></textarea>
</mat-form-field>

<div class="bztt-absolute bztt-bottom-8 bztt-flex bztt-justify-between bztt-items-start bztt-w-full bztt-left-0 bztt-px-4 bztt-text-sm md:bztt-text-base bztt-gap-3">
   <span class="bztt-text-start">
     To view your contract, please click here:  
  </span>

  <!-- [href]="contractLink" -->
  <a
  (click)="previewContract()"
  target="_blank"
  class="bztt-flex bztt-justify-start bztt-items-center bztt-gap-2 bztt-border-0 bztt-p-0"
  ><span
    class="bztt-text-sm bztt-underline bztt-font-semibold bztt-underline-offset-4 bztt-text-nowrap"
    >View contract</span
  ></a>
</div>
</div>

<div class="bztt-mt-6 bztt-flex bztt-flex-col md:bztt-flex-row bztt-justify-between bztt-items-center bztt-gap-5">
  <div class="bztt-text-sm bztt-font-semibold bztt-mb-4 bztt-text-start">
    The contract will be automatically included in the message and sent to the customer's inbox.
  </div>
  <button
    mat-raised-button
    type="submit"
    app-button
    color="accent"
    class="bztt-min-w-[160px] bztt-w-fit bztt-px-8"
    (click)="sendcontract()"
  >Send</button>
</div>
