export enum NavigationNotificationType {
    vendor = 'vendor navigation update',
    user = 'user navigation update'
}

type NotificationMessageCountData = {
  customer: { count: number}[];
  vendor: { count: number}[];
}
export interface NavigationNotificationDataResponse {
    messages: NotificationMessageCountData;
    bookings: number;
    likes: number;
    plannerItems?: number;
}

export interface NavigationNotificationData {
    messages: number;
    bookings: number;
    likes: number;
    plannerItems?: number;
}

export interface NavigationNotificationUpdate {
    message: 'update' | 'add' | 'remove',
    data: NavigationNotificationDataResponse
}
