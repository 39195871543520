<jarvis-modal
  (modalClose)="closeHandler()"
>
  <jarvis-ui-modal-wrapper
    class="modal-wrapper medium-modal"
    [fullHeight]="true"
    (closed)="closeHandler()"
  >
    <ng-container *ngIf="{
      component: configComponentPortal$ | async
    } as vm">
      <div *ngIf="!vm.component?.portal" class="bztt-flex bztt-justify-center bztt-items-center bztt-min-h-[540px]">
        <span>Loading...</span>
      </div>
      <ng-template (attached)="vm.component?.attachCb($event)" [cdkPortalOutlet]="vm.component?.portal"></ng-template>
    </ng-container>
  </jarvis-ui-modal-wrapper>
</jarvis-modal>