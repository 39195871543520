import { Pipe, PipeTransform } from '@angular/core';
import { ChatRoom } from '../../types/messaging.types';

@Pipe({
  name: 'ReadByUser',
  pure: true,
  standalone: true
})
export class ReadByUserPipe implements PipeTransform {
  transform(chatroom: ChatRoom, userId: string): any {
    if (chatroom.readBy?.includes(userId)) {
      return true;
    }

    return false;
  }
}

@Pipe({
  name: 'ReadByBoth',
  pure: true,
  standalone: true
})
export class ReadByBothPipe implements PipeTransform {
  transform(chatroom: ChatRoom): any {
    if (chatroom?.readBy?.length === 2) {
      return true;
    }

    return false;
  }
}
