<footer
  class="landing-footer bztt-relative bztt-gap-10 bztt-justify-start bztt-items-center"
  [ngClass]="{ 'bztt-mt-10': (footerUpperMargin$ | async) }"
>
  <img
    alt="logo"
    src="assets/images/bzt-logo.svg"
    class="bztt-invert bztt-max-w-[130px] bztt-self-start bztt-mt-7"
  />
  <div
    class="bztt-flex bztt-flex-row bztt-justify-between bztt-w-full md:bztt-gap-6 bztt-gap-14 bztt-flex-wrap"
  >
    <div
      class="bztt-flex bztt-flex-col bztt-gap-y-4 bztt-justify-start"
      [class.bztt-items-start]="!isMobile"
    >
      <div class="bztt-flex bztt-flex-col bztt-gap-8 bztt-max-w-md">
        <div *ngIf="domainCountry === 'us'">
          Breezit Inc. <br />2055 Limestone Rd. STE 200-C <br />
          Wilmington, DE <br />
          19808, USA
        </div>
        <div *ngIf="domainCountry !== 'us'">
          Vesele Tech UAB <br />
          Goštauto g. 8 <br />
          Vilnius, Lietuva
        </div>
        <div class="bztt-flex bztt-flex-col">
          <a class="email-link" href="mailto: support@breezit.com"
            >support@breezit.com</a
          >
          <a
            class="email-link"
            *ngIf="domainCountry === 'us'"
            href="tel:+1 (213) 723-2796"
            >+1 (213) 723-2796</a
          >
        </div>
      </div>
    </div>

    <ng-container class="groups-container" *ngIf="!(hideNearMe$ | async)">
      <div
        class="group bztt-flex bztt-flex-col bztt-gap-2"
        *ngFor="let group of data"
        [ngClass]="{'bztt-order-2 md:bztt-order-[unset]': !!group.titleLink}"
      >
        <ng-container [ngSwitch]="!!group.titleLink">
            <a *ngSwitchCase="true" [href]="group.titleLink" class="bztt-no-underline">
                <div class="group-title bztt-mb-3">{{ group.title | translate }}</div>
            </a>
            <div *ngSwitchDefault class="group-title bztt-mb-3">{{ group.title | translate }}</div>
        </ng-container>
        <a
          [href]="link.link"
          [target]="link.target ? link.target : '_self'"
          class="group-link bztt-no-underline bztt-p-0"
          *ngFor="let link of group.links"
        >
          {{ link.text | translate }}
        </a>
        <!-- <div class="label">Product</div>
      <a [href]="saasLandingLink" target="_blank" class="item"
        >For service providers</a
      >
      <a [href]="marketplaceLandingLink" target="_blank" class="item"
        >For event hosts</a
      > -->
      </div>
    </ng-container>

    <!-- <div class="group bztt-flex bztt-flex-col bztt-gap-2">
      <div class="group-title bztt-mb-3">
        {{ 'landing.footer.contactUs' | translate }}
      </div>
      <a class="email-link" href="mailto: support@breezit.com"
        >support@breezit.com</a
      >
    </div> -->

    <div>
      <div class="group-title">
        {{ 'landing.footer.followUs' | translate }}
      </div>
      <div
        class="social-links bztt-flex bztt-flex-row bztt-gap-6 bztt-mt-6"
        *ngIf="domainCountry !== 'us'"
      >
        <a target="_blank" href="https://www.facebook.com/breezitLT/">
          <img
            class="bztt-invert bztt-h-5"
            src="/assets/icons/ic_facebook.svg"
            alt="facebook"
          />
        </a>
        <a target="_blank" href="https://www.instagram.com/breezit_lt/">
          <img
            class="bztt-invert bztt-h-5"
            src="/assets/icons/ic_instagram.svg"
            alt="instagram"
          />
        </a>
        <!-- <a
          target="_blank"
          href="https://www.youtube.com/channel/UCtuf0-BInD4Bs8LRpmGKEIA"
        >
          <img
            class="bztt-invert bztt-h-5"
            src="/assets/icons/ic_youtube_footer.svg"
            alt="youtube"
          />
        </a> -->
        <!-- <a target="_blank" href="https://medium.com/foxywedding">
          <img
            class="bztt-invert bztt-h-5"
            src="/assets/icons/ic_medium_footer.svg"
            alt="medium"
          />
        </a> -->
      </div>
      <div
        class="social-links bztt-flex bztt-flex-row bztt-gap-6 bztt-mt-6"
        *ngIf="domainCountry === 'us'"
      >
        <a target="_blank" href="https://www.facebook.com/JustBreezit">
          <img
            class="bztt-invert bztt-h-5"
            src="/assets/icons/ic_facebook.svg"
            alt="facebook"
          />
        </a>
        <a target="_blank" href="https://www.instagram.com/justbreezit/">
          <img
            class="bztt-invert bztt-h-5"
            src="/assets/icons/ic_instagram.svg"
            alt="instagram"
          />
        </a>
      </div>
    </div>
    <!-- <mat-accordion *ngIf="isMobile" class="footer-accordion">
      <mat-expansion-panel *ngFor="let group of data">
        <mat-expansion-panel-header>
          <mat-panel-title class="group-title"
            >{{ group.title | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="group bztt-flex bztt-flex-col bztt-gap-y-4">
          <a
            [href]="link.link"
            class="group-link bztt-no-underline"
            *ngFor="let link of group.links"
          >
            {{ link.text | translate }}
          </a>
        </div>
      </mat-expansion-panel>
    </mat-accordion> -->
  </div>
  <!-- <div class="bztt-w-full md:bztt-mt-10" *ngIf="domainCountry === 'us' && !(hideNearMe$ | async) ">
    <div class="group-title bztt-mb-5 bztt-text-xl">
      {{ 'landing.footer.nearMe.title' | translate }}
    </div>

    <div
      class="bztt-flex bztt-flex-row bztt-w-full bztt-justify-between bztt-flex-wrap bztt-gap-2"
    >
      <div
        class="group bztt-flex bztt-flex-col bztt-gap-2"
        *ngFor="let nearMeGroup of nearMeLinks"
      >
        <a
          [href]="nearMeItem.link"
          class="group-link bztt-no-underline bztt-p-0"
          *ngFor="let nearMeItem of nearMeGroup"
        >
          {{ nearMeItem.text | translate }}
        </a>
      </div>
    </div>
  </div> -->
  <div class="bztt-mt-10 bztt-text-sm bztt-pb-5 md:bztt-pb-0">
    {{
      'landing.footer.allRightsReserved' | translate : { dateRange: '2022-' + currentYear }
    }}
  </div>
</footer>
