<div [class.bztt-mx-4]="!isModal" [class.md:bztt-mx-0]="!isModal">
    <div class="header-container">
        <h1 class="title">{{'termsConditions.header' | translate}}</h1>
        <div *ngIf="isModal" class='close-container'>
            <button class='close-button' (click)="close()">
                <a>Close</a>
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <div class="app-terms-data">
        <div class="terms-container">
            <nav>
                <ul class="nav-list">
                    <li *ngFor="let item of buttons">
                        <ng-container [ngSwitch]="isModal">
                            <button *ngSwitchCase="true" type="button" [class.active-button]="item === activeButton" class="button-link"
                                (click)="changeActiveTab(item)">
                                {{ item.label | translate }}
                            </button>
                            <button *ngSwitchDefault type="button" class="button-link" routerLinkActive="active-button" [routerLink]="item.link">
                                {{item.label | translate }}
                            </button>
                        </ng-container>
                    </li>
                </ul>
            </nav>
            <ng-container [ngSwitch]="isModal">
                <section *ngSwitchCase="true" [ngSwitch]="activeButton.value" class="terms-content">
                    <jarvis-ui-rules-policies-rules *ngSwitchCase="'rules'"></jarvis-ui-rules-policies-rules>
                    <jarvis-ui-rules-policies-privacy *ngSwitchCase="'privacy'"></jarvis-ui-rules-policies-privacy>
                    <jarvis-ui-rules-policies-cookies *ngSwitchCase="'cookies'"></jarvis-ui-rules-policies-cookies>
                </section>
                <section *ngSwitchDefault class="terms-content">
                    <router-outlet></router-outlet>
                </section>
            </ng-container>
        </div>
    </div>
</div>