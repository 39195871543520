import { Directive, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[serviceTypeActive]' })
export class ServiceTypeActiveDirective {

  router = inject(Router);
  currentRoute = inject(ActivatedRoute);

  constructor() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      console.log(event);
    });
  }
}