<div class="actions-container" [class.expanded]="isExpanded" [class.disabled]="messageActionButtonDisabled && messageActionType ==='icon'">
  <div class="sub-action bztt-flex bztt-flex-col bztt-w-full bztt-h-full bztt-rounded-lg">
    <textarea
      *ngIf="isExpanded"
      class='expanded-textarea bztt-bg-[#F1F1F1]'
      (focus)="toggleTextAreaFocus('focus')"
      (blur)="toggleTextAreaFocus('blur')"
      (keydown)="handleInputKeyPress($event)"
      #messageInput
      [placeholder]="_placeholder | translate"
      [formControl]="messageControl"
    ></textarea>
  
    <div
      [class.bztt-px-5]="isExpanded"
      [class.bztt-pb-2]="isExpanded"
      class="bztt-justify-between bztt-flex bztt-pt-2 lg:bztt-pb-2 bztt-flex-row bztt-items-center bztt-w-full"
      cdkOverlayOrigin
      #emojiOrigin="cdkOverlayOrigin"
    > 
      <div *ngIf="showActions || (isExpanded && isMobile)" class="bztt-flex bztt-row bztt-gap-1 bztt-mr-2">
        <mat-icon
          *ngIf="showActions"
          [matTooltip]="'messages.attachFile' | translate"
          [matTooltipDisabled]="isMobile"
          class="material-icons-outlined bztt-text-[#959595] bztt-cursor-pointer"
          (click)="fileInput.click();"
        >attach_file</mat-icon>

        <mat-icon *ngIf="showActions" class="emoji-picker material-icons-outlined bztt-cursor-pointer bztt-text-[#959595]" (click)="openEmojiPicker()">
          emoji_emotions
        </mat-icon>

        <mat-icon *ngIf="expandEnabled && isExpanded" class="md:bztt-hidden bztt-block material-icons-outlined bztt-text-[#959595]" (click)="toggleTextAreaExpand()">
          {{isExpanded ? 'close_fullscreen' : 'open_in_full'}}
        </mat-icon>
      </div>

      <mat-form-field class="fix-suffix bztt-bg-[#F1F1F1] bztt-w-[160px] bztt-rounded-lg" *ngIf="!isExpanded" [appearance]="'none'">
        <textarea
          [cdkTextareaAutosize]="!disableAutoHeight"
          [cdkAutosizeMinRows]="minRows"
          [cdkAutosizeMaxRows]="maxRows"
          (focus)="toggleTextAreaFocus('focus')"
          (blur)="toggleTextAreaFocus('blur')"
          (keydown)="handleInputKeyPress($event)"
          #messageInput
          matInput
          class="message-input bztt-jarvis-scroll bztt-pointer-events-none"
          [formControl]="messageControl"
        ></textarea>

        <span matSuffix *ngIf="expandEnabled && !isExpanded" class="bztt-h-full bztt-flex bztt-flex-row bztt-items-center bztt-justify-center bztt-px-2">
          <mat-icon class="bztt-block material-icons-outlined bztt-text-[#959595] bztt-text-[18px] bztt-h-[18px] bztt-w-[18px] bztt-leading-[1em]" (click)="toggleTextAreaExpand()">
              {{isExpanded ? 'close_fullscreen' : 'open_in_full'}}
          </mat-icon>
          <mat-icon class="bztt-cursor-pointer bztt-text-[#959595] bztt-text-[24px] bztt-h-[24px] bztt-w-[24px] bztt-leading-[1em]" *ngIf="editMode" (click)="cancelMessageEdit()">clear</mat-icon>
        </span>
      </mat-form-field>

      <div *ngIf="showMessageActionButton" [ngClass]="{'md:bztt-min-w-[100px] bztt-min-w-[40px]': messageActionType === 'text'}" class="bztt-pl-2 bztt-flex bztt-items-center bztt-justify-center">
        <ng-container [ngSwitch]="messageActionType">

          <mat-icon class="bztt-text-[#959595] bztt-cursor-pointer" (click)="editMode ? confirmMessageEditHandler() : sendMessageHandler()" *ngSwitchCase="'icon'">
            {{editMode ? 'done' : 'arrow_forward'}}
          </mat-icon>

          <button *ngSwitchDefault [disabled]="messageActionButtonDisabled" app-button class=" bztt-pl-1.5 md:bztt-pl-0 bztt-w-[35px] md:bztt-w-[90px] bztt-h-[35px]" color="accent" (click)="editMode ? confirmMessageEditHandler() : sendMessageHandler()">
            <span class="bztt-hidden md:bztt-block">{{ (editMode ? 'messages.edit' : 'messages.send') | translate}}</span>
            <mat-icon class="bztt-block md:bztt-hidden">{{editMode ? 'done' : 'arrow_forward'}}</mat-icon>                
          </button>
        </ng-container>
        
      </div>

    </div> 
  </div>

  <div *ngIf="!editMode && !isExpanded && uploadFiles.length > 0" class="sub-action bztt-w-full bztt-mt-2 md:bztt-mt-0">
    <div mouseScrollable class=" bztt-flex bztt-mb-2 md:bztt-gap-2 bztt-gap-3 bztt-jarvis-scroll bztt-overflow-x-auto bztt-pb-1 bztt-pt-3 bztt-touch-none">
      <div *ngFor="let file of uploadFiles; let i = index" class="bztt-border bztt-flex bztt-rounded-lg bztt-font-montserrat bztt-text-sm bztt-h-[56px] bztt-relative bztt-group bztt-select-none">
        
        <div
          (click)="removeFile(i)"
          class="bztt-absolute -bztt-right-[12px] -bztt-top-[12px] bztt-h-[24px] bztt-w-[24px] bztt-border bztt-bg-white bztt-rounded-full bztt-justify-center bztt-items-center bztt-cursor-pointer bztt-flex md:bztt-hidden md:group-hover:bztt-flex bztt-z-[1]"
        >
          <mat-icon class="bztt-text-[18px] bztt-h-[18px] bztt-w-[18px] bztt-text-[#717171]">close</mat-icon>
        </div>
        
        <ng-container [ngSwitch]="file.type.includes('image')">
          <div *ngSwitchCase="true" class="bztt-overflow-hidden bztt-w-[56px] bztt-rounded-lg bztt-pointer-events-none">
            <img *ngIf="file | ImagePreview | async as imageSrc" class="bztt-object-cover bztt-w-full bztt-h-full" [src]="imageSrc" />
          </div>

          <div *ngSwitchDefault class="bztt-my-auto bztt-mx-2 bztt-flex bztt-min-w-[10rem] bztt-max-w-[12rem]">
            <div class="bztt-border bztt-border-[#A1A1A1] bztt-rounded-lg bztt-mr-2 bztt-inline-flex bztt-items-center bztt-content-center">
              <mat-icon class="bztt-text-[32px] bztt-h-[32px] bztt-w-[32px] bztt-m-1">attach_file</mat-icon>
            </div>

            <div class="bztt-inline-flex bztt-flex-col bztt-justify-between bztt-overflow-hidden">
              <span class="bztt-text-sm bztt-font-semibold bztt-text-ellipsis bztt-whitespace-nowrap bztt-overflow-hidden">{{file.name}}</span>
              <span class="bztt-text-xs bztt-text-ellipsis bztt-whitespace-nowrap bztt-overflow-hidden">.{{file.name.split('.')[1] | uppercase}}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<input #fileInput multiple="true" hidden type="file" (change)="fileInputChange($event)" />

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="emojiOrigin"
  [cdkConnectedOverlayOpen]="emojiPickerOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayWidth]="100"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="closeEmojiPicker()"
>
  <emoji-mart [showPreview]="false" (emojiClick)="addEmoji($event)"></emoji-mart>
</ng-template>