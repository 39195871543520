<div class="pricing-page bztt-flex bztt-flex-col bztt-gap-5 md:bztt-gap-20 bztt-pt-32 bztt-w-full bztt-px-6 md:bztt-px-0">
  <!-- <jarvis-claim-offer-bar
    *ngIf="showOffer"
    (closeOffer)="showOffer = false"
  ></jarvis-claim-offer-bar> -->

  <div class="page-title bztt-w-full">{{'pricingPage.title' | translate}}</div>
  <jarvis-plans-preview
  class="bztt-w-full"
    [membershipsData]="membershipsData"
  ></jarvis-plans-preview>
</div>
<!-- <landings-pricing-faq class="bztt-w-full bztt-my-6"></landings-pricing-faq> -->
<jarvis-pricing-reviews
  class="bztt-w-full bztt-overflow-hidden"
></jarvis-pricing-reviews>
