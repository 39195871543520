import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth';
import { Component, OnInit, OnDestroy, Inject, inject } from '@angular/core';
import { JarvisHeaderService, LayoutService } from '@jarvis/ui';
import { Subject, of } from 'rxjs';
import { LinkUrls, LINK_URLS, BASE_URL, DOMAIN_COUNTRY } from '@jarvis/services';
import { HttpClient } from '@angular/common/http';
import { MessagesType, MESSAGES_TYPE } from '@jarvis/messaging';

@Component({
  selector: 'login-standalone-page',
  templateUrl: './login-standalone-page.component.html',
  styleUrls: ['./login-standalone-page.component.scss']
})
export class LoginStandalonePageComponent implements OnInit, OnDestroy {

  hash: string;
  destroy$ = new Subject<void>();
  private domainCountry = inject(DOMAIN_COUNTRY);
  
  constructor(
    private headerService: JarvisHeaderService,
    private layoutService: LayoutService,
    private authService: JarvisAuthService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LINK_URLS) private linkUrls: LinkUrls,
    private httpService: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(MESSAGES_TYPE) private messagesType: MessagesType,
  ) {
    this.layoutService.disableFooter();
    this.hash = this.route.snapshot.params.hash;

  }

  ngOnInit(): void {
    this.headerService.hideHeader();

    this.authService.createRegisterOrLoginSuccessStream().pipe(
      takeUntil(this.destroy$),
      switchMap((userData) => {
        if (this.hash) {
          return this.httpService.get(`${this.baseUrl}/invite/${this.hash}`).pipe(
            map((newBookingInfo) => ({
              userData,
              newBookingInfo
            }))
          );
        }

        return of({
          userData,
          newBookingInfo: null
        });
      })
    ).subscribe((data) => {

      if (data.newBookingInfo) {
        if (this.domainCountry === 'lt') {
          return this.router.navigate(['/visos-rezervacijos', 'detales', data.newBookingInfo._id]);
        } else {
          return this.router.navigate(['/bookings-list', 'detail', data.newBookingInfo._id]);
        }        
      }

      const user = data.userData;

      if (user.userType === 'user') return this.router.navigateByUrl('/bookings');
      if (user.userType === 'vendor') return location.replace(this.linkUrls.saas);

      return this.router.navigate([]);
    });
  }

  ngOnDestroy(): void {
    this.headerService.setDefaultHeader();
    this.layoutService.enableFooter();
    this.destroy$.next();
  }

}
