<jarvis-ui-modal-wrapper class="agreement-view modal-wrapper" [closeButton]="presentationType!='user'"
  (closed)="close()">
  <div class="main-containter d-flex flex-column justify-content-start overflow-hidden"
    [class.bztt-mt-6]="presentationType=='user'">
    <div
      class="bztt-z-10 bztt-absolute bztt-top-0 bztt-left-0 bztt-items-center bztt-bg-[#ffffff8f] bztt-w-full bztt-h-full bztt-flex bztt-flex-col bztt-justify-center"
      *ngIf="isLoading | async">
      <mat-progress-spinner mode="indeterminate" strokeWidth="2"></mat-progress-spinner>
    </div>

    <div class="bztt-hidden md:bztt-flex bztt-flex-col bztt-text-left controls bztt-gap-2">
      <div class="fs-3 fw-bold bztt-justify-start">
        {{
        'bookings.agreement.' +
        ( presentationType=='vendor'?
        (doc?'custom_title':'upload_title'):'userTitle') | translate
        }}
      </div>

      <div class="d-flex bztt-justify-start" *ngIf="doc">
        <div *ngIf="!data.disableUpload" class="d-flex back align-items-center me-3" (click)="doc=null">
          <mat-icon>file_upload</mat-icon><a>{{ 'bookings.agreement.upload' | translate }}</a>
        </div>
        <div *ngIf="doc != null" class="d-flex back align-items-center me-3" (click)="download()">
          <mat-icon>file_download</mat-icon><a class="fs-7">{{ 'bookings.agreement.download' | translate }}</a>
        </div>

        <div *ngIf="doc != null " class="d-flex back align-items-center me-3" [matMenuTriggerFor]="sendmenu">
          <mat-icon>link</mat-icon><a class="fs-7">{{ 'bookings.agreement.copyLink' | translate }}</a>
        </div>
        <div *ngIf="doc != null && isCustom && data.canChange" class="d-flex back align-items-center"
          (click)="removeFile()">
          <mat-icon>delete</mat-icon><a class="fs-7">{{ 'bookings.agreement.delete' | translate }}</a>
        </div>

      </div>
    </div>
    <input #uploader hidden accept="application/pdf" multiple type="file" (change)="onFileChange($event)" />
    <a #downloader [href]="doc" class="d-none"></a>
    <div class="md:bztt-mt-6"></div>
    <ng-container *ngIf="presentationType!='user' && doc">
      <div class="bztt-flex" [ngSwitch]="data.detailData?.options?.contractVendorSigned">
        <div *ngSwitchCase="true"
          class="bztt-text-sm md:bztt-text-base mt-3 col border d-flex align-items-start p-2 ms-5 me-5 rounded-6 custom-msg">
          {{ 'bookings.detail.successAddSignAction.message' | translate }}
        </div>
        <div *ngSwitchDefault
          class="bztt-text-sm md:bztt-text-base  mt-3 col border d-flex align-items-start p-2 ms-5 me-5 rounded-6 standard-msg">
          {{ 'bookings.agreement.custom_used' | translate }}
        </div>
      </div>

    </ng-container>
    <div id="mainview" class="mt-2 h-auto overflow-auto main-view" [ngSwitch]="doc == null">
      <div *ngSwitchCase="true" class="bztt-h-full bztt-flex bztt-flex-col bztt-items-center bztt-justify-center">
        <div class="bztt-py-6">{{ 'bookings.agreement.upload_text' | translate }}</div>

        <ng-container *ngFor="let tp of uploadType">
          <button app-button color="accent" class="bztt-w-80 bztt-mb-5" (click)="upload(tp)">
            <span>{{ ('bookings.agreement.upload_control.' + tp) | translate }}</span>
          </button>
        </ng-container>
      </div>

      <ng-container *ngSwitchDefault>
        <pdf-viewer *ngIf="doc" class="pdf-viewer" (after-load-complete)="isLoading.next(false)" [original-size]="false"
          [zoom-scale]="'page-width'" [src]="doc" [render-text]="false"></pdf-viewer>
      </ng-container>
    </div>

    <ng-container *ngIf="presentationType=='user'">
      <div
        class="button-container border-top d-flex pt-3 ps-4 pe-4 pb-4 pb-md-0 justify-content-center align-middle align-items-center">
        <button (click)="close()" app-button class="bztt-w-[150px] bztt-mr-2">
          <span>{{'bookings.agreement.back' | translate }}</span>
        </button>
        <button app-button [disabled]="true" color="accent" class="bztt-w-[200px]"
          (click)="close({state:'previewPay'})">
          <span>{{ 'bookings.agreement.pay' | translate }}</span>
        </button>
      </div>
    </ng-container>

    <div *ngIf="presentationType=='vendor'"
      class="button-container border-top d-flex pt-3 ps-4 pe-4 pb-4 pb-md-0 justify-content-center align-middle align-items-center"
      [class.doc-empty]="doc == null">
      <button *ngIf="doc" app-button class="bztt-mr-2 bztt-w-[180px]" (click)="close({state:'preview'})">
        <span>{{ ('bookings.agreement.' + ((isMobile$ | async)?'previewShort':'preview')) | translate }}</span>
      </button>

      <button *ngIf="doc && !data.detailData.options.contractVendorSigned" app-button class="bztt-mr-2 bztt-w-[180px]"
        [disabled]="data.detailData.options.contractVendorSigned" (click)="close({state:'addSign'})">
        <span>{{ ('bookings.agreement.' + ((isMobile$ | async)?'addSignShort':'addSign')) | translate }}</span>
      </button>

      <button *ngIf="doc != null" [disabled]="
          doc == null
        " app-button color="accent" class="bztt-w-[180px]" [matMenuTriggerFor]="sendmenu">
        <!-- </button> (click)="close({state:'send'})"> -->
        <span>{{ 'bookings.agreement.send' | translate }}</span>
      </button>

      <div *ngIf="doc != null" class="ms-1 d-block d-md-none" style="padding: 8px">
        <mat-icon [matMenuTriggerFor]="moremenu">more_horiz</mat-icon>
      </div>
    </div>
  </div>
</jarvis-ui-modal-wrapper>

<mat-menu #moremenu="matMenu">
  <button *ngIf="data.canChange" [disabled]="data.disableUpload" (click)="doc=null" mat-menu-item>
    {{ ('bookings.agreement.upload') | translate }}
  </button>

  <button *ngIf="doc != null" (click)="download()" mat-menu-item>
    {{ 'bookings.agreement.download' | translate }}
  </button>

  <button *ngIf="doc != null && isCustom && data.canChange" (click)="removeFile()" mat-menu-item>
    {{ 'bookings.agreement.delete' | translate }}
  </button>
  <button *ngIf="doc != null && isCustom" (click)="copy()" mat-menu-item>
    {{ 'bookings.agreement.copyLink4Customer' | translate }}
  </button>
  <button *ngIf="doc != null && isCustom" (click)="copy()" mat-menu-item>
    {{ 'bookings.agreement.copyLink4Colleague' | translate }}
  </button>
  <button (click)="copy()" mat-menu-item>
    {{ 'bookings.agreement.paymentLink' | translate }}
  </button>
</mat-menu>


<mat-menu #sendmenu="matMenu">
  <button mat-menu-item  (click)="copy()">
    {{ ('bookings.agreement.copyLink4Customer') | translate }}
  </button>
  <button mat-menu-item>
    {{ ('bookings.agreement.copyLink4Colleague') | translate }}
  </button>
  <button mat-menu-item>
    {{ ('bookings.agreement.paymentLink') | translate }}
  </button>

</mat-menu>
