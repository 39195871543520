<ng-container *ngIf="(currentChatroom$ | async) as chatroom">
    <div class="bztt-flex bztt-flex-row bztt-items-center bztt-pt-6 bztt-pb-4" *ngIf="isMobile$ | async">
        <a [routerLink]="['..']" class="bztt-border-0 bztt-pb-0"><mat-icon class="bztt-text-[40px] bztt-w-[40px] bztt-h-[40px]">chevron_left</mat-icon></a>
        <div class="profile-photo bztt-w-[52px] bztt-h-[52px]">
          <img class="" [src]="chatroom.users?.profilePhoto || '/assets/images/noUser.jpeg'" alt="profile-photo" />
        </div>
        
    </div>
    <div class="bztt-flex bztt-justify-between bztt-items-center md:bztt-border-b md:bztt-pb-2 md:bztt-pt-2 bztt-border-[#DCDCDC] bztt-w-full">
        <div>
        <h2 class="bztt-text-sm bztt-text-[#1F1F1F]">{{chatroom | ChatUserName}}</h2>
        <span class="service-list" *ngIf="messagesType === 'user'">
            <ng-container *ngIf="(uniqueServiceEvents$ | async) as serviceEvents">
                <a [routerLink]="[ event.slug || event._id ]" [relativeTo]="null" target="_blank" *ngFor="let event of serviceEvents; let i = index;">
                    {{chatroom.serviceBase | ServiceName:event._id}}
                    {{ i !== serviceEvents.length - 1 ? ', ' : ''}}
                </a>
            </ng-container>
            <ng-template #defaultLink>
                <a>{{chatroom.serviceBase?.brandName}}</a>
            </ng-template>
        </span>
       </div>
       <span
        *ngIf="{
          route: (route2Bookings | async),
          chatListingName: (chatroom.serviceBase | ChatListingName)
        } as infoButton"
        class="bztt-flex bztt-flex-row bztt-gap-2 bztt-items-center bztt-justify-center"
       >
          <messaging-phone-no-container [chatroom]="chatroom"></messaging-phone-no-container>        
          <a
            [class.bztt-pointer-events-none]="!infoButton.route"
            [class.bztt-cursor-pointer]="!!infoButton.route"
            [routerLink]="infoButton.route" 
            [state]="{ serviceBase: chatroom.serviceBase._id, find: infoButton.chatListingName}"
            class="bztt-border-b-0 bztt-pb-0 bztt-cursor-pointer"
          >
            <mat-icon>info</mat-icon>
          </a>
        </span>        
    </div>

    <!-- (click)="openBookingInfo.emit() *ngIf="(isMobile$ | async) !== true" -->
</ng-container>