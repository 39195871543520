<div class="bztt-flex bztt-w-full bztt-justify-center">
  <div
    class="faq-container bztt-max-w-[84rem] bztt-justify-center bztt-w-full bztt-flex bztt-flex-col"
  >
    <div class="bztt-max-w-[1000px]">
      <h2 class="title">{{ 'pricingPage.FAQ.title' | translate }}</h2>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let faq of faqConfiguration['vendor']">
          <mat-expansion-panel-header [collapsedHeight]="'70px'">
            <mat-panel-title class="faq-title">
              {{ faq.title | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="faq-description">{{ faq.description | translate }}</div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="other-questions-title">
        {{ 'pricingPage.FAQ.otherQuestions' | translate }}
      </div>
      <div class="other-questions-description">
        {{ 'pricingPage.FAQ.sendUsEmail1' | translate }}
        <a href="mailto: support@breezit.com">{{
          'pricingPage.FAQ.sendUsEmail2' | translate
        }}</a>
        {{ 'pricingPage.FAQ.sendUsEmail3' | translate }}
        <a target="_blank" href="https://calendly.com/d/d3w-c33-42j/intro-to-breezit">{{
          'pricingPage.FAQ.sendUsEmail4' | translate
        }}</a>.
      </div>
    </div>
  </div>
</div>
