import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'searchFilter'
})


export class SearchFilterPipe implements PipeTransform {
 transform(value: any, propNames: string[], args?: any): any {
        if(!value) return null;
        if(!args) return value;
  
        args = args.toLowerCase();
  
        return value.filter(function(data: any){
            return propNames.find(function(propName) {
              if (propName) 
               { 
                 const v = propName.includes('.')?data[propName.split('.')[0]][propName.split('.')[1]]:data[propName];
                 return (v && v.toLowerCase().includes(args));
               } 
             });  
        });
    }
}    
