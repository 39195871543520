<div
  *ngIf="data || cardType === 'loading'; else noData"
  (click)="cardClickHandle($event)"
  class="bztt-bg-white bztt-h-full bztt-border bztt-border-gray-300 bztt-rounded-xl bztt-overflow-hidden bztt-text-left bztt-flex bztt-flex-col"
>
  <div
    (click)="photoClickHandler($event)"
    class="photo-container bztt-w-full bztt-relative"
    [ngClass]="{
      'bztt-h-[274px]': !isMobile && cardType === 'venues-blog'
    }"
  >
    <div
      *ngIf="notAvailable"
      class="bztt-bg-white bztt-bg-opacity-70 bztt-w-full bztt-h-full bztt-absolute bztt-z-[2]"
    >
      <div class="bztt-relative">
        <div
          class="bztt-pointer-events-none bztt-absolute bztt-px-3 bztt-min-w-[155px] bztt-rounded-md bztt-flex bztt-items-center bztt-bg-[#6B6060] bztt-h-9 bztt-font-montserrat bztt-text-xs bztt-text-white bztt-left-4 bztt-top-4"
        >
          <span class="bztt-font-semibold">{{
            'listing.notAvailable.' + notAvailable | translate
          }}</span>
        </div>
      </div>
    </div>

    <ng-container [ngSwitch]="cardType">
      <a
        *ngSwitchCase="'partnerListCustomItem'"
        [href]="data.url"
        [target]="isMobile ? '_self' : '_blank'"
        class="bztt-p-0 gaListingClick"
        [ngClass]="{
          'bztt-h-[121px]': !isMobile && cardType === 'venues-blog'
        }"
      >
        <div
          class="bztt-flex bztt-justify-center bztt-items-center bztt-h-full bztt-bg-gray-300 ng-tns-c400-17 ng-star-inserted"
        >
          <img
            class="bztt-h-28 bztt-invert-[0.5] ng-tns-c400-17"
            [src]="defImageByCategory(data.type)"
          />
        </div>
      </a>
      <a
        *ngSwitchDefault
        [routerLink]="disableRedirect ? null : (data | Link : cardType)"
        [queryParams]="data | LinkQueryParams"
        [target]="isMobile ? '_self' : '_blank'"
        [class.card-click-link]="disableRedirect"
        class="bztt-p-0 gaListingClick"
        (click)="(data['customLike'] ? false : true)"
      >
        <ng-container [ngSwitch]="cardType">
          <div
            *ngSwitchCase="'loading'"
            class="bztt-object-cover bztt-w-full bztt-h-full bztt-bg-gray-300"
          ></div>
          <ng-container
            *ngSwitchCase="'planner'"
            class="bztt-object-cover bztt-w-full bztt-h-full bztt-bg-gray-300"
          >
            <div
              *ngIf="data['customLike']"
              class="bztt-flex bztt-justify-center bztt-items-center bztt-h-full bztt-bg-gray-300"
            >
              <img [src]="images?.[0]" class="bztt-h-28 bztt-invert-[0.5]" />
            </div>
            <jarvis-ui-marketplace-card-photo-swiper
              [swiperRefresh]="swiperRefresh"
              *ngIf="!data['customLike']"
              [images]="images"
              [title]="data.title"
            ></jarvis-ui-marketplace-card-photo-swiper>
          </ng-container>
          <ng-container *ngSwitchCase="'venues-blog'">
            <div class="bztt-flex bztt-flex-row bztt-w-full bztt-h-full">
              <jarvis-ui-marketplace-card-photo-swiper
                [ngClass]="{
                  'bztt-w-full': isMobile,
                  'bztt-w-[50%]': !isMobile
                }"
                [swiperRefresh]="swiperRefresh"
                [images]="images"
                [title]="data.title"
              ></jarvis-ui-marketplace-card-photo-swiper>
              <div
                *ngIf="!isMobile"
                class="bztt-p-4"
                [ngClass]="{
                  'bztt-w-full': isMobile,
                  'bztt-w-[50%]': !isMobile
                }"
              >
                <jarvis-ui-marketplace-card-venues-blog-info [data]="data">
                </jarvis-ui-marketplace-card-venues-blog-info>
              </div>
            </div>
          </ng-container>
          <!-- <img
          *ngSwitchDefault
          class="bztt-object-cover bztt-w-full bztt-h-full"
          [src]="mainImageSrc"
          fill
        /> -->
          <jarvis-ui-marketplace-card-photo-swiper
            *ngSwitchDefault
            [swiperRefresh]="swiperRefresh"
            [images]="images"
            [title]="data.title"
          ></jarvis-ui-marketplace-card-photo-swiper>
        </ng-container>
      </a>
    </ng-container>

    <div
      *ngIf="cardType !== 'loading'"
      class="bztt-pointer-events-none bztt-absolute bztt-top-0 bztt-w-full bztt-h-1/5"
      [ngClass]="{
        'bztt-bg-gradient-to-b bztt-from-black bztt-opacity-70':
          cardType !== 'venues-blog'
      }"
    ></div>

    <jarvis-ui-marketplace-card-tag-container
      [cardType]="cardType"
      [data]="data"
    ></jarvis-ui-marketplace-card-tag-container>

    <ng-container *ngIf="!hideLike">
      <div
        *ngIf="liked && cardType !== 'loading'"
        (click)="likeClicked($event)"
        [ngClass]="{
          'bztt-right-[52%]': !isMobile && cardType === 'venues-blog'
        }"
        class="gaLikeClick bztt-flex bztt-w-9 bztt-rounded-full bztt-bg-white bztt-justify-center bztt-text-[#ef5518] bztt-items-center bztt-absolute bztt-h-9 bztt-right-4 bztt-top-4 bztt-cursor-pointer bztt-z-[2]"
      >
        <mat-icon>favorite</mat-icon>
      </div>

      <div
        *ngIf="!liked && cardType !== 'loading'"
        (click)="likeClicked($event)"
        [ngClass]="{
          'bztt-right-[52%]': !isMobile && cardType === 'venues-blog'
        }"
        class="gaLikeClick bztt-flex bztt-w-9 bztt-rounded-full bztt-bg-white bztt-justify-center bztt-items-center bztt-absolute bztt-h-9 bztt-right-4 bztt-top-4 bztt-cursor-pointer bztt-z-[2]"
      >
        <mat-icon>favorite_outline</mat-icon>
      </div>
    </ng-container>

    <ng-container [ngSwitch]="status">
      <div
        *ngSwitchCase="'main'"
        class="bztt-flex bztt-items-center bztt-select-none bztt-gap-1 bztt-absolute bztt-h-9 bztt-left-4 bztt-top-4 bztt-text-white bztt-bg-[#2d3cc4] bztt-rounded-lg bztt-p-2 bztt-text-center bztt-font-montserrat bztt-font-semibold"
      >
        <mat-icon>check_circle_filled</mat-icon>
        <p class="bztt-m-0 bztt-text-sm">Main</p>
      </div>

      <div
        *ngSwitchCase="'make-main'"
        (click)="statusChange('make-main')"
        class="bztt-flex bztt-cursor-pointer bztt-items-center bztt-select-none bztt-gap-1 bztt-absolute bztt-h-9 bztt-left-4 bztt-top-4 bztt-text-[#2d3cc4] bztt-bg-white bztt-rounded-lg bztt-p-2 bztt-text-center bztt-font-montserrat bztt-font-semibold"
      >
        <mat-icon>radio_button_unchecked</mat-icon>
        <p class="bztt-m-0 bztt-text-sm">Make main</p>
      </div>

      <div
        *ngSwitchCase="'recommended'"
        class="bztt-flex bztt-items-center bztt-select-none bztt-absolute bztt-h-9 bztt-left-4 bztt-top-4 bztt-bg-[#ef5518] bztt-rounded-lg bztt-p-2 bztt-text-center bztt-font-montserrat-bold"
      >
        <p class="bztt-m-0 bztt-text-white bztt-text-sm">
          {{ 'listingsSearch.card.recomended' | translate }}
        </p>
      </div>

      <div
        *ngSwitchCase="'supported'"
        class="bztt-flex bztt-items-center bztt-select-none bztt-absolute bztt-h-9 bztt-left-4 bztt-top-4 bztt-bg-[#ebebeb] bztt-rounded-lg bztt-p-2 bztt-text-center bztt-font-montserrat-bold"
      >
        <p class="bztt-m-0 bztt-text-[#717171] bztt-text-sm">
          {{ 'listingsSearch.card.supported' | translate }}
        </p>
      </div>

      <div
        *ngSwitchCase="'reserved'"
        class="bztt-flex bztt-items-center bztt-select-none bztt-absolute bztt-h-9 bztt-left-4 bztt-top-4 bztt-bg-[#e5e7f8] bztt-rounded-lg bztt-p-2 bztt-text-center bztt-font-montserrat-bold"
      >
        <p class="bztt-m-0 bztt-text-[#2d3cc4] bztt-text-sm">
          {{ 'listingsSearch.card.reserved' | translate }}
        </p>
      </div>
    </ng-container>

    <div
      class="bztt-absolute bztt-right-4 bztt-bottom-4 bztt-flex"
      [ngClass]="{
        'bztt-right-[52%]': !isMobile && cardType === 'venues-blog'
      }"
    >
      <a
        *ngIf="data?.instagramLink"
        target="_blank"
        class="bztt-p-0 bztt-decoration-0 bztt-border-0 bztt-cursor-default"
      >
        <img
          class="bztt-h-9 bztt-w-9"
          src="assets/icons/instagram_icon_clr.svg"
        />
      </a>
      <span
        *ngIf="imageCount > 0 && cardType !== 'loading'"
        class="bztt-flex bztt-ml-1.5 bztt-pointer-events-none bztt-items-center bztt-gap-2 bztt-h-9 bztt-cursor-pointer bztt-select-none bztt-text-[#717171] bztt-bg-gray-100 bztt-rounded-lg bztt-p-2 bztt-text-center"
      >
        <mat-icon>photo_camera</mat-icon>
        <p class="bztt-m-0 bztt-font-montserrat bztt-font-semibold">
          {{ imageCount }}
        </p>
      </span>
    </div>

    <div
      *ngIf="requestSent"
      class="bztt-absolute bztt-px-3 bztt-w-full bztt-bottom-4 bztt-select-none"
    >
      <div
        class="bztt-flex bztt-items-center bztt-justify-center bztt-rounded-lg bztt-h-9 bztt-text-[#ef5518] bztt-bg-[#fbdcd0]"
      >
        <span class="bztt-text-sm">{{
          'listingsSearch.card.requestSent' | translate
        }}</span>
      </div>
    </div>
  </div>

  <a
    [routerLink]="disableRedirect ? null : (data | Link : cardType)"
    [queryParams]="data | LinkQueryParams"
    [target]="isMobile ? '_self' : '_blank'"
    (click)="dataClickHandler($event)"
    class="gaListingClick bztt-p-4 bztt-block bztt-border-none bztt-flex-grow"
    [class.bztt-pb-0]="data?.['partnerData']"
    [class.card-click-link]="disableRedirect"
  >
    <ng-container [ngSwitch]="cardType">
      <div *ngSwitchCase="'venues-blog'">
        <jarvis-ui-marketplace-card-venues-blog-info
          *ngIf="isMobile; else description"
          class="bztt-flex bztt-flex-col bztt-h-full"
          [isMobile]="true"
          [data]="data"
        >
          <ng-content select="[blogButtons]"></ng-content>
        </jarvis-ui-marketplace-card-venues-blog-info>
        <ng-template #description>
          <hr />
          <div class="bztt-text-sm bztt-font-sora bztt-pb-1.5 bztt-pt-4">
            {{ 'listing.about' | translate }}
          </div>
          <jarvis-read-more-link
            [text]="data.serviceBase.description.generalDescription"
            [maxHeight]="54"
            [fontSize]="'14px'"
            [lineHeight]="'130%'"
          >
          </jarvis-read-more-link>
        </ng-template>
      </div>
      <jarvis-ui-marketplace-card-planner-info
        *ngSwitchCase="'planner'"
        class="bztt-flex bztt-flex-col bztt-h-full"
        [data]="data"
      ></jarvis-ui-marketplace-card-planner-info>

      <a
        [href]="(data | Link : cardType) + (data | LinkQueryParams : true)"
        [target]="isMobile ? '_self' : '_blank'"
        *ngSwitchCase="'partnerList'"
        (click)="(data['customLike'] ? false : true)"
        class="bztt-flex bztt-flex-col bztt-h-full bztt-border-none"
      >
        <jarvis-ui-marketplace-card-partner-list-info
          [data]="data"
        ></jarvis-ui-marketplace-card-partner-list-info>
      </a>

      <a
        [href]="data.url"
        [target]="isMobile ? '_self' : '_blank'"
        *ngSwitchCase="'partnerListCustomItem'"
        class="bztt-flex bztt-flex-col bztt-h-full bztt-border-none"
      >
        <jarvis-ui-marketplace-card-partner-list-info
          [data]="data"
          [cardType]="cardType"
        ></jarvis-ui-marketplace-card-partner-list-info>
      </a>

      <jarvis-ui-marketplace-card-loading
        *ngSwitchCase="'loading'"
        class="bztt-flex bztt-flex-col bztt-h-full"
      ></jarvis-ui-marketplace-card-loading>

      <ng-container *ngSwitchDefault>
        <jarvis-ui-marketplace-card-default-info-new
          *ngIf="domainCountry === 'us'"
          class="bztt-flex bztt-flex-col bztt-h-full"
          [data]="data"
        >
          <ng-container *ngTemplateOutlet="bottomContent"></ng-container>
        </jarvis-ui-marketplace-card-default-info-new>

        <jarvis-ui-marketplace-card-default-info
          *ngIf="domainCountry === 'lt'"
          class="bztt-flex bztt-flex-col bztt-h-full"
          [data]="data"
        >
          <ng-container *ngTemplateOutlet="bottomContent"></ng-container>
        </jarvis-ui-marketplace-card-default-info>
      </ng-container>
    </ng-container>
  </a>
</div>

<ng-template #bottomContent>
  <ng-content select="[cardBottom]"></ng-content>
</ng-template>

<ng-template #noData>
  <div
    class="bztt-max-w-sm bztt-border bztt-border-gray-300 bztt-rounded-xl bztt-overflow-hidden bztt-min-h-[20rem]"
  >
    <div
      class="bztt-aspect-[1/0.85] bztt-w-full bztt-relative bztt-flex bztt-items-center bztt-justify-center bztt-min-h-[4rem] bztt-p-4"
    >
      <span>Could not load data</span>
    </div>

    <div class="bztt-p-4 bztt-min-h-[4rem]"></div>

    <div
      *ngIf="cardType === 'planner'"
      class="bztt-w-full bztt-px-4 bztt-min-h-[4rem]"
    ></div>
  </div>
</ng-template>
