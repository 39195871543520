import { SwiperModule } from 'swiper/angular';
import { PricingReviewsComponent } from './pages/saas-landing-pricing/components/pricing-reviews/pricing-reviews.component';
import { PricingFaqComponent } from './pages/saas-landing-pricing/components/pricing-faq/pricing-faq.component';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaasLandingComponent } from './saas-landing.component';
import { MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions, MatLegacyFormFieldModule as MatFormFieldModule, MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { LANDING_TYPE } from '../services';
import { ROUTES, RouterModule, Routes } from '@angular/router';
import { ButtonModule } from '@jarvis/ui';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { LandingsModule } from '../landings.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyMenuDefaultOptions as MatMenuDefaultOptions, MatLegacyMenuModule as MatMenuModule, MAT_LEGACY_MENU_DEFAULT_OPTIONS as MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/legacy-menu';
import { SaasLandingMainComponent } from './pages/saas-landing-main/saas-landing-main.component';
import { SaasLandingPricingComponent } from './pages/saas-landing-pricing/saas-landing-pricing.component';
import { PlansPreviewComponent } from './pages/saas-landing-pricing/components/plans-preview/plans-preview.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ClaimOfferBarComponent } from './pages/saas-landing-pricing/components/claim-offer-bar/claim-offer-bar.component';
import { MatRippleModule } from '@angular/material/core';
import { MembershipItemComponent } from './pages/saas-landing-pricing/components/plans-preview/components/membership-item/membership-item.component';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';

const MENU_DEFAULT_OPTIONS: Partial<MatMenuDefaultOptions> = {
  overlayPanelClass: 'bzt-menu-overlay'
};

const FORM_FIELD_DEFAULTS: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

const ROUTES_US: Routes = [
  {
    path: '',
    component: SaasLandingComponent,
    children: [
      {
        path: '',
        component: SaasLandingMainComponent
      },
      {
        path: 'photographers',
        component: SaasLandingMainComponent
      }
    ]
  }
];

const ROUTES_LT: Routes = [
  {
    path: '',
    component: SaasLandingComponent,
    children: [
      {
        path: '',
        component: SaasLandingMainComponent
      },
      {
        path: 'photographers',
        component: SaasLandingMainComponent
      },
      {
        path: 'pricing',
        component: SaasLandingPricingComponent
      }
    ]
  }
];

@NgModule({
  declarations: [
    SaasLandingComponent,
    SaasLandingMainComponent,
    SaasLandingPricingComponent,
    PlansPreviewComponent,
    PricingFaqComponent,
    PricingReviewsComponent,
    ClaimOfferBarComponent,
    MembershipItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    LayoutModule,
    ButtonModule,
    TranslateModule.forChild(),
    HttpClientModule,
    LandingsModule,
    MatSidenavModule,
    MatMenuModule,
    TranslateModule.forChild(),
    MatExpansionModule,
    SwiperModule,
    MatRippleModule
  ],
  providers: [
    TranslatePipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: FORM_FIELD_DEFAULTS
    },
    {
      provide: LANDING_TYPE,
      useValue: 'vendor'
    },
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: MENU_DEFAULT_OPTIONS
    },
    {
      provide: ROUTES,
      deps: [ DOMAIN_COUNTRY ],
      useFactory: (domainCountry) => domainCountry === 'lt' ? ROUTES_LT : ROUTES_US
    }
  ],
  exports: [
    SaasLandingComponent
  ]
})
export class SaasLandingModule { }
