import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'jarvis-overlay-container',
  templateUrl: 'overlay-container.component.html',
  standalone: true,
  imports: [
    CommonModule,
  ]
})

export class JarvisOverlayContainerComponent {
  
}
