<h2 class="bztt-font-maple-r bztt-text-2xl bztt-mb-2">{{'profiling.source.title' | translate}}</h2>

<span class="bztt-text-sm bztt-text-[#717171] bztt-mb-6">{{'profiling.bestFitting' | translate}}</span>

<ng-container *ngFor="let item of values; let i = index;">
    <button app-button (click)="selectValue(item.value)" class="bztt-mb-2" [ngClass]="{
            'bztt-mb-6': values.length - 1 === i,
            'selected-outline-accent': selectedValue === item.value
        }">
        {{item.label | translate }}
    </button>
</ng-container>

<button class="md:bztt-mt-auto bztt-mb-2 gaProfileCompleted" [disabled]="formControl.invalid" color="accent" (click)="next()" app-button>{{'profiling.next' | translate}}</button>
<mat-progress-bar mode="indeterminate" *ngIf="upload$ | HttpRequestLoading | async"  class='module-loading-indicator'></mat-progress-bar>
<span *ngIf="upload$ | HttpRequestError | async" class="bztt-text-xs bztt-text-center bztt-text-[#f44336]">{{'profiling.uploadError' | translate }}</span>
