import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { LayoutService } from '../services/layout.service';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[onlyMobile]'  })
export class OnlyMobileDirective implements OnInit, OnDestroy {

    isMobile$: Observable<boolean>;
    private destroy$ = new Subject<void>();

    constructor(
        private layoutService: LayoutService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) { }

    ngOnInit(): void {
        this.isMobile$ = this.layoutService.isMobileObserver;

        this.isMobile$.pipe(
            startWith(this.layoutService.isMobile),
            takeUntil(this.destroy$)
        ).subscribe(isMobileView => {
            this.toggleElement(isMobileView);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private toggleElement(showElement: boolean): void {
        this.viewContainer.clear();

        if (showElement) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
