import { 
    Directive,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyFormField as MatFormField, MAT_LEGACY_FORM_FIELD as MAT_FORM_FIELD } from '@angular/material/legacy-form-field';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import { JarvisMeasurementUnitService } from 'libs/services/src';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MinuteTimeValuePipe } from '../pipes/minute-time-value.pipe';

@Directive({
    selector: 'input[jarvisUiTimepicker]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimePickerInputDirective),
            multi: true
        }
    ]
})
export class TimePickerInputDirective implements ControlValueAccessor, OnInit, OnDestroy {

    onTouched: () => unknown;
    onChanged: (newValue: Date) => unknown;

    @HostBinding('readonly') readonly = 'readonly';

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onFocus = new EventEmitter();

    @Input() 
    set jarvisUiTimepicker(picker: any) {
        if (picker) {
            this._timepicker = picker;
            picker.registerInput(this);
        }
    }
    _timepicker: any;

    private _value: Date;
    private currentLocale = 'en-US';
    private destroy$ = new Subject<void>();

    constructor(
        // public formControl: NgControl,
        private elementRef: ElementRef<HTMLInputElement>,
        private renderer: Renderer2,
        private minuteTimeValuePipe: MinuteTimeValuePipe,
        @Optional() private meauserementUnitService: JarvisMeasurementUnitService,
        @Optional() @Inject(MAT_FORM_FIELD) private formField?: MatFormField
    ) { }

    ngOnInit(): void {
        if (this.meauserementUnitService) {
            this.meauserementUnitService.activeLocale$.pipe(
                takeUntil(this.destroy$)
            ).subscribe(locale => this.currentLocale = locale);
        }  
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    @HostListener('keydown', ['$event']) keydownEvent(event: KeyboardEvent) {
        event.preventDefault();
    }

    @HostListener('focus') focusEvent() {
        this.elementRef.nativeElement.blur();
        this.onFocus.emit();
    }

    get currentValue(): Date {
        return this._value;
    }

    writeValue(value: Date) {
        if (!value) {
            return;
        }

        const minutes = getHours(value) * 60 + getMinutes(value);
        const formatted = this.minuteTimeValuePipe.transform(minutes, this.currentLocale);
        this._value = value;

        this.renderer.setProperty(this.elementRef.nativeElement, 'value', formatted);
    }

    setDateFromMinutes(minutes: number): void {
        const minuteValue = minutes % 60;
        const hourValue = (minutes - minuteValue) / 60;
        const newDate = setHours(setMinutes(this._value || new Date(), minuteValue), hourValue);
        this.writeValue(newDate);
        this.onChanged(newDate);
    }

    registerOnChange(fn: () => void): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    getOverlayOrigin(): ElementRef {
        return this.formField ? this.formField.getConnectedOverlayOrigin() : this.elementRef;
    }

    getWidth(): number {
        return this.formField ?
            this.formField.getConnectedOverlayOrigin().nativeElement.clientWidth :
            this.elementRef.nativeElement.clientWidth;
    }
}
