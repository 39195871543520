<div *ngIf="userData$ | async as user" class="lg:bztt-pt-3 lg:bztt-w-[314px]">
  <div
    class="bztt-flex bztt-flex-col bztt-justify-center lg:bztt-items-center bztt-items-start lg:bztt-gap-4"
  >
    <img
      class="bztt-h-[90px] bztt-w-[90px] bztt-rounded-full bztt-hidden lg:bztt-flex bztt-object-cover"
      [src]="
        user?.profilePhoto ? user?.profilePhoto : 'assets/images/noUser.jpeg'
      "
    />

    <div
      class="bztt-text-[22px] bztt-font-montserrat bztt-font-semibold bztt-my-5 bztt-hidden lg:bztt-flex"
    >
      {{ user.name }} {{ user.surname }}
    </div>
    <!-- <mat-divider class="lg:bztt-hidden bztt-flex display bztt-w-full">
    </mat-divider> -->
    <div class="bztt-flex bztt-flex-col bztt-justify-center bztt-items-center">
      <ng-container [ngSwitch]="links[user.userType].booking.platform">
        <ng-container *ngSwitchCase="'marketplace'">
          <a
            class="bztt-border-0"
            [routerLink]="links[user.userType].booking.link"
          >
            <ng-container *ngTemplateOutlet="bookingButton; context: { user: user }"></ng-container>
          </a>
          <a
            class="bztt-border-0"
            [routerLink]="links[user.userType].message.link"
          >
            <ng-container *ngTemplateOutlet="messageButton; context: { user: user }"></ng-container>
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'saas'">
          <a
            class="bztt-border-0"
            [href]="linkUrls.saas + '/' + links[user.userType].booking.link"
          >
            <ng-container *ngTemplateOutlet="bookingButton; context: { user: user }"></ng-container>
          </a>
          <a
            class="bztt-border-0"
            [href]="linkUrls.saas + '/' + links[user.userType].message.link"
          >
            <ng-container *ngTemplateOutlet="messageButton; context: { user: user }"></ng-container>
          </a>
        </ng-container>
      </ng-container>
    </div>

    <mat-divider class="display bztt-w-full bztt-my-2"> </mat-divider>
    <mat-list class="bztt-w-full dropdown-list">
      <ng-container *ngFor="let link of links[(userData$ | async).userType].menu">
        <ng-container [ngSwitch]="link.platform === 'marketplace'">
          <a
            class="bztt-border-0 bztt-p-0"
            *ngSwitchCase="true"
            [routerLink]="link.link"
          >
            <mat-list-item>{{ link.label | translate }}</mat-list-item>
          </a>

          <a
            class="bztt-border-0 bztt-p-0"
            *ngSwitchDefault
            [href]="linkUrls.saas + '/' + link.link"
          >
            <mat-list-item>{{ link.label | translate }}</mat-list-item>
          </a>
        </ng-container>
      </ng-container>

      <!-- <a
        *ngIf="domainCountry === 'lt'"
        class="bztt-border-0 bztt-p-0"
        href="https://foxywedding.lt/auth/login"
      >
        <mat-list-item>Foxy Wedding savitarna</mat-list-item>
      </a> -->

      <!-- <mat-list-item
        class="bztt-cursor-pointer bztt-text-[15px]"
        (click)="navigateToSettings()"
        >{{ 'header.profileSettings' | translate }}</mat-list-item
      > -->
      <!-- <mat-list-item
        class="bztt-cursor-pointer"
        *ngIf="domainCountry != 'us'"
        (click)="changeLanguage()"
      >
        {{ 'header.language' | translate }}
      </mat-list-item> -->
    </mat-list>

    <mat-divider class="bztt-w-full bztt-my-2"> </mat-divider>

    <mat-list class="bztt-w-full bztt-cursor-pointer dropdown-list">
      <mat-list-item
        class="lg:bztt-mt-[-15px]"
        (click)="moveToSaas()"
        *ngIf="(userData$ | async).userType === 'user'"
        >{{ 'header.becomeSeller' | translate }}</mat-list-item
      >
      <mat-divider
        *ngIf="(userData$ | async).userType === 'user'"
        class="bztt-w-full bztt-my-2"
      >
      </mat-divider>

      <mat-list-item
        (click)="logout()"
        class="bztt-text-[15px] bztt-font-semibold lg:bztt-font-normal"
        >{{ 'header.logout' | translate }}</mat-list-item
      >
    </mat-list>
  </div>
</div>

<ng-template #bookingButton let-user="user">
  <button
    class="dropdown-button bztt-relative bztt-border-[#ef5518] border-solid bztt-bg-[#ef5518] bztt-text-white border-2 bztt-rounded-full bztt-px-4 bztt-h-10 lg:bztt-font-normal bztt-w-40 lg:bztt-w-60 lg:bztt-mb-3 bztt-text-[15px] bztt-font-semibold"
  >
    <span>
      {{ links[user.userType].booking.label | translate }}
    </span>
    <span
      *ngIf="notificationService.newBookingCount$ | async"
      class="counter-badge bookings"
      >{{
        (user.userType === 'vendor'
          ? bookingsCount$
          : notificationService.newBookingCount$
        ) | async
      }}</span
    >
  </button>
</ng-template>

<ng-template #messageButton let-user="user">
  <button
    class="dropdown-button bztt-relative bztt-bg-transparent bztt-border-[#ef5518] border-solid bztt-text-[#ef5518] border-2 bztt-rounded-full bztt-px-4 bztt-h-10 lg:bztt-font-normal bztt-w-40 lg:bztt-w-60 bztt-text-[15px] bztt-font-semibold"
  >
    <span>
      {{ links[user.userType].message.label | translate }}
    </span>
    <span
      *ngIf="notificationService.newMessagesCount$ | async"
      class="counter-badge messages"
      >{{ notificationService.newMessagesCount$ | async }}</span
    >
  </button>
</ng-template>
