import { Component, Inject, Input, OnInit } from '@angular/core';
import { ChatRoom } from '../../types/messaging.types';
import { MessagesType, MESSAGES_TYPE } from '../services/messages-type.token';
import { DOMAIN_COUNTRY } from '@jarvis/services';

@Component({
  selector: 'messaging-phone-no-container',
  templateUrl: './phone-no-container.component.html',
  styleUrls: ['./phone-no-container.component.scss']
})
export class PhoneNoContainerComponent implements OnInit {

  @Input()
  chatroom: ChatRoom;

  phoneNo: string | null;

  constructor(
    @Inject(MESSAGES_TYPE) public messagesType: MessagesType,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string,    
  ) { }

  ngOnInit(): void {
    this.phoneNo = this.getPhoneNo(this.chatroom);
  }

  private getPhoneNo(chatroom: ChatRoom): string {
    if (!chatroom) {
      return null;
    }

    if (this.messagesType === 'vendor') {
      const { serviceBase } = chatroom;
      const servicePhoneNo = serviceBase.contacts?.showPhone ? serviceBase.contacts.phoneNo : null;
      return servicePhoneNo;
    }

    if (this.messagesType === 'user') {
      const { users } = chatroom;
      const userProfile = users?.profile?.find((profile) => profile.main);

      // Temporary disabling of customer' phones for the U.S. (by A.A.)
      if (this.domainCountry==='us') return "+1 (213) 723-2796"
      
      const userPhoneNo = userProfile ? userProfile.phoneNo ?? null : null;
      return userPhoneNo;
    }

    return null;
  }

}
