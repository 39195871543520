import { Component, ViewEncapsulation, Inject, inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';

@Component({
  selector: 'app-referals',
  templateUrl: './referal.component.html',
  styleUrls: ['./referal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReferalEditComponent {
  public data: any;
  public i18nKey: string;

  /** Data structure description **/
  referals: UntypedFormArray = this.fb.array([]);

  domainCountry = inject(DOMAIN_COUNTRY);

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dt: any
  ) {
    this.data = this.dt.data;
    //  console.log(dt);
    this.i18nKey = dt.i18nKey;
    // console.log(this.i18nKey);
  }

  public canAddNew(): boolean {
    return (
      this.referals.length == 0 ||
      this.referals.at(this.referals.length - 1).valid
    );
  }

  public canInvite(): boolean {
    return (
      this.referals.length > 0 &&
      this.referals.at(this.referals.length - 1).valid
    );
  }

  public removeItem(idx) {
    this.referals.removeAt(idx);
  }

  public referalSelected(e, idx) {
    this.referals
      .at(idx)
      .patchValue(this.data.lst.find((itm) => itm.name == e.option.value));
  }

  public addReferal(value?: any) {
    const referalsForm: UntypedFormGroup = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.pattern('[- +()0-9]+')]],
    });
    this.referals.push(referalsForm);
    if (value) referalsForm.patchValue(value);
    else this.canAddNew();
  }

  public invite(): void {
    if (
      this.referals.length > 0 &&
      this.referals.at(this.referals.length - 1).invalid
    )
      this.referals.removeAt(this.referals.length - 1);
    this.close(this.referals.value);
  }

  close(val?: any): void {
    console.log(val);
  }

  ngOnInit(): void {
    if (this.data.item.referals)
      this.data.item.referals.forEach((itm) => this.addReferal(itm));
    else this.addReferal();
  }
}
