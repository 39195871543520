<jarvis-ui-modal-wrapper class="modal-wrapper large-modal" (closed)="close()">
  <ng-container *ngIf="!sentSuccessfully; else success">
    <div class="bztt-flex bztt-flex-col bztt-mt-4 md:bztt-max-w-[60%] bztt-mx-auto">
      <h1>{{'plannerPage.plannerItemAdded.saveToEmail' | translate}}</h1>

      <span class="bztt-mb-6">{{'likes.sendToEmail' | translate}}</span>

      <div class="bztt-mb-2" *ngFor="let control of emailControls.controls; let i = index">

        <mat-form-field class="bztt-w-full">
          <mat-label>{{"contacts.email" | translate }}</mat-label>
          <input autocomplete="email" matInput [formControl]="control"/>
          <mat-error *ngIf="control.hasError('required')">{{"errors.fieldRequired" | translate }}</mat-error>
          <mat-error *ngIf="control.hasError('email')">{{"errors.email" | translate }}</mat-error>
        </mat-form-field>

        <div class="bztt-mb-2" *ngIf="i > 0">
          <div (click)="removeEmail(i)" class="bztt-flex bztt-items-center bztt-cursor-pointer bztt-w-fit bztt-text-sm">
            <mat-icon>remove</mat-icon>
            <span>{{"likes.removeEmail" | translate}}</span>
          </div>
        </div>
      </div>

      <div class="bztt-mb-8 bztt-pt-4 bztt-border-t bztt-border-[#ddd]">
        <div (click)="addEmail()" class="bztt-flex bztt-items-center bztt-cursor-pointer bztt-w-fit bztt-text-sm">
          <mat-icon>add</mat-icon>
          <span>{{"likes.addAnotherEmail" | translate}}</span>
        </div>
      </div>


      <button [disabled]="loading || emailControls.invalid" (click)="submit()" class="bztt-mt-auto" app-button color="accent">{{'likes.continue' | translate}}</button>

      <div class='bztt-mt-8 bztt-mb-4 bztt-text-sm'>
        <span>
          {{'register.byContinuing' | translate}} Breezit
          <a class="bztt-font-normal" (click)="openTermsAndConditionsModal('rules')">
            {{'register.termsAndConditions' | translate}}
          </a>{{'register.and' | translate}}
          <a class="bztt-font-normal" (click)="openTermsAndConditionsModal('privacy')">
            {{'register.privacyPolicy' | translate}}
          </a>
        </span>
      </div>

      <div class='bztt-mb-4 bztt-text-sm'>
        <span>
          {{'likes.dontHaveAccount' | translate}}
          <a class="bztt-font-normal" (click)="signUp()">
            {{'likes.here' | translate}}
          </a>
        </span>
      </div>
    </div>
  </ng-container>
</jarvis-ui-modal-wrapper>

<ng-template #success>
  <div class='success-container md:bztt-h-[300px] bztt-flex bztt-flex-col bztt-justify-center'>
    <img class="bztt-mx-auto" src="assets/icons/ic_success_large.svg">
    <h1>{{ 'likes.success' | translate }}</h1>
    <div class='text-wrapper'>
        {{ 'likes.linkSentSuccessfully' | translate }}
    </div>
</div>
</ng-template>
