import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'marketplace-dropdown-wrapper',
  templateUrl: './dropdown-wrapper.component.html',
  styleUrls: ['./dropdown-wrapper.component.scss']
})
export class DropdownWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
