import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface SelectedService {
  category: string | null;
  subcategory: string | null;
}

@Injectable({providedIn: 'root'})
export class JarvisSelectedServiceService {

  private selectedServiceSource = new BehaviorSubject<SelectedService>({category: null, subcategory: null});
  selectedService$ = this.selectedServiceSource.asObservable();

  resetSelected(): void {
    this.selectedServiceSource.next({category: null, subcategory: null});
  }

  setSelected(category: string, subcategory: string | null = null): void {
    this.selectedServiceSource.next({category, subcategory});
  }
  
}