import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdvertServiceVariant, AdvertsPriceUnits, generateServiceDescriptions } from '@jarvis/services';
import { BookingDetailsType } from '@jarvis/types';
import { TranslateService } from '@ngx-translate/core';
import { ConnectableObservable, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BookingsDataService } from '../bookings.service';

@Pipe({
  name: 'ServiceDescriptions',
  pure: false,
  standalone: true
})

export class ServiceDescriptionsPipe implements PipeTransform {
  
  constructor(
    private translationService: TranslateService,
    private bookingsService: BookingsDataService,
    private currentRoute: ActivatedRoute
  ) {}

  transform(details: any, locale?: any, venueSeparateService?: any): Observable<string[] | null> {

    const eventDate = new Date();

    let rentAdditionalPriceUnit = null;
    let rentAdditionalFixedCost = null;
    let rentAdditionalUnitCost = null;
    
    if (venueSeparateService && venueSeparateService.qnt) {
      rentAdditionalPriceUnit = venueSeparateService.unit;

      if (venueSeparateService.unit === AdvertsPriceUnits.fixedFee || venueSeparateService.unit === AdvertsPriceUnits.startingPrice) {
        rentAdditionalFixedCost = venueSeparateService.price;
      }

      if (venueSeparateService.unit === AdvertsPriceUnits.currencyPerPerson) {
        rentAdditionalUnitCost = venueSeparateService.price;
      }
    }

    const priceInfo$ = of({
      priceUnit: details.unit,
      priceValue: details.price || details.serviceVariant?.getFinalUnitPrice(eventDate),
      tax: details.serviceVariant?.tax || (details.taxPercent ? details.taxPercent * 100 : null),
      serviceCharge: details.serviceVariant?.serviceCharge || (details.serviceChargePercent ? details.serviceChargePercent * 100 : null),
      type: details.serviceVariant?.type || details.type,
      excludedFromGlobalMinBudget: details.serviceVariant?.excludedFromGlobalMinBudget || details.excludedFromGlobalMinBudget || false,
      minBudget: details.serviceVariant?.getMinimalBudget(eventDate) || details.minBudget,
      rentAdditionalPriceUnit,
      rentAdditionalFixedCost,
      rentAdditionalUnitCost
    });

    const description$ = generateServiceDescriptions({
      priceInfo$,
      translationService: this.translationService,
      locale$: locale === 'lt-LT' ? of({
        currency: 'EUR',
        locale: 'lt-LT'
      }) : null
    });

    return description$;
  }
}