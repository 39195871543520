import { NgModule } from '@angular/core';
// import { HttpClientModule } from '@angular/common/http';
import { ServicesService } from './services/services.service';
import { CredentialsInterceptor } from './interceptors/credentials.interceptor';

@NgModule({
  imports: [
    // HttpClientModule
  ],
  providers: [
    CredentialsInterceptor,
    ServicesService
  ]
})
export class ServicesModule {}
