

<h2 class="bztt-font-maple-r bztt-text-2xl">{{'profiling.website.title' | translate}}</h2>

<mat-form-field class="bztt-w-full">
    <input #inputField (blur)="checkURLProtocol(inputField.value)" [formControl]="formControl" placeholder="{{'profiling.website.enterUrl' | translate}}" matInput />
    <mat-error>{{'profiling.website.enterCorrectUrl' | translate}}</mat-error>
</mat-form-field>

<button class="md:bztt-mt-auto bztt-mb-2" [disabled]="!formControl.value || formControl.invalid" (click)="next()" color="accent" app-button>{{'profiling.next' | translate}}</button>
<button class="bztt-mb-2" app-button (click)="skip()">{{'profiling.website.dontHaveWebsite' | translate}}</button>
                