<div
  class="pricing-plan-container bztt-mt-8 md:bztt-mt-0"
  [class.popular]="plan.mostPopular"
  [class.bztt-mt-12]="plan.mostPopular"
>
  <div class="pricing-top">
    <div *ngIf="plan.mostPopular" class="most-popular-label">
      {{ 'pricingPage.membershipItem.mostPopular' | translate }}
    </div>
    <div class="type">{{ plan?.name }}</div>
    <div class="price bztt-mt-6">
      {{ plan?.price[range] ? plan?.price[range] : plan?.price }}
    </div>

    <div
      class="bztt-flex bztt-justify-center bztt-items-center bztt-mb-4 md:bztt-hidden bztt-py-4"
    >
      <button
        app-button
        color="accent"
        class="get-started-button mat-button-base bztt-max-w-[200px]"
      >
        <a
          [routerLink]="'../../sign-up'"
          [queryParams]="{ source: 'pricing-page' }"
        >
          {{ 'pricingPage.membershipItem.getStarted' | translate }}
        </a>
      </button>
    </div>
  </div>

  <div class="pricing-plan-item bztt-bg-[#F9F9F9]">
    <span
      class="label bztt-text-[#1F1F1F] bztt-text-start bztt-block md:bztt-hidden"
      >{{ labels?.bestFor }}</span
    >
    <span
      class="row-value bztt-text-[#1F1F1F] bztt-text-start md:bztt-text-center"
      >{{ plan?.bestFor }}</span
    >
  </div>
  <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.minTerm
    }}</span>
    <span class="row-value">{{ plan?.minTerm }}</span>
  </div>
  <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.yearMonthPrice
    }}</span>
    <span class="row-value">{{ plan?.yearMonthPrice }}</span>
  </div>
  <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.yearDiscount
    }}</span>
    <span class="row-value">{{ plan?.yearDiscount }}</span>
  </div>
  <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.bookingCommission
    }}</span>
    <span class="row-value">{{ plan?.bookingCommission }}</span>
  </div>

  <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.listingCreation
    }}</span>
    <span class="row-value">
      <mat-icon *ngIf="!plan.listingCreation" style="color: red"
        >close</mat-icon
      >
      <mat-icon *ngIf="plan.listingCreation" style="color: rgb(3, 196, 3)"
        >check</mat-icon
      >
    </span>
  </div>
  <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.adsAndPromos
    }}</span>
    <span class="row-value">
      <mat-icon *ngIf="!plan.adsAndPromos" style="color: red">close</mat-icon>
      <mat-icon *ngIf="plan.adsAndPromos" style="color: rgb(3, 196, 3)"
        >check</mat-icon
      ></span
    >
  </div>
  <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.crmEnabled
    }}</span>
    <span class="row-value">
      <mat-icon *ngIf="!plan.crmEnabled" style="color: red">close</mat-icon>
      <mat-icon *ngIf="plan.crmEnabled" style="color: rgb(3, 196, 3)"
        >check</mat-icon
      ></span
    >
  </div>
  <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.conversation
    }}</span>
    <span class="row-value">
      <mat-icon *ngIf="!plan.conversation" style="color: red">close</mat-icon>
      <mat-icon *ngIf="plan.conversation" style="color: rgb(3, 196, 3)"
        >check</mat-icon
      ></span
    >
  </div>
  <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.paymentsEnabled
    }}</span>
    <span class="row-value">
      <mat-icon *ngIf="!plan.paymentsEnabled" style="color: red"
        >close</mat-icon
      >
      <mat-icon *ngIf="plan.paymentsEnabled" style="color: rgb(3, 196, 3)"
        >check</mat-icon
      ></span
    >
  </div>
  <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.cardFee
    }}</span>
    <span class="row-value">{{ plan?.cardFee }}</span>
  </div>
  <!-- <div class="pricing-plan-item">
    <span class="label bztt-text-start bztt-block md:bztt-hidden">{{
      labels?.bookingFee
    }}</span>
    <span class="row-value">{{ plan?.bookingFee }}</span>
  </div> -->

  <div
    class="bztt-justify-center bztt-items-center bztt-mb-4 md:bztt-flex bztt-hidden"
  >
    <button
      app-button
      [color]="plan?.mostPopular ? 'accent' : ''"
      class="get-started-button mat-button-base bztt-max-w-[200px]"
    >
      <a
        [routerLink]="'../../sign-up'"
        [queryParams]="{ source: 'pricing-page' }"
      >
        {{ 'pricingPage.membershipItem.getStarted' | translate }}
      </a>
    </button>
  </div>
</div>
