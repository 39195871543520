import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { JarvisAuthService } from '@jarvis/auth';

@Injectable({providedIn: 'root'})
export class LoginActivationGuard implements CanActivate {
    constructor(private authService: JarvisAuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.authService.isLoggedIn$.pipe(
            map(loggedIn => {
                // console.log(`Login status: ${loggedIn}`)
                if (loggedIn) {
                    return this.router.parseUrl('/');
                }

                return true;
            }),
            // tap(x => console.log(x))
        );
    }
}
