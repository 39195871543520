import { Pipe, PipeTransform } from '@angular/core';
import { AdvertsPriceUnits, ServiceTypes } from '@jarvis/services';
// import { ServiceTypes } from 'libs/services/src/lib/types/service-types.enum';

const UNITS_TO_EXPAND = [
  AdvertsPriceUnits.priceForDoubleRoom,
  AdvertsPriceUnits.priceForWhole,
];

const SERVICES_TO_EXPAND: string[] = [
  // ServiceTypes.venueStandalone,
  ServiceTypes.venueAccomodation,
  ServiceTypes.serviceCharge,
  ServiceTypes.globalMinimalBudget,
];

@Pipe({
  name: 'ServiceDescriptionUnit',
  pure: true,
  standalone: true,
})
export class ServiceDescriptionUnitPipe implements PipeTransform {
  transform(
    value: any,
    priceUnit: any,
    serviceType?: string,
    serviceChargeType?: string,
    customDescription?: string
  ): any {
    if (serviceType && SERVICES_TO_EXPAND.includes(serviceType)) {
      if (serviceType === ServiceTypes.serviceCharge) {
        return `${value}.${serviceChargeType}`;
      }
      if (serviceType === ServiceTypes.globalMinimalBudget) {
        return `${value}.${serviceChargeType}`;
      }
      return `${value}.${priceUnit}`;
    }

    if (UNITS_TO_EXPAND.includes(priceUnit)) {
      return `${value}.${priceUnit}`;
    }

    if (customDescription && !value?.includes('booking.defaultDescriptions'))
      return customDescription;

    return value;
  }
}
