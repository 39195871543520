<div class="small-calendar-container">
  <ng-container [ngTemplateOutlet]="currentView | async"></ng-container>
  <div *ngIf="isLoading" class="loading-container">
    <mat-progress-spinner
      strokeWidth="2"
      color="primary"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
</div>

<ng-template #calendarDayCell let-day="day" let-locale="locale">
  <div
    *ngIf="day.inMonth"
    class="calendarCell"
    [class.disabled]="
    (disableToday && day.isToday) ||
    (disableWeekdays && disableWeekdays.includes(day.day))
  "
    [class.dayDisabled]="
      (disablePastDays && day.isPast) ||
      (disableToday && day.isToday) ||
      (disableWeekdays && disableWeekdays.includes(day.day))
    "
    [ngClass]="day | CalendarClasses"
  >
    <div class="cellDate">
      {{ day.date | calendarDate : 'monthViewDayNumber' : locale }}
    </div>
    <div *ngIf="day | CalendarDiscount as discount" class="cellDiscount">
      {{ discount }}
    </div>
    <!-- <div *ngIf="day | CalendarMarkup as markup" class='cellMarkup'>
            {{markup}}
        </div> -->
  </div>
</ng-template>

<ng-template #monthView>
  <div class="calendar-controls">
    <div class="selector-container" (click)="previousMonth()">
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div #yearViewAnchor class="title" (click)="showYearView()">
      {{ viewDate | date : monthTitleFormat : undefined : locale | titlecase
      }}<mat-icon>expand_more</mat-icon>
    </div>
    <div class="selector-container" (click)="nextMonth()">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <mwl-calendar-month-view
    weekStartsOn="1"
    (dayClicked)="dayClicked($event.day)"
    [events]="allEvents | async"
    class="calendar"
    [cellTemplate]="calendarDayCell"
    [viewDate]="viewDate"
    [locale]="locale"
  >
  </mwl-calendar-month-view>
</ng-template>

<ng-template #monthsView>
  <div class="calendar-controls">
    <div class="selector-container" (click)="previousYear()">
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div #yearViewAnchor class="title" (click)="showMonthView()">
      {{ viewDate | date : yearViewFormat : undefined : locale | titlecase }}
      <mat-icon>expand_less</mat-icon>
    </div>
    <div class="selector-container" (click)="nextYear()">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <div class="months-view-container">
    <div
      (click)="selectMonth(i)"
      class="month-selection-item"
      [class.current]="(i | CurrentMonth) && (viewDate | CurrentYear)"
      *ngFor="let month of months | async; let i = index"
    >
      {{ month }}
    </div>
  </div>
</ng-template>

<ng-template #yearsView>
  <div class="calendar-controls">
    <div
      class="selector-container"
      (click)="previousYearsInterval(yearInterval.from)"
    >
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div class="title" (click)="showMonthsView()">
      {{ yearInterval.from }} – {{ yearInterval.to }}
      <mat-icon>expand_less</mat-icon>
    </div>
    <div
      class="selector-container"
      (click)="nextYearsInterval(yearInterval.to)"
    >
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <div class="months-view-container">
    <div
      (click)="selectYear(year)"
      class="month-selection-item"
      [class.current]="year | CurrentYear"
      *ngFor="let year of years | async"
    >
      {{ year }}
    </div>
  </div>
</ng-template>
