import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMessagesViewService {
    openChatView();
    openInformationView();
    closeMobileViews();

    currentChatroomId$: BehaviorSubject<string>;
}

export const MESSAGES_VIEW_SERVICE: InjectionToken<IMessagesViewService> = 
    new InjectionToken<IMessagesViewService>('MESSAGES_VIEW_SERVICE');