<jarvis-overlay
  [bottom]="0"
  [backdrop]="false"
  panelClass="topOverlay"
  type="global"
  (closed)="handleClose()"
>
  <div
    class="bztt-w-full bztt-flex bztt-justify-center bztt-mb-4 bztt-p-3"
    [@openClose]="isOpen ? 'open' : 'closed'"
    (@openClose.done)="animationFinish()"
  >
    <ng-content></ng-content>
  </div>
</jarvis-overlay>