import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'book-a-tour-summary',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ],
  templateUrl: 'book-a-tour-summary.component.html'
})

export class BookATourSummaryComponent {
  @Input() serviceName: string;
  @Input() dates: Date[];
}