<div
  class="categories-container bztt-flex bztt-justify-center bztt-items-center bztt-h-full bztt-w-full bztt-py-[78px] bztt-px-6"
>
  <div
    class="categories-inner-container bztt-max-w-[84rem] bztt-w-full bztt-flex bztt-flex-col bztt-gap-6 xl:bztt-px-9"
  >
    <div class="categories-section-title">
      <span class="bztt-text-[#EF5518]">
        {{ 'landing.homepageUplift.categories.title.1' | translate }}
      </span>
      <br class="bztt-hidden md:bztt-flex" />
      <span class="bztt-text-[#1F1F1F]">
        {{ 'landing.homepageUplift.categories.title.2' | translate }}
      </span>
    </div>

    <div class="bztt-text-[#6B6060] bztt-text-start bztt-mb-4 bztt-max-w-4xl">
      {{ 'landing.homepageUplift.categories.description' | translate }}
    </div>

    <div class="bztt-grid bztt-grid-cols-6 bztt-gap-4">
      <a
        [routerLink]="item.link"
        *ngFor="let item of categories; let i = index"
        class="bztt-h-[167px] md:bztt-h-80 bztt-max-h-80 bztt-overflow-hidden bztt-rounded-md bztt-relative bztt-cursor-pointer bztt-border-none bztt-p-0"
        [class.md:bztt-col-span-3]="i < 2"
        [class.bztt-col-span-6]="i === 0"
        [class.md:bztt-col-span-2]="i >= 2"
        [class.bztt-col-span-3]="i !== 0"
      >
        <img
          [src]="item.image"
          alt=""
          class="bztt-w-full bztt-brightness-75 bztt-h-full bztt-object-cover"
        />

        <div
          class="bztt-absolute bztt-left-3 bztt-bottom-3 md:bztt-left-5 md:bztt-bottom-5 bztt-flex bztt-flex-col bztt-gap-2"
        >
          <div
            class="bztt-text-start bztt-text-white bztt-text-lg md:bztt-text-4xl bztt-font-semibold"
          >
            {{ item.label | translate }}
          </div>
          <!-- <div class="bztt-hidden md:bztt-block bztt-text-start bztt-text-white bztt-font-semibold">
            {{ item.description | translate }}
          </div> -->
        </div>

        <!-- <div
          class="bztt-absolute bztt-right-3 bztt-top-3 md:bztt-right-5 md:bztt-top-5 bztt-w-16 bztt-h-16 md:bztt-h-28 md:bztt-w-28 bztt-rounded-full bztt-flex bztt-justify-center bztt-items-center bztt-flex-col bztt-p-1 bztt-border-[3px] bztt-border-solid bztt-border-[#5357F7]"
        >
          <div
            class="bztt-flex bztt-justify-center bztt-items-center bztt-flex-col bztt-rounded-full bztt-bg-[#5357F7] bztt-h-full bztt-w-full bztt-gap-1 bztt-h-full"
          >
            <div
              class="bztt-text-center bztt-text-white md:bztt-text-xl bztt-text-sm bztt-font-semibold bztt-leading-none"
            >
              {{ item.count | translate }}
            </div>
            <div
              class="bztt-text-center md:bztt-text-sm bztt-text-[10px] bztt-text-white bztt-font-semibold bztt-leading-none"
            >
              {{ item.countLabel | translate }}
            </div>
          </div>
        </div> -->
      </a>
    </div>
  </div>
</div>
