<h2>
    {{ 'common.breezitReviews' | translate }}
</h2>

<jarvis-reviews-review-summary
    [data]="data"
    [interactive]="false" class="bztt-border-b-0  bztt-pb-0"></jarvis-reviews-review-summary>
<div class="bztt-flex bztt-justify-end bztt-pb-4" style="border-bottom: 1px solid #ddd;">
    <button class="bztt-max-w-fit bztt-px-4" app-button (click)="editReviews()">
        {{ 'reviews.summary.manageReviews' | translate }}
    </button>   
</div>

<div class="review-actions">

    <div class='icon'>
        <img src="assets/icons/ic_reviews.svg" />
    </div>

    <div class='texts'>
        <span class='bolder'>
            {{ 'reviews.summary.getReviews' | translate }}
        </span>
        <span>
            {{ 'reviews.summary.getReviewsSubtext' | translate }}
        </span>
    </div>

    <div class="actions-buttons">
        <button app-button (click)="askForReview()">
            {{ 'reviews.summary.askForReview' | translate }}
        </button>
        <!-- <button app-button (click)="askForReview()">Upload review</button> 
        <button app-button (click)="editReviews()">
            {{ 'reviews.summary.manageReviews' | translate }}
        </button>-->
    </div>
</div>