<app-modal-wrapper class='modal-wrapper medium-modal' (closed)="close()" [closeButton]="closeButton">
    <app-card-slider class="no-border slider" [arrowIcons]="true" [noButtons]="isMobile$ | async">
        <app-card>
            <ng-container *ngTemplateOutlet="testTemplate"></ng-container>
        </app-card>
        <app-card>
            <ng-container *ngTemplateOutlet="testTemplate"></ng-container>
        </app-card>
        <app-card>
            <ng-container *ngTemplateOutlet="testTemplate"></ng-container>
        </app-card>
        <app-card>
            <ng-container *ngTemplateOutlet="testTemplate"></ng-container>
        </app-card>
        <app-card>
            <ng-container *ngTemplateOutlet="testTemplate"></ng-container>
        </app-card>
    </app-card-slider>

    <button app-button color="accent" (click)="nextSlide()">Next</button>
</app-modal-wrapper>

<ng-template #testTemplate>
    <div class="content">
        <!-- <video src="/assets/videos/test-tutorial-video.mp4" type="video/mp4" autoplay></video> -->
        <div class='tutorial-placeholder'>
            <span>Image</span>
        </div>
        <h1>Welcome to Breezit</h1>
        <div class="text">
            Tutorial step description. Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere laudantium voluptate sint, non asperiores cumque, id tempora accusamus sapiente maiores dignissimos dolorum ab quos debitis, necessitatibus dolor praesentium ad. Quibusdam?
        </div>
    </div>
</ng-template>