<section aria-describedby="introplan" class="intro">
  <h1 #introplan>{{ titleConfiguration.title | translate }}</h1>

  <p style="font-size: 21px; margin-top: 27px">
    {{ 'landing.saas.description' | translate }}
  </p>

  <button
    app-button
    class="vendor-hero-getstarted bztt-px-4"
    color="accent"
    (click)="handleVendorSignup('vendor-hero')"
  >
    {{ 'landing.saas.createPage' | translate }}
  </button>

  <span
    class="bztt-font-montserrat-bold bztt-mt-4 bztt-text-xs bztt-text-[#717171]"
  >
    {{ 'landing.saas.descriptionSmall' | translate }} 🌟
  </span>

  <landings-browser-window [class.big]="!isMobile" class="main-screenshot">
    <img [src]="'assets/images/' + titleConfiguration.topImage" />
  </landings-browser-window>
</section>

<section class="our-approach sections-container">
  <div class="filled"></div>
  <div class="border"></div>
  <div class="approach-container">
    <div
      class="approach-content bztt-flex bztt-flex-col bztt-gap-x-7 bztt-justify-center bztt-max-w-2xl bztt-text-center"
    >
      <h2 class="label bztt-mb-5 bztt-justify-center">
        {{ 'landing.saas.ourApproach.title' | translate }}
      </h2>
      <p class="description bztt-text-center">
        {{ 'landing.saas.ourApproach.description' | translate }}
      </p>
    </div>
  </div>
</section>

<section class="sections-container">
  <ng-container *ngFor="let section of sections; let i = index">
    <div [id]="section.id">
      <div class="text-container">
        <h2>{{ section.header | translate }}</h2>
        <p>{{ section.text | translate }}</p>
        <div>
          <div class="subtext" *ngFor="let subtext of section.subtexts">
            <mat-icon>check_circle</mat-icon>{{ subtext | translate }}
          </div>
        </div>
        <a
          [routerLink]="(vendorSignupLink | async).link"
          [queryParams]="{ source: 'learn-more' }"
          class="bztt-no-underline"
        >
          <div
            [ngClass]="section.id + ' learn-more mt-4'"
            (click)="handleVendorSignup('learn-more')"
          >
            {{ 'landing.saas.more' | translate }}
          </div>
        </a>
      </div>
      <div class="video-container">
        <landings-browser-window>
          <img [src]="section.videoPoster" alt="section-image" />
        </landings-browser-window>
      </div>
    </div>
  </ng-container>
</section>

<section class="sections-container bztt-w-full">
  <div class="compare-container bztt-gap-y-4">
    <div id="free_profile" class="with bztt-self-stretch">
      <div class="title-container">
        <h2>{{ 'landing.saas.profile.title' | translate }}</h2>

        <!-- <img _ngcontent-rrq-c242="" src="assets/images/bzt-logo.svg" /> -->
      </div>
      <ul class="bztt-flex bztt-flex-col bztt-gap-y-3">
        <li *ngFor="let text of comparePositives">
          <mat-icon>check_circle</mat-icon>
          <span>
            {{ text | translate }}
          </span>
        </li>
      </ul>
    </div>
    <div class="without bztt-self-stretch">
      <div class="title-container">
        <h2>{{ 'landing.saas.marketing.title' | translate }}</h2>
      </div>
      <ul class="bztt-flex bztt-flex-col bztt-gap-y-3">
        <li *ngFor="let text of compareNegatives">
          <mat-icon>check_circle</mat-icon>
          <span>
            {{ text | translate }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</section>

<section
  class="auders-photo sections-container bztt-flex bztt-flex-col bztt-justify-center bztt-items-center"
>
  <h2 class="label bztt-text-center bztt-px-5 bztt-justify-center">
    {{ 'landing.saas.audersSection.title' | translate }}
  </h2>

  <img
    class="bztt-rounded-full"
    [src]="
      domainCountry === 'us'
        ? 'assets/images/landing/jody_profile_image.png'
        : 'assets/images/landing/auders-photography.jpg'
    "
    alt="The Event Certificate"
  />

  <p class="subtitle bztt-justify-center bztt-text-center">
    {{ 'landing.saas.audersSection.audersPhoto' | translate }}
  </p>

  <p class="description bztt-text-center bztt-px-5 bztt-justify-center">
    {{ 'landing.saas.audersSection.description' | translate }}
  </p>
</section>

<section class="section-fullwidth">
  <landings-lets-breezit-section
    [type]="'vendor'"
    [contactUrl]="
      domainCountry === 'us'
        ? 'https://meetings-eu1.hubspot.com/arturas-asakavicius/intro-to-breezit'
        : 'https://meetings-eu1.hubspot.com/gintare-sudziute/breezit-skambutis'
    "
    (buttonClick)="handleVendorSignup('vendor-footer')"
  ></landings-lets-breezit-section>
</section>
