<div
  class="bztt-h-full bztt-w-full"
  [ngClass]="{'bztt-overflow-hidden': hideOverflow}"
  #contentContainer
  (touchend)="touchEndHandler()"
>
     <!-- <div 
        class="shadow-left"
        [@shadowOpacity]="{
            value: shadowLeftState || 'none',
            params: {
                opacity: shadowOpacity
            }
        }"
    ></div> -->
    <!-- <ng-container *ngFor="let card of content; let i = index;">
      <div 
          *ngIf="i >= currentCardIndex -1 && i <= currentCardIndex + 1"
          [@translateCard]="{
              value: card.position || 'void',
              params: {
                  animationDuration: animationDuration,
                  manualPosition: card.manualPosition
              }
          }"
          (@translateCard.start)="onTranslateCardStarted($event)"
          (@translateCard.done)="translateCardComplete($event)"
          (pan)="panHandler($event, i)"
          (panend)="panEndHandler($event, i)"
          class="bztt-col-start-1 bztt-row-start-1"
          [ngStyle]="{'order': content.length - i}"
      >
          <ng-template [cdkPortalOutlet]="card.portal"></ng-template>
      </div>
    </ng-container> -->
    <!-- <div
     
          [ngStyle]="{'z-index': content.length - i}"
        class="shadow-right"
        [@shadowOpacity]="{
            value: shadowRightState || 'none',
            params: {
                opacity: shadowOpacity
            }
        }"
    ></div> -->
    <div
      class="bztt-flex bztt-h-full bztt-gap-[var(--gap)]"
      [ngStyle]="{'--gap': gap || 0}"
      [ngClass]="{'bztt-transition-transform bztt-duration-500': currentPosition === 'auto'}"
      (pan)="panHandlerBlock($event)"
      (panend)="panEndHandlerBlock($event)"
      [@translateCard]="{
        value: currentPosition || 'void',
        params: {
            animationDuration: animationDuration,
            manualPosition: manualPosition,
            currentPositionIndex: currentCardIndex,
            gap: gap || '0px'
        }
    }"
    >
      <ng-container *ngFor="let card of content; let i = index;">
        <div class="bztt-h-full bztt-flex-shrink-0 bztt-flex-grow-0 bztt-basis-full">
          <ng-template [cdkPortalOutlet]="card.portal"></ng-template>
        </div>
      </ng-container>
    </div>
</div>