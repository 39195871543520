import { Pipe, PipeTransform } from '@angular/core';
import {UntypedFormGroup } from '@angular/forms';

@Pipe({
    name: 'StarRatingError',
    pure: false
})

export class StarRatingErrorPipe implements PipeTransform {
    transform(value:UntypedFormGroup, controlName: string): any {
        const control = value.get(controlName);

        if (!control || !control.touched) {
            return false;
        }
        // console.log(control.errors);
        return !!control.getError('min') ? true : false;
    }
}