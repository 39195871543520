<jarvis-ui-modal-wrapper
  (closed)="close()"
  [closeButton]="closeButton"
  class="modal-wrapper medium-modal"
>
  <h1 class="bztt-mb-8">{{ 'reviews.verify.title' | translate }}</h1>

  <div class="form-wrapper">
    <ng-container [ngTemplateOutlet]="firstScreen"></ng-container>
  </div>
</jarvis-ui-modal-wrapper>

<ng-template #firstScreen>
  <form
    [formGroup]="registerFormGroup"
    [class.breezit-vendor-sign-up]="userType === 'vendor'"
  >
<!--
   <mat-form-field appearance="outline">
      <mat-label>{{ 'register.yourEmail' | translate }}</mat-label>
      <input
        type="email"
        autocomplete="email"
        matInput
        formControlName="email"
      />
      <mat-error>{{ 'errors.' + (emailError$ | async) | translate }}</mat-error>
    </mat-form-field>
    [disabled]="registerFormGroup.invalid"
-->   
    <button
      
      class="signup1 gaEmailClicked"
      app-button
      style="margin-bottom: 15px"
      color="accent"
      (click)="continueWithEmail()"
    >
      <span>{{ 'reviews.verify.continueWithEmail' | translate }}</span>
    </button>

    <div class="or">
      <span>{{ 'register.or' | translate }}</span>
      <div class="line"></div>
    </div>

    <button
      app-button
      class="gaGoogleClicked"
      (click)="registerWithGoogle()"
      type="button"
    >
      <mat-icon svgIcon="google" prefix></mat-icon>
      <span>{{ 'reviews.verify.continueWithGoogle' | translate }}</span>
    </button>

    <button
      app-button
      type="button"
      class="gaFacebookClicked"
      style="margin-bottom: 15px"
      (click)="registerWithFacebook()"
    >
      <mat-icon class="fb-icon" svgIcon="facebook" prefix></mat-icon>
      <span>{{ 'reviews.verify.continueWithFacebook' | translate }}</span>
    </button>

  </form>
</ng-template>

