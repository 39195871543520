import { SocialAuthService } from '@abacritt/angularx-social-login';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser,
} from '@abacritt/angularx-social-login';
import {
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { JarvisAuthService } from '@jarvis/auth';
import { of, Subject } from 'rxjs';
import { JarvisTrackingService } from '@jarvis/services';

@Component({
  selector: 'jarvis-reviews-verify',
  templateUrl: './add-review-verify.component.html',
  styleUrls: ['./add-review-verify.component.scss'],
})
export class ReviewsVerifyComponent implements OnInit, OnDestroy {
  @Output() submitEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();

  private trackService = inject(JarvisTrackingService);

  private destroy$ = new Subject<void>();
  closeButton: any;

  registerFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
  });

  userType: any;

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private socialAuthService: SocialAuthService,
    private authService: JarvisAuthService
  ) {}

  close() {}

  registerWithFacebook(): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'Sign-up_click',
      data: {
        type: 'Facebook',
      },
    });
    
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .catch((e) => this.submitEvent.emit({ type: 'error', e }));
  }

  registerWithGoogle(): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'Sign-up_click',
      data: {
        type: 'Google',
      },
    });

    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .catch((e) => this.submitEvent.emit({ type: 'error', e }));
  }

  initSocialAuthStateChange() {
    this.socialAuthService.authState
      .pipe(
        takeUntil(this.destroy$),
        switchMap((user: SocialUser) => {
          if (user?.provider === 'FACEBOOK')
            return this.authService.facebookRegister(
              user.authToken,
              this.userType
            );
          if (user?.provider === 'GOOGLE')
            return this.authService.googleRegister(
              user.authToken,
              this.userType
            );

          return of(null);
        }),
        filter((info) => !!info)
      )
      .subscribe({
        next: () => {
          this.authService.registrationEvent$.next('success');
          this.authService.getCurrentUser();
          this.submitEvent.emit({ type: 'registered' });
        },
        error: ({ error }) => {
          this.authService.registrationEvent$.next('fail');
          this.registerFormGroup.get('email').setErrors({ emailTaken: true });
          this.submitEvent.emit({ type: 'error', error });
        },
      });
  }

  continueWithEmail() {
    this.submitEvent.emit({ type: 'email' });
  }

  ngOnInit(): void {
    this.initSocialAuthStateChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
