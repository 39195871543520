import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  JarvisMeasurementUnitModule,
  JarvisMeasurementUnitService,
} from '@jarvis/services';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'jarvis-marketplace-venues-blog-info-tags',
  templateUrl: 'venues-blog-info-tags.component.html',
  styleUrls: ['venues-blog-info-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [JarvisMeasurementUnitService],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    JarvisMeasurementUnitModule,
  ],
})
export class MarketplaceVenuesBlogInfoTagsComponent {
  @Input() listingData: any;

  private measurementUnitService = inject(JarvisMeasurementUnitService);

  activeCurrencyCode$ = this.measurementUnitService.activeCurrencyCode$;
  activeLocale$ = this.measurementUnitService.activeLocale$;

  get fakeResponseTime() {
    return 15 + (parseInt(this.listingData.serviceBase._id.slice(-2), 16) % 20);
  }
}
