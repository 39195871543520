import { Component } from '@angular/core';
import {UntypedFormControl } from '@angular/forms';
import { checkFormOrGroupInvalid } from '../../../../utils/profiler.utils';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';

@Component({
  templateUrl: './clients.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class ClientsComponent {

  values = [
    {
      label: 'profiling.clients.weddings',
      value: 'weddings'
    },
    {
      label: 'profiling.clients.familyEvents',
      value: 'familyEvents'
    },
    {
      label: 'profiling.clients.corporateEvents',
      value: 'corporateEvents'
    },
    {
      label: 'profiling.clients.partiesCelebrations',
      value: 'partiesCelebrations'
    },
    {
      label: 'profiling.other',
      value: 'other'
    }
  ];

  formControl:UntypedFormControl;
  selectedValues: string[] = [];

  constructor(
    private profilingFormService: JarvisProfilingFormService
  ) {
    this.formControl = this.profilingFormService.getStepControl('eventCategory');
    const valuesParsed: string = this.formControl.value;
    if (valuesParsed) {
      this.selectedValues = valuesParsed.split(',');
    }
  }

  selectValue(value: string): void {

    if (this.selectedValues.includes(value)) {
      const index = this.selectedValues.indexOf(value);
      this.selectedValues.splice(index, 1);
    } else {
      this.selectedValues.push(value);
    }

    this.formControl.setValue(this.selectedValues.join(','));
  }

  next(): void {
    const formValid = checkFormOrGroupInvalid(this.formControl);

    if (!formValid) {
      return;
    }

    this.profilingFormService.trackFieldCaptureEvent('event_category');
    this.profilingFormService.navigateToStep('experience');
  }
}
