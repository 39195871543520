<div class="bztt-flex bztt-justify-between bztt-mb-2">
  <p
    class="bztt-font-montserrat bztt-m-0 bztt-text-sm bztt-text-[#717171] bztt-whitespace-nowrap bztt-overflow-ellipsis bztt-overflow-hidden"
  >
    {{ ( (cardType=="partnerListCustomItem"?"categories.":"") + data.type) | translate }}
  </p>
  <div class="bztt-min-w-fit" *ngIf="data.rating != '-'">
    <div class="bztt-flex bztt-items-center">
      <span class="bztt-text-[#ef5518] bztt-mr-2">★</span>
      <span class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171]"
        >{{ data.rating }} ({{ data.ratingCount }})</span
      >
    </div>
  </div>
</div>

<div class="bztt-min-h-[18px] bztt-mb-1">
  <div *ngIf="data.address" class="bztt-flex bztt-text-[#717171]">
    <mat-icon class="bztt-text-xs bztt-h-3 bztt-w-3">location_on</mat-icon>
    <span
      class="bztt-font-montserrat bztt-text-xs bztt-h-4 bztt-text-ellipsis bztt-w-64 bztt-overflow-hidden bztt-whitespace-nowrap"
    >
      <!-- bztt-whitespace-nowrap bztt-overflow-hidden bztt-text-ellipsis bztt-h-4"> -->
      {{ data.address }}
    </span>
  </div>
  <div *ngIf="!data.address" class="bztt-h-4"></div>

  <div class="bztt-min-h-[4rem]">
    <h2
      class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222] card-title-overflow-elipses"
    >
      {{ data.title }}
    </h2>
  </div>
</div>

<div 
  class="bztt-flex bztt-gap-1 bztt-items-center bztt-mt-auto bztt-min-h-[44px]"
>
  <div *ngIf="cardType!='partnerListCustomItem'" class="bztt-flex-col">
    <div class="bztt-text-[#717171] bztt-pb-2">
      {{ 'listing.cart.priceTitle' | translate }}
      <span
        class="bztt-flex-initial bztt-justify-center bztt-border bztt-border-[#515151] bztt-pt-1 bztt-relative bztt-top-0.5 bztt-pl-0.5 bztt-pr-0.5 bztt-rounded-full"
        *ngIf="
          data?.venueInfo?.hasCatering ||
          data?.venueInfo?.hasDrinks ||
          data?.venueInfo?.hasAccomodation
        "
      >
        <mat-icon
          class="bztt-h-3.5 bztt-w-3.5 bztt-ml-px bztt-text-[12px]"
          *ngIf="data.venueInfo.hasCatering"
          >restaurant</mat-icon
        >
        <mat-icon
          class="bztt-h-3.5 bztt-w-3.5 bztt-text-[12px]"
          *ngIf="data.venueInfo.hasDrinks"
          >local_bar</mat-icon
        >
        <mat-icon
          class="bztt-h-3.5 bztt-w-3.5 bztt-text-[12px]"
          *ngIf="data.venueInfo.hasAccomodation"
          >king_bed</mat-icon
        >
      </span>
    </div>
    <ng-container [ngSwitch]="!!data.discountedPrice">
      <ng-container *ngSwitchCase="true">
        <span
          class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#ef5518]"
          >{{ data.discountedPrice }}</span
        >
        <span
          class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171] bztt-line-through"
          >{{ data.indicativePrice }}</span
        >
      </ng-container>

      <ng-container *ngSwitchDefault>
        <span
          class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222]"
          >{{ data.indicativePrice }}</span
        >
      </ng-container>
    </ng-container>

    <span class="bztt-font-montserrat bztt-text-sm bztt-text-[#717171]">{{
      data.priceUnit | PriceUnitLabel | async
    }}</span>
  </div>

  <div
    class="bztt-ml-auto bztt-flex bztt-flex-col bztt-items-start"
    *ngIf="data.venueInfo"
  >
    <div class="bztt-text-[#717171] bztt-pb-2">
      {{ 'listing.cart.guestCount' | translate }}
    </div>
    <span
      [ngSwitch]="data.venueInfo.filteredGuestCount > 0"
      class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222]"
      ><ng-container *ngSwitchCase="true">{{
        data.venueInfo.filteredGuestCount
      }}</ng-container>
      <ng-container *ngSwitchDefault>{{
        'listing.cart.guestCountUpTo' | translate : data.venueInfo
      }}</ng-container>
    </span>
  </div>
  <div
    class="bztt-ml-auto bztt-flex bztt-flex-col bztt-items-start"
    *ngIf="data?.priceAddition?.hoursIncluded"
  >
    <div class="bztt-text-[#717171] bztt-pb-2">
      {{ 'listing.cart.hoursIncluded' | translate }}
    </div>
    <span class="bztt-font-montserrat-bold bztt-text-base bztt-text-[#222222]">
      {{
        'listing.cart.hoursIncludedVal' | translate : data.priceAddition
      }}</span
    >
    <!--    <mat-icon matTooltip="Included in price" class="bztt-mr-2" *ngIf="data.venueInfo.hasCatering">restaurant</mat-icon>
      <mat-icon matTooltip="Included in price" class="bztt-mr-2" *ngIf="data.venueInfo.hasDrinks">local_bar</mat-icon>
      <mat-icon matTooltip="Included in price" *ngIf="data.venueInfo.hasAccomodation">king_bed</mat-icon>-->
  </div>
</div>
<!--
    bztt-border-y bztt-border-gray-200


  -->
<div
  *ngIf="data.partnerData?.stats"
  class="bztt-flex bztt-flex-col bztt-bg-[#FFF7F3] bztt-mt-4 bztt-m-[-18px] bztt-px-[18px]"
>
  <div class="bztt-flex bztt-flex-row bztt-h-20 bztt-justify-between">
    <div
      *ngFor="let stat of stats"
      class="bztt-flex bztt-flex-col bztt-justify-between bztt-py-4"
    >
      <div class="bztt-text-xl bztt-font-semibold bztt-leading-none">
        {{ data.partnerData?.stats[stat.param] }}
      </div>
      <div class="bztt-flex bztt-justify-center bztt-items-end bztt-gap-1">
        <mat-icon
          class="bztt-h-[16px] bztt-text-sm bztt-w-[16px] bztt-text-[#6B6060]"
          >{{ stat.icon }}</mat-icon
        ><span
          class="bztt-text-xs bztt-text-[#6B6060] bztt-font-medium bztt-leading-none"
          >{{ 'partnersListPreview.stats.' + stat.label | translate }}</span
        >
      </div>
    </div>
  </div>

  <div class="bztt-mt-2 bztt-mb-6">
    <mat-button-toggle-group
      class="bztt-h-8 bztt-w-full"
      [value]="data.partnerData?.isVisible"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      (change)="handleVisibilityChange($event)"
    >
      <mat-button-toggle class="bztt-w-32 left bztt-flex-1" [value]="true">
        <mat-icon class="bztt-mr-2">visibility</mat-icon>
        <span>{{
          (data.partnerData?.isVisible
            ? 'partnersListPreview.published'
            : 'partnersListPreview.publish'
          ) | translate
        }}</span>
      </mat-button-toggle>
      <mat-button-toggle class="bztt-w-32 right bztt-flex-1" [value]="false">
        <mat-icon class="bztt-mr-2">visibility_off</mat-icon>
        <span>{{
          (data.partnerData?.isVisible
            ? 'partnersListPreview.hide'
            : 'partnersListPreview.hidden'
          ) | translate
        }}</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <!--
  <div class="bztt-flex bztt-justify-between bztt-my-4">
    <div>Partner status</div>
    <div>{{ data.partnerData.status }}</div>
  </div> -->
</div>
