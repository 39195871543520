import { Pipe, PipeTransform } from '@angular/core';
import { ExtractedServices } from '../services/pricing-calculator';

@Pipe({
  name: 'IncludedServices',
  pure: false
})
export class JarvisServicesIncludedServicesPipe implements PipeTransform {
  transform(services: ExtractedServices): any {
    const flattened = [...services.main, ...services.additional];

    return flattened.filter((service) => service.included);
  }
}
