
import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PageChangeEvent } from './paginator.interface';
import { JarvisTrackingService } from '@jarvis/services';

@Component({
  selector: 'jarvis-ui-paginator',
  templateUrl: 'paginator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [':host { display: block; }'],
})
export class JarvisUiPaginatorComponent implements OnInit, OnDestroy {
  @Output() pageChange = new EventEmitter<PageChangeEvent>();

  @Input()
  get pageIndex(): number {
    return this._pageIndex;
  }
  set pageIndex(value: NumberInput) {
    this._pageIndex = Math.max(coerceNumberProperty(value), 0);
  }
  private _pageIndex = 0;

  @Input()
  get length(): number {
    return this._length;
  }
  set length(value: NumberInput) {
    this._length = coerceNumberProperty(value);
    this.calculateShownPageIndexes();
    // this.setPage(0);
  }
  private _length = 0;

  @Input()
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: NumberInput) {
    this._pageSize = Math.max(coerceNumberProperty(value), 0);
    // this._updateDisplayedPageSizeOptions();
  }
  private _pageSize = 10;

  @Input()
  get maxPageButtons(): number {
    return this._maxPageButtons;
  }
  set maxPageButtons(value: NumberInput) {
    this._maxPageButtons = Math.max(coerceNumberProperty(value), 0);
  }
  private _maxPageButtons = 5;

  get maxPageIndex(): number {
    return Math.ceil(this.length / this.pageSize) - 1;
  }

  @Input() i18nKey: string = 'filters';

  shownPageIndexes: number[] = [];

  get lessPagesVisible(): boolean {
    if (!this.shownPageIndexes.includes(0)) {
      return true;
    }
    return false;
  }

  get morePagesVisible(): boolean {
    if (!this.shownPageIndexes.includes(this.maxPageIndex)) {
      return true;
    }
    return false;
  }

  private trackService = inject(JarvisTrackingService);

  constructor() {}

  ngOnInit() {
    // this.shownPageIndexes = new Array(this.maxPageButtons).fill(null).map((_, index) => index);
    this.calculateShownPageIndexes();
  }

  ngOnDestroy(): void {}

  nextPage(): void {
    if (this.pageIndex === this.maxPageIndex) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex + 1;

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'page_click',
      data: {
        page_number: this.pageIndex + 1,
        type: 'page_next',
      },
    });

    this.calculateShownPageIndexes();
    this.emitPageChangeEvent(previousPageIndex);
  }

  previousPage(): void {
    if (this.pageIndex === 0) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex - 1;

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'page_click',
      data: {
        page_number: this.pageIndex + 1,
        type: 'page_back',
      },
    });

    this.calculateShownPageIndexes();
    this.emitPageChangeEvent(previousPageIndex);
  }

  setPage(pageIndex: number): void {
    if (pageIndex < 0 || pageIndex > this.maxPageIndex) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = pageIndex;

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'page_click',
      data: {
        page_number: this.pageIndex + 1,
        type: 'page_number',
      },
    });

    this.calculateShownPageIndexes();
    this.emitPageChangeEvent(previousPageIndex);
  }

  private calculateShownPageIndexes(): void {
    const pageButtonsByLength = Math.ceil(this._length / this._pageSize);
    const maxPageButtons = Math.min(this.maxPageButtons, pageButtonsByLength);
    const middlePointIndex = Math.floor(maxPageButtons / 2);

    const newIndexes = new Array(maxPageButtons).fill(null).map((_, index) => {
      if (this.maxPageButtons - middlePointIndex > this.pageIndex) {
        return index;
      }

      if (this.pageIndex > this.maxPageIndex - middlePointIndex) {
        return this.maxPageIndex - (maxPageButtons - 1) + index;
      }

      const differenceBetweenMiddleAndCurrent = middlePointIndex - index;
      return this.pageIndex - differenceBetweenMiddleAndCurrent;
    });

    this.shownPageIndexes = newIndexes;
  }

  private emitPageChangeEvent(previousPageIndex): void {
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
      previousPageIndex,
    });
  }
}
