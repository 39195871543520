<div
  class="homepage-reviews-container bztt-h-full bztt-w-full bztt-justify-center bztt-flex"
>
  <div
    class="bztt-max-w-[84rem] reviews-inner-container bztt-flex bztt-flex-row bztt-justify-center bztt-gap-12 xl:bztt-px-9"
  >
    <div class="reviews-title">
      {{ 'landing.homepageUplift.reviews.title' | translate }}
    </div>
    <div class="swiper-reviews-container bztt-max-w-[500px] bztt-max-h-[420px]">
      <swiper [config]="reviewsConfig" class="swiper-reviews">
        <ng-template swiperSlide *ngFor="let review of reviews">
          <div
            (click)="
              trackService.handleEvent({
                eventName: 'review_card_click',
                trackers: ['amplitude', 'mixpanel']
              })
            "
            class="homepage-review-container bztt-w-full bztt-h-full bztt-items-start bztt-justify-between bztt-flex bztt-flex-col bztt-p-5 md:bztt-p-10 bztt-gap-6"
          >
            <div class="bztt-text-start bztt-whitespace-pre-wrap">
              {{ review.description | translate }}
            </div>

            <div class="bztt-flex bztt-flex-col bztt-gap-6">
              <div class="bztt-font-semibold bztt-text-start">
                {{ review.name | translate }}
              </div>
              <img
                class="bztt-h-[70px] bztt-w-[70px] bztt-rounded-full bztt-object-cover"
                [src]="
                  '/assets/images/landing/homepage-uplift/reviews/' +
                  review.image
                "
              />
            </div>
          </div>
        </ng-template>
      </swiper>
      <div
        class="bztt-mt-5 bztt-flex bztt-flex-row bztt-justify-start bztt-gap-6"
      >
        <div
          class="slide-counter desktop bztt-flex bztt-flex-row bztt-justify-start bztt-gap-[5px]"
        >
          <div class="current-slide">
            {{ swiper?.swiperRef.realIndex + 1 | number : '2.0' }}
          </div>
          <div class="total-slides">
            / {{ (swiper?.activeSlides | async)?.length | number : '2.0' }}
          </div>
        </div>
        <div
          class="navigation-buttons bztt-flex bztt-flex-row bztt-justify-start bztt-gap-x-2 bztt-items-center"
        >
          <button
            class="swiper-button-prev bztt-static bztt-mt-0 after:bztt-content-none"
            [class.disabled]="swiper?.swiperRef?.isBeginning"
            mat-fab
            (click)="swipePrev()"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>

          <div
            class="slide-counter mobile bztt-flex bztt-flex-row bztt-justify-start bztt-gap-[5px]"
          >
            <div class="current-slide">
              {{ swiper?.swiperRef.realIndex + 1 | number : '2.0' }}
            </div>
            <div class="total-slides">
              / {{ (swiper?.activeSlides | async)?.length | number : '2.0' }}
            </div>
          </div>

          <button
            class="swiper-button-next bztt-static bztt-mt-0 after:bztt-content-none"
            [class.disabled]="swiper?.swiperRef?.isEnd"
            mat-fab
            (click)="swipeNext()"
          >
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
