import { Pipe, PipeTransform } from '@angular/core';
import { ExtractedServices } from '../services/pricing-calculator';

@Pipe({
  name: 'Taxes',
  pure: false
})
export class JarvisServicesTaxPipe implements PipeTransform {
  transform(services: ExtractedServices, date?: Date): number {
    const flattened = [...services.main, ...services.additional];
    return flattened
      .filter((service) => service.included)
      .reduce((total, curr) => {
        // const tax = curr.getFinalPrice(date || new Date()) * (1 + curr.serviceCharge / 100) * (curr.tax / 100);
        const tax = curr.getTax(date);
        total += tax;
        return total;
      }, 0);
  }
}
