import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdvertsPriceUnits } from '@jarvis/services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const advertsPriceUnitsUnitLabels = [
    /* {
        label: 'units.fixedPrice',
        value: AdvertsPriceUnits.fixedFee
    }, */
    /* {
        label: 'units.percentPrice',
        value: AdvertsPriceUnits.percentageFee
    }, */
    /* {
        label: '/ units.unit',
        value: AdvertsPriceUnits.currencyPerUnit
    },
    {
        label: '/ units.person',
        value: AdvertsPriceUnits.currencyPerPerson
    }, */
    /* {
        label: 'units.hour',
        value: AdvertsPriceUnits.currencyPerHour
    }, */
    {
        label: '/ units.weight',
        value: AdvertsPriceUnits.currencyPerWeight
    },
    {
        label: '/ units.distance',
        value: AdvertsPriceUnits.currencyPerDistance
    }
    /* {
        label: '/ hour',
        value: AdvertsPriceUnits.hourlyPrice
    }, */
    /* {
        label: 'units.priceForWhole',
        value: AdvertsPriceUnits.priceForWhole
    },
    {
        label: 'units.priceForDoubleRoom',
        value: AdvertsPriceUnits.priceForDoubleRoom
    },
    {
        label: 'units.priceFrom',
        value: AdvertsPriceUnits.priceFrom
    },
    {
        label: 'units.priceTo',
        value: AdvertsPriceUnits.priceTo
    } */
];

@Pipe({
    name: 'PriceUnitLabel',
    standalone: true
})
export class PriceUnitLabelPipe implements PipeTransform {
    constructor(
        private translateService: TranslateService
    ) {}

    transform(value: AdvertsPriceUnits): Observable<string> {
        if (!value) {
            return of(null);
        }
        const template = advertsPriceUnitsUnitLabels.find(x => x.value === value);

        if (!template) {
            return of('');
        }

        const splitTemplate = template.label.split('/ ');

        if (splitTemplate.length > 1) {
            return this.translateService.get(splitTemplate)
                .pipe(map((values => `${values[splitTemplate[0]]}/ ${values[splitTemplate[1]]}`)));
        }

        return this.translateService.get(splitTemplate[0]);
    }
}
