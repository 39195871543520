<div [@slideContent]="animationState" (@slideContent.done)="close()">
    <app-dropdown-wrapper>
        <a (click)="navigateToSettings()">{{'header.profileSettings' | translate}}</a>
        <a (click)="openDemoRequest()">{{'header.requestDemo' | translate}}</a>
        <a [routerLink]="domainCountry === 'lt'?'/taisykles':'/rules-policies'">{{'header.rulesPolicies' | translate}}</a>
        <ng-container *ngIf="domainCountry === 'lt'">
          <!-- <a target="_blank" href="https://foxywedding.lt/auth/login">{{'header.foxySelfService' | translate}}</a> -->
          <a target="_blank" href="https://foxyevents.crunch.help/lt/breezit-paslaugu-teikejams">{{'header.helpCenter' | translate}}</a>
        </ng-container>
        <a (click)="moveToMarketplace()">{{'header.becomeBuyer' | translate}}</a>
        <a (click)="logout()">{{'header.logout' | translate}}</a>
    </app-dropdown-wrapper>
</div>