import { Inject, Injectable, Type } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { CONTACT_FORM_COMPONENT } from '../contact-form/contact-form.token';

const MOBILE_WIDTH = 812;

@Injectable()
export class LandingDialogService {
    isMobile = false;
    private mobileBreakpointObserver = this.breakpointObserver.observe([`(max-width: ${MOBILE_WIDTH}px)`]);

    constructor(
        private dialogService: MatDialog,
        private breakpointObserver: BreakpointObserver,
        @Inject(CONTACT_FORM_COMPONENT) private contactsForm: Type<unknown>
    ) { 
        this.mobileBreakpointObserver.pipe(
            map(state => state.matches)
        ).subscribe(isMobile => {
            this.isMobile = isMobile;
        });
    }

    openContactFormDialog(): void {
        const options: MatDialogConfig = {
            width: '672px',
            autoFocus: false
        };

        const mobileOptions: MatDialogConfig = {
            maxWidth: '100vw',
            maxHeight: '100vh',
            width: '100vw',
            height: '100%'
        }

        this.dialogService.open(this.contactsForm, this.isMobile ? { ...options, ...mobileOptions } : options);
    }
}