import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { JarvisUiModalService } from '../modal';
import { Subject, takeUntil } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';

@Component({
  selector: 'jarvis-modal',
  templateUrl: 'new-modal.component.html',
  imports: [
    MatDialogModule
  ],
  standalone: true,
})

export class JarvisModalComponent implements OnInit, OnDestroy {
  @ViewChild('modalContent', { read: TemplateRef, static: true}) modalContent: TemplateRef<unknown>;

  @Input() autoFocus = false;
  @Input() maxHeight = '100vh';
  @Input() height = 'auto';
  @Input() modalClass: string | string[] = '';

  @Output() modalClose = new EventEmitter();
  @Output() opened = new EventEmitter();
  
  private vcr = inject(ViewContainerRef);
  private modalService = inject(JarvisUiModalService);

  private destroy$ = new Subject<void>();

  private modalRef: MatDialogRef<unknown>;

  ngOnInit(): void {
    if (this.modalContent) {
      this.openModal(this.modalContent);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.modalClose.complete();
    this.opened.complete();

    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  private openModal(template: TemplateRef<unknown>): void {
    this.modalRef = this.modalService.openDialog(template as any, {
      viewContainerRef: this.vcr,
      autoFocus: this.autoFocus,
      maxHeight: this.maxHeight,
      height: this.height,
      panelClass: this.modalClass
    });

    this.opened.emit();

    this.modalRef.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.modalClose.emit();
    });
  }
}
