<div>
  <ng-container [ngSwitch]="dates.length > 1">
    <ng-container *ngSwitchCase="true">
      <span>{{'assistantChat.bookATourSummary.multiEvent' | translate: { serviceName } }}</span>
      <div class="bztt-flex bztt-flex-col bztt-gap-1 bztt-ml-2 bztt-mt-2">
        <span *ngFor="let date of dates">{{date | date: 'shortDate'}}; {{(date | date: 'shortTime')}}</span>
      </div>
    </ng-container>
  
    <ng-container *ngSwitchDefault>
      <span>{{'assistantChat.bookATourSummary.singleEvent' | translate: { serviceName, date: (dates[0] | date: 'shortDate'), time: (dates[0] | date: 'shortTime') } }}</span>
    </ng-container>
  </ng-container>
</div>