import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JarvisProfilingFormService } from '../../services/profiler-form.service';

@Component({
  selector: 'jarvis-profiler-step',
  templateUrl: './profiler-step.component.html',
  providers: [
    JarvisProfilingFormService
  ]
})
export class JarvisProfilerStepComponent implements OnInit {
  constructor(
    private currentRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    const firstChild = this.currentRoute.firstChild;

    // If initial navigation is of a child path - get back to first step
    if (firstChild) {
      const lastPath = firstChild.snapshot.url[0]?.path;
      if (lastPath) {
        this.router.navigate(['.'], { relativeTo: this.currentRoute, replaceUrl: true});
      }
    }
  }
}
