import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, debounceTime, map, merge, of, shareReplay, skip, startWith, switchMap, tap } from 'rxjs';
import { MESSAGES_TYPE } from '../tokens';
import { MessagingService } from '../../services/messaging.service';
import { ChatRoom } from '../../types/messaging.types';

interface SearchResults {
  data: ChatRoom[];
  state: 'active' | 'inactive' | 'empty' | 'error';
  total: number; // total number of data found
  isLoading?: boolean;
}

enum FiltersEnum {
  search = 'search',
  service = 'service',
}

type Filters = keyof typeof FiltersEnum;

@Component({
  selector: 'marketplace-messages-chatrooms-filters',
  templateUrl: 'chatrooms-filters.component.html',
  styleUrls: ['./chatrooms-filters.component.scss'],
})
export class MessagesChatroomsFiltersComponent implements OnInit {

  private isSearchModeSource = new BehaviorSubject<boolean>(false);
  isSearchMode$ = this.isSearchModeSource.asObservable();

  private searchResultSource$: Observable<{data: ChatRoom[], total:number}>;
  searchResult$: Observable<SearchResults>;

  filterTypes = FiltersEnum;
  expandedFilter: Filters = FiltersEnum.search;
  
  searchControl = new UntypedFormControl(null);
  serviceFilterControl = new UntypedFormControl(null);
  // Unread messages check box
  unreadFilterControl = new UntypedFormControl(false); 

  serviceBases: Observable<any>;

  vendorType = inject(MESSAGES_TYPE);
  private messagingService = inject(MessagingService);
  private destroy$ = new Subject<void>();

  constructor() {
    // TODO: Move to a component-based service for clarity
    // TODO: Get ALL servicebase parsed chatrooms, not lazily loaded ones only?
    this.serviceBases = this.messagingService.getExistingServiceBases();

    this.searchResultSource$ = combineLatest([
      this.searchControl.valueChanges.pipe(
        startWith(this.searchControl.value)
      ),
      this.serviceFilterControl.valueChanges.pipe(
        startWith(this.serviceFilterControl.value)
      ),
      this.unreadFilterControl.valueChanges.pipe(
        startWith(this.unreadFilterControl.value)
      )      
    ]).pipe(
      skip(1),
      debounceTime(200),
      switchMap(([textSearch, serviceId, unreadOnly]) => {
        if (!textSearch && !serviceId && !unreadOnly) {
          return of({data:[],total:0});
        }

        /* const rand = Math.random() * 10;

        if (rand > 5) {
          throw new Error('test');
        } */

        return merge(of(null), this.messagingService.searchChatrooms({
          textSearch,
          serviceId,
          unreadOnly
        }));
      })
    );

    this.searchResult$ = this.isSearchMode$.pipe(
      switchMap((isSearchMode) => {
        if (!isSearchMode) {
          return of({
            state: 'inactive' as const,
            data: [],
            total:0
          });
        }
  
        return this.searchResultSource$.pipe(
          map((data) => ({
            state: 'active' as const,
            data: data?.data,
            total: data?.total
          })),
          startWith({
            state: 'empty' as const,
            data: [],
            total:0
          }),
          catchError(() => { 
            return of({
              state: 'error' as const,
              data: [],
              total:0
            })
          })
        );
      }),
      shareReplay(1)
    );
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.isSearchModeSource.complete();
  }

  exitFilterState(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.expandedFilter = FiltersEnum.search;
    // this.clearServiceFilter();
    this.serviceFilterControl.reset();
    this.searchControl.reset();
    this.unreadFilterControl.reset();
    this.isSearchModeSource.next(false);
  }

  activateSearchMode(): void {
    if (this.isSearchModeSource.value !== true) {
      this.isSearchModeSource.next(true);
    }
  }

  expandFilter(type: Filters, event: MouseEvent) {
    this.activateSearchMode();

    if (type === this.expandedFilter) {
      return;
    }

    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    this.expandedFilter = type;
  }
}