import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { HeaderComponent } from './header.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { AppButtonModule } from '../../../shared/components/buttons/button.module';
import { MoreDropdownComponent } from './more-dropdown/more-dropdown.component';
import { ProfileDropdownComponent } from './profile-dropdown/profile-dropdown.component';
import { DropdownWrapperComponent } from './dropdown-wrapper/dropdown-wrapper.component';
import { PortalModule } from '@angular/cdk/portal';
import { HeaderMenuComponent } from './menu/menu.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { HEADER_SERVICE } from './services/header-service.di';
import { HeaderService } from './services/header.service';
import { CommonDialogService } from '../../../shared/services/common-dialog.service';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuItemComponent,
    MoreDropdownComponent,
    ProfileDropdownComponent,
    DropdownWrapperComponent,
    HeaderMenuComponent,
    MobileMenuComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    PortalModule,
    MatMenuModule,
    AppButtonModule,
    TranslateModule.forChild(),
    RouterModule,
  ],
  exports: [
    HeaderComponent
  ],
  providers: [
    CommonDialogService,
    {
      provide: HEADER_SERVICE,
      useExisting: HeaderService
    }
  ],
})
export class HeaderModule { }
