import { FormControl } from '@angular/forms';
import {
  AdvertsPriceUnits,
  ExtractedServices,
  ServiceTypes,
} from './pricing-calculator.types';
import { AdvertServiceVariant } from './pricing-calculator';

export interface MainParsedServicesType {
  venueStandalone: AdvertServiceVariant[];
  venueCeremony: AdvertServiceVariant[];
  venueRentAndCatering: {
    venue: AdvertServiceVariant;
    catering: {
      banquet: AdvertServiceVariant[];
      buffet: AdvertServiceVariant[];
      beverages: AdvertServiceVariant[];
      kids: AdvertServiceVariant[];
      kidsBeverages: AdvertServiceVariant[];
    };
  };
  catering: {
    buffet: AdvertServiceVariant[];
    banquet: AdvertServiceVariant[];
    foodTruck: AdvertServiceVariant[];
    snacks: AdvertServiceVariant[];
  };
  others: { name: string; services: AdvertServiceVariant[] }[];
}

export function parseMainServices(
  serviceVariants: ExtractedServices,
  serviceType: string,
  eventDateControl = new FormControl(null)
) {
  const mainParsed: any = {
    venueStandalone: null,
    venueCeremony: null,
    venueRentAndCatering: {
      venue: null,
      catering: {
        banquet: [],
        buffet: [],
        beverages: [],
        kids: [],
        kidsBeverages: [],
      },
    },
    catering: {
      buffet: [],
      banquet: [],
      foodTruck: [],
      snacks: [],
    },
    others: [],
  };

  serviceVariants.main.forEach((mainService) => {
    if (mainService.type === ServiceTypes.venueStandalone) {
      if (!mainParsed.venueStandalone) {
        mainParsed.venueStandalone = [];
      }
      mainParsed.venueStandalone.push(mainService);
      return;
    }

    if (mainService.type === ServiceTypes.venueCeremony) {
      if (!mainParsed.venueCeremony) {
        mainParsed.venueCeremony = [];
      }
      mainParsed.venueCeremony.push(mainService);
      return;
    }

    if (mainService.type === ServiceTypes.venueSeparate) {
      mainParsed.venueRentAndCatering.venue = mainService;
      return;
    }

    if (
      mainService.type === ServiceTypes.venueCateringMenuMain ||
      mainService.type === ServiceTypes.venueCateringMenuAdditional ||
      mainService.type === ServiceTypes.venueCateringMenuBeverages ||
      mainService.type === ServiceTypes.venueCateringMenuChildren ||
      mainService.type === ServiceTypes.venueCateringMenuBeveragesChildren
    ) {
      const venueSeparate = serviceVariants.main.find(
        (service) => service.type === ServiceTypes.venueSeparate
      );

      if (venueSeparate) {
        mainService.additionalPriceUnit = venueSeparate.priceUnit;
        if (venueSeparate.priceUnit === AdvertsPriceUnits.fixedFee) {
          mainService.additionalFixedCostFn = () =>
            venueSeparate.getFinalPrice(eventDateControl.value);
        }

        if (venueSeparate.priceUnit === AdvertsPriceUnits.startingPrice) {
          mainService.additionalFixedCostFn = () =>
            venueSeparate.getFinalPrice(eventDateControl.value);
        }

        if (venueSeparate.priceUnit === AdvertsPriceUnits.currencyPerPerson) {
          mainService.additionalUnitFixedCostFn = () =>
            venueSeparate.getFinalPrice(eventDateControl.value);
        }
      }

      if (mainService.originalService.cateringType?.buffet) {
        mainParsed.venueRentAndCatering.catering.buffet.push(mainService);
      } else if (mainService.originalService.cateringType?.banquet) {
        mainParsed.venueRentAndCatering.catering.banquet.push(mainService);
      }

      if (mainService.type === ServiceTypes.venueCateringMenuBeverages) {
        mainParsed.venueRentAndCatering.catering.beverages.push(mainService);
      }

      /* if (mainService.type === ServiceTypes.venueCateringMenuChildren) {
        mainParsed.venueRentAndCatering.catering.kids.push(mainService);
      } */

      if (
        mainService.type === ServiceTypes.venueCateringMenuBeveragesChildren
      ) {
        mainParsed.venueRentAndCatering.catering.beverages.push(mainService);
      }

      return;
    }

    if (
      serviceType === 'catering' &&
      (mainService.type === ServiceTypes.cateringBanquetMenu ||
        mainService.type === ServiceTypes.cateringBuffetMenu ||
        mainService.type === ServiceTypes.cateringFoodTruck ||
        mainService.type === ServiceTypes.cateringSnacks)
    ) {
      if (mainService.type === ServiceTypes.cateringBanquetMenu) {
        mainParsed.catering.banquet.push(mainService);
      }

      if (mainService.type === ServiceTypes.cateringBuffetMenu) {
        mainParsed.catering.buffet.push(mainService);
      }

      if (mainService.type === ServiceTypes.cateringFoodTruck) {
        mainParsed.catering.foodTruck.push(mainService);
      }

      if (mainService.type === ServiceTypes.cateringSnacks) {
        mainParsed.catering.snacks.push(mainService);
      }

      return;
    }

    if (mainService.key) {
      const target = mainParsed.others.find(
        (subtype) => subtype.name === mainService.key
      )?.services;
      if (Array.isArray(target)) {
        target.push(mainService);
      } else {
        mainParsed.others.push({
          name: mainService.key,
          services: [mainService],
        });
      }
    } else {
      const target = mainParsed.others.find(
        (subtype) => subtype.name === 'main'
      )?.services;
      if (Array.isArray(target)) {
        target.push(mainService);
      } else {
        mainParsed.others.push({
          name: 'main',
          services: [mainService],
        });
      }
    }
  });

  return mainParsed;
}
