import { add, init } from '@amplitude/analytics-browser';
import { BrowserOptions } from '@amplitude/analytics-types';
import * as amplitude from '@amplitude/analytics-browser';

export function notHeadlessBrowser() {
  return !/eadless/.test(window.navigator.userAgent) ? true : false;
}

export const AmplitudeFactory = (APP_KEY: string) => {
  return () => {
    return () => {
      if (APP_KEY && notHeadlessBrowser()) {
        const guestForm = JSON.parse(
          localStorage.getItem('QUEST_CONTACT_FORM')
        );

        init(APP_KEY, guestForm && guestForm.email, {
          includeFbclid: true,
          includeGclid: true,
          includeReferrer: true,
          //   saveParamsReferrerOncePerSession: false,
          //   unsetParamsReferrerOnNewSession: true,
          // defaultTracking: false,
          includeUtm: true,
          defaultTracking: {
            sessions: true,
            // pageViews: true,
          },
        } as BrowserOptions);

        add({
          name: 'Landing Page Check',
          type: amplitude.Types.PluginType.BEFORE,
          setup: (config: amplitude.Types.Config) => {
            return Promise.resolve(null);
          },
          execute: (context: amplitude.Types.Event) => {
            if (
              context.event_type === 'page_load' ||
              context.event_type === '[Amplitude] Page Viewed'
            ) {
              const SESSION_ID_KEY =
                'amplitude_session_id_' +
                (context.event_type === 'page_load'
                  ? 'page_load'
                  : 'page_view');

              const localSessionId = localStorage.getItem(SESSION_ID_KEY);

              if (
                !localSessionId ||
                localSessionId !== amplitude.getSessionId().toString()
              ) {
                context.event_properties['landing_page'] = true;

                localStorage.setItem(
                  SESSION_ID_KEY,
                  amplitude.getSessionId().toString()
                );
              }
            }

            return Promise.resolve(context);
          },
        });
      }
    };
  };
};
