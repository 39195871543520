import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ButtonModule, JarvisUiModalWrapperModule } from '@jarvis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { LikeSaveEmailModalComponent } from './like-save-email.component';

@NgModule({
  imports: [
    CommonModule,
    JarvisUiModalWrapperModule,
    TranslateModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ButtonModule,
    MatIconModule
  ],
  exports: [LikeSaveEmailModalComponent],
  declarations: [LikeSaveEmailModalComponent],
  providers: [],
})
export class LikeSaveEmailModule { }
