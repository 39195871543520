import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  Validators,
  ValidatorFn
} from '@angular/forms';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';

const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-d-form[descriptor]',
  templateUrl: './d-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./d-form.component.scss'],
  providers: [
    TranslatePipe,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() descriptor: FormDescriptor<any>;
  @Input() readonly: Object;
  @Input() noButton = false;
  @Input() i18nKey: string;
  @Input() template1;
  @Input() locale = 'en-US';
  @Input() model: Object;
  @Input() actionService: DFormActionService;
  @Output() onAction = new EventEmitter<FormActionType<any>>();

  public dformGroup: UntypedFormGroup;
  private actionSubscription: Subscription;
  public values: any;
  public timeTick: { tm: number; name: string }[] = [];
  private static TIME_PREFIX = '_time$$';

  constructor(private fb: UntypedFormBuilder, private translatePipe: TranslatePipe) {}

  public unorderedKeys(val: any) {
    return Object.keys(val);
  }
  static newFormDescriptor = <T>(
    value: FormDescriptor<T>
  ): FormDescriptor<T> => {
    return value;
  };

  onEvnt(type: string, model: Object = null): void {
    this.onAction.emit({ type: type, model: model });
  }

  public onSubmit() {
    if (this.dformGroup.valid) {
      // Object.entries(this.descriptor.controls).filter(([controlName, control])=>(control.type==='datetime'))
      //     .forEach(([controlName, control])=>{
      //       const time = Number(this.dformGroup.get(controlName + DFormComponent.TIME_PREFIX).value);
      //       let dt = moment(this.dformGroup.get(controlName).value);
      //       dt.set({hour:time / 60, minute: time % 60 });
      //       this.dformGroup.get(controlName).patchValue(dt.toDate());

      //     });

      this.onEvnt(
        'submit',
        this.fromEntries<any>(
          Object.entries(this.dformGroup.value).filter(
            ([controlName, control]) =>
              !controlName.endsWith(DFormComponent.TIME_PREFIX)
          )
        )
      );
    }
  }

  private fromEntries<T>(entries: [keyof T, T[keyof T]][]): T {
    return entries.reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      <T>{}
    );
  }

  public patchValue(): void {
    if (this.model) this.dformGroup.patchValue(this.model);
    Object.entries(this.descriptor.controls)
      .filter(([controlName, control]) => control.type === 'datetime')
      .forEach(([controlName, control]) => {
        const tick = Number(control.tick ?? '15');
        const dt = moment(this.dformGroup.get(controlName).value);
        this.dformGroup
          .get(controlName + DFormComponent.TIME_PREFIX)
          .patchValue(dt.hour() * 60 + Math.round(dt.minute() / tick) * tick);
      });
  }

  public timeSelection(key: string, selected: any) {
    const time = Number(selected.value);
    const dt = moment(this.dformGroup.get(key).value);
    dt.set({ hour: time / 60, minute: time % 60 });
    this.dformGroup.get(key).patchValue(dt.toDate());
  }

  ngOnDestroy() {
    if (this.actionSubscription) this.actionSubscription.unsubscribe();
  }

  ngOnInit(): void {
    const controls = this.descriptor.controls;
    const group = {};
    Object.keys(controls).forEach((controlName) => {
      const validators = [];
      const control = controls[controlName];

      if (control.type === 'datetime') {
        if (this.timeTick.length == 0)
          for (let i = 0; i < 60 * 24; i = i + Number(control.tick ?? '30'))
            this.timeTick.push({
              tm: i,
              //                  name:Math.floor(i/60).toString().padStart(2,"0") + ":" + (i % 60).toString().padStart(2,"0")
              name: formatDate(
                moment(
                  Math.floor(i / 60)
                    .toString()
                    .padStart(2, '0') +
                    ':' +
                    (i % 60).toString().padStart(2, '0'),
                  'HH:mm'
                ).toDate(),
                'shortTime',
                this.locale
              )
            });
        group[controlName + DFormComponent.TIME_PREFIX] = new UntypedFormControl('');
      }
      if (control.required) validators.push(Validators.required);
      if (control.onChangeOrInit) {
        control.onChange = control.onChangeOrInit;
        control.onInit = control.onChangeOrInit;
      }
      if (control.type == 'email') validators.push(Validators.email);
      if (control.type == 'tel')
        validators.push(Validators.pattern('[- +()0-9]+'));
      if (control.validators)
        control.validators.forEach((validator) => validators.push(validator));

      group[controlName] = new UntypedFormControl('', validators);
      if (control.disabled) group[controlName].disable();
    });
    this.dformGroup = new UntypedFormGroup(group, this.descriptor.validators);

    if (this.readonly) this.dformGroup.disable();
    this.patchValue();

    if (this.actionService)
      this.actionSubscription = this.actionService
        .getAction()
        .subscribe(this.onActionSubscription.bind(this));

    this.onChanges();
  }

  private onChanges(): void {
    const controls = this.descriptor.controls;
    Object.keys(controls).forEach((controlName) => {
      const control = controls[controlName];
      if (control.onChange)
        this.dformGroup
          .get(controlName)
          .valueChanges.subscribe((val) =>
            control.onChange(
              this.dformGroup,
              controlName,
              val,
              this.descriptor,
              'change'
            )
          );
      if (control.onInit)
        control.onInit(
          this.dformGroup,
          controlName,
          this.dformGroup.get(controlName).value,
          this.descriptor,
          'init'
        );
    });
  }

  private onActionSubscription(r: string) {
    if (r === 'patchValue') this.patchValue();
    if (r === 'clearValue') this.dformGroup.reset(); // warning this command is bugged
  }

  public timeClass(controlName: string, value: number): string {
    const control = this.descriptor.controls[controlName];
    const dt = moment(this.dformGroup.get(controlName).value).startOf('day');
    dt.set({ hour: value / 60, minute: value % 60 });
    if (control.dateClassFn)
      return control.dateClassFn(
        this.dformGroup,
        controlName,
        dt,
        'time',
        this.descriptor
      );
    return '';
  }

  public dateClass(controlName): MatCalendarCellClassFunction<Date> {
    const control = this.descriptor.controls[controlName];
    return (cellDate, view) => {
      if (control.dateClassFn)
        return control.dateClassFn(
          this.dformGroup,
          controlName,
          cellDate,
          view,
          this.descriptor
        );
      return '';
    };
  }

  public isMessageSet(key: string): boolean {
    return (
      this.translatePipe.transform(this.i18nKey + '.' + key) !==
      this.i18nKey + '.' + key
    );
  }

  public optionText(name: string, item: OptionType): string {
    if (item.text) return item.text;
    const translateOptions: translateArgsFn =
      this.descriptor.controls[name].translateArgs as translateArgsFn;
    const value = this.translatePipe.transform(
      this.i18nKey + '.' + name + '_options.' + item.id,
      translateOptions
        ? translateOptions(this.dformGroup, name, item, this.descriptor)
        : null
    );
    if (value == this.i18nKey + '.' + name + '_options.' + item.id)
      return item.id.toString();
    else return value;
  }

  ngOnChanges() {
    if (this.dformGroup) {
      if (this.readonly) this.dformGroup.disable();
      else {
        this.dformGroup.enable();
      }
    }
  }
}

type onChangeFn = (
  dformGroup: UntypedFormGroup,
  control: string,
  value: any,
  descriptor: FormDescriptor<any>,
  tp: string
) => void;
type dateClassFn = (
  dformGroup: UntypedFormGroup,
  control: string,
  cellDate,
  view: string,
  descriptor: FormDescriptor<any>
) => string;
type translateArgsFn = (
  dformGroup: UntypedFormGroup,
  control: string,
  value: any,
  descriptor: FormDescriptor<any>
) => any;

export type OptionType = {
  id: Object;
  text?: string;
  data?: any;
  options?: OptionType[];
};

export type FormDescriptor<T> = {
  controls: {
    [K in keyof T]?: {
      required?: boolean;
      type?: string;
      trigger?: string;
      options?: OptionType[];
      translateArgs?: translateArgsFn | ({[key: string]:string});
      control?: string;
      tick?: number;
      validators?: ValidatorFn[];
      dateClassFn?: dateClassFn;
      minRows?: number;
      maxRows?: number;
      disableTime?: boolean;
      disabled?: boolean;
      hidden?: boolean;
      class?: string;
      onChange?: onChangeFn;
      onInit?: onChangeFn;
      onChangeOrInit?: onChangeFn;
    };
  };
  validators?: ValidatorFn | ValidatorFn[];
  readonly?: boolean;
  cancelButtonHide?: boolean;
  data?: any;
  resetAfterSubmit?: boolean;
};
export type FormActionType<T> = { type: string; model?: T };

export class DFormActionService {
  private actionStatus = new Subject<any>();

  constructor() {}

  public callAction(name: string) {
    this.actionStatus.next(name);
  }

  public callUpdateModel() {
    this.callAction('patchValue');
  }
  public callClearModel() {
    this.callAction('clearValue');
  }

  public getAction(): Observable<string> {
    return this.actionStatus.asObservable();
  }
}
