import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, of } from 'rxjs';
import { BASE_URL } from '@jarvis/services';

@Injectable({providedIn: 'root'})
export class AssistantChatService {
  
  private baseUrl = inject(BASE_URL);
  private httpService = inject(HttpClient);

  startChatUnregistered(serviceBase: string, userId: string, message: string, prompt: string) {
    const endpoint = `${this.baseUrl}/chat/startGptNoUser`;

    const body = {
      userId,
      serviceBase,
      message,
      instructions: prompt
    };

    return this.httpService.post<any>(endpoint, body);
  }

  getChat(serviceBase: string, userId: string) {
    const endpoint = `${this.baseUrl}/chat/getChatGptNoUser`;

    const params = {
      userId,
      serviceBase
    };

    return this.httpService.get<any>(endpoint, { params }).pipe(
      catchError((err) => {
        if (err.error.errors?.[0].message === 'Chat room doesnt exist') {
          return of({
            chatRoom: {},
            chatMessages: []
          });
        }

        throw new Error(err);
      })
    );
  }

  sendMessage(serviceBase: string, userId: string, message: string) {
    const endpoint = `${this.baseUrl}/chat/addMessageToChat`;

    const body = {
      serviceBase,
      userId,
      message
    };

    return this.httpService.post<any>(endpoint, body);
  }

  addBookingToChat(serviceBase: string, userId: string, booking: string, message: string) {
    const endpoint = `${this.baseUrl}/chat/addBookingToChat`;

    const body = {
      serviceBase,
      userId,
      booking,
      message
    };

    return this.httpService.post<any>(endpoint, body);
  }

  getBooking(bookingId: string) {
    return this.httpService.get<any>(`${this.baseUrl}/common/bookingPDF/${bookingId}`);
  }

  addBookATour(chatRoomId: string, userId: string, bookATour: any) {
    const endpoint = `${this.baseUrl}/chat/editBookATour`;

    const body = {
      id: chatRoomId,
      userId,
      bookATour
    };

    return this.httpService.post<any>(endpoint, body);
  }
}