import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatDatepicker } from "@angular/material/datepicker";

@Component({
  selector: 'jarvis-ui-datapicker-range', 
  styles: [`
.datepicker-footer {
    width: 100%;
    padding: 0 1.125rem 1.125rem 1.125rem;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
}
  `],  
  template:  `
<mat-date-range-input [rangePicker]="picker" class="d-none">
        <input type="hidden" matStartDate [(ngModel)]="dateFrom" placeholder="Start date">
        <input type="hidden" matEndDate [(ngModel)]="dateTo" placeholder="End date">
           <mat-date-range-picker #picker></mat-date-range-picker>
</mat-date-range-input>  

<mat-form-field>
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="datepicker" [value]="selectedValue">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker (opened)="onOpen()"></mat-datepicker>
    </mat-form-field>
<!-- Workaround for adding the buttons -->
<div style="display: none">
    <div class="datepicker-footer" #datepickerFooter>
        <button mat-raised-button color="primary" (click)="today()" #todayButton>
            Today
        </button>
        <button mat-raised-button (click)="done()" #doneButton>
            Done
        </button>
    </div>
</div>
`
})

export class JarvisDatepickerRange {
    @ViewChild('datepickerFooter', {static: false}) datepickerFooter: ElementRef;
    @ViewChild('datepicker', {static: false}) datepicker: MatDatepicker<any>;

    selectedValue : Date | null = null;
    dateFrom: Date;
    dateTo: Date;

    onOpen() {
        this.appendFooter();
    }

    today() {
        this.selectedValue = new Date();
        this.datepicker.close();
    }

    done() {
        // console.log('done');
        this.datepicker.close();
    }

    private appendFooter() {
        const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
        matCalendar.appendChild(this.datepickerFooter.nativeElement);
    }

}