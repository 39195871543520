import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { JarvisProfilerStepComponent } from './components/profiler-step/profiler-step.component';
import { ButtonModule } from '@jarvis/ui/button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { CategoryComponent } from './components/steps/vendor/category/category.component';
import { ClientsComponent } from './components/steps/vendor/clients/clients.component';
import { PriceComponent } from './components/steps/vendor/price/price.component';
import { ExperienceComponent } from './components/steps/vendor/experience/experience.component';
import { BusinessComponent } from './components/steps/vendor/business/business.component';
import { WebsiteComponent } from './components/steps/vendor/website/website.component';
import { SourceComponent } from './components/steps/vendor/source/source.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LocationComponent } from './components/steps/vendor/location/location.component';
import { SuccessComponent } from './components/steps/vendor/success/success.component';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { CasePipesModule, JarvisHttpStateModule } from '@jarvis/utils';
import { ProfilingCountryResolver } from './resolvers/profiling-country.resolver';
import { PromoCodeComponent } from './components/steps/vendor/promo-code/promo-code.component';
import { PhoneComponent } from './components/steps/vendor/phone/phone.component';
import { JarvisPhoneNumberModule } from '@jarvis/ui/phone-number';
import { BookACallComponent } from './components/steps/vendor/book-a-call/book-a-call.component';

const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'business',
    pathMatch: 'full'
  },
  {
    component: JarvisProfilerStepComponent,
    path: '',
    resolve: {
      country: ProfilingCountryResolver
    },
    children: [
      /* {
        path: 'category',
        component: CategoryComponent
      },
      {
        path: 'clients',
        component: ClientsComponent
      },
      {
        path: 'price',
        component: PriceComponent
      },
      {
        path: 'experience',
        component: ExperienceComponent
      }, */
      {
        path: 'business',
        component: BusinessComponent
      },
      {
        path: 'phone',
        component: PhoneComponent
      },      
      {
        path: 'website',
        component: WebsiteComponent
      },
      {
        path: 'source',
        component: SourceComponent
      },
      /* {
        path: 'location',
        component: LocationComponent
      },
      {
        path: 'promo-code',
        component: PromoCodeComponent
      }, */
      {
        path: 'call',
        component: BookACallComponent
      },
      {
        path: 'success',
        component: SuccessComponent
      }
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    ButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatIconModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatMomentDateModule,
    MatProgressBarModule,
    JarvisHttpStateModule,
    CasePipesModule,
    JarvisPhoneNumberModule
  ],
  declarations: [
    JarvisProfilerStepComponent,
    CategoryComponent,
    ClientsComponent,
    PriceComponent,
    ExperienceComponent,
    BusinessComponent,
    WebsiteComponent,
    SourceComponent,
    LocationComponent,
    PhoneComponent,
    PromoCodeComponent,
    SuccessComponent,
    BookACallComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ]
})
export class JarvisUserProfilingModule {}
