import { Injectable, OnDestroy, Self } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JarvisLanguageService } from './language/language.service';
import { Moment } from 'moment';

@Injectable()
export class JarvisDateAdapterService implements OnDestroy {

    private destroy$ = new Subject<void>();

    constructor(
        private languageService: JarvisLanguageService,
        private dateAdapter: DateAdapter<Moment>
    ) {
        this.languageService.currentLanguage$.pipe(
            takeUntil(this.destroy$)
        ).subscribe((currentLanguage) => {
            this.dateAdapter.setLocale(currentLanguage);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}