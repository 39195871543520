import { Component } from '@angular/core';
import {UntypedFormControl } from '@angular/forms';
import { checkFormOrGroupInvalid } from '../../../../utils/profiler.utils';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';

@Component({
  templateUrl: './category.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class CategoryComponent {

  otherCategories = [
    { label: 'Event venue', value: 'venues' },
    { label: 'Catering', value: 'catering' },
    { label: 'Ceremony place', value: 'ceremony_venue' },
    { label: 'Snacks after ceremony', value: 'ceremony_regale' },
    { label: 'Bartender', value: 'bartenders' },
    { label: 'Food truck', value: 'foodtruck' },
    { label: 'Videographer', value: 'videographer' },
    { label: 'Photographer', value: 'photographer' },
    { label: 'Live music', value: 'live_music' },
    { label: 'Event planning', value: 'planning' },
    { label: 'Event host', value: 'host' },
    { label: 'Cakes & desserts', value: 'cakes_desserts' },
    { label: 'Beauty', value: 'makeup_hairdressing' },
    { label: 'DJ', value: 'dj' },
    { label: 'Bride attire', value: 'bride_clothing' },
    { label: 'Groom attire', value: 'groom_clothing' },
    { label: 'Second hand attire', value: 'secondary_dress' },
    { label: 'Transport', value: 'transport' },
    { label: 'Decor rentals', value: 'decor' },
    { label: 'Invitations', value: 'invitations_printing' },
    { label: 'Event decor & flowers', value: 'decor_floristics' },
    { label: 'Tents & furniture', value: 'pavilion_furniture' },
    { label: 'Sound & lights', value: 'sound_lights' },
    { label: 'Screens', value: 'screens_projectors' },
    { label: 'Shows', value: 'show' },
    { label: 'Classes', value: 'training' },
    { label: 'Entertainment', value: 'entertainment' },
    { label: 'Jewelry', value: 'jewelery' },
    { label: 'Gifts', value: 'presents' },
    { label: 'Honey moon', value: 'honeymoon' },
    { label: 'Other', value: 'other' }
  ];

  values = [
    {
      label: 'profiling.category.venue',
      value: 'venue'
    },
    {
      label: 'profiling.category.photographyVideography',
      value: 'photographyVideography'
    },
    {
      label: 'profiling.category.liveMusicDj',
      value: 'liveMusicDj'
    },
    {
      label: 'profiling.category.eventOrWeddingPlanning',
      value: 'eventOrWeddingPlanning'
    },
    {
      label: 'profiling.other',
      value: 'other'
    }
  ];
  
  formControl:UntypedFormControl;

  selectedValue: string | null = null;

  constructor(
    private profilingFormService: JarvisProfilingFormService
  ) {
    this.formControl = this.profilingFormService.getStepControl('serviceCategory');
    this.selectedValue = this.formControl.value;
  }

  selectValue(value: string): void {
    if (value !== 'other') {
      this.formControl.setValue(value);
    } else {
      this.formControl.reset();
    }

    this.selectedValue = value;
  }

  next(): void {
    const formValid = checkFormOrGroupInvalid(this.formControl);

    if (!formValid) {
      return;
    }

    this.profilingFormService.trackFieldCaptureEvent('service_category');
    this.profilingFormService.navigateToStep('clients');
  }
}
