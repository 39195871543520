import {
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  LinkUrls,
  LINK_URLS,
  JarvisTrackingService,
  DOMAIN_COUNTRY,
} from '@jarvis/services';
import { AnalyticsService } from '@jarvis/analytics';
import { Subject } from 'rxjs';
import { MarketplaceCardAnimations } from './marketplace-card.animations';
import { MarketplaceCardData } from './marketplace-card.interface';
import { JarvisUiMarketplacePhotoSwiperComponent } from './components/photo-swiper/photo-swiper.component';
import { JarvisServiceLikesService } from '@jarvis/services/likes';
import { MetricsEnum } from '@jarvis/types';
import { ICON_DATA } from 'libs/planner/src/lib/pipes/planner-card-pictures.pipe';

type ImageArray = Array<{ type: string; link: string } | string>;

@Component({
  selector: 'jarvis-ui-marketplace-card',
  templateUrl: 'marketplace-card.component.html',
  styleUrls: ['./marketplace-card.component.scss'],
  animations: [...MarketplaceCardAnimations],
})
export class JarvisUiMarketplaceCardComponent implements OnInit, OnDestroy {
  @ViewChild(JarvisUiMarketplacePhotoSwiperComponent)
  set photoSwiper(swiper: JarvisUiMarketplacePhotoSwiperComponent) {
    // Automatically destroyed in child component itself
    if (swiper) {
      swiper.photoSwiped.subscribe(() => {
        this.trackSwipes();
      });
    }
  }

  @Input() notAvailable: 'NO_TIME' | 'NO_SPACE' | null = null;
  @Input() imagePriority = false;

  @Input() set images(imageArr: ImageArray) {
    this._imageCount = imageArr?.length ?? 0;

    this._images = imageArr
      .filter((imageOrObj) =>
        typeof imageOrObj === 'string'
          ? true
          : imageOrObj?.type === 'image'
          ? true
          : false
      )
      .map((imageOrObj) =>
        typeof imageOrObj === 'string' ? imageOrObj : imageOrObj.link
      );

    if (this._imageCount === 0) {
      this.mainImageSrc = '/assets/images/noUser.jpeg';
      this._imageCount = 0;
      return;
    }

    this.mainImageSrc = this._images[0];
  }
  get images(): string[] {
    return this._images;
  }
  private _images: string[];

  private _imageCount: number;
  private _customImageCount: number | null = null;

  @Input()
  set imageCount(value: number) {
    this._customImageCount = value;
  }
  get imageCount() {
    if (this._customImageCount) {
      return this._customImageCount;
    }

    return this._imageCount;
  }

  @Input() cardType:
    | 'default'
    | 'planner'
    | 'partnerList'
    | 'partnerListCustomItem'
    | 'venues-blog'
    | 'loading' = 'default';

  @Input() requestSent = false;

  @Input() isMobile = false;

  @Input() swiperRefresh: Subject<void>;

  @Input() recommendationType: string | boolean = false;

  @Input() status:
    | 'supported'
    | 'main'
    | 'make-main'
    | 'recommended'
    | 'reserved'
    | '' = '';

  @Input() liked = false;

  @Input() hideLike = false;

  @Input() pageType: string;

  @Input() cardClickEventType: string;

  @Input() data: MarketplaceCardData;

  @Input() disableRedirect = false;

  @Output() likeEvent = new EventEmitter();
  @Output() cardClickEvent = new EventEmitter();
  @Output() photoClick = new EventEmitter();
  @Output() dataClick = new EventEmitter();
  @Output() statusEvent = new EventEmitter();
  @Output() commentChanged = new EventEmitter();

  window = window;

  mainImageSrc: string;

  hostUrl = '';

  domainCountry = inject(DOMAIN_COUNTRY);

  private destroy$ = new Subject<void>();

  private analyticsService = inject(AnalyticsService);
  private trackService = inject(JarvisTrackingService);

  constructor(
    @Inject(LINK_URLS) public linkUrls: LinkUrls,
    private likeService: JarvisServiceLikesService
  ) {}

  ngOnInit() {
    if (this.cardType === 'partnerList')
      this.hostUrl = this.linkUrls.marketplace;

    if (this.disableRedirect) {
      const cardLink = document.querySelectorAll('.card-click-link');
      cardLink.forEach((link) => {
        link.removeAttribute('href');
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  photoClickHandler(event: Event) {
    this.photoClick.emit();
  }

  dataClickHandler(event: Event) {
    if (this.data['customLike']) {
      event.preventDefault();
      return;
    }

    this.dataClick.emit();
  }

  likeClicked(event: MouseEvent): void {
    event.stopPropagation();

    // this.liked = !this.liked;

    if (!this.liked)
      this.likeService.lastLikeSubject.next({
        ...this.data,
        image: this.mainImageSrc,
      });

    this.likeEvent.emit(this.liked);
  }

  statusChange(status?: string): void {
    this.statusEvent.emit(status);
  }

  cardClickHandle(clickEvent: MouseEvent) {
    if ((clickEvent.target as any)?.className?.includes('swiper-button')) {
      return;
    }
    this.cardClickEvent.emit();

    const targetSpecificTracker = (clickEvent.target as any).attributes?.track
      ?.value;
    const targetSpecificTrackerKey = (clickEvent.target as any).attributes
      ?.trackKey?.value;

    if (
      this.disableRedirect &&
      !(targetSpecificTracker && targetSpecificTrackerKey)
    ) {
      return;
    }

    const trackObject = {
      type: this.cardClickEventType,
      page_type: this.pageType,
      listing_name: this.data.title,
      listing_URL: this.data.slug,
      tag_paid: this.data.paid,
      tag_instagram: !(this.data.instagramLink == null),
      tag_negotiable_price: this.data?.priceAddition?.negotiablePricing,
      listing_category: this.data.type.split('.')[1],
      category: this.data.type.split('.')[1],
      listing_subcategory: this.data.type.split('.')[2] || null,
      recommendations: this.recommendationType,
    };

    if (targetSpecificTracker && targetSpecificTrackerKey) {
      trackObject[targetSpecificTrackerKey] = targetSpecificTracker;
    }

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'listing_click',
      data: trackObject,
    });
  }

  private trackSwipes() {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'picture_swipe',
      data: {
        page_type: this.pageType,
        listing_name: this.data.title,
        listing_URL: this.data.slug,
        tag_instagram: !(this.data.instagramLink == null),
        tag_negotiable_price: this.data?.priceAddition?.negotiablePricing,
        listing_category: this.data.type.split('.')[1],
        listing_subcategory: this.data.type.split('.')[2] || null,
      },
    });

    this.analyticsService
      .saveDayCountAnalytics(
        MetricsEnum.photoViews,
        null,
        this.data.serviceBase._id
      )
      .subscribe();
  }

  public defImageByCategory(category: string): string {
    return ICON_DATA[category] ?? ICON_DATA.new;
  }
}
