import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarMonthViewDay } from 'angular-calendar';
import { startOfDay, addMonths, subMonths, addYears, subYears, getYear, setYear, setMonth } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { setHours, setMinutes, getHours, getMinutes } from 'date-fns';
import { JarvisMeasurementUnitService } from '@jarvis/services/measurement-units';
import { takeUntil } from 'rxjs/operators';
import { CustomDateFormatter } from '@jarvis/utils';
import { formatDate } from '@angular/common';
import { JarvisLanguageService } from '@jarvis/services/language';

@Component({
  selector: 'jarvis-ui-small-calendar',
  templateUrl: './small-calendar.component.html',
  styleUrls: ['./small-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }]
  /*  providers: [
      {
        provide: CalendarDateFormatter,
        useExisting: CustomDateFormatter
      }
    ]*/
})
export class SmallCalendarComponent implements OnInit, OnDestroy {

  @ViewChild('yearViewAnchor') yearViewAnchor: ElementRef;
  @ViewChild('monthView', { static: true }) monthView: ElementRef;
  @ViewChild('monthsView', { static: true }) monthsView: ElementRef;
  @ViewChild('yearsView', { static: true }) yearsView: ElementRef;

  currentView = new BehaviorSubject<ElementRef>(null);
  months = new BehaviorSubject<string[]>([]);
  years = new BehaviorSubject<string[]>([]);
  yearInterval = {
    from: null,
    to: null
  };

  isLoading = false;
  allEvents = new BehaviorSubject<CalendarEvent[]>([]);

  todaysDate = startOfDay(new Date());

  private _date: Date = startOfDay(new Date());
  private _events: CalendarEvent[];

  @Input() viewDate = startOfDay(new Date());
  @Input() yearsViewIntervalSize = 24;

  @Input() set events(value: CalendarEvent[]) {
    this._events = value;
    this.combineCurrentDayAndEvents();
  }
  get events(): CalendarEvent[] {
    return this._events;
  }

  @Input() set date(value: Date) {
    this._date = value;
    this.combineCurrentDayAndEvents();
  }
  get date(): Date {
    return this._date;
  }

  @Input() disablePastDays = false;
  @Input() disableWeekdays: number[];
  @Input() disableToday = false;
  @Input() monthTitleFormat = 'LLLL, yyyy';
  @Input() yearViewFormat = 'yyyy';

  @Input() onDestroy: () => unknown = null;

  @Output() daySelected = new EventEmitter();
  @Output() viewChanged = new EventEmitter();

  private destroy$ = new Subject<void>();
  public locale: string;
  constructor(
    public languageService: JarvisLanguageService,
    @Optional() private measurementUnitService: JarvisMeasurementUnitService
  ) {
    this.locale = languageService.getLocaleByLanguage();

    this.languageService.currentLanguage$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((v) => {
      this.locale = languageService.getLocaleByLanguage()
      this.updateLocalizedMonths(this.locale);
    });

    this.updateYears(getYear(this.todaysDate));
  }

  ngOnInit(): void {
    console.log(this.viewDate)
    this.currentView.next(this.monthView);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.onDestroy) {
      this.onDestroy();
    }
  }

  dayClicked(day: CalendarMonthViewDay): void {
    if (this.disablePastDays && day.isPast) {
      return;
    }

    if (this.disableToday && day.isToday) {
      return;
    }

    if (this.disableWeekdays && this.disableWeekdays.includes(day.day)) {
      return;
    }

    if (day.events.some(x => x.title == 'booked' && x.allDay)) {
      return;
    }

    const currentSelectedPeriodEventIndex = this.events.findIndex(event => event.title === 'selectedPeriod');
    if (currentSelectedPeriodEventIndex !== -1) {
      this.events = this.events.filter(event => event.title !== 'selectedPeriod');
    }

    const newDate = setHours(setMinutes(day.date, getMinutes(this.date)), getHours(this.date));
    this.date = newDate;
    this.viewDate = newDate;
    this.daySelected.emit(newDate);
    this.events.unshift({
      title: 'selectedPeriod',
      start: newDate,
      end: newDate
    });
  }

  nextMonth(): void {
    this.viewDate = addMonths(this.viewDate, 1);
    this.viewChanged.emit(this.viewDate);
  }

  previousMonth(): void {
    this.viewDate = subMonths(this.viewDate, 1);
    this.viewChanged.emit(this.viewDate);
  }

  nextYear(): void {
    this.viewDate = addYears(this.viewDate, 1);
    this.viewChanged.emit(this.viewDate);
  }

  previousYear(): void {
    this.viewDate = subYears(this.viewDate, 1);
    this.viewChanged.emit(this.viewDate);
  }

  showYearView(): void {
    this.currentView.next(this.yearsView);
  }

  showMonthView(): void {
    this.currentView.next(this.monthView);
  }

  showMonthsView(): void {
    this.currentView.next(this.monthsView);
  }

  selectYear(year: number) {
    this.viewDate = setYear(this.viewDate, year);
    this.viewChanged.emit(this.viewDate);
    this.showMonthsView();
  }

  selectMonth(monthIndex: number) {
    this.viewDate = setMonth(this.viewDate, monthIndex);
    this.viewChanged.emit(this.viewDate);
    this.showMonthView();
  }

  nextYearsInterval(lastIntervalYear: number): void {
    this.updateYears(lastIntervalYear + 1);
  }

  previousYearsInterval(firstIntervalYear: number): void {
    // TODO: Uncomment if we wish to disable letting user navigate to past years
    // const currentYear = getYear(this.todaysDate);
    const yearToStartFrom = firstIntervalYear - this.yearsViewIntervalSize;
    /* if (this.disablePastDays && yearToStartFrom < currentYear) {
      yearToStartFrom = currentYear;
    } */
    this.updateYears(yearToStartFrom);
  }

  private combineCurrentDayAndEvents(): void {
    const filteredEvents = this.events?.filter(event => event.title !== 'selectedPeriod') || [];
    this.allEvents.next([
      {
        title: 'selectedPeriod',
        start: this.date,
        end: this.date
      },
      ...filteredEvents
    ]);
  }

  private updateLocalizedMonths(locale: string) {
    const localeMonths = [];
    for (let i = 0; i < 12; i++) {

      const monthDate = new Date(2000, i, 1);
      localeMonths.push(
        formatDate(monthDate, 'MMM', locale
        ));
    }
    this.months.next(localeMonths);
  }

  private updateYears(startYear: number) {
    const newYearsArr = [];
    for (let i = 0; i < this.yearsViewIntervalSize; i++) {
      newYearsArr.push(startYear + i);
    }
    this.years.next(newYearsArr);
    this.yearInterval = {
      from: newYearsArr[0],
      to: newYearsArr[newYearsArr.length - 1]
    };
  }
}
