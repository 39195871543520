import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import SwiperCore, { Navigation, Pagination, Lazy, Virtual } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Navigation, Pagination, Lazy, Virtual]);

@Component({
  selector: 'jarvis-ui-marketplace-card-photo-swiper',
  templateUrl: 'photo-swiper.component.html',
  styleUrls: ['photo-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JarvisUiMarketplacePhotoSwiperComponent
  implements OnInit, OnDestroy
{
  @ViewChild(SwiperComponent, { static: true }) swiper: SwiperComponent;

  @Input() 
  set images(images: any[]) {
    if (this.swiper?.swiperRef) {
      this.swiper.swiperRef.virtual?.removeAllSlides();
    }
    this._images = (images || []).map(url => ({ url }));
  }
  get images() {
    return this._images;
  }
  _images: {url: string}[] = [];
  @Input() title: string;
  @Input() swiperRefresh: Subject<void>;

  @Output() photoSwiped = new EventEmitter();

  private destroy$ = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.swiper.indexChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.photoSwiped.emit();
    });

    if (this.swiperRefresh) {
      this.swiperRefresh.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.swiper.swiperRef.update();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.photoSwiped.complete();
  }
}
