import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-stripe-acc',
  encapsulation: ViewEncapsulation.None,  
  styles: [`
  @media (min-width: 768px) { 
     .confirm-dialog .mat-dialog-container {border-radius:12px!important;} 
  }

  .confirmation .modal-body-container {display: flex; flex-direction: column;}
  .confirmation .message { color:#717171!important; font-size: 14px;}     
  .confirmation .message.fw-bold { color:black!important; font-size: 16px;}      
  .confirmation .header { font-size:26px }  
  .confirmation button {max-width:400px; width:100%;}
  .confirmation .icon {color:#ef5518; width:60px; font-size:60px }
  .confirmation ul { list-style-type: disc; padding: 0px 30px 0px 30px; text-align: left; }
  .confirmation ul li::marker { color: blue;  font-size: 20px;}
  `],  
  template:  `
<app-modal-wrapper class="modal-wrapper confirmation" (closed)="close(null)">  
<div class="h-100 w-100 pt-md-3 pb-md-3 ps-md-5 pe-md-5 d-flex flex-column align-items-center justify-content-between">  
    <div></div>
    <div class="d-flex flex-column align-items-center mb-5">
       <div class="header mt-3">{{ data.i18nKey+ ".title" | translate }}</div>
  
       <span class="mt-3 message">{{ data.i18nKey + ".message" | translate }}</span>
       <span *ngIf="isMessageSet('messageBold')" class="mt-3 message bztt-font-bold">{{ data.i18nKey + ".messageBold" | translate }}

       </span>
       <ng-container *ngIf="isMessageSet('list.item1')">
         <ul>
          <ng-container *ngFor="let item of [].constructor(10); let i = index">
          
             <li class="message" *ngIf="isMessageSet('list.item' + i)">
                 {{data.i18nKey + '.list.item' + i | translate:item}}
             </li>                        
           </ng-container>           
          </ul>
       </ng-container>
    </div>
  
   
  <div class="d-flex flex-column flex-md-row-reverse w-100 justify-content-center">
      <div class="d-flex justify-content-center pb-3 w-100">
          <button app-button (click)="confirm()" class="bztt-mx-2" color="accent">{{data.i18nKey+ ".confirm" | translate}}</button>
          <button app-button class="bztt-mx-2" *ngIf="!data.i18nKey.endsWith('.alreadyConnectedComponentMsg')" (click)="addExistingAccount()" color="accent">{{data.i18nKey+ ".addExisting" | translate}}</button>
      </div>
   </div>
   <!--<div class="d-flex bztt-pb-2 justify-content-center w-100 bztt-mb-5" *ngIf="!data.i18nKey.endsWith('.alreadyConnectedComponentMsg')"><a (click)="addExistingAccount()" class="remove">{{data.i18nKey+ ".addExisting" | translate}}</a></div> -->
  </div>    
</app-modal-wrapper>
`
})

export class ModalStripeAccountComponent  implements OnInit {

constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data:  { i18nKey?: string },
    @Optional() private dialogRef: MatDialogRef<ModalStripeAccountComponent>,
    private translatePipe: TranslatePipe
  ) {

  }

  public isMessageSet(key: string): boolean {
    return this.translatePipe.transform(this.data.i18nKey + '.' + key) !== (this.data.i18nKey + '.' + key);
  }

  public cancel(): void {
    this.close(false);
  }

  public close(value): void {
    this.dialogRef.close(value);
  }  

  public confirm(): void {
    this.close("connect");
  }

  addExistingAccount() {
    this.close("addExisting");
  }
    

ngOnInit(): void { 
}  
}  