<jarvis-ui-modal-wrapper class="modal-wrapper" (closed)="close()">
  <div class="select-service h-100 w-100 d-flex justify-content-center">
    <div
      class="h-100 w-100 select-service-container d-flex flex-column align-items-center justify-content-between"
    >
      <div></div>
      <div class="d-flex w-100 flex-column align-items-center mb-3">
        <div class="pb-3 fs-4 fw-bolder">
          {{ 'bookings.selectService.title' | translate }}
        </div>

        <app-d-form
          #selectServiceForm
          i18nKey="bookings.selectService"
          [model]="model"
          noButton="true"
          class="w-100"
          [template1]="template1"
          (onAction)="formAction($event)"
          [descriptor]="forms['newBooking']"
        >
        </app-d-form>
      </div>
      <button
        app-button
        color="accent"
        [disabled]="isInvalidForm()"
        (click)="submit()"
      >
        <span>{{ 'bookings.selectService.save' | translate }}</span>
      </button>
    </div>
  </div>
</jarvis-ui-modal-wrapper>

<ng-template #template1 let-form="form">
  <mat-form-field *ngIf="isCanSelectEventTypes(form)" appearance="outline">
    <mat-label>{{
      'bookings.selectService.serviceType' | translate
    }}</mat-label>
    <mat-select [formControl]="form.get('serviceType')">
      <mat-option *ngFor="let item of getEventTypes(form)" [value]="item"
        >{{ 'eventTypes.' + item | translate }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('serviceType').hasError('required')">
      {{ 'd-form.errors.required' | translate }}
    </mat-error>
  </mat-form-field>
</ng-template>
