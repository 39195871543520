import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ServiceDeposit'
})
export class JarvisServicesDepositPipe implements PipeTransform {
  transform(totalPrice: number, advancesInfo: any): number {
    if (!advancesInfo) {
      return 0;
    }

    if (advancesInfo.fullAdvance) {
      return totalPrice;
    }

    if (advancesInfo.priceUnit === 'percentage') {
      return totalPrice * (advancesInfo.price / 100);
    }

    if (advancesInfo.priceUnit === 'fixed') {
      return advancesInfo.price > totalPrice ? totalPrice : advancesInfo.price;
    }

    return 0;
  }
}
