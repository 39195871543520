import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { Resolve } from '@angular/router';
import { HomepageStructuredDataStrategy } from './homepage-structured-data.strategy';
import { JarvisSeoService } from '@jarvis/seo';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';

export class HomepageSeoResolver implements Resolve<void> {

  private domainCountry = inject(DOMAIN_COUNTRY);
  private document = inject(DOCUMENT);
  private seoService = inject(JarvisSeoService);

  resolve() {
    const homepageStructuredDataStrategy = new HomepageStructuredDataStrategy(
      this.document,
      this.domainCountry
    );
    this.seoService.structuredDataStrategy = homepageStructuredDataStrategy;
  }
}