
import { DOMAIN_COUNTRY, JarvisTrackingService } from '@jarvis/services';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Inject,
  DEFAULT_CURRENCY_CODE,
  ViewChild,
  OnDestroy,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingsDataService } from '../../bookings.service';
import { SelectServiceComponent } from '../select-service/select-service.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { debounceTime, startWith, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import {
  BookingStateKeys,
  BookingStateEnum,
  BookingOrderByEnum,
  ServiceListType,
  ServiceTypeEnum,
  BookingsType,
  BookingsResult,
  BookingsUtil as BUtil,
} from '@jarvis/types';
import { DateUtils } from '@jarvis/utils';
import {
  JarvisUiPaginatorComponent,
  PageChangeEvent,
} from '@jarvis/ui/paginator';
import { UntypedFormControl } from '@angular/forms';
import { CommonDialogService } from '@jarvis/ui';
import { MatSelectChange } from '@angular/material/select';
import { throws } from 'assert';

@Component({
  selector: 'bookings-list',
  templateUrl: './list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnDestroy {
  @ViewChild(JarvisUiPaginatorComponent) paginator: JarvisUiPaginatorComponent;

  private pagination$: BehaviorSubject<PageChangeEvent> = new BehaviorSubject(
    null
  );

  public bookingStateEnum = BookingStateEnum;
  public serviceTypeEnum = ServiceTypeEnum;
  public bookingsUtil = BUtil;
  public list: BookingsType[];
  public bookingFilterKeys = Object.keys(BookingStateEnum); //.sort((a,b)=> a==b?0:['new','cancelled','communication','reserved','completed'].includes(a)?1:-1);
  public bookingOrderByKeys = Object.keys(BookingOrderByEnum).filter((v) =>
    isNaN(Number(v))
  );
  public shownByKeys = ['all', 'active', 'deleted']; //, 'qualified'];
  public bookingKeys =
    Object.keys(BookingStateEnum).concat('canceledByCustomer');
  public serviceBase: ServiceListType[];
  public userId: string;
  public count: number;
  public startingCount: number;
  private translationService = inject(TranslateService);
  private destroy$ = new Subject<void>();

  filterSelected = new UntypedFormControl([
    { _state: 'signing' },
    { _state: 'meetingBooked' },
    { _state: 'proposalSubmitted' },
    { _state: 'payment' },
    { _state: 'reserved' },
    { _state: 'completed' },
  ]);
  orderByControl = new UntypedFormControl(this.bookingOrderByKeys[0]);
  showControl = new UntypedFormControl(this.shownByKeys[1]);
  searchPattern = new UntypedFormControl(null, { initialValueIsDefault: true });
  private prevState: BookingStateKeys;
  public dateUtils = DateUtils;
  public pageSize = BookingsDataService.pageSize;
  public dataObtained = false;
  public initiateServiceBaseFilter: string;

  private trackService = inject(JarvisTrackingService);

  constructor(
    private route: ActivatedRoute,
    @Inject(DEFAULT_CURRENCY_CODE) public currencyCode1: string,
    private router: Router,
    private commonDialogService: CommonDialogService,
    private translatePipe: TranslatePipe,
    public listService: BookingsDataService,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string
  ) {
    const nav = this.router.getCurrentNavigation();

    this.serviceBase = BUtil.transformServiceBase(
      this.route.snapshot.data.serviceBase,
      this.translationService
    );

    this.initiateServiceBaseFilter =
      this.router.getCurrentNavigation()?.extras?.state?.find;

    if (this.initiateServiceBaseFilter)
      this.filterSelected.patchValue([
        { serviceName: this.initiateServiceBaseFilter },
      ]);

    if (
      !(
        nav &&
        nav.previousNavigation.finalUrl
          .toString()
          .match(/^\/(messages|bookings).*/)
      )
    )
      this.listService.currentPage = 1;
  }

  public changeState(event: MatSelectChange, item: BookingsType) {
    try {
      item.state = event.value;
      if (BUtil.changeState(this.prevState, item)) {
        this.listService.changeState(item).subscribe(() =>
          this.trackService.handleEvent({
            trackers: ['amplitude', 'mixpanel'],
            eventName: 'inquiry_status_' + event.value,
            data: {
              bookingId: item._id,
              userType: 'vendor',
              serviceName: item.serviceName,
              serviceType: item.serviceType,
            },
          })
        );
      }
    } catch (e) {
      this.commonDialogService.openSuccess(
        'bookings.stateChangeError',
        'error',
        undefined,
        this.translatePipe.transform('bookings.state.' + item.state + '.name')
      );
      item.state = this.prevState;
      event.source.writeValue(this.prevState);
    }
  }

  public compareFilterObjects(object1: any, object2: any) {
    return (
      object1 &&
      object2 &&
      ((object1._state && object1._state === object2._state) ||
        (object1.serviceName && object1.serviceName === object2.serviceName))
    );
  }

  public getState(item): BookingStateKeys {
    return BUtil.transformState(item);
  }

  public openedChange(opened: boolean, item: BookingsType = null) {
    if (item?.state) this.prevState = item.state;
    if (opened) document.body.classList.add('disable-scroll');
    else document.body.classList.remove('disable-scroll');
  }

  public newBooking() {
    //, serviceEvent: item.serviceEvents[0]
    const modalRef = this.commonDialogService.openCustomWDialog(
      SelectServiceComponent,
      this.serviceBase,
      'select-service-dialog'
    );
  }

  public toChatRoom(item: BookingsType) {
    this.listService.toChatRoom(item);
  }

  openTutorialDialog(): void {
    this.router.navigate(['/video-presentation']);
  }

  public pageChange(evnt) {
    this.listService.currentPage = evnt.pageIndex + 1;
    this.pagination$.next(evnt);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  newService() {
    this.router.navigate(['services/new']);
  }

  public ngOnInit(): void {
    const contextSearch = this.searchPattern.valueChanges.pipe(
      debounceTime(200),
      tap((v) => (this.listService.currentPage = 1))
    );

    combineLatest([
      this.pagination$,
      contextSearch.pipe(startWith(null)),
      this.orderByControl.valueChanges.pipe(
        startWith(this.orderByControl.value)
      ),
      this.showControl.valueChanges.pipe(startWith(this.showControl.value)),
      this.filterSelected.valueChanges.pipe(
        startWith(this.filterSelected.value),
        tap((v) => (this.listService.currentPage = 1)),
        startWith(
          this.initiateServiceBaseFilter
            ? [{ serviceName: this.initiateServiceBaseFilter }]
            : null
        )
      ),
    ])
      .pipe(
        switchMap(
          ([pagination, contextSearch, orderBy, show, selectedFilter]) => {
            const filter = selectedFilter || [];
            const addParam = [];

            let sort = { sort: null, sortOrder: null };

            switch (Number(BookingOrderByEnum[orderBy])) {
              case BookingOrderByEnum.eventDateAsc:
                sort = { sort: 'eventDate', sortOrder: 1 };
                break;
              case BookingOrderByEnum.eventDateDesc:
                sort = { sort: 'eventDate', sortOrder: -1 };
                break;
              default:
                sort = { sort: 'createdAt', sortOrder: -1 };
            }

            if (contextSearch) {
              addParam.push({
                $or: [
                  {
                    state: { $regex: '^' + contextSearch + '*', $options: 'i' },
                  },
                  { 'customer.name': { $regex: contextSearch, $options: 'i' } },
                  {
                    'customer.email': { $regex: contextSearch, $options: 'i' },
                  },
                  {
                    'customerId.name': { $regex: contextSearch, $options: 'i' },
                  },
                  {
                    'customerId.email': {
                      $regex: contextSearch,
                      $options: 'i',
                    },
                  },
                ],
              });
            }

            const showDeleted = ['all', 'deleted'].includes(show);
            addParam.push({
              $and: [
                // show == 'qualified'
                //   ? {"options.adminData.introductionSent":true} : null ,
                show == 'deleted'
                  ? { deletedBy: { $exists: true, $ne: [] } }
                  : null,
                {
                  $or: [
                    ...filter
                      .filter((v) => v._state)
                      .map((v) => ({ state: v._state })),
                  ],
                },
                {
                  $or: filter
                    .filter((v) => v.serviceName)
                    .map((v) => ({ serviceName: v.serviceName })),
                },
              ].filter(
                (v: any) =>
                  v &&
                  (v.deletedBy || //|| v["options.adminData.introductionSent"]
                    v.$or?.length)
              ),
            });

            const filteredAddParam = addParam.filter(
              (v) => v && (Object.values(v)[0] as any[]).length
            );

            return this.listService.getBookingsList(
              this.listService.currentPage,
              BookingsDataService.pageSize,
              filteredAddParam?.length ? { $and: filteredAddParam } : null,
              sort.sort,
              sort.sortOrder,
              showDeleted
            );
          }
        ),
        tap((v) => (this.dataObtained = true)),
        //tap((v) => (this.startingCount = this.startingCount || v.count)),
        tap(
          (data: BookingsResult) =>
            (this.list = data.docs.map((itm) => ({
              ...itm,
              _state: BUtil.transformState(itm),
            })))
        ),
        tap((data: BookingsResult) => (this.count = data.count ?? 0))
      )
      .subscribe();

    //this.list = this.route.snapshot.data.list.docs.map((itm) => ({
    //   ...itm,
    //   _state: BUtil.transformState(itm as LibBookingsType)
    // }));
    this.userId = this.route.snapshot.data?.currentUser?._id;

    this.startingCount = this.serviceBase.length;
  }
}
