<div
  class="curated-container bztt-flex bztt-justify-center bztt-items-center bztt-h-full bztt-w-full bztt-py-[78px] bztt-px-6"
>
  <div
    class="curated-inner-container bztt-max-w-[84rem] bztt-w-full bztt-flex bztt-justify-between bztt-gap-12 bztt-items-center"
  >
    <div class="bztt-w-full">
      <img
        class="bztt-hidden md:bztt-block"
        [src]="
          '/assets/images/landing/homepage-uplift/budgetPlanner/planner_' +
          country +
          '_desktop.svg'
        "
      />
      <img
        class="md:bztt-hidden"
        [src]="
          '/assets/images/landing/homepage-uplift/budgetPlanner/planner_' +
          country +
          '_mobile.svg'
        "
      />
    </div>
    <div class="bztt-flex bztt-flex-col bztt-gap-7">
      <div class="curated-section-title bztt-max-w-xs">
        <span class="bztt-text-[#EF5518]">
          {{ 'landing.homepageUplift.budgetPlanner.title.1' | translate }}
        </span>
        <span class="bztt-text-[#1F1F1F]">
          {{ 'landing.homepageUplift.budgetPlanner.title.2' | translate }}
        </span>
      </div>
      <div class="bztt-text-[#6B6060] bztt-text-start">
        {{ 'landing.homepageUplift.budgetPlanner.description' | translate }}
      </div>
      <div class="bztt-flex bztt-flex-col bztt-gap-4">
        <div class="bztt-flex bztt-justify-start bztt-items-center bztt-gap-3">
          <mat-icon>check_circle_outline</mat-icon>
          <div class="bztt-font-semibold">
            {{ 'landing.homepageUplift.budgetPlanner.items.1' | translate }}
          </div>
        </div>
        <div class="bztt-flex bztt-justify-start bztt-items-center bztt-gap-3">
          <mat-icon>check_circle_outline</mat-icon>
          <div class="bztt-font-semibold">
            {{ 'landing.homepageUplift.budgetPlanner.items.2' | translate }}
          </div>
        </div>
      </div>

      <a
        [routerLink]="[country === 'us' ? '/planner' : '/planuoklis']"
        [queryParams]="{ t: 1 }"
        class="bztt-border-none"
      >
        <button app-button color="accent" class="bztt-w-fit">
          <div
            class="bztt-flex bztt-flex-row bztt-justify-center bztt-items-center bztt-gap-3 bztt-px-7"
          >
            {{ 'landing.homepageUplift.budgetPlanner.button' | translate }}
          </div>
        </button>
      </a>
    </div>
  </div>
</div>
