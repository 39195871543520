import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  FlexibleConnectedPositionStrategy,
  GlobalPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { hasModifierKey } from '@angular/cdk/keycodes';
import { TimePickerInputDirective } from '../../directives/time-picker-input.directive';
import { JarvisUiTimePickerContentComponent } from '../picker-content/time-picker-content.component';

@Component({
  selector: 'jarvis-ui-timepicker',
  template: '',
})
export class JarvisUiTimePickerComponent implements OnInit, OnDestroy {
  @Input() xPosition = 'start';
  @Input() yPosition = 'below';

  @Input() closeOnSelect = true;
  @Input() allowTimeBefore = 1441;
  @Input() allowTimeAfter = -1;

  @Input()
  set dateEvents(value: any[]) {
    this._dateEvents = value;
  }
  private _dateEvents: any[];

  @Input()
  set currentDate(date: Date) {
    this._currentDate = date;
  }
  private _currentDate: Date;

  /**
   * Mobile view opens up a dialog instead of an overlay
   */
  @Input()
  get isMobile() {
    return this._isMobile;
  }
  set isMobile(value: BooleanInput) {
    this._isMobile = coerceBooleanProperty(value);
  }
  private _isMobile = false;

  @Output() timeSelected = new EventEmitter();

  private _overlayRef: OverlayRef | null;

  private _componentRef: ComponentRef<JarvisUiTimePickerContentComponent> | null;

  private timepickerInput: TimePickerInputDirective;

  private destroy$ = new Subject<void>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay
  ) {}

  ngOnInit() {}

  registerInput(inputComponentInstance: TimePickerInputDirective) {
    this.timepickerInput = inputComponentInstance;
    this.timepickerInput.onFocus
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.open();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  open() {
    this.destroyExistingOverlay();

    const portal = new ComponentPortal(
      JarvisUiTimePickerContentComponent,
      this.viewContainerRef
    );
    const isMobile = this.isMobile;

    const overlayRef = (this._overlayRef = this.overlay.create(
      new OverlayConfig({
        positionStrategy: isMobile
          ? this.getDialogStrategy()
          : this.getDropdownStrategy(),
        hasBackdrop: true,
        backdropClass: isMobile
          ? 'cdk-overlay-dark-backdrop'
          : 'cdk-overlay-transparent-backdrop',
        scrollStrategy: isMobile
          ? this.overlay.scrollStrategies.block()
          : this.overlay.scrollStrategies.reposition(),
        width: `${this.timepickerInput.getWidth()}px`,
      })
    ));

    this._componentRef = overlayRef.attach(portal);

    this._componentRef.instance.setTime(this.timepickerInput.currentValue);
    this._componentRef.instance.dateEvents = this._dateEvents;
    this._componentRef.instance.allowTimeAfter = this.allowTimeAfter;    
    this._componentRef.instance.allowTimeBefore = this.allowTimeBefore;        
    this._componentRef.instance.currentDate = this._currentDate;

    this._componentRef.instance.timeSelection
      .pipe(takeUntil(this._componentRef.instance.destroy$))
      .subscribe((newTimeValue) => {
        this.timepickerInput.setDateFromMinutes(newTimeValue);

        const hours = Math.floor(newTimeValue / 60);
        const minutes = newTimeValue % 60;
        const hourValue = hours.toString().padStart(2, '0');
        const minuteValue = minutes.toString().padStart(2, '0');

        this.timeSelected.emit(`${hourValue}:${minuteValue}`);

        if (this.closeOnSelect) {
          this.close();
        }
      });

    this.closeEvents(overlayRef).subscribe((event) => {
      if (event) {
        event.preventDefault();
      }
      this.close();
    });
  }

  close(): void {
    this.destroyExistingOverlay();
  }

  getDialogStrategy(): GlobalPositionStrategy {
    return this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
  }

  getDropdownStrategy(): FlexibleConnectedPositionStrategy {
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.timepickerInput.getOverlayOrigin())
      // .withTransformOriginOn('.mat-datepicker-content')
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withLockedPosition();

    return this.setConnectedPositions(strategy);
  }

  private setConnectedPositions(strategy: FlexibleConnectedPositionStrategy) {
    const primaryX = this.xPosition === 'end' ? 'end' : 'start';
    const secondaryX = primaryX === 'start' ? 'end' : 'start';
    const primaryY = this.yPosition === 'above' ? 'bottom' : 'top';
    const secondaryY = primaryY === 'top' ? 'bottom' : 'top';

    return strategy.withPositions([
      {
        originX: primaryX,
        originY: secondaryY,
        overlayX: primaryX,
        overlayY: primaryY,
      },
      {
        originX: primaryX,
        originY: primaryY,
        overlayX: primaryX,
        overlayY: secondaryY,
      },
      {
        originX: secondaryX,
        originY: secondaryY,
        overlayX: secondaryX,
        overlayY: primaryY,
      },
      {
        originX: secondaryX,
        originY: primaryY,
        overlayX: secondaryX,
        overlayY: secondaryY,
      },
    ]);
  }

  private destroyExistingOverlay(): void {
    if (this._overlayRef) {
      this._overlayRef.detach();
      this._overlayRef = null;
    }
  }

  private closeEvents(overlayRef: OverlayRef) {
    return merge(
      overlayRef.backdropClick(),
      overlayRef.detachments(),
      overlayRef.keydownEvents().pipe(
        filter((event) => {
          return (
            (event.key === 'Escape' && !hasModifierKey(event)) ||
            (this.timepickerInput &&
              hasModifierKey(event, 'altKey') &&
              event.code === 'ArrowUp')
          );
        })
      )
    );
  }
}
