import { sections } from './saas-landing.constants';

import { DOCUMENT } from '@angular/common';
import {
  Component,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { JarvisAuthService, User } from '@jarvis/auth';
import {
  DOMAIN_COUNTRY,
  LinkUrls,
  LINK_URLS,
  JarvisTrackingService,
} from '@jarvis/services';
import { LayoutService } from '@jarvis/ui';
import { Subject, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { HeaderService } from '@jarvis/header';

@Component({
  selector: 'jarvis-saas-landing-root',
  templateUrl: './saas-landing.component.html',
  styleUrls: ['./saas-landing.component.scss'],
})
export class SaasLandingComponent implements OnInit, OnDestroy {
  @ViewChild('landingHeader', { static: true })
  landingHeader: TemplateRef<HTMLDivElement>;

  private trackService = inject(JarvisTrackingService);


  window = window;

  marketplaceLandingLink = `${this.linkUrls.marketplace}/event-hosts`;

  isMobile = false;
  loggedIn$ = this.authService.isLoggedIn$;

  sections = sections;

  private destroy$ = new Subject<void>();

  constructor(
    private layoutService: LayoutService,
    private headerService: HeaderService,
    private router: Router,
    private authService: JarvisAuthService,
    @Inject(LINK_URLS) private linkUrls: LinkUrls,
    @Inject(DOCUMENT) private document: Document,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string
  ) {}

  ngOnInit(): void {
    this.headerService.setSaasHeader();
    this.layoutService.disableFooterMargin();

    this.layoutService.isMobileObserver
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => (this.isMobile = isMobile));

    // this.headerService.setHeaderTemplate(this.landingHeader, this.vcr);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.headerService.setSaasHeader(false);
    this.layoutService.enableFooterMargin();
  }

  handleTrackClick(event: string, data?): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: event,
      data,
    });
  }

  // openContactFormDialog(): void {
  //   this.authService.userData$.pipe(
  //     take(1),
  //   ).subscribe(userData => {
  //     if (!userData) {
  //       this.router.navigateByUrl('/auth/register', { state: { userType: 'vendor' } });
  //       this.authService.createRegisterOrLoginSuccessStream().subscribe(() => {
  //         window.location.href = this.linkUrls.saas;
  //       });
  //       return;
  //     }

  //     if (userData.userType === 'user') {
  //       this.router.navigateByUrl('/planner');
  //       return;
  //     }

  //     window.location.href = this.linkUrls.saas;
  //   });

  // }

  // marketplaceRedirrect() {
  //   window.location.href = this.linkUrls.marketplace;
  // }

  // private navigateToFirstHomePage(userType: 'user' | 'vendor'): void {
  //   if (userType === 'user') {
  //     this.router.navigateByUrl('/photographer');
  //     return;
  //   }

  //   window.location.href = this.linkUrls.saas;
  // }

  get vendorSignupLink(): Observable<any> {
    return this.authService.userData$.pipe(
      take(1),
      map((userData) => {
        if (userData) {
          return {
            link: this.getFirstPageNavigationUrl(userData.userType),
          };
        }

        const signUpLink =
          this.domainCountry === 'lt' ? '/registracija' : '/sign-up';

        return {
          link: signUpLink,
        };
      })
    );
  }

  private getFirstPageNavigationUrl(userType: 'user' | 'vendor'): string {
    // if (userType === 'user') {
    //   return './photographers';
    // }

    return './' + this.linkUrls.saas;
  }

  handleVendorSignup(location: string) {
    this.handleTrackClick('create_profile_click', { location });

    // this.authService.userData$.pipe(take(1)).subscribe((userData) => {
    //   if (userData) {
    //     this.navigateToFirstHomePage(userData.userType);
    //     return;
    //   }
    //   window.location.replace(window.location.origin + '/sign-up?source=' + location);

    //   // this.router.navigate(['sign-up'], {
    //   //   queryParams: { source: location }
    //   // });
    // });
  }

  openLoginDialog(): void {
    this.handleTrackClick('log_in_click');

    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (!userData) {
        this.router.navigateByUrl('/auth/login');
        this.authService
          .createRegisterOrLoginSuccessStream()
          .subscribe((newUserData: User) => {
            // if (newUserData && newUserData.userType === 'user') {
            //   this.router.navigateByUrl('/planner');
            //   return;
            // }

            window.location.href = this.linkUrls.saas;
          });
        return;
      }

      // if (userData.userType === 'user') {
      //   this.router.navigateByUrl('/planner');
      //   return;
      // }

      window.location.href = this.linkUrls.saas;
    });
  }

  marketplaceRedirrect() {
    window.location.href = this.linkUrls.marketplace;
  }

  navigateToSection(sectionId): void {
    if (sectionId !== 'free_profile')
      this.handleTrackClick('solutions_click', {
        solution_selected: sectionId,
        page_url: window.location.href,
      });

    const html = document.getElementById('bContent');
    const element = document.getElementById(sectionId) as HTMLElement;
    html.scrollTo({ top: element.offsetTop - 125, behavior: 'smooth' });
  }

  openPricingPopup() {
    this.handleTrackClick('pricing_click');
    this.navigateToSection('free_profile');

    // const dialogRef = this.modalService.openDialog(PricingPopupDialogComponent, { panelClass: 'pricing-popup-dialog', scrollStrategy: new NoopScrollStrategy() });
    // dialogRef.afterClosed().subscribe(res => {
    //   if (!res) return;

    //   window.scrollTo({ top: 0 });
    //   this.handleVendorSignup('vendor-pricing-popup');
    // });
  }
}
