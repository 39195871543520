<div class='uploader-wrapper'>
    <div class="upload-box" *ngIf="(!filesUploading); else progressIndicator">
        <img src="assets/icons/ic-file-upload.svg" />
        <a (click)="pdfInput.click()">{{ title || 'actions.uploadFiles' | translate}}</a>
    </div>
    <div *ngIf="formArray" class="uploaded-files-container">
        <div *ngFor="let file of files$ | async; let i = index;" class="uploaded-file">
            <mat-icon class='attachement'>attach_file</mat-icon>
            <span class="file-name">{{file.fileName}}</span>
            <mat-icon class='clear' (click)="removeFile(i)">clear</mat-icon>
        </div>
    </div>
</div>

<input #pdfInput hidden multiple [accept]="accept" type="file" (change)="uploadFiles($event)" />

<ng-template #progressIndicator>
    <div class='progress-container'>
        <div class='spinner-percent-wrapper'>
            <mat-progress-spinner
                [diameter]="60"
                [strokeWidth]="2"
                mode='determinate'
                [value]="fileUploadProgress$ | async"
            ></mat-progress-spinner>
            <span class='progress-percentage'>{{fileUploadProgress$ | async}} %</span>
        </div>

        <span>{{'actions.uploadingFiles' | translate}}</span>
    </div>
</ng-template>
