import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'marketplace-messages-chatrooms-information',
  templateUrl: 'chatrooms-information.component.html',
  styleUrls: ['./chatrooms-information.component.scss'],
})
export class MessagesChatroomsInformationComponent implements OnInit {
  @Input() message: string;
  @Input() icon: string;

  ngOnInit(): void {
      
  }
}