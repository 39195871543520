<header #header class="bztt-h-[70px] md:bztt-h-[90px]">
  <a class="logo-container" routerLink="/">
    <img src="/assets/images/bzt-logo.svg" alt="Breezit Logo" />
  </a>

  <marketplace-top-search class="bztt-w-full bztt-max-w-md md:bztt-mr-24"></marketplace-top-search>

  <div class="link-wrapper bztt-hidden lg:bztt-flex">
    <main-header-menu [headerRef]="header"></main-header-menu>
  </div>

  <img
    class="bztt-cursor-pointer bztt-flex lg:bztt-hidden"
    src="/assets/images/header_mobile_menu_open.svg"
    (click)="openMobileMenu()"
  />
</header>
