import { Component, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { AbstractModalComponent } from '@jarvis/foxy-vendor/app/shared/abstract/abstract-modal.component';
import { DFormComponent } from '@jarvis/foxy-vendor/app/shared/components/d-form/d-form.component';
import { FormActionType, JarvisUiModalService } from '@jarvis/ui';
import { ModalService } from '@breezit/vendor/app/shared/services/modal.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-customer-email',
  template: `
    <jarvis-ui-card-wrapper
      class="modal-wrapper medium-modal"
      (closed)="close()"
    >
      <div class="h-100 w-100 d-flex justify-content-center">
        <div
          class="h-100 w-100 balance-container d-flex flex-column align-items-center justify-content-between"
        >
          <div
            class="d-flex w-100 flex-column align-items-center pt-4 pb-4 mb-3"
          >
            <div class="pb-5 fs-4 fw-bolder">
              {{ i18nKey + '.title' | translate }}
            </div>
            <div class="d-flex flex-column align-items-center pb-5">
              <span class="mt-3 message">{{
                i18nKey + '.message' | translate
              }}</span>
            </div>

            <app-d-form
              #dform
              [i18nKey]="i18nKey"
              class="w-100"
              [model]="customerEditData"
              (onAction)="formAction($event)"
              [descriptor]="customerEditForm"
            >
            </app-d-form>
          </div>
        </div>
      </div>
    </jarvis-ui-card-wrapper>
  `,
  styles: [
    `
      .customer-email button {
        max-width: 300px;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerMailComponent extends AbstractModalComponent<{
  email: string;
}> {
  @ViewChild('dform') dform;

  i18nKey = 'bookings.customerEMail';

  constructor(
    private dialogR: MatDialogRef<AbstractModalComponent<{ email: string }>>,
    private modalSrv: ModalService,
    @Inject(MAT_DIALOG_DATA) private dt: any
  ) {
    super(dialogR, modalSrv, dt);

    if (dt.i18nkey) this.i18nKey = dt.i18nkey;
  }

  public submit() {
    super.close(this.dform.dformGroup.value);
  }

  public customerEditData: { email: string };

  public formAction(evnt: FormActionType<{ email?: string }>) {
    if (evnt.type == 'cancel') this.close();
    else this.close(evnt.model);
  }

  public customerEditForm = DFormComponent.newFormDescriptor<{ email: string }>(
    {
      controls: {
        email: { type: 'email', required: true },
      },
    }
  );

  ngOnInit(): void {}
}
