import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SmallCalendarComponent } from './small-calendar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { CalendarClassesPipe } from './pipes/calendar-class.pipe';
import { CalendarDiscountPipe } from './pipes/calendar-discount.pipe';
import { CalendarDateFormatterService } from './services/calendar-date-formatter.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LayoutService } from '../../layout';
import { CurrentMonthPipe } from './pipes/is-current-month.pipe';
import { CurrentYearPipe } from './pipes/is-current-year.pipe';
import { CalendarMarkupPipe } from './pipes/calendar-markup.pipe';



@NgModule({
  declarations: [
    SmallCalendarComponent,
    CalendarClassesPipe,
    CalendarDiscountPipe,
    CalendarMarkupPipe,
    CurrentMonthPipe,
    CurrentYearPipe
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    CalendarModule,
  ],
  providers: [
    CalendarDateFormatterService,
    LayoutService
  ],
  exports: [
    SmallCalendarComponent
  ]
})
export class SmallCalendarModule { }
