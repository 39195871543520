import { Component, inject } from '@angular/core';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';
import { UntypedFormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Observable, delay, startWith } from 'rxjs';
import { Router } from '@angular/router';
import { HttpRequestState } from '@jarvis/utils';
import { UserProfilingData } from 'libs/user-profiling/src/lib/types/user-profiling.types';

const CALENDLY_LINK = 'https://just-breezit.na.chilipiper.com/book/me/augustas-ausra?type=introduction-to-breezit-demo-call';

@Component({
  templateUrl: './book-a-call.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class BookACallComponent {

  formControl: UntypedFormControl;
  formValue$: Observable<boolean>;

  upload$: Observable<HttpRequestState<UserProfilingData>> | null = null;

  private document = inject(DOCUMENT);
  private router = inject(Router);
  
  constructor(
    private profilingFormService: JarvisProfilingFormService
  ) {
    this.formControl = this.profilingFormService.getStepControl('bookACallPressed');
    this.formValue$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value)
    );
  }

  openBookACallLink(): void {
    this.document.defaultView?.open(CALENDLY_LINK);

    this.formControl.setValue(true);
  }

  next(): void {
    this.upload$ = this.profilingFormService.submitProfilingInfo({
      completedValue: true
    });

    this.upload$.pipe(delay(1000)).subscribe({
      next: (state) => {
        if (!state.isLoading && !state.error) {
          this.profilingFormService.trackFieldCaptureEvent('book_a_call');
          this.router.navigate(['services', 'new']);
        }
      }
    });
    
  }

}
