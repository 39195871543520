import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'jarvis-loading-indicator-simple',
  template: `
    <svg #loadingElement id="dots-wrapper" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Page-dots" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="dots">
          <circle id="dot1" cx="25" cy="30" r="13"></circle>
          <circle id="dot2" cx="65" cy="30" r="13"></circle>
          <circle id="dot3" cx="105" cy="30" r="13"></circle>
        </g>
      </g>
    </svg>
  `,
  styleUrls: [
    './loading-indicator-simple.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class JarivsLoadingIndicatorSimpleComponent {
  @ViewChild('loadingElement', { static: true, read: ElementRef }) loadingElement: ElementRef<SVGElement>;

  @Input()
  set width(width) {
    const element = this.loadingElement.nativeElement;
    element.style.setProperty(
      '--width',
      width
    );
  }
  get width() {
    const element = this.loadingElement.nativeElement;
    const width = element.style.getPropertyValue('--width');
    return width;
  }

  @Input()
  set height(width) {
    const element = this.loadingElement.nativeElement;
    element.style.setProperty(
      '--height',
      width
    );
  }
  get height() {
    const element = this.loadingElement.nativeElement;
    const width = element.style.getPropertyValue('--height');
    return width;
  }

  @Input()
  set color(width) {
    const element = this.loadingElement.nativeElement;
    element.style.setProperty(
      '--color',
      width
    );
  }
  get color() {
    const element = this.loadingElement.nativeElement;
    const width = element.style.getPropertyValue('--color');
    return width;
  }
}