import { Component, Inject, Optional, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./confirm-modal.component.scss'],
  providers: [ TranslatePipe ]
})
export class ConfirmDialogComponent {
  public i18nKey = 'confirm-modal';
  // titleSet = false;
  // confirmButtonSet = false;

  titleSet = this.getMessageSet$('title');
  confirmButtonSet = this.getMessageSet$('confirm');
  translationData;
  closeButton = true;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { i18nKey?: string, disableCloseButton?: boolean, translationData?: any },
    @Optional() private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private translatePipe: TranslatePipe,
    private translationService: TranslateService
  ) {
    console.log(data);
    if (data.disableCloseButton) this.closeButton = false;
    if (data.i18nKey) {
      this.i18nKey = data.i18nKey;
      // this.titleSet = this.isMessageSet('title');
      // this.confirmButtonSet = this.isMessageSet('confirm');
    }

    this.translationData = data.translationData;
  }

  public isMessageSet(key: string): boolean {
    return this.translatePipe.transform(this.i18nKey + '.' + key) !== (this.i18nKey + '.' + key);
  }

  public cancel(): void {
    this.close(false);
  }

  public close(value): void {
    this.dialogRef.close(value);
  }

  public confirm(): void {
    this.close(true);
  }

  private getMessageSet$(key: string) {
    return of(this.data.i18nKey).pipe(
      switchMap((dialogKey) => {
        if (!dialogKey) {
          return of(false);
        }

        return this.translationService.get(this.data.i18nKey + '.' + key).pipe(
          map((translatedValue) => {
            return (this.i18nKey + '.' + key) !== translatedValue;
          }));
      })
    );
  }
}
