
import {
  Component,
  Output,
  EventEmitter,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { LayoutService } from '@jarvis/ui/layout';
import { take } from 'rxjs/operators';
import { ALL_CATEGORIES_LT, ALL_CATEGORIES_US } from './service-type.data';
import {
  JarvisSelectedServiceService,
  JarvisTrackingService,
} from '@jarvis/services';
@Component({
  selector: 'jarvis-service-type-selector',
  templateUrl: './service-type-selector.component.html',
  styleUrls: ['./service-type-selector.component.scss'],
})
export class ServiceTypeSelectorComponent implements OnInit {
  @Output() typeSelected = new EventEmitter<string>();
  @Input() disableFeatured = false;
  @Input() disableFooter = false;
  @Input() rearrangeItems = false;
  @Input() skipMobileCheck = false;

  iconData = {
    new: '/assets/icons/icon-types/uplifted/ic_new.svg',
    venues: '/assets/icons/icon-types/uplifted/ic_venues.svg',
    ceremony_venue: '/assets/icons/icon-types/ic_ceremony.svg',
    catering: '/assets/icons/icon-types/ic_food.svg',
    ceremony_regale: '/assets/icons/icon-types/ic_regale.svg',
    bartenders: '/assets/icons/icon-types/ic_bartender.svg',
    foodtruck: '/assets/icons/icon-types/ic_food_cart.svg',
    videographer: '/assets/icons/icon-types/ic_video.svg',
    photographer: '/assets/icons/icon-types/ic_photo.svg',
    live_music: '/assets/icons/icon-types/ic_music.svg',
    planning: '/assets/icons/icon-types/ic_planning.svg',
    host: '/assets/icons/icon-types/ic_microphone.svg',
    cakes_desserts: '/assets/icons/icon-types/ic_cake.svg',
    makeup_hairdressing: '/assets/icons/icon-types/ic_makeup.svg',
    dj: '/assets/icons/icon-types/ic_dj.svg',
    bride_clothing: '/assets/icons/icon-types/ic_dress.svg',
    groom_clothing: '/assets/icons/icon-types/ic_groom.svg',
    secondary_dress: '/assets/icons/icon-types/ic_market.svg',
    transport: '/assets/icons/icon-types/ic_transport.svg',
    decor: '/assets/icons/icon-types/ic_decorations.svg',
    invitations_printing: '/assets/icons/icon-types/ic_invitation.svg',
    decor_floristics: '/assets/icons/icon-types/ic_flowers.svg',
    pavilion_furniture: '/assets/icons/icon-types/ic_pavilion.svg',
    sound_lights: '/assets/icons/icon-types/ic_effects.svg',
    screens_projectors: '/assets/icons/icon-types/ic_screens.svg',
    entertainment: '/assets/icons/icon-types/ic_party.svg',
    show: '/assets/icons/icon-types/ic_show.svg',
    training: '/assets/icons/icon-types/uplifted/ic_training.svg',
    jewelery: '/assets/icons/icon-types/ic_rings.svg',
    presents: '/assets/icons/icon-types/ic_present.svg',
    honeymoon: '/assets/icons/icon-types/ic_honeymoon.svg',
    photo_booth: '/assets/icons/icon-types/ic_photobooth.svg',
    ceremony_host: '/assets/icons/icon-types/ic_ceremony_host.svg',
    visual_entertainment:
      '/assets/icons/icon-types/ic_visual_entertainment.svg',
  };

  domainCountry = inject(DOMAIN_COUNTRY);
  selectedServiceService = inject(JarvisSelectedServiceService);
  private trackService = inject(JarvisTrackingService);

  categories;
  state: any = {
    megaMenuNavigation: true,
  };

  allCategories: any[] =
    this.domainCountry === 'lt' ? ALL_CATEGORIES_LT : ALL_CATEGORIES_US;

  layoutService = inject(LayoutService, { optional: true });

  isMobile$ = this.layoutService.isMobileObserver;

  trackCategoryClick(category) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'services_dropdown_click',
      data: {
        service_selected: category,
      },
    });
  }

  typeSelectedHandle(type: string) {
    this.trackCategoryClick(type);
    this.typeSelected.emit(type);
  }

  ngOnInit() {
    if (this.disableFooter) {
      this.state = { ...this.state, disableFooter: true };
      this.layoutService.disableFooter();
    }

    this.isMobile$.pipe(take(1)).subscribe((isMobile) => {
      isMobile = isMobile || this.skipMobileCheck;
      this.categories = this.allCategories.map((itm) => ({
        label: itm.label,
        items: itm.items.filter(
          (v) =>
            ((v.mobileOnly && isMobile) || !v.mobileOnly) &&
            (v.link != 'featured' || !this.disableFeatured) &&
            (!v.hiddenForCountries ||
              !v.hiddenForCountries?.includes(this.domainCountry.toUpperCase()))
        ),
      }));

      if (this.rearrangeItems) {
        const allItems = this.categories.reduce(
          (a, v) => [...a, ...v.items],
          []
        );
        const oneColCount = Math.floor(
          allItems.length /
            this.categories.reduce(
              (a, v) => a + (v.items.length > 0 ? 1 : 0),
              0
            )
        );

        this.categories = this.categories.map((v, i) => ({
          ...v,
          items: allItems.slice(i * oneColCount, (i + 1) * oneColCount),
        }));
      }
    });
  }
}
