import { Inject, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export class CustomDateAdapter extends MomentDateAdapter {
  locale: string;

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
    this.locale = dateLocale;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    if (this.locale == 'LT-lt' && style == 'short')
      return ['Pr', 'An', 'Tr', 'Kt', 'Pt', 'Št', 'Sk'];
    return super.getDayOfWeekNames(style);
  }
}
