import { Component, Input } from '@angular/core';

@Component({
  selector: 'jarvis-reviews-individual-scores',
  templateUrl: 'individual-scores.component.html',
  styleUrls: [
    'individual-scores.component.scss'
  ]
})

export class JarvisReviewsIndividualScoresComponent {
  @Input() data: any;
}