import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASE_URL } from '@jarvis/services';
@Injectable({
  providedIn: 'root'
})
export class EventHostHomepageService {

  eventDateControl = new UntypedFormControl();

  private get homepageEndpoint(): string {
    if (this.baseUrl) {
      return `${this.baseUrl}/marketplace/search/home`;
    }

    return `/marketplace/search/home`;
  }

  constructor(
    private httpService: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
  ) { }

  getHomepageServices(eventType: string, country:string): Observable<any> {

    return this.httpService.get(this.homepageEndpoint + `?venueType=${eventType}` + (country? `&country=${country.toUpperCase()}`:''));
  }
}
