import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OnDestroy, suggestEmail } from '@jarvis/utils';
import { debounceTime, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'jarvis-email-hint',
  templateUrl: 'email-hint.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    AsyncPipe,
    NgIf
  ]
})

export class JarvisEmailHintComponent implements OnInit {
  @Input() control: string;
  @Output() emailHintSelected: EventEmitter<string> = new EventEmitter<string>();

  emailHint$: Observable<string | null>;

  private controlContainer = inject(ControlContainer);
  
  @OnDestroy()
  private destroy$: Subject<void>;

  get formControl() {
    return this.controlContainer.control.get(this.control);
  }

  ngOnInit(): void {
    this.emailHint$ = this.formControl?.valueChanges.pipe(
      debounceTime(200),
      map((email) => {
        const suggestion = suggestEmail(email);
        return suggestion || null;
      }),
      takeUntil(this.destroy$)
    ) || of(null);
  }
  
  hintSelected(email: string) {
    this.emailHintSelected.emit(email);
  }
}