import { HomepageCalendarComponent } from './components/homepage-calendar/homepage-calendar.component';
import { EventHostHomepageService } from './services/event-host-homepage.service';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { DOMAIN_COUNTRY, ServiceCurrencyPipe } from '@jarvis/services';
/* eslint-disable max-len */
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth';
import {
  LinkUrls,
  LINK_URLS,
  getServicePrices,
  extractServicesFromAdvert,
  extractTextAddressFromService,
} from '@jarvis/services';
import {
  JarvisHeaderService,
  JarvisUiModalService,
  LayoutService,
} from '@jarvis/ui';
import { Subject, merge, Observable } from 'rxjs';
import {
  take,
  takeUntil,
  distinctUntilChanged,
  mergeMap,
  map,
  filter,
} from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { ProfileSelectorDialogComponent } from './components/profile-selector-dialog/profile-selector-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { kebabCaseToSnakeCase, serviceEventPictureSort } from '@jarvis/utils';
import { ReviewsUtils } from '@jarvis/reviews';
import { HomepageMenuServiceListComponent } from '../homepage-uplifted/components/service-list/service-list.component';
import { JarvisServiceLikesService } from '@jarvis/services/likes';

@Component({
  selector: 'jarvis-event-host-homepage',
  templateUrl: './event-host-homepage.component.html',
  styleUrls: ['./event-host-homepage.component.scss'],
})
export class EventHostHomepageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('landingHeader', { static: true }) landingHeader: TemplateRef<HTMLDivElement>;
  @ViewChild('smallCalendarOverlay') smallCalendarOverlay: TemplateRef<any>;
  @ViewChild('dateAnchor', { read: ElementRef }) dateAnchor: ElementRef;
  @ViewChild('dateAnchor2', { read: ElementRef }) dateAnchor2: ElementRef;
  @ViewChild('intersection', { read: ElementRef }) intersection: ElementRef;

  Math = Math;

  eventTypes = ['privateEvent', 'businessEvent'];
  eventType;

  isMobile = false;
  loggedIn$ = this.authService.isLoggedIn$;
  saasLandingLink = `${this.linkUrls.saas}`;
  private destroy$ = new Subject<void>();
  private closeCalendar$ = new Subject<void>();

  currentImageIndex = 0;

  headerTopPosition = true;

  services = [];
  servicesToShow = [];

  seeMoreStep = 0;

  endorsmentConfig = [
    {
      image: null,
      label: 'landing.homepage.endorsments.item1.label',
      description: 'landing.homepage.endorsments.item1.description',
    },
    {
      image: null,
      label: 'landing.homepage.endorsments.item2.label',
      description: 'landing.homepage.endorsments.item2.description',
    },
    {
      image: null,
      label: 'landing.homepage.endorsments.item3.label',
      description: 'landing.homepage.endorsments.item3.description',
    },
    {
      image: null,
      label: 'landing.homepage.endorsments.item4.label',
      description: 'landing.homepage.endorsments.item4.description',
    },
    {
      image: null,
      label: 'landing.homepage.endorsments.item5.label',
      description: 'landing.homepage.endorsments.item5.description',
    },
    {
      image: null,
      label: 'landing.homepage.endorsments.item6.label',
      description: 'landing.homepage.endorsments.item6.description',
    },
  ];

  sections = [
    {
      id: 'section-1',
      header: 'landing.homepage.sections.section-1.title',
      text: 'landing.homepage.sections.section-1.description',
      subtexts: [
        'landing.homepage.sections.section-1.item-1',
        'landing.homepage.sections.section-1.item-2',
      ],
      image: 'assets/images/landing/homepage/functionality-1.png',
    },
    {
      id: 'section-2',
      header: 'landing.homepage.sections.section-2.title',
      text: 'landing.homepage.sections.section-2.description',
      subtexts: [
        'landing.homepage.sections.section-2.item-1',
        'landing.homepage.sections.section-2.item-2',
      ],
      image: 'assets/images/landing/homepage/functionality-2.png',
    },
    {
      id: 'section-3',
      header: 'landing.homepage.sections.section-3.title',
      text: 'landing.homepage.sections.section-3.description',
      subtexts: [
        'landing.homepage.sections.section-3.item-1',
        'landing.homepage.sections.section-3.item-2',
      ],
      image: 'assets/images/landing/homepage/functionality-3.png',
    },
  ];

  getAllDoneItems = [
    {
      image: 'assets/images/landing/homepage/send-message.png',
      label: 'landing.homepage.getAllDone.item-1',
    },
    {
      image: 'assets/images/landing/homepage/sign-contract.png',
      label: 'landing.homepage.getAllDone.item-2',
    },
    {
      image: 'assets/images/landing/homepage/make-payments.png',
      label: 'landing.homepage.getAllDone.item-3',
    },
  ];

  images = [
    'homepage1-min.jpg',
    'homepage2-min.jpg',
    'homepage3-min.jpg',
    'homepage4-min.jpg',
    'homepage5-min.jpg',
  ];

  constructor(
    public homepageService: EventHostHomepageService,
    private layoutService: LayoutService,
    private headerService: JarvisHeaderService,
    private router: Router,
    private authService: JarvisAuthService,
    private vcr: ViewContainerRef,
    private overlay: Overlay,
    private likesService: JarvisServiceLikesService,
    private modalService: JarvisUiModalService,
    private translateService: TranslateService,
    private currencyPipe: ServiceCurrencyPipe,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string,
    @Inject(LINK_URLS) private linkUrls: LinkUrls
  ) {}

  ngOnInit(): void {
    this.layoutService.isMobileObserver
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => (this.isMobile = isMobile));

    this.headerService.setHeaderTemplate(this.landingHeader, this.vcr);

    this.eventType = 'wedding';
  }

  ngAfterViewInit(): void {
    this.createAndObserve(this.intersection)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.headerTopPosition = data;
      });

    this.initHomepageServices();
  }

  initHomepageServices() {
    this.homepageService
      .getHomepageServices(this.eventType, this.domainCountry)
      .subscribe((services) => {
        this.services = services.map((value) => {
          const serviceType = value.serviceBase.type.replace('-', '_');
          const subcategories = value.serviceBase.subcategories?.types;
          const firstSubtype = subcategories ? Object.keys(subcategories).find((categoryType) => subcategories[categoryType] === true) : '';

          const avgRating = ReviewsUtils.averageRating(
            value?.thirdPartyReviews,
            value.review?.[0]
          );
          const ratingCount = avgRating.count;
          const rating = avgRating.rating;
          /*const ratingCount = value.review?.[0]?.reviewsCount || '-';
          const rating =
            this.roundToTwoPrecision(value.review?.[0]?.score ?? null) || '-';*/

          const extracted = extractServicesFromAdvert({
            base: value.serviceBase,
            event: value.wedding,
          });

          const servicePrices = getServicePrices(extracted);

          const cardAddress = extractTextAddressFromService(
            value.serviceBase,
            this.translateService
          );

          return {
            _id: value._id,
            address: cardAddress,
            type: firstSubtype
              ? `subcategories.${serviceType}.${firstSubtype}`
              : 'categoriesMarketplace.' + kebabCaseToSnakeCase(serviceType),
            title: value.serviceBase.brandName,
            rating: `${rating}`,
            ratingCount: `${ratingCount}`,
            indicativePrice: this.currencyPipe.transform(
              servicePrices.lowestPrice.price,
              value.serviceBase,
              '1.0-0'
            ),
            priceUnit:
              servicePrices.lowestPrice.service?.priceUnit || 'price_per_unit',
            slug: `${value.slug || value._id}`,
            images: this.imageMap(value, this.eventType),
            likeId: value.liked?.[0]?._id,
          };
        });

        this.servicesToShow = this.services.slice(0, 7);
      });
  }

  roundToTwoPrecision(value: number) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  createAndObserve(element: ElementRef): Observable<boolean> {
    return new Observable((observer) => {
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          observer.next(entries);
        },
        {
          rootMargin: '-30px',
        }
      );
      intersectionObserver.observe(element.nativeElement);

      return () => {
        intersectionObserver.disconnect();
      };
    }).pipe(
      mergeMap((entries: IntersectionObserverEntry[]) => entries),
      map((entry: any) => entry.isIntersecting),
      distinctUntilChanged()
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.headerService.setDefaultHeader();
  }

  openContactFormDialog(): void {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      this.router.navigateByUrl('/auth/register');
      this.authService
        .createRegisterOrLoginSuccessStream()
        .subscribe((newUserData) => {
          this.navigateToFirstHomePage(newUserData.userType);
        });
    });
  }

  openLoginDialog(
    userType: 'user' | 'vendor' = 'user',
    dialogType: 'login' | 'register' = 'login'
  ): void {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      this.router.navigate(['/auth/' + dialogType], {
        state: { userType },
      });

      this.authService
        .createRegisterOrLoginSuccessStream()
        .subscribe((newUserData) => {
          // console.log(newUserData);
          this.navigateToFirstHomePage(newUserData.userType);
        });
    });
  }

  openDatePicker(top = false): void {
    if (this.layoutService.isMobile) {
      this.modalService.openDialog(HomepageCalendarComponent, {
        viewContainerRef: this.vcr,
      });
    } else {
      this.createOverlay(
        this.smallCalendarOverlay,
        top ? this.dateAnchor2 : this.dateAnchor
      );
    }
  }

  private navigateToFirstHomePage(userType: 'user' | 'vendor'): void {
    // console.log(userType)
    if (userType === 'user') {
      this.router.navigateByUrl('/planner');
      return;
    }

    window.location.href = this.linkUrls.saas;
  }

  private getFirstPageNavigationUrl(userType: 'user' | 'vendor'): string {
    if (userType === 'user') {
      return '/planner';
    }

    return this.linkUrls.saas;
  }

  scrollTop(smooth = false) {
    window.scrollTo({
      top: 0,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }

  private createOverlay(
    template: TemplateRef<any>,
    connectedTo: ElementRef
  ): void {
    const position: any[] = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
    ];
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(connectedTo)
        .withPositions(position)
        .withDefaultOffsetY(5),
    });

    const componentPortal = new TemplatePortal(template, this.vcr);

    merge(
      overlayRef.backdropClick(),
      overlayRef.detachments(),
      this.closeCalendar$
    )
      .pipe(take(1))
      .subscribe(() => {
        overlayRef.detach();
      });

    overlayRef.attach(componentPortal);
  }

  handleWeddingDateSelect(date) {
    this.homepageService.eventDateControl.setValue(date);
    this.closeCalendar$.next();
  }

  submitWeddingDate() {
    this.navigateToMarketplace();
  }

  dontHaveDateHandler() {
    this.navigateToMarketplace();
  }

  providersArrowClickHandler() {
    this.navigateToMarketplace();
  }

  get marketplaceRouterLink() {
    const wedding_date = this.homepageService.eventDateControl.value;

    // const url = true || this.domainCountry.toUpperCase() == 'US' ? '/new' : '/photographer';
    const url = '/new';

    if (wedding_date) {
      return {
        link: url,
        queryParams: { date: wedding_date.getTime() },
        state: {
          fromHomePage: true,
        },
      };
    }

    return {
      link: url,
      state: {
        fromHomePage: true,
      },
    };
  }

  navigateToMarketplace() {
    const wedding_date = this.homepageService.eventDateControl.value;

    window.scrollTo(0, 0);
    if (this.domainCountry.toUpperCase() === 'US') {
      return this.router.navigate(['/new']);
    } else if (wedding_date)
      return this.router.navigate(['/photographer'], {
        queryParams: { date: wedding_date.getTime() },
        state: {
          fromHomePage: true,
        },
      });
    return this.router.navigateByUrl('/photographer', {
      state: { fromHomePage: true },
    });
  }

  async serviceLikeHandler(status, service) {
    const loggedIn = await this.authService.isLoggedIn$
      .pipe(take(1))
      .toPromise();

    if (!loggedIn) {
      this.router.navigateByUrl('/auth/login');
      return;
    }

    if (!service.likeId) {
      this.likesService.saveLike(service._id).subscribe((likeResponse) => {
        service.likeId = likeResponse._id;
      });
      return;
    }

    this.likesService.removeLike(service.likeId).subscribe(() => {
      service.likeId = null;
    });
  }

  imageMap(value, eventType) {
    const photos = value[eventType].photos;

    if (!photos) {
      return [];
    }

    const sortedPhotos = serviceEventPictureSort(photos);

    return sortedPhotos;
  }

  openServicesOverlay(): void {
    if (this.domainCountry.toUpperCase() === 'US') {
      this.navigateToMarketplace();
      return;
    }
    if (this.layoutService.isMobile) {
      this.modalService.openDialog(HomepageMenuServiceListComponent);
    } else {
      const overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        scrollStrategy: this.overlay.scrollStrategies.block(),
        // width: '100vh',
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.headerService.headerRef)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
            },
          ]),
        // .withDefaultOffsetY(5)
      });

      const componentPortal = new ComponentPortal(
        HomepageMenuServiceListComponent
      );

      const componentRef = overlayRef.attach(componentPortal);

      const close$ = componentRef.instance.close$;

      merge(
        overlayRef.backdropClick(),
        overlayRef.detachments(),
        close$,
        this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd)
        )
      )
        .pipe(take(1))
        .subscribe(() => {
          overlayRef.detach();
        });
    }
  }

  get vendorSignupLink() {
    return this.authService.userData$.pipe(
      take(1),
      map((userData) => {
        if (userData) {
          return {
            link: this.getFirstPageNavigationUrl(userData.userType),
          };
        }

        return {
          link: '/sign-up',
        };
      })
    );
  }

  handleVendorSignup(location: string, onlyTrack = false) {


    if (onlyTrack) return;

    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      const signUpLink =
        this.domainCountry === 'lt'
          ? '/registracija?source='
          : '/sign-up?source=';

      window.location.replace(window.location.origin + signUpLink + location);

      // this.router.navigate(['sign-up'], {
      //   queryParams: { source: location }
      // });
    });
  }

  openUserTypeSelector() {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      const dialog = this.modalService.openDialog(
        ProfileSelectorDialogComponent
      );

      dialog.afterClosed().subscribe((profileType) => {
        if (!profileType) return;

        if (profileType === 'vendor')
          return this.handleVendorSignup('homepage-header');
        this.openLoginDialog(profileType, 'register');
      });
    });
  }

  handleHeaderAuth(type: 'Login' | 'Signup') {
    if (type === 'Login') {
      this.openLoginDialog();
    }
    if (type === 'Signup') {
      this.openUserTypeSelector();
    }
  }

  handleShowMore() {
    if (this.seeMoreStep) return this.navigateToMarketplace();
    this.servicesToShow = this.services;
    this.seeMoreStep = 1;
    return null;
  }
}
