import {
  Component,
  inject,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BASE_URL } from '@jarvis/services/tokens';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { JarvisTrackingService } from '@jarvis/services';

@Component({
  selector: 'marketplace-top-search',
  templateUrl: './top-search.component.html',
  styleUrls: ['./top-search.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TopSearchComponent implements OnInit {
  public mobileShowInput = false;
  searchPartnersControl = new FormControl();
  partnersOptions: any;
  eventsLoading$ = new BehaviorSubject(false);

  private trackService = inject(JarvisTrackingService);

  constructor(
    private httpClient: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(DOMAIN_COUNTRY) public country: string
  ) {}

  ngOnInit(): void {
    this.searchPartnersControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(200),
        startWith(''),
        tap(() => {
          this.partnersOptions = [];
          this.eventsLoading$.next(true);
        }),
        switchMap((value) => this.searchServices(value))
      )
      .subscribe((data: any) => {
        this.eventsLoading$.next(false);
        this.partnersOptions = data;
      });
  }

  getPanelWidth(): string {
    if (window.innerWidth < 768) return '100vw';
    return null;
  }

  searchServices(searchString) {
    return this.httpClient.get(
      `${
        this.baseUrl
      }/marketplace/search/textSearch?country=${this.country.toUpperCase()}&q=${searchString}`
    );
  }

  onFocus() {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'site_search_clicked',
    });
  }

  selectParther(partner: any) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'site_search_selected',
      data: { selected: partner.name },
    });

    window.location.href = '/' + partner.slug;
  }
}
