import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { JarvisAuthService } from '../../services/auth.service';
import { JarvisAuthPasswordRemindModalComponent } from '../password-remind-modal/password-remind-modal.component';
import { JarvisUiModalService } from '@jarvis/ui';

@Component({
  selector: 'jarvis-auth-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class JarvisAuthForgotPasswordModalComponent {

  emailControl = new UntypedFormControl('');
  submitError = false;

  constructor(
    private authService: JarvisAuthService,
    private modalService: JarvisUiModalService,
    private router: Router,
    private dialogRef: MatDialogRef<JarvisAuthForgotPasswordModalComponent>
  ) { }

  sendReminder(event: any): void {
    event.preventDefault();
    const email = this.emailControl.value;
    this.authService.forgotPassword(email).subscribe(() => {
      const modalRef = this.modalService.openDialog(JarvisAuthPasswordRemindModalComponent, {
        data: {
          email
        }
      });

      this.onClose();

      modalRef.afterClosed().subscribe(() => {
        this.router.navigateByUrl('/');
      });
    },
    () => {
      this.submitError = true;
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
