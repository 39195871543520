import { Pipe, PipeTransform } from '@angular/core';
import { ServiceBase } from '@jarvis/types';

@Pipe({
    name: 'ChatListingName',
    pure: true
})

export class ChatListingNamePipe implements PipeTransform {
    transform(value: ServiceBase): any {
        return value?.brandName || '';
    }
}