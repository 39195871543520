import { coerceNumberProperty } from '@angular/cdk/coercion';
import { formatCurrency } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOMAIN_COUNTRY } from '@jarvis/services';
import { Subject } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[currencyMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JarvisCurrencyInputMaskDirective),
      multi: true
    }
  ],
  standalone: true
})
export class JarvisCurrencyInputMaskDirective implements ControlValueAccessor, OnInit, OnDestroy {

  private formValue: any = null;

  // private currentLocale = 'us-EN';
  private destroy$ = new Subject<void>();

  private renderer = inject(Renderer2);
  private elRef = inject(ElementRef);
  private domainCountry = inject(DOMAIN_COUNTRY);

  onTouched: () => unknown;
  onChange: (val: any) => unknown = () => { };

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('blur') blurEvent() {
    this.writeValue(this.elRef.nativeElement.value);
  }

  @HostListener('focus') focusEvent() {
    this.renderer.setProperty(this.elRef.nativeElement, 'value', this.formValue);
  }

  writeValue(obj: any): void {
    const numberCoerced = Number(obj);

    const invalidNumber = isNaN(numberCoerced);

    const parsedNumber = invalidNumber ? this.formValue : numberCoerced;

    const formatted = formatCurrency(parsedNumber, 'en-US', '$', 'USD', '1.0-0');
    this.renderer.setProperty(this.elRef.nativeElement, 'value', formatted);

    if (invalidNumber) {
      return;
    }

    if (parsedNumber !== this.formValue) {
      this.onChange(parsedNumber);
      this.formValue = parsedNumber;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
