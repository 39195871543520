import { Subject } from 'rxjs';

export function OnDestroy(): PropertyDecorator {
  return function(target: any, propertyKey: string | symbol) {
    const subject = Symbol();
    const originalNgOnDestroy = target.ngOnDestroy;
    target.ngOnDestroy = function() {
      this[subject]?.next();
      this[subject]?.complete();
      originalNgOnDestroy?.apply(this);
    }

    Object.defineProperty(target, propertyKey, {
      get: function () {
        if (!this[subject]) {
          this[subject] = new Subject<void>();
        }
        return this[subject];
      }
    });
  }
}