import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from '../../../../services/src/lib/tokens/base-url.token';

@Injectable()
export class LandingContactsService {

    get contactsEndpoint(): string {
        if (this.baseUrl) {
            return `${this.baseUrl}/api/marketplace/contact`;
        }

        return `api/marketplace/contact`;
    }

    constructor(
        private httpService: HttpClient,
        @Optional() @Inject(BASE_URL) private baseUrl: string
    ) {

    }

    postComments(data: any, type: string): Observable<any> {
        return this.httpService.post(this.contactsEndpoint, {
            type,
            data
        });
    }
}