import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BookingsDataService } from '../../bookings.service';
import { BookingsType } from '@jarvis/types';
import { CommonDialogService } from '@jarvis/ui';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'jarvis-add-signature',
  templateUrl: './add-signature.component.html',
  styleUrls: ['./add-signature.component.scss'],
})
export class AddSignatureComponent {
  @Input() data: BookingsType = {} as BookingsType;
  @Input() mode: string = "sign";
  @Output() event = new EventEmitter<any>();  
  
  private bookingsDataService = inject(BookingsDataService);
  processed = new BehaviorSubject(false);

  signature = new UntypedFormControl();
  commonDialogService = inject(CommonDialogService);

  public sign(){
     this.processed.next(true);
     if (this.mode=="preview") { this.event.emit({state:"addSignPreviewsigned" }); }
     else this.bookingsDataService.signContract(this.data, this.signature.value).subscribe( {
       next:
        v=> 
       {  this.data.options.contractVendorSigned = true;
          this.event.emit({state:"signed", changes: true });     
       },
       error: (error) => { 
        this.processed.next(false);
        this.commonDialogService.openSuccess("errors.errorMsg", "error", undefined, `Error: ${error.error?.errors?.map((x:any) => x.message).join(", ")}`); },
       complete: () => { this.processed.next(false); }
    });
  }

}
