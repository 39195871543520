import { coerceBooleanProperty } from '@angular/cdk/coercion';
// import { DatePipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Input, OnDestroy, OnInit, Optional, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { JarvisMeasurementUnitService, JarvisLanguageService } from '@jarvis/services';
import { startOfDay } from 'date-fns';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[dateMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JarvisDateInputMaskDirective),
      multi: true
    }
  ]
})
export class JarvisDateInputMaskDirective implements ControlValueAccessor, OnInit, OnDestroy {

  @Input()
  get dateMask(): boolean {
    return this._dateMask;
  }
  set dateMask(value: boolean | 'time') {
    if (value === 'time') {
      this._timeMask = true;
      this._dateMask = true;
      this.blurEvent();
      return;
    }

    this._timeMask = false;
    this._dateMask = coerceBooleanProperty(value);
    this.blurEvent();
  }
  private _dateMask = true;
  private _timeMask = false;

  private formValue: any = null;

  private currentLocale = 'us-EN';
  private destroy$ = new Subject<void>();

  onTouched: () => unknown;
  onChange: (val: any) => unknown = () => { };


  constructor(
    // private datePipe: DatePipe,
    private renderer: Renderer2,
    private elRef: ElementRef,
    @Optional() private measurementUnitService: JarvisMeasurementUnitService,
    private languageService: JarvisLanguageService
  ) {
    this.languageService.currentLanguage$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((currentLanguage) => {
      this.currentLocale = this.languageService.getLocaleByLanguage();
    });
  }

  ngOnInit(): void {
    if (this.measurementUnitService) {
      this.measurementUnitService.activeLocale$.pipe(
        takeUntil(this.destroy$)
      ).subscribe(locale => this.currentLocale = locale);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('blur') blurEvent() {
    const newDate = new Date(this.elRef.nativeElement.value);
    const today = new Date();
    if (isNaN(+newDate)) {
      this.writeValue(this.formValue);
    } else if (this.dateMask && (+startOfDay(newDate) < +startOfDay(today))) {
      this.writeValue(this.formValue);
    } else {
      this.writeValue(newDate);
    }
  }

  writeValue(obj: any): void {
    let formattedDate: string;
    if (this._timeMask) {
      // formattedDate = this.datePipe.transform(obj, 'MMM d, y h:mm a');
      if (this.currentLocale === 'lt-LT') {
        formattedDate = obj ? moment(obj).locale(this.currentLocale).format('ll HH:mm') : null;
      } else {
        formattedDate = obj ? moment(obj).locale(this.currentLocale).format('MMM D, y h:mm a') : null;
      }
    } else {
      // formattedDate = this.datePipe.transform(obj);
      formattedDate = obj ? moment(obj).locale(this.currentLocale).format('ll') : null;
    }
    this.renderer.setProperty(this.elRef.nativeElement, 'value', formattedDate);
    if (+obj !== +this.formValue) {
      this.onChange(obj);
      this.formValue = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /* setDisabledState(isDisabled: boolean): void {

  } */
}
