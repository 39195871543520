
import { of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Inject, inject } from '@angular/core';
import { JarvisAuthService } from '../../services/auth.service';
import { takeUntil, switchMap, map } from 'rxjs/operators';
import { BASE_URL, LinkUrls, LINK_URLS } from '@jarvis/services/tokens';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { JarvisHeaderService } from '@jarvis/ui/header';
import { LayoutService } from '@jarvis/ui/layout';
import { MessagesType, MESSAGES_TYPE } from '@jarvis/messaging/tokens';
import { JarvisTrackingService } from '@jarvis/services';

@Component({
  selector: 'vendor-signup-page',
  templateUrl: './vendor-signup-page.component.html',
  styleUrls: ['./vendor-signup-page.component.scss'],
})
export class VendorSignupPageComponent implements OnInit, OnDestroy {
  private trackService = inject(JarvisTrackingService);

  destroy$ = new Subject<void>();

  endorsment = {
    title: 'landing.saas.signUpPage.customerName1',
    description: 'landing.saas.signUpPage.customerReview1',
    image: 'assets/images/landing/joy_michelie.jpg',
  };

  isProfileLink =
    this.route.snapshot.queryParams.source === 'profile-link-signup';

  constructor(
    private headerService: JarvisHeaderService,
    public layoutService: LayoutService,
    private route: ActivatedRoute,
    private authService: JarvisAuthService,
    @Inject(BASE_URL) private baseUrl: string,
    private router: Router,
    // @Inject(LINK_URLS) private linkUrls: LinkUrls,
    @Inject(MESSAGES_TYPE) private messagesType: MessagesType,
    @Inject(LINK_URLS) private linkUrls: LinkUrls,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string
  ) {
    this.layoutService.disableFooter();
  }

  ngOnInit(): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'page_load',
      data: { page_type: 'vendor-signup' },
    });

    if (this.domainCountry != 'us') {
      this.endorsment = {
        title: 'landing.saas.signUpPage.customerName2',
        description: 'landing.saas.signUpPage.customerReview2',
        image: 'assets/images/landing/auders-photography.jpg',
      };
    }

    this.headerService.hideHeader();
    setTimeout(() => window.scrollTo({ top: 0 }), 200);

    this.authService
      .createRegisterOrLoginSuccessStream()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user.userType === 'user')
          return this.router.navigateByUrl('profile/settings?from=login');
        if (user.userType === 'vendor')
          return location.replace(this.linkUrls.saas);

        return this.router.navigate([]);
      });
  }

  redirectToVendorsLanding() {
    if (this.domainCountry === 'us') {
      this.router.navigateByUrl('/');
      return;
    }

    this.router.navigateByUrl('rekomenduojami');
  }

  ngOnDestroy(): void {
    this.headerService.setDefaultHeader();
    this.layoutService.enableFooter();
    this.destroy$.next();
  }
}
