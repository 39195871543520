<div class="bztt-flex bztt-font-montserrat-r bztt-h-full">
  
  <div class="bztt-flex bztt-flex-col bztt-items-center bztt-w-full md:bztt-p-2">
    <ng-container *ngIf="chatInfo$ | async as chatInfo">
      <ng-container *ngIf="{
        tourData: tourData$ | async,
        bookingData: bookingData$ | async,
        listingData: listingData$ | async
      } as vm">
        <div class="bztt-flex bztt-flex-col bztt-h-full bztt-overflow-hidden bztt-w-full">
          <div #messageContainer class="bztt-overflow-y-auto bztt-flex bztt-flex-auto bztt-h-full bztt-w-full bztt-jarvis-scroll bztt-px-2 md:bztt-px-0">
            <div class="bztt-mt-auto bztt-w-full">
              <div *ngIf="type === 'after_inquiry'" class="bztt-flex bztt-flex-col bztt-gap-1 bztt-items-center bztt-justify-center">
                <img class="bztt-h-7" src="assets/icons/ic_success.svg" />
                <p class="bztt-font-sora bztt-text-base bztt-font-semibold">
                  {{'assistantChat.inquirySuccessful' | translate}}
                </p>
              </div>
    
              <ng-container *ngFor="let message of chatInfo.chatMessages; let i = index; let lastMessage = last; trackBy: messageTrackBy">

                <chat-message
                  *ngIf="message.message !== '___PDF___'"
                  class="bztt-w-full"
                  messageStyle="left"
                  [message]="message"
                  [lastMessage]="lastMessage"
                  [currentUserId]="currentUserId"
                  [showActionsButton]="false"
                  vendorImage="assets/images/assistant/maya.jpeg"
                  (retrySendMessage)="retrySendMessage(message)"
                ></chat-message>
      
                <ng-container 
                  *ngIf="message.options?.toolCall?.function.name === 'is_ready_for_book_a_tour'"
                >
      
                  <chat-message
                    class="bztt-w-full"
                    messageStyle="left"
                    [message]="{
                      message: 'assistantChat.bookATourActionMessage' | translate,
                      type: 'text'
                    }"
                    [lastMessage]="lastMessage"
                    [currentUserId]="currentUserId"
                    [showActionsButton]="false"
                    vendorImage="assets/images/assistant/maya.jpeg"
                  ></chat-message>
      
                  <chat-message
                    *ngIf="vm.tourData"
                    class="bztt-w-full"
                    messageStyle="left"
                    [message]="{
                      type: 'inline',
                      sender: currentUserId
                    }"
                    [lastMessage]="lastMessage"
                    [currentUserId]="currentUserId"
                    [showActionsButton]="false"
                    vendorImage="assets/images/assistant/maya.jpeg"
                  >
                    <book-a-tour-summary
                      slot="message"
                      [serviceName]="vm.tourData.serviceName"
                      [dates]="vm.tourData.selectedDates"
                    ></book-a-tour-summary>
                  </chat-message>
                </ng-container>
      
                <ng-container 
                  *ngIf="message.options?.toolCall?.function.name === 'is_ready_for_proposal'"
                >
      
                  <chat-message
                    class="bztt-w-full"
                    messageStyle="left"
                    [message]="{
                      message: 'assistantChat.proposalActionMessage' | translate,
                      type: 'text'
                    }"
                    [lastMessage]="lastMessage"
                    [currentUserId]="currentUserId"
                    [showActionsButton]="false"
                    vendorImage="assets/images/assistant/maya.jpeg"
                  ></chat-message>
      
                  <chat-message
                    *ngIf="vm.bookingData"
                    class="bztt-w-full"
                    messageStyle="left"
                    [message]="{
                      type: 'inline',
                      sender: currentUserId
                    }"
                    [lastMessage]="lastMessage"
                    [currentUserId]="currentUserId"
                    [showActionsButton]="false"
                    vendorImage="assets/images/assistant/maya.jpeg"
                  >
                    <quote-summary
                      slot="message"
                      [bookingData]="vm.bookingData"
                    ></quote-summary>
                  </chat-message>      
                </ng-container>

                <chat-message
                    *ngIf="message.message === '___PDF___'"
                    class="bztt-w-full"
                    messageStyle="left"
                    [message]="{
                      type: 'inline'
                    }"
                    [lastMessage]="lastMessage"
                    [currentUserId]="currentUserId"
                    [showActionsButton]="false"
                    vendorImage="assets/images/assistant/maya.jpeg"
                  >
                    <div slot="message" class="bztt-w-[260px] bztt-flex bztt-flex-col bztt-gap-3">
                      <span>{{ 'assistantChat.yourProposal' | translate }}</span>
                      
                      <div class="bztt-overflow-hidden bztt-flex bztt-flex-col bztt-text-center bztt-items-center bztt-justify-end bztt-border bztt-border-[#F8B9A0] bztt-rounded-lg bztt-bg-[#FFF7F3] bztt-pb-3 bztt-px-3">
                        <div class="bztt-relative bztt-w-[150px] bztt-h-[60px] bztt-mb-5">
                          <img
                            *ngIf="listingMainPhoto$ | async as listingMainPhoto"
                            class="bztt-absolute bztt-rounded-full bztt-top-[-150%] bztt-left-0 bztt-w-[150px] bztt-h-[150px]"
                            [src]="listingMainPhoto"
                          />
                        </div>

                        <span class="bztt-font-sora bztt-font-bold bztt-text-2xl bztt-leading-[1.75rem] bztt-text-[#EF5518] bztt-mb-3">
                          {{ vm.bookingData.serviceName }}
                        </span>

                        <span class="bztt-font-sora bztt-font-medium bztt-text-[8px] bztt-text-[#EF5518]">
                          <ng-container *ngIf="{
                            bookingDate: bookingDate$ | async
                          } as vm">
                            <ng-container [ngSwitch]="!vm.bookingDate">
                              <ng-container *ngSwitchCase="true">
                                {{ 'assistantChat.pricingGuide.noDate' | translate }}
                              </ng-container >
                              <ng-container *ngSwitchDefault>
                                {{ 'assistantChat.pricingGuide.withDate' | translate: { eventDate: vm.bookingDate } }}
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </span>
                      </div>
                      
                      <button app-button color="accent" class="bztt-w-full bztt-h-8" (click)="openPdfPreview()">
                        {{ 'assistantChat.view' | translate }}
                      </button>
                    </div>
                  </chat-message>
              </ng-container>
            
            </div>
          </div>
          
          <div 
            *ngIf="chatInfo.chatMessages[chatInfo.chatMessages.length - 1]?.options?.toolCall as toolCall"
            class="bztt-flex bztt-font-sora bztt-flex-col bztt-gap-2 bztt-max-w-2xl bztt-mx-auto bztt-mt-auto bztt-p-2 bztt-w-full"
          >
            <span class="bztt-text-xs bztt-font-normal bztt-text-[#6B6060]">{{'assistantChat.quickReplies' | translate}}</span>
            
            <ng-container [ngSwitch]="toolCall.function.name">
  
              <div *ngSwitchCase="'is_ready_for_proposal'" class="bztt-flex bztt-gap-2">
                <button (click)="getAQuoteFlowHandler()" class="bztt-w-full bztt-h-9 bztt-border bztt-font-normal bztt-text-sm bztt-border-[#EF5514] bztt-text-[#EF5514] bztt-rounded-lg">
                  {{'assistantChat.actionButton.yes' | translate}}
                </button>
                <button (click)="getAQuoteNotReadyHandler()" class="bztt-w-full bztt-h-9 bztt-border bztt-font-normal bztt-text-sm bztt-border-[#EF5514] bztt-text-[#EF5514] bztt-rounded-lg">
                  {{'assistantChat.actionButton.notReady' | translate}}
                </button>
              </div>
    
              <div *ngSwitchCase="'is_ready_for_book_a_tour'" class="bztt-flex bztt-gap-2">
                <button (click)="bookATourFlowHandler()" class="bztt-w-full bztt-h-9 bztt-border bztt-font-normal bztt-text-sm bztt-border-[#EF5514] bztt-text-[#EF5514] bztt-rounded-lg">
                  {{'assistantChat.actionButton.yes' | translate}}
                </button>
                <button (click)="bookATourNotReadyHandler()" class="bztt-w-full bztt-h-9 bztt-border bztt-font-normal bztt-text-sm bztt-border-[#EF5514] bztt-text-[#EF5514] bztt-rounded-lg">
                  {{'assistantChat.actionButton.notReady' | translate}}
                </button>
              </div>
    
            </ng-container>
          </div>
  
          <div 
            *ngIf="type === 'reels' && chatInfo.chatMessages.length === 1"
            class="bztt-flex bztt-font-sora bztt-flex-col bztt-gap-2 bztt-max-w-2xl bztt-mx-auto bztt-mt-auto bztt-p-2 bztt-w-full"
          >
            <span class="bztt-text-xs bztt-font-normal bztt-text-[#6B6060]">{{'assistantChat.suggestions' | translate}}</span>
            
            <div class="bztt-flex bztt-gap-2">
              <button (click)="getAQuoteFlowHandler()" class="bztt-w-full bztt-h-9 bztt-border bztt-font-normal bztt-text-sm bztt-border-[#EF5514] bztt-text-[#EF5514] bztt-rounded-lg">
                {{'assistantChat.actionButton.getAQuote' | translate}}
              </button>
              <button (click)="bookATourFlowHandler()" class="bztt-w-full bztt-h-9 bztt-border bztt-font-normal bztt-text-sm bztt-border-[#EF5514] bztt-text-[#EF5514] bztt-rounded-lg">
                {{'assistantChat.actionButton.bookATour' | translate}}
              </button>
            </div>
          </div>
        </div>
    
        <!-- <chat-messages-actions
          #messageActions
          class="bztt-block bztt-w-full bztt-mt-auto"
          placeholder="assistantChat.chatActionPlaceholder"
          messageActionType="icon"
          [showActions]="false"
          (sendMessage)="sendMessage($event)"
          (expand)="showDebugPrompt()"
          [messageActionButtonDisabled]="messageInProgress || promptControl.invalid || chatInfo.chatMessages[chatInfo.chatMessages.length - 1]?.options?.toolCall != null"
        ></chat-messages-actions> -->

      </ng-container>
    </ng-container>

    <chat-messages-actions
      *ngIf="{
        chatInfo: chatInfo$ | async
      } as vm"
      #messageActions
      class="bztt-block bztt-w-full bztt-mt-auto"
      [placeholder]="placeholder"
      messageActionType="icon"
      [showActions]="false"
      (sendMessage)="sendMessage($event)"
      (expand)="showDebugPrompt()"
      (inputBlurred)="inputBlurredHandler()"
      [messageActionButtonDisabled]="messageInProgress || promptControl.invalid || vm.chatInfo?.chatMessages[vm.chatInfo?.chatMessages.length - 1]?.options?.toolCall != null"
    ></chat-messages-actions>
  </div>

  <!-- <div class="bztt-flex bztt-flex-col bztt-items-center bztt-flex-grow bztt-p-2 bztt-w-[30%] bztt-border-l">
    <div class="bztt-flex bztt-items-center bztt-justify-center bztt-h-16">
      <span>Debug prompt</span>
    </div>

    <textarea
      class="bztt-w-full bztt-h-full bztt-resize-none bztt-jarvis-scroll bztt-bg-[#F1F1F1] bztt-font-montserrat-r bztt-rounded-lg bztt-p-3 bztt-text-sm"
      [formControl]="promptControl"
    ></textarea>

  </div> -->

</div>

<ng-container *ngIf="{
  component: getAQuoteFlowPortal$ | async
} as vm">
  <ng-template (attached)="vm.component?.attachCb($event)" [cdkPortalOutlet]="vm.component?.portal"></ng-template>
</ng-container>

<ng-container *ngIf="{
  component: bookATourFlowPortal$ | async
} as vm">
  <ng-template (attached)="vm.component?.attachCb($event)" [cdkPortalOutlet]="vm.component?.portal"></ng-template>
</ng-container>

<ng-container *ngIf="{
  component: pdfPreviewPortal$ | async
} as vm">
  <ng-template (attached)="vm.component?.attachCb($event)" [cdkPortalOutlet]="vm.component?.portal"></ng-template>
</ng-container>

<debug-prompt
  [promptControl]="promptControl"
  *ngIf="showDebugPrompt$ | async"
  (closed)="closeDebugPrompt()"
></debug-prompt>