<div
  class="curated-container bztt-flex bztt-justify-center bztt-items-center bztt-h-full bztt-w-full bztt-py-[78px] bztt-px-6"
>
  <div
    class="curated-inner-container bztt-max-w-[84rem] bztt-w-full bztt-flex bztt-flex-col bztt-gap-6 xl:bztt-px-9"
  >
    <div class="curated-section-title">
      <span class="bztt-text-[#EF5518]">
        {{ 'landing.homepageUplift.curated.title.1' | translate }}
      </span>
      <br />
      <span class="bztt-text-[#1F1F1F]">
        {{ 'landing.homepageUplift.curated.title.2' | translate }}
      </span>
    </div>

    <div class="bztt-text-[#6B6060] bztt-text-start bztt-mb-4 bztt-max-w-4xl">
      {{ 'landing.homepageUplift.curated.description' | translate }}
    </div>

    <div
      class="bztt-gap-3 md:bztt-gap-7 bztt-justify-between curated-items-container bztt-pb-[18px]"
    >
      <a
        *ngFor="let item of listsToShow"
        style="box-shadow: 0px 0px 13px 2px rgba(218, 218, 218, 0.25)"
        class="bztt-rounded-[7px] bztt-p-3 md:bztt-p-6 bztt-flex bztt-flex-col bztt-gap-4 bztt-bg-white bztt-min-w-[300px] bztt-cursor-pointer bztt-border-none"
        [routerLink]="
          (country === 'lt' ? '/rekomenduojami-tiekejai/' : '/curated-list/') +
          item.slug
        "
      >
        <div class="">
          <img
            class="bztt-rounded-md bztt-col-span-2 bztt-h-[350px] bztt-w-[300px] md:bztt-h-96 md:bztt-w-96 bztt-object-cover"
            [src]="item.image"
            [alt]="item.title"
          />
        </div>

        <div class="bztt-flex bztt-justify-between bztt-items-center">
          <div class="bztt-flex bztt-flex-col">
            <div
              class="bztt-text-lg md:bztt-text-2xl bztt-text-start bztt-font-semibold bztt-min-h-[64px] max-two-lines bztt-flex bztt-items-center"
            >
              {{ item.title }}
            </div>
            <div
              class="bztt-text-sm md:bztt-text-base bztt-text-[#6B6060] bztt-text-start max-two-lines"
            >
              {{ item.subTitle }}
            </div>
          </div>
          <div class="bztt-pl-3">
            <div
              class="bztt-h-10 bztt-w-10 bztt-rounded-full bztt-border-[1px] bztt-border-solid bztt-border-[#EF5518] bztt-flex bztt-justify-center bztt-items-center bztt-cursor-pointer"
            >
              <mat-icon class="bztt-text-[#EF5518]">arrow_forward</mat-icon>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div
      (click)="handleSeeMore()"
      class="bztt-hidden md:bztt-block"
      *ngIf="curatedLists.length !== listsToShow.length"
    >
      <a> {{ 'landing.homepageUplift.curated.seeMore' | translate }}</a>
    </div>
  </div>
</div>
