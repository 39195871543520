import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { JarvisLocaleValuesService, ServiceTypes } from '@jarvis/services';
import {
  BookingsType,
  BookingsUtil,
  ServiceBase,
  ServiceEvents,
} from '@jarvis/types';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BookingDetailsService } from '../../booking-details.service';

@Component({
  selector: 'bookings-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
})
export class ServicesListComponent implements OnInit {
  @Input() detailData: BookingsType;
  @Output() openBookingEdit = new EventEmitter<void>();

  private localeVal = inject(JarvisLocaleValuesService);
  serviceBase: ServiceBase;
  serviceEvent: ServiceEvents;
  listOfServices: any[];
  locale: string;
  currencyCode: string;
  ServiceTypes = ServiceTypes;
  bookingsUtil = BookingsUtil;

  translateService = inject(TranslateService);
  bookingDetailsService = inject(BookingDetailsService);
  titleTranslation = "orderTitle";

  get flexible() {
    return this.detailData.options.noEventDate
      ? this.translateService.instant('bookings.list.flexible')
      : '';
  }

  ngOnInit(): void {
    this.serviceBase = this.detailData.serviceBaseId as ServiceBase;
    this.serviceEvent = this.detailData.serviceEventId as ServiceEvents;
    const localeValues = this.localeVal.getLocaleValues(this.serviceBase);
    this.locale = localeValues.localeId;
    this.currencyCode = localeValues.currencyCode;
    this.listOfServices = this.detailData.details.filter(
      (v) => v.qnt > 0 && v.price > 0
    );

    if (this.detailData.paid>0 || this.detailData.options.contractSigned)  this.titleTranslation = "bookingTitle";
  }

  filterFunc = (v) => {
    if (!v.isIncluded) return false;
    if (
      v.serviceVariant &&
      v.serviceVariant.type === ServiceTypes.globalMinimalBudget
    ) {
      return v.serviceVariant.customPrice > 0 || v.price > 0;
    }
    return v.qnt > 0 && v.price > 0 && v.amount > 0;
  };

  private dateDiff(mode: 'days' | 'hours' | 'minutes' | 'seconds'): number {
    return moment(
      this.detailData.eventTillDate ?? this.detailData.eventDate
    ).diff(moment(this.detailData.eventDate), mode);
  }

  isSameDay(): boolean {
    return this.dateDiff('days') == 0;
  }

  isTimeScheduled(): boolean {
    return (
      this.serviceBase?.scheduling?.multiClientPerDay &&
      this.dateDiff('minutes') != 0
    );
  }

  get venueSeparateService(): any {
    return this.detailData.details.find(
      (service: any) => service.type === ServiceTypes.venueSeparate
    );
  }

  public facts() {
    return BookingsUtil.getUIState(this.detailData, this.serviceBase, 'facts');
  }
}
