import { ConfigurationDataService } from './../../../../../../../../auth/src/lib/services/configuration-data.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'jarvis-claim-offer-bar',
  templateUrl: './claim-offer-bar.component.html',
  styleUrls: ['./claim-offer-bar.component.scss']
})
export class ClaimOfferBarComponent implements OnInit {
  offersLeftCount;

  @Output() closeOffer = new EventEmitter<void>();
  @Output() claimOffer = new EventEmitter<void>();

  constructor(
    private configurationDataService: ConfigurationDataService
  ) { }

  async ngOnInit() {
    const configuration = await this.configurationDataService.getConfiguration('MEMBERSHIP_PRICING');
    this.offersLeftCount = configuration && configuration.offersLeftCount ? configuration.offersLeftCount : 55;
  }

  handleOfferClose() {
    this.closeOffer.emit();
  }

  handleOfferClaim() {
    this.claimOffer.emit();
  }

}
