import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LayoutService } from '@jarvis/ui';
import { LightGallerySettings } from 'lightgallery/lg-settings';
import { LightGallery } from 'lightgallery/lightgallery.min.js';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  retry,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { MessagingService } from '../../services/messaging.service';
import { ChatRoom } from '../../types/messaging.types';
import { MessagesService } from '../services/messages.service';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import { InitDetail } from 'lightgallery/lg-events';
import { ServiceBase } from '@jarvis/types';
import { CdkOverlayOrigin, OverlayRef } from '@angular/cdk/overlay';
import { iOS } from '@jarvis/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { MESSAGES_TYPE } from '../services/messages-type.token';

let GLOBAL_SELF_MESSAGE_ID = 0;

@Component({
  selector: 'messaging-chat-messages',
  templateUrl: 'chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss']
})
export class MessagingChatMessagesComponent implements OnInit, OnDestroy {
  // @ViewChild('chatActions') chatActionsTemplate: TemplateRef<any>;

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  @ViewChild('messageInput') messageInput: ElementRef<HTMLTextAreaElement>;
  @ViewChild('messageContainer')
  set messagesContainer(content: ElementRef<HTMLDivElement>) {
    if (content) {
      this._messagesContainer = content;
      this.scrollToBottom();
    }

    this._messagesContainer = null;
  }
  get messagesContainer() {
    return this._messagesContainer;
  }
  _messagesContainer: ElementRef<HTMLDivElement>;

  gallerySettings: LightGallerySettings = {
    counter: false,
    // container: this._elementRef.nativeElement as HTMLElement,
    controls:false,
    dynamic: true,
    dynamicEl: [],
    plugins: [lgZoom, lgVideo],
    download: false,
    mobileSettings: {
      showCloseIcon: true,
    },
  };

  currentUserId: string;
  currentChatroomId$: BehaviorSubject<string>;
  currentChatRoom$: Observable<ChatRoom>;
  autoReply$: Observable<ChatRoom['autoReply']>;
  autoReplyActive$: Observable<boolean>;
  messageControl = new UntypedFormControl(null);

  profilePhotoImage: string;

  selfMessage$ = new Subject<any>();

  emojiPickerOpen = false;

  isLoading = false;
  currentChatRoomMessages$: Observable<any>;

  textAreaFocused = false;
  isExpanded = false;

  moreActionsEl = null;
  serviceBase: ServiceBase = null;

  mobileActionsMenuRef: OverlayRef | null = null;

  actionsMenuOpened = false;

  private messageActionsOverlayDataSource = new BehaviorSubject<any>(null);
  messageActionsOverlayData$ = this.messageActionsOverlayDataSource.asObservable();

  private autoReplyShownSource = new BehaviorSubject<boolean>(false);
  autoReplyShown$ = this.autoReplyShownSource.asObservable();

  private lightGallery: LightGallery;

  private editMessageId: string = null;
  get editMode() {
    return !!this.editMessageId;
  }

  domainCountry = inject(DOMAIN_COUNTRY);
  messagesType = inject(MESSAGES_TYPE);

  private router = inject(Router);
  private currentRoute = inject(ActivatedRoute);

  private scrollToBottomEnabled = true;
  private destroy$ = new Subject<void>();

  uploadFiles: File[] = [];

  private sanitizer = inject(DomSanitizer);

  private retrySubjects: Array<{ lastId: string, subject: Subject<void> | null}> = [];
  
  private messagingService = inject(MessagingService);
  private messagesService = inject(MessagesService);
  private layoutService = inject(LayoutService);
  private route = inject(ActivatedRoute)
  
  isMobile$ = this.layoutService.isLaptopObserver;
  isMobile = this.layoutService.isLaptop;
  
  ngOnInit() {
    this.currentUserId = this.messagesService.currentUserId;
    this.currentChatroomId$ = this.messagesService.currentChatroomId$;

    if (iOS() && window.visualViewport) {
      fromEvent(window.visualViewport, 'resize')
        .pipe(
          switchMap(() => this.isMobile$),
          filter((isMobile) => isMobile),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          const elList = document.getElementsByClassName('actions-container');
          const targetEl = elList[0] as HTMLDivElement;
          if (targetEl && this.isExpanded) {
            targetEl.style.setProperty(
              '--dynamic-fixed-height',
              `${window.visualViewport.height}px`
            );
            window.scroll({ top: 0 });
          }
        });
    }
    
    // REMOVE_LATER: Fixed a bug on mobile view where this would search for a null chatroom id
    this.currentChatRoomMessages$ = this.currentChatroomId$.pipe(
      distinctUntilChanged(),
      switchMap((id) => {       
        if (!id) {
          return of([]);
        }
 
        return this.messagingService.getChatroom$(id, this.selfMessage$).pipe(
          tap(() => {
            this.markAsRead();
            this.calculateShouldScrollToBottom();
          })
        );
      }),
      //tap((messages) => console.log(messages))
    );

    /* this.messagesService.currentChatRoom$.subscribe(chatroom => {
      console.log(chatroom);
    }); */

    this.currentChatRoom$ = this.messagesService.currentChatRoom$;

    this.autoReply$ = this.currentChatRoom$.pipe(
      map((chatroom) => {
        const autoReplies = chatroom.autoReply || [];
        return autoReplies;
      })
    ).pipe(
      shareReplay(1)
    );

    this.autoReplyActive$ = this.autoReply$.pipe(
      map(autoReplies => {
        return autoReplies.some(autoReply => autoReply.active);
      })
    );

    this.currentChatRoom$
      .pipe(takeUntil(this.destroy$))
      .subscribe((chatroom) => {
        // TODO: Handle no image with a directive
        this.profilePhotoImage = chatroom?.users?.profilePhoto || '/assets/images/noUser.jpeg';
        this.serviceBase = chatroom.serviceBase;
      });
  }

  ngOnDestroy(): void {
    this.retrySubjects.forEach((record) => {
      record.subject.complete();
    });
    this.messageActionsOverlayDataSource.complete();
    this.autoReplyShownSource.complete();
    this.selfMessage$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  textAreaFocus(event: any): void {
    event.currentTarget.focus();
  }

  textAreaLeave(event: any): void {
    event.currentTarget.blur();
  }

  closeEmojiPicker(): void {
    this.emojiPickerOpen = false;
  }

  openEmojiPicker(): void {
    this.emojiPickerOpen = true;
  }

  toggleTextAreaFocus(event: 'focus' | 'blur'): void {
    if (event === 'blur') {
      this.textAreaFocused = false;
      return;
    }

    this.textAreaFocused = true;
  }

  toggleTextAreaExpand(): void {
    // Does not work in iOS (a la security feature, needs to originate in sync)
    if (!this.isExpanded) {
      setTimeout(() => {
        this.messageInput.nativeElement.focus();
      }, 100);
    }
    this.isExpanded = !this.isExpanded;
  }

  markAsRead() {
    if (this.currentChatroomId$.value) {
      this.messagingService
        .markChatroomAsRead(this.currentChatroomId$.value, this.currentUserId)
        .subscribe();
    }
  }
  
  markAsUnread() {
    if (this.currentChatroomId$.value) {
      this.messagingService
        .markChatroomAsUnread(this.currentChatroomId$.value, this.currentUserId)
        .subscribe();

      this.router.navigate(['..'], { relativeTo: this.currentRoute});
    }
  }

  handleInputKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey && !this.isMobile) {
      if (this.editMode) {
        this.confirmMessageEdit();
      } else {
        this.sendMessage();
      }
      event.preventDefault();
    }
  }

  openMessageActionsOverlay(anchor: CdkOverlayOrigin, messageData: any, isLastMessage: boolean): void {
    this.messageActionsOverlayDataSource.next({
      isOwn: this.isOwn(messageData.sender),  
      currentUserId: this.currentUserId,
      anchor,
      messageData,
      isLastMessage
    });
  }

  closeMessageActionsOverlay(): void {
    this.messageActionsOverlayDataSource.next(null);
  }

  startMessageEdit(messageId: string, message: string) {
    this.editMessageId = messageId;
    this.messageControl.setValue(message);
    this.messageInput.nativeElement.focus();
  }

  confirmMessageEdit() {
    const textMessage = this.messageControl.value;
    this.messagingService
      .editMessage(this.editMessageId, textMessage)
      .subscribe();
    this.messageControl.reset();
    this.editMessageId = null;
  }

  cancelMessageEdit() {
    this.editMessageId = null;
    this.messageControl.reset();
  }

  deleteMessage(messageId: string) {
    this.messagingService.deleteMessage(messageId).subscribe();
  }

  autoReplyHandler(): void {
    this.autoReplyShownSource.next(true);
  }

  closeAutoReply(): void {
    this.messagingService.handleAutoReplyClosed(null);
    this.autoReplyShownSource.next(false);
  }

  openGalleryImage(galleryItems: any[], index: number, inactive = false): void {
    if (inactive) {
      return;
    }

    this.lightGallery.refresh(
      galleryItems.map((item) => ({ src: item.url as string }))
    );
    this.lightGallery.openGallery(index);
  }

  removeFile(index: number) {
    this.uploadFiles.splice(index, 1);
  }

  fileInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    // const chatRoomId = this.currentChatroomId$.value;

    const fileArray = Array.from(files);

    this.uploadFiles = [
      ...this.uploadFiles,
      ...fileArray
    ];

    input.value = null;

    // this.messagesService.uploadFiles(chatRoomId, Array.from(files)).subscribe();
  }

  retrySendMessage(lastId: string): void {
    const subjectRecord = this.retrySubjects.find(record => record.lastId === lastId);
    if (subjectRecord) {
      subjectRecord.subject.next();
    }
  }

  // TODO: Should be handled in a service
  sendMessage(): void {
    const newChatRoom = !! this.route?.snapshot.data?.newChatRoom;

    const textMessage = this.messageControl.value;
    const chatRoomId = this.currentChatroomId$.value;
    const hasFilesToUpload = this.uploadFiles.length > 0;

    if (!chatRoomId && !newChatRoom) {
      return;
    }

    if (!textMessage && !hasFilesToUpload) {
      return;
    }

    this.isExpanded = false;
    const lastId = `${++GLOBAL_SELF_MESSAGE_ID}`;

    if (hasFilesToUpload) {
      const parsedFiles = this.uploadFiles.map(file => {
        const url = URL.createObjectURL(file);
        // MUST: Think about implications if used by bad actor
        const bypassed = this.sanitizer.bypassSecurityTrustUrl(url);

        return {
          fileType: file.type,
          fileName: file.name,
          url: bypassed
        };
      });

      const retrySubject = new Subject<void>();

      this.retrySubjects.push(
        {
          lastId,
          subject: retrySubject
        }
      );

      combineLatest([
        of(true).pipe(
          tap(() => {
            this.selfMessage$.next({
              message: 'self files message',
              data: {
                _id: lastId,
                createdAt: new Date(),
                updatedAt: new Date(),
                message: textMessage,
                type: 'files',
                sender: this.currentUserId,
                files: parsedFiles,
                uploading: true
              },
            });
          })
        ),
        this.getFileSendStream(chatRoomId, this.uploadFiles, lastId, textMessage)
      ]).pipe(
        retry({
          delay: () => {
            this.selfMessage$.next({
              message: 'self files error',
              data: {
                _id: lastId,
                uploading: false,
                error: true
              },
            });

            return retrySubject;
          }
        })
      ).subscribe({
        next: () => {
          retrySubject.complete();
          const subjectRecordIndex = this.retrySubjects.findIndex((record) => record.lastId === lastId);
          if (subjectRecordIndex > -1) {
            this.retrySubjects.splice(subjectRecordIndex, 1);
          }
        },
        error: (err) => {
          console.error(err);
        }
      });

    } else {
      this.selfMessage$.next({
        message: 'self message',
        data: {
          _id: lastId,
          createdAt: new Date(),
          updatedAt: new Date(),
          message: textMessage,
          type: 'text',
          sender: this.currentUserId,
        },
      });

      this.getSendMessageStream(chatRoomId, textMessage, lastId).subscribe({
        error: (err) => {
          console.error(err);
        }
      });
    }

    this.uploadFiles = [];
    this.messageControl.reset();
  }

  // TODO: Should be in a service
  private getSendMessageStream(chatRoomId: string, textMessage: string, lastId: string) {
    return this.messagingService
      .sendMessage({
        chatRoomId,
        message: textMessage,
        lastId,
      })
      .pipe(
        catchError((err) => {
          this.selfMessage$.next({
            message: 'self message error',
            data: {
              _id: lastId,
              errorType: 'default',
            },
          });

          return of(new Error(err));
        })
      );
  }

  private getFileSendStream(chatRoomId: string, files: File[], lastId: string, messageText?: string): Observable<any> {
    /* return timer(2000).pipe(
      tap(() => console.log('Fake request initiated')),
      switchMap(() => throwError(() => new Error('Whoops')))
    ); */

    if (messageText) {
      return this.messagesService.uploadFiles(chatRoomId, files, lastId, messageText);
    }
    
    return this.messagesService.uploadFiles(chatRoomId, files, lastId);
  }

  addEmoji(event: any) {
    const emojiChar = event.emoji.native;
    const currentValue = (this.messageControl.value as string) ?? '';
    let newValue: string;
    if (currentValue.charAt(currentValue.length - 1) !== ' ') {
      newValue = `${currentValue} ${emojiChar}`;
    } else {
      newValue = `${currentValue}${emojiChar}`;
    }

    this.messageControl.setValue(newValue);
    this.closeEmojiPicker();
  }

  messageTrackBy(index: number, el: any) {
    return el._id;
  }

  galleryOnInit = (detail: InitDetail): void => {
    this.lightGallery = detail.instance;    
  };

  messageTap(event: any) {
    //console.log(event);
    const target = event.target as HTMLDivElement;
    target.focus();
  }

  private calculateShouldScrollToBottom(): void {
    if (!this.messagesContainer) {
      return;
    }

    const elementHeight = this.messagesContainer.nativeElement.clientHeight;
    const scrollContainerHeight =
      this.messagesContainer.nativeElement.scrollHeight;
    const maxScrollHeight = scrollContainerHeight - elementHeight;
    const currentHeight = this.messagesContainer.nativeElement.scrollTop;
    const scrollToBottomLimit = elementHeight / 2;

    if (currentHeight < maxScrollHeight - scrollToBottomLimit) {
      this.scrollToBottomEnabled = false;
    }

    this.scrollToBottomEnabled = true;
  }

  private scrollToBottom() {
    if (!this.messagesContainer || !this.scrollToBottomEnabled) {
      return;
    }

    // const elementHeight = this.messagesContainer.nativeElement.clientHeight;
    const scrollContainerHeight =
      this.messagesContainer.nativeElement.scrollHeight;
    // const maxScrollHeight = scrollContainerHeight - elementHeight;

    this.messagesContainer.nativeElement.scroll({
      top: scrollContainerHeight + 10,
    });
  }

  public isOwn(usr:string):boolean { return this.messagesService.isOwn(usr); }
}
