import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'groupValues',
    standalone: true
})


export class GroupValuesPipe implements PipeTransform {
 transform(value: any, property: string): Array<any> { 

        if(!value || !(value instanceof Array)) return null;
        if(!property) return null;
        
        const result:any[] = [];
        
        value.forEach((item) => {
            const propValue = item[property];
            if (result.indexOf(propValue)<0) result.push(propValue);
        });    
    return result;
    }
}    
