import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';
import { BASE_URL } from '@jarvis/services/tokens';
import { MetricsEnum } from '@jarvis/types';
import { JarvisAuthService } from '@jarvis/auth';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private httpService = inject(HttpClient);
  private baseUrl = inject(BASE_URL);
  private authService = inject(JarvisAuthService);

  saveDayAnalytics(
    _type: MetricsEnum,
    user: string | null,
    data: any,
    serviceBase?: string,
    serviceEvent?: string
  ): Observable<any> {
    const type = isNaN(Number(_type)) ? _type : MetricsEnum[_type];
    return this.httpService.post<any>(
      `${this.baseUrl}/common/saveDayAnalytics`,
      { type, user, data, serviceBase, serviceEvent }
    );
  }

  saveDayCountAnalytics(
    _type: MetricsEnum,
    user?: string,
    serviceBase?: string,
    serviceEvent?: string
  ): Observable<any> {
    return this.saveDayAnalytics(
      _type,
      user || null,
      { count: 1 },
      serviceBase,
      serviceEvent
    );
  }

  saveAnalytics(
    _type: MetricsEnum,
    user?: string,
    serviceBase?: string,
    serviceEvent?: string,
    data?: any
  ): Observable<any> {
    const type = isNaN(Number(_type)) ? _type : MetricsEnum[_type];
    // return this.httpService.post<any>(`${this.baseUrl}/common/saveAnalytics`, {type,user, data,serviceBase,serviceEvent});
    return this.authService.isLoggedIn$.pipe(
      take(1),
      switchMap((loggedIn) => {
        const postData: Record<string, any> = {
          type,
          user,
          data,
          serviceBase,
          serviceEvent,
        };

        if (!loggedIn) {
          postData.session = this.authService.unauthId;
        }

        return this.httpService.post<any>(
          `${this.baseUrl}/common/saveAnalytics`,
          postData
        );
      })
    );
  }

  saveCountAnalytics(
    _type: MetricsEnum,
    user: string,
    serviceBase?: string,
    serviceEvent?: string
  ): Observable<any> {
    return this.saveAnalytics(_type, user, serviceBase, serviceEvent, {
      count: 1,
    });
  }

  getAnalytics(
    _type: MetricsEnum,
    dateFrom: Date,
    dateTo: Date,
    serviceBase?: string,
    serviceEvent?: string
  ): Observable<any> {
    const type = isNaN(Number(_type)) ? _type : MetricsEnum[_type];
    return this.httpService.get<any>(
      `${
        this.baseUrl
      }/common/getAnalytics?type=${type}&from=${dateFrom.getTime()}&to=${dateTo.getTime()}`
    );
  }
}
