<div
class="bztt-hidden md:bztt-flex bztt-max-w-lg bztt-ml-8 bztt-border-[#A8A8A8] bztt-border-2 bztt-relative bztt-items-center bztt-h-10 bztt-w-full
      bztt-focus-within:shadow-lg bztt-bg-white bztt-overflow-hidden bztt-rounded-full"
>

<div 
  class="bztt-absolute bztt-grid bztt-place-items-center bztt-h-full bztt-w-12 bztt-text-[#A8A8A8]"
>
  <mat-icon >search</mat-icon>
</div>

<input
  class="bztt-pl-10 bztt-peer bztt-h-full bztt-w-full bztt-outline-none bztt-text-sm bztt-text-gray-700 bztt-pr-2"
  type="text"
  [matAutocomplete]="auto"
  (focus)="onFocus()"   
  [formControl]="searchPartnersControl"  
  [placeholder]="'header.topSearch' | translate"
/>
</div> 
  
<div
  class="bztt-flex bztt-mr-4 md:bztt-hidden bztt-justify-end bztt-h-full">
  <mat-icon (click)="mobileShowInput=true">search</mat-icon>
</div>

<div *ngIf="mobileShowInput" class="bztt-flex bztt-z-[1000] md:bztt-hidden  bztt-absolute bztt-left-0 bztt-top-0 bztt-h-full bztt-w-full bztt-bg-white">
<mat-form-field appearance = "standard" class="bztt-w-full align-items-center">
    <mat-icon (click)="mobileShowInput=false" matPrefix class="bztt-ml-3 bztt-bg-white bztt-h-[30px]">arrow_back</mat-icon>
    <input matInput class="bztt-w-full bztt-h-[30px] bztt-bg-white bztt-outline-none bztt-text-sm bztt-text-gray-700 bztt-px-2"
    type="text"
    (focus)="onFocus()"
    [placeholder]="'header.topSearch' | translate"
    [matAutocomplete]="auto"
    [formControl]="searchPartnersControl"
    />
    <mat-icon matSuffix class="bztt-bg-white bztt-h-[30px] bztt-mr-3">search</mat-icon>
</mat-form-field>
</div>


<mat-autocomplete
  #auto="matAutocomplete"
  class="top-search-ac bzt-select-overlay"
  [panelWidth]="getPanelWidth()"  
>
  <mat-option class="bztt-w-full bztt-flex bztt-justify-center" *ngIf="eventsLoading$ | async"><mat-progress-spinner mode="indeterminate" [diameter]="20" strokeWidth="2"></mat-progress-spinner></mat-option>
  <mat-option class="selected" *ngFor="let partner of partnersOptions" (click)="selectParther(partner)">
    {{ partner.name }}
  </mat-option>
</mat-autocomplete>

<!-- <mat-progress-spinner mode="indeterminate" [diameter]="20" strokeWidth="2"></mat-progress-spinner> -->