import { ProfileSelectorDialogComponent } from './../event-host-homepage/components/profile-selector-dialog/profile-selector-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

import { ComponentPortal } from '@angular/cdk/portal';
import { DOMAIN_COUNTRY, JarvisTrackingService } from '@jarvis/services';
/* eslint-disable max-len */
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
  HostListener,
  inject,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { JarvisAuthService } from '@jarvis/auth';
import { LinkUrls, LINK_URLS } from '@jarvis/services';
import { JarvisHeaderService, JarvisUiModalService } from '@jarvis/ui';
import { LayoutService } from '@jarvis/ui/layout';

import { Subject, merge } from 'rxjs';
import { take, takeUntil, map, filter } from 'rxjs/operators';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { serviceEventPictureSort } from '@jarvis/utils';
import { HomepageMenuServiceListComponent } from './components/service-list/service-list.component';
import { HomepageUpliftedService } from './services/homepage-uplifted.service';

@Component({
  selector: 'jarvis-homepage-uplifted',
  templateUrl: './homepage-uplifted.component.html',
  styleUrls: ['./homepage-uplifted.component.scss'],
})
export class HomepageUpliftedComponent implements OnInit, OnDestroy {
  @ViewChild('landingHeader', { static: true })
  landingHeader: TemplateRef<HTMLDivElement>;
  @ViewChild('intersection', { read: ElementRef }) intersection: ElementRef;

  private homepageService = inject(HomepageUpliftedService);
  private layoutService = inject(LayoutService);
  private trackService = inject(JarvisTrackingService);

  window = window;

  scrolledTopPosition = true;

  Math = Math;

  isMobile = false;
  loggedIn$ = this.authService.isLoggedIn$;
  saasLandingLink = `${this.linkUrls.saas}`;
  private destroy$ = new Subject<void>();

  currentImageIndex = 0;

  servicesOverlay: OverlayRef;
  closeOverlay = new Subject<void>();

  eventTypes = ['privateEvent', 'businessEvent'];
  eventType;

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.innerHeight + window.scrollY === document.body.offsetHeight) {
      this.scrolledTopPosition = true;
    } else {
      this.scrolledTopPosition = false;
    }
  }

  constructor(
    private headerService: JarvisHeaderService,
    private router: Router,
    private authService: JarvisAuthService,
    private overlay: Overlay,
    private modalService: JarvisUiModalService,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string,
    @Inject(LINK_URLS) private linkUrls: LinkUrls,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.layoutService.disableFooterMargin(), 1000);
    this.layoutService.disableFooter();

    this.layoutService.isMobileObserver
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => (this.isMobile = isMobile));

    // this.headerService.setHeaderTemplate(this.landingHeader, this.vcr);

    this.eventType = 'wedding';

    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'page_load',
      data: {
        page_type: 'homepage',
      },
    });
  }

  roundToTwoPrecision(value: number) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.layoutService.enableFooterMargin();
  }

  openContactFormDialog(): void {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      this.router.navigateByUrl('/auth/register');
      this.authService
        .createRegisterOrLoginSuccessStream()
        .subscribe((newUserData) => {
          this.navigateToFirstHomePage(newUserData.userType);
        });
    });
  }

  openLoginDialog(
    userType: 'user' | 'vendor' = 'user',
    dialogType: 'login' | 'register' = 'login'
  ): void {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      this.router.navigate(['/auth/' + dialogType], {
        state: { userType },
      });

      this.authService
        .createRegisterOrLoginSuccessStream()
        .subscribe((newUserData) => {
          // console.log(newUserData);
          this.navigateToFirstHomePage(newUserData.userType);
        });
    });
  }

  private navigateToFirstHomePage(userType: 'user' | 'vendor'): void {
    if (userType === 'user') {
      this.router.navigateByUrl('/planner');
      return;
    }

    window.location.href = this.linkUrls.saas;
  }

  scrollTop(smooth = false) {
    window.scrollTo({
      top: 0,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }

  get marketplaceRouterLink() {
    const wedding_date = this.homepageService.eventDateControl.value;

    const url =
      this.domainCountry === 'lt' ? 'rekomenduojami' : '/wedding-venues';

    if (this.homepageService.eventLocationControl.value) {
      const locationSlug =
        this.homepageService.eventLocationControl?.value?.slug || '';

      const queryParams = {};

      if (!locationSlug) {
        const locationData = this.homepageService.eventLocationControl?.value;

        if (locationData && locationData.key) {
          queryParams['locationKey'] = locationData.key;
        }
      }

      return {
        link: '/wedding-venues/' + locationSlug,
        queryParams,
        state: {
          fromHomePage: true,
        },
      };
    }

    if (wedding_date) {
      return {
        link: url,
        // queryParams: { date: wedding_date.getTime() },
        state: {
          fromHomePage: true,
        },
      };
    }

    return {
      link: url,
      state: {
        fromHomePage: true,
      },
    };
  }

  navigateToMarketplace() {
    return this.router.navigate(['/new']);
  }

  imageMap(value, eventType) {
    const photos = value[eventType].photos;

    if (!photos) {
      return [];
    }

    const sortedPhotos = serviceEventPictureSort(photos);

    return sortedPhotos;
  }

  openServicesOverlay(): void {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'services_click',
    });

    if (this.servicesOverlay && this.servicesOverlay.hasAttached()) {
      this.closeOverlay.next();
      return;
    }

    if (this.layoutService.isMobile) {
      this.modalService.openDialog(HomepageMenuServiceListComponent);
    } else {
      this.servicesOverlay = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'uplifted-overlay-transparent-backdrop',
        scrollStrategy: this.overlay.scrollStrategies.block(),
        // width: '100vh',
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.headerService.headerRef)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
            },
          ]),
        // .withDefaultOffsetY(5)
      });

      const componentPortal = new ComponentPortal(
        HomepageMenuServiceListComponent
      );

      const servicesComponentRef = this.servicesOverlay.attach(componentPortal);

      const close$ = servicesComponentRef.instance.close$;

      merge(
        this.servicesOverlay.backdropClick(),
        this.servicesOverlay.detachments(),
        close$,
        this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd)
        ),
        this.closeOverlay.asObservable()
      )
        .pipe(take(1))
        .subscribe(() => {
          this.servicesOverlay.detach();
        });
    }
  }

  get vendorSignupLink() {
    return this.authService.userData$.pipe(
      take(1),
      map(() => {
        const signUpLink =
          this.domainCountry === 'lt' ? '/registracija' : '/sign-up';

        return {
          link: signUpLink,
        };
      })
    );
  }

  handleVendorSignup(location: string, onlyTrack = false) {
    if (onlyTrack) return;

    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      const signUpLink =
        this.domainCountry === 'lt'
          ? '/registracija?source='
          : '/sign-up?source=';

      window.location.replace(window.location.origin + signUpLink + location);
    });
  }

  handleHeaderAuth(type: 'Login' | 'Signup') {
    if (type === 'Login') {
      this.trackService.handleEvent({
        trackers: ['amplitude', 'mixpanel'],
        eventName: 'log_in_click',
      });
      this.openLoginDialog();
    }
    if (type === 'Signup') {
      this.trackService.handleEvent({
        trackers: ['amplitude', 'mixpanel'],
        eventName: 'signup_click',
      });
      this.openUserTypeSelector();
    }
  }

  openUserTypeSelector() {
    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      if (userData) {
        this.navigateToFirstHomePage(userData.userType);
        return;
      }

      const dialog = this.modalService.openDialog(
        ProfileSelectorDialogComponent
      );

      dialog.afterClosed().subscribe((profileType) => {
        if (!profileType) return;

        if (profileType === 'vendor') {
          return this.handleVendorSignup('homepage-header');
        }

        this.trackService.handleEvent({
          trackers: ['amplitude', 'mixpanel'],
          eventName: 'signup_host_click',
        });

        this.openLoginDialog(profileType, 'register');
      });
    });
  }
}
