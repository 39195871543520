import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonModule,
  InputFormatsModule,
  JarvisModalComponent,
  JarvisOverlayComponent,
  JarvisUiModalWrapperModule,
  LayoutService,
  SmallCalendarModule,
} from '@jarvis/ui';
import { MatInputModule } from '@angular/material/input';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import * as moment from 'moment';
import { BookingDetailsService } from 'libs/saved-bookings/src/lib/booking-details.service';
import { BookingsType, BookingsUtil } from '@jarvis/types';
import { BookingDetailsOptions } from 'libs/saved-bookings/src/lib/bookings.types';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AdvertsPriceUnits, ServiceTypes } from '@jarvis/services';
import {
  JarvisUiTimePickerModule,
  MinuteTimeValuePipe,
} from '@jarvis/ui/time-picker';
import { DateUtils } from '@jarvis/utils';

@Component({
  selector: 'jarvis-edit-event-details',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    JarvisOverlayComponent,
    MatIconModule,
    SmallCalendarModule,
    OverlayModule,
    InputFormatsModule,
    JarvisModalComponent,
    JarvisUiModalWrapperModule,
    ButtonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    JarvisUiTimePickerModule,
  ],
  templateUrl: './edit-event-details.component.html',
  styleUrls: ['./edit-event-details.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
    JarvisUiTimePickerModule,
    MinuteTimeValuePipe,
  ],
})
export class EditEventDetailsComponent implements OnInit, OnDestroy {
  @Output() hideMainDialog = new EventEmitter<boolean>();
  moment = moment;
  dateUtils = DateUtils;

  private bookingDetailsService = inject(BookingDetailsService);
  private layoutService = inject(LayoutService);

  showMobileOverlay = false;
  showDesktopOverlay = false;

  showGuestCountPriceUpdateNotification = false;
  showCalendarPriceUpdateNotification = false;

  selectedTime: string;

  get detailData(): BookingsType {
    return this.bookingDetailsService.detailData;
  }

  get locale(): string {
    return this.bookingDetailsService.locale;
  }

  get options(): BookingDetailsOptions {
    return this.bookingDetailsService.options;
  }

  get guestNo() {
    return this.detailData.options.guestNo > 1
      ? this.detailData.options.guestNo
      : null;
  }

  set guestNo(v: number) {
    this.detailData.options.guestNo = v;
  }

  get proposalValidityPeriod() {
    return this.detailData.options?.proposalValidityPeriod;
  }

  set proposalValidityPeriod(v: number) {
    this.detailData.options.proposalValidityPeriod = v;
  }

  get noProposalValidity() {
    return this.detailData.options?.noProposalValidity;
  }

  set noProposalValidity(v: boolean) {
    this.detailData.options.noProposalValidity = v;
  }

  get isMobile() {
    return this.layoutService.isMobile;
  }

  get fullDate(): string {
    if (!this.detailData.eventDate) return null;
    return moment(this.detailData.eventDate)
      .utc()
      .locale(this.locale)
      .format('MMM D, y');
  }

  get date() {
    return this.detailData.eventDate
      ? new Date(this.detailData.eventDate)
      : new Date();
  }

  hideMainDialogEmit(hide = true) {
    this.hideMainDialog.emit(hide);
  }

  destroy$ = new Subject<void>();
  guestFormControl = new FormControl(this.guestNo);
  timeFromControl = new UntypedFormControl();
  timeToControl = new UntypedFormControl();

  get viewDate() {
    return new Date(this.bookingDetailsService.detailData.eventDate);
  }

  ngOnInit(): void {
    if (
      !BookingsUtil.allDayEvent(
        this.detailData.eventDate,
        this.detailData.eventTillDate
      )
    ) {
      this.timeFromControl.patchValue(
        DateUtils.convertTZ(this.detailData.eventDate, 0, 0)
      );
      this.timeToControl.patchValue(
        DateUtils.convertTZ(this.detailData.eventTillDate, 0, 0)
      );
    }

    if (!this.proposalValidityPeriod) {
      this.proposalValidityPeriod = 7;
      this.updateIfChanged();
    }

    this.selectedTime = moment(this.viewDate).format('HH:mm');

    this.guestFormControl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(1000))
      .subscribe((val) => this.guestCountChanged(val));
  }

  closeDatePicker() {
    this.showMobileOverlay = false;
    this.showDesktopOverlay = false;
  }

  selectedTimeChanged(key: 'eventDate' | 'eventTillDate', time: any, doNotUpdate = false) {
    const hours = time.split(':')[0];
    const minutes = time.split(':')[1];
    const date = moment(this.detailData.eventDate)
      .utc(false)
      .set('hours', hours)
      .set('minutes', minutes)
      .set('second', 0)
      .set('millisecond', 0)
      .format();

    this.detailData[key] = new Date(date);
    if (!doNotUpdate) this.updateIfChanged();
  }

  dateChanged(_date:any) {
    this.closeDatePicker();
    const date = DateUtils.sameAsGMT(_date);
    
    const eventTime = moment(this.timeFromControl.value).toDate();
    const saveTime = eventTime?`${eventTime.getHours()}:${eventTime.getMinutes()}`:"00:00";
    this.bookingDetailsService.detailData.eventDate = date;       
    this.selectedTimeChanged('eventDate',saveTime,true);

    if (this.bookingDetailsService.detailData.eventTillDate) {
        const eventTillTime = moment(this.timeToControl.value || DateUtils.convertTZ(this.detailData.eventTillDate, 0, 0)).toDate();
        const saveTillTime = eventTime?`${eventTillTime.getHours()}:${eventTillTime.getMinutes()}`:"23:59";
        this.bookingDetailsService.detailData.eventTillDate = date;               
        this.selectedTimeChanged('eventTillDate',saveTillTime,true);        
    }
    this.handlePriceUpdateDate();
  }

  guestCountChanged(val) {
    this.guestNo = val;
    setTimeout(()=> this.showGuestCountPriceUpdateNotification = true, 100);
    this.hideMainDialogEmit();

    this.updateIfChanged();
  }

  closeNotifications() {
    this.hideMainDialogEmit(false);
    this.showGuestCountPriceUpdateNotification = false;
    this.showCalendarPriceUpdateNotification = false;
  }

  handlePriceUpdateDate() {
    this.closeNotifications();

    this.bookingDetailsService.servicesEditData
      .filter(
        (detail) =>
          detail.serviceVariant &&
          detail.serviceVariant?.type !== ServiceTypes.serviceCharge &&
          detail.isIncluded
      )
      .map((detail) => {
        detail.amount = detail.serviceVariant.getFinalPrice(
          this.detailData.eventDate
            ? new Date(this.detailData.eventDate)
            : undefined
        );

        detail.price = detail.amount / detail.qnt;

        return detail;
      });

    this.updateIfChanged();
    this.bookingDetailsService.saveServiceEditChanges();
  }

  handlePriceUpdateGuestCount(onlyDate: boolean = false) {
    this.closeNotifications();
    const personUnitTypes: string[] = [
      AdvertsPriceUnits.currencyPerPerson,
      AdvertsPriceUnits.pricePerHourPerPerson,
      AdvertsPriceUnits.currencyPerUnit,
      AdvertsPriceUnits.fixedFeeByGuest,
      AdvertsPriceUnits.fixedPerPerson,
    ];

    this.bookingDetailsService.servicesEditData
      .filter(
        (detail) =>
          detail.serviceVariant &&
          personUnitTypes.includes(detail.serviceVariant.priceUnit) &&
          detail.serviceVariant?.type !== ServiceTypes.serviceCharge &&
          detail.isIncluded
      )
      .map((detail) => {
        detail.qnt = this.detailData.options.guestNo;
        detail.serviceVariant.quantity = this.detailData.options.guestNo;

        detail.amount = detail.serviceVariant.getFinalPrice(
          this.detailData.eventDate
            ? new Date(this.detailData.eventDate)
            : undefined
        );

        detail.price = detail.amount / detail.qnt;

        return detail;
      });

    this.updateIfChanged();
    this.bookingDetailsService.saveServiceEditChanges();
  }

  handleDatepickerClick() {
    this.hideMainDialogEmit();
    this.showCalendarPriceUpdateNotification = true;
  }

  openDatePicker(): void {
    this.closeNotifications();
    if (this.layoutService.isMobile) {
      this.showMobileOverlay = true;
      return;
    }
    this.showDesktopOverlay = true;
  }

  public updateIfChanged() {
    this.bookingDetailsService.updateIfChanged();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
