<ng-container *ngIf="bookingInfo$ | async as bookingsInfo; else empty">
    <div class="bztt-h-full bztt-flex bztt-items-center bztt-flex-col bztt-justify-center" *ngIf="waiting$ | async">
        <mat-progress-spinner  mode="indeterminate" strokeWidth="2"></mat-progress-spinner>    
   </div>  
    <ng-container *ngIf="!(waiting$ | async)">
    <div class="booking-info-container" [class.collapsed]="i !== expandedIndex" *ngFor="let booking of bookingsInfo; let i = index;">
        <div class="info-header-container">
            <div class='info-wrapper'>
                <h2>
                    {{booking.serviceName}}
                </h2>
                <ng-container [ngSwitch]="!!booking.eventDate">
                    <ng-container *ngSwitchCase="true">
                        <span *ngIf="i !== expandedIndex">
                            {{'messages.eventDate' | translate}}: {{ booking.eventDate | ServiceDate:serviceBase }}
                        </span>
                    </ng-container>
                    <span *ngSwitchDefault>
                        {{'messages.requestDate' | translate}}: {{ booking.createdAt | ServiceDate:serviceBase }}
                    </span>
                </ng-container>
            </div>

            <div class="expander" *ngIf="bookingsInfo.length > 1">
                <mat-icon (click)="expandedIndex = i">
                    {{expandedIndex === i ? 'remove' : 'add'}}
                </mat-icon>
            </div>
        </div>
        <div class="collapsible">        
            <div class="booking-status {{bookingState(booking)}}" (click)="navigateToBooking(booking._id)">
                {{ 'bookings.state.' + bookingState(booking) + '.name' | translate }}
                <mat-icon *ngIf="messagesType === 'vendor'">expand_more</mat-icon>
            </div>
        
            <div class="date-guest-container">
                <mat-form-field>
                    <mat-label>
                        {{ ( booking.eventDate ? 'messages.eventDate' : 'messages.chooseDate' ) | translate}}
                    </mat-label>
                    <input matInput [value]="booking.eventDate | ServiceDate:serviceBase "/>
                    <mat-icon matSuffix>event</mat-icon>
                </mat-form-field>
                <mat-form-field *ngIf="booking.options?.guestNo">
                    <mat-label>
                        {{ ('bookings.detail.guestsPlaceHolder' + ((booking.options?.guestNo==1)?'NA':'') ) | translate }}  
                    </mat-label>
                    <input matInput [value]="(booking.options?.guestNo==1)?null:booking.options?.guestNo"/>
                    <!-- <mat-inputselect>
                        <mat-option *ngFor="let guestNo of [1, 2, 3, 4, 5, 6]; let i = index;">
                            {{ i }}
                        </mat-option>
                    </mat-select> -->
                </mat-form-field>
                <div class="click-block" (click)="navigateToBooking(booking._id)">
        
                </div>
            </div>
        
            <h2>{{'messages.services' | translate }}</h2>
        
            <div class="service-list-container">
                <div class="service-item" *ngFor="let service of booking.details"> 
                    <span>{{service.name | translate}}</span>
                    <div class="actions" (click)="navigateToBooking(booking._id)">
                        <ng-container [ngSwitch]="service.type === 'serviceCharge'">
                          <span *ngSwitchCase="true">{{ (booking.serviceCharge) | ServiceCurrency:serviceBase }}</span>
                          <span *ngSwitchDefault>{{ (service.price * service.qnt) | ServiceCurrency:serviceBase }}</span>
                        </ng-container>
                        <!-- <ng-container *ngIf="messagesType === 'vendor'">
                            <mat-icon>expand_more</mat-icon>
                            <mat-icon>clear</mat-icon>
                        </ng-container> -->
                    </div>
                </div>
            </div>
        
            <a *ngIf="messagesType === 'vendor'" (click)="navigateToBooking(booking._id, BookingAction.serviceEdit)">
                {{(booking.details && booking.details.length > 0 ? 'messages.booking.editServices' : 'messages.booking.addService') | translate }}
            </a>
        
            <div class="cost-container">
                <div>
                  <span>{{'messages.booking.serviceCost' | translate}}</span>
                  <span>{{ (booking.price) - (booking.tax || 0) | ServiceCurrency:serviceBase}}</span>
                </div>
                <div>
                  <span>{{'messages.booking.tax' | translate}}</span>
                  <span>{{ (booking.tax || 0) | ServiceCurrency:serviceBase}}</span>
                </div>
                <div>
                    <span>{{'messages.booking.deposit' | translate}}</span>
                    <span>{{booking.deposit || 0 | ServiceCurrency:serviceBase}}</span>
                </div>
                <div *ngIf="booking.discount && booking.discount > 0">
                    <span>{{'messages.booking.discount' | translate}}</span>
                    <span>{{booking.discount || 0 | ServiceCurrency:serviceBase}}</span>
                </div>
                <div>
                    <span>{{'messages.booking.paid' | translate}}</span>
                    <span>{{booking.paid || 0 | ServiceCurrency:serviceBase}}</span>
                </div>
                <div>
                    <span>{{'messages.booking.price' | translate}}</span>
                    <span>{{booking.price || 0 | ServiceCurrency:serviceBase}}</span>
                </div>
            </div>
        
            <a *ngIf="messagesType === 'vendor'" (click)="navigateToBooking(booking._id, BookingAction.priceEdit)">
                {{ 'actions.edit' | translate }}
            </a>
        
            <div class="notes-container">
                <mat-form-field>
                    <textarea
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="5"
                        placeholder="{{'messages.booking.notesSelf' | translate}}"
                        matInput
                        [formControl]="booking.notesControl"
                    ></textarea>
                </mat-form-field>
            </div>

            <ng-container *ngIf="booking.bookingButton as bookingButton">
                <button 
                    *ngIf="bookingButton.visible"
                    [disabled]="!bookingButton.enabled"
                    (click)="navigateToBooking(booking._id, bookingButton.action)"
                    app-button
                    color="accent"
                >
                    {{ bookingButton.title | translate }}
                </button>
            </ng-container>

        </div>
    </div>
   </ng-container>
</ng-container>

<ng-template #empty>
    No data...
</ng-template>


