import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit, NgModule } from '@angular/core';

@Component({
  selector: 'jarvis-reviews-review-stars',
  templateUrl: './review-stars.component.html',
  styleUrls: ['./review-stars.component.scss']
})
export class ReviewStarsComponent implements OnInit {
  stars = [
    { index: 0 },
    { index: 1 },
    { index: 2 },
    { index: 3 },
    { index: 4 }
  ].reverse();

  @Input()
  set value(value: number) {
    if (typeof value === 'string') {
      this._value = isNaN(+value) ? 0 : Math.round(+value);
      return;
    }

    this._value = Math.round(value);
  }
  get value(): number {
    return this._value;
  }

  private _value: number;

  @Input() interactive = false;

  @Output() valueChange = new EventEmitter();

  selectRating(index: number): void {
    this.value = index + 1;
    this.valueChange.emit(this.value);
  }

  ngOnInit(): void {
  }
}


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ReviewStarsComponent],
  exports: [ReviewStarsComponent]
})

export class ReviewStarsModule { }
