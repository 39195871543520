import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { OnlyImagesPipe } from '../../pipes/mixed-content.pipe';
import { ShowActionsInlinePipe } from '../../pipes/show-actions-inline.pipe';
import { TimePassedPipe } from '../../pipes/time-passed.pipe';
import { ReadByBothPipe, ReadByUserPipe } from '../../pipes/read-by-user.pipe';
import { ShowTimePassedPipe } from '../../pipes/next-user-different.pipe';
import { BehaviorSubject } from 'rxjs';
import { CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import { MessagesChatMessagesActionsComponent } from '../../chat-messages/chat-actions/chat-actions.component';
import { UrlifyPipe } from '@jarvis/utils';
import { ImageFallbackDirective, JarivsLoadingIndicatorSimpleComponent } from '@jarvis/ui';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'chat-message',
  standalone: true,
  templateUrl: './message.component.html',
  styleUrls: [ './message.component.scss' ],
  imports: [
    CommonModule,
    TranslateModule,
    OverlayModule,
    MatIconModule,
    MatProgressSpinnerModule,
    OnlyImagesPipe,
    ShowActionsInlinePipe,
    TimePassedPipe,
    ReadByUserPipe,
    ReadByBothPipe,
    ShowTimePassedPipe,
    UrlifyPipe,
    ImageFallbackDirective,
    MessagesChatMessagesActionsComponent,
    JarivsLoadingIndicatorSimpleComponent
  ],
  providers: [
    DatePipe
  ],
  // encapsulation: ViewEncapsulation.None
})
export class ChatMessageComponent {

  @Input() currentUserId: string;
  @Input() message: any;

  // serviceBase | ServiceName:messageData.message
  @Input() serviceName = '';

  @Input() messageStyle = 'default';

  @Input() lastMessage = false;
  @Input() vendorImage = null;
  @Input() userImage = null;

  @Input() domainCountry: string;
  @Input() messagesType: string;

  @Input() showTimePassed = false;
  @Input() showVendorPhoto = false;
  @Input() autoReplyActive = false;
  @Input() readByBoth = false;
  @Input() showActionsButton = true;

  @Output() openGalleryImage = new EventEmitter<number>();
  @Output() retrySendMessage = new EventEmitter<string>();
  @Output() editMessage = new EventEmitter<void>();
  @Output() deleteMessage = new EventEmitter<void>();
  @Output() markAsUnread = new EventEmitter<void>();
  @Output() autoReplyClicked = new EventEmitter<void>();

  private messageActionsOverlayDataSource = new BehaviorSubject<any>(null);
  messageActionsOverlayData$ = this.messageActionsOverlayDataSource.asObservable();

  openGalleryImageHandler(index: number, inactive = false): void {
    if (inactive) {
      return;
    }

    this.openGalleryImage.emit(index);
  }

  retrySendMessageHandler(lastId: string): void {
    this.retrySendMessage.emit(lastId);
  }

  messageTap(event: any) {
    const target = event.target as HTMLDivElement;
    target.focus();
  }

  editMessageHandler() {
    this.editMessage.emit();
  }
  
  deleteMessageHandler() {
    this.deleteMessage.emit();
  }
  
  markAsUnreadHandler() {
    this.markAsUnread.emit();
  }

  autoReplyHandler() {
    this.autoReplyClicked.emit();
  }

  openMessageActionsOverlay(anchor: CdkOverlayOrigin, messageData: any, isLastMessage: boolean): void {
    this.messageActionsOverlayDataSource.next({
      currentUserId: this.currentUserId,
      anchor,
      messageData,
      isLastMessage
    });
  }

  closeMessageActionsOverlay(): void {
    this.messageActionsOverlayDataSource.next(null);
  }
}