<mat-sidenav-container #sidenav>
  <mat-sidenav-content>
    <main>
      <jarvis-homepage-hero-section
        [marketplaceLink]="marketplaceRouterLink"
        class="bztt-mb-20"
      ></jarvis-homepage-hero-section>

      <jarvis-categories-section></jarvis-categories-section>
      <jarvis-curated-lists></jarvis-curated-lists>
      <jarvis-homepage-listing-list></jarvis-homepage-listing-list>
      <jarvis-planner-section></jarvis-planner-section>

      <section
        class="sections-container section-fullwidth bztt-bg-white bztt-p-y-20 bztt-px-9 bztt-relative"
      >
        <img
          class="bztt-absolute -bztt-right-2 bztt-top-44 md:bztt-right-8 md:bztt-top-8 bztt-w-48 bztt-h-auto"
          src="/assets/images/landing/homepage-uplift/sections/decor-bubles.svg"
        />
        <div
          class="info-section bztt-h-full bztt-w-full bztt-flex bztt-justify-center bztt-items-center"
        >
          <div
            class="outstanding-vendors bztt-max-w-[84rem] bztt-w-full bztt-flex bztt-gap-12 bztt-flex-row bztt-justify-between bztt-items-end xl:bztt-px-9"
          >
            <div class="section-title bztt-text-start bztt-z-10">
              {{
                'landing.homepageUplift.bottomDateSelector.title.1' | translate
              }}
              <span>{{
                'landing.homepageUplift.bottomDateSelector.title.2' | translate
              }}</span
              >{{
                'landing.homepageUplift.bottomDateSelector.title.3' | translate
              }}
            </div>

            <div class="calendar-select">
              <jarvis-event-date-selector
                [position]="'location_bottom'"
                class="bztt-flex, bztt-relative"
                [marketplaceLink]="marketplaceRouterLink"
              ></jarvis-event-date-selector>
            </div>
          </div>
        </div>
      </section>

      <!-- <section
        class="bztt-max-w-[84rem] sections-container md:bztt-w-full bztt-mb-32"
      >
        <div
          class="info-section bztt-flex bztt-flex-row bztt-justify-between bztt-items-center bztt-mt-20"
        >
          <div class="section-image">
            <img
              src="/assets/images/landing/homepage-uplift/sections/users-worldwide.png"
              alt="users worldwide"
            />
          </div>

          <div
            class="description-container bztt-flex bztt-flex-col bztt-justify-center bztt-items-start bztt-w-[500px]"
          >
            <div class="section-title">
              <span>{{
                'landing.homepageUplift.usersWorldwide.title-1' | translate
              }}</span>
              {{ 'landing.homepageUplift.usersWorldwide.title-2' | translate }}
            </div>
            <div class="section-description">
              {{
                'landing.homepageUplift.usersWorldwide.description' | translate
              }}
            </div>
          </div>
        </div>
      </section> -->

      <!-- 
      <jarvis-homepage-package-section
        class="bztt-mb-32"
      ></jarvis-homepage-package-section> -->

      <!-- <section
        class="bztt-max-w-[84rem] sections-container md:bztt-w-full bztt-mb-32"
      >
        <div
          class="info-section bztt-flex bztt-flex-row bztt-justify-between bztt-items-center bztt-mt-20"
        >
          <div class="section-image">
            <img
              src="/assets/images/landing/homepage-uplift/sections/not-just-listing.png"
              alt="not just a listing"
            />
          </div>

          <div
            class="description-container bztt-flex bztt-flex-col bztt-justify-center bztt-items-start bztt-w-[440px]"
          >
            <div class="section-title">
              <span>{{
                'landing.homepageUplift.notJustListing.title1' | translate
              }}</span
              >{{ 'landing.homepageUplift.notJustListing.title2' | translate }}
            </div>
            <div class="section-description">
              {{
                'landing.homepageUplift.notJustListing.description' | translate
              }}
            </div>
          </div>
        </div>
      </section> -->

      <!-- <jarvis-homepage-reviews></jarvis-homepage-reviews> -->

      <!-- <section class="sections-container section-fullwidth bztt-px-9">
        <div
          class="bztt-w-full bztt-flex bztt-flex-col bztt-justify-between bztt-max-w-[84rem] xl:bztt-px-9"
        >
          <div
            class="bottom-section bztt-w-full bztt-flex bztt-flex-row bztt-items-center bztt-justify-between bztt-mt-24 bztt-gap-x-8"
          >
            <div class="section-title bztt-text-start bztt-max-w-xl">
              {{ 'landing.homepageUplift.areYouVendor.title1' | translate }}
              <span>
                {{ 'landing.homepageUplift.areYouVendor.title2' | translate }}
              </span>
              {{ 'landing.homepageUplift.areYouVendor.title3' | translate }}
            </div>
            <div
              class="bztt-flex bztt-flex-col bztt-justify-end bztt-items-end"
            >
              <a
                [routerLink]="(vendorSignupLink | async).link"
                [queryParams]="{ source: 'homepage-footer' }"
                class="bztt-min-w-[150px] bztt-w-[440px] bztt-max-w-[600px] bztt-no-underline"
              >
                <button
                  app-button
                  color="accent"
                  (click)="
                    handleVendorSignup('homepage-footer', true);
              
                  "
                  class="bztt-px-7 bztt-w-[440px]"
                >
                  <div class="bztt-font-normal">
                    {{ 'landing.homepage.createProfile' | translate }}
                  </div>
                </button>
              </a>
            </div>
          </div>
          <img
            class="bztt-mt-5 bztt-self-end"
            src="/assets/images/landing/homepage-uplift/sections/decor-bubles.png"
          />
        </div>
      </section> -->
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
