import { Component } from '@angular/core';
import { checkFormOrGroupInvalid } from '../../../../utils/profiler.utils';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';
import {UntypedFormControl } from '@angular/forms';

@Component({
  templateUrl: './phone.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class PhoneComponent {

  formControl:UntypedFormControl;
  
  constructor(
    private profilingFormService: JarvisProfilingFormService
  ) {
    this.formControl = this.profilingFormService.getStepControl('phone');
    
  }

  isValid() { return this.formControl.valid; } 

  next(): void {
    /*const formValid = checkFormOrGroupInvalid(this.formControl);
    if (!formValid) {
      return;
    }*/

    this.profilingFormService.trackFieldCaptureEvent('phone');    
    // this.profilingFormService.navigateToStep('source');
    this.profilingFormService.navigateToStep('website');
  }

}
