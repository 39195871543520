import { NgModule } from '@angular/core';
import { JarvisMeasurementUnitCurrencyPipe } from './measurement-unit-currenyc.pipe';

const PIPES = [
    JarvisMeasurementUnitCurrencyPipe
];

@NgModule({
    declarations: [
        ...PIPES
    ],
    exports: [
        ...PIPES
    ]
})
export class JarvisMeasurementUnitModule { }
