import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'
import { CommonDialogService } from '@jarvis/ui';
import { switchMap } from 'rxjs/operators';
import { PaymentsService } from './payments.service';

@Component({template:`<div></div>`,
providers: [PaymentsService]
})

export class StripeAuth implements OnInit {
   
   constructor (private activatedRoute: ActivatedRoute,
                private commonDialogService:CommonDialogService,
                private paymentService:PaymentsService
                ) {}

   ngOnInit () {
      const {code,state} =  this.activatedRoute.snapshot.queryParams;

       if (!(code && state))          
           this.commonDialogService.openSuccessRx("errors.errorMsg", "error");
       else {    
         const stateObj = JSON.parse(decodeURIComponent(state));
         this.paymentService.processConnectExistingAccount(code,stateObj);
      }
   }

}