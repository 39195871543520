<div
  *ngIf="!hideClose && this.emailTemplate === this.emailScreenTemplate"
  (click)="backToSocial()"
  class="bztt-flex bztt-flex-row bztt-justify-start bztt-items-center bztt-cursor-pointer bztt-mb-5 bztt-absolute bztt-top-0 bztt-left-0"
>
  <mat-icon>chevron_left</mat-icon>
  <a class="bztt-text-xs bztt-p-0 bztt-leading-3">{{
    'register.back' | translate
  }}</a>
</div>

<jarvis-ui-modal-wrapper
  (closed)="close()"
  [closeButton]="!(hideClose || standalone)"
  class="modal-wrapper medium-modal"
>
  <h1 *ngIf="!standalone && !hideTitle">
    {{
      (navigationState?.withoutRegistrationSubject
        ? 'register.registerToSend'
        : 'register.register'
      ) | translate
    }}
  </h1>

  <div class="form-wrapper">
    <ng-container [ngTemplateOutlet]="emailTemplate"></ng-container>

    <div *ngIf="!hideTerms" class="text-wrapper">
      <span>
        {{ 'register.byContinuing' | translate }} Breezit
        <a (click)="openTermsAndConditionsModal('rules')">
          {{ 'register.termsAndConditions' | translate }} </a
        >{{ 'register.and' | translate }}
        <a (click)="openTermsAndConditionsModal('privacy')">
          {{ 'register.privacyPolicy' | translate }}
        </a>
      </span>
    </div>

    <div class="text-wrapper">
      <span>{{ 'register.haveProfile' | translate }}</span
      ><a class="bztt-font-semibold" (click)="reopenLogin()">{{
        'register.loginAction' | translate
      }}</a>
    </div>
  </div>
</jarvis-ui-modal-wrapper>

<ng-template #firstScreen>
  <form
    [formGroup]="registerFormGroup"
    [class.breezit-vendor-sign-up]="userType === 'vendor'"
  >
    <mat-form-field appearance="outline">
      <mat-label>{{ 'register.yourEmail' | translate }}</mat-label>
      <input
        type="email"
        autocomplete="email"
        matInput
        formControlName="email"
      />
      <mat-error>{{ 'errors.' + (emailError$ | async) | translate }}</mat-error>
    </mat-form-field>

    <button
      class="signup1 gaEmailClicked"
      app-button
      style="margin-bottom: 15px"
      color="accent"
      (click)="continueWithEmail()"
    >
      <span>{{ 'register.continueWithEmail' | translate }}</span>
    </button>

    <div class="or">
      <span>{{ 'register.or' | translate }}</span>
      <div class="line"></div>
    </div>

    <!-- <asl-google-signin-button type='icon' size='medium'></asl-google-signin-button> -->

    <div
      class="bztt-flex bztt-flex-row bztt-justify-center bztt-gap-5 bztt-my-5"
    >
      <div
        class="gaGoogleClicked bztt-w-[72px] bztt-flex bztt-flex-col bztt-items-center bztt-gap-2 bztt-p-3 bztt-bg-[#F9F9F9] bztt-rounded-lg bztt-cursor-pointer"
        (click)="registerWithGoogle()"
        GoogleSigninButtonDirective
      >
        <mat-icon prefix>
            <svg viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                <path d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" fill="#4285f4"></path><path d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" fill="#34a853"></path><path d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z" fill="#fbbc04"></path><path d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" fill="#ea4335"></path>
            </svg>
        </mat-icon>
        <span class="bztt-text-xs">{{ 'register.google' | translate }}</span>
      </div>

      <div
        *ngIf="userType === 'user'"
        class="gaFacebookClicked bztt-w-[72px] bztt-flex bztt-flex-col bztt-items-center bztt-gap-2 bztt-p-3 bztt-bg-[#F9F9F9] bztt-rounded-lg bztt-cursor-pointer"
        (click)="registerWithFacebook()"
      >
        <img class="bztt-h-6" src="assets/icons/ic_facebook2.svg" />
        <span class="bztt-text-xs">{{ 'register.facebook' | translate }}</span>
      </div>
    </div>

    <!-- <button
      *ngIf="navigationState?.withoutRegistrationSubject"
      app-button
      type="button"
      style="margin-bottom: 15px"
      (click)="emitWithoutRegistration()"
    >
      <span>{{ 'register.withoutRegistration' | translate }}</span>
    </button> -->

    <!-- <button app-button>
      <mat-icon svgIcon="apple" prefix></mat-icon>
      <span>{{'register.continueWithApple' | translate}}</span>
    </button> -->
  </form>
</ng-template>

<ng-template #emailScreen>
  <form [formGroup]="registerFormGroup">
    <!-- <mat-form-field appearance="outline">
      <mat-label>{{ 'register.name' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ 'errors.required' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'register.surname' | translate }}</mat-label>
      <input matInput formControlName="surname" />
      <mat-error>{{ 'errors.required' | translate }}</mat-error>
    </mat-form-field> -->

    <!-- <div class="small-text light-text">
      {{ 'register.nameHint' | translate }}
    </div> -->

    <mat-form-field appearance="outline">
      <mat-label>{{ 'register.yourEmail' | translate }}</mat-label>
      <input matInput type="email" formControlName="email" />
      <mat-error>{{ 'errors.' + (emailError$ | async) | translate }}</mat-error>
    </mat-form-field>

    <!-- <mat-form-field appearance="outline">
      <mat-label>{{'register.mobilePhone' | translate}}</mat-label>
      <input matInput formControlName="mobilePhone" />
    </mat-form-field> -->

    <ng-container formGroupName="passwordGroup">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'register.password' | translate }}</mat-label>
        <input
          #passInput
          autocomplete="new-password"
          type="password"
          matInput
          formControlName="password"
        />
        <mat-icon
          class="selectable"
          matSuffix
          (click)="
            passInput.type = passInput.type === 'password' ? 'text' : 'password'
          "
        >
          {{ passInput.type === 'password' ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-error>{{
          'errors.' + (passwordError$ | async) | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'register.passwordRepeat' | translate }}</mat-label>
        <input
          #passRepeatInput
          autocomplete="new-password"
          type="password"
          matInput
          formControlName="passwordRepeat"
          [errorStateMatcher]="passwordRepeatErrorMatcher"
        />
        <mat-icon
          class="selectable"
          matSuffix
          (click)="
            passRepeatInput.type =
              passRepeatInput.type === 'password' ? 'text' : 'password'
          "
        >
          {{
            passRepeatInput.type === 'password'
              ? 'visibility_off'
              : 'visibility'
          }}
        </mat-icon>
        <mat-error *ngIf="passwordRepeatError$ | async as passwordRepeatError">
          {{ 'errors.' + passwordRepeatError | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            registerFormGroup.get('passwordGroup').hasError('passwordMatch') &&
            !(passwordRepeatError$ | async)
          "
          >{{ 'errors.passwordMatch' | translate }}</mat-error
        >
      </mat-form-field>

      <!-- <div class='form-errors' *ngIf="passwordGroup.invalid && passwordGroup.touched && (passwordGroupError$ | async) as groupError">
        <mat-error>{{ 'errors.' + groupError | translate}}</mat-error>
      </div> -->
    </ng-container>

    <div class="small-text light-text">
      {{ 'register.passwordHint' | translate }}
    </div>

    <button
      app-button
      color="accent"
      style="margin-bottom: 15px"
      class="signup2 gaAccountCreated"
      (click)="registerWithEmail()"
    >
      <span>{{ 'register.register' | translate }}</span>
      <mat-progress-spinner
        mode="indeterminate"
        diameter="24"
        suffix
        *ngIf="isLoading$ | async"
      >
      </mat-progress-spinner>
    </button>
  </form>
</ng-template>
