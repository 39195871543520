import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { JarvisUiModalWrapperComponent } from './modal-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule
    ],
    exports: [JarvisUiModalWrapperComponent],
    declarations: [JarvisUiModalWrapperComponent],
    providers: [],
})
export class JarvisUiModalWrapperModule { }
