<div
  class="bztt-w-full bztt-flex bztt-flex-row bztt-justify-end bztt-border-b bztt-border-b-slate-300 bztt-py-2"
>
  <div
    class="bztt-flex bztt-flex-row bztt-cursor-pointer bztt-mr-2"
    (click)="close()"
  >
    <span class="bztt-underline bztt-font-semibold bztt-text-sm">{{
      'actions.close' | translate
    }}</span>
    <mat-icon>clear</mat-icon>
  </div>
</div>
<div class="bztt-p-4 bztt-min-w-[350px]">
  <div
    class="bztt-flex bztt-flex-row bztt-rounded-lg bztt-h-28 bztt-shadow-md bztt-w-full"
  >
    <img
      [src]="lastLike.image"
      class="bztt-w-20 bztt-object-cover bztt-rounded-l-lg"
    />
    <div
      class="bztt-flex bztt-flex-col bztt-px-4 bztt-py-3 bztt-justify-between"
    >
      <div class="bztt-flex bztt-flex-col bztt-gap-1">
        <div class="bztt-text-sm bztt-text-[#1F1F1F]">
          {{ lastLike.type | translate }}
        </div>
        <div
          class="bztt-font-semibold bztt-overflow-hidden bztt-overflow-ellipsis bztt-text-[#1F1F1F]"
        >
          {{ lastLike.title | translate }}
        </div>
      </div>
      <div
        class="bztt-text-sm bztt-text-[#6B6060]"
        *ngIf="lastLike.indicativePrice"
      >
        {{ 'plannerPage.plannerItemAdded.startingPackage' | translate }}:
        {{ lastLike.indicativePrice }}
      </div>
    </div>
  </div>
</div>

<div
  class="bztt-w-full bztt-flex bztt-flex-row bztt-justify-end bztt-border-t bztt-border-t-slate-300 bztt-px-5 bztt-py-3"
>
  <button
    app-button
    color="accent"
    [routerLink]="[country === 'us' ? '/planner' : '/planuoklis']"
    [queryParams]="{ t: 1 }"
  >
    <div
      class="bztt-flex bztt-flex-row bztt-justify-center bztt-items-center bztt-gap-3 bztt-px-7"
    >
      {{ 'plannerPage.plannerItemAdded.viewInPlanner' | translate }}
    </div>
  </button>
</div>
