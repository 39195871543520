import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../../src/lib/layout/services/layout.service';

@Component({
  selector: 'jarvis-ui-phone-no-display',
  templateUrl: './phone-no-display.component.html',
  styleUrls: ['./phone-no-display.component.scss']
})
export class PhoneNoDisplayComponent implements OnInit {

  @Input()
  enabled = true;

  @Input()
  phoneShown = false;

  @Input()
  phoneNo = '';

  isMobile$ = this.layoutService.isMobileObserver;
  
  constructor(
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
  }

  callLink(phoneNo: string): void {
    window.location.href = `tel:${phoneNo}`;
  }

}
