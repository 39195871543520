<h2 class="bztt-font-maple-r bztt-text-2xl">{{'profiling.location.title' | translate}}</h2>

<mat-form-field class="bztt-w-full bztt-mb-6">
    <mat-label>{{ 'profiling.location.selectState' | translate }}</mat-label>
    <input 
        type="text"
        matInput
        [formControl]="formControl"
        [matAutocomplete]="stateComplete"
    />
    <mat-autocomplete [displayWith]="stateDisplayFn" #stateComplete="matAutocomplete">
        <mat-option *ngFor="let item of filteredState$ | async" [value]="item">
            <ng-container [ngSwitch]="currentCountry">
                <ng-container *ngSwitchCase="'LT'">
                {{ 'location.ltCounties.' + item | translate}}
                </ng-container>
                <ng-container *ngSwitchDefault>
                    {{ item | CamelToNormal | titlecase }}
                </ng-container>
            </ng-container>
        </mat-option>
    </mat-autocomplete>
    <mat-error>{{ 'profiling.location.pleaseSelectFromList' | translate }}</mat-error>
</mat-form-field>

<button class="md:bztt-mt-auto bztt-mb-2" [disabled]="formControl.invalid" color="accent" (click)="next()" app-button>{{'profiling.next' | translate}}</button>
<button class="bztt-mb-2" (click)="skip()" app-button>{{'profiling.location.nonUS' | translate}}</button>