import { ChatroomAutoReply } from '@jarvis/auto-reply';
import { ProfileInfo, ServiceBase } from '@jarvis/types';

export enum ChatRoomsUpdateEvent {
    markMessageRead = 'mark message read',
    newMessage = 'chat room new message',
    newChatroom = 'new chatroom'
}

export enum SingleChatRoomUpdateEvent {
    newMessage = 'private message',
    innerChatRoomRead = 'inner chatroom read',
    selfMessage = 'self message',
    selfMessageDelete = 'self message delete',
    editedMessage = 'edited message',
    deletedMessage = 'deleted message'
}

export enum MessagingEvent {
    chatRoomsUpdate = 'chatRoom update'
}

interface UserInfo {
    userType: string;
    profilePhoto: string;
    _id: string;
    email: string;
    emailVerified: boolean;
    createdAt: string;
    name?: string;
    surname?: string;
}

export interface ChatRoom {
    _id: string;
    createdAt: Date;
    updatedAt: Date;
    lastMessage: string;
    notified: any[];
    readBy: string[];
    users: UserInfo & { profile?: ProfileInfo[]};
    files?: any[];
    messages?: ChatRoomMessage[];
    serviceBase: ServiceBase;
    booking?: string;
    name?: string;
    surname?: string;
    email?:string;
    autoReply?: ChatroomAutoReply[]
}

export interface ChatRoomMessage {
    _id: string;
    createdAt: Date;
    updatedAt: Date;
    chatRoomId: string;
    lastId: string;
    message: string;
    messageHistory: any[];
    sender: string;
    type: string;
    uploading?: boolean;
    error?: boolean;
}

export type NewChatroomPayload = ChatRoom[];

// Placeholders
export interface MessageContent {
    chatRoomId: string;
    message: any;
    lastId?: string;
    type?: string;
    // message type will be text or file / file might be a separate request
}

export interface ChatroomUpdatePayload {
    message: ChatRoomsUpdateEvent;
    data: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ChatroomPayload {
    message: SingleChatRoomUpdateEvent;
    data: ChatRoomMessage;
}

export interface PaginationMetadata {
    page: number;
    total: number;
}

export interface ChatService {
  _id: string;
  brandName: string;
  count: number;
}

export interface AllChatrooms {
    metadata: PaginationMetadata;
    data: ChatRoom[];
    services: ChatService[];
}
