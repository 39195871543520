import { inject, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  ServicesService,
  hashPhotographerService,
  hashVenueCateringBeveragesService,
  hashVenueCateringService,
} from '../../services/services.service';
import { ServiceCurrencyPipe } from './currency.pipe';
import { extractTextAddressFromService } from '../../services/services.utils';
import { ReviewsUtils } from 'libs/reviews/src/lib/utils/review-utils';
import { camelCaseToKebabCase, kebabCaseToCamelCase } from '@jarvis/utils';
import { DOMAIN_COUNTRY } from '../../services/url.utils';

const NO_SUBCATEGORY_CATEGORIES = ['photographer', 'training'];

interface PriceAddition {
  hoursIncluded?: number;
  negotiablePricing: boolean;
  breezitExclusive?: {
    enabled: boolean;
    value: number;
  } | null;
}

@Pipe({
  name: 'CardData',
  pure: true,
  standalone: true,
})
export class ServiceMarketplaceCardDataPipe implements PipeTransform {

  private domainCountry = inject(DOMAIN_COUNTRY);

  constructor(
    private translateService: TranslateService,
    private currencyPipe: ServiceCurrencyPipe,
    private servicesService: ServicesService
  ) {}

  transform(
    value: any,
    eventType: string,
    dynamicParam$: Observable<any> = of([])
  ): Observable<any> {
    const serviceType = value.serviceBase.type;
    const subcategories = value.serviceBase.subcategories?.types;
    const firstSubtype = subcategories
      ? Object.keys(subcategories).find(
        (categoryType) => subcategories[categoryType] === true
      )
      : '';

    const avgRating = ReviewsUtils.averageRating(
      value?.thirdPartyReviews,
      value.reviewBase?.[0] || value.review?.[0]
    );
    const ratingCount = avgRating.count;
    const rating = avgRating.rating; // roundToTwoPrecision((value.reviewBase?.[0]?.score || value.thirdPartyReviews?.[0]?.rating) ?? null) || '-';

    let indicativePrice = value.price;
    const priceAddition: PriceAddition = {
      negotiablePricing: value.serviceBase?.advances?.canRebate,
    };

    const defaultPrice = value.defaultPrice?.description;
    const paid = value.paid ?? false;

    const isPhotographerVideographer = [
      'photographer',
      'videographer',
    ].includes(serviceType);

    const queryParams: any = {};

    if (defaultPrice && isPhotographerVideographer) {
      // disabled by request: # Photographer/videographer optimisations for US 19.06.2023
      if (value.serviceBase?.address?.country != 'US') {
        priceAddition.hoursIncluded = defaultPrice.hoursIncluded;

        if (
          defaultPrice.priceUnit === 'hour' &&
          defaultPrice.minimalBudget &&
          defaultPrice.priceValue
        ) {
          priceAddition.hoursIncluded =
            Math.round(
              (defaultPrice.minimalBudget / defaultPrice.priceValue) * 10
            ) / 10;
        }
      }
      if (!defaultPrice.additionalPriceEnabled) {
        indicativePrice = value.defaultPrice?.description?.minimalBudget
          ? value.defaultPrice?.description?.minimalBudget
          : indicativePrice;
      }
    }

    let venueInfo = null;
    if (serviceType === 'venues') {
      const hasCatering = value.venue?.venueStandaloneEnabled === false || value.serviceEvent?.[eventType]?.venueStandalone?.enabled === false || (value.cateringPrice && value.cateringPrice < value.price);
      const hasAccomodation = value.accomodationPrice;
      const hasDrinks =
        hasCatering && value.venue?.catering?.alcoholPriceIncluded;
      const guestCount =
        value.maxBanquetPeopleCount ||
        value.serviceBase.venues.maxBanquetPeopleCount;
      const filteredGuestCount = null;
      // indicativePrice = value.cateringPrice ? Math.min(value.cateringPrice, value.price) : value.price;

      const cateringProvided = value.serviceBase?.kitchen?.enabled;
      // Edge case from backend - if we apply beverages cateringType filter, response does not have cateringBeverages object. Handled in info new component
      const beveragesProvided = value.venue?.cateringBeverages?.enabled || value.serviceEvent?.[eventType]?.cateringBeverages?.enabled;

      const enoughSeats = value.serviceBase?.inventory?.enoughSeatingForMaxPeople;
      const maxSeatCount = value.serviceBase?.inventory?.seatingCount ? +value.serviceBase?.inventory?.seatingCount : 0;

      venueInfo = {
        hasCatering,
        hasAccomodation,
        hasDrinks,
        guestCount,
        filteredGuestCount,
        cateringProvided,
        beveragesProvided,
        enoughSeats,
        maxSeatCount,
        seatsOverCapacity: false
      };
    }

    const cardAddress = extractTextAddressFromService(
      value.serviceBase,
      this.translateService
    );

    return dynamicParam$.pipe(
      map((dynamicParams) => {
        const dateParam = dynamicParams.find(
          (param) => param.type === 'dateFrom'
        );
        const personCount = dynamicParams.find(
          (param) => param.type === 'personCount'
        );
        const budget = dynamicParams.find(
          (param) => param.type === 'budgetList'
        );
        const subType = dynamicParams.find((param) => param.type === 'subtype');
        const defaultSlugCheck = !!dynamicParams.find(
          (param) => param.defaultSlugCheck
        );

        const cateringType = dynamicParams.find(
          (param) => param.type === 'cateringType'
        );
        const cateringTypeActive =
          cateringType?.value?.includes('buffet') ||
          cateringType?.value?.includes('banquet') ||
          cateringType?.value?.includes('catering') ||
          cateringType?.value?.includes('beverages');

        const cateringTypeBringOwn = cateringType?.value?.includes('bringMyOwn');

        const fromRecommended = dynamicParams.find((param) => param.type === 'fromRecommended');

        if (fromRecommended) {
          queryParams._recs = fromRecommended.value;
        }

        const hourFilterActive =
          !!dynamicParams.find((param) => param.type === 'personCount')
            ?.value && isPhotographerVideographer;

        const priceModifingFiltersActive = cateringTypeActive || cateringTypeBringOwn || personCount?.value || dateParam?.value || budget?.value;

        if (serviceType === 'venues' && value.serviceBase?.address?.country === 'US') {

          if (!priceModifingFiltersActive) {
            indicativePrice = value.venue?.basePrice50 || value.price;
          }

          /* if (value.venue?.basePrice50 || (priceModifingFiltersActive && !personCount?.value)) {
            queryParams.personCount = 50;
          } */
        }

        if (value.cateringPrice && cateringTypeActive) {
          venueInfo.hasCatering = true;
          indicativePrice = value.cateringPrice;
        } 
        
        if (value.cateringPrice && cateringType?.value?.includes('beverages')) {
          venueInfo.hasBeverages = true;
          indicativePrice = value.cateringPrice;
        }

        if (hourFilterActive) {
          // Change slug to select photographer service
          const hash = hashPhotographerService(
            value.defaultPrice?.description?.priceUnit,
            value.defaultPrice?.description?.minimalBudget,
            value.defaultPrice?.description?.hoursIncluded
          );

          // slug += `?service=${hash}`;
          queryParams.service = hash;
        }

        const subTypeFromFilter =
          serviceType !== 'venues' &&
          subType?.value !== 'main' &&
          subType?.value;
        const subTypeParsed = NO_SUBCATEGORY_CATEGORIES.includes(serviceType)
          ? null
          : subTypeFromFilter || firstSubtype;

        if (personCount && personCount.value && venueInfo) {
          venueInfo.filteredGuestCount = personCount.value;

          venueInfo.seatsOverCapacity = venueInfo.enoughSeats === false && venueInfo.maxSeatCount > 0 && ( (personCount.value || 0) > venueInfo.maxSeatCount );
        }

        if (
          personCount &&
          personCount.value &&
          isPhotographerVideographer &&
          value.serviceBase?.address?.country != 'US'
        ) {
          priceAddition.hoursIncluded = personCount.value;
        }

        const brandName = this.servicesService.extractServiceEventName(
          value.serviceBase,
          value._id
        );
        const breezitExclusivePrice = value.extra?.breezitExclusive ?? null;

        let slugType = eventType;

        if (defaultSlugCheck && value.serviceEvent?.enabledTypes) {
          const enabledTypes = Object.entries(
            value.serviceEvent.enabledTypes
          ).filter(
            ({ 0: eventTypeInner, 1: typeValue }: any) =>
              typeValue && value?.serviceEvent[eventTypeInner]?.completed
          );

          slugType = enabledTypes.length
            ? camelCaseToKebabCase(enabledTypes[0][0])
            : 'wedding';
        }

        const slug =
          `${value.slug || value._id}` +
          (value.customPricing ? `/${slugType}` : '');

        let priceTitle = null;
        let guestCountTitle = null;

        if (
          serviceType === 'photographer' &&
          personCount &&
          personCount.value
        ) {
          priceTitle = 'priceTitlePackage';
        }

        if (serviceType === 'venues') {
          const priceChangingFilterActive =
            (personCount && personCount.value) ||
            (dateParam && dateParam.value);
          const quantityFilterActive = personCount && personCount.value;
          const priceWithCatering =
            venueInfo?.hasCatering ||
            venueInfo?.hasDrinks ||
            venueInfo?.hasAccomodation;

          if (venueInfo?.hasCatering && !venueInfo?.hasDrinks) {
            // Change slug to select catering service
            const hashedService = hashVenueCateringService();
            // slug += `?service=${hashedService}`;
            queryParams.service = hashedService;
          }

          if (venueInfo?.hasBeverages) {
            // Change slug to select catering service
            const hashedService = hashVenueCateringBeveragesService();
            // slug += `?service=${hashedService}`;
            queryParams.service = hashedService;
          }

          if (this.domainCountry === 'lt') {
            if (priceWithCatering && priceChangingFilterActive) {
              priceTitle = 'priceTitleSpecificWith';
            } else if (priceChangingFilterActive) {
              priceTitle = 'priceTitleSpecific';
            } else if (priceWithCatering) {
              priceTitle = 'priceTitleWith';
            }
          }

          if (this.domainCountry === 'us') {
            if (priceChangingFilterActive) {
              priceTitle = 'priceTitleSpecific';
            }
          }

          if (quantityFilterActive) {
            guestCountTitle = 'guestCountSpecific';
          }
        }

        const formattedIndicativePrice = this.currencyPipe.transform(
          indicativePrice,
          value.serviceBase,
          '1.0-0'
        );

        let indicativePriceFinal = formattedIndicativePrice;

        /* if (
          defaultPrice &&
          defaultPrice.priceUnit === AdvertsPriceUnits.currencyPerPerson &&
          defaultPrice.minimalBudget === defaultPrice.priceValue
        ) {
          const translatedString = this.translateService.instant('listing.cart.perPerson');
          indicativePriceSuffix = ` ${translatedString}`;
        } */

        const pricePerHourCategory = [
          'photographer',
          'videographer',
          // 'dj'
        ].includes(serviceType);

        const hasRelevantCountFilters = !!dateParam?.value || !!personCount?.value || !!budget?.value;

        if (pricePerHourCategory && value.serviceBase?.address?.country === 'LT' && (priceAddition.hoursIncluded || defaultPrice?.priceUnit === 'hour')) {
          priceTitle = 'priceTitleSpecific';
          if (!hasRelevantCountFilters) {
            const priceForHour = priceAddition.hoursIncluded ? indicativePrice / priceAddition.hoursIncluded :  defaultPrice.priceValue;
            const formattedPriceForHour = this.currencyPipe.transform(
              priceForHour,
              value.serviceBase,
              '1.0-0'
            );
            indicativePriceFinal = `${formattedPriceForHour} / val.`;
            if (priceAddition.hoursIncluded) {
              delete priceAddition.hoursIncluded;
            }
          }
        }

        return {
          _id: value._id,
          type: subTypeParsed
            ? `subcategories.${serviceType}.${subTypeParsed}`
            : 'categories.' + serviceType,
          address: cardAddress,
          title: brandName,
          paid,
          rating: `${rating}`,
          ratingCount: `${ratingCount}`,
          indicativePrice: indicativePriceFinal,
          priceUnit:
            value.defaultPrice?.description?.priceUnit ||
            value?.venue?.catering?.priceUnit ||
            value.innerPrices?.service?.priceUnit ||
            '',
          slug,
          queryParams,
          instagramLink: value.serviceBase?.contacts?.instagramLink,
          venueInfo,
          eventType,
          eventTypeCamel: kebabCaseToCamelCase(eventType),
          category: serviceType,
          priceAddition: {
            ...priceAddition,
            breezitExclusive: breezitExclusivePrice,
          },
          serviceBase: value.serviceBase,
          serviceEvent: value,
          priceTitle,
          guestCountTitle
        };
      })
    );
  }
}

function roundToTwoPrecision(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
