import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot  } from '@angular/router';
import { map } from 'rxjs/operators';
import { JarvisAuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class JarvisBackAfterLoginGuard implements CanActivate {
  protected url = '/auth/login';
  constructor(private authService: JarvisAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isLoggedIn$.pipe(
      map((loggedIn) => {
        if (loggedIn) {
          return true;
        }

        this.router.navigateByUrl(this.url);

        this.authService.createRegisterOrLoginSuccessStream().subscribe(() => {
          this.router.navigateByUrl(state.url);
        });

        return false;
      })
    );
  }
}

