import { Pipe, PipeTransform } from '@angular/core';
import { ServiceBase } from '../../../../../types/src/lib/service-base.types';

@Pipe({
  name: 'ServiceCountryKey',
  standalone: true
})
export class ServiceCountryKeyPipe implements PipeTransform {
  constructor() {}

  transform(
    value: string,
    serviceBaseOrCountry?: ServiceBase | string
  ): string {
    const country =
      typeof serviceBaseOrCountry === 'string'
        ? serviceBaseOrCountry
        : serviceBaseOrCountry.address?.country || 'US';
    return value + (country==='US'?'':country);
  }
}
