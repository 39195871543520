import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  inject,
} from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { getHours, getMinutes } from 'date-fns';
import { JarvisMeasurementUnitService } from 'libs/services/src';
import { JarvisLanguageService } from 'libs/services/src';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
@Component({
  selector: 'jarvis-ui-time-picker-content',
  templateUrl: 'time-picker-content.component.html',
  styleUrls: ['./time-picker-content.component.scss'],
})
export class JarvisUiTimePickerContentComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  country = inject(DOMAIN_COUNTRY);

  @Output() timeSelection = new EventEmitter();
  @Input() selectedValue = null;
  @Input() dateEvents = [];
  @Input() allowTimeBefore = 1441;
  @Input() allowTimeAfter = -1;
  @Input() currentDate: Date;

  interval = 30;
  timeValues = new BehaviorSubject<number[]>([]);
  currentLocale$;

  destroy$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private languageService: JarvisLanguageService,
    @Optional() private measurementUnitService: JarvisMeasurementUnitService
  ) {}

  ngOnInit() {
    // if (this.measurementUnitService) {
    //     this.currentLocale$ = this.measurementUnitService.activeLocale$;
    // } else {
    //     this.currentLocale$ = this.languageService.currentLanguage$.pipe(takeUntil(this.destroy$), switchMap(v=>of(this.languageService.getLocaleByLanguage)));
    // }
    this.currentLocale$ = of(this.country === 'lt' ? 'lt-LT' : 'en-US');
    this.updateTimeValues();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.scrollToActive();
  }

  updateTimeValues(): void {
    const maxTimeValue = 1440;
    const valueCount = maxTimeValue / this.interval;
    const newTimeValues: number[] = [];
    for (let i = 0; i < valueCount; i++)   
      {
        const value = i * this.interval;
        if (value>this.allowTimeAfter && value<this.allowTimeBefore)
        newTimeValues.push(value);
      }
    this.timeValues.next(newTimeValues);
  }

  timeSelect(timeValue: number): void {
    this.selectedValue = timeValue;
    this.timeSelection.emit(timeValue);
  }

  setTime(date: Date) {
    this.selectedValue = getHours(date) * 60 + getMinutes(date);
  }

  scrollToActive(): void {
    const el = this.elementRef.nativeElement as HTMLElement;
    const scrollElement = el.children[0] as HTMLElement;
    const selectedItem = el.getElementsByClassName('selected');

    const middayIndex = (60 / this.interval) * 12;
    const scrollToItem =
      selectedItem[0] || el.children[0].children[middayIndex];
    if (scrollToItem) {
      const containerPadding = 32;
      const containerCenterY = el.offsetHeight / 2 + containerPadding;
      const elementOffset = (scrollToItem as HTMLElement).offsetTop;
      scrollElement.scrollTo({
        top: elementOffset - containerCenterY,
      });
    }
  }
}
