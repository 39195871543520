import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'CardListingType',
  pure: true,
  standalone: true
})
export class CardListingTypePipe implements PipeTransform {

  private translate = inject(TranslateService);

  transform(data): any {
    const typeTranslations = {
      eventNonTranslated: data.type + '.' + data.eventTypeCamel,
      withEvent: this.translate.instant(data.type + '.' + data.eventTypeCamel),
      default: this.translate.instant(data.type)
    }

    return typeTranslations.withEvent !== typeTranslations.eventNonTranslated
      ? typeTranslations.withEvent
      : typeTranslations.default;
  }
} 