import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class JarvisValidators {
  static fullEmailValidator(formControl: UntypedFormControl): {
    [key: string]: boolean;
  } {
    const { value } = formControl;

    if (typeof value !== 'string' || value.length === 0) {
      return null;
    }

    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const matched = emailRegex.test(value);

    if (matched) {
      return null;
    }

    return {
      email: true,
    };
  }

  static repeatPasswordValidation(
    formGroup: UntypedFormGroup
  ): { [key: string]: boolean } | null {
    const { controls } = formGroup;

    if (!controls) {
      return null;
    }

    const { password, passwordRepeat } = formGroup.value;
    if (password === passwordRepeat) {
      return null;
    }

    return { passwordMatch: true };
  }

  static samePassword(
    formGroup: UntypedFormGroup
  ): { [key: string]: boolean } | null {
    const { controls } = formGroup;

    if (!controls) {
      return null;
    }

    const { passwordRepeat } = formGroup.value;
    const parentGroup = formGroup.parent;

    if (!parentGroup) {
      return null;
    }

    const currentPassword = parentGroup.get('currentPassword').value;
    if (currentPassword !== passwordRepeat) {
      return null;
    }

    return { samePassword: true };
  }

  static passwordComplexityValidation(
    formControl: UntypedFormControl
  ): { [key: string]: boolean } | null {
    const { value } = formControl;

    if (typeof value !== 'string' || value.length === 0) {
      return null;
    }

    // Rules: One Capital letter, One Digit, Length between 8 and 40 symbols
    const hasCapitalLetter = /\p{Lu}/u.test(value);
    const hasDigit = /.*\d/u.test(value);
    const hasCorrectLength = /^.{8,40}$/u.test(value);

    if (hasCapitalLetter && hasDigit && hasCorrectLength) {
      return null;
    }

    return {
      noCapital: !hasCapitalLetter,
      noDigit: !hasDigit,
      noLength: !hasCorrectLength,
    };
  }

  static phone(control: AbstractControl): ValidationErrors | null {
    const countryCode = '370';
    const regexStr = `.?(${countryCode})\\s?\\d{3}\\s?\\d{5}`;
    const phoneRegex = new RegExp(regexStr);

    const valueToCheck = control.value as string;

    if (!valueToCheck) {
      return null;
    }

    const result = valueToCheck.match(phoneRegex);

    if (!result || result[0] !== control.value) {
      return {
        phoneInvalid: true,
      };
    }
    return null;
  }

  static hyperlink(
    domain?: string
  ): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValid = true;

      if (control.value === '' || control.value == null) {
        return null;
      }

      let hostNameValid = true;

      try {
        const parsedUrl = new URL(control.value);
        if (domain) {
          hostNameValid =
            parsedUrl.hostname.replace('www.', '').split('.')[0] === domain;
        }
      } catch {
        isValid = false;
      }

      if (!isValid) {
        return {
          hyperlinkInvalid: true,
        };
      }

      if (!hostNameValid) {
        return {
          hyperlinkDomainInvalid: true,
        };
      }

      return null;
    };
  }

  static conditionalPercentage(
    formControl: AbstractControl
  ): ValidationErrors | null {
    if (!formControl.parent) {
      return null;
    }

    const dependantControl = formControl.parent.get('priceUnit');

    if (dependantControl && dependantControl.value === 'percentage') {
      // return Validators.required(formControl);
      const numerical = parseInt(formControl.value, 10);
      if (numerical < 0 || numerical > 100) {
        return {
          percentage: true,
        };
      }
    }

    return null;
  }

  static generateErrorStateMatcher(
    formGroup: UntypedFormGroup,
    controlPath: string
  ): Observable<string> {
    const control = controlPath === '' ? formGroup : formGroup.get(controlPath);

    if (!control) {
      return EMPTY;
    }

    return control.statusChanges.pipe(
      map((status) => {
        if (!control.touched || !control.errors || status !== 'INVALID') {
          return null;
        }

        for (const errName in control.errors) {
          if (Object.prototype.hasOwnProperty.call(control.errors, errName)) {
            if (control.errors[errName]) {
              return errName;
            }
          }
        }
        return null;
      })
    );
  }

  static dateLessThan(
    dateField1: string,
    dateField2: string,
    errorFields: [key: string]
  ): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const date1 = c.get(dateField1).value;
      const date2 = c.get(dateField2).value;
      if (date1 !== null && date2 !== null) {
        const result = date1 > date2 ? { dateLessThan: true } : null;
        errorFields.forEach((item) => {
          c.get(item).setErrors(result);
        });
        return result;
      }
      return null;
    };
  }

  static dateLessOrEqualsThan(
    dateField1: string,
    dateField2: string,
    errorFields: [key: string]
  ): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const date1 = c.get(dateField1).value;
      const date2 = c.get(dateField2).value;
      if (date1 !== null && date2 !== null) {
        const result = date1 >= date2 ? { dateLessThan: true } : null;
        errorFields.forEach((item) => {
          c.get(item).setErrors(result);
        });
        return result;
      }
      return null;
    };
  }

  // static noWhiteSpaceAtStart = Validators.pattern(/^[^\s]+[-a-zA-Z\s]+([-a-zA-Z]+)*$/);
  static noWhiteSpaceAtStart = Validators.pattern(/^[^\s]+[\p{L}]+$/u);
}

export class PasswordRepeatErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null
  ): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(
      control?.parent?.invalid && control?.parent?.dirty
    );

    return control.touched && (invalidCtrl || invalidParent);
  }
}
