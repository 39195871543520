import { JarvisServiceLikesService } from '@jarvis/services/likes';
import { LocalLike } from './../../../../likes/src/services/likes.service';
import { switchMap, debounceTime, take, tap } from 'rxjs/operators';
import { PlannerCardDataPipe } from './../pipes/planner-card-data.pipe';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { JarvisAuthService } from '@jarvis/auth';
import {
  BASE_URL,
  DOMAIN_COUNTRY,
  JarvisTrackingService,
  ServiceCurrencyPipe,
} from '@jarvis/services';
import { BehaviorSubject, of, Subject, takeUntil, map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { kebabCaseToSnakeCase } from '@jarvis/utils';


export enum PLANNER_TABS {
  WISHLIST = 1,
  BUDGET,
  DETAILS,
}

export const CATEGORY_COLORS = {
  venues: '#F87171',
  catering: '#FB6A3C',
  ceremony_venue: '#F9B7E6',
  ceremony_regale: '#FBBF24',
  bartenders: '#FBE624',
  foodtruck: '#FFF389',
  videographer: '#F7FB24',
  photographer: '#CCFA15',
  live_music: '#A3E635',
  planning: '#7AFA15',
  host: '#15FA2C',
  cakes_desserts: '#4ADE80',
  decor_elements: '#6EE7B7',
  makeup_hairdressing: '#34D399',
  dj: '#2DD4BF',
  bride_clothing: '#15FAD1',
  groom_clothing: '#22EEEE',
  secondary_dress: '#A3EDF9',
  transport: '#92E5FF',
  decor: '#38D5F8',
  invitations_printing: '#38BDF8',
  decor_floristics: '#60A5FA',
  pavilion_furniture: '#818CF8',
  sound_lights: '#A78BFA',
  screens_projectors: '#C084FC',
  show: '#E879F9',
  training: '#F9B7E6',
  entertainment: '#F979D5',
  jewelery: '#F472B6',
  presents: '#FD5487',
  honeymoon: '#FB7185',
  photo_booth: '#F9B7B7',
  ceremony_host: '#FBBF24',
  visual_entertainment: '#FFF389',
};

export interface PlannerResponse {
  plannerBase: PlannerBase;
  plannerItems: PlannerItem[];
  loggedIn?: boolean;
}
export interface PlannerBase {
  budget: number;
  createdAt: string;
  eventDate: string;
  guestCount: number;
  eventType: string;
  location: string;
  updatedAt: string;
  concept: Concept;
  user: string;
  _id: string;
}

export interface Concept {
  title: string;
  subtitle: string;
  description: string;
}

export interface CustomVendor {
  url: string;
  vendorName: string;
  contactName: string;
  budget: number;
  email: string;
  phoneNumber: string;
  location: string;
  category: string;
}

export interface PlannerItem {
  id: string;
}

@Injectable({ providedIn: 'root' })
export class PlannerService {
  // 644a7b58a2cadddf6b4298a4 Luko planner ID

  private _sharedPlannerId: string | null = null;

  get sharedPlannerId() {
    return this._sharedPlannerId;
  }

  PLANNER_LOCAL_STORAGE_KEY = 'LOCAL_PLANNER';
  LIKES_STORAGE_KEY = 'liked_services';

  plannerForm: FormGroup;

  get activeTab(): number {
    const tab = this.activatedRoute.snapshot.queryParams['t'];
    return tab || PLANNER_TABS.WISHLIST;
  }

  activePlanerTab = new BehaviorSubject(this.activeTab);
  refreshPlanner = new Subject<any>();
  refreshWishlist = new Subject<void>();

  budget;
  likesCount = 0;
  shepherdInit = new Subject<void>();

  loggedIn: boolean = false;

  destroy$ = new Subject<void>();

  private cardDataPipe = inject(PlannerCardDataPipe);
  private currencyPipe = inject(ServiceCurrencyPipe);
  private country = inject(DOMAIN_COUNTRY);
  private trackService = inject(JarvisTrackingService);

  get totalBudgetText() {
    return this.budget
      ? `${this.currencyPipe.transform(
          this.budget?.total,
          null,
          '1.0-0',
          this.country.toUpperCase()
        )}`
      : '';
  }

  get hideInfoBar() {
    if (!this.plannerForm) return false;

    const data = this.plannerForm?.value?.plannerBase;
    return data.eventDate && data.location && data.guestCount;
  }

  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    private authService: JarvisAuthService,
    private httpService: HttpClient,
    private fb: FormBuilder,
    private likeService: JarvisServiceLikesService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.initPlannerData();
  }

  setSharedPlannerId(id: string | null) {
    this._sharedPlannerId = id;
  }

  initPlannerData() {
    this.refreshPlanner
      .pipe(
        // startWith(true),
        debounceTime(300),
        takeUntil(this.destroy$),
        switchMap((refreshWishlist) => this.getPlannerBase(refreshWishlist))
      )
      .subscribe();

    this.likeService.lastLikeSubject
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((like) => {
        this.addFirstPlannerItem(like);
      });

    this.authService.isLoggedIn$
      .pipe(
        tap((loggedIn) => (this.loggedIn = loggedIn)),
        debounceTime(400),
        takeUntil(this.destroy$)
      )
      .subscribe((loggedIn) => {
        // this.setSharedPlannerId(null);
        this.refreshPlanner.next(true);
      });
  }

  addFirstPlannerItem(like: any) {
    const category = like.serviceBase?.type || like.category;
    const plannerItems = this.plannerForm.value.plannerItems;
    const foundByCategory = plannerItems.find(
      (item: any) =>
        item.serviceBase?.type === category ||
        item?.cardData?.category === category
    );
    if (foundByCategory) return;
    this.addPlannerBaseItem(like._id, {
      note: '',
      eventType: like.eventType,
    }).subscribe((res) => {
      this.refreshPlanner.next(true);
    });
  }

  getPlannerBase(refreshWishlist = true) {
    return this.authService.isLoggedIn$.pipe(
      take(1),
      switchMap((loggedIn) =>
        this.getPlannerBaseData(loggedIn).pipe(
          map(
            (data) => Object.assign(data || {}, { loggedIn }) as PlannerResponse
          )
        )
      ),
      tap((plannerBase: PlannerResponse) => {
        if (plannerBase.loggedIn || this.sharedPlannerId) {
          if (plannerBase.plannerItems[0]) {
            plannerBase.plannerItems[0]['items'] = this.mapPlannerItems(
              plannerBase.plannerItems[0]?.['items'] || [],
              plannerBase.plannerBase.eventDate || new Date(),
              plannerBase.plannerBase.guestCount
            );
            this.budget = this.generatePlannerBudget(
              plannerBase.plannerItems[0]['items']
            );
          }

          this.plannerForm = this.generatePlannerForm(plannerBase);
          this.initFormTracking();

          if (refreshWishlist) this.refreshWishlist.next();
          return;
        }

        if (refreshWishlist) this.refreshWishlist.next();
        this.plannerForm = this.generatePlannerForm(plannerBase);
        this.budget = this.generatePlannerBudget(plannerBase.plannerItems);
        this.initFormTracking();

        this.plannerForm.valueChanges
          .pipe(debounceTime(500), takeUntil(this.destroy$))
          .subscribe((value) => {
            value.plannerItems = value.plannerItems.map((item) => {
              return { eventId: item._id, note: item.note };
            });

            localStorage.setItem(
              this.PLANNER_LOCAL_STORAGE_KEY,
              JSON.stringify(value)
            );
          });
      })
    );
  }

  trackFilterChange(param, value) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: 'planner_filter_change',
      data: {
        param,
        value,
      },
    });
  }

  initFormTracking() {
    this.plannerForm
      .get('plannerBase')
      .get('eventDate')
      .valueChanges.pipe(debounceTime(700))
      .subscribe((value) => {
        this.trackFilterChange('eventDate', value);
        this.updatePlannerBaseData({ eventDate: value }).subscribe(() =>
          this.refreshPlanner.next(false)
        );
      });
    this.plannerForm
      .get('plannerBase')
      .get('location')
      .valueChanges.pipe(debounceTime(700))
      .subscribe((value) => {
        this.trackFilterChange('location', value);
        this.updatePlannerBaseData({ location: value }).subscribe();
      });
    this.plannerForm
      .get('plannerBase')
      .get('budget')
      .valueChanges.pipe(debounceTime(700))
      .subscribe((value) => {
        this.trackFilterChange('budget', value);
        this.updatePlannerBaseData({ budget: value }).subscribe();
      });
    this.plannerForm
      .get('plannerBase')
      .get('guestCount')
      .valueChanges.pipe(debounceTime(700))
      .subscribe((value) => {
        this.trackFilterChange('guestCount', value);
        this.updatePlannerBaseData({ guestCount: value }).subscribe(() =>
          this.refreshPlanner.next(false)
        );
      });
    this.plannerForm
      .get('plannerBase')
      .get('eventType')
      .valueChanges.pipe(debounceTime(700))
      .subscribe((value) => {
        this.trackFilterChange('eventType', value);
        this.updatePlannerBaseData({ eventType: value }).subscribe();
      });
  }

  mapPlannerItems(plannerItems, date, guests) {
    if (plannerItems.length === 0) return [];
    return plannerItems
      .filter(
        (item) =>
          (item.serviceBase && item.serviceEvent) || item.customServiceEvent
      )
      .map((item) => {
        if (item.customServiceEvent) {
          item.customServiceEvent =
            item.customServiceEvent.customServiceEvent ||
            item.customServiceEvent;
        }
        const cardData = this.cardDataPipe.transform(
          item,
          item.eventType || 'general',
          date,
          guests
        );
        return { ...item, cardData };
      })
      .sort((a, b) => {
        if (
          a.cardData.category === 'venues' ||
          b.cardData.category === 'venues'
        ) {
          if (a.cardData.category === b.cardData.category) {
            return b.cardData.priceNumber - a.cardData.priceNumber;
          } else {
            return a.cardData.category === 'venues' ? -1 : 1;
          }
        } else {
          return b.cardData.priceNumber - a.cardData.priceNumber;
        }
      });
  }

  generatePlannerBudget(plannerItems: any[]) {
    if (!plannerItems || plannerItems.length === 0) return null;

    const itemsByCategory = plannerItems.reduce((acc, item) => {
      if (item.customServiceEvent) {
        if (!acc[item.customServiceEvent?.category || item.category]) {
          acc[item.customServiceEvent?.category || item.category] = [];
        }
        acc[item.customServiceEvent?.category || item.category].push(
          item.customServiceEvent.budget || item.budget
        );
      }

      if (
        !item.customServiceEvent &&
        !isNaN(item.cardData.booking?.price || item.cardData.priceNumber)
      ) {
        if (!acc[item.cardData.category]) {
          acc[item.cardData.category] = [];
        }
        acc[item.cardData.category].push(
          item.cardData.booking
            ? item.cardData.booking.price
            : item.cardData.priceNumber
        );
      }
      return acc;
    }, {});

    const totaPrice = Object.keys(itemsByCategory).reduce((acc, category) => {
      const categoryTotal = itemsByCategory[category].reduce(
        (acc, price) => acc + price,
        0
      );
      return acc + categoryTotal;
    }, 0);

    const categoriesWithPercentage = Object.keys(itemsByCategory).reduce(
      (acc, category) => {
        const categoryTotal = itemsByCategory[category].reduce(
          (acc, price) => acc + price,
          0
        );
        const percentage = Math.round((categoryTotal / totaPrice) * 100);
        const color =
          CATEGORY_COLORS[kebabCaseToSnakeCase(category)] || '#FBBF24';

        acc[category] = { percentage, categoryTotal, color };
        return acc;
      },
      {}
    );

    const sorted = Object.fromEntries(
      Object.entries(categoriesWithPercentage).sort((a: any, b: any) => {
        if (a[0] === 'venues' || b[0] === 'venues') {
          return a[0] === 'venues' ? -1 : 1;
        } else {
          return b[1].categoryTotal - a[1].categoryTotal;
        }
      })
    );

    if (!totaPrice) return null;
    return {
      categories: sorted,
      total: Math.round(totaPrice),
    };
  }

  private generatePlannerForm(planner?: PlannerResponse) {
    return this.fb.group({
      plannerBase: this.generatePlannerBaseForm(planner?.plannerBase),
      plannerItems: [
        (planner?.loggedIn || this.sharedPlannerId
          ? planner?.plannerItems?.[0]?.['items']
          : planner?.plannerItems) || [],
      ],
    });
  }

  private generatePlannerBaseForm(plannerBase?: PlannerBase) {
    return this.fb.group({
      _id: plannerBase && plannerBase._id,
      concept: this.generateConceptForm(plannerBase && plannerBase.concept),
      eventDate: plannerBase && plannerBase.eventDate,
      location: plannerBase && plannerBase.location,
      budget: plannerBase && plannerBase.budget,
      guestCount: plannerBase && plannerBase.guestCount,
      eventType: (plannerBase && plannerBase.eventType) || 'wedding',
      user: plannerBase && plannerBase.user,
    });
  }

  private generateConceptForm(concept: Concept) {
    return this.fb.group({
      title: concept && concept.title,
      subtitle: concept && concept.subtitle,
      description: concept && concept.description,
    });
  }

  getLikedServiceEvent() {
    return this.httpService.get(`${this.baseUrl}/marketplace/likes`);
  }

  setActiveTab(tab: PLANNER_TABS) {
    this.activePlanerTab.next(tab);
  }

  handleWishlistNoteChange(id, comment) {
    return this.httpService.patch(`${this.baseUrl}/marketplace/likes/${id}`, {
      comment,
    });
  }

  getPlannerBaseData(loggedIn) {
    if (this.sharedPlannerId)
      return this.getPlannerBaseDataById(this.sharedPlannerId);
    if (loggedIn)
      return this.httpService.post(
        `${this.baseUrl}/marketplace/planner/plannerBase?limit=100`,
        {}
      );

    const localPlannerBase = JSON.parse(
      localStorage.getItem(this.PLANNER_LOCAL_STORAGE_KEY)
    );

    if (!localPlannerBase)
      localStorage.setItem(
        this.PLANNER_LOCAL_STORAGE_KEY,
        JSON.stringify(this.generatePlannerForm().getRawValue())
      );

    if (!localPlannerBase || localPlannerBase?.plannerItems.length === 0)
      return of(localPlannerBase);

    return this.httpService
      .post(`${this.baseUrl}/marketplace/likes/byids`, {
        ids: localPlannerBase.plannerItems
          .filter((item) => !item?.customServiceEvent)
          .map((item) => item.eventId),
      })
      .pipe(
        map((plannerItems: any[]) => {
          plannerItems.forEach((likeService) => {
            const localPlannerItem = localPlannerBase.plannerItems.find(
              (item: LocalLike) => item.eventId === likeService._id
            );
            likeService['localEvent'] = true;
            Object.assign(likeService, localPlannerItem);
            likeService.cardData = this.cardDataPipe.transform(
              likeService,
              'general',
              localPlannerBase.plannerBase.eventDate || new Date(),
              localPlannerBase.plannerBase.guestCount
            );
          });

          localPlannerBase.plannerItems = [
            ...plannerItems,
            ...localPlannerBase.plannerItems
              .filter((item) => item.customServiceEvent)
              .map((item) => {
                item.cardData = this.cardDataPipe.transform(
                  item,
                  'general',
                  localPlannerBase.plannerBase.eventDate || new Date(),
                  localPlannerBase.plannerBase.guestCount
                );
                return item;
              }),
          ].sort((a, b) => {
            if (
              a.cardData.category === 'venues' ||
              b.cardData.category === 'venues'
            ) {
              if (a.cardData.category === b.cardData.category) {
                return b.cardData.priceNumber - a.cardData.priceNumber;
              } else {
                return a.cardData.category === 'venues' ? -1 : 1;
              }
            } else {
              return b.cardData.priceNumber - a.cardData.priceNumber;
            }
          });

          return localPlannerBase;
        })
      );
  }

  getPlannerBaseDataById(id) {
    return this.httpService.get(
      `${this.baseUrl}/marketplace/planner/plannerBase/${id}?limit=100`,
      {}
    );
  }

  updatePlannerBaseData(data) {
    return this.authService.isLoggedIn$.pipe(
      take(1),
      switchMap((loggedIn) => {
        if (loggedIn)
          return this.httpService.patch(
            `${this.baseUrl}/marketplace/planner/plannerBase`,
            data
          );
        return of(null);
      })
    );
  }

  addPlannerBaseItem(serviceEvent: number, data?: any) {
    return this.authService.isLoggedIn$.pipe(
      take(1),
      switchMap((loggedIn) => {
        if (loggedIn) {
          if (serviceEvent)
            return this.httpService.post(
              `${this.baseUrl}/marketplace/planner/plannerItem`,
              { serviceEvent, eventType: data?.eventType }
            );
          if (!serviceEvent)
            return this.httpService.post(
              `${this.baseUrl}/marketplace/planner/plannerItemCustom`,
              { likeId: data._id }
            );
        }

        return this.addPlannerBaseItemLocal(serviceEvent, data);
      })
    );
  }

  addPlannerBaseItemLocal(serviceEventId, data) {
    return of(serviceEventId).pipe(
      map((serviceEventId) => {
        const plannerBase = JSON.parse(
          localStorage.getItem(this.PLANNER_LOCAL_STORAGE_KEY)
        );

        if (plannerBase) {
          let objectToPush = {
            eventId: serviceEventId,
            note: data.note,
            eventType: data.eventType,
          };
          if (data.customLike) {
            objectToPush = Object.assign(objectToPush, data.customLike);
            objectToPush['customServiceEvent'] = true;
          }

          plannerBase.plannerItems.push(objectToPush);
          localStorage.setItem(
            this.PLANNER_LOCAL_STORAGE_KEY,
            JSON.stringify(plannerBase)
          );
          this.likeService.updateLocalLike(serviceEventId, {
            plannerItem: true,
          });
        }

        return true;
      })
    );
  }

  addCustomLike(customServiceEvent: number) {
    return this.authService.isLoggedIn$.pipe(
      take(1),
      switchMap((loggedIn) => {
        if (loggedIn) {
          return this.httpService.post(
            `${this.baseUrl}/marketplace/likes/customLike`,
            { customServiceEvent }
          );
        }

        return this.addCustomLikeLocal(customServiceEvent);
      })
    );
  }

  addCustomLikeLocal(customServiceEvent) {
    return of(customServiceEvent).pipe(
      map((customServiceEvent) => {
        customServiceEvent['eventId'] = Date.now().toString();

        let localLikes =
          JSON.parse(localStorage.getItem(this.LIKES_STORAGE_KEY)) || [];

        if (localLikes) {
          localLikes = [
            { ...customServiceEvent, customServiceEvent: true },
            ...localLikes,
          ];
          localStorage.setItem(
            this.LIKES_STORAGE_KEY,
            JSON.stringify(localLikes)
          );
        }

        return true;
      })
    );
  }

  deletePlannerItem(id: string) {
    return this.authService.isLoggedIn$.pipe(
      take(1),
      switchMap((loggedIn) => {
        if (loggedIn) {
          return this.httpService
            .delete(`${this.baseUrl}/marketplace/planner/plannerItem/${id}`)
            .pipe(
              tap(() => {
                const index = this.plannerForm.value.plannerItems.findIndex(
                  (item) => item._id === id
                );
                this.plannerForm.value.plannerItems.splice(index, 1);
              })
            );
        }

        return this.deletePlannerItemLocal(id);
      })
    );
  }

  deletePlannerItemLocal(id: string) {
    return of(id).pipe(
      map((id) => {
        const plannerBase = JSON.parse(
          localStorage.getItem(this.PLANNER_LOCAL_STORAGE_KEY)
        );

        if (plannerBase) {
          const index = plannerBase.plannerItems.findIndex(
            (item) => item.eventId === id
          );
          plannerBase.plannerItems.splice(index, 1);
          localStorage.setItem(
            this.PLANNER_LOCAL_STORAGE_KEY,
            JSON.stringify(plannerBase)
          );
          this.likeService.updateLocalLike(id, { plannerItem: false });
        }
      })
    );
  }

  editPlannerItem(id: string, data: any) {
    return this.httpService.patch(
      `${this.baseUrl}/marketplace/planner/plannerItem/${id}`,
      data
    );
  }

  updateLocalPlannerItem(eventId: string, data: any) {
    const plannerBase = JSON.parse(
      localStorage.getItem(this.PLANNER_LOCAL_STORAGE_KEY)
    );

    const foundlocalLike = plannerBase.plannerItems.find(
      (localLike) => localLike.eventId === eventId
    );
    if (foundlocalLike) {
      Object.assign(foundlocalLike, data);
      localStorage.setItem(
        this.PLANNER_LOCAL_STORAGE_KEY,
        JSON.stringify(plannerBase)
      );
    }
  }

  saveLocalStateForUser() {
    const plannerBase = JSON.parse(
      localStorage.getItem(this.PLANNER_LOCAL_STORAGE_KEY)
    );
    const likes = JSON.parse(localStorage.getItem(this.LIKES_STORAGE_KEY));

    const mergedLikes = this.mergeArraysOfObjects(
      likes,
      plannerBase.plannerItems,
      'eventId'
    );

    const mappedLikes = mergedLikes.map((like) => {
      return {
        id: like.customServiceEvent ? null : like.eventId,
        comment: like.note,
        inTeam: like.plannerItem || false,
        customServiceEvent: like.customServiceEvent
          ? this.getCustomLikeData(like)
          : null,
        inTeamCustom: (like.customServiceEvent && like.plannerItem) || false,
      };
    });

    const data = {
      likes: mappedLikes,
      eventDate: plannerBase.plannerBase.eventDate,
      guestCount: plannerBase.plannerBase.guestCount,
      budget: plannerBase.plannerBase.budget,
      location: plannerBase.plannerBase.location,
      concept: plannerBase.plannerBase.concept,
    };

    return this.httpService
      .post(`${this.baseUrl}/marketplace/likes/addServicesAfterLogin`, data)
      .pipe(
        tap(() => {
          localStorage.removeItem(this.PLANNER_LOCAL_STORAGE_KEY);
          localStorage.removeItem(this.LIKES_STORAGE_KEY);
        })
      );
  }

  getCustomLikeData(customVendor) {
    return {
      url: customVendor && customVendor.url,
      vendorName: customVendor && customVendor.vendorName,
      contactName: customVendor && customVendor.contactName,
      budget: customVendor && customVendor.budget,
      email: customVendor && customVendor.email,
      phoneNumber: customVendor && customVendor.phoneNumber,
      location: customVendor && customVendor.location,
      category: customVendor && customVendor.category,
    };
  }

  mergeArraysOfObjects(arr1, arr2, param) {
    const mergedArray = [];

    arr1.forEach((obj) => {
      mergedArray.push(obj);
    });

    arr2.forEach((obj) => {
      const existingObj = mergedArray.find(
        (item) => item[param] === obj[param]
      );

      if (existingObj) {
        obj.comment =
          existingObj.note || existingObj.comment || obj.note || obj.comment;
        Object.assign(existingObj, obj);
      } else {
        mergedArray.push(obj);
      }
    });

    return mergedArray;
  }

  savePlanner(userId: string) {
    return this.httpService.post(
      `${this.baseUrl}/marketplace/planner/copyWishlist/${userId}`,
      {}
    );
  }

  savePlannerLocal() {
    console.log('Copy to localhost');
    return of(null);
  }

  clearLocalState() {
    localStorage.removeItem(this.PLANNER_LOCAL_STORAGE_KEY);
    localStorage.removeItem(this.LIKES_STORAGE_KEY);
    location.reload();
  }

  destroyPlannerService() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
