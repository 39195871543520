import { InjectionToken } from '@angular/core';

export type DomainCountry = string;

export const DOMAIN_COUNTRY = new InjectionToken<DomainCountry>(
  'Domain country injection token'
);

export const ExamineCountry = () => {
  return () => {
    const localStorageVal = localStorage.getItem('DEV_COUNTRY');

    if (localStorageVal) {
      return localStorageVal;
    }

    const hostName = window.location.hostname.toLowerCase();
    if (hostName.match(/^[\w-]*-en\.\w*\.[a-z]{1,3}$/gm)) return 'us';
    if (hostName.endsWith('.lt')) return 'lt';
    return 'us';
  };
};

export const EnvironmentByHostName = (
  environment,
  environmentByHostName: any[]
): any => {
  const hostName = window.location.hostname.toLowerCase();
  const specificEnvironmentByHostName = environmentByHostName.find((v) =>
    hostName.match(v.hostName)
  );
  if (specificEnvironmentByHostName) {
    Object.assign(environment, specificEnvironmentByHostName);
  }
  return environment;
};
