import { ComponentPortal } from '@angular/cdk/portal';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { JarvisAuthService } from '@jarvis/auth';
import { BASE_URL } from '@jarvis/services';
import { BookingsType, ServiceEvents } from '@jarvis/types';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subject,
  timer,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { MessagesType, MESSAGES_TYPE } from '../services/messages-type.token';
import { MessagingService } from '../../services/messaging.service';
import { ChatRoom } from '../../types/messaging.types';
import { MessagingBookingsListMobileComponent } from '../bookings-list-mobile/bookings-list-mobile.component';
import { MessagingChatMessagesMobileComponent } from '../chat-messages-mobile/chat-messages-mobile.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '@jarvis/ui';

@Injectable()
export class MessagesService implements OnDestroy {
  currentUserId = null;
  currentChatroomId$ = new BehaviorSubject(null);
  currentMobileView$ = new BehaviorSubject<ComponentPortal<any>>(null);
  currentBookings$: Observable<BookingsType[]>;
  currentChatRoom$: Observable<ChatRoom>;
  chatRoomCount$: Observable<number>;
  uniqueServiceEvents$: Observable<ServiceEvents[]>;

  timeAgoTimer$ = timer(0, 60 * 1000).pipe(mapTo(null));

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(MESSAGES_TYPE) private messagesType: MessagesType,
    private httpService: HttpClient,
    private authService: JarvisAuthService,
    private messagingService: MessagingService,
    private currentRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private router: Router
  ) {
    this.layoutService.disableFooter();

    this.authService.userData$.pipe(take(1)).subscribe((userData) => {
      this.currentUserId = userData._id;
    });

    this.currentChatRoom$ = combineLatest([
      this.messagingService.allChatroomCache$,
      this.currentChatroomId$.pipe(distinctUntilChanged()),
    ]).pipe(
      filter(([chatroomCache]) => {
        return chatroomCache.length > 0;
      }),
      switchMap(([chatroomCache, currentId]) => {
        const chatroomFromCache = chatroomCache.find(
          (chatroom) => chatroom._id === currentId
        );

        if (!chatroomFromCache) {
          return this.messagingService
            .getChatroom(currentId)
            .pipe(map((chatroomRaw) => chatroomRaw[0]));
        }

        return of(chatroomFromCache);
      }),
      filter((data) => !!data),
      shareReplay(1)
    );

    this.currentBookings$ = this.currentChatRoom$.pipe(
      // tap((chatroom) => // console.log('Current bookigns changing', chatroom)),
      switchMap((chatroom) =>
        chatroom
          ? this.getBookings(
            chatroom.serviceBase._id,
            chatroom.users._id,
            chatroom?.booking
          )
          : of(null)
      ),
      takeUntil(this.destroy$)
    );

    this.uniqueServiceEvents$ = this.currentBookings$.pipe(
      map((bookings) => {
        const serviceEvents: ServiceEvents[] = [];
        bookings.forEach((booking) => {
          if (
            !serviceEvents.some(
              (serviceEvent) => serviceEvent._id === booking.serviceEventId
            )
          ) {
            serviceEvents.push(booking.serviceEvent);
          }
        });

        return serviceEvents;
      })
    );

    this.chatRoomCount$ = this.messagingService
      .getChatrooms$()
      .pipe(map((chatrooms: any) => chatrooms.length));

    
    combineLatest([this.chatRoomCount$, this.currentChatroomId$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([count, chatId]) => {
        // console.log(count, chatId);

        if (chatId) {
          this.layoutService.disableFooter();
          return;
        }

        if (count === 0) {
          this.layoutService.enableFooter();
        } else {
          this.layoutService.disableFooter();
        }

        /* if (count === 0) {
          if (chatId) {
            this.layoutService.disableFooter();
          } else {
            this.layoutService.enableFooter();
          }
        } */
      });
  }

  ngOnDestroy(): void {
    this.layoutService.enableFooter();
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateBookingNotes(newNotes: string, bookingId: string): Observable<any> {
    const url =
      this.messagesType === 'vendor'
        ? `${this.baseUrl}/booking/${bookingId}`
        : `${this.baseUrl}/marketplace/booking/editNotes/${bookingId}`;

    return this.httpService.patch(url, {
      notes: newNotes,
    });
  }

  getBookings(
    serviceBaseId: string,
    customerId: string,
    bookingId?: string
  ): Observable<BookingsType[]> {
    const additionalParams = JSON.stringify(
      bookingId
        ? { $$_id: bookingId }
        : {
          '$$serviceBaseId._id': serviceBaseId,
          ...(this.messagesType === 'vendor'
            ? { '$$customerId._id': customerId }
            : null),
        }
    );

    const params = new HttpParams({
      fromObject: {
        page: '1',
        size: '100',
        additionalParams,
      },
    });

    const url =
      this.messagesType === 'vendor'
        ? `${this.baseUrl}/booking`
        : `${this.baseUrl}/marketplace/booking`;

    return this.httpService
      .get<{ count: number; docs: BookingsType[] }>(url, { params })
      .pipe(
        map((bookings) => bookings.docs)
        /*            map(bookings => {
                
                return bookings.filter(booking => {
                    const customerObjectOrId = (booking.customerId as any);
                    const serviceBaseOrId = (booking.serviceBaseId as any);
                    const bookingCustomerId = customerObjectOrId?._id || customerObjectOrId || null;
                    const bookingServiceBaseId = serviceBaseOrId?._id || serviceBaseOrId || null;

                    if (this.messagesType === 'vendor') {
                        return (bookingServiceBaseId === serviceBaseId && customerId === bookingCustomerId) 
                                || booking._id == bookingId;
                    }

                    return bookingServiceBaseId === serviceBaseId && bookingCustomerId === this.currentUserId;
                });
            }) */
      );
  }

  getBooking(bookingId: string): Observable<BookingsType> {
    return this.httpService.get<BookingsType>(
      `${this.baseUrl}/booking/${bookingId}`
    );
  }

  openChatView(): void {
    const portal = new ComponentPortal(MessagingChatMessagesMobileComponent);
    this.currentMobileView$.next(portal);
  }

  openInformationView(): void {
    const portal = new ComponentPortal(MessagingBookingsListMobileComponent);
    this.currentMobileView$.next(portal);
  }

  closeMobileViews(): void {
    this.currentMobileView$.next(null);
  }
  

  uploadFiles(chatroomId: string, files: File[], lastId: string, messageText?: string) {
    const formData = new FormData();
    formData.append('chatRoomId', chatroomId);
    formData.append('lastId', lastId);

    if (messageText) {
      formData.append('message', messageText);
    }

    files.forEach((file) => {
      formData.append('message-files', file);
    });
    return this.httpService.post(`${this.baseUrl}/chat/sendFiles`, formData);
  }

  public isOwn(usr:string):boolean { return this.currentUserId === usr; }

  /* uploadPhotos() {
        
  } */
}
