<div class="bztt-text-left bztt-flex-wrap bztt-font-montserrat-bold bztt-text-sm bztt-text-[#222222] bztt-w-full bztt-rounded-lg bztt-border bztt-bg-white bztt-border-[#dddddd] bztt-flex bztt-justify-between bztt-items-center bztt-h-fit md:bztt-h-14 bztt-p-4 md:bztt-p-0 bztt-gap-4 md:bztt-gap-0">
    <button (click)="previousPage()" class="bztt-flex bztt-items-center md:bztt-ml-6 bztt-select-none" [ngClass]="{'bztt-text-[#717171] bztt-pointer-events-none' : pageIndex === 0}">
        <mat-icon>chevron_left</mat-icon>
        <span>{{ (i18nKey + '.prev') | translate}}</span>
    </button>

    <div class="bztt-flex bztt-items-center bztt-order-3 md:bztt-order-none bztt-justify-between bztt-w-full md:bztt-w-fit">
        <span [ngClass]="{'bztt-opacity-100' : lessPagesVisible, 'bztt-opacity-0 ' : !lessPagesVisible}" class="bztt-rounded-full bztt-select-none md:bztt-mx-[0.4rem] bztt-flex bztt-items-center bztt-justify-center bztt-h-9 md:bztt-w-9 bztt-text-[#717171]">...</span>
        <ng-container *ngFor="let index of shownPageIndexes">
            <button (click)="setPage(index)" [ngClass]="{'bztt-bg-[#2d3cc4] bztt-text-white' : index === pageIndex}" class="bztt-rounded-full bztt-select-none bztt-mx-[0.4rem] bztt-flex bztt-items-center bztt-justify-center bztt-h-9 bztt-w-9">
                {{index + 1}}

                <a *ngIf="index !== 0" [routerLink]="[]" [queryParams]="{page: index + 1}" class="bztt-hidden">
                  {{index + 1}}
                </a>
                <a *ngIf="index === 0" [routerLink]="[]" class="bztt-hidden">
                  {{index + 1}}
                </a>
            </button>
        </ng-container>
        <span [ngClass]="{'bztt-opacity-100' : morePagesVisible, 'bztt-opacity-0 ' : !morePagesVisible}" class="bztt-opacity-0 bztt-rounded-full bztt-select-none md:bztt-mx-[0.4rem] bztt-flex bztt-items-center bztt-justify-center bztt-h-9 md:bztt-w-9 bztt-text-[#717171]">...</span>
    </div>

    <button (click)="nextPage()" class="bztt-flex bztt-items-center md:bztt-mr-6 bztt-select-none" [ngClass]="{'bztt-text-[#717171] bztt-pointer-events-none' : pageIndex === maxPageIndex}">
        <span>{{ (i18nKey + '.next') | translate}}</span>
        <mat-icon>chevron_right</mat-icon>
    </button>
</div>