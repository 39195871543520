<ng-container *ngIf="startingCount; else noservices">
  <jarvis-ui-card-wrapper class="booking-list no-line">
    <div class="container-fluid title-bar">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-auto title bztt-mt-4 bztt-mb-2">
          {{ 'bookings.list.title' | translate }}
        </div>

        <!-- Top title-bar controls [(ngModel)]="searchPattern" -->
        <div class="bztt-flex bztt-justify-end bztt-items-center controls">
          <mat-form-field appearance="outline" floatLabel="never" class="me-1">
            <input
              placeholder="{{ 'bookings.list.searchPlaceHolder' | translate }}"
              type="text"
              [formControl]="searchPattern"
              min="0"
              matInput
            />
            <button mat-icon-button matSuffix>
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
          <!-- [(value)]="filterSelected" -->
          <mat-form-field appearance="outline" floatLabel="never">
            <mat-select
              #multiFilter
              disableOptionCentering
              panelClass="select-mob-panel"
              placeholder="{{ 'bookings.list.filterPlaceHolder' | translate }}"
              [formControl]="filterSelected"
              multiple
              (openedChange)="openedChange($event)"
              [compareWith]="compareFilterObjects"
            >
              <mat-select-trigger *ngIf="filterSelected.value?.length > 1">
                <ng-container>
                  {{
                    'bookings.list.listingTrigger'
                      | translate : { p1: filterSelected.value?.length }
                  }}
                </ng-container>
              </mat-select-trigger>

              <div
                class="d-flex flex-column h-100 justify-content-start overflow-hidden filter-select"
              >
                <div class="d-md-none d-flex justify-content-end pe-2">
                  <button
                    mat-button
                    mat-icon-button
                    matSuffix
                    (click)="multiFilter.close()"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                <div
                  class="h-auto overflow-auto compact-mat-option"
                  style="flex-grow: 1"
                >
                  <!--             <mat-optgroup [label]="'bookings.list.filter.byServiceType' | translate">
                <mat-option [value]="{serviceType:item}"
                          *ngFor="let item of list | notContainsValues: [{serviceType:'general'}] | groupValues: 'serviceType'">{{ 'eventTypes.' + item | translate }}
                </mat-option>
              </mat-optgroup> list | groupValues: 'serviceName' -->
                  <mat-optgroup
                    class="mat-accent"
                    [label]="'bookings.list.filter.byService' | translate"
                  >
                    <mat-option
                      [value]="{ serviceName: item }"
                      *ngFor="let item of serviceBase | groupValues : 'name'"
                      >{{ item }}
                    </mat-option>
                  </mat-optgroup>
                  <mat-optgroup
                    [label]="'bookings.list.filter.orderBy' | translate"
                  >
                  </mat-optgroup>
                  <mat-radio-group class="bztt-flex bztt-flex-col" [formControl]="orderByControl">
                    <mat-radio-button
                      *ngFor="let enum of bookingOrderByKeys"
                      [value]="enum"
                      class="bztt-pl-4 bztt-pb-2 filter-radio"
                    >
                      {{ 'bookings.orderBy.' + enum | translate }}
                    </mat-radio-button>
                  </mat-radio-group>
                  <mat-optgroup
                    [label]="'bookings.list.show.title' | translate"
                  >
                  </mat-optgroup>
                  <mat-radio-group class="bztt-flex bztt-flex-col" [formControl]="showControl">
                    <mat-radio-button
                      *ngFor="let enum of shownByKeys"
                      [value]="enum"
                      class="bztt-pl-4 bztt-pb-2 filter-radio"
                    >
                      {{ 'bookings.list.show.' + enum | translate }}
                    </mat-radio-button>
                  </mat-radio-group>
                  <mat-optgroup
                    class="mat-accent"
                    [label]="'bookings.list.filter.byState' | translate"
                  >
                    <mat-option
                      [value]="{ _state: enum }"
                      *ngFor="let enum of bookingFilterKeys"
                      >{{ 'bookings.state.' + enum + '.name' | translate }}
                    </mat-option>
                    <mat-option [value]="{ _deleted: true }"
                      >{{ 'bookings.list.filter.deleted' | translate }}
                    </mat-option>
                  </mat-optgroup>
                </div>

                <div
                  class="d-md-none d-flex justify-content-center align-items-center p-2"
                >
                  <button
                    app-button
                    color="accent"
                    [disabled]="!filterSelected"
                    (click)="multiFilter.close()"
                    class="btn-apply icon-button"
                  >
                    {{ 'bookings.list.applyFilter' | translate }}
                  </button>
                </div>
              </div>
            </mat-select>

            <button
              mat-button
              mat-icon-button
              matSuffix
              (click)="filterSelected.reset(); $event.stopPropagation()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <button
            app-button
            color="accent"
            class="d-none d-lg-inline-block btn-new-booking icon-button"
            (click)="newBooking()"
          >
            <mat-icon>add</mat-icon
            ><span>{{ 'bookings.list.newBooking' | translate }}</span>
          </button>
        </div>
        <!------------------>
      </div>
    </div>

    <!-- List headers -->
    <div class="container-fluid d-none d-md-block gutter32">
      <div class="row header">
        <div class="col-3 md:bztt-max-w-[270px]">
          {{ 'bookings.list.header.state' | translate }}
        </div>
        <div class="col-3">
          {{ 'bookings.list.header.customer' | translate }}
        </div>
        <div class="col">{{ 'bookings.list.header.created' | translate }}</div>
        <div class="col">{{ 'bookings.list.header.date' | translate }}</div>
        <div class="col">{{ 'bookings.list.header.service' | translate }}</div>
        <div class="col">{{ 'bookings.list.header.price' | translate }}</div>
        <div class="col bztt-min-w-[150px]">&nbsp;</div>
      </div>
    </div>
    <!------------------>

    <!-- List content
            | searchFilter
              : ['customer.name', 'customer.email', 'state']
              : searchPattern
            | containsValues: filterSelected

    -->
    <div class="container-fluid overflow-hidden">
      <div class="row data-row" *ngFor="let item of list">
        <div class="col-md-3 state md:bztt-max-w-[270px]">
          <mat-form-field
            appearance="outline"
            [class]="'booking-state-' + getState(item)"
            class="form-field-input"
          >
            <mat-select
              #stateSelect
              [value]="getState(item)"
              panelClass="select-mob-panel"
              (openedChange)="openedChange($event, item)"
              (selectionChange)="changeState($event, item)"
            >
              <div class="d-md-none d-flex justify-content-end pe-2">
                <button
                  mat-button
                  mat-icon-button
                  matSuffix
                  (click)="stateSelect.close()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <mat-option [value]="enum" *ngFor="let enum of bookingKeys"
                >{{ 'bookings.state.' + enum + '.name' | translate }}
              </mat-option>
            </mat-select>
            <mat-hint>{{
              'bookings.state.' + item.state + '.advise' | translate
            }}</mat-hint>
          </mat-form-field>
        </div>

        <div class="col-md-3 customer d-flex align-items-center">
          <span class="pt-2 pt-md-0 md:bztt-flex md:bztt-items-center">
            <img
              *ngIf="item.customerId?.profilePhoto"
              class="circle bztt-object-cover bztt-mb-3 md:bztt-mb-0"
              [src]="item.customerId.profilePhoto"
            />
            <div
              *ngIf="!item.customerId?.profilePhoto"
              class="circle initials bztt-font-semibold"
            >
              {{
                bookingsUtil.getCustomerName(item.customer, item.customerId)
                  | personInitials
                  | uppercase
              }}
            </div>
            <span class="bold">{{
              bookingsUtil.getCustomerName(item.customer, item.customerId)
            }}</span>
          </span>
        </div>
        <div class="d-md-none d-block"><hr /></div>

        <div class="col-md align-items-center d-flex justify-content-between">
          <span class="d-md-none d-inline-block pe-4">{{
            'bookings.list.header.created' | translate
          }}</span>
          <span class="bold">{{
            dateUtils.convertTZ(item.createdAt, 0, 0)
              | date
                : (domainCountry === 'us' ? 'MMM d, y h:mm a' : 'yyyy-MM-dd')
          }}</span>
        </div>
        <div class="d-md-none d-block"><hr /></div>

        <div class="col-md align-items-center d-flex justify-content-between">
          <span class="d-md-none d-inline-block pe-4">{{
            'bookings.list.header.date' | translate
          }}</span>
          <span class="bold"
            >{{
              item.eventDate
                ? (dateUtils.convertTZ(item.eventDate, 0, 0)
                  | date : (domainCountry === 'us' ? 'MMM d, y' : 'yyyy-MM-dd'))
                : !item.options.noEventDate
                ? ('bookings.list.unknownEventDate' | translate)
                : ''
            }}
            <ng-container *ngIf="item.eventDate && item.options.noEventDate">
              ({{ 'bookings.list.flexible' | translate }})
            </ng-container>
            <ng-container *ngIf="!item.eventDate && item.options.noEventDate">
              {{ 'bookings.list.flexible' | translate }}
            </ng-container>
          </span>
        </div>
        <div class="d-md-none d-block"><hr /></div>

        <div class="col-md align-items-center d-flex justify-content-between">
          <span class="d-md-none d-inline-block pe-4">{{
            'bookings.list.header.service' | translate
          }}</span>
          <span
            ><div class="bold text-end text-md-start">
              {{ item.serviceName }}
            </div>
            <div
              *ngIf="item.serviceType != 'general'"
              class="text-end text-md-start"
            >
              {{ 'eventTypes.' + item.serviceType | translate }}
            </div></span
          >
        </div>
        <div class="d-md-none d-block"><hr /></div>

        <div class="col-md align-items-center d-flex justify-content-between">
          <span class="d-md-none d-inline-block pe-4">{{
            'bookings.list.header.price' | translate
          }}</span>
          <span [ngSwitch]="!item.price">
            <span *ngSwitchCase="true">
              {{ 'bookings.list.noPrice' | translate }}
            </span>
            <ng-container *ngSwitchDefault>
              <div
                class="bold d-flex justify-content-md-start justify-content-end"
              >
                {{
                  item.price - (item.discount ? item.discount : 0)
                    | ServiceCurrency : item.serviceBaseId
                }}
              </div>
              <!-- <div (item.discount?item.discount:0) - (item.paid?item.paid:0)-(item.paidCash?item.paidCash:0) *ngIf="item.price" class="d-flex justify-content-md-start justify-content-end">{{'bookings.list.preliminary' | translate}}</div> -->
            </ng-container>
          </span>
        </div>
        <div class="d-md-none d-block"><hr /></div>

        <div
          class="col-md d-flex align-items-center d-flex justify-content-between pt-3 pt-md-0 bztt-min-w-[150px]"
        >
          <a [routerLink]="['./detail', item._id]">{{
            'bookings.list.header.details' | translate
          }}</a>
          <mat-icon
            *ngIf="item.customerId?._id != userId"
            [class.no-message]="!item.customerId && !item.customer.email"
            class="cursor-pointer"
            (click)="toChatRoom(item)"
            [matBadgeHidden]="!bookingsUtil.haveNewMessages(userId, item)"
            [matBadge]="1"
            >chat</mat-icon
          >
        </div>
      </div>

      <!------------------>
      <div class="row" *ngIf="count">
        <jarvis-ui-paginator
          i18nKey="bookings.list"
          [pageSize]="pageSize"
          [pageIndex]="listService.currentPage - 1"
          [length]="count"
          class="bztt-px-0 bztt-w-full"
          (pageChange)="pageChange($event)"
        >
        </jarvis-ui-paginator>
      </div>
      <!-- [ngClass]="{ 'bztt-hidden': !dataSource || dataSource.length === 0 }"  dataLength$ | async -->

      <!-- Bottom fixed position controls (for small screen) -->
      <div class="d-lg-none fixed-bottom p-3 bg-white border-top">
        <button
          app-button
          color="accent"
          class="btn-new-booking"
          (click)="newBooking()"
        >
          <mat-icon style="vertical-align: middle">add</mat-icon
          ><span>{{ 'bookings.list.newBooking' | translate }}</span>
        </button>
      </div>
    </div>
  </jarvis-ui-card-wrapper>
</ng-container>
<ng-template #noservices>
  <jarvis-ui-card-wrapper *ngIf="dataObtained" class="bookings-wrapper">
    <jarvis-ui-actions-card
      [showButton]="true"
      [showSubtext]="false"
      [customButton]="true"
      (buttonClickHandle)="newService()"
      [i18nKey]="'bookings.list.noServices'"
    ></jarvis-ui-actions-card>
  </jarvis-ui-card-wrapper>
</ng-template>
