import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
  Optional,
} from '@angular/core';
import { LayoutService } from '@jarvis/ui';
import { Observable, combineLatest, map, shareReplay } from 'rxjs';
import { MessagingService } from '../../services/messaging.service';
import { ChatRoom } from '../../types/messaging.types';
import { MessagesType, MESSAGES_TYPE } from '../services/messages-type.token';
import { MessagesService } from '../services/messages.service';
import { MessagesChatroomsFiltersComponent } from '../chatrooms-filters/chatrooms-filters.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagingEnvType, MESSAGING_ENV_TYPE } from '../services/messages-env.token';

@Component({
  selector: 'marketplace-messages-chatrooms',
  templateUrl: 'chatrooms.component.html',
  styleUrls: ['./chatrooms.component.scss'],
})
export class MessagesChatroomsComponent implements OnInit, OnDestroy {
  currentUserId: string;
  selectedChatroomId$: Observable<string>;
  @Output() chatroomSelected = new EventEmitter();

  @ViewChild('scrollRoot') scrollRoot: ElementRef<HTMLDivElement>;

  @ViewChild('additionalChatroomsLoading')
  set additionalChatroomsLoading(loadingElement: ElementRef<HTMLDivElement>) {
    this.createLoadingIntersectionObserver()(loadingElement?.nativeElement);
  }

  @ViewChild(MessagesChatroomsFiltersComponent, { static: true })
  chatroomsFilters: MessagesChatroomsFiltersComponent;

  chatrooms$: Observable<ChatRoom[]>;
  searchMessage$: Observable<
    null | 'empty' | 'not-found' | 'error' | 'no-unread'
  >;
  private chatRoomsSource: 'search' | 'all' = 'all'; // Current chatRooms source, by search or all chatrooms
  selectedChatId: string = null;
  timer$ = this.messagesService.timeAgoTimer$;
  allChatroomsLoaded$ = this.messagingService.allChatroomsLoaded$;

  showLoadingPlaceholder$: Observable<boolean>;

  private layoutService = inject(LayoutService);
  isMobile$ = this.layoutService.isLaptopObserver;

  private router = inject(Router);
  private currentRoute = inject(ActivatedRoute);

  constructor(
    private messagingService: MessagingService,
    private messagesService: MessagesService,
    @Inject(MESSAGES_TYPE) public vendorType: MessagesType,
    @Optional() @Inject(MESSAGING_ENV_TYPE) public messagingEnv: MessagingEnvType
  ) {}

  ngOnInit() {
    this.currentUserId = this.messagesService.currentUserId;
    this.selectedChatroomId$ = this.messagesService.currentChatroomId$;

    this.chatrooms$ = combineLatest([
      this.messagingService.getChatrooms$(),
      this.chatroomsFilters.searchResult$,
    ]).pipe(
      map(([chatrooms, searchResult]) => {
        // Select the current chatroom source
        if (searchResult.state !== 'inactive') {
          this.chatRoomsSource = 'search';
          return searchResult.data;
        }
        this.chatRoomsSource = 'all';
        return chatrooms;
      }),
      shareReplay(1)
    );

    // chatroom.messages[0]?.sender !== currentUserId

    this.searchMessage$ = this.chatroomsFilters.searchResult$.pipe(
      map((searchResult) => {
        if (searchResult.state === 'inactive') {
          return null;
        }

        if (
          searchResult.state === 'active' &&
          searchResult?.data?.length === 0
        ) {
          return this.chatroomsFilters.unreadFilterControl.value
            ? 'no-unread'
            : 'not-found';
        }

        if (searchResult.state === 'active') {
          return null;
        }

        return searchResult.state;
      })
    );

    this.showLoadingPlaceholder$ = combineLatest([
      this.allChatroomsLoaded$,
      this.chatroomsFilters.searchResult$,
      this.chatrooms$,
    ]).pipe(
      map(([allChatroomsLoaded, chatroomSearchResults]) => {
        // Show loading placeholder for search, assuming a pagination
        switch (chatroomSearchResults.state) {
          case 'active':
            return (
              chatroomSearchResults?.data?.length < chatroomSearchResults.total
            );
          case 'empty':
            return false;
        }

        if (allChatroomsLoaded) {
          return false;
        }

        return true;
      })
    );

    // console.log(this.chatroomsFilters);
  }

  ngOnDestroy(): void {
    this.chatroomSelected.complete();
  }

  selectChat(chatroom: ChatRoom) {
    const id = chatroom._id;
    this.selectedChatId = id;
    this.chatroomSelected.emit(id);
  }

  markChatroomAsRead(chatroomId: string, clickEvent: MouseEvent): void {
    clickEvent.stopPropagation();
    this.messagingService
      .markChatroomAsRead(chatroomId, this.messagesService.currentUserId)
      .subscribe();
  }

  markChatroomAsUnread(chatroomId: string, clickEvent: MouseEvent): void {
    clickEvent.stopPropagation();
    this.messagingService
      .markChatroomAsUnread(chatroomId, this.messagesService.currentUserId)
      .subscribe();

    const childRouteId =
      this.currentRoute.children?.[0].snapshot.params.messageId;

    if (childRouteId && childRouteId === chatroomId) {
      this.router.navigate(['.'], { relativeTo: this.currentRoute });
    }
  }

  chatroomTrackFn = (index: number, chatroom: ChatRoom) => {
    return chatroom._id;
  };

  private createLoadingIntersectionObserver() {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        if (entry?.isIntersecting) {
          observer.disconnect();
          this.messagingService.loadMoreChatroom$.next(this.chatRoomsSource);
        }
      },
      {
        root: this.scrollRoot?.nativeElement,
        rootMargin: '0px 0px 120px 0px',
      }
    );

    return (loadingElement: Element) => {
      if (loadingElement) {
        observer.observe(loadingElement);
        return;
      }

      observer.disconnect();
    };
  }
}
