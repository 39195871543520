<div class="faq-container">
  <h2 class="title">F.A.Q</h2>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let faq of faqConfiguration[type]">
      <mat-expansion-panel-header [collapsedHeight]="'70px'">
        <mat-panel-title class="faq-title"> {{ faq.title }} </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="faq-description">{{ faq.description }}</div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
