<div
  class="bztt-pb-5 bztt-font-sora bztt-text-[#1f1f1f] bztt-font-semibold bztt-text-lg bztt-text-start"
>
  {{ 'bookings.detail.eventDetails' | translate }}
</div>

<div class="d-md-flex bztt-cursor-pointer bztt-pb-5">
  <mat-form-field
    cdkOverlayOrigin
    #dateAnchor="cdkOverlayOrigin"
    class="bztt-w-full"
    [floatLabel]="'always'"
    appearance="outline"
    (click)="handleDatepickerClick()"
  >
    <mat-label>{{ 'bookings.detail.dateLabel' | translate }}</mat-label>
    <input
      class="bztt-cursor-pointer"
      autocomplete="off"
      readonly
      matInput
      [value]="fullDate"
    />
    <mat-icon matSuffix>event</mat-icon>
  </mat-form-field>
</div>

<div class="bztt-flex bztt-flex-col gap bztt-gap-5">
  <mat-form-field
    class="bztt-w-full"
    [floatLabel]="'always'"
    appearance="outline"
  >
    <mat-label>{{ 'bookings.detail.timeFrom' | translate }}</mat-label>
    <input
      #fromTime
      matInput
      [formControl]="timeFromControl"
      [jarvisUiTimepicker]="tp2"
    />
    <jarvis-ui-timepicker-toggle
      matSuffix
      [for]="tp2"
    ></jarvis-ui-timepicker-toggle>
    <jarvis-ui-timepicker
      [isMobile]="isMobile"
      [dateEvents]="[]"
      #tp2
      [currentDate]="selectedTime"
      [allowTimeBefore] = "dateUtils.getMinutes(timeToControl.value, 1441)"
      (timeSelected)="selectedTimeChanged('eventDate', $event)"
    ></jarvis-ui-timepicker>
  </mat-form-field>
  <!-- (timeSelected)="selectedTimeChanged('eventTimeFrom', $event)" -->

  <!-- <jarvis-ui-time-picker
  [isMobile]="isMobile"
  class="bztt-w-full app-time-picker"
  [(ngModel)]="detailData.options.eventTimeFrom"
  (ngModelChange)="updateIfChanged()"
  tick="30"
></jarvis-ui-time-picker> -->

  <mat-form-field
    class="bztt-w-full"
    [floatLabel]="'always'"
    appearance="outline"
  >
    <mat-label>{{ 'bookings.detail.timeTo' | translate }}</mat-label>
    <input
      #toTime
      matInput
      [formControl]="timeToControl"
      [jarvisUiTimepicker]="tp"
    />
    <jarvis-ui-timepicker-toggle
      matSuffix
      [for]="tp"
    ></jarvis-ui-timepicker-toggle>
    <jarvis-ui-timepicker
      [isMobile]="isMobile"
      (timeSelected)="selectedTimeChanged('eventTillDate', $event)"
      [allowTimeAfter] = "dateUtils.getMinutes(timeFromControl.value, -1)"
      [dateEvents]="[]"
      #tp
      [currentDate]="selectedTime"
    ></jarvis-ui-timepicker>
  </mat-form-field>

  <!-- <jarvis-ui-time-picker
  [isMobile]="isMobile"
  class="bztt-w-full app-time-picker"
  [(ngModel)]="detailData.options.eventTimeTo"
  (ngModelChange)="updateIfChanged()"
  tick="30"
></jarvis-ui-time-picker> -->

  <div class="d-flex" *ngIf="options.type == 'venues'">
    <mat-form-field
      class="bztt-w-full"
      [floatLabel]="'always'"
      appearance="outline"
    >
      <mat-label>{{
        'bookings.detail.guestsPlaceHolder' | translate
      }}</mat-label>
      <input
        [placeholder]="'bookings.detail.guestsPlaceHolderNA' | translate"
        type="number"
        inputmode="numeric"
        pattern="[0-9]*"
        [formControl]="guestFormControl"
        min="1"
        matInput
      />
    </mat-form-field>
  </div>

  <div class="d-flex" *ngIf="options.type != 'venues'">
    <mat-form-field appearance="outline" class="bztt-w-full">
      <mat-label>{{
        'bookings.detail.locationPlaceHolder' | translate
      }}</mat-label>
      <input
        (blur)="updateIfChanged()"
        type="text"
        [(ngModel)]="detailData.location"
        min="0"
        matInput
      />
    </mat-form-field>
  </div>
</div>

<div class="d-flex bztt-mt-5">
  <mat-form-field
    class="bztt-w-full"
    [floatLabel]="'always'"
    appearance="outline"
  >
    <mat-label>{{
      'bookings.detail.proposalValidityPeriod' | translate
    }}</mat-label>
    <input
      [placeholder]="'bookings.detail.proposalValidityPeriod' | translate"
      [placeholder]="'bookings.detail.days' | translate"
      type="number"
      [disabled]="noProposalValidity"
      inputmode="numeric"
      pattern="[0-9]*"
      (blur)="updateIfChanged()"
      [(ngModel)]="proposalValidityPeriod"
      min="1"
      matInput
    />
  </mat-form-field>
</div>

<div class="d-flex bztt-justify-start">
  <mat-checkbox (change)="updateIfChanged()" [(ngModel)]="noProposalValidity">{{
    'bookings.detail.proposalValidityCheckbox' | translate
  }}</mat-checkbox>
</div>

<ng-template #smallCalendarOverlay>
  <div
    class="bztt-bg-white bztt-rounded-t-lg md:bztt-rounded-md bztt-p-4 bztt-shadow-md bztt-w-full"
  >
    <jarvis-ui-small-calendar
      (daySelected)="dateChanged($event)"
      [disablePastDays]="false"
      [events]="[]"
      [date]="date"
    ></jarvis-ui-small-calendar>
  </div>
</ng-template>

<jarvis-overlay
  (closed)="closeDatePicker()"
  *ngIf="showDesktopOverlay"
  [anchor]="dateAnchor"
  originX="start"
  originY="bottom"
  overlayX="start"
  overlayY="top"
>
  <ng-container [ngTemplateOutlet]="smallCalendarOverlay"></ng-container>
</jarvis-overlay>

<jarvis-overlay
  (closed)="closeDatePicker()"
  *ngIf="showMobileOverlay"
  type="global"
  [closeOnNavigation]="false"
>
  <ng-container [ngTemplateOutlet]="smallCalendarOverlay"></ng-container>
</jarvis-overlay>

<ng-container
  *ngIf="{
    calendar: showCalendarPriceUpdateNotification,
    guestCount: showGuestCountPriceUpdateNotification
  } as vm"
>
  <jarvis-modal
    *ngIf="vm.calendar || vm.guestCount"
    maxHeight="90vh"
    [modalClass]="['mat-dialog-no-padding']"
    (modalClose)="closeNotifications()"
  >
    <jarvis-ui-modal-wrapper
      class="modal-wrapper medium-modal bztt-flex bztt-p-4"
      (closed)="closeNotifications()"
      [closeButton]="vm.guestCount && !vm.calendar"
    >
      <div
        *ngIf="vm.guestCount && !vm.calendar"
        class="bztt-flex bztt-flex-col bztt-gap-8 bztt-p-4 bztt-h-full bztt-justify-center bztt-items-center"
      >
        <div class="bztt-text-lg bztt-font-semibold">
            Would you like to update the selected package information, including applicable prices, based on the new guest count?
        </div>

        <div class="bztt-flex bztt-justify-end bztt-items-center bztt-gap-3">
          <button
            app-button
            color="accent"
            (click)="handlePriceUpdateGuestCount()"
            class="bztt-w-32"
          >
            Yes
          </button>
          <button app-button (click)="closeNotifications()" class="bztt-w-32">
            No
          </button>
        </div>
      </div>

      <div
        *ngIf="vm.calendar && !vm.guestCount"
        class="bztt-flex bztt-flex-col bztt-gap-8 bztt-p-4 bztt-h-full bztt-justify-center bztt-items-center"
      >
        <div class="bztt-text-xl bztt-font-semibold">
          Please note that changing the event date might impact your service
          price based on the pricing rules applicable to that particular date.
        </div>

        <div class="bztt-flex bztt-justify-end bztt-items-center bztt-gap-3">
          <button
            app-button
            color="accent"
            (click)="openDatePicker()"
            class="bztt-w-32"
          >
            OK
          </button>
          <button app-button (click)="closeNotifications()" class="bztt-w-32">
            Close
          </button>
        </div>
      </div>
    </jarvis-ui-modal-wrapper>
  </jarvis-modal>
</ng-container>
