import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { BASE_SOCKET_URL } from '../tokens/base-url.token';

@Injectable({providedIn: 'root'})
export class SocketService {

    get isConnected(): Observable<boolean> {
        return this.socket.pipe(map((socket) => !!socket));
    }

    get socket(): Observable<Socket> {
        return this._socket.asObservable();
    }

    protected _socket = new BehaviorSubject<Socket>(null);

    constructor(
        @Inject(BASE_SOCKET_URL) private baseSocketUrl: string
    ) { }

    destroySocket(): void {
        if (this._socket.value) {
            this._socket.value.disconnect();
        }
        this._socket.next(null);
    }

    initSocket(): void {
        
        this._socket.next( io(this.baseSocketUrl, {
            path: '/api/socket.io',
            withCredentials: true
        }) );
    }

    wrapInSocket<T>(socketCb: (socket: Socket) => Observable<T>): Observable<T> {
        const wrapped =  this.socket.pipe(
            switchMap((socket) => {
                if (socket) {
                    return socketCb(socket);
                }

                return of(null);
            })
        );

        return wrapped;
    }
    
}