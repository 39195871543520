import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jarvis-pricing-popup-dialog',
  templateUrl: './pricing-popup-dialog.component.html',
  styleUrls: ['./pricing-popup-dialog.component.scss'],
})
export class PricingPopupDialogComponent {
  constructor(private dialogRef: MatDialogRef<PricingPopupDialogComponent>,) { }

  handleSignup() {
    this.dialogRef.close(true);
  }

  handleClose() {
    this.dialogRef.close();
  }
}
