import { Component, Inject, OnDestroy, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  BookingAction,
  BookingsType,
  BookingsUtil,
  ServiceBase,
} from '@jarvis/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  map,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { MESSAGES_TYPE, MessagesType } from '../services/messages-type.token';
import { MessagesService } from '../services/messages.service';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';

@Component({
  selector: 'messaging-booking-info',
  templateUrl: 'booking-info.component.html',
  styleUrls: ['./booking-info.component.scss'],
})
export class MessagesBookingInfoComponent implements OnDestroy {
  bookingInfo$: Observable<BookingsType[]>;
  notesControl = new UntypedFormControl(null);
  expandedIndex = 0;

  currentChatRoom$: Observable<any>;
  serviceBase: ServiceBase;

  private destroy$ = new Subject<void>();
  private bookingUpdate$ = new Subject<void>();
  public waiting$ = new BehaviorSubject<boolean>(true);
  private domainCountry = inject(DOMAIN_COUNTRY);
  
  private  = new Subject<void>();

  constructor(
    @Inject(MESSAGES_TYPE) public messagesType: MessagesType,
    private messagesService: MessagesService,
    private router: Router
  ) {
    this.currentChatRoom$ = this.messagesService.currentChatRoom$.pipe(tap(v=>this.waiting$.next(true)));
    this.bookingUpdate$
      .pipe(withLatestFrom(this.currentChatRoom$), takeUntil(this.destroy$))
      .subscribe(([update, chatroom]) => {
        this.waiting$.next(false);        
        this.serviceBase = chatroom.serviceBase;
      });

    this.bookingInfo$ = this.messagesService.currentBookings$.pipe(
      tap(() => {
        this.expandedIndex = 0;        
        this.bookingUpdate$.next();
      }),
      map((bookings) =>
        bookings
          ? bookings.map((booking) => ({
            ...booking,
            notesControl: this.getControl(
              this.messagesType === 'vendor'
                ? booking.notes
                : booking.userNotes,
              booking._id
            ),
            bookingButton: BookingsUtil.getUIState(
              booking,
              this.serviceBase,
              this.messagesType
            ),
          }))
          : []
      )
    );
  }

  bookingState(booking: BookingsType): string {
    return BookingsUtil.transformState(booking);
  }

  navigateToBooking(bookingId: string, event?: BookingAction): void {
    const marketplaceDetailLink = this.domainCountry === 'lt' ? '/visos-rezervacijos/detales/' : '/bookings-list/detail/';
    const bookingUrl = this.messagesType === 'user' ? `${marketplaceDetailLink}${bookingId}` : `/bookings/detail/${bookingId}`;
    this.router.navigateByUrl(bookingUrl, {
      state: {
        bookingAction: event,
      },
    });
  }

  getControl(initialNotes: string, bookingId: string) {
    const control = new UntypedFormControl(initialNotes ?? null);

    // TODO: Will probably leak as the bookings reload. We should clear it when new load starts
    control.valueChanges
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((notes) => {
        this.messagesService.updateBookingNotes(notes, bookingId).subscribe();
      });
    return control;
  }

  public get BookingAction(): typeof BookingAction {
    return BookingAction;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.bookingUpdate$.complete();
  }
}
