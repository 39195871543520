import { Component } from '@angular/core';
import {UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';

@Component({
  templateUrl: './success.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class SuccessComponent {

  formControl:UntypedFormControl;

  constructor(
    private profilingFormService: JarvisProfilingFormService,
    private router: Router
  ) {
    this.formControl = this.profilingFormService.getStepControl('heardFromSource');
  }

  createListing(): void {
    this.router.navigate(['services', 'new']);
  }

  bookCall(): void {
    window.open('https://meetings-eu1.hubspot.com/arturas-asakavicius/intro-to-breezit', '_blank', 'noreferrer');
    this.router.navigate(['services', 'new']);
  }
}