import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'jarvis-auth-email-confirmed-modal',
  templateUrl: './email-confirmed-modal.component.html',
  styleUrls: ['./email-confirmed-modal.component.scss']
})
export class JarvisAuthEmailConfirmedModalComponent {

  constructor(private dialogRef: MatDialogRef<JarvisAuthEmailConfirmedModalComponent>) { }

  close(): void {
    this.dialogRef.close();
  }

}
