import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ServiceBase } from '@jarvis/types';

@Pipe({
    name: 'ServiceDate',
    standalone: true
})

export class ServiceDatePipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe,
        @Inject(LOCALE_ID) private localeId: string
    ) {}

    transform(value: Date | string, serviceBase?: ServiceBase): any {
        const country = serviceBase.address?.country || 'US';
        const localeId = country === 'US' ? 'en-US' : 'lt-LT';
        return this.datePipe.transform(value, 'mediumDate', null, localeId);
    }
}