import { inject, Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ltAddresses, usStatesCounties } from '@jarvis/data/address';
import { shareReplay } from 'rxjs/operators';
import { UserProfilingData } from '../types/user-profiling.types';
import { JarvisUserProfilingService } from './profiling.service';

import { JarvisValidators } from '@jarvis/utils';
import { JarvisTrackingService } from '@jarvis/services';

@Injectable()
export class JarvisProfilingFormService {
  profilingForm: UntypedFormGroup;
  currentCountry: 'LT' | 'US';

  private trackService = inject(JarvisTrackingService);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private profilingService: JarvisUserProfilingService
  ) {
    this.currentCountry = this.currentRoute.snapshot.data['country'];

    this.profilingForm = this.formBuilder.group({
      serviceCategory: [null, [Validators.required]],
      eventCategory: [null, [Validators.required]],
      // startingPrice: [null, [Validators.required, Validators.min(0)]],
      tenure: [null, [Validators.required]],
      businessName: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      websiteUrl: [null, [JarvisValidators.hyperlink()]],
      heardFromSource: [null, [Validators.required]],
      location: [
        null,
        [Validators.required, includesStateValidator(this.currentCountry)],
      ],
      bookACallPressed: [false],
      // promoCode: [null]
    });
  }

  getStepControl(name: keyof UserProfilingData): UntypedFormControl {
    return this.profilingForm.get(name) as UntypedFormControl;
  }

  navigateToStep(stepName: string, saveToDb = true): void {
    if (saveToDb) {
      this.profilingService.modifyIncompleteProfilingInfo(
        this.profilingForm.value
      );
    }
    this.router.navigate([stepName], { relativeTo: this.currentRoute });
  }

  submitProfilingInfo(options?: { completedValue: boolean }) {
    const value = {
      ...this.profilingForm.value,
      completed: options?.completedValue ?? true,
    };

    return this.profilingService
      .createProfilingInfo(value)
      .pipe(shareReplay(1));
  }

  trackFieldCaptureEvent(stepName: string) {
    this.trackService.handleEvent({
      trackers: ['amplitude', 'mixpanel'],
      eventName: `${stepName}_captured`,
    });
  }
}

const includesStateValidator: (country: 'LT' | 'US') => ValidatorFn = (
  country
) => {
  return (formControl) => {
    const stateVal = formControl.value;
    const states = Object.keys(
      country === 'US' ? usStatesCounties : ltAddresses
    );
    if (!states.includes(stateVal)) {
      return {
        incorrectState: true,
      };
    }

    return null;
  };
};
