<app-modal-wrapper class="modal-wrapper confirmation" [closeButton]="closeButton" (closed)="close(null)">  
<div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between">  
    <div></div>
    <div class="d-flex flex-column align-items-center mb-3">
      <span><mat-icon class="icon material-icons-outlined">new_releases</mat-icon></span>

      <span class="mt-3 fs-3 bolder" *ngIf="titleSet | async">{{ i18nKey + ".title" | translate }}</span>

      <span class="mt-3 message">{{ i18nKey + ".message" | translate: translationData }}</span>
    </div>

   
  <div class="d-flex flex-column flex-md-row-reverse w-100 justify-content-center">
    <!-- <div class="d-flex mt-3 justify-content-center"> -->
        <div class="d-flex justify-content-center pb-3" *ngIf="confirmButtonSet | async">
            <button app-button (click)="confirm()" color="accent">{{i18nKey + ".confirm" | translate}}</button>
        </div>
        <div class="divider"></div>
        <div class="d-flex justify-content-center">
            <button app-button (click)="cancel()">{{i18nKey + ".cancel" | translate}}</button> 
        </div>

   </div> 
  </div>    
</app-modal-wrapper>