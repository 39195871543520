import { Pipe, PipeTransform, inject } from '@angular/core';
import { ServiceBase } from './service-base.types';
import { ServiceCurrencyPipe } from '@jarvis/services';

@Pipe({
  name: 'CancelationDescription',
  standalone: true,
  pure: true
})
export class BookingsCancelationDescriptionPipe implements PipeTransform {

  private currencyPipe = inject(ServiceCurrencyPipe);

  transform(service: ServiceBase) {
    if (service?.reservation?.canCancelReservation) {
      if (service.reservation.refundType == 'whole')
        return [{ type: 2, p1: service.reservation.refundUntilDays}];
      else
        return service.reservation.refundRules.map((item) => 
          ({
            type: 3,
            p1: item.daysUntil,
            p2: item.fee
              ? this.currencyPipe.transform(
                item.fee,
                service
              )
              : item.refundPart,
            p3: item.priceUnit
          }));
    } else {
      return [{ type: 1 }];
    }
  }
}

@Pipe({
  name: 'PenaltiesDescription',
  standalone: true,
  pure: true
})
export class BookingsPenaltiesDescriptionPipe implements PipeTransform {

  private currencyPipe = inject(ServiceCurrencyPipe);

  transform(service: ServiceBase) {
    if (service?.reservation?.cancelationFee) {
      return service.reservation.cancelationFeeRules.map((item) => ({
        type: 2,
        p1: item.daysUntil,
        p2: this.currencyPipe.transform(
          item.fee,
          service)
      }));
    } else {
      return [{ type: 1 }];
    }
  }
}

@Pipe({
  name: 'RescheduleDescription',
  standalone: true,
  pure: true
})
export class BookingsRescheduleDescriptionPipe implements PipeTransform {

  private currencyPipe = inject(ServiceCurrencyPipe);

  transform(service: ServiceBase) {
    if (service?.reservation?.canReschedule) {
      if (!service.reservation.rescheduleFee)
        return [{ type: 2 }];        
      else
        return [{
          type: 3,
          p1: this.currencyPipe.transform(
            service.reservation.rescheduleFeeMonetary ?? 0,
            service
          )
        }];
    } else {
      return [{ type: 1 }];
    }
  }
}

@Pipe({
  name: 'PaymentDescription',
  standalone: true,
  pure: true
})
export class BookingsPaymentDescriptionPipe implements PipeTransform {

  transform(service: ServiceBase) {
    return [{
      type: service.advances.fullAdvance
        ? 3
        : service.advances.partialPaymentOption == 'before_event'
          ? 1
          : 2,
      p1: service.advances.paymentDays }];
  }
}
