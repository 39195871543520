import { Portal } from '@angular/cdk/portal';
import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LinkUrls, LINK_URLS } from '@jarvis/services';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { map, mapTo, startWith, tap } from 'rxjs/operators';
import { HeaderMenuComponent } from './menu/menu.component';
import { HeaderService } from './services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: [
    './header.component.scss'
  ]
})

export class HeaderComponent implements OnInit {

  @ViewChild('header', { read: ElementRef, static: true }) headerRef: ElementRef;
  @ViewChild('defaultMenu', { static: true }) defaultMenu: TemplateRef<unknown>;
  currentPortal$: Observable<Portal<any>>;

  windowWidth$ = fromEvent(window, 'resize').pipe(
    map(() => document.documentElement.clientWidth)
  );

  menuState$: Observable<any>;

  constructor(
    private router: Router,
    public headerService: HeaderService,
    private viewContainerRef: ViewContainerRef,
    @Inject(LINK_URLS) private linkUrls: LinkUrls
  ) { }

  ngOnInit(): void {
    this.currentPortal$ = this.headerService.headerPortal$;

    const nativeHeaderEl = this.headerRef.nativeElement as HTMLDivElement;
    const headerWidth$ = fromEvent(window, 'resize').pipe(
      startWith([
        document.documentElement.clientWidth,
        nativeHeaderEl.scrollWidth
      ]),
      map(() => [
        document.documentElement.clientWidth,
        nativeHeaderEl.scrollWidth
      ] as [number, number])
    );

    this.headerService.setHeaderWidthStream(headerWidth$);
  }

  homepageRedirrect() {
    window.location.href = this.linkUrls.marketplace;
  }
}
