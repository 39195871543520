<ng-container *ngIf="(currentChatRoomMessages$ | async) as currentChatroomMessages">
  <div class="messages-container" [class.shrunk]="isExpanded">
    <div class="messages-scroll-container" #messageContainer>
      <div class='message-wrapper hover:bztt-bg-opacity-5 hover:bztt-bg-gray-500'
          *ngFor="let messageData of currentChatroomMessages; let i = index; trackBy: messageTrackBy"
          [class.own-message]="isOwn(messageData.sender)"
          [class.deleted]="messageData.type === 'deleted'"
          (click)="messageTap($event)"
      >
        <div *ngIf="{lastMessage: currentChatroomMessages.length - 1 === i} as vm" class="message-photo-container">
            <div class="profile-photo"
              *ngIf="!isOwn(messageData.sender) && messageData.type !== 'serviceEventMessage'"
            >
              <img *ngIf="currentChatroomMessages[i+1] | ShowTimePassed:messageData" imageError="/assets/images/noUser.jpeg" [src]="profilePhotoImage" alt="profile-photo" />
            </div>
            
            <div class="event-start" *ngIf="messageData.type === 'serviceEventMessage'">
              <span>
                {{serviceBase | ServiceName:messageData.message}}
              </span>
            </div>
            
            <p *ngIf="['text','autoText','concierge','proposal','contract'].includes(messageData?.type)" [innerHTML]="messageData.message | urlify"></p>
            
            <p *ngIf="messageData.type === 'deleted'">
                {{'messages.deleted' | translate }}
            </p>

            <div class="bztt-flex bztt-relative bztt-flex-col file-container group-hover:md:bztt-bg-gray-200 bztt-bg-opacity-10 group-hover:md:bztt-shadow-lg" *ngIf="messageData.type === 'files'">
              <div
                *ngIf="messageData.uploading"
                class="bztt-h-full bztt-w-full bztt-absolute bztt-top-0 bztt-left-0 bztt-bg-gray-400 bztt-bg-opacity-30 bztt-flex bztt-items-center bztt-justify-center"
              >
                <mat-progress-spinner
                  [strokeWidth]="2"
                  mode="indeterminate"
                ></mat-progress-spinner>
              </div>
              
              <p [class.bztt-opacity-80]="messageData.error" class="bztt-mb-1" *ngIf="!!messageData.message" [innerHTML]="messageData.message | urlify"></p>

              <ng-container [ngSwitch]="messageData.files | OnlyImages">
                <ng-container *ngSwitchCase="true">
                  <div 
                    class="files"
                    [class.bztt-opacity-80]="messageData.error"
                  >
                    <div
                      [class.half]="messageData.files.length > 1"
                      [class.third]="messageData.files.length > 4"
                      class="image-grid"
                    >
                      <ng-container 
                        *ngIf="{
                          inactive: messageData.uploading || messageData.error
                        } as galleryOptions"
                      >
                        <a
                          (click)="openGalleryImage(messageData.files, i, galleryOptions.inactive)"
                          *ngFor="let image of messageData.files; let i = index;"
                        >
                          <img [src]="image.url" alt="message-picture" />
                        </a>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
  
                <ng-container *ngSwitchDefault>
                  <div class="bztt-flex bztt-flex-col bztt-gap-2 bztt-justify-end">
                    <div *ngFor="let file of messageData.files" class="files-doc">
                      <div class="bztt-border bztt-border-[#A1A1A1] bztt-rounded-lg bztt-mr-2 bztt-inline-flex bztt-items-center bztt-content-center">
                        <mat-icon class="bztt-text-[32px] bztt-h-[32px] bztt-w-[32px] bztt-m-1">attach_file</mat-icon>
                      </div>
                      <a [href]="file.url" target="_blank" class="bztt-cursor-pointer bztt-border-none bztt-overflow-hidden">
                        <div class="bztt-inline-flex bztt-flex-col bztt-justify-between bztt-overflow-hidden bztt-w-full">
                          <span class="bztt-text-sm bztt-underline bztt-font-semibold bztt-text-ellipsis bztt-whitespace-nowrap bztt-overflow-hidden">{{file.fileName || file.url}}</span>
                          <span class="bztt-text-xs bztt-font-normal bztt-text-ellipsis bztt-whitespace-nowrap bztt-overflow-hidden">.{{file.fileName?.split('.')[1] | uppercase}}</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <span
                *ngIf="messageData.error"
                class="bztt-text-red-500 bztt-text-xs bztt-mt-2"
              >
                {{ 'messages.sendError' | translate }} <a (click)="retrySendMessage(messageData._id)" class="bztt-border-none bztt-underline bztt-underline-offset-4 bztt-p-0 bztt-font-normal">{{ 'messages.retry' | translate }}?</a>
              </span>
            </div>

            <div *ngIf="(autoReplyActive$ | async) && isOwn(messageData.sender) && vm.lastMessage && domainCountry === 'us' && messagesType === 'vendor'" class="bztt-flex bztt-items-center bztt-justify-center bztt-mr-2">
              <mat-icon>forum</mat-icon>
            </div>

            <div *ngIf="messageData | ShowActionsInline: isOwn(messageData.sender) : vm.lastMessage" class="additional-actions"
              [class.interacted]="moreActionsEl === moreActionsOrigin">
              <mat-icon
                (click)="openMessageActionsOverlay(moreActionsOrigin, messageData, vm.lastMessage)"
                cdkOverlayOrigin
                #moreActionsOrigin="cdkOverlayOrigin"
              >
                more_horiz
              </mat-icon>
            </div>
          </div>
          <span class="time-passed" *ngIf="currentChatroomMessages[i+1] | ShowTimePassed: messageData">
            <ng-container
              [ngSwitch]="((currentChatroomMessages.length - 1) !== i) || !isOwn(messageData.sender)"
            >
              <ng-container *ngSwitchCase="true">
                {{ messageData.createdAt | TimePassed }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ 
                  (currentChatRoom$ | async | ReadByBoth) ? ('messages.seen' | translate | titlecase) : (messageData.createdAt | TimePassed)
                }}
              </ng-container>
            </ng-container>
          </span>
      </div>
    </div>
  </div>

  <div class="actions-container" [class.expanded]="isExpanded">
    <div class="sub-action bztt-flex bztt-flex-col bztt-w-full bztt-h-full bztt-rounded-lg">
      <textarea
        *ngIf="isExpanded"
        class='expanded-textarea bztt-bg-[#F1F1F1]'
        (focus)="toggleTextAreaFocus('focus')"
        (blur)="toggleTextAreaFocus('blur')"
        (keydown)="handleInputKeyPress($event)"
        #messageInput
        [placeholder]="'messages.writeMessage' | translate"
        [formControl]="messageControl"
      ></textarea>
    
      <div
        [class.bztt-px-5]="isExpanded"
        [class.bztt-pb-2]="isExpanded"
        class="bztt-justify-between bztt-flex bztt-pt-2 lg:bztt-pb-2 bztt-flex-row bztt-items-center bztt-w-full"
        cdkOverlayOrigin
        #emojiOrigin="cdkOverlayOrigin"
      > 
        <div class="bztt-flex bztt-row bztt-gap-1 bztt-mr-2">
          <mat-icon
            [matTooltip]="'messages.attachFile' | translate"
            [matTooltipDisabled]="isMobile$ | async"
            class="material-icons-outlined bztt-text-[#959595] bztt-cursor-pointer"
            (click)="fileInput.click();"
          >attach_file</mat-icon>
      
          <!-- <mat-icon  class="md:bztt-hidden bztt-block material-icons-outlined bztt-text-[#959595] bztt-ml-2" (click)="openEmojiPicker()">
              emoji_emotions
          </mat-icon> -->

          <mat-icon class="emoji-picker material-icons-outlined bztt-text-[#959595]" (click)="openEmojiPicker()">
            emoji_emotions
          </mat-icon>

          <mat-icon *ngIf="isExpanded" class="md:bztt-hidden bztt-block material-icons-outlined bztt-text-[#959595]" (click)="toggleTextAreaExpand()">
            {{isExpanded ? 'close_fullscreen' : 'open_in_full'}}
          </mat-icon>
        </div>

        <mat-form-field class="fix-suffix bztt-bg-[#F1F1F1] bztt-w-[160px] bztt-rounded-lg" *ngIf="!isExpanded" [appearance]="'none'">
          <textarea
            cdkTextareaAutosize
            [cdkAutosizeMinRows]="1"
            [cdkAutosizeMaxRows]="10"
            (focus)="toggleTextAreaFocus('focus')"
            (blur)="toggleTextAreaFocus('blur')"
            (keydown)="handleInputKeyPress($event)"
            #messageInput
            [placeholder]="'messages.writeMessage' | translate"
            matInput
            class="message-input bztt-jarvis-scroll"
            [formControl]="messageControl"
          ></textarea>

          <span matSuffix *ngIf="!isExpanded" class="bztt-h-full bztt-flex bztt-flex-row bztt-items-center bztt-justify-center bztt-px-2">
            <mat-icon class="md:bztt-hidden bztt-block material-icons-outlined bztt-text-[#959595] bztt-text-[18px] bztt-h-[18px] bztt-w-[18px] bztt-leading-[1em]" (click)="toggleTextAreaExpand()">
                {{isExpanded ? 'close_fullscreen' : 'open_in_full'}}
            </mat-icon>
            <mat-icon class="bztt-cursor-pointer bztt-text-[#959595] bztt-text-[24px] bztt-h-[24px] bztt-w-[24px] bztt-leading-[1em]" *ngIf="editMode" (click)="cancelMessageEdit()">clear</mat-icon>
          </span>
        </mat-form-field>

        <div class="md:bztt-min-w-[100px] bztt-min-w-[40px] bztt-pl-2">
          <button app-button class=" bztt-pl-1.5 md:bztt-pl-0 bztt-w-[35px] md:bztt-w-[90px] bztt-h-[35px]" color="accent" (click)="editMode ? confirmMessageEdit() : sendMessage()">
            <span class="bztt-hidden md:bztt-block">{{ (editMode ? 'messages.edit' : 'messages.send') | translate}}</span>
            <mat-icon class="bztt-block md:bztt-hidden">{{editMode ? 'done' : 'arrow_forward'}}</mat-icon>                
          </button>
        </div>

      </div> 
    </div>

    <div *ngIf="!editMode && !isExpanded && uploadFiles.length > 0" class="sub-action bztt-w-full bztt-mt-2 md:bztt-mt-0">
      <div mouseScrollable class=" bztt-flex bztt-mb-2 md:bztt-gap-2 bztt-gap-3 bztt-jarvis-scroll bztt-overflow-x-auto bztt-pb-1 bztt-pt-3 bztt-touch-none">
        <div *ngFor="let file of uploadFiles; let i = index" class="bztt-border bztt-flex bztt-rounded-lg bztt-font-montserrat bztt-text-sm bztt-h-[56px] bztt-relative bztt-group bztt-select-none">
          
          <div
            (click)="removeFile(i)"
            class="bztt-absolute -bztt-right-[12px] -bztt-top-[12px] bztt-h-[24px] bztt-w-[24px] bztt-border bztt-bg-white bztt-rounded-full bztt-justify-center bztt-items-center bztt-cursor-pointer bztt-flex md:bztt-hidden md:group-hover:bztt-flex bztt-z-[1]"
          >
            <mat-icon class="bztt-text-[18px] bztt-h-[18px] bztt-w-[18px] bztt-text-[#717171]">close</mat-icon>
          </div>
          
          <ng-container [ngSwitch]="file.type.includes('image')">
            <div *ngSwitchCase="true" class="bztt-overflow-hidden bztt-w-[56px] bztt-rounded-lg bztt-pointer-events-none">
              <img *ngIf="file | ImagePreview | async as imageSrc" class="bztt-object-cover bztt-w-full bztt-h-full" [src]="imageSrc" />
            </div>

            <div *ngSwitchDefault class="bztt-my-auto bztt-mx-2 bztt-flex bztt-min-w-[10rem] bztt-max-w-[12rem]">
              <div class="bztt-border bztt-border-[#A1A1A1] bztt-rounded-lg bztt-mr-2 bztt-inline-flex bztt-items-center bztt-content-center">
                <mat-icon class="bztt-text-[32px] bztt-h-[32px] bztt-w-[32px] bztt-m-1">attach_file</mat-icon>
              </div>

              <div class="bztt-inline-flex bztt-flex-col bztt-justify-between bztt-overflow-hidden">
                <span class="bztt-text-sm bztt-font-semibold bztt-text-ellipsis bztt-whitespace-nowrap bztt-overflow-hidden">{{file.name}}</span>
                <span class="bztt-text-xs bztt-text-ellipsis bztt-whitespace-nowrap bztt-overflow-hidden">.{{file.name.split('.')[1] | uppercase}}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="emojiOrigin"
  [cdkConnectedOverlayOpen]="emojiPickerOpen" [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayWidth]="100"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="closeEmojiPicker()">
    <emoji-mart [showPreview]="false" (emojiClick)="addEmoji($event)"></emoji-mart>
  </ng-template>

  <lightgallery #lg [onInit]="galleryOnInit" [settings]="gallerySettings"></lightgallery>

</ng-container>

<input #fileInput multiple="true" hidden type="file" (change)="fileInputChange($event)" />

<messages-chat-messages-actions
  *ngIf="messageActionsOverlayData$ | async as actionOverlayData"
  [actionOverlayData]="actionOverlayData"
  [messagesType]="messagesType"
  [domainCountry]="domainCountry"
  (closed)="closeMessageActionsOverlay()"
  (edit)="startMessageEdit($event.id, $event.message)"
  (delete)="deleteMessage($event)"
  (markUnread)="markAsUnread()"
  (autoReply)="autoReplyHandler()"
></messages-chat-messages-actions>

<ng-container *ngIf="autoReplyShown$ | async">
  <chat-autoreply
    (closed)="closeAutoReply()"
    [autoReplies]="autoReply$ | async"
    [chatroomId]="currentChatroomId$ | async"
  ></chat-autoreply>
</ng-container>