<mat-sidenav-container #sidenav>
  <mat-sidenav role="navigation" position="end" fixedInViewport>
    <nav>
      <div class="links">
        <a [href]="saasLandingLink">Providing services?</a>
        <a
          class="link header-login"
          (click)="openLoginDialog()"
          style="text-decoration: none"
          >Log in</a
        >
      </div>
      <button
        (click)="openContactFormDialog(); sidenav.close()"
        app-button
        color="accent"
        class="header-get-started"
      >
        {{ 'header.getStarted' | translate }}<
      </button>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <main>
      <section aria-describedby="introplan" class="intro">
        <h1 #introplan>Plan your event without a hassle</h1>

        <p>
          Breezit is a free workspace that helps you plan your event, discover
          service providers and book their services
        </p>

        <button
          class="hero-getstarted"
          app-button
          color="accent"
          (click)="openContactFormDialog()"
        >
          Get started, it's free!
        </button>

        <landings-browser-window
          [class.big]="!isMobile"
          class="main-screenshot"
        >
          <img alt="plaform picture" src="assets/images/planner.png" />
        </landings-browser-window>
      </section>

      <section aria-describedby="endorsmentcount" class="reviews-endorsments">
        <h2 #endorsmentcount>
          +17,000 event hosts already using Breezit to plan their most important
          events
        </h2>

        <landings-reviews-slide
          [config]="endorsmentConfig"
        ></landings-reviews-slide>
      </section>

      <section class="sections-container">
        <ng-container *ngFor="let section of sections; let i = index">
          <div>
            <div class="text-container">
              <h2>{{ section.header }}</h2>
              <p>{{ section.text }}</p>
              <div class="link" (click)="openContactFormDialog()">
                <a>Start now</a>
                <mat-icon>arrow_forward</mat-icon>
              </div>
            </div>
            <div class="video-container">
              <landings-browser-window>
                <video
                  playInView="0.3"
                  loop
                  muted
                  playsinline
                  preload="none"
                  [poster]="section.videoPoster"
                  *ngIf="section.videoLink as videoLink"
                >
                  <source [src]="videoLink" type="video/mp4" />
                </video>
                <img *ngIf="section.imageLink as imageLink" [src]="imageLink" />
              </landings-browser-window>
            </div>
          </div>
        </ng-container>
      </section>

      <section class="function-section">
        <h2>2 minutes to get started</h2>
        <p>
          Create a free Breezit workspace is easy. Just create an account at a
          click of few buttons and enjoy great event planning experience with
          Breezit.
        </p>
        <landings-functions-section
          [config]="functionsConfig"
        ></landings-functions-section>
      </section>

      <section class="section-with-image">
        <h2>It’s free. No catch.</h2>
        <p>
          Breezit charges a small membership fee to service providers. For this
          fee, they get a full set of tools helping them manage their business
          and provide great experience to customers like you.
        </p>
        <img src="/assets/images/card_illustration.svg" />
      </section>

      <section class="section-fullwidth">
        <landings-lets-breezit-section
          [type]="'user'"
        ></landings-lets-breezit-section>
      </section>
    </main>

    <!-- <landings-landing-footer></landings-landing-footer> -->
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #landingHeader>
  <div class="landing-header">
    <div class="logo">
      <img alt="logo" src="assets/images/bzt-logo.svg" />
    </div>
    <div
      (click)="sidenav.open()"
      class="mobile-button-container"
      *ngIf="isMobile"
    >
      <mat-icon>menu</mat-icon>
    </div>
    <div class="provider-button-container" *ngIf="!isMobile">
      <ul class="section-navigation-list">
        <!-- <li (click)="navigateToSection('faq')">FAQ</li> -->
        <li>
          <a [href]="saasLandingLink" style="text-decoration: none"
            >Providing services?</a
          >
        </li>
        <li (click)="openLoginDialog()" class="header-login">Log in</li>
      </ul>
      <a href="https://breezit.com"></a>
      <button
        (click)="openContactFormDialog()"
        app-button
        class="accent-outline bottom-getstarted"
      >
        <span>{{ 'header.getStarted' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
