import { URLUtils } from '@jarvis/utils';

export const getPdfLink = (bookingId, serviceName, linkUrls) => {
  const detailLink = `${linkUrls.marketplace}/proposal/${encodeURIComponent(serviceName.replace(/ /g,'-'))}/${URLUtils.encodeUUID(bookingId)}`;
  return detailLink;
};

export const getShortenedProposalId = (bookingId) => {
  return URLUtils.encodeUUID(bookingId);
};
