import { Pipe, PipeTransform } from '@angular/core';
import { ExtractedServices } from '../services/pricing-calculator';

@Pipe({
  name: 'ServiceCharge',
  pure: false
})
export class JarvisServicesServiceChargePipe implements PipeTransform {
  transform(services: ExtractedServices, date?: Date): number {
    const flattened = [...services.main, ...services.additional];
    return flattened
      .filter((service) => service.included)
      .reduce((total, curr) => {
        total += curr.getServiceChargeWithoutTax(date);
        return total;
      }, 0);
  }
}
