import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { camelCaseToNormalText, kebabCaseToCamelCase, kebabCaseToSnakeCase, firstUpperCase, snakeCaseToCamelCase, camelCaseToKebabCase } from '../../caseConverters';

@Pipe({
    name: 'CamelToNormal',
    pure: true
})
export class CamelCaseToNormalTextPipe implements PipeTransform {
    transform(value: string): string {
        return camelCaseToNormalText(value);
    }
}

@Pipe({
    name: 'KebabToCamel'
})
export class KebabCaseToCamelCasePipe implements PipeTransform {
    transform(value: string): string {
        return kebabCaseToCamelCase(value);
    }
}

@Pipe({
    name: 'KebabToSnake'
})
export class KebabCaseToSnakeCasePipe implements PipeTransform {
    transform(value: string): string {
        return kebabCaseToSnakeCase(value);
    }
}

@Pipe({
    name: 'FirstUpperCase'
})
export class FirstUpperCasePipe implements PipeTransform {
    transform(value: string): string {
        return firstUpperCase(value);
    }
}

@Pipe({
    name: 'SnakeToCamel'
})
export class SnakeCaseToCamelCasePipe implements PipeTransform {
    transform(value: string): string {
        return snakeCaseToCamelCase(value);
    }
}

@Pipe({
  name: 'CamelToKebab'
})
export class CamelCaseToKebabCasePipe implements PipeTransform {
  transform(value: string): string {
      return camelCaseToKebabCase(value);
  }
}

@Pipe({
    name: 'PropKebabToSnake'
  })
  export class PropKebabCaseToSnakeCasePipe implements PipeTransform {
   
    transform (value: Observable<any>, propName: string): Observable<any> {
      return value.pipe(map(v=>({...v, [propName]: kebabCaseToSnakeCase(v[propName]) })));
    }    
  }
  