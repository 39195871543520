<jarvis-ui-modal-wrapper
  (closed)="close()"
  [closeButton]="!(hideClose || standalone)"
  class="modal-wrapper medium-modal"
>
  <div class="form-wrapper">
    <h1 *ngIf="!standalone && !hideTitle">{{ 'login.login' | translate }}</h1>
    <form [formGroup]="loginFormGroup">
      <p class="inner-header">
        {{ 'login.loginHeader' | translate }}
      </p>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'login.yourEmail' | translate }}</mat-label>
        <input matInput formControlName="email" />
        <mat-error>{{
          'errors.' + (emailErrors$ | async) | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'login.password' | translate }}</mat-label>
        <input
          #passInput
          autocomplete="current-password"
          type="password"
          matInput
          formControlName="password"
        />
        <mat-icon
          class="selectable"
          matSuffix
          (click)="
            passInput.type = passInput.type === 'password' ? 'text' : 'password'
          "
        >
          {{ passInput.type === 'password' ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-error *ngIf="passwordErrors$ | async as passwordError">{{
          'errors.' + passwordError | translate
        }}</mat-error>
      </mat-form-field>

      <div class="form-errors" *ngIf="formErrors$ | async as formErrorMessage">
        <mat-error>
          {{ 'errors.' + formErrorMessage | translate }}
          <a
            *ngIf="formErrorMessage === 'notVerified'"
            (click)="resendConfirmation()"
          >
            {{ 'login.newConfirmationEmail' | translate }}
          </a>
        </mat-error>
      </div>

      <div class="login-options-wrapper">
        <!-- <mat-checkbox formControlName="rememberMe">{{'login.rememberMe' | translate}}</mat-checkbox> -->
        <a routerLink="/auth/forgot-password" (click)="close()">{{
          'login.forgotPassword' | translate
        }}</a>
      </div>

      <button class="login-submit" app-button color="accent" (click)="login()">
        <span>{{ 'login.loginAction' | translate }}</span>
      </button>

      <div class="or">
        <span>{{ 'login.or' | translate }}</span>
        <div class="line"></div>
      </div>

      <!-- <button GoogleSigninButtonDirective  app-button (click)="registerWithGoogle()">
        <mat-icon svgIcon="google" prefix></mat-icon>
        <span>{{ 'login.continueWithGoogle' | translate }}</span>
      </button>

      <button
        app-button
        style="margin-bottom: 15px"
        (click)="registerWithFacebook()"
      >
        <mat-icon class="fb-icon" svgIcon="facebook" prefix></mat-icon>
        <span>{{ 'login.continueWithFacebook' | translate }}</span>
      </button> -->
      <div
        class="bztt-flex bztt-flex-row bztt-justify-center bztt-gap-5 bztt-my-5"
      >
        <div
          class="gaGoogleClicked bztt-w-[72px] bztt-flex bztt-flex-col bztt-items-center bztt-gap-2 bztt-p-3 bztt-bg-[#F9F9F9] bztt-rounded-lg bztt-cursor-pointer"
          (click)="registerWithGoogle()"
          GoogleSigninButtonDirective
        >
          <mat-icon svgIcon="google" prefix>
            <svg viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                <path d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" fill="#4285f4"></path><path d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" fill="#34a853"></path><path d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z" fill="#fbbc04"></path><path d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" fill="#ea4335"></path>
            </svg>            
          </mat-icon>
          <span class="bztt-text-xs">{{ 'register.google' | translate }}</span>
        </div>

        <div
          class="gaFacebookClicked bztt-w-[72px] bztt-flex bztt-flex-col bztt-items-center bztt-gap-2 bztt-p-3 bztt-bg-[#F9F9F9] bztt-rounded-lg bztt-cursor-pointer"
          (click)="registerWithFacebook()"
        >
          <img class="bztt-h-6" src="assets/icons/ic_facebook2.svg" />
          <span class="bztt-text-xs">{{
            'register.facebook' | translate
          }}</span>
        </div>
      </div>

      <!-- <button app-button >
        <mat-icon svgIcon="apple" prefix></mat-icon>
        <span>{{ 'login.continueWithApple' | translate }}</span>
      </button> -->

      <p class="inner-footer">
        <span>{{ 'login.dontHaveProfile' | translate }}</span>
        <a (click)="reopenRegister()">{{
          'login.registerAction' | translate
        }}</a>
      </p>
    </form>
  </div>
</jarvis-ui-modal-wrapper>
