import { ServiceBase } from '@jarvis/types';
import { TranslateService } from '@ngx-translate/core';

export const extractTextAddressFromService = (serviceBase: ServiceBase, translationService: TranslateService) => {

  let textAddress = null;

  if (serviceBase?.address?.area1) {
    const { area1, area2, serviceProvisionInAllCountry, city, country } = serviceBase.address;
    
    if (!serviceProvisionInAllCountry) {
      
      if (country?.toUpperCase() === 'US' && city && area2) {
        textAddress = `${city}, ${area2}`;
      } else if (city) {
        textAddress = `${city}, ${area1}`;
      } else if (area2 && country?.toUpperCase() !== 'LT') {
        textAddress = `${area2}, ${area1}`;
      } else {
        textAddress = `${area1}`;
      }

    } else {
      textAddress = `${area1} ${translationService.instant('listing.wholeCountry')}`;
    }
  } 

  return textAddress;
};
