import { Component, Input, OnChanges, Output, EventEmitter, OnInit, OnDestroy, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import {UntypedFormGroup,UntypedFormControl,UntypedFormArray,UntypedFormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { ServiceTypeCategoriesEnum, ServiceTypeCategoriesIconData } from '@jarvis/types';

@Component({
  selector: 'app-customer-needs',
  template: `
  <div class="d-flex flex-column h-100 justify-content-center overflow-hidden">
      <div class="w-100 balance-container d-flex flex-column align-items-center justify-content-between">
         <div class="d-flex w-100 flex-column align-items-center pt-4 pb-2">
            <h1 class="pb-1 fs-4">{{i18nKey + '.title' | translate}}</h1>
            <div class="description">{{i18nKey+'.description' | translate}}</div>
         </div>
  </div>

   <div class="h-auto mt-3 overflow-auto main-view">

   <div class="container">
   <div [formGroup]="referal" *ngFor="let referal of needs.controls; let i = index" class="row">
     <mat-form-field appearance="outline"  class="col form-field">
        <mat-label>{{i18nKey+'.serviceType' | translate}}</mat-label>

        <mat-select formControlName="serviceType">
            <mat-option [value]="serviceType" *ngFor="let serviceType of serviceTypeCategories;">
              <div class="d-flex align-items-center service-type-option"><img [src]="iconData[serviceType]" class="me-2"/><span>{{ ("categories." + serviceType) |  translate }}</span></div>
            </mat-option>
        </mat-select>

     </mat-form-field>

     <mat-form-field appearance="outline"  class="col form-field">
        <mat-label>{{i18nKey+'.budget' | translate}}</mat-label>
        <input type="number" matInput formControlName="budget">
     </mat-form-field>
     <div (click)="removeItem(i)" class="col-auto d-flex align-items-center pb-3"><mat-icon class="material-icons">delete</mat-icon></div>
     </div>
   </div>
   <div class="d-flex justify-content-between ps-4 pe-4 controls">
      <a [class.disabled]="!canAddNew()" class="d-flex fw-bolder align-items-center" (click)="addNeed()"><mat-icon class="material-icons">add</mat-icon>{{ i18nKey+'.addMore' | translate }}</a>
   </div>
  </div>


   <div class="d-flex bztt-mt-8 bztt-mb-10 justify-content-around">
      <button [disabled]="!canRequest()" (click)="request()" class="send-btn" type="button" app-button color="accent">
         <span>{{i18nKey+'.submit' | translate}}</span>
      </button>
   </div>

   </div>
  `,

  styles: [`
     .send-btn { max-width:400px; }
     .description { font-size: 14px; font-weight: normal; color: #717171;}
     .service-type-option img { height: 20px; width:20px; }
     .new-referal .mat-icon { margin-bottom: -8px; margin-top: -10px; margin-right:3px; }
     .controls .disabled  { opacity:0.5; pointer-events: none; }
     .send-btn[disabled]  { opacity:0.5; }
     .main-view {flex-grow: 1;  max-height: 600px; }
   `]
})

export class CustomerNeedsComponent {
  @Output() onAction = new EventEmitter<any>();
  @Input() i18nKey: string;
  @Input() data: { serviceType: ServiceTypeCategoriesEnum, budget: number }[];

  needs:UntypedFormArray = this.fb.array([]);
  iconData = ServiceTypeCategoriesIconData;

  constructor(private fb:UntypedFormBuilder) {
  }

  public canAddNew(): boolean {
    return (this.needs.length == 0 || this.needs.at(this.needs.length - 1).valid);
  }

  public canRequest(): boolean {
    return (this.needs.length > 0 && this.needs.at(this.needs.length - 1).valid);
  }

  public removeItem(idx) { this.needs.removeAt(idx); }


  public addNeed(value?: any) {
    const needsForm:UntypedFormGroup = this.fb.group({
      serviceType: ['', [Validators.required,]],
      budget: ['', [Validators.required, Validators.min(0)]]
    });
    this.needs.push(needsForm);
    if (value) needsForm.patchValue(value);
    else this.canAddNew();
  }

  public request(): void {
    if (this.needs.length > 0 && this.needs.at(this.needs.length - 1).invalid) this.needs.removeAt(this.needs.length - 1);
    this.onAction.emit(this.needs.value);
  };


  ngOnInit(): void {
    if (this.data) this.data.forEach(itm => this.addNeed(itm));
    else this.addNeed()
  }

  public get serviceTypeCategories(): ServiceTypeCategoriesEnum[] {
    return Object.keys(ServiceTypeCategoriesEnum)
      .filter(v => isNaN(Number(v)) === false)
      .map(key => ServiceTypeCategoriesEnum[key]);
  }

}
