export enum HEADER_MENU_ACTIONS {
  OPEN_SERVICES = 1,
  HANDLE_OPEN_PRICING,
  OPEN_SERVICES_WO_FEATURED,
  OPEN_EVENT_TYPE_DROPDOWN
}

export const HEADER_MENU_ITEMS_LT = [
  {
    path: 'sventes-vietos',
    label: 'venues',
    type: 'marketplace',
    // showArrow: true,
    // action: HEADER_MENU_ACTIONS.OPEN_EVENT_TYPE_DROPDOWN,
    state: {
      megaMenuNavigation: true
    }
  },
  {
    // path: 'services',
    label: 'services',
    icon: 'celebration',
    userType: 'user',
    action: HEADER_MENU_ACTIONS.OPEN_SERVICES,
    showArrow: true,
    auth: false
  },
  {
    path: 'planuoklis',
    label: 'planner',
    icon: 'favorite_outline',
    userType: 'user',
    auth: false
  }
];

export const HEADER_MENU_ITEMS_US = [
  {
    path: 'venues',
    label: 'venues',
    type: 'marketplace',
    state: {
      megaMenuNavigation: true
    }
  },
  {
    path: 'services',
    label: 'services',
    icon: 'celebration',
    userType: 'user',
    action: HEADER_MENU_ACTIONS.OPEN_SERVICES,
    showArrow: true,
    auth: false
  },
  {
    path: 'planner',
    label: 'planner',
    icon: 'favorite_outline',
    userType: 'user',
    auth: false
  },
  /* {
    path: 'service-providers/pricing',
    label: 'pricing',
    type: 'saas',
    auth: false,
    country: 'us'
  }, */
];
