<div
  class="bztt-flex bztt-flex-col bztt-gap-4 bztt-justify-center bztt-w-full bztt-items-center"
>
  <div
    class="bztt-font-sora bztt-text-[#1f1f1f] bztt-font-semibold bztt-text-lg bztt-text-start bztt-w-full"
  >
    {{ 'bookings.balanceEdit.title' | translate }}
  </div>
  <div
    class="bztt-font-sora bztt-text-[#1f1f1f] bztt-font-semibold d-flex w-100 fw-bolder justify-content-between md:pb-4 md:pt-3"
  >
    <div>{{ 'bookings.balanceEdit.cost' | translate }}</div>
    <div>
      {{
        booking.price - booking.tax
          | currency : currencyCode : 'symbol' : null : locale
      }}
    </div>
  </div>
  <div
    class="bztt-font-sora bztt-text-[#1f1f1f] bztt-font-semibold d-flex w-100 fw-bolder justify-content-between md:pb-4 md:pt-3"
    *ngIf="hasTaxes"
  >
    <div>{{ 'bookings.detail.price.tax' | translate }}</div>
    <div>
      {{
        booking.tax ?? 0 | currency : currencyCode : 'symbol' : null : locale
      }}
    </div>
  </div>
  <!-- { title: 'price.tax', value: detailData.tax ?? 0, hidden: !facts().hasTaxes } -->
  <app-d-form
    #dform
    i18nKey="bookings.balanceEdit"
    noButton="true"
    class="w-100"
    [model]="balanceEditData"
    [descriptor]="balanceEditForm"
    (change)="saveChanges()"
  >
  </app-d-form>

  <div class="bztt-flex bztt-justify-between bztt-w-full bztt-items-center bztt-py-4">
    <span class="bztt-font-semibold">
      {{ 'bookings.balanceEdit.balance' | translate }}
    </span>  
    <span class="bztt-font-semibold"> 
      {{
        booking.price -
          (dform.dformGroup.value.discount
            ? dform.dformGroup.value.discount
            : 0) - (booking.paid??0) - 
          (dform.dformGroup.value.paidCash ? dform.dformGroup.value.paidCash : 0) 
           | currency : currencyCode : 'symbol' : null : locale
      }}
    </span>
    <!-- <a
            app-button
            color="accent"
            (click)="saveChanges()"
            class="d-flex align-items-center text-center save-button bztt-text-sm bztt-leading-4"
          >
            {{ 'bookings.balanceEdit.save' | translate }}
          </a> -->
  </div>
</div>
