import { Pipe, PipeTransform } from '@angular/core';
import {
  ExtractedServices,
  ServiceTypes
} from '../services/pricing-calculator';

@Pipe({
  name: 'TotalServicePrice',
  pure: false
})
export class JarvisServicesTotalServicePricePipe implements PipeTransform {
  transform(
    services: ExtractedServices,
    date?: Date,
    withoutTax = false,
    cashback = 0
  ): number {
    const flattened = [...services.main, ...services.additional];
    const totPrice = flattened
      .filter(
        (service) =>
          service.type !== ServiceTypes.serviceCharge && service.included
      )
      .reduce((total, curr) => {
        total += !withoutTax
          ? curr.getFinalPriceWithCharges(date)
          : curr.getFinalPrice(date);
        return total;
      }, 0);

    const cashbackAbs = Math.abs(cashback);

    return totPrice - cashbackAbs < 0 ? 0 : totPrice - cashbackAbs;
  }
}
