<div [@slideContent]="animationState" (@slideContent.done)="close()">
  <marketplace-dropdown-wrapper>
    <jarvis-profile-dropdown-content></jarvis-profile-dropdown-content>

    <!-- <a (click)="navigateToSettings()">{{
      'header.profileSettings' | translate
    }}</a>
    <a (click)="openDemoRequest()">{{ 'header.requestDemo' | translate }}</a>
    <a routerLink="/rules-policies">{{ 'header.rulesPolicies' | translate }}</a>
    <a (click)="moveToSaas()">{{ 'header.becomeSeller' | translate }}</a>
    <a (click)="moveToMarketplace()">{{ 'header.becomeBuyer' | translate }}</a>
    <a (click)="logout()">{{ 'header.logout' | translate }}</a> -->
  </marketplace-dropdown-wrapper>
</div>
