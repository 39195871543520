import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { JarvisHeaderService } from './header.service';

@Component({
  selector: 'jarvis-ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('header', { read: ElementRef, static: true }) headerRef: ElementRef;


  headerPortal$ = this.headerService.headerPortal$;

  constructor(private headerService: JarvisHeaderService) { }

  ngOnInit(): void {
    this.headerService.headerRef = this.headerRef;
  }

}
