import { Component } from '@angular/core';
import { checkFormOrGroupInvalid } from '../../../../utils/profiler.utils';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';
import {UntypedFormControl } from '@angular/forms';

@Component({
  templateUrl: './promo-code.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class PromoCodeComponent {

  formControl:UntypedFormControl;
  
  constructor(
    private profilingFormService: JarvisProfilingFormService
  ) {
    this.formControl = this.profilingFormService.getStepControl('promoCode');
  }

  next(): void {
    const formValid = checkFormOrGroupInvalid(this.formControl);

    if (!formValid) {
      return;
    }

    this.profilingFormService.trackFieldCaptureEvent('promo_code');
    this.profilingFormService.navigateToStep('source');
  }

  skip(): void {
    this.formControl.reset();
    this.profilingFormService.trackFieldCaptureEvent('promo_code');
    this.profilingFormService.navigateToStep('source');
  }

}
