import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CardSliderComponent } from '@jarvis/foxy-vendor/app/shared/components/card-slider/card-slider.component';
import { LayoutService } from '@jarvis/foxy-vendor/app/shared/services/layout.service';

@Component({
  selector: 'app-intro-tutorial',
  templateUrl: './intro-tutorial.component.html',
  styleUrls: ['./intro-tutorial.component.scss']
})
export class IntroTutorialComponent implements OnInit {

  @ViewChild(CardSliderComponent) slider: CardSliderComponent;
  closeButton = false;
  isMobile$ = this.layoutService.isMobileObserver;

  constructor(
    @Optional() private dialogRef: MatDialogRef<IntroTutorialComponent>,
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    if (this.dialogRef) {
      this.closeButton = true;
    }
  }

  nextSlide(): void {
    this.slider.translateToNextCard();
  }

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

}
