import { Component, inject, ViewChild } from '@angular/core';
import { BookingsObjType, CustomerType } from '../../bookings.types';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import {
  FormDescriptor,
  DFormComponent,
  FormActionType,
} from '@jarvis/foxy-vendor/app/shared/components/d-form/d-form.component';
import { BookingsDataService } from '../../bookings.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BookingAction } from '@jarvis/types';
import { DOMAIN_COUNTRY } from '@jarvis/services';
import {
  ButtonModule,
  JarvisDFormModule,
  JarvisUiModalWrapperModule,
} from '@jarvis/ui';
import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  DateAdapter,
  MatNativeDateModule,
  NativeDateAdapter,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-select-service',
  templateUrl: './select-service.component.html',
  styleUrls: ['./select-service.component.scss'],
  standalone: true,
  providers: [
    { provide: DateAdapter, useClass: NativeDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  imports: [
    CommonModule,
    JarvisDFormModule,
    JarvisUiModalWrapperModule,
    TranslateModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacySelectModule,
    ReactiveFormsModule,
    ButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
  ],
})
export class SelectServiceComponent {
  @ViewChild('selectServiceForm') selectServiceForm;
  public model: any;

  private domainCountry = inject(DOMAIN_COUNTRY);
  private data: any[] = inject(MAT_LEGACY_DIALOG_DATA);
  private dialogRef = inject(DialogRef);

  constructor(
    private listService: BookingsDataService,
    private _router: Router
  ) {}

  public formGroup: UntypedFormGroup;

  public forms: { [key: string]: FormDescriptor<any> } = {
    newBooking: DFormComponent.newFormDescriptor<ExtCustomerType>({
      controls: {
        service: { type: 'select', trigger: 'serviceName', required: true },
        serviceType: { control: '1', required: true },
        name: { required: true },
        surname: { required: true },
        email: { type: 'email', required: true },
        phoneNo: { type: 'tel' },
        eventDate: { type: 'date' },
        channel: { type: 'select' },
        requestDate: { type: 'date' },
      },
    }),
  };

  public isCanSelectEventTypes(form: UntypedFormGroup): boolean {
    const count = form.get('service').value.typeCount > 1;
    const control = form.get('serviceType');
    if (count && control.disabled) control.enable();
    if (!count && !control.disabled) control.disable();

    return count;
  }

  public isInvalidForm(): boolean {
    return this.selectServiceForm
      ? this.selectServiceForm.dformGroup.invalid
      : true;
  }

  public submit() {
    this.selectServiceForm.onSubmit();
    this.dialogRef.close();
  }

  public getEventTypes(form: UntypedFormGroup): string[] {
    const value = form.get('service').value;
    const res = this.data
      .find((item) => item._id == value.serviceBaseId)
      .events.find((item) => item.serviceEventId == value.serviceEventId);
    return res.types;
  }

  close() {
    this._router.navigate(['bookings']);
    this.dialogRef.close();
  }

  public formAction(evnt: FormActionType<ExtCustomerType>) {
    if (evnt.type == 'cancel') {
      console.log('close');
      return;
    } else {
      const data: any = evnt.model;

      const booking: BookingsObjType = {
        serviceEventId: data.service.serviceEventId,
        serviceBaseId: data.service.serviceBaseId,
        serviceName: data.service.serviceName,
        options: {
          hideServiceType: data.service.typeCount == 1,
          contractSigned: false,
          bookingRequest: 'addedManually',
          channel: data.channel,
          requestDate: data.requestDate,
        },
        eventDate: data.eventDate,
        eventTillDate: data.eventDate
          ? moment(data.eventDate).endOf('day').toDate()
          : null,
        serviceType:
          data.service.typeCount > 1 ? data.serviceType : data.service.type,
        state: 'new',
        price: 0,
        paid: 0,
        paidCash: 0,
        deposit: 0,
        discount: 0,
        additions: [],
        details: [],
        customer: {
          type: 'person',
          name: data.name,
          surname: data.surname,
          email: data.email,
          phoneNo: data.phoneNo,
        } as CustomerType,
      };

      this.listService.createBooking(booking).subscribe((insertedItem) => {
        this._router.navigate(['bookings/detail', insertedItem._id, 'edit'], {
          state: { bookingAction: BookingAction.serviceEdit },
        });
      });
    }
  }

  public ngOnInit(): void {
    this.forms['newBooking'].controls['channel'].options = [
      { id: 'breezit', text: 'Breezit' },
      { id: 'breezit-email', text: 'Breezit el. paštu' },
      { id: 'breezit-phone', text: 'Breezit telefonu' },
      { id: 'planner_recommendation', text: 'Planuotojos rekomendacija' },
      { id: 'client_recommendation', text: 'Buvusio kliento rekomendacija' },
      { id: 'facebook', text: 'Facebook' },
      { id: 'instagram', text: 'Instagram' },
      { id: 'site', text: 'Asmeninis tinklapis' },
      { id: 'paslaugos.lt', text: 'Paslaugos.lt' },
      { id: 'other', text: 'Kita' },
    ];
    if (this.domainCountry !== 'lt')
      delete this.forms.newBooking.controls.channel;

    this.forms.newBooking.controls.service.options = this.data.map((item) => ({
      id: {
        serviceBaseId: item._id,
        serviceEventId: item.events[0].serviceEventId,
        type: item.events[0].types[0],
        typeCount: item.events[0].types.length,
        serviceName: item.name,
      },
      text: item.name,
      options: item.events
        .map((subitem) =>
          item.events.length != 1
            ? {
                id: {
                  serviceBaseId: item._id,
                  serviceEventId: subitem.serviceEventId,
                  typeCount: subitem.types.length,
                  type: subitem.types[0],
                  serviceName: subitem.name,
                },
                text: subitem.name,
              }
            : null
        )
        .filter((itm) => itm),
    }));

    if (this.forms.newBooking.controls.service.options?.length == 1)
      this.model = {
        service: this.forms.newBooking.controls.service.options[0].id,
      };
  }
}

interface ExtCustomerType extends CustomerType {
  surname: string;
  service: string;
  serviceType: string;
  eventDate?: Date;
  channel?: string;
  requestDate?: Date;
}
