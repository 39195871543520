import { ButtonModule, JarvisCommonDialogModule, PlayInViewDirective } from '@jarvis/ui';
import { SwiperModule } from 'swiper/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserWindowComponent } from './browser-window/browser-window.component';
import { ReviewsSlideComponent } from './reviews-slide/reviews-slide.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FunctionsSectionComponent } from './functions-section/functions-section.component';
import { LetsBreezitSectionComponent } from './lets-breezit-section/lets-breezit-section.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { LandingContactsService, LandingDialogService } from './services';
import { CONTACT_FORM_COMPONENT } from './contact-form/contact-form.token';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FaqComponent } from './faq/faq.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { PricingPopupDialogComponent } from './pricing-popup-dialog/pricing-popup-dialog.component';
import { ServicePipesModule } from '@jarvis/services';

@NgModule({
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    CommonModule,
    SwiperModule,
    ButtonModule,
    MatSelectModule,
    MatProgressBarModule,
    MatExpansionModule,
    RouterModule,
    TranslateModule.forChild(),
    JarvisCommonDialogModule,
    ServicePipesModule
  ],
  declarations: [
    BrowserWindowComponent,
    ReviewsSlideComponent,
    ContactFormComponent,
    FunctionsSectionComponent,
    LetsBreezitSectionComponent,
    FaqComponent,
    PlayInViewDirective,
    PricingPopupDialogComponent
  ],
  exports: [
    BrowserWindowComponent,
    ReviewsSlideComponent,
    ContactFormComponent,
    FunctionsSectionComponent,
    LetsBreezitSectionComponent,
    FaqComponent,
    PlayInViewDirective
  ],
  providers: [
    LandingContactsService,
    LandingDialogService,
    TranslatePipe,
    {
      provide: CONTACT_FORM_COMPONENT,
      useValue: ContactFormComponent,
    },
  ],
})
export class LandingsModule {}
