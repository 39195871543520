<div class="time-picker-container">
  <button
    class="bztt-w-full"
    (click)="timeSelect(timeValue)"
    [class.disabled]="timeValue | TimeValueDisabled: dateEvents:currentDate"
    [class.selected]="timeValue === selectedValue"
    *ngFor="let timeValue of timeValues | async"
  >
    {{ timeValue | MinuteTimeValue: (currentLocale$ | async) }}
  </button>
</div>
