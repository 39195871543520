import { Pipe, PipeTransform } from '@angular/core';
import { getYear } from 'date-fns';

@Pipe({
    name: 'CurrentYear',
    pure: true
})

export class CurrentYearPipe implements PipeTransform {
    transform(value: number | Date): boolean {
        const currentYear = getYear(new Date());
        if (typeof value === 'number') {
            return value === currentYear;
        }
        
        return getYear(value) === currentYear;
    }
}