import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'jarvis-auth-password-remind-modal',
  templateUrl: './password-remind-modal.component.html',
  styleUrls: ['./password-remind-modal.component.scss']
})
export class JarvisAuthPasswordRemindModalComponent implements OnInit {

  email: string;
  closeButton = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: { email: string },
    @Optional() private dialogRef: MatDialogRef<JarvisAuthPasswordRemindModalComponent>
  ) { }

  ngOnInit(): void {
    this.email = this.dialogData?.email;
    if (this.dialogRef) {
      this.closeButton = true;
    }
  }

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

}
