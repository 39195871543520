import {
  CdkOverlayOrigin,
  Overlay,
  OverlayConfig,
  OverlayModule,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NEVER, filter, merge, take } from 'rxjs';

type ScrollStrategies = 'noop' | 'block' | 'close' | 'reposition';

/**
 * Declarative overlay component.
 * Current usage:
 * - Use CDK Overlay origin directive to get anchor point
 * - Declare reference to this component to open it (set isOpen to true)
 * - Closing is automatic if scrolled, navigated or backdrop clicked
 *
 * TODO:
 * - Create convenience directive for anchoring
 * - Create convienience directive for open/closing
 * - Expose configuration API to set positions and other overlay config settings
 */
@Component({
  selector: 'jarvis-overlay',
  templateUrl: 'overlay.component.html',
  standalone: true,
  styles: [
    `
      :host {
        display: none;
      }
    `,
  ],
  imports: [CommonModule, OverlayModule],
})
export class JarvisOverlayComponent implements OnInit {
  @Input() originX: 'start' | 'end' | 'center' = 'end';
  @Input() originY: 'center' | 'bottom' | 'top' = 'bottom';
  @Input() overlayX: 'start' | 'end' | 'center' = 'start';
  @Input() overlayY: 'center' | 'bottom' | 'top' = 'top';
  @Input() offsetX = 0;
  @Input() offsetY = 10;
  @Input() connectedHeight: string | number | null = null;
  @Input() connectedWidth: string | number | null = null;

  @Input() type: 'global' | 'connected' = 'connected';

  @Input() withLockedPosition: boolean | null = null;
  @Input() withGrowAfterOpen: boolean | null = null;
  @Input() withPush: boolean | null = null;

  @Input() top: string = null;
  @Input() bottom: string = null;
  @Input() left: string = null;
  @Input() right: string = null;
  @Input() height = 'auto';

  @Input() backdrop = true;
  @Input() backdropClass: string;
  @Input() panelClass: string;

  @Input() scrollStrategy: ScrollStrategies = null;

  @Input() closeOnNavigation = true;

  @ViewChild('overlayView', { read: TemplateRef, static: true })
  overlayView: TemplateRef<unknown>;

  @Input() anchor: CdkOverlayOrigin;

  @Input() viewportMargin = 20;

  /* @Input()
  set anchor(anchor: unknown) {
    if (anchor instanceof CdkOverlayOrigin) {
      this._anchor = anchor;
      return;
    }

    // If simple div
    const thing = new CdkOverlayOrigin(anchor as any);
    this._anchor = thing;
  }
  get anchor(): CdkOverlayOrigin {
    return this._anchor;
  }
  private _anchor: CdkOverlayOrigin; */

  @Output() closed = new EventEmitter();

  private overlay = inject(Overlay);
  private vcr = inject(ViewContainerRef);
  private router = inject(Router);

  private overlayInstance: OverlayRef | null = null;

  ngOnInit(): void {
    const ref = this.createOverlay();
    this.overlayInstance = ref;
  }

  private createOverlay(customConfig: OverlayConfig = {}) {
    const positionStrategy = this.createPositionStrtategy();

    const overlayConfig = this.createOverlayConfig(
      positionStrategy,
      customConfig
    );

    const templatePortal = new TemplatePortal(this.overlayView, this.vcr);

    const overlayRef = this.overlay.create(overlayConfig);

    merge(
      overlayRef.backdropClick(),
      overlayRef.detachments(),
      this.closeOnNavigation
        ? this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
          )
        : NEVER
    )
      .pipe(take(1))
      .subscribe(() => {
        this.disposeOverlay();
      });

    overlayRef.attach(templatePortal);
    return overlayRef;
  }

  private createPositionStrtategy() {
    if (this.type === 'global') {
      let globalPositionStrategy = this.overlay.position().global();

      if (!this.top && !this.bottom && !this.left && !this.right) {
        return globalPositionStrategy.bottom('0');
      }

      if (this.top) {
        globalPositionStrategy = globalPositionStrategy.top(this.top);
      }

      if (this.bottom) {
        globalPositionStrategy = globalPositionStrategy.bottom(this.bottom);
      }

      if (this.left) {
        globalPositionStrategy = globalPositionStrategy.left(this.left);
      }

      if (this.right) {
        globalPositionStrategy = globalPositionStrategy.right(this.right);
      }

      return globalPositionStrategy;
    }

    let positionConfig = this.overlay
      .position()
      .flexibleConnectedTo(this.anchor.elementRef)
      .withPositions([
        {
          originX: this.originX,
          originY: this.originY,
          overlayX: this.overlayX,
          overlayY: this.overlayY,
          offsetY: this.offsetY,
          offsetX: this.offsetX
        },
      ])
      .withViewportMargin(this.viewportMargin);

    if (this.withLockedPosition) {
      positionConfig = positionConfig.withLockedPosition(this.withLockedPosition);
    }
    
    if (this.withGrowAfterOpen) {
      positionConfig = positionConfig.withGrowAfterOpen(this.withGrowAfterOpen);
    }
    
    if (this.withPush) {
      positionConfig = positionConfig.withPush(this.withPush);
    }

    return positionConfig;
  }

  private createOverlayConfig(
    positionStrategy: PositionStrategy,
    customConfig: OverlayConfig
  ): OverlayConfig {
    if (this.type === 'global') {
      const globalStrategyConfig: OverlayConfig = {
        width: '100%',
        height: this.height,
        positionStrategy,
        backdropClass: this.backdropClass,
        panelClass: this.panelClass,
        hasBackdrop: this.backdrop,
        scrollStrategy:
          this.getScrollStrategy(this.scrollStrategy) ||
          this.overlay.scrollStrategies.noop(),
        ...customConfig,
      };

      return globalStrategyConfig;
    }

    const connectedStrategyConfig: OverlayConfig = {
      positionStrategy,
      height: this.connectedHeight,
      width: this.connectedWidth,
      hasBackdrop: this.backdrop,
      backdropClass: this.backdropClass || 'cdk-overlay-transparent-backdrop',
      panelClass: this.panelClass,
      scrollStrategy:
        this.getScrollStrategy(this.scrollStrategy) ||
        this.overlay.scrollStrategies.close(),
      ...customConfig,
    };

    return connectedStrategyConfig;
  }

  private disposeOverlay(): void {
    if (this.overlayInstance) {
      this.overlayInstance.dispose();
      this.closed.emit();
      this.overlayInstance = null;
    }
  }

  private getScrollStrategy(strategy: ScrollStrategies) {
    switch (strategy) {
      case 'reposition':
        return this.overlay.scrollStrategies.reposition();
      case 'block':
        return this.overlay.scrollStrategies.block();
      case 'close':
        return this.overlay.scrollStrategies.close();
      case 'noop':
        return this.overlay.scrollStrategies.noop();
      default:
        return null;
    }
  }
}
