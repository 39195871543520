import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdvertsBaseForm } from './base-form.service.types';
import { BASE_URL } from '@jarvis/services';

@Injectable({ providedIn: 'root' })
export class AdvertsListingService {

    listingReload$ = new Subject<void>();

    servicesEndpoint = `${this.baseUrl}/serviceBase`;
    serviceEventEndpoint = `${this.baseUrl}/serviceEvent`;
    stripeConnectEndpoint = `${this.baseUrl}/stripe/retrieveAccount`;

    constructor(
        private httpService: HttpClient,
        @Inject(BASE_URL) private baseUrl: string
    ) { }

    reloadListings(): void {
        this.listingReload$.next();
    }

    // TODO - talk about mapping. I think we should change the logic in the backend to accomodate general
    getServices(): Observable<AdvertsBaseForm[]> {
        return this.httpService.get<AdvertsBaseForm[]>(this.servicesEndpoint).pipe(
            map((services: any[]) => {
              return services.map(oldService => {
                const serviceEvents = oldService.serviceEvents as any[];

                if (!serviceEvents) {
                    return oldService;
                }

                serviceEvents.forEach(serviceEvent => {
                  if (!serviceEvent.customPricing) {
                      serviceEvent.general = serviceEvent.wedding;
                      delete serviceEvent.wedding;
                  }
                });
                return oldService;
              });
            })
        );
    }

    getService(id: string): Observable<AdvertsBaseForm> {
        return this.httpService.get<AdvertsBaseForm>(`${this.servicesEndpoint}/${id}`).pipe(
            map((data: any) => {
              const serviceEvents = data.serviceEvents as any[];

              if (!serviceEvents) {
                  return data;
              }

              serviceEvents.forEach(serviceEvent => {
                if (!serviceEvent.customPricing) {
                    serviceEvent.general = serviceEvent.wedding;
                    delete serviceEvent.wedding;
                }
              });
              return data;
            })
        );
    }

    getServiceEvent(id: string): Observable<any> {
        return this.httpService.get(`${this.serviceEventEndpoint}/${id}`).pipe(
            map((data: any) => {
                if (!data.customPricing) {
                    data.general = data.wedding;
                    delete data.wedding;
                }
                return data;
            })
        );
    }

    saveBaseService(data: any): Observable<any> {
        return this.httpService.post(this.servicesEndpoint, data);
    }

    saveServiceEvent(data, overwritePricing = true): Observable<any> {
        if (!data.customPricing && overwritePricing) {
            data.wedding = data.general;
        }

        return this.httpService.post(this.serviceEventEndpoint, data);
    }

    getStripeInfo(profileId: string): Observable<any> {
        return this.httpService.get(`${this.stripeConnectEndpoint}/${profileId}`);
    }

    editBaseService(id: string, data: any): Observable<any> {
        return this.httpService.patch(`${this.servicesEndpoint}/${id}`, data);
    }

    editServiceEvent(id: string, data: any): Observable<any> {
        return this.httpService.patch(`${this.serviceEventEndpoint}/${id}`, data);
    }

    deleteServiceBase(id: string): Observable<any> {
        return this.httpService.delete(`${this.servicesEndpoint}/${id}`);
    }

    deleteServiceEvent(id: string): Observable<any> {
        return this.httpService.delete(`${this.serviceEventEndpoint}/${id}`);
    }
}
