import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CardImageCount',
  standalone: true
})

export class ServiceMarketplaceCardImageCountPipe implements PipeTransform {
  transform(value: any): number | null {
    
    const photos = value.venue?.photos;

    if (!photos || !photos.generalCount) {
      return null;
    }
    
    const count = photos.generalCount + photos.main.length + photos.videoUrls.length;
    return count;
  }
}