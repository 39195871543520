
import { Component, OnInit, inject } from '@angular/core';
import { categories } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { DOMAIN_COUNTRY } from '@jarvis/services';
import {
  CATEGORIES_V2_LT,
  CATEGORIES_V2_US,
} from '@jarvis/ui/marketplace-header/src/lib/data-access/service-type.data';
import { HOMEPAGE_CATEGORIES } from '../../services/homepage-uplifted.constants';

@Component({
  selector: 'jarvis-categories-section',
  templateUrl: './categories-section.component.html',
  styleUrls: ['./categories-section.component.scss'],
})
export class HomepageCategoriesSectionComponent implements OnInit {


  private domainCountry = inject(DOMAIN_COUNTRY);

  categories = HOMEPAGE_CATEGORIES[this.domainCountry].filter((item) =>
    this.domainCountry === 'us' ? item.showInCategories : true
  );

  constructor() {}

  ngOnInit(): void {}
}
