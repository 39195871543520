import { Component, Directive, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModalService } from '../services/modal.service';

@Directive()
export abstract class AbstractModalComponent<T> implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<AbstractModalComponent<T>>,
  	private modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: T
  	) {  }  

   public ngOnInit(): void {};

   public close(value?: any):void {this.dialogRef.close(value)};   
}	