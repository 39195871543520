import { DEFAULT_CURRENCY_CODE, Inject, Injectable } from '@angular/core';
import { ServiceBase } from '@jarvis/types';

@Injectable({providedIn: 'root'})

export class JarvisLocaleValuesService {
  constructor(@Inject(DEFAULT_CURRENCY_CODE) private defaultCurrency: string){}


  getLocaleValues(serviceBase: ServiceBase): { country:string, currencyCode:string, localeId:string, tz:string } {
      const country = serviceBase.address?.country || 'US';
      const currencyCode = country === 'US' ? 'USD' : 'EUR';
      const localeId = country === 'US' ? 'en-US' : 'lt-LT';
      const tz = country === 'US' ? 'PDT': 'EEST;'
      return {country,currencyCode, localeId, tz};
   }       
}	