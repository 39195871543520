import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditEventDetailsComponent } from './components/edit-event-details/edit-event-details.component';
import { BookingDetailsService } from '../../booking-details.service';
import { roundToTwoPrecision } from '@jarvis/utils';
import { BookingEditServicesListComponent } from './components/services-list/services-list.component';
import { ButtonModule } from '@jarvis/ui';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { BookingEditBalanceEditComponent } from './components/balance-edit/balance-edit.component';
import { SelectedServicesTotalPricePipe } from '../../pipes/shared-pipes.pipe';

export enum BookingEditSteps {
  EventDetails = 1,
  ServicesList = 2,
  BalanceEdit = 3,
}
@Component({
  selector: 'jarvis-booking-edit',
  standalone: true,
  imports: [
    CommonModule,
    EditEventDetailsComponent,
    BookingEditServicesListComponent,
    BookingEditBalanceEditComponent,
    ButtonModule,
    MatIconModule,
    TranslateModule,
    SelectedServicesTotalPricePipe
  ],
  templateUrl: './booking-edit.component.html',
  styleUrls: ['./booking-edit.component.scss'],
})
export class BookingEditComponent {
  @HostBinding('class.bztt-hidden') public isHidden = false;

  @Input() booking: any;
  @Output() proposalOpen = new EventEmitter<void>();

  @ViewChild(BookingEditBalanceEditComponent)
  balanceEditComponent: BookingEditBalanceEditComponent;

  private bookingDetailsService = inject(BookingDetailsService);

  currentStep: BookingEditSteps = BookingEditSteps.EventDetails;

  get detailData() {
    return this.bookingDetailsService.detailData;
  }

  get currencyCode() {
    return this.bookingDetailsService.currencyCode;
  }

  get locale() {
    return this.bookingDetailsService.locale;
  }

  get eventType() {
    return this.bookingDetailsService.getServiceType();
  }

  get deposit() {
    return this.bookingDetailsService.getDeposit();
  }

  get selectedServicesTotal() {
    return this.bookingDetailsService.getSelectedTotal();
  }

  get servicesEditData() {
    return this.bookingDetailsService.servicesEditData;
  }

  constructor() {}

  nextStep() {
    this.currentStep++;
  }

  previousStep() {
    if (this.currentStep === 1) return;
    this.currentStep--;
  }

  handleDialogHide(hide: any) {
    this.isHidden = hide;
  }

  handleProposalOpen() {
    this.proposalOpen.emit();
  }

  handleServiceDetailsListChange() {
    this.bookingDetailsService.saveServiceEditChanges();
  }

  handleBalanceEditChange(result) {

    if (!result) return;
    Object.assign(this.bookingDetailsService.detailData, result);

    if (this.bookingDetailsService.getDeposit() != result.deposit)
      this.bookingDetailsService.detailData.options.customDeposit = true;

    this.bookingDetailsService.detailData.paidCash =
      this.bookingDetailsService.detailData.paidCash -
      this.bookingDetailsService.detailData.paid;
    if (
      this.bookingDetailsService.detailData.paidCash > 0 &&
      this.bookingDetailsService.getTotalPaid() >=
        this.bookingDetailsService.detailData.deposit &&
      this.bookingDetailsService.facts.payment.notYetPaidState
    ) {
      this.bookingDetailsService.detailData.state = 'reserved';
      this.bookingDetailsService.prevState =
        this.bookingDetailsService.detailData.state;
    }
    this.bookingDetailsService.updateIfChanged();
  }
}
