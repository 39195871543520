import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, timer } from 'rxjs';
import { JarvisOverlayComponent } from '../overlay/overlay.component';
import { openCloseAnimation } from './snack-bar.animations';


@Component({
  selector: 'jarvis-snackbar',
  templateUrl: 'snack-bar.component.html',
  standalone: true,
  styles: [
    `
      :host {
        display: none;
      }
    `,
  ],
  imports: [
    CommonModule,
    JarvisOverlayComponent
  ],
  animations: [
    openCloseAnimation
  ]
})
export class JarvisSnackbarComponent implements OnInit, OnDestroy {
  
  @Input() duration = 3000;
  @Output() closed = new EventEmitter();

  isOpen = true;

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    timer(this.duration).subscribe(() => {
      this.handleClose();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  animationFinish(): void {
    if (!this.isOpen) {
      this.closed.emit();
    }
  }

  handleClose(): void {
    this.isOpen = false;
  }

}
