<p
  *ngIf="
    serviceVariant.originalService.additionalDescription && !customDescription
  "
>
  {{ serviceVariant.originalService.additionalDescription }}
</p>

<ng-container
  *ngIf="
    serviceVariant.description
      | ServiceDescriptionUnit
        : priceUnit
        : serviceVariant.type
        : serviceChargeType
        : customDescription
      | translate
        : {
            amount:
              overideMinimalBudget ||
                serviceVariant.customPrice ||
                serviceVariant.defaultPrice
              | currency : currencyCode : 'symbol' : '1.2-2' : locale,
            amountNum:
              serviceVariant.serviceCharge ||
              serviceVariant.customPrice ||
              serviceVariant.defaultPrice,
            priceUnit: accomodationDescription.priceUnit,
            maxPersonCount:
              Number(accomodationDescription.maxPersonCount) <
              serviceVariant.quantity
                ? serviceVariant.quantity
                : accomodationDescription.maxPersonCount,
            startPrice:
              accomodationDescription.startPrice
              | currency : currencyCode : 'symbol' : '1.2-2' : locale
          }
      | AddCustomDescription : serviceVariant : customDescription as desc
  "
>
  <p id="package-description" *ngIf="desc.trim()">
    {{ desc }}
  </p>
</ng-container>



<p
  *ngIf="
    !serviceVariant.description &&
    !serviceVariant.originalService.additionalDescription
  "
>
  {{ 'listing.selectService.moreInformationNotice' | translate }}
</p>
<!--  && !serviceVariant.quantityDisabled -->
<p *ngIf="serviceVariant.priceUnit === 'fixed'">
  {{ 'listing.selectService.venueFixedFee' | translate }}
</p>

<p *ngIf="showPricePerUnit && priceUnit === 'currency_per_person'">
  {{
    'listing.selectService.pricePerPerson'
      | translate
        : {
            v:
              finalUnitPrice
              | currency : currencyCode : 'symbol' : '1.2-2' : locale
          }
  }}
</p>

<p *ngIf="showPricePerUnit && priceUnit === 'hour'">
  {{
    'listing.selectService.pricePerHour'
      | translate
        : {
            v:
              finalUnitPrice
              | currency : currencyCode : 'symbol' : '1.2-2' : locale
          }
  }}
</p>

<p *ngIf="serviceVariant.originalService?.overtimeFrom != null">
  {{
    'listing.selectService.overtimeFrom'
      | translate
        : {
            overtimeValue:
              serviceVariant.originalService.overtimeFrom
              | OverTimeDisplay : locale
          }
  }}
</p>

<p *ngIf="currentMinimalBudget > 0 && !overideMinimalBudget">
  <ng-container [ngSwitch]="serviceVariant.priceUnit">
    <ng-container *ngSwitchCase="'hour'"
      >{{
        'listing.selectService.minHours'
          | translate : { v: currentMinimalBudget }
      }}
    </ng-container>

    <ng-container *ngSwitchCase="'currency_per_hour'"
      >{{
        'listing.selectService.minHours'
          | translate : { v: currentMinimalBudget }
      }}
    </ng-container>

    <ng-container *ngSwitchCase="'currency_per_unit'"
      >{{
        'listing.selectService.minAmount'
          | translate : { v: currentMinimalBudget }
      }}
    </ng-container>

    <ng-container *ngSwitchCase="'currency_per_weight'"
      >{{
        'listing.selectService.minAmount'
          | translate : { v: currentMinimalBudget }
      }}
    </ng-container>

    <ng-container *ngSwitchCase="'fixed_per_person'"
      >{{
        'listing.selectService.fixedPerPerson'
          | translate
            : {
                fixedFee:
                  currentMinimalBudget
                  | currency : currencyCode : 'symbol' : '1.2-2' : locale,
                pricePerPerson:
                  finalUnitPrice
                  | currency : currencyCode : 'symbol' : '1.2-2' : locale,
                startQuantityAt: serviceVariant.originalService.startQuantityAt
              }
      }}
    </ng-container>

    <ng-container *ngSwitchDefault
      >{{
        'listing.selectService.minBudget'
          | translate
            : {
                v:
                  currentMinimalBudget
                  | currency : currencyCode : 'symbol' : '1.2-2' : locale
              }
      }}
    </ng-container>
  </ng-container>
</p>
<p *ngIf="serviceVariant.priceUnit == 'per_hour_per_person'">
  {{
    'listing.selectService.pricePerHourPerPerson'
      | translate
        : {
            priceValue:
              orgService?.priceValue
              | currency : currencyCode : 'symbol' : '1.0' : locale,
            hourCount: orgService?.hourCount
          }
  }}
  <ng-container *ngIf="orgService?.additionalPriceEnabled"
    >{{
      'listing.selectService.pricePerHourPerPersonAdd'
        | translate
          : {
              additionalPriceValue:
                orgService?.additionalPriceValue
                | currency : currencyCode : 'symbol' : '1.0' : locale
            }
    }}
  </ng-container>
</p>

<p *ngIf="serviceVariant.additionalUnitPrice">
  {{
    'listing.selectService.addHourPrice'
      | translate
        : {
            v:
              serviceVariant.additionalUnitPrice
              | currency : currencyCode : 'symbol' : '1.2-2' : locale
          }
  }}
</p>

<p *ngIf="serviceVariant.originalService?.description?.hoursIncluded">
  {{
    'listing.selectService.hourCount'
      | translate
        : {
            hourCount: serviceVariant.originalService.description.hoursIncluded
          }
  }}
</p>

<ng-container *ngIf="!hideServiceChargeAndTaxDescription">
  <ng-container [ngSwitch]="taxChargeType">
    <p *ngSwitchCase="'taxCharge'">
      {{
        'listing.selectService.taxAndChargeIncluded'
          | translate : { tax, serviceCharge }
      }}
    </p>

    <p *ngSwitchCase="'tax'">
      {{ 'listing.selectService.taxIncluded' | translate : { tax } }}
    </p>

    <p *ngSwitchCase="'charge'">
      {{
        'listing.selectService.chargeIncluded' | translate : { serviceCharge }
      }}
    </p>
  </ng-container>
</ng-container>

<p
  *ngIf="
    (serviceVariant.type === 'venueCateringMenuMain' ||
      serviceVariant.type === 'venueCateringMenuAdditional') &&
    serviceVariant.additionalFixedCostFn() as rentCost
  "
>
  <ng-container [ngSwitch]="serviceVariant.additionalPriceUnit">
    <ng-container *ngSwitchCase="'fixed'"
      >{{
        'listing.selectService.venueRentPrice'
          | translate
            : {
                v:
                  rentCost
                  | currency : currencyCode : 'symbol' : '1.2-2' : locale
              }
      }}
    </ng-container>
    <ng-container *ngSwitchCase="'starting_price'"
      >{{
        'listing.selectService.venueRentStartingAtPrice'
          | translate
            : {
                v:
                  rentCost
                  | currency : currencyCode : 'symbol' : '1.2-2' : locale
              }
      }}
    </ng-container>
  </ng-container>
</p>

<p
  *ngIf="
    (serviceVariant.type === 'venueCateringMenuMain' ||
      serviceVariant.type === 'venueCateringMenuAdditional') &&
    serviceVariant.additionalUnitFixedCostFn() as rentCost
  "
>
  {{
    'listing.selectService.venueRentPricePerPerson'
      | translate
        : {
            v: rentCost | currency : currencyCode : 'symbol' : '1.2-2' : locale
          }
  }}
</p>

<p *ngIf="serviceVariant.excludedFromGlobalMinBudget">
  {{ 'listing.selectService.minimumGlobalBudgetDeducted' | translate }}
</p>
