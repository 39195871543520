import { ElementRef, Injectable, Injector, OnDestroy, StaticProvider, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { filter, map, take } from 'rxjs/operators';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { NavigationEnd, Router } from '@angular/router';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { MOBILE_MENU_ACTIONS_REF } from './mobile-menu-actions.token';

const MOBILE_WIDTH = 768;
const LAPTOP_WIDTH = 1024;

interface MobileOverlayOptions {
  width?: string;
  positionStrategy?: PositionStrategy;
  viewContainerRef?: ViewContainerRef
}

@Injectable({ providedIn: 'root' })
export class LayoutService implements OnDestroy {
  isMobile: boolean;
  isMobileObserver: Observable<boolean>;

  hideNearMeSubject = new BehaviorSubject<boolean>(false);
  hideNearMe$ = this.hideNearMeSubject.asObservable();

  isLaptop: boolean;
  isLaptopObserver: Observable<boolean>;
  windowResize = fromEvent(window, 'resize');

  disableOneGlobalScrollEvent = false;

  private mobileBreakpointObserver = this.breakpointObserver.observe([`(max-width: ${MOBILE_WIDTH}px)`]);
  private laptopBreakpointObserver = this.breakpointObserver.observe([`(max-width: ${LAPTOP_WIDTH}px)`]);

  mobileMenuComponentPortal = new BehaviorSubject<ComponentPortal<any>>(null);

  private footerEnabled = new BehaviorSubject(true);
  footerEnabled$ = this.footerEnabled.asObservable();

  private footerUpperMargin = new BehaviorSubject(true);
  footerUpperMargin$ = this.footerUpperMargin.asObservable();

  private headerEnabled = new BehaviorSubject(true);
  headerEnabled$ = this.headerEnabled.asObservable();

  private mobileStickyMenuReference: ElementRef = null;
  private currentMobileActionsComponent = null;
  private currentMobileActionsReference: OverlayRef = null;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private overlay: Overlay
  ) {
    this.isMobileObserver = this.mobileBreakpointObserver.pipe(
      map(state => state.matches)
    );

    this.isLaptopObserver = this.laptopBreakpointObserver.pipe(
      map(state => state.matches)
    );

    this.mobileBreakpointObserver.subscribe(state => {
      this.isMobile = state.matches ? true : false;
    });

    this.laptopBreakpointObserver.subscribe(state => {
      this.isLaptop = state.matches ? true : false;
    })

    // console.log('Layout initiated');
  }

  ngOnDestroy(): void {
    // console.log('Layout destroyed');
    this.mobileMenuComponentPortal.complete();
    this.footerEnabled.complete();
    this.headerEnabled.complete();
  }

  enableFooter(): void {
    this.footerEnabled.next(true);
  }

  disableFooter(): void {
    this.footerEnabled.next(false);
  }

  enableFooterMargin(): void {
    this.footerUpperMargin.next(true);
  }

  disableFooterMargin(): void {
    this.footerUpperMargin.next(false);
  }

  enableHeader(): void {
    this.headerEnabled.next(true);
  }

  disableHeader(): void {
    this.headerEnabled.next(false);
  }

  createOverlay(component: Type<any>, connectedTo: ElementRef): void {
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(connectedTo)
        .withPositions([{
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }])
        .withDefaultOffsetY(5)
    });

    const componentPortal = new ComponentPortal(component);

    merge(
      overlayRef.backdropClick(),
      overlayRef.detachments(),
      this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    ).pipe(take(1)).subscribe(() => {
      overlayRef.detach();
    });

    overlayRef.attach(componentPortal);
  }

  createMobileMenuOverlay(componentOrTemplate: Type<unknown> | TemplateRef<unknown>, options: MobileOverlayOptions): OverlayRef {
    const overlayConfig: OverlayConfig = {
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: options?.positionStrategy || this.overlay.position().global(),
    };

    if (this.isMobile) {
      // overlayConfig.width = '100vw';
      overlayConfig.panelClass = 'jarvis-overlay-no-bottom'
    }

    const overlayRef = this.overlay.create(overlayConfig);

    const injectorOptions: { providers: StaticProvider[], parent?: Injector } = {
      providers: [
        {
          provide: MOBILE_MENU_ACTIONS_REF,
          useValue: overlayRef
        }
      ]
    };

    if (options?.viewContainerRef) {
      injectorOptions.parent = options.viewContainerRef.injector;
    }

    const injector = Injector.create(injectorOptions);

    let portal: TemplatePortal | ComponentPortal<unknown>;
    if (componentOrTemplate instanceof TemplateRef) {
      portal = new TemplatePortal(componentOrTemplate, options?.viewContainerRef);
    } else {
      portal = new ComponentPortal(componentOrTemplate, options?.viewContainerRef, injector);
    }


    merge(
      overlayRef.backdropClick(),
      overlayRef.detachments(),
      this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    ).pipe(take(1)).subscribe(() => {
      overlayRef.detach();
    });
    const componentRef = overlayRef.attach(portal);
    const closeEventSubject = componentRef.instance?.close$;

    if (closeEventSubject && closeEventSubject instanceof Subject) {
      closeEventSubject.pipe(
        take(1)
      ).subscribe(() => {
        overlayRef.detach();
      });
    }

    return overlayRef;
  }

  setMobileMenu(componentOrPortal: ComponentPortal<any>): void {
    this.mobileMenuComponentPortal.next(componentOrPortal);
  }

  removeMobileMenu(): void {
    this.mobileMenuComponentPortal.next(null);
  }

  registerMobileMenuReference(reference: ElementRef): void {
    this.mobileStickyMenuReference = reference;
  }

  unregisterMobileMenuReference(): void {
    this.mobileStickyMenuReference = null;
  }

  openMobileStickyActions(componentOrTemplate: Type<unknown> | TemplateRef<unknown>, viewContainerRef?: ViewContainerRef, customPosition?: 'top') {
    if (this.currentMobileActionsComponent === componentOrTemplate) {
      return this.currentMobileActionsReference;
    }

    if (this.currentMobileActionsReference) {
      this.currentMobileActionsReference.detach();
    }

    this.currentMobileActionsComponent = componentOrTemplate;

    /* const position = this.overlay.position().flexibleConnectedTo(
        this.mobileStickyMenuReference
    ).withPositions([
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
        }
    ]); */

    // TODO - global setting (sets menu height and actions bottom part start position);
    const pxFromBottom = this.mobileMenuComponentPortal.value ? '80px' : '0px';
    const position = customPosition === 'top' ? this.overlay.position().global().top() : this.overlay.position().global().bottom(pxFromBottom);


    const ref = this.createMobileMenuOverlay(componentOrTemplate, {
      positionStrategy: position,
      viewContainerRef
    });
    this.currentMobileActionsReference = ref;

    ref.detachments().pipe(
      take(1)
    ).subscribe(() => {
      this.currentMobileActionsComponent = null;
      this.currentMobileActionsReference = null;
    });

    return ref;
  }

  hideActionsMenu(): void {
    const ref = this.mobileStickyMenuReference?.nativeElement;
    if (ref) {
      this.mobileStickyMenuReference.nativeElement.classList.add('hidden');
    }

    // this.mobileStickyMenuReference.nativeElement.classList.add('hidden');
  }

  showActionsMenu(): void {
    const ref = this.mobileStickyMenuReference?.nativeElement;
    if (ref) {
      this.mobileStickyMenuReference.nativeElement.classList.remove('hidden');
    }

    // this.mobileStickyMenuReference.nativeElement.classList.remove('hidden');
  }
}
