import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EventHostHomepageService } from '../../services/event-host-homepage.service';

@Component({
  selector: 'jarvis-homepage-calendar',
  templateUrl: './homepage-calendar.component.html',
  styleUrls: ['./homepage-calendar.component.scss']
})
export class HomepageCalendarComponent {

  constructor(
    private dialogRef: MatDialogRef<HomepageCalendarComponent>,
    public homepageService: EventHostHomepageService,
  ) { }

  close() {
    this.dialogRef.close()
  }

  handleWeddingDateSelect(date) {
    this.homepageService.eventDateControl.setValue(date)
    this.dialogRef.close()
  }

}
