import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jarvis-ui-marketplace-card-default-info',
  templateUrl: 'default-info.component.html'
})

export class JarvisUiMarketplaceCardDefaultInfoComponent implements OnInit {
    @Input() data;

    constructor() { }
    
    ngOnInit() { }
}