import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './components/list/list.component';
import { DetailComponent } from './components/detail/detail.component';
//import { CauseOfCancellationComponent } from './components/detail/cause-of-cancellation.component';
import { CustomerMailComponent } from './components/detail/customer-mail.component';
import { ReferalEditComponent } from './components/detail/referal.component';
import { SelectServiceComponent } from './components/select-service/select-service.component';
import { AgreementViewComponent } from './components/agreement-view/agreement-view.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  BookingsListResolver,
  CurrentUserResolver,
  BookingsDetailResolver,
  ServiceBaseResolver,
} from './bookings.resolver';
import { BookingsDataService } from './bookings.service';
import { SearchFilterPipe } from './pipes/search.pipe';
import { GroupValuesPipe } from './pipes/group-values.pipe';
import { PersonInitialsPipe } from './pipes/person-initials.pipe';
import {
  JarvisUiRulesPoliciesModule,
  JarvisCauseOfCancellationModule,
  JarvisUiActionsCardModule,
  JarvisCardWrapperModule,
  JarvisUiModalWrapperModule,
  JarvisDFormModule,
  JarvisFileUploaderModule,
  JarvisTimePickerModule,
  ButtonModule,
  JarvisModalComponent,
  JarvisOverlayComponent,
  PackageDescriptionComponent,
} from '@jarvis/ui';
import { ServicesService } from '@jarvis/services';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ReviewsService } from '@jarvis/reviews';
import { ServiceCurrencyPipe, ServicePipesModule } from '@jarvis/services';
import { JarvisUiServiceListModule } from '@jarvis/ui';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { JarvisUiPaginatorModule } from '@jarvis/ui/paginator';
import { ServiceDescriptionsPipe } from './pipes/service-descriptions.pipe';
import { CalendarDateFormatterService } from '@jarvis/ui/src/lib/components/small-calendar/services/calendar-date-formatter.service';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { ContainsValuesPipe } from '@jarvis/utils';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { MatInputModule } from '@angular/material/input';
import { BookingEditComponent } from './components/booking-edit/booking-edit.component';
import { ProposalSendModalComponent } from './components/proposal-send-modal/proposal-send-modal.component';
import { CotnractSendModalComponent } from './components/contract-send-modal/contract-send-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ArrayFilterPipe, SortToEndPipe } from './pipes/shared-pipes.pipe';
import { AddSignatureComponent } from './components/add-signature/add-signature.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';

const ROUTES: Routes = [
  {
    path: '',
    resolve: {
      serviceBase: ServiceBaseResolver,
      currentUser: CurrentUserResolver,
    },
    component: ListComponent,
  },
  {
    path: 'detail/:id',
    resolve: {
      detail: BookingsDetailResolver,
      currentUser: CurrentUserResolver,
    },
    component: DetailComponent,
  },
  {
    path: 'detail/:id/edit',
    resolve: {
      detail: BookingsDetailResolver,
      openEdit: () => true,
      currentUser: CurrentUserResolver,
    },
    component: DetailComponent,
  },
];

@NgModule({
  declarations: [
    ListComponent,
    DetailComponent,
    ServicesListComponent,
    AgreementViewComponent,
    SearchFilterPipe,
    GroupValuesPipe,
    CustomerMailComponent,
    ReferalEditComponent,
    PersonInitialsPipe,
    ContactInfoComponent,
    AddSignatureComponent,
  ],
  imports: [
    CommonModule,
    // SharedModule,
    MatBadgeModule,
    MatMenuModule,
    PdfViewerModule,
    MatRadioModule,
    MatExpansionModule,
    MatAutocompleteModule,
    JarvisCardWrapperModule,
    ServicePipesModule,
    JarvisUiPaginatorModule,
    ClipboardModule,
    JarvisUiActionsCardModule,
    JarvisCauseOfCancellationModule,
    JarvisUiServiceListModule,
    JarvisUiRulesPoliciesModule,
    TranslateModule.forChild(),
    RouterModule.forChild(ROUTES),
    MatIconModule,
    FormsModule,
    TextFieldModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    JarvisUiModalWrapperModule,
    JarvisDFormModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    JarvisFileUploaderModule,
    JarvisTimePickerModule,
    MatTooltipModule,
    MatInputModule,
    ButtonModule,
    JarvisModalComponent,
    JarvisUiModalWrapperModule,
    BookingEditComponent,
    JarvisCardWrapperModule,
    ServiceDescriptionsPipe,
    ContainsValuesPipe,
    JarvisFileUploaderModule,
    ProposalSendModalComponent,
    CotnractSendModalComponent,
    JarvisOverlayComponent,
    MatDialogModule,
    SelectServiceComponent,
    ArrayFilterPipe,
    SortToEndPipe,
    PackageDescriptionComponent,
  ],
  exports: [RouterModule],

  providers: [
    BookingsListResolver,
    ServiceCurrencyPipe,
    BookingsDetailResolver,
    CurrentUserResolver,
    ServiceBaseResolver,
    BookingsDataService,
    CalendarDateFormatterService,
    ReviewsService,
    ServicesService,
    TranslatePipe,
    MatSnackBar,
    MatDatepickerModule,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
  ],

  bootstrap: [AgreementViewComponent, DetailComponent],
})
export class SavedBookingsModule {}
