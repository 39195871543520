<div
  class="bztt-flex bztt-flex-col bztt-justify-center bztt-items-center bztt-h-full bztt-p-10"
>
  <div
    class="bztt-max-w-[440px] bztt-flex bztt-flex-col bztt-justify-center bztt-items-center"
  >
    <img
      src="/assets/images/bzt-logo.svg"
      alt="Breezit Logo"
      width="90px"
      class="bztt-mb-10 "
    />

    <div class="title bztt-text-base bztt-font-bold bztt-text-black bztt-mb-4 bztt-text-center">
      {{ (data + '.title') | translate:{serviceName} }}
    </div>

    <div
      class="description bztt-text-sm bztt-text-gray-400 bztt-text-center bztt-mb-9"
    >
      {{ (data+ '.description') | translate:{serviceName} }}
    </div>
  </div>
  <jarvis-auth-register [standalone]="true"></jarvis-auth-register>
</div>
