<app-card-wrapper>
    <span slot="header">{{'termsConditions.header' | translate}}</span>
    <div class="app-terms-data">
        <div class="terms-container">
            <nav>
                <ul class="nav-list">
                    <li *ngFor="let item of buttons">
                        <button type="button" [class.active-button]="item === activeButton" class="button-link"
                            (click)="changeActiveTab(item)">
                            {{ item.label | translate }}
                        </button>
                    </li>
                </ul>
                <!-- <div class="nav-select">
                    <mat-select [disableRipple]="true" [ngClass]="'mat-custom-select'" [value]="activeButton"
                        (selectionChange)="changeActiveTab($event.value)">
                        <mat-option *ngFor="let item of buttons" [value]="item">
                            {{ item.label }}
                        </mat-option>
                    </mat-select>
                </div> -->
            </nav>
            <section [ngSwitch]="activeButton.value" class="terms-content">
                <app-rules *ngSwitchCase="'rules'"></app-rules>
                <app-privacy *ngSwitchCase="'privacy'"></app-privacy>
                <app-cookies *ngSwitchCase="'cookies'"></app-cookies>
            </section>
        </div>
    </div>

</app-card-wrapper>