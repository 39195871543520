import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'landings-pricing-faq',
  templateUrl: './pricing-faq.component.html',
  styleUrls: ['./pricing-faq.component.scss']
})
export class PricingFaqComponent implements OnInit {

  faqConfiguration = {
    vendor: [
      {
        title: 'pricingPage.FAQ.items.item1.title',
        description: 'pricingPage.FAQ.items.item1.description'
      },
      {
        title: 'pricingPage.FAQ.items.item2.title',
        description: 'pricingPage.FAQ.items.item2.description'
      },
      {
        title: 'pricingPage.FAQ.items.item3.title',
        description: 'pricingPage.FAQ.items.item3.description'
      },
      {
        title: 'pricingPage.FAQ.items.item4.title',
        description: 'pricingPage.FAQ.items.item4.description'
      },
      {
        title: 'pricingPage.FAQ.items.item5.title',
        description: 'pricingPage.FAQ.items.item5.description'
      },
      {
        title: 'pricingPage.FAQ.items.item6.title',
        description: 'pricingPage.FAQ.items.item6.description'
      },
      {
        title: 'pricingPage.FAQ.items.item7.title',
        description: 'pricingPage.FAQ.items.item7.description'
      },
      {
        title: 'pricingPage.FAQ.items.item8.title',
        description: 'pricingPage.FAQ.items.item8.description'
      }
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
