<app-modal-wrapper
  (closed)="handleClose()"
  [closeButton]="true"
  class="modal-wrapper"
>
  <div class="bztt-flex bztt-justify-center">
    <ng-container *ngIf="!serviceTypeSelectorActive; else typeSelector">
      <ng-container *ngIf="!customVendorForm; else customVendorFormTemplate">
        <div class="bztt-flex bztt-flex-col bztt-justify-center">
          <div
            class="bztt-text-xl bztt-font-semibold bztt-my-7 bztt-max-w-sm bztt-text-center"
          >
            {{ 'plannerPage.addPlannerItem.findOrAdd' | translate }}
          </div>

          <div class="bztt-flex bztt-flex-col bztt-justify-center bztt-gap-5">
            <div
              class="bztt-flex bztt-flex-col bztt-justify-center bztt-items-center bztt-gap-3 bztt-rounded-lg bztt-border bztt-border-gray-900 bztt-p-4 bztt-cursor-pointer"
              (click)="redirectToFeaturedVendors()"
              matRipple
            >
              <mat-icon class="bztt-text-3xl bztt-h-[30px] bztt-w-[30px]"
                >search</mat-icon
              >
              <div class="bztt-font-semibold">
                {{ 'plannerPage.addPlannerItem.findMore' | translate }}
              </div>
              <div class="bztt-text-xs bztt-text-gray-600">
                {{
                  'plannerPage.addPlannerItem.findMoreDescription' | translate
                }}
              </div>
            </div>

            <div
              class="bztt-flex bztt-flex-col bztt-justify-center bztt-items-center bztt-gap-3 bztt-rounded-lg bztt-border bztt-border-gray-900 bztt-p-4 bztt-cursor-pointer"
              (click)="addCustomVendor()"
              matRipple
            >
              <mat-icon class="bztt-text-3xl bztt-h-[30px] bztt-w-[30px]"
                >add</mat-icon
              >
              <div class="bztt-font-semibold">
                {{ 'plannerPage.addPlannerItem.addOwn' | translate }}
              </div>
              <div class="bztt-text-xs bztt-text-gray-600">
                {{ 'plannerPage.addPlannerItem.addOwnDescription' | translate }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</app-modal-wrapper>

<ng-template #typeSelector>
  <jarvis-service-type-selector
    [disableFeatured]="true"
    [skipMobileCheck]="true"
    [rearrangeItems]="true"
    (typeSelected)="handleClose()"
  ></jarvis-service-type-selector>
</ng-template>

<ng-template #customVendorFormTemplate>
  <div
    class="bztt-flex bztt-flex-col bztt-gap-y-4 bztt-justify-center lg:bztt-px-8 bztt-max-w-[500px] lg:bztt-w-[500px] bztt-h-full"
  >
    <div class="bztt-text-lg bztt-font-semibold bztt-text-start">
      {{ 'plannerPage.addPlannerItem.addNewVendor' | translate }}
    </div>
    <div class="bztt-text-sm bztt-text-[#6B6060] bztt-text-start">
      {{ 'plannerPage.addPlannerItem.addNewVendorDescription' | translate }}
    </div>

    <div class="bztt-flex bztt-flex-col bztt-w-full bztt-gap-2">
      <mat-form-field appearance="outline">
        <mat-label>{{
          'plannerPage.addPlannerItem.listingUrl' | translate
        }}</mat-label>
        <input
          matInput
          [formControl]="customVendorForm.get('url')"
          placeholder="http..."
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          'plannerPage.addPlannerItem.vendorName' | translate
        }}</mat-label>
        <input matInput [formControl]="customVendorForm.get('vendorName')" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          'plannerPage.addPlannerItem.contactName' | translate
        }}</mat-label>
        <input matInput [formControl]="customVendorForm.get('contactName')" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          'plannerPage.addPlannerItem.budget' | translate
        }}</mat-label>
        <input
          type="number"
          matInput
          [formControl]="customVendorForm.get('budget')"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          'plannerPage.addPlannerItem.email' | translate
        }}</mat-label>
        <input matInput [formControl]="customVendorForm.get('email')" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          'plannerPage.addPlannerItem.phone' | translate
        }}</mat-label>
        <input matInput [formControl]="customVendorForm.get('phoneNumber')" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          'plannerPage.addPlannerItem.category' | translate
        }}</mat-label>
        <mat-select [formControl]="customVendorForm.get('category')">
          <mat-option
            *ngFor="let category of categoryOptions"
            [value]="category"
          >
            {{ ('categories.' + category | KebabToSnake) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          'plannerPage.addPlannerItem.location' | translate
        }}</mat-label>
        <input matInput [formControl]="customVendorForm.get('location')" />
      </mat-form-field>
    </div>

    <div class="bztt-flex">
      <button
        app-button
        color="accent"
        class="bztt-w-full bztt-px-14 bztt-my-4"
        [disabled]="customVendorForm.invalid"
        (click)="save()"
      >
        {{ 'plannerPage.addPlannerItem.save' | translate }}
      </button>
    </div>
  </div>
</ng-template>
