import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { take } from 'rxjs/operators';
import { LayoutService } from '../../../layout';
import { DefaultModalComponent } from '../components/default-modal/default-modal.component';

@Injectable({providedIn: 'root'})
export class JarvisUiModalService {
    constructor(
        private dialog: MatDialog,
        private layoutService: LayoutService
    ) { }

    openDialog<T>(component: ComponentType<T>, config?: MatDialogConfig): MatDialogRef<T> {
        const mobileConfig: MatDialogConfig = {
            panelClass: 'modalMostTop',
            ...config,
            /* height: '100%',
            minHeight: '100vh',
            maxWidth: 'none' */
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%'
        };

        const isMobile = this.layoutService.isMobile;

        const ref = this.dialog.open(component, isMobile ? mobileConfig : config);
        
        if (isMobile) {
            this.layoutService.hideActionsMenu();

            ref.afterClosed().pipe(
                take(1)
            ).subscribe(() => {
                if (this.dialog.openDialogs.length === 0) {
                    this.layoutService.showActionsMenu();
                }
            });
        }

        return ref;
    }

    openDefaultModal(outlet$, options?): MatDialogRef<unknown> {

        const config = options?.config || {};

        const data = {
            ...config?.data,
            outlet$
        }

        const mobileConfig: MatDialogConfig = {
            panelClass: 'modalMostTop',
            ...config,
            data,
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
        };

        const isMobile = this.layoutService.isMobile;

        const ref = this.dialog.open(DefaultModalComponent, isMobile ? mobileConfig : config);
        
        if (isMobile) {
            this.layoutService.hideActionsMenu();

            ref.afterClosed().pipe(
                take(1)
            ).subscribe(() => {
                this.layoutService.showActionsMenu();
            });
        }

        return ref;
    }
}