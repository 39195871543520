import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { JarvisValidators, PasswordRepeatErrorMatcher } from '@jarvis/utils';
import { JarvisAuthService } from '../../services/auth.service';

@Component({
  selector: 'jarvis-auth-password-change-modal',
  templateUrl: './password-change-modal.component.html',
  styleUrls: ['./password-change-modal.component.scss']
})
export class JarvisAuthPasswordChangeModalComponent implements OnInit {

  closeButton = false;

  passwordChangeForm = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required, JarvisValidators.passwordComplexityValidation]),
    passwordRepeat: new UntypedFormControl('', [Validators.required, JarvisValidators.passwordComplexityValidation])
  }, {
    validators: [JarvisValidators.repeatPasswordValidation]
  });

  passwordError$ = JarvisValidators.generateErrorStateMatcher(this.passwordChangeForm, 'password');
  passwordRepeatError$ = JarvisValidators.generateErrorStateMatcher(this.passwordChangeForm, 'passwordRepeat');
  formErrors$ = JarvisValidators.generateErrorStateMatcher(this.passwordChangeForm, '');

  passwordRepeatErrorMatcher = new PasswordRepeatErrorMatcher();

  private hash: string;

  constructor(
    @Optional() private dialogRef: MatDialogRef<JarvisAuthPasswordChangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private authService: JarvisAuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.dialogRef) {
      this.closeButton = true;
    }

    this.hash = this.data.hash;
  }

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  changePassword(): void {
    if (this.passwordChangeForm.invalid) {
      this.passwordChangeForm.markAllAsTouched();
      return;
    }

    const newPassword = this.passwordChangeForm.get('password').value;

    this.authService.changeForgottenPassword(this.hash, newPassword).subscribe(
      () => {
        this.router.navigate(['']);
        this.dialogRef.close();
      },
      (error) => {
        this.passwordChangeForm.setErrors(
          error?.error?.statusCode == 404?{linkAlreadyUsed:true}:{somethingWrong: true});
      }       
    );
  }

}
