import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@jarvis/foxy-vendor/environments/environment';
import {
  BookingsObjType,
  BookingsResult,
  BookingsType,
  CustomerInviteType
} from './bookings.types';
import { ServiceBase } from '@jarvis/foxy-vendor/app/core/types/adverts/ServiceBase.interface';
import { BASE_URL, LinkUrls, LINK_URLS } from '@jarvis/services';
import { BookingAction, Referals, ServiceTypeCategoriesEnum } from '@jarvis/types';
import { MessagingService } from '@jarvis/messaging';
import { Router } from '@angular/router';
import { CommonDialogService } from '@jarvis/ui';

@Injectable()
export class BookingsDataService {
  public static pageSize = 20;
  public currentPage = 1;

  servicesEndpoint = `${environment.baseUrl}api`;
  servicesMockEndpoint = '/mock/bookings';

  private commonDialogService = inject(CommonDialogService);
  private messagingService = inject(MessagingService);
  private router = inject(Router);
  private linkUrls: LinkUrls = inject(LINK_URLS);

  constructor(
    private httpService: HttpClient,
    @Inject(BASE_URL) private baseUrl: string
  ) { }

  getBookingsListMock(): Observable<BookingsType[]> {
    return this.httpService.get<BookingsType[]>(
      `${this.servicesMockEndpoint}/list`
    );
  }

  getBookingsList(
    page: number = 1,
    size: number = 500,
    addParam: object = null,
    sort: string =null, 
    sortOrder: number = null,
    showDeleted = false
  ): Observable<BookingsResult> {
    const params = new HttpParams({
      fromObject: Object.assign(
        { page, size },  {sort}, {sortOrder}, {showDeleted},
        addParam ? { additionalParams: JSON.stringify(addParam) } : null)
    });
    return this.httpService.get<BookingsResult>(
      `${this.servicesEndpoint}/booking`, { params } //;?page=${page}&size=${size}&additionalParams=${addParam}`
    );
  }

  getServiceBase(): Observable<ServiceBase[]> {
    return this.httpService.get<ServiceBase[]>(
      `${this.servicesEndpoint}/serviceBase`
    );
  }

  getServiceBaseReduced(name:string): Observable<ServiceBase[]> {
    return this.httpService.get<ServiceBase[]>(
      `${this.servicesEndpoint}/serviceBase/reduced/${name}`
    );
  }

  getDetail(id: string): Observable<BookingsType> {
    return this.httpService.get<BookingsType>(
      `${this.servicesEndpoint}/booking/${id}`
    );
  }

  getNotRegisteredDetail(id: string): Observable<BookingsType> {
    return this.httpService.get<BookingsType>(
      `${this.servicesEndpoint}/common/bookingPDF/${id}`
    );
  }

  createInvite(email: string, id: string): Observable<CustomerInviteType> {
    return this.httpService.post<CustomerInviteType>(
      `${this.servicesEndpoint}/invite/createInvite`,
      { bookingId: id, email: email }
    );
  }

  changeServiceBaseProp(id: string, data: Object): Observable<any> {
    return this.httpService.patch<any>(
      `${this.servicesEndpoint}/serviceBase/${id}`,
      data
    );
  }

  updateBookingDates(data: Object): Observable<any> {
    return this.httpService.post<any>(
      `${this.servicesEndpoint}/booking/updateBookingDates`,
      data
    );
  }

  updateBookingDatesUnregistered(data: Object): Observable<any> {
    return this.httpService.post<any>(
      `${this.servicesEndpoint}/booking/updateBookingDates`,
      data
    );
  }

  deleteBooking(id: string): Observable<BookingsType> {
    return this.httpService.delete<BookingsType>(
      `${this.servicesEndpoint}/booking/${id}`
    );
  }

  cancelBooking(data: BookingsObjType): Observable<BookingsType> {
    return this.httpService.patch<any>(
      `${this.servicesEndpoint}/booking/${data._id}`, {options:data.options})
      .pipe(switchMap(()=>
        this.httpService.get<BookingsType>(
          `${this.servicesEndpoint}/booking/cancelBooking/${data._id}`))
      );
  }

  restoreBooking(data: BookingsObjType): Observable<BookingsType> {
    return this.httpService.post<BookingsType>(
      `${this.servicesEndpoint}/booking/restoreBooking/${data._id}`,
      {
        state: data.options.stateAtCancellation ?? 'new',
        calendarType: 'booking'
      }
    );
  }

  getStripeAccount(profileId: string): Observable<any> {
    return this.httpService.get(
      `${environment.baseUrl}api/stripe/retrieveAccount/${profileId}`
    );
  }

  createBooking(data: BookingsObjType): Observable<any> {
    return this.httpService.post(`${this.servicesEndpoint}/booking`, data);
  }

  saveBookingReferals(
    bookingId: string,
    serviceType: keyof typeof ServiceTypeCategoriesEnum,
    serviceTypeName: string,
    referals: Referals[]
  ): Observable<any> {
    return this.httpService.post(`${this.servicesEndpoint}/bookingReferals`, {
      bookingId,
      serviceType,
      serviceTypeName,
      referals
    });
  }

  bookingReferalsList(
    serviceType: keyof typeof ServiceTypeCategoriesEnum
  ): Observable<any> {
    return this.httpService
      .get(`${this.servicesEndpoint}/bookingReferals`)
      .pipe(
        map((v) =>
          (v as any[]).filter(
            (itm) => !serviceType || itm.serviceType == serviceType
          )
        )
      );
  }

  bookingNotification(bookingId: string, flow: string): Observable<any> {
    return this.httpService.post<any>(
      `${this.servicesEndpoint}/booking/bookingNotification`,
      { bookingId, flow }
    );
  }

  startChatRoom(userId: string, serviceBase: string, bookingId: string) {
    return this.httpService.post<any>(`${this.baseUrl}/chat/startChat`, {
      userId,
      serviceBase,
      bookingId
    });
  }

  changeState(data: BookingsObjType): Observable<any> {
    return this.httpService.patch<any>(
      `${this.servicesEndpoint}/booking/${data._id}`,
      data
    );
  }

  changeStateUnregistered(data: BookingsObjType): Observable<any> {
    return this.httpService.patch<any>(
      `${this.servicesEndpoint}/booking/bookingEditConcierge/${data._id}`,
      data
    );
  }

  getCalendar(serviceEventId: string, from: Date, to: Date): Observable<any> {
    return this.httpService.get(`${this.baseUrl}/marketplace/calendar`, {
      params: {
        id: serviceEventId,
        dateFrom: `${from.getTime()}`,
        dateTo: `${to.getTime()}`
      }
    });
  }

  createProposal(booking: BookingsType): Observable<string> {
    return this.httpService.post<BookingsType>(
      `${this.servicesEndpoint}/bookingProposal/`, 
      {booking:booking._id, bookingData: booking}
    ).pipe(map(result=>result._id as string));
  }

  signContract(booking: BookingsType, name: string): Observable<any> {
    return this.httpService.post<BookingsType>(
      `${this.servicesEndpoint}/file/signPdfContract`, 
      {booking:booking._id, name}
    )
  }  

  getProposalLink(id:string, mode=""):string {
    return `${this.linkUrls.marketplace}/full-content/proposal/${id}${mode}`;
  } 


  public toChatRoom(item: BookingsType) {
    if (!item.customerId && !item.customer.email) {
      this.commonDialogService
        .openConfirmRx('bookings.list.messageWarning')
        .pipe(
          switchMap((v) => (v === 1 ? of(v) : EMPTY)),
          tap((v) =>
            this.router.navigateByUrl(`/bookings/detail/${item._id}`, {
              state: {
                bookingAction: BookingAction.inviteCustomer
              }
            })
          )
        )
        .subscribe();
      return;
    }
    if (item.chatRoom) this.router.navigate(['/messages', item.chatRoom._id]);
    else {
      
      const userId = item.customerId?._id;
      const serviceBaseId = item.serviceBaseId as string;
      if (userId)
        this.messagingService
          .startChatroomWithUser(userId, serviceBaseId, null, item._id)
          .subscribe((chatRoom) =>
            this.router.navigate(['/messages', chatRoom[0]._id])
          );
      else 
        this.messagingService
          .startChatroomWOUser(item._id)
          .subscribe((chatRoom) =>
            this.router.navigate(['/messages', chatRoom[0]._id])
          );
    }  
  }

}
