import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'landings-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  @Input() type;

  faqConfiguration = {
    vendor: [
      {
        title: 'How do I get started with Breezit?',
        description: `Easy, simply click get started at the top right of this website if you’re on a desktop or
        in the menu section if you’re on mobile. You’ll need to create your service profile by answering a few
        questions. Once that’s done, you’re good to go. Update your calendar, share your personal profile link
         and showcase your services with an outstanding webpage.`
      },
      {
        title: 'How does the free trial work?',
        description: `Great news, we’re still in the early access stage. This means that Breezit is free for the
        first 3 months after opening the account. There are no credit cards, upfront payments, or other fees
         for you to give Breezit a fair shot without any commitments.`
      }, {
        title: 'What are the fees for using Breezit?',
        description: `After the free trial period, Breezit will have a small monthly
        membership between $20 - $40, depending on your service category.`
      },
      {
        title: 'Are there additional fees for payment processing?',
        description: `We use Stripe to collect and manage payments.
        All processed card payments will be credited to your associated Stripe
        account and paid out to your bank account. Stripe fees are passed through
        directly to you as follows: Card processing fee: 2.9% + $0.3 per transaction.
        Payout fee: 0.25% + $0.25 per transaction.`
      },
      {
        title: 'When will I receive customer payments? ',
        description: `The payments will be credited to your
        associated Stripe account immediately, but Stripe takes up
        to 2 business days to credit them to your bank account.
        `
      },
      {
        title: 'How can I reach support?',
        description: `You can reach support at support@breezit.com`
      },
      {
        title: 'How many listings can I have?',
        description: `As many as you want.`
      },
      {
        title: 'Can customers immediately book my services?',
        description: `No. You control the booking process. When you get a booking request,
         you can adjust the quote, add different services, change prices, or even use custom agreements.`
      },
      {
        title: 'Can I have a single unified calendar to manage my availability across all channels?',
        description: `Yes! You can use Breezit calendar to manage your availability across
        your profile link and service webpage. You can also share your link and webpage in your emails,
         website, and social media. In this way, you’ll make sure that your calendar management is
         smooth and you never get double booked.`
      },
      {
        title: 'Do I need to communicate with customers through the Breezit platform?',
        description: `No. You can communicate with your customers in any way you want.
         Your profile link and service page have all your contact details: email,
         phone number, website, and social links.`
      },
      {
        title: 'Can I set my own cancellation policy?',
        description: `Yes. You are always in charge of your bookings and can
        easily set up and edit your cancellations, deposits, and payment terms.`
      },
      {
        title: 'Can I use my own service agreement?',
        description: `Yes. However, if you need a template, Breezit has a standardized agreement
        generated automatically with all details during the booking process. This simplifies the
        whole process and ensures that nothing falls through the cracks.`
      },
      {
        title: 'Can I add my own direct bookings to Breezit?',
        description: `Yes, you can easily add all your direct bookings by going into the
         Booking section and clicking on New Booking at the top right. You can then invite your
          client to manage their booking with you with Breezit.`
      },
    ],
    user: [
      {
        title: 'Postponed',
        description: `Postponed`
      }
    ]
  }

  constructor() { }

  ngOnInit(): void {
  }

}
