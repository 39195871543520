import { ServiceBase } from './service-base.types';
import { ServiceEvents } from './service-events.types';
import { AdvertServiceVariant, ServiceCurrencyPipe } from '@jarvis/services';
import { BookingActionUIState, BookingAction } from './booking-actions.types';
import { CustomerNeeds } from './referals.types';
import * as moment from 'moment';
import { inject } from '@angular/core';
import { JarvisMeasurementUnitCurrencyPipe } from 'libs/services/src/lib/services/measurement-units/measurement-unit-currenyc.pipe';
import { TranslateService } from '@ngx-translate/core';

/***** Choice of bookings state options ******/
export enum BookingStateEnum {
  new = "",
  communication = "",
  meetingBooked = "",
  proposalSubmitted = "",  
  signing = "",
  payment = "",
  reserved = "",
  completed = "",
  cancelled = "",
}

export enum BookingOrderByEnum {
    createdAtDesc,        
    eventDateAsc,
    eventDateDesc,
  }

export type BookingStateKeys = keyof typeof BookingStateEnum | "deleted" | "canceledByCustomer" | "pendingPayment";

export enum ServiceTypeEnum {
    wedding = "wedding",
    business = "business",
    private = "private",
    general = "general",
    privateEvent = "privateEvent",
    businessEvent = "businessEvent"
}

export type ServiceTypeKeys = keyof typeof ServiceTypeEnum;

export enum BookingRequestEnum {
    viaListing = "viaListing",
    viaLink = "viaLink",
    addedManually = "addedManually",
    viaPersonalListing = "viaPersonalListing",
    addedViaEmail = "addedViaEmail"
  }

export type BookingRequestKeys = keyof typeof BookingRequestEnum;

/***** Structure for storing detailed booking data in the database *****/
export interface BookingDetailsType {
    /*** Main data ***/
    name: string;
    qnt: number;
    additionalQnt?: number;
    unit: string;
    price: number;
    maxQuantity: number;
    minQuantity: number;
    minimalBudget: number;
    description?: string;
    hash: string;
    /*** Additional data ***/
    isCustomPrice: boolean;
    serviceVariant?: AdvertServiceVariant;
    alwaysIncluded?: boolean;
    savedMinimalBudget?: number;
  }
  


/***** Structure for storing data in a database *****/
export interface BookingsObjType {
  _id?: string;

  /*** Information about the client and his terms ***/
  customer?: CustomerType;
  customerId?: UserInfoType;
  eventDate?: Date;
  eventTillDate?: Date;
  location?: string;

  /*** Service booking information ***/
  serviceName: string;
  serviceType: ServiceTypeKeys;
  serviceBaseId: string | ServiceBase;
  serviceEventId: string | ServiceEvents;
  serviceEvent?: ServiceEvents;
  details: BookingDetailsType[];

  /*** Financial information and booking status ***/
  state: BookingStateKeys;
  price: number;
  paid: number;
  paidCash: number;
  discount: number;
  deposit: number;
  serviceCharge?: number;
  tax?: number;

  /*** Additional information ***/
  notes?: string;
  userNotes?: string;
  additions: AdditionFileType[];
  options: BookingOptions;
  customerNeeds?: CustomerNeeds;
}


/***** Structure for use in frontend *****/
export interface BookingsType extends BookingsObjType {
  /*** information retrieved from the database ***/
  msgCount?: number;
  newMsgCount?: number;
  stripeAccountId?: string;
  history: HistoryOfChangesType[];
  invite?: CustomerInviteType[];
  chatRoom?: ChatRoomType;
  vendor?: VendorType;
  review?: any;
  createdAt?: Date;
  updatedAt?: Date;
}


export interface CustomerInviteType {
  _id: string;
  email: string;
  hash: string;
  status: string;
  sendEmail?:boolean;
}

export interface ChatRoomType {
  _id: string;
  notified: string[];
  readBy: string[];
  messagesSentBy?: string[];
  serviceBase: string;
  users: string[];
}

export interface HistoryOfChangesType {
  changeDate: Date;
  description: string;
}

export interface AdditionFileType {
  url: string,
  filename: string
}

export enum CustomerTypeEnum { person = "", company = "" }
export type CustomerTypeKeys = keyof typeof CustomerTypeEnum;

export interface BookingOptions {
    hideServiceType: boolean;
    calendarId?: string;
    customAgreement?: string;
    contractSigned?: boolean;
    contractVendorSigned?: boolean;
    bookingRequest?: BookingRequestKeys;
    causeOfCancellation?: CauseOfCancellation;
    otherCancelCause?: string;
    stateAtCancellation?: string;
    customDeposit?: boolean;
    partnerId?: string;
    stateQueue?: BookingStateKeys[];
    anonUserMessage?: string;
    guestNo?: number;
    noEventDate?: boolean;
    eventTypeInFilter?: string;  
    channel?:string;
    requestDate?: Date;
    viaRecommended?: boolean;
    adminData?: any;
    eventTimeFrom?: Date;
    eventTimeTo?: Date;
    proposalValidityPeriod?: number;
    noProposalValidity?: boolean;
    proposalSent?:boolean;
    contractSent?:boolean;
    bookedByProposal?:boolean;
    proposal?: string;
}

export interface CustomerType {
  _id?: string;
  name: string;
  type: CustomerTypeKeys;
  email?: string;
  avatar?: string;
  phoneNo?: string;
  entityCode?: string;
  address?: string;
  surname?: string;
}

export interface VendorType {
  _id: string;
  email: string;
  emailVerified: boolean;
  profilePhoto?: string;
  userType: string;
}

export interface CustomerPersonType extends CustomerType {
  surname: string;
}

export interface CustomerCompanyType extends CustomerType {
  vatCode?: string;
  nameOfRepresentative?: string;
  surnameOfRepresentative?: string;
  addressCorrespondence?: string;
}

export interface UserInfoType {
    _id: string;
    email?: string;
    profilePhoto?: string;
    userType: string;
    name?: string;
    surname?: string;
  }

export enum CauseOfCancellation {
  eventCanceled,
  eventRescheduled,
  personalReason,

  customerDisappeared,
  disagreedOnService,
  disagreedOnPrice,
  alreadyBooked,
  chosenAnotherProvider,
  canceledByCustomer,

  providerDisappeared,
  canceled,
  other
}

export enum ContractStateType {
  standard,
  custom,
  customDefined,
  customUploaded
}

/***** Helpers *****/
export interface ServiceListType {
  _id: string;
  name: string;
  timeScheduling: boolean;
  timePerClient?: number;
  type?: string;
  events: {
    types: ServiceTypeKeys[];
    name: string;
    serviceEventId: string;
  }[]

}

export interface BookingsResult {
  count: number;
  distinct: {_id:string, count:number}[];
  docs: BookingsType[];
}

export interface BookingFacts 
  {
    contract: string,
    waitingSigning: boolean,
    canChangeContract: boolean,
    deposit: {
      required: boolean,
      payed: boolean,
      fullAdvance: boolean
     },
     payment: {
      notYetPaidState: boolean,
      paidFullAmount: boolean,            
      value: number
     },
     canInvite: boolean,
     empty: boolean,
     emptyDetail: boolean,     
     cancelled: boolean,
     canCancel: boolean
     cancelledRequest: boolean,
     canDelete: boolean,
     canRestore: boolean,
     canReview: boolean,
     hasReview: boolean,
     reserved: boolean,
     replaceSigningState: boolean,          
     country: string,
     hasTaxes: boolean,
     action?: BookingAction, 
     enabled: boolean
  }  


// TODO: Types lib should not contain implementation logic
export abstract class BookingsUtil {

  public static hashCode = (s, m) => ((m ? "m" : "a") + "_" + s).split('').reduce((a, b) => { a = ((a << 5) - a) + b.charCodeAt(0); return a & a; }, 0).toString();

  public static getCustomerName(item: CustomerType, profile: UserInfoType): string {
    if (profile && profile.name && profile.surname) return profile.name + ' ' + profile.surname;
    if ('surname' in item && item.name && (item as CustomerPersonType).surname) 
      return (item as CustomerPersonType).name + ' ' + (item as CustomerPersonType).surname;
    return item.email??item.phoneNo;
    }

  public static isStateVisible(state: BookingStateKeys, facts: any) {
    return !(state == "signing" && facts.contract == "standard");
  }

  public static getUIState(bookingObj: BookingsType, serviceBase: ServiceBase, mode: ("user" | "vendor" | "facts")): BookingFacts {

    let result: BookingActionUIState | any = { action: null, enabled: true };

    function checkFact(name: string): boolean {
      switch (name) {
        case "canInvite": return !(bookingObj.customerId);
        case "reqDeposit": return bookingObj.deposit > 0 && !serviceBase?.advances?.fullAdvance;
        case "isFullAdvance": return serviceBase?.advances?.fullAdvance;
        case "isDepositPayed": return bookingObj.deposit <= (bookingObj.paid + bookingObj.paidCash ?? 0);
        case "isEmptyDetail": return bookingObj.price == 0;
        case "isDateAssigned": return bookingObj.eventDate != null;
        case "replaceSigningState": return (bookingObj.options.stateQueue && bookingObj.options.stateQueue.includes("payment") &&
          bookingObj.options?.contractSigned && bookingObj.state == 'signing') || bookingObj.state == 'payment';
        case "isNoVendorActionStart": return ["new", "communication"].includes(bookingObj.state);
        case "notYetPaidState": return ["new", "communication", "meetingBooked","proposalSubmitted","signed"].includes(bookingObj.state);
        case "canChangeToPayment": return checkFact("notYetPaidState") && checkFact('isContractSigned');
        case "isPaidFullAmount": return bookingObj.price - bookingObj.discount - bookingObj.paid - (bookingObj.paidCash ?? 0) <= 0;
        case "isContractSigned": return (serviceBase.contractExample?.ourContract ?? false) || (bookingObj.options?.contractSigned ?? false);
        case "isCustomContractSigned": return checkFact('isCustomConractDefined') && (bookingObj.options?.contractSigned ?? false);
        case "isStandardContract": return serviceBase.contractExample?.ourContract;
        case "isCustomConractDefined": return (serviceBase.contractExample?.contractExampleFiles?.length > 0) ||
          bookingObj.options?.customAgreement != null;
        case "canChangeContract": return checkFact("notYetPaidState") && (bookingObj.paid + bookingObj.paidCash ?? 0) == 0 && !bookingObj.options?.contractSigned;
        case "reqVendorContract": return !checkFact("isStandardContract") && !checkFact("isCustomConractDefined");
        case "canSignContract": return checkFact("notYetPaidState") && checkFact("isCustomConractDefined");
        case "isWaitingSigning": return bookingObj.state == 'signing' && !bookingObj.options?.contractSigned && checkFact('isCustomConractDefined');
        case "canCancel": return !["cancelled", "deleted", "completed"].includes(bookingObj.state);
        case "canDelete": return true; /*(["new", "cancelled"].includes(bookingObj.state) && //bookingObj.options.bookingRequest == "addedManually" && 
                                   !bookingObj?.invite?.length
                                   );*/
        case "canRestore": return ["cancelled", "canceledByCustomer"].includes(bookingObj.state);
        case "canReview": return ["completed"].includes(BookingsUtil.transformState(bookingObj)) && !bookingObj.review;
        case "hasReview": return ["completed"].includes(BookingsUtil.transformState(bookingObj)) && bookingObj.review;
        case "isReserved": return ["reserved", "completed"].includes(bookingObj.state);
        case "isTaxApplies": return (serviceBase.type == "venues") && ((serviceBase.address?.country?.toUpperCase()??'US') == 'US');
        case "isCancelledRequest": return checkFact('canRestore') && !(["reserved", "completed"].includes(bookingObj.options?.stateAtCancellation));
        case "inCanSignAgreement": return ["new", "meetingBooked","proposalSubmitted", 
                                           "communication", "signing"].includes(bookingObj.state) &&
          (!checkFact('isStandardContract') && !bookingObj.options?.contractSigned);
      }
      return false;      


    }
    result.visible = !(checkFact('isEmptyDetail') || ["deleted"].includes(bookingObj.state));
    switch (mode) {
      case "facts":
        result = {
          contract: checkFact('isCustomConractDefined') ? "custom" :
            checkFact('isStandardContract') ? "standard" : "unknown",
          waitingSigning: checkFact('isWaitingSigning'),
          canChangeContract: checkFact('canChangeContract'),
          deposit: {
            required: checkFact('reqDeposit'),
            payed: checkFact('isDepositPayed'),
            fullAdvance: checkFact('isFullAdvance')
          },
          payment: {
            notYetPaidState: checkFact("notYetPaidState"),
            paidFullAmount: checkFact('isPaidFullAmount'),            
            value: (bookingObj.paid + bookingObj.paidCash ?? 0)
          },
          canInvite: checkFact('canInvite'),
          empty: checkFact('isEmptyDetail') || !checkFact('isDateAssigned'),
          emptyDetail: checkFact('isEmptyDetail'),
          cancelled: checkFact('canRestore'),
          canCancel: checkFact('canCancel'),
          cancelledRequest: checkFact('isCancelledRequest'),
          canDelete: checkFact('canDelete'),
          canRestore: checkFact('canRestore'),
          canReview: checkFact('canReview'),
          hasReview: checkFact('hasReview'),
          reserved: checkFact('isReserved'),
          replaceSigningState: checkFact('replaceSigningState'),          
          country: serviceBase.address?.country?.toUpperCase()??'US',
          hasTaxes: checkFact('isTaxApplies')
        };
        break;
      case "user":
        switch (bookingObj.state) {
          case "completed": if (checkFact('canReview')) result = { ...result, title: "bookings.detail.reviewButton", action: BookingAction.review };
          else result.visible = false;
            break;

          case "reserved": if (checkFact('isPaidFullAmount')) result = { ...result, title: "bookings.detail.reviewButton", enabled: false };
          else result = { ...result, title: "bookings.detail.askButton.pay", action: BookingAction.pay };
            break;

          case "payment": if (checkFact('isDepositPayed')) result = { ...result, title: "bookings.detail.askButton.pay", action: BookingAction.pay };
          else result = { ...result, title: "bookings.detail.askButton.deposit", action: BookingAction.pay };
            break;

          case "signing": if (checkFact('isContractSigned'))
            if (checkFact('isDepositPayed')) result = { ...result, title: "bookings.detail.askButton.pay", action: BookingAction.pay };
            else result = { ...result, title: "bookings.detail.askButton.deposit", action: BookingAction.pay };
          else result = { ...result, title: "bookings.detail.contractSigning", action: BookingAction.signContract };
            break;
          case "canceledByCustomer":
          case "cancelled": result.title = "bookings.state.cancelled.name"; result = { ...result, enabled: false }; break;
          case "new":
          case "meetingBooked":
          case "proposalSubmitted":            
          case "communication": if (checkFact('isNoVendorActionStart')) result = { ...result, title: "bookings.detail.wantToBook", action: BookingAction.wantToBook };
          else if (checkFact('reqDeposit')) result = { ...result, title: "bookings.detail.askButton.deposit", action: BookingAction.pay };
          else result = { ...result, title: "bookings.detail.askButton.pay", action: BookingAction.pay };
            break;
        }
        break;
      case "vendor":

        switch (bookingObj.state) {
          case "completed": if (checkFact('canReview')) result = { ...result, title: "bookings.detail.reviewButton", action: BookingAction.review };
          else result.visible = false;
            break;
          case "reserved": if (checkFact('isPaidFullAmount'))
            result = { ...result, title: "bookings.detail.completeBooking", action: BookingAction.complete };
          else result = { ...result, title: "bookings.detail.waitingBalance", enabled: false };
            break;
          case "payment": if (checkFact('isDepositPayed')) result = { ...result, title: "bookings.detail.reserveBooking", action: BookingAction.reserve };
          else result = { ...result, title: "bookings.detail.waitingDeposit", enabled: false };
            break;
          case "signing": if (checkFact('inCanSignAgreement')) result = { ...result, title: "bookings.detail.waitingSigning", enabled: false };
          else {
            if (checkFact('isDepositPayed')) result = { ...result, title: "bookings.detail.reserveBooking", action: BookingAction.reserve };
            else result = { ...result, title: "bookings.detail.waitingBalance", enabled: false };
          }
            break;
          case "canceledByCustomer":
          case "cancelled": result = { ...result, title: "bookings.detail.deleteBooking", action: BookingAction.delete }; break;
          case "new":
          case "meetingBooked":
          case "proposalSubmitted":
          case "communication": if (checkFact('canSignContract')) result = { ...result, title: "bookings.detail.contractSigning", action: BookingAction.signContract };
          else if (checkFact('reqDeposit')) result = { ...result, title: "bookings.detail.askButton.deposit", action: BookingAction.pay };
          else result = { ...result, title: "bookings.detail.askButton.payment", action: BookingAction.pay };
            break;
        }
    }
    return result;
  }



  public static aliasServiceObjByTypeName(type: ServiceTypeKeys): string {
    switch (type) {
      case "private": return "privateEvent";
      case "general": return "wedding";
      case "business": return "businessEvent";
      case "wedding": return "wedding";
    }
    return null;
  }

  public static transformToServiceOptionList(serviceList: ServiceListType[]): (
    { id: Object, text: string, data: any, options?: { id: Object, text: string, data: any }[] }[]) {
    return serviceList.map(item =>
    ({
      id: item.events[0].serviceEventId, text: item.name,
      data: { types: item.events[0].types, timeScheduling: item.timeScheduling, serviceBaseId: item._id },
      options: item.events.map(subitem => ((item.events.length != 1) ? {
        id: subitem.serviceEventId,
        data: { types: subitem.types, timeScheduling: item.timeScheduling, serviceBaseId: item._id },
        text: subitem.name
      } : null)).filter(itm => itm)
    }));
  }

  public static haveNewMessages(userId: string, item: BookingsType): boolean {
    const chatRoom = item.chatRoom;
    if (chatRoom && chatRoom.readBy.length > 0) return !chatRoom.readBy.includes(userId);
    return false;
  }

  // NOTE: Disabled and moved to pipes - inject function must be run in injection context (constructor phase)
  /* public static prepareCancelationDescription(service:ServiceBase):any[] {
    if (service?.reservation?.canCancelReservation) {
      if (service.reservation.refundType == 'whole')
        return [{ type: 2, p1: service.reservation.refundUntilDays}];
      else
        return service.reservation.refundRules.map((item) => 
          ({
            type: 3,
            p1: item.daysUntil,
            p2: item.fee
              ? inject(ServiceCurrencyPipe).transform(
                item.fee,
                service
              )
              : item.refundPart,
            p3: item.priceUnit
          }));
    } else {
      return [{ type: 1 }];
    }
  }

  public static preparePenaltiesDescription(service:ServiceBase):any[] {
    if (service?.reservation?.cancelationFee) {
      return service.reservation.cancelationFeeRules.map((item) => ({
        type: 2,
        p1: item.daysUntil,
        p2: inject(ServiceCurrencyPipe).transform(
          item.fee,
          service)
      }));
    } else {
      return [{ type: 1 }];
    }
  }

  public static prepareRescheduleDescription(service:ServiceBase):any[] {  
    if (service?.reservation?.canReschedule) {
      if (!service.reservation.rescheduleFee)
        return [{ type: 2 }];        
      else
        return [{
          type: 3,
          p1: inject(ServiceCurrencyPipe).transform(
            service.reservation.rescheduleFeeMonetary ?? 0,
            service
          )
        }];
    } else {
      return [{ type: 1 }];
    }
  }

  public static preparePaymentDescription(service:ServiceBase):any[] {    
    return [{
      type: service.advances.fullAdvance
        ? 3
        : service.advances.partialPaymentOption == 'before_event'
          ? 1
          : 2,
      p1: service.advances.paymentDays }];
  }
  */

  public static prepareFiles(service:ServiceBase):any[] {    
    return [].concat(
      service?.contractExample?.contractExampleFiles?.map((item) => ({
        filename: item.fileName,
        url: item.fileUrl
      })),
      service?.kitchen?.foodMenu?.map((item) => ({
        filename: item.fileName,
        url: item.fileUrl
      })),
      service?.kitchen?.drinksMenu?.map((item) => ({
        filename: item.fileName,
        url: item.fileUrl
      })),
      service?.venues?.layoutPlans?.map((item) => ({
        filename: item.fileName,
        url: item.fileUrl
      })),
      service?.venues?.accomodationPlans?.map((item) => ({
        filename: item.fileName,
        url: item.fileUrl
      }))
    ).filter((item) => item);
  }

  public static changeState(oldState: BookingStateKeys, item: BookingsType): boolean {
    //return true;

    const invalidState = "invalidState";
    if (item.customerId) // If a customer is registered
      switch (item.state) {
      case "new": if (oldState != "new") throw new Error(invalidState); break;
      case "meetingBooked":
      case "proposalSubmitted":
      case "communication": 
      case "signing": 
      case "payment": if (oldState == "reserved" && 
         (item.paid>0 || item.paidCash>0)) throw new Error(invalidState);
        // Cannot manually change from 'reserved' if payment has been made via our platform
        break;
      case "reserved": break;
      case "completed": break;
      }
    else // If a customer is not registered
    {
      switch (item.state) {        
      case "new": if (oldState != "new") throw new Error(invalidState); break;
      }   
    }
    return true;
  }

  public static transformBookingRequest(item: BookingsType): string {
    return item?.options?.viaRecommended ? "viaRecommended" : item.options?.bookingRequest ?? 'addedManually';
  }  

  public static allDayEvent(eventDate: Date | undefined, eventTillDate:Date | undefined):boolean {
    if (!eventTillDate || !eventDate) return true;
    const diff = moment(eventTillDate).diff(eventDate,"minutes"); 
    return diff >=  1439 && diff <= 1440;
  }

  public static transformState(item: BookingsType, dest: ('vendor' | 'customer') = 'vendor'): BookingStateKeys {
    if (item.options?.contractSigned && item.state == 'signing') return 'payment';
    else if (item.state=='pendingPayment') return (item.paid + (item.paidCash ?? 0))>0?'reserved':'payment';
    else if (item.state=='reserved' && Math.round((item.price - item.discount - item.paid - (item.paidCash ?? 0))*100)*0.01 <= 0 &&
         moment().diff(moment(item.eventDate),'days')>1) return 'completed';
    //if (item.state == 'new' && item?.chatRoom?.messagesSentBy?.length > 1) return 'communication';
    //if (item.state == 'communication' && item?.chatRoom?.messagesSentBy?.length < 2) return 'new';
    return item.state;
  }

  public static transformServiceBase(serviceBase: ServiceBase[], translateService:TranslateService): ServiceListType[] {
    const findDuplicates = (arr) => {
      const sorted_arr = arr.slice().sort((a,b)=>a.name.localeCompare(b.name)); 
      const results = [];
      for (let i = 0; i < sorted_arr.length - 1; i++) 
        if (sorted_arr[i + 1].name.trim() == sorted_arr[i].name.trim()) results.push(sorted_arr[i].name.trim());
      return results;
    };

    const list = serviceBase
      .filter(checkItem => { return checkItem.completed; })  // only completed services
      .map(item => ({
        _id: item._id, name: item.brandName, timeScheduling: item?.scheduling?.multiClientPerDay ? true : false, //concreteTimeForClient
        timePerClient: item?.scheduling?.timePerClient, type: item.type, country: (item.address?.country ?? "US").toLowerCase(), 
        events: [{ name: item.brandName, serviceEventId: item.serviceEvents[0]._id }]

          .concat(([].concat(item.venues?.areas, [item.venues?.backyard]))
            .filter(itm => itm?.serviceId)  // contains serviceId
            .map(area => ({ name: area.name, serviceEventId: area.serviceId })))
          .map(area => {
            const event = item.serviceEvents.find(serviceEventToCheck => serviceEventToCheck._id === area.serviceEventId);
            return {
              ...area,
              // check if events is completed?                              
              types: [((event?.businessEvent?.completed) ? ServiceTypeEnum.business : null),
              ((event?.privateEvent?.completed) ? ServiceTypeEnum.private : null),
              ((event?.wedding?.completed && event?.customPricing) ? ServiceTypeEnum.wedding : null),
              ((event?.wedding?.completed && !event?.customPricing) ? ServiceTypeEnum.general : null)
              ].filter(itm => itm) // contains Event description
            };
          }).filter(itm => itm.types.length > 0) // contains at least one description

      })).filter(itm => itm.events.length > 0); //interested only non-empty elements
    
    const duplicates = findDuplicates(list);

    return list.map(v=>({...v, 
      name: duplicates.includes(v.name.trim())?
        `${v.name} (${translateService.instant("serviceTypeCategories."+v.type).replace(/(?:^|\s)\S/g,chr=>chr.toLowerCase())})`
        :v.name}));
  }
}      
