<ng-container [ngSwitch]="isMobile$ | async" *ngIf="enabled">
    <ng-container *ngSwitchCase="true">
        <mat-icon class="mobile-show" (click)="callLink(phoneNo)">phone</mat-icon>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <mat-icon (click)="phoneShown = !phoneShown">phone</mat-icon>
        <a (click)="callLink(phoneNo)" *ngIf="phoneShown">
            {{phoneNo}}
        </a>
    </ng-container>
</ng-container>