import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay } from 'date-fns';

@Pipe({
  name: 'ShowTimePassed',
  pure: true,
  standalone: true
})
export class ShowTimePassedPipe implements PipeTransform {
  transform(nextMessage, currentMessage): any {
    if (!nextMessage || !currentMessage) {
      return true;
    }

    if (!isSameDay(currentMessage.createdAt, nextMessage.createdAt)) {
      return true;
    }

    if (nextMessage.sender !== currentMessage.sender) {
      return true;
    }

    return false;
  }
}
