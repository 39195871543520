import { FormControl } from '@angular/forms';

export const marketplaceEventTypefilterControl = new FormControl<string | null>(null, { initialValueIsDefault: true });

import { Injectable } from '@angular/core';
// import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class JarvisGlobalEventTypeService {
  
  private eventTypeChangeSubject = new Subject<string>();
  
  private eventTypeSource = new BehaviorSubject(null);

  eventType$ = this.eventTypeSource.asObservable();

  constructor() { }

  setValue(newValue) {
    this.eventTypeSource.next(newValue);
  }
  
}