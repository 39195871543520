import { CdkPortalOutlet, Portal, PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ElementRef, Input, OnDestroy } from '@angular/core';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ContentChildren,
  Directive,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { cardAnimations } from './card-animations';
import { BoundingCards, CardPositionState } from './card-types';
import { JarvisCardComponent } from './card/card.component';
import { Subject } from 'rxjs';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[cardContent]'
})
export class CardContentPortalDirective extends CdkPortalOutlet {
  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) document: any
  ) {
    super(componentFactoryResolver, viewContainerRef, document);
  }
}

@Component({
  selector: 'jarvis-card-slider',
  templateUrl: './card-slider.component.html',
  styleUrls: ['./card-slider.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    PortalModule,
    ScrollingModule
  ],
  animations: [cardAnimations.translateCard, cardAnimations.shadowOpacity]
})
export class JarvisCardSliderComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  currentPortal: Portal<any> | null = null;

  @ContentChildren(JarvisCardComponent, { descendants: true }) cards: QueryList<JarvisCardComponent> | undefined;

  @ViewChild('contentContainer') contentContainer: ElementRef<HTMLDivElement> | undefined;
  @Input() arrowIcons = false;
  @Input() noButtons = false;

  @Input() hideOverflow = true;

  @Input() gap: string;

  content: any[] = [];
  // renderedContent: any[] = [];

  position: CardPositionState = 'center';
  animationDuration = '500ms';
  manualPosition = 0;

  shadowLeftState = 'none';
  shadowRightState = 'none';
  shadowOpacity = 0;

  currentPosition = 'auto';

  thresholdPercent = 20;

  private currentOutletIndex = 0;
  private outletCount = 0;

  private destroy$ = new Subject<void>();

  cardLength = 0;

  get currentCardIndex() {
    return this.currentOutletIndex;
  }

  constructor(private viewContainerRef: ViewContainerRef) { }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  panHandler(event: any, currentIndex: number): void {
    const {card, previousCard, nextCard} = this.getBoundingCards(currentIndex);

    const lastIndex = this.outletCount - 1;

    const absolutePosition = Math.abs(card.manualPosition);
    const positionSign = Math.sign(card.manualPosition);

    let shadowSensitivity = 0;

    if (currentIndex === 0 && card.manualPosition >= 0) {
      this.shadowLeftState = 'manual';
      shadowSensitivity = 0.01;
    }

    if (currentIndex === lastIndex && card.manualPosition <= 0) {
      this.shadowRightState = 'manual';
      shadowSensitivity = 0.01;
    }

    let newPosition = event.deltaX;
    this.shadowOpacity = Math.abs(shadowSensitivity * event.deltaX);

    if (
      (currentIndex === 0 && absolutePosition >= 40 && positionSign > 0) ||
      (currentIndex === lastIndex && absolutePosition >= 40 && positionSign < 0)
    ) {
      newPosition = card.manualPosition;
    }

    card.position = 'manual';
    card.manualPosition = newPosition;

    if (previousCard) {
      previousCard.position = 'manual-left';
      previousCard.manualPosition = newPosition;
    }

    if (nextCard) {
      nextCard.position = 'manual-right';
      nextCard.manualPosition = newPosition;
    }
  }

  panHandlerBlock(event: any): void {
    this.currentPosition = 'manual-block';

    const positionDelta = event.deltaX;

    const absolutePosition = Math.abs(positionDelta);
    const positionSign = Math.sign(positionDelta);

    if (
      (this.currentCardIndex === 0 && absolutePosition >= 40 && positionSign > 0) ||
      (this.currentCardIndex === (this.cardLength - 1) && absolutePosition >= 40 && positionSign < 0)
    ) {
      return;
    }

    this.manualPosition = positionDelta;
  }

  panEndHandlerBlock(event: any): void {
    this.currentPosition = 'auto';
    this.manualPosition = 0;
    
    const totalChange = event.deltaX;
    const containerWidth = this.contentContainer.nativeElement.clientWidth;

    const changeThresholdInPixels = containerWidth * (this.thresholdPercent / 100);

    if (Math.abs(totalChange) >= changeThresholdInPixels) {
      const side = Math.sign(totalChange);

      if (side < 0 && this.currentCardIndex !== this.cardLength - 1) {
        this.currentOutletIndex += 1;
      }

      if (side > 0 && this.currentCardIndex !== 0) {
        this.currentOutletIndex -= 1;
      }
    }

  }

  touchEndHandler() {
    this.currentPosition = 'auto';
  }

  panEndHandler(event: any, currentIndex: number): void {
    const {card, previousCard, nextCard} = this.getBoundingCards(currentIndex);

    const lastIndex = this.outletCount - 1;

    const absPositionPercentage = Math.abs(card.manualPosition);
    const positionSign = Math.sign(card.manualPosition);

    this.shadowLeftState = 'none';
    this.shadowRightState = 'none';
    this.shadowOpacity = 0;

    if (
      absPositionPercentage < 30 ||
      (currentIndex === 0 && card.manualPosition > 0) ||
      (currentIndex === lastIndex && card.manualPosition < 0)
    ) {
      this.setPosition(card, 'center');
      this.setPosition(previousCard, 'left');
      this.setPosition(nextCard, 'right');
      return;
    }

    if (positionSign < 0) {
      this.translateToNextCard(currentIndex);
      return;
    }

    if (positionSign > 0) {
      this.translateToPreviousCard(currentIndex);
      return;
    }

  }

  translateCardComplete(event: any): void {
    // console.log('Translation complete');
  }

  nextCard() {
    if (this.currentOutletIndex === this.cardLength - 1) {
      return;
    }

    this.currentOutletIndex += 1;
  }

  previousCard() {
    if (this.currentOutletIndex === 0) {
      return;
    }

    this.currentOutletIndex -= 1;
  }

  setCardIndex(index: number) {
    if (index >= 0 && index < this.cardLength) {
      this.currentOutletIndex = index;
    }
  }

  translateToPreviousCard(currentIndex: number = this.currentOutletIndex): void {
    if (currentIndex === 0) {
      return;
    }

    const {card, previousCard, nextCard} = this.getBoundingCards(currentIndex);

    this.setPosition(card, 'right');
    this.setPosition(previousCard, 'center');
    this.setPosition(nextCard, 'right');

    const nextIndex = currentIndex - 1;
    this.changeActiveCard(currentIndex, nextIndex);
    this.currentOutletIndex = nextIndex;
  }

  translateToNextCard(currentIndex: number = this.currentOutletIndex): void {
    if (currentIndex === this.outletCount - 1) {
      return;
    }

    const {card, previousCard, nextCard} = this.getBoundingCards(currentIndex);

    this.setPosition(card, 'left');
    this.setPosition(previousCard, 'left');
    this.setPosition(nextCard, 'center');

    const nextIndex = currentIndex + 1;
    this.changeActiveCard(currentIndex, nextIndex);
    this.currentOutletIndex = nextIndex;
  }

  get isFirstCard(): boolean {
    return this.currentOutletIndex === 0;
  }

  get isLastCard(): boolean {
    return this.currentOutletIndex === this.outletCount - 1;
  }

  private changeActiveCard(currentIndex: number, nextIndex: number): void {
    this.currentOutletIndex = currentIndex - 1;
    this.content[currentIndex].active = false;
    this.content[nextIndex].active = true;
  }

  private setPosition(card: any, position: CardPositionState, manualPosition = 0): void {
    if (!card) {
      return;
    }
    card.position = position;
    card.manualPosition = manualPosition;
  }

  private getBoundingCards(index: number): BoundingCards {
    return {
      card: this.content[index],
      nextCard: index !== (this.outletCount - 1) ? this.content[index + 1] : null,
      previousCard: index !== 0 ? this.content[index - 1] : null
    };
  }


  ngOnInit(): void {
    // this.cardLength = this.cards?.length ?? 0;
  }

  ngAfterViewInit(): void {
    // this.calculateContentConainerWidth();
    // this.cardLength = this.cards?.length ?? 0;
  }

  ngAfterContentInit(): void {
    if (!this.cards) {
      console.error('Card template not found!');
      return;
    }

    // TODO: Hacky - rethink initiation logic.
    setTimeout(() => {
      this.cardLength = this.cards?.length ?? 0;
    }, 0);

    for (const card of this.cards) {
      const cardTemplateRef = card.cardContent;
      if (!cardTemplateRef) {
        console.error('Card does not have content!');
        continue;
      }
      this.content.push({
        position: 'right',
        manualPosition: 0,
        active: false,
        portal: new TemplatePortal(cardTemplateRef, this.viewContainerRef)
      });
    }

    this.outletCount = this.content.length;
    this.content[0].active = true;
    this.content[0].position = 'center';
  }

  onTranslateCardStarted(event: any): void {
    // console.log(event);
  }


}
