import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ReviewsInviteInfo, ReviewsService } from '../services/reviews.service';

@Injectable({ providedIn: 'root' })
export class GetReviewsResolver implements Resolve<ReviewsInviteInfo> {
    constructor(
        private reviewsService: ReviewsService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ReviewsInviteInfo> {
        const hash = route.params.hash;
        if (!hash) {
            return of(null);
        }
        return this.reviewsService.getReviewInviteInfo(hash);
    }
}
