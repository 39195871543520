<h2 class="bztt-font-maple-r bztt-text-2xl bztt-mb-2">{{'profiling.clients.title' | translate}}</h2>

<span class="bztt-text-sm bztt-text-[#717171] bztt-mb-6">{{'profiling.bestFitting' | translate}}</span>

<ng-container *ngFor="let item of values; let i = index;">
    <button app-button (click)="selectValue(item.value)" class="bztt-mb-2" [ngClass]="{
            'bztt-mb-6': values.length - 1 === i,
            'selected-outline-accent': selectedValues.includes(item.value)
        }">
        {{item.label | translate }}
    </button>
</ng-container>

<button [disabled]="formControl.invalid" class="md:bztt-mt-auto" color="accent" app-button (click)="next()">{{'profiling.next' | translate}}</button>
