import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JarvisUiRulesPoliciesComponent } from './rules-policies.component';
import { TranslateModule } from '@ngx-translate/core';
import { JarvisUiRulesComponent } from './rules/rules.component';
import { JarvisUiPrivacyComponent } from './privacy/privacy.component';
import { JarvisUiCookiesComponent } from './cookies/cookies.component';
import { JarvisUiRulesPoliciesService } from './services/rules-policies.service';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    JarvisUiRulesPoliciesComponent,
    JarvisUiRulesComponent,
    JarvisUiPrivacyComponent,
    JarvisUiCookiesComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatProgressSpinnerModule,
    RouterModule
  ],
  exports: [
    JarvisUiRulesPoliciesComponent
  ],
  providers: [
    JarvisUiRulesPoliciesService
  ]
})
export class JarvisUiRulesPoliciesModule { }
