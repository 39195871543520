export const sections = [
  {
    id: 'online-directory',
    header: 'landing.saas.quality.title',
    text: 'landing.saas.quality.description',
    subtexts: ['landing.saas.quality.item1', 'landing.saas.quality.item2'],
    videoPoster: 'assets/images/saas-landing-top-img.png'
  },
  {
    id: 'inquiry-pages',
    header: 'landing.saas.bookings.title',
    text: 'landing.saas.bookings.description',
    subtexts: ['landing.saas.bookings.item1', 'landing.saas.bookings.item2'],
    videoLink: 'assets/videos/vendors_1.mp4',
    videoPoster: 'assets/images/landing/get-bookings-easier-lukas.jpg'
  },
  {
    id: 'purpose-built-crm',
    header: 'landing.saas.onboarding.title',
    text: 'landing.saas.onboarding.description',
    subtexts: ['landing.saas.onboarding.item1', 'landing.saas.onboarding.item2'],
    videoLink: 'assets/videos/vendors_3.mp4',
    videoPoster: 'assets/images/landing/crm-reservations.png'
  },
  {
    id: 'email-reminders',
    header: 'landing.saas.payments.title',
    text: 'landing.saas.payments.description',
    subtexts: ['landing.saas.payments.item1', 'landing.saas.payments.item2'],
    videoLink: 'assets/videos/vendors_4.mp4',
    videoPoster: 'assets/images/landing/collect-payments.png'
  }
];
