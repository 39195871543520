<swiper
  [navigation]="true"
  [pagination]="{ dynamicBullets: true, dynamicMainBullets: 3 }"
  [slidesPerView]="1"
  class='bztt-h-full bztt-z-0 card-swiper'
  [virtual]="true"
  [preventClicksPropagation]="true"
>
  <ng-template [virtualIndex]="i" swiperSlide *ngFor="let image of images; let i = index">
    <img
        class="bztt-object-cover bztt-w-full bztt-h-full"
        [ngSrc]="image.url"
        fill
        [alt]="title + (i > 0 ? ' #' + (i + 1) : '')"
    />
  </ng-template>
</swiper>
