import { Router } from '@angular/router';
import { Component, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HttpRequestState } from '@jarvis/utils';
import { UserProfilingData } from '../../../../types/user-profiling.types';
import { Observable } from 'rxjs';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';
import { delay } from 'rxjs/operators';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';

@Component({
  templateUrl: './source.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class SourceComponent {

  values = [
    {
      label: 'profiling.source.emailOrPhone',
      value: 'emailOrPhone'
    },
    {
      label: 'profiling.source.socialMedia',
      value: 'socialMedia'
    },
    {
      label: 'profiling.source.searchEngine',
      value: 'searchEngine'
    },
    {
      label: 'profiling.source.referral',
      value: 'referral'
    },
    {
      label: 'profiling.other',
      value: 'other'
    }
  ];

  formControl:UntypedFormControl;
  selectedValue: string | null = null;
  upload$: Observable<HttpRequestState<UserProfilingData>> | null = null;

  private domainCountry = inject(DOMAIN_COUNTRY);

  constructor(
    private profilingFormService: JarvisProfilingFormService,
    private router: Router
  ) {
    this.formControl = this.profilingFormService.getStepControl('heardFromSource');
  }

  selectValue(value: string): void {
    this.formControl.setValue(value);
    this.selectedValue = value;
  }

  next(): void {
    this.upload$ = this.profilingFormService.submitProfilingInfo({
      completedValue: this.domainCountry === 'lt' ? true : false
    });

    this.upload$.pipe(delay(1000)).subscribe({
      next: (state) => {
        if (!state.isLoading && !state.error) {
          if (this.domainCountry === 'lt') {
            this.router.navigate(['services', 'new']);
          } else { 
            this.profilingFormService.navigateToStep('call');
          }
        }
      }
    });
  }
}
