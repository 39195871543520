import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '../confirm-modal.component';
import { SuccessDialogComponent } from '../success-modal.component';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ComponentType } from '@angular/cdk/portal';
import { LayoutService } from '../../../layout';
import { JarvisUiModalService } from '../../modal';


@Injectable({providedIn: 'root'})
export class CommonDialogService extends JarvisUiModalService {
  constructor(
    dialog: MatDialog,
    layoutService: LayoutService
  ) { super(dialog, layoutService); }

  dialogRef: MatDialogRef<any>;

  /****************
   *  Usage:
   *
   *  constructor(private commonDialogService: CommonDialogService, ....
   *
   *  ....
   *  this.commonDialogService.openConfirm();
   *  this.commonDialogService.confirmed().subscribe(confirmed => {
   *     if (confirmed) { // console.log("Action is confirmed")
   *  }
   *
   *   Or
   *
   *  this.commonDialogService.openConfirm("key-to-custom-messages");
   *
   *  Where "key-to-custom-messages" - is key in translations. Example:
   *    "key-to-custom-messages": { "message":"Custom confirm message", "confirm":"Yes", "canclel":"No"}
   *
   * **************/

  public openConfirm(i18nKey?: string, data?: { [key: string]: any }): MatDialogRef<boolean> {
    this.dialogRef = super.openDialog(ConfirmDialogComponent, { width: '672px', panelClass: 'confirm-dialog', data: { i18nKey, translationData: data } });
    return this.dialogRef;
  }


  public openConfirmRx(i18nKey?: string, disableCloseButton = false): Observable<number> {
    this.dialogRef = super.openDialog(ConfirmDialogComponent, { width: '672px', panelClass: 'confirm-dialog', data: { i18nKey, disableCloseButton } });
    return this.dialogRef.afterClosed().pipe(map(value=>(value==null?-1:(value?1:0))));
  }    

  public openSuccess(i18nKey?: string, icon: string = null, title: string = null, message: string = null): MatDialogRef<boolean> {
    this.dialogRef = super.openDialog(SuccessDialogComponent,
      {
        height: '332px', width: '672px', panelClass: 'success-dialog',
        data: { i18nKey, icon, title, message }
      });
    return this.dialogRef;
  }

  public openSuccessRx(i18nKey?: string, icon: string = null, title: string = null, message: string = null, values:any = null): Observable<number> {
    this.dialogRef = super.openDialog(SuccessDialogComponent,
      {
        height: '332px', width: '672px', panelClass: 'success-dialog',
        data: { i18nKey, icon, title, message, values }
      });
    return this.dialogRef.afterClosed().pipe(map(value=>(value==null?-1:(value?1:0))));
  }    

  /* public openTemplateDialog<T>(template: TemplateRef<unknown>, data?: any, panelClass?: string): MatDialogRef<T> {
    const wrapperPortal = new ComponentPortal(ModalWrapperComponent);
    wrapperPortal.attach()
    this.dialogRef = super.openDialog(wrapperPortal, { height: '332px', width: '672px', panelClass, data });
    return this.dialogRef;
  } */

  public openCustomDialog<T>(component: ComponentType<T>, data?: any, panelClass?: string): MatDialogRef<T> {
    this.dialogRef = super.openDialog(component, { height: '332px', width: '672px', panelClass, data });
    return this.dialogRef;
  }

  public openCustomWDialog<T>(component: ComponentType<T>, data?: any, panelClass?: string): MatDialogRef<T> {
    this.dialogRef = super.openDialog(component, { width: '672px', panelClass, data });
    return this.dialogRef;
  }

  public openDialogRx<T>(component: ComponentType<T>, data?: any, panelClass?: string): Observable<any> {
    return super.openDialog(component, { width: '672px', panelClass, data }).afterClosed();
  }  


  public closed(): Observable<any> { return this.confirmed(); }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }
    ));
  }
}
