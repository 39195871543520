import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { JarvisFileUploadService } from '@jarvis/services';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'jarvis-ui-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class JarvisFileUploaderComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() formArray: UntypedFormArray;
  @Input() accept = '*';
  @Input() noUser = false;
  @Input() customSaveParams: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onAction = new EventEmitter<FileUploaderActionType[]>();
  @ViewChild('pdfInput') pdfInput: ElementRef;

  filesUploading = false;
  private fileUploadProgressSource$ = new BehaviorSubject(0);
  fileUploadProgress$ = this.fileUploadProgressSource$.asObservable();

  private files = new BehaviorSubject<File[]>(null);
  files$ = this.files.asObservable();

  constructor(private fileUploadService: JarvisFileUploadService) {}

  ngOnInit(): void {
    if (this.formArray) {
      this.files.next(this.formArray.value);
    }

    // For testing
    /* timer(0, 200).subscribe(() => {
      const lastVal = this.fileUploadProgressSource$.value;

      if (lastVal === 100) {
        this.fileUploadProgressSource$.next(0);
      }
      else {
        const newVal = lastVal + 5;
        this.fileUploadProgressSource$.next(newVal);
      }
    }); */
  }

  ngOnDestroy(): void {
    this.fileUploadProgressSource$.complete();
    this.files.complete();
  }

  public clickUpload() {
    this.pdfInput.nativeElement.click();
  }

  uploadFiles(event: any): void {
    const files = event.target.files as FileList;

    const fileArr = Array.from(files);

    const streams = this.createUploadStream(fileArr);
    let progress = 0;

    this.filesUploading = true;

    const mappedStreams = streams.map((stream) => {
      return stream.pipe(
        tap(() => {
          progress += 1;
          const percentage = (progress / streams.length) * 100;
          this.fileUploadProgressSource$.next(percentage);
        })
      );
    });

    combineLatest(mappedStreams).subscribe(
      (fileResponses) => {
        if (this.formArray) {
          fileResponses.forEach((fileRes) => {
            const newForm = new UntypedFormGroup({
              fileName: new UntypedFormControl(fileRes.fileName),
              fileUrl: new UntypedFormControl(fileRes.fileUrl),
            });
            this.formArray.push(newForm);
          });

          this.files.next(this.formArray.value);
        }

        // Generate event on the add action
        this.onAction.emit(
          fileResponses.map((fileRes) => ({
            type: 'add',
            fileName: fileRes.fileName,
            fileUrl: fileRes.fileUrl,
          }))
        );
      },
      () => {},
      () => {
        this.fileUploadProgressSource$.next(0);
        this.filesUploading = false;
      }
    );
  }

  removeFile(index: number): void {
    // generate event on the remove action
    this.onAction.emit([
      {
        type: 'remove',
        fileName: this.formArray.at(index).get('fileName').value,
        fileUrl: this.formArray.at(index).get('fileUrl').value,
      },
    ]);

    this.formArray.removeAt(index);
    this.files.next(this.formArray.value);
  }

  private createUploadStream(fileArr: File[]): Observable<any>[] {
    const streams: Observable<any>[] = [];

    fileArr.forEach((file) => {
      const stream = from(file.arrayBuffer()).pipe(
        switchMap((fileArrayBuffer) => {
          const blob = new Blob([fileArrayBuffer]);
          return !this.noUser
            ? this.fileUploadService.saveFile(
                blob,
                file.name,
                'ServiceDocument'
              )
            : this.fileUploadService.saveConcierge(
                blob,
                file.name,
                this.customSaveParams
              );
        })
      );

      streams.push(stream);
    });

    return streams;
  }
}

export type FileUploaderActionType = {
  type: 'add' | 'remove';
  fileName: string;
  fileUrl: string;
};
