import { Pipe, PipeTransform } from '@angular/core';
import { ChatRoom } from '../../types/messaging.types';

interface User {
  _id: string;
  createdAt: Date;
  email: string;
  emailVerified: string;
  profilePhoto: string;
  userType: string;
  name?: string;
  surname?: string;
}

interface Profile {
  address: string;
  addressCorrespondence?: string;
  businessName?: string;
  createdAt: Date;
  entityCode?: string;
  isVATPayer: boolean;
  main: boolean;
  name: string;
  personType: string;
  phoneNo: string;
  surname: string;
  updatedAt: Date;
  user: string;
  _id: string;
  vatPayerCode?: string;
}

type ChatUserAndProfile = User & {
  profile: Profile[];
};

@Pipe({
  name: 'ChatUserName',
  pure: true,
})
export class ChatUserNamePipe implements PipeTransform {
  transform(chatRoom: ChatRoom): any {
    const value = chatRoom.users as unknown as ChatUserAndProfile;

    if (value.name && value.surname && value.name.length > 0) {
      return `${value.name} ${value.surname}`;
    }

    if (chatRoom.users?.profile && chatRoom.users.profile.length !== 0) {
      const mainProfile = value.profile?.find((profile) => profile.main);
      
      if (
        mainProfile &&
        mainProfile.name &&
        mainProfile.surname &&
        mainProfile.name.length > 0
      ) {
        return `${mainProfile.name} ${mainProfile.surname}`;
      }
    }
   

    if (chatRoom.name) {
      return `${chatRoom.name} ${chatRoom.surname}`;
    }
    
    if (chatRoom.email) {
      return chatRoom.email;
    }

    return value.email;
  }
}
