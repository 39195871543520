import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import { combineAll, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import {
  AdvertServiceVariant,
  AdvertsPriceUnits,
  ServiceTypes
} from '../pricing-calculator';

interface ServiceAndDescriptionData {
  extractedService: AdvertServiceVariant;
  translationService: TranslateService;
  date$: Observable<Date>;
  locale$?: Observable<any>;
  customPrice$?: Observable<number | null>;
}

interface ServicePriceDescriptor {
  priceValue: number;
  priceUnit: AdvertsPriceUnits;
  minBudget?: number;
  additionalUnitPrice?: number;
  tax?: number;
  serviceCharge?: number;
  type?: ServiceTypes;
  excludedFromGlobalMinBudget?: boolean;
  rentAdditionalPriceUnit?: string;
  rentAdditionalFixedCost?: number;
  rentAdditionalUnitCost?: number;
}

interface DescriptionData {
  priceInfo$: Observable<ServicePriceDescriptor>;
  locale$?: Observable<any>;
  translationService: TranslateService;
  includeTranslationKey?:boolean;
}

const AMOUNT_UNITS = [
  AdvertsPriceUnits.hourlyPrice,
  AdvertsPriceUnits.currencyPerHour,
  AdvertsPriceUnits.currencyPerWeight,
  AdvertsPriceUnits.currencyPerUnit
];

export function serviceDescriptionParser(parserData: ServiceAndDescriptionData): Observable<string[]> {
  const {
    extractedService,
    translationService,
    date$,
    customPrice$
  } = parserData;

  let locale$ = parserData.locale$;

  if (!extractedService) {
    return of([]);
  }

  if (!locale$) {
    locale$ = of({
      currency: 'USD',
      locale: 'en-US'
    });
  }

  const formatter$ = locale$.pipe(
    map((localeParameters) => {
      const currencyFormatter = Intl.NumberFormat(localeParameters.locale, {
        style: 'currency',
        currency: localeParameters.currency
      });
      const numberFormatter = Intl.NumberFormat(localeParameters.locale, {maximumFractionDigits: 2, minimumFractionDigits: 2});
      return { currencyFormatter, numberFormatter };
    })
  );

  const showUnitPrice =
    extractedService.type === ServiceTypes.venueCateringMenuMain ||
    extractedService.type === ServiceTypes.venueCateringMenuAdditional;

  const finalUnitPrice$ = combineLatest([formatter$, date$]).pipe(
    map(([formatter, date]) => {
      const price = extractedService.getFinalUnitPrice(date || new Date());
      return formatter.currencyFormatter.format(price);
    })
  );

  const unitPriceText$: Observable<string> = finalUnitPrice$.pipe(
    filter(() => showUnitPrice),
    map((finalUnitPrice) => {
      const translationText =
        extractedService.priceUnit === AdvertsPriceUnits.hourlyPrice
          ? 'listing.selectService.pricePerHour'
          : 'listing.selectService.pricePerPerson';

      return {
        finalUnitPrice,
        translationText
      };
    }),
    switchMap(({ finalUnitPrice, translationText }) =>
      translationService.get(translationText, { v: finalUnitPrice })
    )
  );

  const currentMinimalBudget$ = combineLatest([formatter$, date$]).pipe(
    map(([formatter, date]) => ({
      formatter: formatter.currencyFormatter,
      minBudget: extractedService.getMinimalBudget(date || new Date())
    })),
    filter(({ minBudget }) => minBudget > 0),
    map(({ minBudget, formatter }) => {
      if (AMOUNT_UNITS.includes(extractedService.priceUnit)) {
        return minBudget;
      }

      return formatter.format(minBudget);
    })
  );

  const minBudgetText$: Observable<string> = currentMinimalBudget$.pipe(
    map((currentMinimalBudget) => {
      const priceUnit = extractedService.priceUnit;
      if (
        priceUnit === AdvertsPriceUnits.hourlyPrice ||
        priceUnit === AdvertsPriceUnits.currencyPerHour
      ) {
        return {
          currentMinimalBudget,
          translationString: 'listing.selectService.minHours'
        };
      }

      if (
        priceUnit === AdvertsPriceUnits.currencyPerUnit ||
        priceUnit === AdvertsPriceUnits.currencyPerWeight
      ) {
        return {
          currentMinimalBudget,
          translationString: 'listing.selectService.minAmount'
        };
      }

      return {
        currentMinimalBudget,
        translationString: 'listing.selectService.minBudget'
      };
    }),
    switchMap(({ translationString, currentMinimalBudget }) =>
      translationService.get(translationString, { v: currentMinimalBudget })
    )
  );

  const additionalUnitPrice = extractedService.additionalUnitPrice;

  const additionalUnitPriceText$ = combineLatest([
    of(additionalUnitPrice),
    formatter$
  ]).pipe(
    switchMap(([showText, formatter]) => {
      if (!showText) {
        return of(null);
      }

      const formattedPrice = formatter.currencyFormatter.format(additionalUnitPrice);
      return translationService.get('listing.selectService.addHourPrice', { v: formattedPrice });
    })
  );

  let taxChargeType = '';

  if (extractedService.tax && extractedService.serviceCharge) {
    taxChargeType = 'taxCharge';
  } else if (extractedService.tax) {
    taxChargeType = 'tax';
  } else if (extractedService.serviceCharge) {
    taxChargeType = 'charge';
  }



  const taxText$ = combineLatest([
    of(taxChargeType),
    formatter$
  ]).pipe( 
    switchMap(([type,formatter]) => {
      const taxChargeData = {
        tax: formatter.numberFormatter.format(extractedService.tax),
        serviceCharge: formatter.numberFormatter.format(extractedService.serviceCharge)
      };

      if (type === 'taxCharge') {
        return translationService.get('listing.selectService.taxAndChargeIncluded', taxChargeData);
      }

      if (type === 'tax') {
        return translationService.get('listing.selectService.taxIncluded', taxChargeData);
      }

      if (type === 'charge') {
        return translationService.get('listing.selectService.chargeIncluded', taxChargeData);
      }

      return of(null);
    })
  );

  const showFixedVenueRentText =
    (extractedService.type === 'venueCateringMenuMain' ||
    extractedService.type === 'venueCateringMenuAdditional') &&
      extractedService.additionalFixedCostFn();

  const fixedVenueRentText$ = of(showFixedVenueRentText).pipe(
    switchMap((showText) => {
      if (!showText) {
        return of(null);
      }

      const rentCost = extractedService.additionalFixedCostFn();
      const additionalPriceUnit = extractedService.additionalPriceUnit;

      if (additionalPriceUnit === AdvertsPriceUnits.fixedFee) {
        return translationService.get('listing.selectService.venueRentPrice', { v: rentCost });
      }
      
      if (additionalPriceUnit === AdvertsPriceUnits.startingPrice) {
        return translationService.get('listing.selectService.venueRentStartingAtPrice', { v: rentCost });
      }

      return of(null);
    })
  );


  const showUnitVenueRentText =
    (extractedService.type === 'venueCateringMenuMain' ||
    extractedService.type === 'venueCateringMenuAdditional') &&
      extractedService.additionalUnitFixedCostFn();

  const unitVenueRentText$ = of(showUnitVenueRentText).pipe(
    switchMap((showText) => {
      if (!showText) {
        return of(null);
      }

      const rentCost = extractedService.additionalUnitFixedCostFn();

      return translationService.get('listing.selectService.venueRentPricePerPerson', { v: rentCost });
    })
  );

  const showExcludedMinBudgetText =
    extractedService.excludedFromGlobalMinBudget;

  const excludedMinBudgetText$ = of(showExcludedMinBudgetText).pipe(
    switchMap((showText) => {
      if (!showText) {
        return of(null);
      }
      
      return translationService.get('listing.selectService.minimumGlobalBudgetDeducted');
    })
  );

  return combineLatest([
    unitPriceText$.pipe(startWith('')),
    minBudgetText$.pipe(startWith('')),
    additionalUnitPriceText$.pipe(startWith('')),
    taxText$.pipe(startWith('')),
    fixedVenueRentText$.pipe(startWith('')),
    unitVenueRentText$.pipe(startWith('')),
    excludedMinBudgetText$.pipe(startWith('')),
  ]).pipe(
    map(paragraphs => paragraphs.filter(paragraph => paragraph)),
    // tap(console.log)
  );
}

export function generateServiceDescriptions(descriptionData: DescriptionData): Observable<string[]> {
  let locale$ = descriptionData.locale$;
  const includeTranslationKey = descriptionData.includeTranslationKey;

  if (!locale$) {
    locale$ = of({
      currency: 'USD',
      locale: 'en-US'
    });
  }

  const formatter$ = locale$.pipe(
    map((localeParameters) => {
      const currencyFormatter = Intl.NumberFormat(localeParameters.locale, {
        style: 'currency',
        currency: localeParameters.currency
      });
      const numberFormatter = Intl.NumberFormat(localeParameters.locale, {maximumFractionDigits: 2, minimumFractionDigits: 2});
      return { currencyFormatter, numberFormatter };
    })
  );

  return combineLatest([descriptionData.priceInfo$, formatter$]).pipe(
    map(([priceInfo,formatter]) => {
      const priceUnitInfo = parseUnitPriceInfo(priceInfo.priceValue, priceInfo.priceUnit, priceInfo.type);
      const minBudgetInfo = parseMinBudgetInfo(priceInfo.minBudget, priceInfo.priceUnit);
      const additionalPriceUnitInfo = parseAdditionalUnitPriceInfo(priceInfo.additionalUnitPrice);
      const taxServiceChargeInfo = parseTaxAndServiceChargeInfo(priceInfo.tax, priceInfo.serviceCharge, formatter.numberFormatter);
      const venueRentFixedInfo = parseFixedVenueRentInfo(priceInfo.type, priceInfo.rentAdditionalPriceUnit, priceInfo.rentAdditionalFixedCost);
      const venueRentUnitInfo = parseUnitVenueRentInfo(priceInfo.type, priceInfo.rentAdditionalUnitCost);
      const excludedMinBudgetInfo = parseExcludedMinBudgetInfo(priceInfo.excludedFromGlobalMinBudget);
      return [
        priceUnitInfo,
        minBudgetInfo,
        additionalPriceUnitInfo,
        taxServiceChargeInfo,
        venueRentFixedInfo,
        venueRentUnitInfo,
        excludedMinBudgetInfo
      ].filter(info => info);

    }),
    
    switchMap((translationsInfo) => {
      return formatter$.pipe(
        map(formatter => ({ formatter: formatter.currencyFormatter , translationsInfo }))
      );
    }),
    map((infoWithFormatter) => {
      const translatedData = infoWithFormatter.translationsInfo.map(info => {
        const formatData = (info as any).format;
        const hasFormat = typeof formatData === 'boolean';
        if (hasFormat && !formatData) {
          return descriptionData.translationService.get(info.translationString, info.data)
            .pipe(map(translatedData=>includeTranslationKey && translatedData?
              JSON.stringify({key:info.translationString,data:info.data,value:translatedData})
              :translatedData));
        } 

        const formattedData = {};
        Object.keys(info.data || {}).forEach(key => {
          const formattedInfo = infoWithFormatter.formatter.format(info.data[key]);
          formattedData[key] = formattedInfo;
        });
        return descriptionData.translationService.get(info.translationString, formattedData)
          .pipe(map(translatedData=>includeTranslationKey?
            JSON.stringify({key:info.translationString,data:formattedData, value:translatedData})
            :translatedData));

      });
      return translatedData;
    }),
    switchMap(translation$ => combineLatest(translation$))
  );
}

function parseUnitPriceInfo(priceValue: number, priceUnit: AdvertsPriceUnits, type: ServiceTypes) {
  const showUnitPrice =
        type === ServiceTypes.venueCateringMenuMain ||
        type === ServiceTypes.venueCateringMenuAdditional;

  if (!showUnitPrice) {
    return null;
  }

  const unitPriceTranslationText =
    priceUnit === AdvertsPriceUnits.hourlyPrice
      ? 'listing.selectService.pricePerHour'
      : 'listing.selectService.pricePerPerson';

  const unitPriceTranslationData = {
    v: priceValue
  };

  return {
    translationString: unitPriceTranslationText,
    data: unitPriceTranslationData
  };
}

function parseMinBudgetInfo(minimalBudget: number, priceUnit: AdvertsPriceUnits) {
  if (!minimalBudget) {
    return null;
  }

  if (
    priceUnit === AdvertsPriceUnits.hourlyPrice ||
    priceUnit === AdvertsPriceUnits.currencyPerHour
  ) {
    return {
      translationString: 'listing.selectService.minHours',
      data: {
        v: minimalBudget
      },
      format: false
    };
  }

  if (
    priceUnit === AdvertsPriceUnits.currencyPerUnit ||
    priceUnit === AdvertsPriceUnits.currencyPerWeight
  ) {
    return {
      data: { v: minimalBudget},
      translationString: 'listing.selectService.minAmount',
      format: false
    };
  }

  return {
    data: { v: minimalBudget },
    translationString: 'listing.selectService.minBudget'
  };
}

function parseAdditionalUnitPriceInfo(additionalUnitPrice: number) {
  if (!additionalUnitPrice) {
    return null;
  }

  return {
    translationString: 'listing.selectService.addHourPrice',
    data: { v: additionalUnitPrice }
  };
}

function parseTaxAndServiceChargeInfo(tax: number, serviceCharge: number, formatter: Intl.NumberFormat) {
  let taxChargeType = '';

  if (tax && serviceCharge) {
    taxChargeType = 'taxCharge';
  } else if (tax) {
    taxChargeType = 'tax';
  } else if (serviceCharge) {
    taxChargeType = 'charge';
  }

  if (!taxChargeType) {
    return null;
  }

  const taxChargeData = {
    tax: formatter.format(tax),
    serviceCharge: formatter.format(serviceCharge)
  };
  

  if (taxChargeType === 'taxCharge') {
    return {
      translationString: 'listing.selectService.taxAndChargeIncluded',
      data: taxChargeData,
      format: false
    };
  }

  if (taxChargeType === 'tax') {
    return {
      translationString: 'listing.selectService.taxIncluded',
      data: taxChargeData,
      format: false
    };
  }

  if (taxChargeType === 'charge') {
    return {
      translationString: 'listing.selectService.chargeIncluded',
      data: taxChargeData,
      format: false
    };
  }

  return null;
}

function parseUnitVenueRentInfo(type: ServiceTypes, additionalUnitCost: number, ) {
  const showUnitVenueRentText =
    (type === 'venueCateringMenuMain' ||
    type === 'venueCateringMenuAdditional') &&
      additionalUnitCost;

  if (!showUnitVenueRentText) {
    return null;
  }

  const rentCost = additionalUnitCost;
  return {
    translationString: 'listing.selectService.venueRentPricePerPerson',
    data: { v: rentCost }
  };
}

function parseFixedVenueRentInfo(type: ServiceTypes, additionalPriceUnit: string, additionalFixedCost: number) {
  const showFixedVenueRentText = (type === 'venueCateringMenuMain' || type === 'venueCateringMenuAdditional') && additionalFixedCost;

  if (!showFixedVenueRentText) {
    return null;
  }

  const rentCost = additionalFixedCost;

  if (additionalPriceUnit === AdvertsPriceUnits.fixedFee) {
    return {
      translationString: 'listing.selectService.venueRentPrice',
      data: { v: rentCost }
    };
  }
  
  if (additionalPriceUnit === AdvertsPriceUnits.startingPrice) {
    return {
      translationString: 'listing.selectService.venueRentStartingAtPrice',
      data: { v: rentCost }
    };
  }

  return null;
}

function parseExcludedMinBudgetInfo(excludedFromGlobalMinBudget: boolean) {
  if (!excludedFromGlobalMinBudget) {
    return null;
  }

  return {
    data: null,
    translationString: 'listing.selectService.minimumGlobalBudgetDeducted'
  };
}