<div
  class="bztt-flex bztt-flex-col lg:bztt-mt-8 lg:bztt-flex-row bztt-justify-between bztt-w-full bztt-text-[#222222] lg:bztt-px-8 lg:bztt-pb-4 bztt-z-10 bztt-max-w-7xl bztt-gap-2"
>
  <div
    class="bztt-flex bztt-flex-col lg:bztt-mt-0"
    *ngFor="let category of categories"
  >
    <div class="bztt-flex bztt-flex-col">
      <ng-container *ngFor="let typeInfo of category.items">
        <ng-container
          *ngIf="
            !typeInfo.mobileOnly || (typeInfo.mobileOnly && (isMobile$ | async)) || skipMobileCheck
          "
        >
          <a
            class="category bztt-flex bztt-items-center bztt-border-0 bztt-gap-2 bztt-mb-3 last:bztt-mb-0 bztt-select-none hover:bztt-text-[white] orange-transform"
            routerLink="/{{
              typeInfo.link || typeInfo.temp_redirrect || typeInfo.title
            }}"
            [state]="state"
            (click)="typeSelectedHandle(typeInfo.title)"
          >
            <img
              class="bztt-h-7 bztt-w-9 bztt-object-contain"
              [src]="iconData[typeInfo.iconName]"
            />
            <span class="bztt-text-start">{{
              'categoriesMarketplace.' + typeInfo.title | translate
            }}</span>
          </a>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
