/* eslint-disable @angular-eslint/directive-selector */
import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({ selector: '[suffixCenter]' })
export class InputSuffixCenterDirective implements AfterViewInit {
    constructor(
        private elementRef: ElementRef<HTMLAnchorElement>
    ) {
        
    }

    ngAfterViewInit(): void {
        const element: HTMLDivElement | null = this.elementRef.nativeElement.parentNode as any;
        if (element) {
            // element.style.position = 'unset';
            element.style.alignSelf = 'center';
            element.style.lineHeight = '1';
        }
    }
}

/* 
element.style {
    position: unset;
    align-self: center;
}
*/
