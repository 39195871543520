import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { of, Subject, timer } from 'rxjs';
import { delay, map, scan, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { MobileMenuComponent } from '../../../../core/components/header/mobile-menu/mobile-menu.component';
import { LayoutService } from '../../../../shared/services/layout.service';
import { ProfileDropdownComponent } from '../profile-dropdown/profile-dropdown.component';
import { HEADER_SERVICE } from '../services/header-service.di';
import { HeaderServiceInterface } from '../services/header-service.interface';
import { JarvisAuthService } from '@jarvis/auth';
import { MessagingService } from '@jarvis/messaging';
import { JarvisNotificationService } from '@jarvis/notifications';
import { DOMAIN_COUNTRY, JarvisLanguageService } from '@jarvis/services';

const mockNotifChange$ = timer(0, 1000).pipe(
  scan(acc => {
    if (acc === 10) {
      return 0;
    }

    return ++acc;
  }, 0),
  takeWhile(x => x < 11)
);

const MOBILE_MENU_WIDTH = 574;

@Component({
  selector: 'app-header-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class HeaderMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('profile') profileRef: ElementRef;
  onlyIcon = false;

  private bookingNotificationCount$ = this.notificationService.newBookingCount$.pipe(
    map((data: any) => this.domainCountry=='us'? null : data)
  );


  links = [
    {
      path: 'overview',
      label: 'overview',
      icon: 'bar_chart'
    },
    {
      path: 'services',
      label: 'services',
      icon: 'celebration'
    },
    {
      path: 'calendar',
      label: 'calendar',
      icon: 'date_range'
    },
    {
      path: 'bookings',
      label: 'bookings',
      icon: 'today',
      notifications: this.bookingNotificationCount$
    },
    {
      path: 'messages',
      label: 'messages',
      icon: 'chat',
      notifications: this.notificationService.newMessagesCount$
    },
    {
      path: 'partner-list',
      label: 'partnerList',
      icon: 'handshake'
    },
    {
      path: 'sponsored-listings',
      label: 'sponsored',
      icon: 'campaign'
    },

    // {
    //   path: 'pricing',
    //   label: 'pricing',
    //   icon: 'card_travel'
    // },
    /*{
      path: 'video-presentation',
      label: 'videoPresentation',
      icon: 'videocam'
    }, */
    /* {
      label: 'more',
      icon: 'menu',
      dropdown: MoreDropdownComponent
    }, */
    /* {
      label: 'language',
      icon: 'language',
      path: ''
    } */
  ];

  profileDropdown = ProfileDropdownComponent;

  isLoggedIn$ = this.authService.isLoggedIn$;
  delayedIsLoggedIn$ = this.authService.isLoggedIn$.pipe(switchMap(v=>v?of(v):of(v).pipe(delay(800))));
  userData$ = this.authService.userData$;

  isMobile$ = this.breakpointObserver.observe([`(max-width: ${MOBILE_MENU_WIDTH}px)`]).pipe(
    map(state => state.matches)
  );
  private destroy$ = new Subject<void>();
  private lastWidth = 0;

  constructor(
    private messagingService: MessagingService,
    private notificationService: JarvisNotificationService,
    private authService: JarvisAuthService,
    private layoutService: LayoutService,
    private languageService: JarvisLanguageService,
    private router: Router,
    @Inject(HEADER_SERVICE) public headerService: HeaderServiceInterface,
    private elementRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string,
  ) {  }

  ngOnInit(): void {
    // this.isMobile$ = this.layoutService.isMobileObserver;
  }

  ngAfterViewInit(): void {
    this.lastWidth = this.elementRef.nativeElement.scrollWidth;

    this.headerService.headerWidth$.pipe(takeUntil(this.destroy$)).subscribe((widths) => {
      const navBarWidth = widths[1];
      // For testing. Later we will calculate through children
      const withoutLogoWidth = navBarWidth - 200;
      // console.log(this.lastWidth, withoutLogoWidth);

      const nowValue = this.onlyIcon;

      if (this.lastWidth <= withoutLogoWidth) {
        this.onlyIcon = false;
      } else {
        this.onlyIcon = true;
        // this.lastWidth = this.elementRef.nativeElement.scrollWidth;
      }

      if (nowValue !== this.onlyIcon) {
        this.cdRef.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openLogin(): void {
    this.router.navigateByUrl('/auth/login');
  }

  openRegister(): void {
    this.router.navigateByUrl('/auth/register');
  }

  openProfileDropdown(): void {
    this.layoutService.createOverlay(ProfileDropdownComponent, this.profileRef);
  }

  changeLanguage(): void {
    this.languageService.toggleLanguage();
  }

  openMobileMenu(): void {
    this.layoutService.createMobileMenuOverlay(MobileMenuComponent);
  }

}
