import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, TemplateRef, Type, ViewChild, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JarvisServiceLikesService } from '@jarvis/services/likes';
import { LayoutService } from '@jarvis/ui/layout';
import { Observable, Subject, of } from 'rxjs';
import { delay, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { LastLikeDropdownComponent } from '../last-like-dropdown/last-like-dropdown.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'marketplace-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent implements OnInit {
  @ViewChild('lastLikeDropdownTrigger') lastLikeDropdownTrigger: MatMenuTrigger;

  @Input() iconName;
  @Input() notifications: Observable<string | number>;
  @Input() dropdown: Type<any>;
  @Input() link: string;
  @Input() imageLink: string | null = null;
  @Input() onlyIcon = false;
  @Input() routerLinkActive: string;
  @Input() disableLink = false;
  @Input() showArrow = false;
  @Input() activeOn: string[];
  @Input() state: any = null;

  @ViewChild('iconOutlet') iconOutlet: TemplateRef<any>;


  currentUrl$: Observable<string>;

  likesService = inject(JarvisServiceLikesService);
  destroy$ = new Subject<void>();
  lastLike$ = this.likesService.lastLikeSubject.asObservable();

  constructor(
    private layoutService: LayoutService,
    private elementRef: ElementRef,
    private router: Router,
    private bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
    this.currentUrl$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => {
        return e.url;
      }),
      startWith(this.router.url)
    );

    if (this.link === 'planner' || this.link === 'planuoklis') {
      this.likesService.lastLikeSubject.pipe(
        filter((like) => !!like),
        delay(500),
        takeUntil(this.destroy$)
      ).subscribe((like) => {
        if (!this.layoutService.isMobile) {
          this.lastLikeDropdownTrigger.openMenu();
          setTimeout(() => {
            this.lastLikeDropdownTrigger.closeMenu();
          }, 10000);
        }
        if (this.layoutService.isMobile) this.openLastLikeMobile(like);
      });
    }
  }

  openDropdown(clickEvent: MouseEvent): void {
    if (this.dropdown) {
      clickEvent.preventDefault();
      this.layoutService.createOverlay(this.dropdown, this.elementRef);
    }
  }

  openLastLikeMobile(likeData): void {
    this.bottomSheet.open(LastLikeDropdownComponent, {
      hasBackdrop: false,
      data: likeData
    });
    setTimeout(() => {
      this.bottomSheet.dismiss();
    }, 4000);
  }

  closeLikeDropdown() {
    this.lastLikeDropdownTrigger.closeMenu();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
