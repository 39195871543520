<div class="modal-wrapper medium-modal">
    <div class='close-container'>
        <button class='close-button' (click)="close()">
            <a>{{'reviews.invite.close' | translate}}</a>
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    
    <div *ngIf="!isSuccess" class='form-container'>
        <h2>
            {{'reviews.invite.getReviewsFromCustomers' | translate}}
        </h2>

        <div class='contacts-container' *ngFor="let form of contactForms.controls; let i = index;" [formGroup]="form">
            <mat-form-field>
                <mat-label>{{ 'reviews.contacts.name' | translate }}</mat-label>
                <input autocomplete="given-name" matInput formControlName="name" />
                <mat-error>{{'errors.fieldRequired' | translate}}</mat-error>
            </mat-form-field>
        
            <mat-form-field>
                <mat-label>{{ 'reviews.contacts.surname' | translate }}</mat-label>
                <input autocomplete="family-name" matInput formControlName="surname" />
                <mat-error>{{'errors.fieldRequired' | translate}}</mat-error>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>{{ 'reviews.contacts.email' | translate }}</mat-label>
                <input autocomplete="email" type="email" matInput formControlName="email" />
                <mat-error>{{'errors.fieldRequired' | translate}}</mat-error>
            </mat-form-field>
        
            <mat-form-field>
                <mat-label>{{ 'reviews.contacts.phoneNo' | translate }}</mat-label>
                <input autocomplete="tel" type="tel" matInput formControlName="phoneNo" />
                <mat-error>{{'errors.fieldRequired' | translate}}</mat-error>
            </mat-form-field>
        
            <a *ngIf="i !== 0" (click)="removeForm(i)">
                {{'reviews.invite.removeCustomer' | translate}}
            </a>
        </div>
        
        <div class='actions'>
            <div>
                <mat-icon>add</mat-icon>
                <a (click)="addForm()">
                    {{'reviews.invite.addCustomer' | translate}}
                </a>
            </div>
        
            <div>
                <a  (mouseenter)="$event.stopImmediatePropagation()" 
                    (mouseleave)="$event.stopImmediatePropagation()"
                    #copiedTooltip="matTooltip"
                    matTooltip="{{'reviews.invite.copiedToClipboard' | translate}}"
                    (click)="generateLinkToClipboard()"
                >
                    {{'reviews.invite.copyInviteLink' | translate}}
                </a>
            </div>
        </div>
        
        <div class='submit-button'>
            <button app-button color="accent" (click)="submit()">
                {{'reviews.invite.sendRequest' | translate}}
            </button>
        </div>
    </div>

    <jarvis-reviews-success-engage 
        [successHeader]="'invitationSuccess'"
        *ngIf="isSuccess"></jarvis-reviews-success-engage>
</div>
