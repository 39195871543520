import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[playInView]' })
export class PlayInViewDirective implements AfterViewInit {

    @Input() set playInView(value: string) {
        // Parsed number
        const numValue = +value;
        if (!isNaN(numValue)) {
            this.playThreshold = numValue;
        }
    }

    // Default threshold - fully visible
    private playThreshold = 1;

    constructor(private elRef: ElementRef) { }

    ngAfterViewInit(): void {
        const videoEl: any = this.elRef.nativeElement;

        // Otherwise we can't control the video
        videoEl.muted = true;

        if (!('IntersectionObserver' in window)) {
            videoEl.autoplay = true;
            videoEl.play();
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio <= this.playThreshold) {
                        videoEl.pause();
                    } else {
                        videoEl.play();
                    }
                });
            },
            { threshold: this.playThreshold }
        );

        observer.observe(videoEl);
    }
}