import { Pipe, PipeTransform } from '@angular/core';
import { ChatRoom } from '../../types/messaging.types';

@Pipe({
    name: 'ListingLink'
})

export class ListingLinkPipe implements PipeTransform {
    transform(chatroom: ChatRoom): string | null {
        return null;
    }
}