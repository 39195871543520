import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from '../button/button.module';
import { CauseOfCancellationComponent } from './cause-of-cancellation.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { JarvisCardWrapperModule } from "../card-wrapper/card-wrapper.module";
import { JarvisUiModalWrapperModule } from "../modal-wrapper/modal-wrapper.module";

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    MatDialogModule, 
    TranslateModule,
    MatRadioModule,
    MatIconModule,  
    MatInputModule,
    MatButtonModule,
    JarvisCardWrapperModule,
    JarvisUiModalWrapperModule

  ],
  exports: [
    CauseOfCancellationComponent
  ],
  declarations: [
    CauseOfCancellationComponent
  ],  
  providers: [
  ]  
})
export class JarvisCauseOfCancellationModule { }