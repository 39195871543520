import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'jarvis-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true
    }
  ]
})
export class SliderComponent implements OnInit, ControlValueAccessor {

  @Input() color: string;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  state = false;

  @Output() valueChanged = new EventEmitter<boolean>();

  onChange: any = () => { };
  onTouch: any = () => { };

  @Input()
  set value(val: boolean) {
    if (val !== undefined && this.state !== val) {
      this.state = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  toggle(): void {
    if (this.disabled) {
      return;
    }

    this.writeValue(!this.state);
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged.next(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

}
