<app-modal-wrapper
  (closed)="handleClose()"
  [closeButton]="true"
  class="modal-wrapper"
>
  <div
    class="bztt-flex bztt-flex-col bztt-gap-y-4 bztt-justify-center bztt-max-w-[500px] bztt-p-8 bztt-h-[90%]"
  >
    <img
      class="bztt-rounded-[15px]"
      src="assets/images/landing/pricing-popup-image-min.jpg"
      alt="auders-photography"
    />

    <div class="pricing-title bztt-text-xl bztt-font-semibold bztt-mt-4">
      {{ 'landing.saas.pricingModal.title' | translate }}
    </div>
    <div class="pricing-description bztt-text-sm bztt-font-normal bztt-mb-4">
      {{ 'landing.saas.pricingModal.description' | translate }}
    </div>

    <button
      app-button
      class="vendor-hero-getstarted"
      color="accent"
      (click)="handleSignup()"
    >
      {{ 'landing.saas.createPage' | translate }}
    </button>
  </div>
</app-modal-wrapper>
