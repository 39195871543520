import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, take } from 'rxjs/operators';
import { TermsConditionsModalComponent } from '@jarvis/foxy-vendor/app/site/profile/terms-conditions-modal/terms-conditions-modal.component';
import { DemoRequestModalComponent } from '../components/demo-request-modal/demo-request-modal.component';
import { ModalService } from './modal.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonDialogService } from './common-dialog.service';
import { JarvisAuthService } from '@jarvis/auth';
import { environment } from '@breezit/vendor/environments/environment';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SharedService {

  constructor(
    private modalService: ModalService,
    private commonDialogService: CommonDialogService,
    private clipboard: Clipboard,
    private translationService: TranslateService,
    private httpService: HttpClient,
    private authService: JarvisAuthService
  ) {
  }


  async openDemoRequestModal() {
    // await this.registerUserContactRequest('DemoRequest');
    this.commonDialogService.openCustomDialog(DemoRequestModalComponent);
  }

  openTermsAndConditionsModal(activePage: 'rules' | 'privacy' = 'rules'): void {
    this.modalService.openDialog(TermsConditionsModalComponent, {
      data: {
        activePage
      }
    });
  }

  copyTextToClipboard(text: string): void {
    this.clipboard.copy(text);
  }

  async registerUserContactRequest(type, additionalInfo?: any) {
    const endpoint = `${environment.baseUrl}api/marketplace/contact`;

    const user = await this.authService.userData$.pipe(take(1)).toPromise();

    if (!user) return;

    const data = {
      type: type,
      data: {
        userId: user._id,
        status: 'NEW',
        ...additionalInfo
      }
    };

    await this.httpService.post<any>(endpoint, data).toPromise();
  }

  userContactRequest(type: string, additionalInfo?: Record<string, any>) {
    const endpoint = `${environment.baseUrl}api/marketplace/contact`;

    return this.authService.userData$.pipe(
      take(1),
      switchMap((user) => {
        if (!user) {
          return throwError(() => new Error('Not authenticated'));
        }

        const data = {
          type: type,
          data: {
            userId: user._id,
            status: 'NEW',
            ...additionalInfo
          }
        };

        return this.httpService.post(endpoint, data);
      })
    );
  }

}
