<app-modal-wrapper
  class="modal-wrapper profile-selector-container bztt-min-w-full md:bztt-min-w-[500px]"
  (closed)="close()"
>
  <div class="bztt-p-5">
    <div class="bztt-mb-6 title">{{ "landing.homepage.chooseProfile" | translate }}</div>

    <div
      class="bztt-flex bztt-flex-col bztt-gap-y-7 md:bztt-flex-row md:bztt-gap-x-7"
    >
      <button app-button (click)="submitProfile('vendor')">
        {{ "landing.homepage.profileVendor" | translate }}
      </button>
      <button app-button (click)="submitProfile('user')">
        {{ "landing.homepage.eventHost" | translate }}
      </button>
    </div>
  </div>
</app-modal-wrapper>
