import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { RouterModule, Routes } from '@angular/router';
import {
  ButtonModule,
  JarvisCardWrapperModule,
  JarvisOverlayComponent,
  JarvisUiActionsCardModule,
} from '@jarvis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MessagesBookingInfoComponent } from './booking-info/booking-info.component';
import { MessagingChatMessagesComponent } from './chat-messages/chat-messages.component';
import { MessagesChatroomsComponent } from './chatrooms/chatrooms.component';
import { FormFieldNoPaddingDirective } from './directives/form-field-no-padding.directive';
import { InputSuffixCenterDirective } from './directives/input-suffix-center.directive';
import { JarvisMessagesComponent } from './messages.component';
import { ChatUserNamePipe } from './pipes/chat-username.pipe';
import { ShowTimePassedPipe } from './pipes/next-user-different.pipe';
import { ReadByBothPipe, ReadByUserPipe } from './pipes/read-by-user.pipe';
import { TimePassedPipe } from './pipes/time-passed.pipe';
import { MessagingChatHeaderComponent } from './chat-header/chat-header.component';
import { MessagingBookingsListMobileComponent } from './bookings-list-mobile/bookings-list-mobile.component';
import { MessagingChatMessagesMobileComponent } from './chat-messages-mobile/chat-messages-mobile.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { LightgalleryModule } from 'lightgallery/angular';
import { MessagesNoMessagesComponent } from './no-messages/no-messages.component';
import { ChatListingNamePipe } from './pipes/chat-listing-name.pipe';
import { PickerModule as EmojiPickerModule } from '@ctrl/ngx-emoji-mart';
import { TimerPipe } from './pipes/timer.pipe';
import { ServiceNamePipe } from './pipes/service-name.pipe';
import { ShowActionsInlinePipe } from './pipes/show-actions-inline.pipe';
import { MessagesOverlayMenuComponent } from './overlay-menu/overlay-menu.component';
import { MessagesChatMessagesActionsComponent } from './chat-messages/chat-actions/chat-actions.component';
import { FormFieldNoInfixDirective } from './directives/form-field-no-infix.directive';
import { MessagesChatBookingContainerComponent } from './chat-booking-container/chat-booking-container.component';
import { CustomerPhoneNoPipe } from './pipes/customer-phone-no.pipe';
import { PhoneNoContainerComponent } from './phone-no-container/phone-no-container.component';
import { JarvisUiPhoneNoDisplayModule } from '@jarvis/ui/phone-no-display';
import { ListingLinkPipe } from './pipes/listing-link.pipe';
import { ServicePipesModule } from '@jarvis/services';
import { ImageErrorDirective, UrlifyPipe } from '@jarvis/utils';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MessagesChatroomsFiltersComponent } from './chatrooms-filters/chatrooms-filters.component';
import { MessagesChatroomsInformationComponent } from './chatrooms-information/chatrooms-information.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ImagePreviewPipe } from './pipes/image-preview.pipe';
import { MouseScrollableDirective } from './directives/scrollable.directive';
import { OnlyImagesPipe } from './pipes/mixed-content.pipe';
import { ChatAutoreplyComponent } from './chat-messages/chat-autoreply/chat-autoreply.component';

const ROUTES: Routes = [
    {
      path: '',
      component: JarvisMessagesComponent,
      children: [
        {
            path: 'new',
            component: MessagesChatBookingContainerComponent,
            data: { newChatRoom: true }
        },  
        {
          path: ':messageId',
          component: MessagesChatBookingContainerComponent,
        },
      ],
    },
  ];

@NgModule({
  imports: [
    EmojiPickerModule,
    ServicePipesModule,
    JarvisUiPhoneNoDisplayModule,
    CommonModule,
    ButtonModule,
    ReactiveFormsModule,
    LightgalleryModule,
    JarvisCardWrapperModule,
    JarvisUiActionsCardModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    TranslateModule,
    OverlayModule,
    PortalModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    ImageErrorDirective,
    MatTooltipModule,
    ChatAutoreplyComponent,
    JarvisOverlayComponent,
    ImagePreviewPipe,
    OnlyImagesPipe,
    ShowActionsInlinePipe,
    TimePassedPipe,
    ReadByUserPipe,
    ReadByBothPipe,
    ShowTimePassedPipe,
    UrlifyPipe,
    MessagesChatMessagesActionsComponent,
    RouterModule.forChild(ROUTES)
  ],
  exports: [JarvisMessagesComponent, MessagingChatMessagesComponent],
  declarations: [
    JarvisMessagesComponent,
    MessagesChatroomsComponent,
    MessagingChatMessagesComponent,
    MessagesBookingInfoComponent,
    InputSuffixCenterDirective,
    MessagesChatBookingContainerComponent,
    FormFieldNoPaddingDirective,
    MessagesNoMessagesComponent,
    MessagesOverlayMenuComponent,
    FormFieldNoInfixDirective,
    ChatUserNamePipe,
    ServiceNamePipe,
    ChatListingNamePipe,
    TimerPipe,
    CustomerPhoneNoPipe,
    ListingLinkPipe,
    MessagingChatHeaderComponent,
    MessagingBookingsListMobileComponent,
    MessagingChatMessagesMobileComponent,
    PhoneNoContainerComponent,
    MessagesChatroomsFiltersComponent,
    MessagesChatroomsInformationComponent,
    MouseScrollableDirective,
  ],
  providers: [DatePipe],
})
export class JarvisMessagesModule {}
