import { Injectable, OnDestroy, inject } from '@angular/core';
import { MapsAPILoader } from '@jarvis/agm-map';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import { BehaviorSubject, Observable, catchError, filter, finalize, from, of, switchMap, take } from 'rxjs';

@Injectable()
export class GoogleMapsLocationFilterAutocompleteService implements OnDestroy {
  
  private country = inject(DOMAIN_COUNTRY);
  private mapsApiLoader = inject(MapsAPILoader);

  private autocompleteService = new BehaviorSubject<google.maps.places.AutocompleteService>(null);
  private placesService = new BehaviorSubject<google.maps.places.PlacesService>(null);

  private autocompleteOptions = {
    componentRestrictions: {
      country: this.country
    },
    types: ['administrative_area_level_2', 'administrative_area_level_1', 'locality'],
    bounds: null
  };

  

  constructor() {
    this.mapsApiLoader.load().then(() => {

      const dummyDiv = document.createElement('div');

      this.autocompleteOptions.bounds = new google.maps.LatLngBounds(  
        new google.maps.LatLng(32.209001452772796, -122.63619280604286),  
        new google.maps.LatLng(36.6345025610656, -114.35861886684116)
      );

      this.autocompleteService.next(new google.maps.places.AutocompleteService());
      this.placesService.next(new google.maps.places.PlacesService(dummyDiv));
    });
  }

  ngOnDestroy(): void {
    this.autocompleteService.complete();
    this.placesService.complete();
  }

  getGoogleSuggestions(query: string): Observable<google.maps.places.AutocompletePrediction[]> {
    return this.autocompleteService.pipe(
      filter(service => !!service),
      switchMap((service) => {
        return this.wrapCallbackToObservable(
          service,
          service.getPlacePredictions,
          {
            ...this.autocompleteOptions,
            input: query
          }
        ) as Observable<any>
      }),
      catchError(() => {
        return of(null);
      }),
      take(1)
    );
  }

  getPlaceResult(placeId: string): Observable<google.maps.places.PlaceResult> {
    return this.placesService.pipe(
      filter(service => !!service),
      switchMap((service) => {
        return this.wrapCallbackToObservable(
          service,
          service.getDetails,
          {
            placeId
          }
        ) as Observable<any>
      }),
      catchError(() => {
        return of(null);
      }),
      take(1)
    )
  }

  private wrapCallbackToObservable(thisScope, fn, parameters) {
    const promise = new Promise((resolve, reject) => {
      fn.call(thisScope, ...[parameters, (res, status) => {
        if (status !== 'OK') {
          reject(status);
          return;
        }

        resolve(res);
      }]);
    });

    return from(promise);
  }
  
}