import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DOMAIN_COUNTRY } from '@jarvis/services';

@Component({
  selector: 'app-demo-request-modal',
  templateUrl: './demo-request-modal.component.html',
  styleUrls: ['./demo-request-modal.component.scss']
})
export class DemoRequestModalComponent implements OnInit {

  closeButton = false;

  constructor(
    @Optional() private dialogRef: MatDialogRef<DemoRequestModalComponent>,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string
  ) { }

  ngOnInit(): void {
    if (this.dialogRef) {
      this.closeButton = true;
    }
  }

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

}
