import { NgModule } from '@angular/core';
import { MatCommonModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { AppButtonComponent } from './button';

@NgModule({
    imports: [
        MatRippleModule,
        MatCommonModule,
        MatIconModule,
        CommonModule
    ],
    exports: [
        AppButtonComponent,
        MatCommonModule,
    ],
    declarations: [
        AppButtonComponent
    ]
})
export class AppButtonModule { }
