<ng-container *ngIf="(isMobile$ | async) === false; else mobileView">
    <div class="bztt-p-3 bztt-overflow-hidden">
      <div class='message-header-container bztt-border bztt-rounded-lg'>
        <messaging-chat-header></messaging-chat-header>
        <messaging-chat-messages></messaging-chat-messages>
      </div>
    </div>
    <!-- <div class="booking-info-container">
        <messaging-booking-info></messaging-booking-info>
    </div>  -->
</ng-container>

<ng-template #mobileView>
    <messaging-bookings-list-mobile
        (closed)="closeBookingView()"
        *ngIf="bookingInfoOpen"
    >
    </messaging-bookings-list-mobile>
    <messaging-chat-messages-mobile
        [class.hidden]="bookingInfoOpen"
        (openBookingInfo)="openBookingInfo()"
    ></messaging-chat-messages-mobile>
</ng-template>