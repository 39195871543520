<div
  class="signup-wrapper bztt-flex bztt-h-full bztt-w-full"
  *ngIf="!layoutService.isMobile"
>
  <div class="signup-background bztt-h-full bztt-w-full"></div>
  <div class="bztt-flex bztt-flex-row signup-form bztt-shadow-md">
    <div
      class="bztt-flex bztt-flex-col bztt-justify-center bztt-items-center bztt-py-14"
    >
      <div
        class="bztt-max-w-[440px] bztt-flex bztt-flex-col bztt-justify-center bztt-items-center"
      >
        <!-- <img
      src="/assets/images/bzt-logo.svg"
      alt="Breezit Logo"
      width="90px"
      class="bztt-mb-10"
      /> -->

        <div
          class="title bztt-font-bold bztt-text-black bztt-mb-12 bztt-text-center bztt-text-[24px]"
        >
          {{ 'landing.saas.signUpPage.createProfile' | translate }}
        </div>
      </div>
      <jarvis-auth-register
        [standalone]="true"
        [predefinedUserType]="'vendor'"
        [hideClose]="true"
      ></jarvis-auth-register>
    </div>
    <div class="quate-container bztt-items-center">
      <div
        *ngIf="!isProfileLink"
        class="close-wrapper"
        (click)="redirectToVendorsLanding()"
      >
        <span>{{ 'landing.saas.signUpPage.back' | translate }}</span>
      </div>
      <div
        class="bztt-w-[360px] bztt-h-full bztt-px-16 bztt-py-20 bztt-flex bztt-items-center bztt-justify-center"
      >
        <div class="bztt-flex-col">
          <img
            class="bztt-rounded-full bztt-h-44 bztt-w-44 bztt-mb-7 bztt-font-light"
            [src]="endorsment.image"
            [alt]="endorsment.title | translate"
          />

          <p
            class="bztt-text-left bztt-text-black bztt-text-[17px] bztt-font-light"
          >
            {{ endorsment.description | translate }}
          </p>
          <p class="title-vendor bztt-text-[17px] bztt-mt-5">
            {{ endorsment.title | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="layoutService.isMobile"
  class="bztt-h-full bztt-flex bztt-justify-center bztt-items-center"
>
  <div class="bztt-flex bztt-flex-col bztt-justify-center bztt-items-center">
    <div class="bztt-p-8">
      <div
        class="bztt-max-w-[440px] bztt-flex bztt-flex-col bztt-justify-center bztt-items-center"
      >
        <div
          class="title bztt-font-bold bztt-text-black bztt-mb-12 bztt-text-center bztt-text-[24px] bztt-mt-10"
        >
          {{ 'landing.saas.signUpPage.createProfile' | translate }}
        </div>
      </div>
      <jarvis-auth-register
        [standalone]="true"
        [predefinedUserType]="'vendor'"
      ></jarvis-auth-register>
    </div>

    <div
      class="quate-container-mobile bztt-items-center bztt-justify-center bztt-flex bztt-flex-col bztt-pb-14"
    >
      <div
        class="bztt-w-full bztt-p-10 bztt-flex bztt-items-center bztt-justify-center"
      >
        <div
          class="bztt-flex bztt-flex-col bztt-items-center bztt-justify-center"
        >
          <img
            class="bztt-rounded-full bztt-h-44 bztt-w-44 bztt-mb-7"
            [src]="endorsment.image"
            [alt]="endorsment.title | translate"
          />

          <p
            class="bztt-text-center bztt-text-black bztt-text-[17px] bztt-font-light"
          >
            {{ 'landing.saas.signUpPage.customerReview1' | translate }}
          </p>
          <p
            class="title-vendor bztt-text-center bztt-text-[17px] bztt-mt-5 bztt-font-semibold"
          >
            {{ 'landing.saas.signUpPage.customerName1' | translate }}
          </p>
        </div>
      </div>
      <div
        *ngIf="!isProfileLink"
        class="close-wrapper bztt-underline bztt-font-bold"
        (click)="redirectToVendorsLanding()"
      >
        <span>{{ 'landing.saas.signUpPage.back' | translate }}</span>
      </div>
    </div>
  </div>
</div>
