<div class="review-scores">
  <div class="scores bztt-mr-2">
    <span
      >{{ 'reviews.score' | translate | titlecase }}
      {{ data?.reviewsBase?.score || 0 | number: '1.0-1' }}</span
    >
    <jarvis-reviews-review-stars
      [value]="data?.reviewsBase?.score"
      [interactive]="interactive"
    ></jarvis-reviews-review-stars>
    <span
      >{{ 'reviews.reviewsCount' | translate }}
      {{ data?.reviewsBase?.reviewsCount || 0 | number: '1.0-1' }}</span
    >
  </div>

  <div class="individual-score-container">
    <jarvis-reviews-individual-scores
      [data]="data?.reviewsBase?.individualScores"
    ></jarvis-reviews-individual-scores>
  </div>
</div>
