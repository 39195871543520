import { VendorSignupPageComponent } from './components/vendor-signup-page/vendor-signup-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JarvisAuthLoginComponent } from './components/login/login.component';
import { JarvisAuthRegisterComponent } from './components/register/register.component';
import { ButtonModule, JarvisUiModalWrapperModule } from '@jarvis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { JarvisAuthPasswordRemindModalComponent } from './components/password-remind-modal/password-remind-modal.component';
import { JarvisAuthEmailConfirmedModalComponent } from './components/email-confirmed-modal/email-confirmed-modal.component';
import { JarvisAuthForgotPasswordModalComponent } from './components/forgot-password-modal/forgot-password.component';
import { RouterModule } from '@angular/router';
import { JarvisAuthPasswordChangeModalComponent } from './components/password-change-modal/password-change-modal.component';
import { SignupStandalonePageComponent } from './components/signup-standalone-page/signup-standalone-page.component';
import { LoginStandalonePageComponent } from './components/login-standalone-page/login-standalone-page.component';
// import { SocialLoginModule } from '@abacritt/angularx-social-login';

const EXPORT_COMPONENTS = [
  JarvisAuthPasswordRemindModalComponent,
  JarvisAuthEmailConfirmedModalComponent,
  JarvisAuthForgotPasswordModalComponent,
  JarvisAuthPasswordChangeModalComponent,
  JarvisAuthRegisterComponent,
  JarvisAuthLoginComponent
];

@NgModule({
  declarations: [
    ...EXPORT_COMPONENTS,
    SignupStandalonePageComponent,
    LoginStandalonePageComponent,
    VendorSignupPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    JarvisUiModalWrapperModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  exports: [
    ...EXPORT_COMPONENTS
  ]
})
export class JarvisAuthModule { }
