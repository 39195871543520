import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../../../environments/environment'
import { CommonDialogService } from '@jarvis/ui';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalStripeAccountComponent } from './modal-stripe-account.component';

@Injectable()
export class PaymentsService {


  // "connectConfirmMsg"
  // "alreadyConnectedMsg"
  // "returnMsg"
  // "reconnectConfirmMsg"
  // "refreshMsg"


  constructor(private httpService: HttpClient,
    private commonDialogService: CommonDialogService,
    private router: Router    
  ) { }

  public checkRoute(route: ActivatedRoute, router: Router, spinner: BehaviorSubject<boolean>):boolean {

    if ("refresh" in route.snapshot.queryParams) {
      router.navigate(
        [],
        {
          relativeTo: route,
          queryParams: { refresh: null },
          queryParamsHandling: 'merge'
        });
      this.commonDialogService.openConfirm("payments.stripe.refreshMsg")      
        .afterClosed().subscribe(result => { if (result) this.connectStripe(route.snapshot.data, spinner); });
      return false;  

    } else
    if ("success" in route.snapshot.queryParams) {
      router.navigate([],
        {
          relativeTo: route,
          queryParams: { success: null },
          queryParamsHandling: 'merge'
        });
      this.commonDialogService.openSuccess("payments.stripe.returnMsg");
      return false;
    }
    return true; 
  }

  public connect(data: any, spinner: BehaviorSubject<boolean>, provider?: string) {

    this.getStripeAccount(data.listing.entityInfo.entity).subscribe(
      (stripeAccountData) => {
        // Message stripe not connected
        if (stripeAccountData.requirements?.pending_verification?.length>0) {
          const reconnectModalRef = this.commonDialogService.openConfirm("payments.stripe.pendingMsg");            
        } 
        else        
        if (!stripeAccountData.charges_enabled) {
          stripeAccountData.requirements?.errors.forEach(itm=>console.log(itm.reason));
          const reconnectModalRef = this.commonDialogService.openConfirm("payments.stripe.reconnectConfirmMsg");
            
          reconnectModalRef.afterClosed().subscribe(result => {
            if (result) this.connectStripe(data, spinner);
          });

        } else {
          const alreadyConnectedDialog = this.commonDialogService.openCustomWDialog(
            ModalStripeAccountComponent,
            {
              i18nKey: "payments.stripe.alreadyConnectedComponentMsg"
            },
            'stripe-account'
          );

          alreadyConnectedDialog.afterClosed().subscribe(result => {
            if (result) {
              spinner.next(true);
              this.getStripeLogin(data.listing.entityInfo.entity).subscribe(loginData => {
                spinner.next(false);
                window.location.href = loginData.url;
              });
            }
          });
        }
      },
      (error) => {
        if (error.status == 400)
          this.getProfileData().pipe(
            switchMap(profileData => 
            {
              const profile = profileData.find(item => item._id == data.listing.entityInfo.entity);
              let form = "connectConfirmComponentMsg";
              if (profile?.personType == "business") form = "connectConfirmCompanyComponentMsg";
              return this.commonDialogService.openCustomWDialog(ModalStripeAccountComponent, 
                { i18nKey: "payments.stripe." + form }, 'stripe-account').afterClosed();
            })
          ).subscribe(result => 
          { 
            if (result=="connect") this.connectStripe(data, spinner); 
            else if (result=="addExisting") this.connectExistingsStripeAccount(data); 
          }
          );
      });
  }

  private connectExistingsStripeAccount(data: any) {
    const state = encodeURIComponent(
      JSON.stringify({
        serviceBase: data.listing._id,
        url: window.location.href,
      }));

    const url = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${environment.stripeClientID}&scope=read_write&state=${state}&redirect_uri=${environment.saasUrl}/stripe/auth`;
    window.location.href = url;      
  }

  private connectStripe(data: any, spinner: BehaviorSubject<boolean>) {
    spinner.next(true);

    this.getProfileData().subscribe(
      profileData => {
        const profile = profileData.find(item => item._id == data.listing.entityInfo.entity);

        if (profile == null) {
          spinner.next(false);
          this.commonDialogService.openSuccess("payments.stripe.errorMsg", "error", null, "No profile found");
          return;
        }

        this.connectStripeAccount({
          country: data.listing?.address?.country ?? "US", "business_type": profile.personType == "business" ? "company" : "individual",
          "business_url": ((data.listing.contacts.siteLink == "" ? null : data.listing.contacts.siteLink) ?? "https://breezit.com"), "email": data.listing.contacts.email, profileId: profile._id,
          "refresh_url": window.location.href + "?refresh", "return_url": window.location.href + "?success"
        }).subscribe(
          response => { spinner.next(false); window.location.href = response.accountLink.url; },
          error => { spinner.next(false); this.commonDialogService.openSuccess("payments.stripe.errorMsg", "error", null, "Error: " + error.error.errors.map(x => x.message).join(", ")); }
        );
      });
  }

  processConnectExistingAccount(code: string, stateObj: any) {
    forkJoin([this.getProfileData(), this.getServiceBase(stateObj.serviceBase)]).pipe(
      switchMap(([profileData, serviceBase])=> {
        const profile = profileData.find(item => item._id == serviceBase.entityInfo.entity);
        if (profile == null) { this.commonDialogService.openSuccess("payments.stripe.errorMsg", "error", null, "No profile found"); return EMPTY; }
        return of({
          code: code,
          country: serviceBase?.address?.country ?? "US", "business_type": profile.personType == "business" ? "company" : "individual",
          "business_url": ((serviceBase.contacts.siteLink == "" ? null :serviceBase.contacts.siteLink) ?? "https://breezit.com"), "email": serviceBase.contacts.email, profileId: profile._id,
          "refresh_url": stateObj.url + "?refresh", "return_url": stateObj.url + "?success"
        }); 
      }),
      switchMap(v=>this.connectExistingStripeAccount(v)) 
    ).subscribe({
      next: (response) => { window.location.href = response.accountLink.url; },
      error: (error) => { this.commonDialogService.openSuccess("payments.stripe.errorMsg", "error", null, "Error: " + error.error.errors.map(x => x.message).join(", ")); }
    });
  }

  getProfileData(): Observable<any> {
    return this.httpService.get(`${environment.baseUrl}api/users/profile`);
  }

  getServiceBase(id:string): Observable<any> {
    return this.httpService.get(`${environment.baseUrl}api/serviceBase/${id}`);    
  }  

  connectStripeAccount(data: any): Observable<any> {
    return this.httpService.post(`${environment.baseUrl}api/stripe/createAccount`, data);
  }

  connectExistingStripeAccount(data: any): Observable<any> {
    return this.httpService.post(`${environment.baseUrl}api/stripe/connectExistingAccount`, data);
  }  

  getStripeAccount(profileId: string): Observable<any> {
    return this.httpService.get(`${environment.baseUrl}api/stripe/retrieveAccount/${profileId}`);
  }

  getStripeLogin(profileId: string): Observable<any> {
    return this.httpService.get(`${environment.baseUrl}api/stripe/getLogin/${profileId}`);
  }

}