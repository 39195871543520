import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { Observable, of, Subject, timer } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { BASE_URL } from '../tokens/base-url.token';
import { AdvertsPriceUnits } from '../types/service-price-units.types';
import { ServiceEventTypes } from '../types/service-types.enum';
import { CalendarEvent } from 'angular-calendar';
import {
  startOfMonth,
  endOfMonth,
  startOfDay,
  endOfDay,
  eachDay,
  isSameDay,
} from 'date-fns';
import { ExtractedServices, ServiceTypes } from './pricing-calculator';
import {
  format,
  getHours,
  setHours,
  getMinutes,
  setMinutes,
  addMinutes,
  isWithinRange,
  isBefore,
  differenceInDays,
} from 'date-fns';
import { ServiceBase, BookingsUtil } from '@jarvis/types';
import { MarketplaceListing } from '../types/marketplace.types';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils, roundToTwoPrecision } from '@jarvis/utils';
import { PixelService } from 'ngx-pixel';
import { JarvisSearchParamStoreService } from './search-param-store/search-param-store.service';
import {
  getSessionId,
  getUserId,
  setUserId,
} from '@amplitude/analytics-browser';
import { formatDate } from '@angular/common';
import { JarvisTrackingService } from './tracking.service';
import { DOMAIN_COUNTRY } from './url.utils';

declare const pintrk;

export type NewBookingType =
  | 'viaListing'
  | 'viaPersonalListing'
  | 'addedManually'
  | 'viaShorts';

export interface ServiceDateParameters {
  startDate: Date;
  endDate?: Date;
  timeDate?: Date;
  timeDateEnd?: Date;
  timePerClient: number | null;
}

export interface NewBookingData {
  serviceInfo: any;
  serviceType: string;
  date?: Date;
  timeDate?: Date;
  discountInfo: any;
  serviceVariantData: ExtractedServices;
  customer: any;
  message?: string | null;
  options?: {
    queryParams?: any;
    noEventDate?: boolean;
    guestNo?: number;
    anonUserMessage?: string;
    morePhotosRequest?: boolean;
    eventTypeInFilter?: string;
    viaRecommended?: boolean;
    source?: string[];
    amplitudeSessionId?: number;
    adminData?: {
      planningStatus: string;
      planningStatusUpdated?: string;
    };
  };
  bookingRequest: NewBookingType;
  _id?: string;
  cashback?: number;
}

export interface EmptyBookingData {
  serviceInfo: any;
  serviceType: string;
  customer: any;
  eventDate: any;
}

export interface ServiceEnableDisableParams {
  enabled: boolean;
  serviceBaseId?: string;
  serviceEventId?: string;
}

@Injectable({ providedIn: 'root' })
export class ServicesService {
  listingReload$ = new Subject<void>();
  serviceSelected$ = new Subject<void>();

  jarvisSearchParamStoreService = inject(JarvisSearchParamStoreService);
  private trackService = inject(JarvisTrackingService);
  private country = inject(DOMAIN_COUNTRY);

  servicesEndpoint = `${this.baseUrl}/serviceBase`;
  serviceEventEndpoint = `${this.baseUrl}/serviceEvent`;
  serviceEnableDisableEndpoint = `${this.baseUrl}/asdasdasd`;

  constructor(
    private httpService: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
    private translationService: TranslateService,
    private pixel: PixelService,
    private currentRoute: ActivatedRoute
  ) {}

  reloadListings(): void {
    this.listingReload$.next();
  }

  userEnableDisableService(data: ServiceEnableDisableParams): Observable<any> {
    // return this.httpService.post(this.serviceEnableDisableEndpoint, data);
    return this.editServiceEvent(data.serviceEventId, {
      disabled: !data.enabled,
    });
  }

  // TODO - talk about mapping. I think we should change the logic in the backend to accomodate general
  getServices(): Observable<any[]> {
    return this.httpService.get<any[]>(this.servicesEndpoint).pipe(
      map((services: any[]) => {
        return services.map((oldService) => {
          const serviceEvents = oldService.serviceEvents as any[];

          if (!serviceEvents) {
            return oldService;
          }

          serviceEvents.forEach((serviceEvent) => {
            if (!serviceEvent.customPricing) {
              serviceEvent.general = serviceEvent.wedding;
              delete serviceEvent.wedding;
            }
          });
          return oldService;
        });
      })
    );
  }

  getService(id: string): Observable<any> {
    return this.httpService.get<any>(`${this.servicesEndpoint}/${id}`).pipe(
      map((data: any) => {
        const serviceEvents = data.serviceEvents as any[];

        if (!serviceEvents) {
          return data;
        }

        serviceEvents.forEach((serviceEvent) => {
          if (!serviceEvent.customPricing) {
            serviceEvent.general = serviceEvent.wedding;
            delete serviceEvent.wedding;
          }
        });
        return data;
      })
    );
  }

  getMarketplaceListing(
    slugOrserviceId: string,
    qparams: string = '',
    deleteWeddingKeyOnGeneral = true
  ): Observable<MarketplaceListing> {
    return this.httpService
      .get<MarketplaceListing>(
        `${this.baseUrl}/marketplace/service/${slugOrserviceId}` + qparams
      )
      .pipe(
        map((serviceEvent: any) => {
          if (!serviceEvent.customPricing) {
            serviceEvent.general = serviceEvent.wedding;
            if (deleteWeddingKeyOnGeneral) {
              delete serviceEvent.wedding;
            }
          }
          return serviceEvent;
        })
      );
  }

  getServiceEvent(id: string): Observable<any> {
    return this.httpService.get(`${this.serviceEventEndpoint}/${id}`).pipe(
      map((data: any) => {
        if (!data.customPricing) {
          data.general = data.wedding;
          delete data.wedding;
        }
        return data;
      })
    );
  }

  useInvite(hash: string): Observable<any> {
    return this.httpService.get<any>(`${this.baseUrl}/invite/${hash}`);
  }

  saveBaseService(data: any): Observable<any> {
    return this.httpService.post(this.servicesEndpoint, data);
  }

  saveServiceEvent(data): Observable<any> {
    if (!data.customPricing) {
      data.wedding = data.general;
    }
    return this.httpService.post(this.serviceEventEndpoint, data);
  }

  editBaseService(id: string, data: any): Observable<any> {
    return this.httpService.patch(`${this.servicesEndpoint}/${id}`, data);
  }

  editServiceEvent(id: string, data: any): Observable<any> {
    return this.httpService.patch(`${this.serviceEventEndpoint}/${id}`, data);
  }

  deleteServiceBase(id: string): Observable<any> {
    return this.httpService.delete(`${this.servicesEndpoint}/${id}`);
  }

  deleteServiceEvent(id: string): Observable<any> {
    return this.httpService.delete(`${this.serviceEventEndpoint}/${id}`);
  }

  createNewEmptyServiceEvent(serviceBaseId: string): Observable<any> {
    return this.httpService.post(this.serviceEventEndpoint, {
      serviceBase: serviceBaseId,
    });
  }

  extractServiceEventName(serviceBase: any, serviceEventId: string): string {
    if (serviceBase.type !== 'venues') {
      return serviceBase.brandName;
    }

    if (
      !serviceBase.venues.areas ||
      serviceBase.venues.areas.length === 0 ||
      !serviceBase.venues.areas[0].name
    ) {
      return serviceBase.brandName;
    }

    const areaName = serviceBase.venues.areas.find(
      (area) => area.serviceId === serviceEventId
    )?.name;
    return areaName
      ? `${serviceBase.brandName}: ${areaName}`
      : serviceBase.brandName;
  }

  extractAvailableServices(
    serviceBase: any,
    serviceEvent: any,
    serviceType: ServiceEventTypes
  ): any[] {
    const serviceContent = serviceEvent[serviceType];

    if (!serviceContent) {
      return [];
    }

    const extractedServices = [];

    if (serviceContent.venuePricing?.enabled) {
      extractedServices.push({
        isMain: true,
        name: 'Venue',
        hash: 'ven',
        qnt: 0,
        isCustomPrice: false,
        unit: serviceContent.venuePricing.priceUnit,
        price: serviceContent.venuePricing.priceValue,
        description: serviceContent.description,
      });
    }

    if (serviceContent.venueStandalone?.enabled) {
      extractedServices.push({
        isMain: true,
        name: 'Venue standalone',
        hash: 'vens',
        qnt: 0,
        isCustomPrice: false,
        unit: serviceContent.venueStandalone.priceUnit,
        price: serviceContent.venueStandalone.priceValue,
      });
    }

    if (serviceContent.accomodationPricing?.enabled) {
      extractedServices.push({
        isMain: true,
        name: 'Accomodation',
        hash: 'acmd',
        qnt: 0,
        isCustomPrice: false,
        unit: serviceContent.accomodationPricing.priceUnit,
        price: serviceContent.accomodationPricing.priceValue,
      });
    }

    // console.log(this.listService.getPrice('/test',service,serviceEvent));

    if (serviceBase.kitchen?.enabled) {
      extractedServices.push({
        isMain: true,
        hash: 'catr',
        qnt: 0,
        isCustomPrice: false,
        name: serviceContent.catering.name
          ? serviceContent.catering.name
          : 'Catering',
        unit: serviceContent.catering.priceUnit,
        description: serviceContent.catering.description,
        price: serviceContent.catering.priceValue,
      });

      if (serviceBase.kitchen.dessertKiloPrice) {
        extractedServices.push({
          isMain: false,
          name: 'Dessert',
          hash: 'dess',
          qnt: 0,
          isCustomPrice: false,
          unit: AdvertsPriceUnits.currencyPerWeight,
          price: serviceBase.kitchen.dessertKiloPrice,
        });
      }

      if (serviceBase.kitchen.isTastingPriced) {
        extractedServices.push({
          isMain: false,
          name: 'Tasting',
          hash: 'tast',
          qnt: 0,
          isCustomPrice: false,
          unit: AdvertsPriceUnits.currencyPerPerson,
          price: serviceBase.kitchen.tastingPrice,
        });
      }
    }

    if (serviceContent.mainServices) {
      Object.keys(serviceContent.mainServices).forEach((key) => {
        serviceContent.mainServices[key].forEach((item) => {
          extractedServices.push({
            isMain: true,
            name: item.description?.name,
            hash: 'm' + key + serviceContent.mainServices[key].indexOf(item),
            qnt: 0,
            isCustomPrice: false,
            unit: AdvertsPriceUnits.currencyPerPerson,
            price: item.description?.priceValue,
            description: item.description?.description,
          });
        });
      });
    }

    if (serviceContent.additionalServicesEnabled) {
      Object.keys(serviceContent.additionalService).forEach((key) => {
        serviceContent.mainServices[key].forEach((item) => {
          extractedServices.push({
            isMain: false,
            name: item.description?.name,
            hash: 'a' + key + serviceContent.mainServices[key].indexOf(item),
            qnt: 0,
            isCustomPrice: false,
            unit: AdvertsPriceUnits.currencyPerPerson,
            price: item.description?.priceValue,
            description: item.description?.description,
          });
        });
      });
    }

    return extractedServices;
  }

  getCalendarEvents(
    serviceEventId: string,
    selectedDate: Date,
    reservedBookingsInDay?: number,
    multiClientPerDay?: boolean
  ): Observable<any> {
    const startTimestamp = +startOfMonth(selectedDate);
    const endTimestamp = +endOfMonth(selectedDate);

    return this.httpService
      .get(`${this.baseUrl}/marketplace/calendar`, {
        params: {
          id: serviceEventId,
          dateFrom: `${startTimestamp}`,
          dateTo: `${endTimestamp}`,
        },
      })
      .pipe(
        map((result: any[]) =>
          result.map((itm) => ({
            ...itm,
            dateFrom: DateUtils.convertTZ(
              itm.dateFrom,
              -(new Date(itm.dateFrom).getTimezoneOffset() / 60)
            ),
            dateTo: DateUtils.convertTZ(
              itm.dateTo,
              -(new Date(itm.dateTo).getTimezoneOffset() / 60)
            ),
          }))
        ),
        map((result: any[]) => {
          const filteredEvents = result
            .filter((record) => {
              const isBlockType = record.type === 'block';
              const isBookingType =
                record.type === 'booking' || record.type === 'autoBlock';
              // const isBookingNew = record.booking?.state === 'new' || record.booking?.state === 'communication';
              const isBookingNew = !(
                record.booking?.state === 'reserved' ||
                record.booking?.state === 'completed' ||
                record.type === 'autoBlock'
              );
              const isPriceChangeType =
                record.type === 'price' &&
                (record.dateObject.changePriceDirection === 'discount' ||
                  record.dateObject.changePriceDirection === 'markup');

              return (
                isBlockType ||
                (isBookingType && !isBookingNew) ||
                isPriceChangeType
              );
            })
            .filter((record) => {
              const dateFrom = new Date(record.dateFrom);
              const dateTo = new Date(record.dateTo);
              return dateFrom.getTime() <= dateTo.getTime();
            });
          const dayAndEvents: { day: Date; events: any[] }[] = [];

          filteredEvents.forEach((event) => {
            const eventDays = eachDay(event.dateFrom, event.dateTo);
            eventDays.forEach((eventDay) => {
              const relevantObject = dayAndEvents.find((dayEventObject) =>
                isSameDay(dayEventObject.day, eventDay)
              );

              if (!relevantObject) {
                dayAndEvents.push({
                  day: eventDay,
                  events: [event],
                });
                return;
              }

              relevantObject.events.push(event);
            });
          });
          const eventsArray: CalendarEvent[] = [];

          dayAndEvents.forEach((dayEvents) => {
            const reservedBookingCount = dayEvents.events.filter((record) => {
              const isBookingType =
                record.type === 'booking' || record.type === 'autoBlock';
              // const isBookingNew = record.booking?.state === 'new' || record.booking?.state === 'communication';
              const isBookingNew = !(
                record.booking?.state === 'reserved' ||
                record.booking?.state === 'completed' ||
                record.type === 'autoBlock'
              );

              return isBookingType && !isBookingNew;
            }).length;

            const allDayBooked =
              dayEvents.events.some((v) => v.type === 'block') || // !!!!!!! Block the day in any case, even if only a few hours are blocked
              (reservedBookingCount > 0 &&
                (!multiClientPerDay ||
                  reservedBookingCount >= reservedBookingsInDay ||
                  this.checkIfAllDayIsBooked(dayEvents.day, dayEvents.events)));

            if (allDayBooked) {
              eventsArray.push({
                title: 'booked',
                start: startOfDay(dayEvents.day),
                end: endOfDay(dayEvents.day),
                allDay: true,
              });
            }

            dayEvents.events
              .filter(
                (event) =>
                  event.type !== 'booking' && event.type !== 'autoBlock'
              )
              .forEach((event) => {
                const transformedRecord: CalendarEvent = {
                  title:
                    event.type === 'price'
                      ? event.dateObject.changePriceDirection
                      : 'booked',
                  start: new Date(event.dateFrom),
                  end: new Date(event.dateTo),
                  allDay: !!event.dateObject.allDay,
                };

                event.type === 'price' &&
                  (transformedRecord.meta = {
                    type: event.dateObject.changePriceType,
                    value: Number(event.dateObject.changePriceValue),
                    direction: event.dateObject.changePriceDirection,
                  });

                eventsArray.push(transformedRecord);
              });
          });

          return eventsArray;
        })
      );
  }

  createBookingRequest(bookingData: NewBookingData): Observable<any> {
    const { serviceInfo, serviceType, serviceVariantData, date, customer } =
      bookingData;

    if (!getUserId()) {
      setUserId(customer.email);
    }

    const serviceBaseInfo = serviceInfo.service;
    const serviceEventId = serviceInfo.serviceEvent._id;
    const serviceBaseId = serviceInfo.service._id;
    const vendorId = serviceBaseInfo.user;
    const serviceName = serviceBaseInfo.brandName;

    const schedulingInfo = (serviceInfo.service as ServiceBase).scheduling;

    const timePerClient = schedulingInfo?.concreteTimeForClient
      ? schedulingInfo.timePerClient
      : null;

    const details = serviceVariantData;

    const flattenedServices = [...details.main, ...details.additional];

    const newDetails = [];
    let finalPrice = 0;
    let totalTax = 0;
    let totalServiceCharge = 0;
    const guestNo = bookingData.options?.guestNo || 1;
    const noEventDate = bookingData.options?.noEventDate || false;
    const vieRecommended = bookingData.options?.viaRecommended || false;
    const requestMorePhotos = bookingData.options?.morePhotosRequest || false;

    flattenedServices
      .filter((service) => service.included)
      .forEach((service) => {
        const servicePrice = service.getFinalPrice(date);
        const unitPrice = service.getFinalUnitPrice(date);
        const tax = service.getTax(date);
        const translatedName = this.translationService.instant(service.name);
        let translatedDescription: string | null = null;

        if (this.country === 'lt') {
          const translationParams = {
            maxPersonCount:
              service.maxQuantity > -1 ? `${service.maxQuantity}` : '',
          };

          try {
            translatedDescription = this.translationService.instant(
              service.description
            );
          } catch (e) {
            translatedDescription = null;
          }

          if (typeof translatedDescription !== 'string') {
            try {
              let translationKey = service.priceUnit as string;

              if (service.type === ServiceTypes.globalMinimalBudget) {
                translationKey = service.globalMinimalBudgetType;
              }

              if (service.type === ServiceTypes.serviceCharge) {
                translationKey = service.serviceChargeType;
              }

              translatedDescription = this.translationService.instant(
                `${service.description}.${translationKey}`,
                translationParams
              );
            } catch (e) {
              // TODO: empty better?
              translatedDescription = '';
            }
          }
        }

        // if (service.priceUnit == AdvertsPriceUnits.currencyPerPerson && service.quantity > guestNo) guestNo = service.quantity;

        const newDetailData: any = {
          name: translatedName,
          description: translatedDescription,
          price:
            service.type === ServiceTypes.serviceCharge
              ? unitPrice
              : servicePrice / service.quantity,
          qnt: service.quantity,
          unit: service.priceUnit,
          isCustomPrice: false,
          type: service.type,
          hash: BookingsUtil.hashCode(
            service.name,
            details.main.includes(service)
          ),
          excludedFromGlobalMinBudget: service.excludedFromGlobalMinBudget,
          minBudget: service.getMinimalBudget(date),
        };

        if (service.additionalQuantityEnabled) {
          newDetailData.additionalQnt = service.additionalQuantity;
        }

        if (service.tax) {
          newDetailData.taxPercent = service.tax / 100;
        }

        if (service.serviceCharge) {
          newDetailData.serviceChargePercent = service.serviceCharge / 100;
        }

        if (service.type === ServiceTypes.venueSeparate) {
          newDetailData.rentAdditionalPriceUnit = service.priceUnit;

          if (
            service.priceUnit === AdvertsPriceUnits.fixedFee ||
            service.priceUnit === AdvertsPriceUnits.startingPrice
          ) {
            newDetailData.rentAdditionalFixedCost = service.getFinalPrice(date);
          }

          if (service.priceUnit === AdvertsPriceUnits.currencyPerPerson) {
            newDetailData.rentAdditionalUnitCost = service.getFinalPrice(date);
          }
        }

        newDetails.push(newDetailData);

        if (service.type === ServiceTypes.serviceCharge) {
          totalServiceCharge += servicePrice;
        }
        finalPrice += servicePrice;
        totalTax += tax;
      });

    let discount = 0;

    finalPrice = finalPrice + totalTax;

    const cashBack = Math.abs(bookingData.cashback ?? 0);

    discount += cashBack;

    // finalPrice = finalPrice - cashBack < 0 ? 0 : finalPrice - cashBack;

    // Rounds
    finalPrice = roundToTwoPrecision(finalPrice);
    totalServiceCharge = roundToTwoPrecision(totalServiceCharge);
    totalTax = roundToTwoPrecision(totalTax);

    /* if (discountInfo && discountInfo.type && discountInfo.value) {
        if (discountInfo.type === 'percent') {
            discount = finalPrice * (discountInfo.value / 100);
        }

        if (discountInfo.type === 'fixed') {
            discount = discountInfo.value;
        }
    } */

    const deposit = this.calculateDeposit(
      serviceInfo,
      finalPrice /*  - discount */
    );

    const parsedDates = this.formatServiceDate({
      startDate: date,
      timeDate: bookingData.timeDate,
      timePerClient,
    });

    const searchParams = this.jarvisSearchParamStoreService.getParam();

    const newBookingData = {
      serviceEventId,
      serviceBaseId,
      serviceName,
      details: newDetails,
      discount: +discount,
      deposit,
      serviceType: this.mapServiceType(serviceType, searchParams?.eventType),
      price: finalPrice,
      serviceCharge: totalServiceCharge,
      tax: totalTax,
      //notes: bookingData.message ?  `Customer message:\n${bookingData.message}`: '',
      paid: 0,
      options: {
        anonUserMessage: bookingData.message || null,
        bookingRequest: bookingData.bookingRequest,
        noEventDate: noEventDate,
        guestNo: guestNo,
        globalMinimalBudget: 0,
        taxes: 0,
        serviceCharge: 0,
        eventTypeInFilter: searchParams?.eventType,
        source: bookingData.options?.source || ['LISTING'],
        amplitudeSessionId: getSessionId(),
        adminData: bookingData.options?.adminData || {},
        queryParams: bookingData.options?.queryParams || {},
      },
      // eventDate: date,
      // eventTillDate: date ? addDays(date, 1) : null,
      ...parsedDates,
      customer,
      vendor: vendorId,
      state: 'new',
    };

    /* if (bookingData.options?.morePhotosRequest) {
      const data: RequestMorePhotosData = {
        serviceBase: serviceBaseInfo,
        serviceEventId,
        name: customer.name,
        email: customer.email,
        phoneNo: customer.phoneNo
      };

      const morePhotosMessage = this.getRequestMorePhotosMessage(data);

      if (newBookingData.options.anonUserMessage) {
        newBookingData.options.anonUserMessage += `\n\n${morePhotosMessage}`;
      } else {
        newBookingData.options.anonUserMessage = morePhotosMessage;
      }
    } */
    if (
      !newBookingData.options.anonUserMessage &&
      (serviceBaseInfo.address?.country ?? 'US').toUpperCase() === 'LT'
    ) {
      newBookingData.options.anonUserMessage =
        this.getDefaultMessage(newBookingData);
    }

    const partnerId = this.currentRoute.snapshot.queryParams?.partnerId;

    if (vieRecommended) {
      newBookingData.options['viaRecommended'] = vieRecommended;
    }

    if (partnerId) {
      newBookingData.options['partnerId'] = partnerId;
    }

    if (requestMorePhotos) {
      newBookingData.options['requestMorePhotos'] = requestMorePhotos;
    }

    const userEventDate = this.getEventDateYearFromNow(date);

    this.trackService.handleEvent({
      trackers: ['pixel'],
      eventName: 'Lead',
      data: {
        content_category: serviceInfo.service?.type,
        content_name: newBookingData.serviceName,
        value: finalPrice,
      },
    });

    this.trackService.handleEvent({
      trackers: ['pixelCustom'],
      eventName: 'inquiry-save',
    });

    if (userEventDate && userEventDate <= 365) {
      this.trackService.handleEvent({
        trackers: ['pixelCustom'],
        eventName: 'lead_less_than_year',
        data: {
          content_category: serviceInfo.service?.type,
          content_name: newBookingData.serviceName,
          user_event_date: userEventDate,
        },
      });
    }
    if (userEventDate && userEventDate > 365) {
      this.trackService.handleEvent({
        trackers: ['pixelCustom'],
        eventName: 'lead_more_than_year',
        data: {
          content_category: serviceInfo.service?.type,
          content_name: newBookingData.serviceName,
          user_event_date: userEventDate,
        },
      });
    }

    const planningStatus =
      bookingData?.options?.adminData?.planningStatusUpdated ||
      bookingData?.options?.adminData?.planningStatus;

    if (planningStatus === 'visiting') {
      this.trackService.handleEvent({
        trackers: ['pixelCustom'],
        eventName: 'lead_already_visiting',
        data: {
          content_category: serviceInfo.service?.type,
          content_name: newBookingData.serviceName,
          user_event_date: userEventDate,
        },
      });

      this.trackService.handleEvent({
        trackers: ['pixel'],
        eventName: 'Purchase',
        data: {
          content_category: serviceInfo.service?.type,
          content_name: newBookingData.serviceName,
        },
      });
    }

    this.trackService.handleEvent({
      trackers: ['pinterest'],
      eventName: 'lead',
      data: {
        lead_type: 'inquiry-save',
      },
    });

    return this.httpService.post(
      `${this.baseUrl}/marketplace/booking`,
      newBookingData
    );
  }

  private getDefaultMessage(data: any): string {
    const translationString =
      data.options.noEventDate || !data.eventDate
        ? 'booking.anonUserMessageWODate'
        : 'booking.anonUserMessage';

    const message = this.translationService.instant(translationString, {
      serviceName: data.serviceName,
      eventDate: data.eventDate
        ? formatDate(data.eventDate, 'yyyy-MM-dd', 'lt-LT')
        : null,
    });

    return message;
  }

  createEmptyBooking(emptyBookingData: EmptyBookingData) {
    const { serviceInfo, serviceType, customer, eventDate } = emptyBookingData;

    const serviceBaseInfo = serviceInfo.service;
    const serviceEventId = serviceInfo.serviceEvent._id;
    const serviceBaseId = serviceInfo.service._id;
    const vendorId = serviceBaseInfo.user;
    const serviceName = serviceBaseInfo.brandName;

    const newBookingData: any = {
      serviceEventId,
      serviceBaseId,
      serviceName,
      serviceType,
      customer,
      options: {
        // Right now only way to create empty booking is from listing page
        bookingRequest: 'viaListing',
      },
      vendor: vendorId,
      state: 'new',
    };
    if (eventDate) {
      newBookingData.eventDate = eventDate;
    }

    return this.httpService.post(
      `${this.baseUrl}/marketplace/booking`,
      newBookingData
    );
  }

  calculateDeposit(serviceInfo, totalPrice): number {
    const advancesInfo = serviceInfo.service.advances;

    if (!advancesInfo) {
      return 0;
    }

    if (advancesInfo.fullAdvance) {
      return totalPrice;
    }

    if (advancesInfo.priceUnit === 'percentage') {
      return totalPrice * (advancesInfo.price / 100);
    }

    if (advancesInfo.priceUnit === 'fixed') {
      return advancesInfo.price > totalPrice ? totalPrice : advancesInfo.price;
    }

    return 0;
  }

  private mapServiceType(
    serviceType: string,
    eventTypeInFilter: string
  ): string {
    // TODO: extract types to config file or standalone one-time initiated value
    const typeMap = {
      'private-event': 'private',
      'business-event': 'business',
      businessEvent: 'business',
      privateEvent: 'private',
      general: 'general',
      wedding: 'wedding',
    };

    return typeMap[serviceType] || eventTypeInFilter || 'general';
  }

  private formatServiceDate(serviceDateParams: ServiceDateParameters) {
    if (!serviceDateParams.startDate) {
      return {
        eventDate: null,
        eventTillDate: null,
      };
    }

    const eventDate = this.setTimeToOtherDate(
      startOfDay(serviceDateParams.startDate),
      serviceDateParams.timeDate
    );

    const eventTillDate = this.setTimeToOtherDate(
      serviceDateParams.endDate || serviceDateParams.timePerClient
        ? addMinutes(eventDate, serviceDateParams.timePerClient)
        : endOfDay(eventDate),
      serviceDateParams.timeDateEnd
    );

    return {
      eventDate: new Date(this.getZeroOffsetDate(eventDate)),
      eventTillDate: new Date(this.getZeroOffsetDate(eventTillDate)),
    };
  }

  private getEventDateYearFromNow(date: Date) {
    if (!date) {
      return null;
    }

    const difference = differenceInDays(date, new Date());

    return difference;
  }

  private setTimeToOtherDate(date: Date, timeDate: Date | null): Date {
    if (!timeDate) {
      return date;
    }

    const hours = getHours(timeDate);
    const minutes = getMinutes(timeDate);
    const newDate = setHours(setMinutes(date, minutes), hours);
    return newDate;
  }

  // Converts local time to the same time of GMT0 (removes timezone)
  private getZeroOffsetDate(date: Date): string {
    return format(date, 'YYYY-MM-DD[T]HH:mm:ss[Z]');
  }

  private checkIfAllDayIsBooked(
    currentDay: Date,
    events: any[],
    intervalInMinutes = 15
  ): boolean {
    const minutesInADay = 1440;
    const interval = minutesInADay / intervalInMinutes;

    const intervalDates = new Array(interval).fill(null).map((_, index) => {
      const currentMinuteValue = index * intervalInMinutes;
      const currentTrueMinute = currentMinuteValue % 60;
      const currentTrueHour = (currentMinuteValue - currentTrueMinute) / 60;

      const date = setHours(
        setMinutes(currentDay, currentTrueMinute),
        currentTrueHour
      );
      return date;
    });

    const checkArray = new Array(interval).fill(false);

    events
      .filter((event) => event.type !== 'price')
      .forEach((event) => {
        intervalDates.forEach((date, dateIndex) => {
          const dateInRangeWithEvent =
            isWithinRange(date, event.dateFrom, event.dateTo) &&
            isBefore(date, event.dateTo);
          if (dateInRangeWithEvent) {
            checkArray[dateIndex] = true;
          }
        });
      });

    return checkArray.every((val) => val === true);
  }
}

export function hashPhotographerService(
  priceUnit: string,
  minimalBudget: number | null,
  hoursIncluded: number | null
) {
  return hashCode(
    `${priceUnit}_${minimalBudget || null}_${hoursIncluded || null}`
  );
}

export function hashVenueCateringService() {
  return hashCode('venue_catering');
}

export function hashVenueCateringBeveragesService() {
  return hashCode('venue_catering_beverages');
}

// Hashes string using java's hashCode() function
function hashCode(input: string): string {
  const splitInput = input.split('');
  const hashed = splitInput.reduce((acc, char) => {
    acc = (acc << 5) - acc + char.charCodeAt(0);
    return acc & acc;
  }, 0);
  return hashed.toString();
}
