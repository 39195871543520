import { Component } from '@angular/core';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';
import {UntypedFormControl } from '@angular/forms';

const VALID_PROTOCOLS = [
  'http://',
  'https://'
];

@Component({
  selector: 'jarvis-website',
  templateUrl: './website.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class WebsiteComponent {

  formControl:UntypedFormControl;

  constructor(
    private profilingFormService: JarvisProfilingFormService
  ) {
    this.formControl = this.profilingFormService.getStepControl('websiteUrl');
  }

  next(): void {
    if (!this.formControl.value) {
      return;
    }

    this.profilingFormService.trackFieldCaptureEvent('website_url');
    // this.profilingFormService.navigateToStep('promo-code');
    this.profilingFormService.navigateToStep('source');
  }

  skip(): void {
    this.formControl.reset();
    this.profilingFormService.trackFieldCaptureEvent('website_skipped');
    // this.profilingFormService.navigateToStep('promo-code');
    this.profilingFormService.navigateToStep('source');
  }

  checkURLProtocol(urlString: string): void {
    if (!urlString) {
      return;
    }
    const hasProtocol = VALID_PROTOCOLS.some((value) => urlString.startsWith(value));
    if (!hasProtocol) {
      this.formControl.setValue(`${VALID_PROTOCOLS[1]}${urlString}`);
    }
  }
}
