import { JarvisAuthModule } from '@jarvis/auth';
import { ReviewStarsModule } from './review-stars/review-stars.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewsAddReviewComponent } from './add-review/add-review.component';
import { ReviewsSummaryComponent } from './summary/summary.component';
import { ReviewsInviteComponent } from './invite/invite.component';
import { ReviewsSuccessEngageComponent } from './success-engage/success-engage.component';
import { ReviewsListComponent } from './list/list.component';
import { ReviewsVerifyComponent } from './add-review-verify/add-review-verify.component';
import { ReviewsAskForReviewComponent } from './ask-for-review/ask-for-review.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { JarvisFileUploaderModule, ButtonModule, JarvisCardWrapperModule, JarvisUiModalWrapperModule, ImageFallbackDirective, JarvisOverlayComponent, JarvisOverlayContainerComponent } from '@jarvis/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ReviewSummaryComponent } from './review-summary/review-summary.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AddReviewContactsComponent } from './add-review-contacts/add-review-contacts.component';
import { PortalModule } from '@angular/cdk/portal';
import { ServiceNamePipe } from '@jarvis/utils';
import { StarRatingErrorPipe } from './pipes/star-rating-error.pipe';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs'
import { RouterModule } from '@angular/router';
import { ReviewsListDetailsComponent } from './list/details/details.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { JarvisReviewsIndividualScoresComponent } from './individual-scores/individual-scores.component';
@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    JarvisFileUploaderModule,
    ReactiveFormsModule,
    ButtonModule,
    JarvisCardWrapperModule,
    MatIconModule,
    TranslateModule,
    JarvisUiModalWrapperModule,
    MatTooltipModule,
    PortalModule,
    RouterModule,
    ReviewStarsModule,
    JarvisAuthModule,
    MatTabsModule,
    JarvisOverlayComponent,
    JarvisOverlayContainerComponent,
    CdkOverlayOrigin,
    ImageFallbackDirective
  ],
  declarations: [
    ReviewsAddReviewComponent,
    ReviewsSummaryComponent,
    ReviewsInviteComponent,
    ReviewsSuccessEngageComponent,
    ReviewsListComponent,
    ReviewsAskForReviewComponent,
    ReviewsVerifyComponent,
    ReviewSummaryComponent,
    AddReviewContactsComponent,
    ReviewsListDetailsComponent,
    JarvisReviewsIndividualScoresComponent,
    StarRatingErrorPipe
  ],
  exports: [
    ReviewsAddReviewComponent,
    ReviewsSummaryComponent,
    ReviewsInviteComponent,
    ReviewsSuccessEngageComponent,
    ReviewsVerifyComponent,
    ReviewsListComponent,
    ReviewsAskForReviewComponent
  ],
  providers: [
    ServiceNamePipe
  ]
})
export class JarvisReviewsModule { }
