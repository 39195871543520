import { SharedService } from '../../../../shared/services/shared.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, map } from 'rxjs';
import { JarvisAuthService } from '@jarvis/auth';
import { JarvisNotificationService } from '@jarvis/notifications';
import { LinkUrls, DOMAIN_COUNTRY, LINK_URLS, JarvisLanguageService } from '@jarvis/services';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit, OnDestroy {

  private vendorNotification$ = this.notificationService.navigationNotifications$('vendor');
  
  private bookingNotificationCount$ = this.vendorNotification$.pipe(
    map((data: any) => this.domainCountry=='us'? null : (data?.bookings ?? 0))
  );

  private messageNotificationCount$ = this.vendorNotification$.pipe(
    map((data: any) => data?.messages ?? 0)
  );

  links = [
    {
      path: 'overview',
      label: 'overview',
      icon: 'bar_chart'
    },
    {
      path: 'services',
      label: 'services',
      icon: 'celebration'
    },
    {
      path: 'calendar',
      label: 'calendar',
      icon: 'date_range'
    },
    {
      path: 'bookings',
      label: 'bookings',
      icon: 'today',
      notifications: this.bookingNotificationCount$
    },
    {
      path: 'messages',
      label: 'messages',
      icon: 'chat',
      notifications: this.messageNotificationCount$
    },
    {
      path: 'partner-list',
      label: 'partnerList',
      icon: 'handshake'
    },
    {
      path: 'sponsored-listings',
      label: 'sponsored',
      icon: 'campaign'
    },    
    /* {
      path: 'video-presentation',
      label: 'videoPresentation',
      icon: 'videocam'
    },
    {
      path: 'auth/password-change',
      label: 'usagePlans',
      icon: 'description'
    } */
  ];

  userData$ = this.authService.userData$;

  isFlipped = false;
  profileExtended = false;

  close$ = new Subject<void>();

  constructor(
    private languageService: JarvisLanguageService,
    private sharedService: SharedService,
    private authService: JarvisAuthService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private notificationService: JarvisNotificationService,
    @Inject(LINK_URLS) private linkUrls: LinkUrls,
    @Inject(DOMAIN_COUNTRY) public domainCountry: string
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.close$.complete();
  }

  extendProfile(): void {
    this.isFlipped = !this.isFlipped;
    this.profileExtended = !this.profileExtended;
  }

  moveToMarketplace(): void {
    window.location.href = `${this.linkUrls.marketplace}/profile/settings?from=login`;
  }

  changeLanguage(): void {
    this.languageService.toggleLanguage();
  }

  navigateToSettings(): void {
    this.router.navigate(['/profile/settings'], { relativeTo: this.currentRoute });
  }

  openDemoRequest(): void {
    this.sharedService.openDemoRequestModal();
  }

  logout(): void {
    this.authService.signOut().subscribe(() => {
      window.location.href = this.linkUrls.marketplace;
      this.closeMenu();
    });
  }

  closeMenu(): void {
    this.close$.next();
  }

}
