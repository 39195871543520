import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ReactiveFormsModule } from '@angular/forms';
import { MainHeaderComponent } from './main-header.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { ProfileDropdownComponent } from './profile-dropdown/profile-dropdown.component';
import { DropdownWrapperComponent } from './dropdown-wrapper/dropdown-wrapper.component';
import { MainHeaderMenuComponent } from './menu/menu.component';
import { ServiceTypeSelectorModule } from '@jarvis/ui/service-type-selector';
import { ButtonModule } from '@jarvis/ui/button';
import { MenuServiceListComponent } from './service-list/service-list.component';
import { ProfileDropdownContentComponent } from './profile-dropdown-content/profile-dropdown-content.component';
import { TopSearchComponent } from './top-search/top-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LastLikeDropdownComponent } from './last-like-dropdown/last-like-dropdown.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { CardListingTypePipe } from '@jarvis/ui/marketplace-card';

@NgModule({
  declarations: [
    MainHeaderComponent,
    MenuItemComponent,
    ProfileDropdownComponent,
    DropdownWrapperComponent,
    MainHeaderMenuComponent,
    MobileMenuComponent,
    MenuServiceListComponent,
    ProfileDropdownContentComponent,
    TopSearchComponent,
    LastLikeDropdownComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatSidenavModule,
    PortalModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    ServiceTypeSelectorModule,
    RouterModule,
    ButtonModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatBottomSheetModule,
    CardListingTypePipe
  ],
  exports: [
    MainHeaderComponent,
    MenuItemComponent,
    ProfileDropdownComponent,
    DropdownWrapperComponent,
    MainHeaderMenuComponent,
    MobileMenuComponent,
    MenuServiceListComponent,
    LastLikeDropdownComponent
  ],
})
export class MainHeaderModule {}
