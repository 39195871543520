import { Pipe, PipeTransform } from '@angular/core';
import { setHours, setMinutes, setSeconds, isWithinRange, startOfDay, isAfter, isBefore } from 'date-fns';
import * as moment from 'moment';

@Pipe({
  name: 'TimeValueDisabled',
  pure: true
})
export class TimeValueDisabledPipe implements PipeTransform {
  transform(value: any, dateEventsOriginal: any[], currentDate: Date): any {
    const dateEvents = (dateEventsOriginal || []).filter((event) => event.type !== 'price');

    if (!dateEvents || dateEvents.length === 0) {
      return false;
    }

    const trueMinutes = value % 60;
    const trueHours = (value - trueMinutes) / 60;

    const datefiedTimeValue =
      setHours(
        setMinutes(
          setSeconds(startOfDay(currentDate), 0
          ), trueMinutes
        ), trueHours);


    const eventWithinInterval = dateEvents.findIndex((event) => {
      return isWithinRange(moment(datefiedTimeValue).utc(true).toISOString(), event.dateFrom, event.dateTo) && isBefore(datefiedTimeValue, event.dateTo);
      // return isWithinRange(datefiedTimeValue, event.dateFrom, event.dateTo);
    });

    return eventWithinInterval === -1 ? false : true;
  }
}
