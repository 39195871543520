import { Component } from '@angular/core';
import {UntypedFormControl } from '@angular/forms';
import { checkFormOrGroupInvalid } from '../../../../utils/profiler.utils';
import { JarvisProfilingFormService } from '../../../../services/profiler-form.service';

@Component({
  selector: 'jarvis-price',
  templateUrl: './price.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class PriceComponent {

  formControl:UntypedFormControl;

  constructor(
    private profilingFormService: JarvisProfilingFormService
  ) {
    this.formControl = this.profilingFormService.getStepControl('startingPrice');
  }

  next(): void {
    const formValid = checkFormOrGroupInvalid(this.formControl);

    if (!formValid) {
      return;
    }

    this.profilingFormService.trackFieldCaptureEvent('price');
    this.profilingFormService.navigateToStep('experience');
  }

}
