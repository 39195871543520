import { Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { from } from 'rxjs';
import { mergeAll, take } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class JarvisSmallCalendarOverlayService {
    constructor(
        private overlay: Overlay
    ) { }
    
    createOverlay(template: TemplateRef<any>, viewContainerRef: ViewContainerRef, connectedTo: ElementRef, position: any[]) {
        const overlayRef = this.overlay.create({
          hasBackdrop: true,
          backdropClass: 'cdk-overlay-transparent-backdrop',
          scrollStrategy: this.overlay.scrollStrategies.close(),
          positionStrategy: this.overlay.position()
            .flexibleConnectedTo(connectedTo)
            .withPositions(position)
            .withDefaultOffsetY(5)
        });
    
        const componentPortal = new TemplatePortal(template, viewContainerRef);
    
        from([
          overlayRef.backdropClick(),
          overlayRef.detachments()
        ]).pipe(mergeAll(), take(1)).subscribe(() => {
          overlayRef.detach();
        });
    
        overlayRef.attach(componentPortal);

        return overlayRef;
      }
}