import { inject, Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PixelService } from 'ngx-pixel';
import { NgxHotjarService } from 'ngx-hotjar';
import mixpanel from 'mixpanel-browser';
import { DOMAIN_COUNTRY } from './url.utils';
import { track } from '@amplitude/analytics-browser';
import {
  PixelEventName,
  PixelEventProperties,
} from 'ngx-pixel/lib/pixel.models';

type TrackerType =
  | 'amplitude'
  | 'gtm'
  | 'hotjar'
  | 'pixel'
  | 'pixelCustom'
  | 'mixpanel'
  | 'tiktok'
  | 'pinterest';

type PixelTrackingEvent = {
  trackers: ['pixel'];
  eventName: PixelEventName;
  data?: PixelEventProperties;
};

type CustomTrackingEvent = {
  trackers: TrackerType[];
  eventName: string;
  data?: any;
};

type TrackingEvent = CustomTrackingEvent;

export const BLACKLISTED_EVENTS = [
  'picture_swipe',
  'listing_gallery_image_shown',
  'search_results',
  'service_plus_click',
  'page_click',
  'read_more_click',
  'listing_gallery_filter_change',
  'photo_click',
  'shorts_preview_next_photo',
  'shorts_swipe_next',
  'listing_description_expanded',
  'service_input_changed',
  'service_minus_click',
  'listing-info-expanded-toggle',
  'VAA-plus',
  'shorts_swipe_prev',
  'add_service_click',
  'VAA',
  'photo_upload_initiated',
  'log_in_popup_click',
  'planner_tab_change',
  'shorts_preview_prev_photo',
  'header_signup_vendor_click',
  'header_login_click',
  'header_signup_click',
  'listing-map-open',
  'Switched to Facebook reviews',
  'photo_upload_success',
  'date_next',
  'planner_filter_change',
  'Switched to Breezit reviews',
  'shorts_preview_close',
  'signup_host_popup_click',
  'signup_popup_open',
  'photo_upload_error',
  'signup_vendor_page_click',
  'listing_flexible_date_unchecked',
  'Switched to Google reviews',
  'remove_from_team_and_budget',
  'business_name_captured',
  'add_to_team_and_budget',
  'planner_concept_selected',
  'planner_item_add_note_click',
  'signup_popup_closed',
  'Switched to Yelp reviews',
  'planner_concept_edited',
  'find_vendors_or_add_own',
  'planner_share_copy_link',
  'sign_up_completed',
  'header_foxy_click',
  'listing_faq_expand',
  'log_in_click',
  'Login-header',
  'promo_code_captured',
  'review_card_click',
  'Sign-up-header',
  'signup_click',
  'signup_host_click',
  'signup_vendor_click',
  'site_search_clicked',
  'site_search_selected',
];

export const AMPLITUDE_WHITELISTED_EVENTS = [
  'page_load',
  'listing_send_inquiry_send',
];

declare var ttq: any;
declare var pintrk: any;

@Injectable({ providedIn: 'root' })
export class JarvisTrackingService {
  private gtmService = inject(GoogleTagManagerService);
  private pixelService = inject(PixelService);
  private hotjarService = inject(NgxHotjarService);

  private country = inject(DOMAIN_COUNTRY);

  constructor() {}

  handleEvent(event: TrackingEvent) {
    if (BLACKLISTED_EVENTS.includes(event.eventName)) return;

    if (!event.data) event.data = {};
    event.data.domain_country = this.country.toUpperCase();
    event.data.page_url = window.location.href;

    if (event.trackers.includes('amplitude')) this.trackAmplitudeEvent(event);
    if (event.trackers.includes('gtm')) this.trackGtmEvent(event);
    if (event.trackers.includes('hotjar')) this.trackHotjarEvent(event);
    if (event.trackers.includes('pixel')) this.trackPixelEvent(event);
    if (event.trackers.includes('pixelCustom'))
      this.trackPixelCustomEvent(event);
    if (event.trackers.includes('mixpanel')) this.trackMixpanelEvent(event);
    if (event.trackers.includes('tiktok')) this.trackTikTokEvent(event);
    if (event.trackers.includes('pinterest')) this.trackPinterestEvent(event);
  }

  trackMixpanelEvent(event: TrackingEvent) {
    mixpanel.track(event.eventName, event.data);
  }

  trackAmplitudeEvent(event: TrackingEvent) {
    if (!AMPLITUDE_WHITELISTED_EVENTS.includes(event.eventName)) return;
    track(event.eventName, event.data);
  }

  trackGtmEvent(event: TrackingEvent) {
    const gtmTag = {
      event: event.eventName,
      ...event.data,
    };
    this.gtmService.pushTag(gtmTag);
  }

  trackHotjarEvent(event: TrackingEvent) {
    this.hotjarService.hj('event', event.eventName);
  }

  trackPixelEvent(event: any) {
    this.pixelService.track(event.eventName, event.data);
  }

  trackPixelCustomEvent(event: TrackingEvent) {
    this.pixelService.trackCustom(event.eventName, event.data);
  }

  trackTikTokEvent(event: TrackingEvent) {
    ttq.track('SubmitForm', {
      event_type: event.eventName,
      ...event.data,
      contents: [event.data],
    });
  }

  trackPinterestEvent(event: TrackingEvent) {
    pintrk('track', event.eventName, event.data);
  }
}
