import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'jarvis-main-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent {

  close$ = new Subject<void>();

  constructor(

  ) { }


  closeMenu(): void {
    this.close$.next();
  }

}
