<div class="bztt-z-10 bztt-bg-white md:bztt-w-screen">
  <div *ngIf="isMobile$ | async" class="bztt-flex bztt-w-full bztt-justify-end">
    <button class="bztt-flex bztt-items-center" (click)="close()">
      <span class="bztt-font-montserrat-bold bztt-text-sm bztt-underline">{{
        'listing.selectCategory.close' | translate
      }}</span>
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <h2 *ngIf="isMobile$ | async" class="bztt-font-maple-r bztt-text-[1.75rem]">
    {{ 'listing.selectCategory.title' | translate }}
  </h2>

  <div
    class="bztt-flex bztt-flex-col md:bztt-flex-row bztt-justify-center bztt-w-full bztt-text-[#222222] md:bztt-px-8 md:bztt-pb-4 bztt-z-10"
  >
    <ng-container>
      <jarvis-service-type-selector 
        [disableFeatured]="disableFeatured" 
        [rearrangeItems]="rearrangeItems" 
        [disableFooter]="disableFooter"
        (typeSelected)="close()"
      ></jarvis-service-type-selector>
    </ng-container>
  </div>
</div>

<div
  *ngIf="(isMobile$ | async) === false"
  (click)="this.close()"
  class="bztt-fixed bztt-h-full bztt-w-full"
  style="
    background: #ef5518 !important;
    background-color: #ef5518 !important;
    opacity: 0.2 !important;
  "
></div>
