import mixpanel from 'mixpanel-browser';

export function notHeadlessBrowser() {
  return !/eadless/.test(window.navigator.userAgent) ? true : false;
}

export const MixpanelFactory = (APP_KEY: string) => {
  return () => {
    return () => {
      if (APP_KEY && notHeadlessBrowser()) {
        mixpanel.init(APP_KEY, {
        //   debug: true,
          persistence: 'localStorage',
        });
      }
    };
  };
};
