import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BASE_URL } from '@jarvis/services/tokens';
import { DOMAIN_COUNTRY } from '@jarvis/services/url-utils';
import {
  Observable,
  Subject,
  debounceTime,
  filter,
  firstValueFrom,
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class JarvisLocationService {
  private baseUrl = inject(BASE_URL);
  private httpService = inject(HttpClient);
  private country = inject(DOMAIN_COUNTRY);

  budgetGuestCountChange = new Subject<any>();
  destroy$ = new Subject<void>();

  originalLocations: any[] = [];
  locations: any[] = [];
  locationSortedByVenues: any[] = [];

  static locationFactory(
    locationService: JarvisLocationService
  ): () => Promise<any> {
    return () => firstValueFrom(locationService.getLocations());
  }

  getLocations(): Observable<any[]> {
    const countryUpperCase = this.country.toUpperCase();

    return this.httpService
      .get<any[]>(`${this.baseUrl}/common/location?country=${countryUpperCase}`)
      .pipe(
        tap((locations) => {
          this.originalLocations = this.mapLocations(locations);
          this.locations = this.originalLocations;
          if (this.country === 'us') {
            this.initFilterParamChanges();
          }
          //   this.locationSortedByVenues = this.locations
          //     .sort((a, b) => b.metadata?.venueCount - a.metadata?.venueCount)
          //     .filter((location) => location.metadata?.venueCount > 10);
        })
      );
  }

  initFilterParamChanges() {
    this.budgetGuestCountChange
      .pipe(
        takeUntil(this.destroy$),
        startWith({ budget: 0, guestCount: 1, serviceType: 'venues' }),
        debounceTime(500),
        filter((item) => item.serviceType === 'venues')
      )
      .subscribe((data) => {
        data.budget = data.budget || 0;
        if (data.budget > 30000) data.budget = 100000000;

        data.guestCount = data.guestCount || 1;

        if (!data.listingCount || data.listingCount < 10) {
          //   this.locations = JSON.parse(JSON.stringify(this.originalLocations));
          this.locations = structuredClone(this.originalLocations);
        } else {
          this.locations = structuredClone(this.originalLocations).map(
            (location: any) => {
              if (location.metadata?.avgPrice) {
                const avgPrice =
                  location.metadata?.avgPrice[
                    this.findRange(data.guestCount)
                  ]?.[0]?.[data.budget]?.[0];

                const formatter = Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });

                location.avgPrice = avgPrice
                  ? formatter.format(avgPrice.avg)
                  : '';
              }
              return location;
            }
          );
        }
      });
  }

  findRange(i: number): number {
    const lower = Math.floor(i / 50) * 50;
    const upper = lower + 50;
    return upper;
  }

  mapLocations(locations: any[]) {
    return locations.map((location) => {
      const mapData = location.gmapData?.data || {};

      if (location.region) mapData.region = location.region;

      if (this.country === 'lt') {
        mapData.distance = location.distance || 120000;

        location.displayValue =
          location.searchText || location.gmapData?.displayValue;
      } else {
        mapData.distance = location.distance || 100000;
        
        // testing location distance override
        if (!mapData.isCity) {
          mapData.distance = 240000;
        }

        location.displayValue =
          location.gmapData?.displayValue || location.name;
      }
      location.data = mapData;
      return location;
    });
  }

  destroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
