import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'jarvis-ui-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JarvisTimePickerComponent),
      multi: true,
    },
  ],
})
export class JarvisTimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() tick = 60;
  @Output() dateChange = new EventEmitter<Date>();
  @Input() ngModel: Date;
  @Input() label: string;
  @Input() isMobile = false;
  @Output() ngModelChange = new EventEmitter<Date>();

  @ViewChild('timePicker') timePicker;

  public value = 0;
  private dt: moment.Moment;
  public time: { tm: number; name: string }[] = [];

  constructor() {}

  public openedChange(opened: boolean) {
    if (opened) document.body.classList.add('time-picker-open');
    else document.body.classList.remove('time-picker-open');
  }

  public close() {
    this.timePicker.close();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  writeValue(value: any) {
    if (value?._isAMomentObject) {
      this.dt = value;
    } else this.dt = moment(value);

    this.value = this.dt.hour() * 60 + this.dt.minute();
  }

  public doSelection(selected: any) {
    this.writeValue({ hour: selected.value / 60, minute: selected.value % 60 });
    // this.dt.set({ hour: selected.value / 60, minute: selected.value % 60 });
    this.propagateChange(this.dt.toDate());
    this.dateChange.emit(this.dt.toDate());
  }

  ngOnInit(): void {
    for (let i = 0; i < 60 * 24; i = i + Number(this.tick)) {
      this.time.push({
        tm: i,
        name:
          Math.floor(i / 60)
            .toString()
            .padStart(2, '0') +
          ':' +
          (i % 60).toString().padStart(2, '0'),
      });
    }
  }
}
