<jarvis-overlay overlayX="end" originX="start" overlayY="top" originY="bottom" [anchor]="actionOverlayData.anchor" (closed)="close()">
  <div class="message-actions-overlay">
    <ul (click)="close()">
      <ng-container [ngSwitch]="!actionOverlayData.isOwn">
        <ng-container *ngSwitchCase="true">
          <li *ngIf="(!actionOverlayData.isOwn) && actionOverlayData.isLastMessage" (click)="markAsUnread()">{{ 'messages.markAsUnread' | translate }}</li>
        </ng-container>
        
        <ng-container *ngSwitchDefault>
          <li *ngIf="actionOverlayData.isLastMessage && domainCountry === 'us' && messagesType === 'vendor'" (click)="enableAutoReply()">{{ 'messages.autoReply' | translate }}</li>
          <li (click)="deleteMessage(actionOverlayData.messageData._id)">{{ 'messages.remove' | translate }}</li>
          <li *ngIf="actionOverlayData.messageData.type !== 'files' || (actionOverlayData.messageData.type === 'files' && actionOverlayData.messageData.message)" (click)="startMessageEdit(actionOverlayData.messageData._id, actionOverlayData.messageData.message)">{{ 'messages.edit' | translate }}</li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</jarvis-overlay>