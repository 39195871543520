import { formatNumber } from '@angular/common';
import {
  BreezitReviewData,
  BreezitReviewSummary,
  ThirdPartyReviewData,
  ThirdPartyReviewProvider,
} from '../reviews.types';

type ReviewParserOptions = {
  thirdPartyReviews?: ThirdPartyReviewData[];
  breezitReviews?: BreezitReviewData;
  excludedThirdPartyProviders?: ThirdPartyReviewProvider[];
};

export class ReviewsUtils {
  static get thirdPartyReviewProviders(): ThirdPartyReviewProvider[] {
    return ['facebook', 'google', 'yelp'];
  }

  public static averageRating(
    thirdPartyReviews: ThirdPartyReviewData[],
    review: BreezitReviewSummary
  ): { count: string; rating: string } {
    const thirdPartyReviewsCount = thirdPartyReviews?.reduce(
      (a, v) => a + (v.count ?? 0),
      0
    );

    const thirdPartyReviewsCountWithRating = thirdPartyReviews?.reduce(
      (a, v) => a + (v.source != 'facebook' && v.count ? v.count : 0),
      0
    );

    const allReviewsCount =
      (thirdPartyReviewsCount ?? 0) + (review?.reviewsCount ?? 0);

    const allReviewsCountWithRating =
      (thirdPartyReviewsCountWithRating ?? 0) + (review?.reviewsCount ?? 0);

    // Breezit rating or Third Party Reviews rating
    // const rating = review?.score || thirdPartyReviews.reduce((a, v) => (v.count / thirdPartyReviewsCount) * v.rating, 0);

    const allReviews = [
      ...(thirdPartyReviews?.map((v) => ({
        count: v.count,
        rating: v.rating,
      })) || []),
      review ? { count: review?.reviewsCount, rating: review?.score } : [],
    ].filter((v) => v);

    const rating = allReviews.reduce(
      (a, v: { count; rating }) =>
        a + (v.count / allReviewsCountWithRating) * (v.rating ?? 0),
      0
    );

    return {
      count: allReviewsCount == 0 ? '-' : `${allReviewsCount}`,
      rating: rating ? formatNumber(rating, 'en-US', '1.0-2') : '-',
    };
  }

  public static getReviewProvidersParsed(options: ReviewParserOptions) {
    const {
      breezitReviews = null,
      thirdPartyReviews = null,
      excludedThirdPartyProviders = [],
    } = options || {};

    const allReviews = [];

    if (!breezitReviews && !thirdPartyReviews) {
      return allReviews;
    }

    if (thirdPartyReviews) {
      const thirdPartyParsed = thirdPartyReviews
        .filter(
          (reviewProvider) =>
            !excludedThirdPartyProviders.includes(reviewProvider.source)
        )
        .map((reviewProvider) => {
          const parsed = {
            score: reviewProvider.rating ?? null,
            totalReviews: reviewProvider.count,
            reviews: [],
          };

          reviewProvider.reviews?.forEach((review) => {
            const parsedReview = {
              text: review.review,
              score: review.rating,
              userInfo: {
                name: review.reviewer,
                surname: null,
              },
              date: review.createdAt,
            };

            parsed.reviews.push(parsedReview);
          });

          return parsed;
        });

      allReviews.push(...thirdPartyParsed.filter((v) => v.score !== null));
    }

    if (breezitReviews) {
      const breezitParsed = {
        score: breezitReviews?.reviewsBase?.score || 0,
        totalReviews: breezitReviews?.reviewsBase?.reviewsCount || 0,
        reviews: [],
      };

      breezitReviews?.docs?.forEach((review) => {
        const parsedReview = {
          text: review.review,
          score: review.score,
          userInfo: review.userInfo || (review as any).user,
          date: review.createdAt,
        };

        breezitParsed.reviews.push(parsedReview);
      });

      allReviews.push(breezitParsed);
    }

    return allReviews;
  }
}
