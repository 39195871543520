<!--<ng-container *ngIf="(currentChatroom$ | async) as chatroom">
    <div class="menu-actions-container">
        <ul class="horizontal-list">
            <li (click)="close()">
                <mat-icon>chevron_left</mat-icon>
                <a>Back</a>
            </li>
            <li class="right-align">
                <messaging-phone-no-container
                    [chatroom]="chatroom"
                ></messaging-phone-no-container>
            </li>
            <li>
                <mat-icon (click)="openBookingInfo.emit()">info</mat-icon>
            </li>
        </ul>
    </div>
</ng-container>-->
<messaging-chat-header class="bztt-ml-0"></messaging-chat-header>
<messaging-chat-messages class="bztt-border-t bztt-border-[#DCDCDC]"></messaging-chat-messages>