import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'NewFiltersDynamicParamsChange',
  standalone: true,
})
export class NewFiltersDynamicParamsChangePipe implements PipeTransform {
  transform(filterParam$: Observable<Record<string, string>>) {
    const paramsToConvert = [
      'dateFrom',
      'personCount',
      'subType',
      'cateringType',
      'budgetList',
      'quantity'
    ];

    const keyConversions = {
      subType: 'subtype',
      quantity: 'personCount',
    };

    return filterParam$.pipe(
      map((filterParams) => {
        const converted = Object.entries(filterParams)
          .filter(([key]) => paramsToConvert.includes(key))
          .map(([key, value]) => {
            const newKey = keyConversions[key] || key;
            return {
              type: newKey,
              value,
            };
          });

        return converted;
      })
    );
  }
}
